import config from "../ep.config.json";
import { httpGet, httpPost, httpPut } from "./HttpService";
import { ProductPriceRequest } from "../utils/mappings/productDetails.jsx";

const {
  cortexApi: { scope }
} = config;

const account = "goodman";
const newVersionToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJnZ1Z4aDJqZ2VmbmZlb0JXcm1uQyIsImF1ZCI6ImFwaS5kYWlraW5jbG91ZC5pbyIsImlzcyI6IkRhaWtpbkNsb3VkU2VydmljZXMgMy4wIiwibmFtZSI6IkVDb21tZXJjZSIsImV4cCI6MTkyNzUxOTIwMCwiZW1haWwiOiJlc3RoZXIueXVAZXkuY29tIn0.P_JPgL3dE8sEngHHc9RpFOeKAXNpcZ4rlszV5dg6URQ";

/**
 * ## getOrderHistory
 *
 * @param query string
 *
 * @description Function returns the information about the query specified
 * orders of the logged-in user or all of the customer's orders if no query is specified.
 */
export const getOrderHistory = async (query: string = ""): Promise<any> => {
  const path = scope === "motili" ? "motili/orders" : `${account}/order`;
  const url = `/1.5/${path}${query}`;
  try {
    const res = await httpGet(url);

    return res;
  } catch (error) {
    return error;
  }
};

/**
 * ## sendContactUsForm
 *
 * @param body string - stringified message
 *
 * @description Function sends the specified message to the "Contact Us" recipient
 * appropriate to the brand. Message is specified in a JSON body.
 * Brand must be Enum: "DAIKIN" | "GOODMAN" | "AMANA". (Daikin API 1.5)
 */
export const sendContactUsForm = async (body: string): Promise<any> => {
  const brandName = scope.toUpperCase();

  const url = `/api/3.0/support/contact?brand=${brandName.toUpperCase()}&message=${encodeURIComponent(
    body
  )}`;
  const options = {
    headers: {
      Authorization: `Bearer ${newVersionToken}`
    }
  };
  try {
    const response = await httpPost(url, null, options);

    return response.data;
  } catch (err) {
    return err;
  }
};

/**
 * ## getCreditInformation
 *
 * @param customerNumber string
 *
 * @description Function returns the current account history for a given account,
 * specified by email address.
 */
export const getCreditInformation = async (
  customerNumber: string
): Promise<any> => {
  const url = `1.5/user/account/history?customerNumber=${customerNumber}`;
  try {
    const response = await httpGet(url);

    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

/**
 * ## checkEntitlementSku
 *
 * @param skus string
 * @param customerNumber string - Pass multiple parameters with | delimiter, like so "sku1|sku2".
 * @param distributor? string Default value is "GOODMAN"
 * @param email? string
 *
 * @description Function returns entitlement information for each sku based on the current user.
 */
export const checkEntitlementSku = async (
  skus: string,
  customerNumber: string,
  distributor?: string,
  email?: string
): Promise<any> => {
  const emailParameter = email ? `&email=${email}` : "";
  const url = `/1.5/entitlement?skus=${encodeURIComponent(
    skus
  )}&customerNumber=${customerNumber}&distributor=${distributor ||
    "GOODMAN"}${emailParameter}`;
  try {
    const response = await httpGet(url);

    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

/**
 * ## priceCatalog
 *
 * @param body ProductPriceRequest
 *
 * @description Function returns pricing for a list of items.
 */
export const priceCatalog = (body: ProductPriceRequest) => {
  const url = "/api/3.0/price/catalog";

  const options = {
    headers: {
      Authorization: `Bearer ${newVersionToken}`
    }
  };

  return httpPost(url, body, options);
};

/**
 * ## getAccountStatement
 * @param customerNumber string
 * @param statementDate string
 *
 * @description Function returns account statements for specified time period.
 */
export const getAccountStatement = async (
  customerNumber: string,
  statementDate: string
): Promise<any> => {
  const url = `/1.5/user/account/statement?customerNumber=${customerNumber}&statementDate=${statementDate}&format=PDF`;
  const options = { responseType: "blob" };

  return httpGet(url, options);
};

/**
 * ## getInvoiceReport
 * @param orderNumber string - this should be the same as invoice number.
 * @param customerNumber string
 *
 * @description Function returns generated invoice report (for the provided order number) in PDF-format.
 */
export const getInvoiceReport = (
  orderNumber: string,
  customerNumber: string
): Promise<any> => {
  const url = `/1.5/user/account/invoice?customerNumber=${customerNumber}&invoiceNumber=${orderNumber}&format=PDF`;
  const options = { responseType: "blob" };

  return httpGet(url, options);
};

/**
 * ## getMatchUps
 *
 * @param branchNumber string
 *
 * @description Function returns the information about the match up products for specified brach
 */

export const getMatchUps = (
  branch: string,
  brand: string = scope.toUpperCase()
): Promise<any> => {
  const url = `/api/3.0/matchups?branch=${branch}&brand=${brand}`;
  const options = {
    headers: {
      Authorization: `Bearer ${newVersionToken}`
    }
  };

  return httpGet(url, options);
};

/**
 * ## getBranches
 * @param options:
 * {
 *    distributors?: string;
 *    affiliate?: string;
 *    limit?: number;
 *    skip?: number;
 *    sort?: string;
 *  }
 *
 * @description Function returns list of all branches with details. The Default distributor is "GOODMAN".
 */

export const getBranches = (options: {
  distributors?: string;
  affiliate?: string;
  limit?: number;
  skip?: number;
  sort?: string;
}): Promise<any> => {
  const affiliateParameter = options.affiliate
    ? `&affiliate=${options.affiliate}`
    : "";
  const limitParameter = options.limit ? `&limit=${options.limit}` : "";
  const skipParameter = options.skip ? `&skip=${options.skip}` : "";
  const sortParameter = options.sort ? `&sort=${options.sort}` : "";

  const url = `/1.5/branch/entitlements?distributors=${options.distributors ||
    "GOODMAN"}${affiliateParameter}${limitParameter}${skipParameter}${sortParameter}`;

  return httpGet(url);
};

/* ## getAvailability
 *
 * @param branch string
 * @param skus string
 * @param lat number
 * @param lng number
 * @param clientId string
 * @param distributors string
 * @param fulfillmentTypeId string
 * @param affiliate string
 *
 * @description Function that returns either DGA availability inventory or Motili availability inventory
 * DGA - Response contains branch availability, regional availability as a sum and list of all available branches with their inventory
 * Motili - Response contains list of all branches with details and individual inventory. The first branch in the array is the current branch
 */

export const getAvailability = (
  branch: string,
  skus: string,
  lat?: number,
  lng?: number,
  clientId?: string,
  distributors?: string,
  fulfillmentTypeId: string = "PICK_UP",
  affiliate?: string
): Promise<any> => {
  let url;

  if (scope !== "motili") {
    const affiliateParameter = affiliate ? `&affiliate=${affiliate}` : "";
    const latParameter = lat ? `&lat=${lat}` : "";
    const lngParameter = lng ? `&lng=${lng}` : "";
    url = `/1.5/inventory/availability?branch=${branch}&skus=${encodeURIComponent(
      skus
    )}&distributors=${distributors ||
      "GOODMAN"}${affiliateParameter}${latParameter}${lngParameter}`;
  } else {
    const clientIdParam = clientId ? `&clientId=${clientId}` : "";
    const fulfillmentTypeIdParam = fulfillmentTypeId
      ? `&fulfillmentTypeId=${fulfillmentTypeId}`
      : "";
    const products = JSON.stringify({ productIds: skus.split("|") });
    url = `/1.5/motili/inventory?latitude=${lat}&longitude=${lng}${clientIdParam}${fulfillmentTypeIdParam}&inventory=${encodeURIComponent(
      products
    )}`;
  }
  const options = {
    timeout: scope === "motili" ? 1000 * 5 : 0
  };

  return httpGet(url, options);
};

/* ## getMincronOrderId
 *
 * @param epOrderId string
 *
 * @description Function returns mincron order id
 */
export const getMincronOrderId = (epOrderId: string): Promise<any> => {
  const url = `/1.5/ecomm/orderlog?ecommOrderNumbers=ecomm_${epOrderId}`;

  return httpGet(url);
};

/**
 * ## getWarrantyModels
 *
 * @param serialNumber string
 *
 * @description Function returns models for the supplied serial number
 */
export const getWarrantyModels = (serialNumber: string) => {
  const url = `/1.5/goodman/warranty/models?serialNumber=${serialNumber}`;
  return httpGet(url);
};

/**
 * ## getWarrantyEntitlements
 *
 * @param serialNumber string
 * @param model string
 * @param installType string
 * @param lastName string
 *
 * @description Function returns warranty entitlements
 */
export const getWarrantyEntitlements = (
  serialNumber: string,
  model: string,
  installType: string,
  lastName: string
) => {
  const queryParams: string = `?serialNumber=${serialNumber}&model=${model}&installType=${installType}${
    lastName ? `&lastName=${lastName}` : ""
  }`;
  const url = `/1.5/goodman/warranty/entitlements${queryParams}`;
  return httpGet(url);
};

/**
 * ## changeHomeBranch
 *
 * @param email string
 * @param defaultBranch string
 *
 * @description Function that changes homeBranch in Daikin
 */
export const changeHomeBranch = (email: string, defaultBranch: string) => {
  const url = `/1.5/user/profile`;
  const data = { email, defaultBranch };
  return httpPut(url, data);
};
/**
 * ## listStatements
 *
 * @param customerNumber string
 *
 * @description Function that lists all avaliable statements for the user.
 */
export const listStatements = (customerNumber: string) => {
  const url = `/1.5/user/account/statement/list?customerNumber=${customerNumber}`;
  return httpGet(url);
};

/**
 * ## resetPassword
 *
 * @param email string
 * @param newPassword string
 *
 * @description Function that changes password for the logged in user.
 */
export const resetPassword = (email: string, newPassword: string) => {
  const url = `/1.5/auth/user/password?email=${email}&password=${newPassword}`;
  return httpPut(url, null);
};

/**
 * ## completeInventory
 *
 * @param body any - as
 * {
 * customerNumber: string;
 * branchNumber: string;
 * items: Array<{sku: string; quantity: number}>
 * }
 *
 * @description Function that returns the list of branches that can completely satisfy the order without a back-order.
 */
export const completeInventory = (body: any) => {
  const url = `/1.5/inventory/complete`;
  return httpPost(url, body);
};

/**
 * ## getMotiliBranches
 *
 * @param motiliCompanyId: string;
 *
 * @description Function that returns list of entitled branches for Motili store
 */
export const getMotiliBranches = (motiliCompanyId: string) => {
  const url = `/1.5/motili/branchentitlements?motiliCompanyId=${motiliCompanyId}`;

  return httpGet(url);
};

/**
 * ## getPartsAndManifests
 *
 * @param sku: string;
 * @param serialNumber: string;
 *
 * @description Function that returns parts and manifests for specific sku and serial numbers
 */
export const getPartsAndManifests = (sku: string, serialNumber: string) => {
  const url = `/api/3.0/parts?sku=${sku}&serial=${serialNumber}`;

  return httpGet(url);
};

/**
 * ## getContractOrders
 *
 * @param customerNumber : string;
 * @param contractNumber : string;
 * @param poNumber : string;
 *
 * @description Function that returns the contract orders for the customer, if any exist.
 * If only the customer number is specified, all contract orders for the customer will be returned.
 * If the contract number or PO number is specified, the results will be filtered by those values.
 */
export const getContractOrders = (
  customerNumber: string,
  contractNumber?: string,
  poNumber?: string
) => {
  const contractNumberParam = contractNumber
    ? `&contractNumber=${contractNumber}`
    : "";
  const poNumberParam = poNumber ? `&poNumber=${poNumber}` : "";
  const url = `/api/3.0/profile/contracts?customerNumber=${customerNumber}${contractNumberParam}${poNumberParam}`;
  const options = {
    headers: {
      Authorization: `Bearer ${newVersionToken}`
    }
  };

  return httpGet(url, options);
};
