import React, { FC, useState, useEffect } from "react";
import intl from "react-intl-universal";
import { ContractOrder } from "../../../app/src/containers/ContractOrdersPage";

export interface ContractItemDetails {
  discountPct: string;
  lineCtlNo: string;
  netPrice: string;
  orderNo: string;
  orderQty: number;
  pricingUOM: string;
  productNo: string;
  releasedQty: number;
  shipFromBranch: string;
  unitPrice: string;
  description?: string;
  exeededQty?: boolean;
  entitled?: boolean;
}

interface ContractItemProps {
  item: ContractItemDetails;
  contract: ContractOrder;
  editable: boolean;
  handleQuantityEntered: (...args: any) => any;
  resetQty: boolean;
}

const ContractItem: FC<ContractItemProps> = ({
  item,
  contract,
  editable,
  handleQuantityEntered,
  resetQty
}) => {
  const [quantity, setQuantity] = useState<string>("");

  useEffect(() => {
    if (resetQty && quantity) {
      setQuantity("");
    }
  }, [resetQty]);

  const onInputChange = e => {
    const { value } = e.target;
    setQuantity(value);
    handleQuantityEntered(item.productNo, value, item.lineCtlNo);
  };

  const checkQuantityReleased = () => {
    if (
      !isPricingContract &&
      Number(item.releasedQty) > Number(item.orderQty)
    ) {
      return item.orderQty;
    }
    return item.releasedQty;
  };

  const { pricing } = contract;

  const isPricingContract = pricing === "Y";

  const hasError = Number(item.releasedQty) >= Number(item.orderQty);

  const isCustomOrder = item.productNo.startsWith("/");

  return (
    <tr className="contract-item">
      <td>
        <span>{intl.get("quantity-abbr")}:</span>
        {!editable ? (
          <p>{item.orderQty}</p>
        ) : (
          <input
            className={`qty-entered ${item.exeededQty ? "qty-error" : ""}`}
            value={quantity}
            onChange={onInputChange}
            type="number"
            min="1"
            max="9999"
            disabled={(!isPricingContract && hasError) || !item.entitled}
          />
        )}
      </td>
      <td>
        <span>{intl.get("product")}#:</span>
        <p>{item.productNo}</p>
      </td>
      <td>
        <span>{intl.get("description")}:</span>
        <p>{item.description}</p>
        {!isPricingContract && hasError && !isCustomOrder && (
          <p className="contract-item-error">{intl.get("release-error")}</p>
        )}
        {isCustomOrder && (
          <p className="contract-item-error">
            {intl.get("custom-order-error")}
          </p>
        )}
        {!hasError && !item.entitled && !isCustomOrder && (
          <p className="contract-item-error">{intl.get("unentitled-item")}</p>
        )}
      </td>
      <td>
        <span>{intl.get("price")}:</span>
        <p>${item.unitPrice}</p>
      </td>
      <td>
        <span>{editable && `${intl.get("qty-ordered")}:`}</span>
        <p>{editable && item.orderQty}</p>
      </td>
      <td>
        <span>{intl.get("qty-released")}:</span>
        <p>{checkQuantityReleased()}</p>
      </td>
    </tr>
  );
};

export default ContractItem;
