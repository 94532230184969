import React from "react";
import { Link } from "react-router-dom";
import { getImageUrl } from "bloomreach-experience-react-sdk";

const HeaderLogo = props => {
  const { content, manageContentButton, preview, pageModel } = props;

  const image = getImageUrl(content.image, pageModel, preview);

  return (
    <div>
      {manageContentButton && manageContentButton}
      <Link to="/" className="logo">
        <img
          src={image}
          alt={`Goodman ${content.name}`}
          className="logo-image"
        />
      </Link>
    </div>
  );
};

export default HeaderLogo;
