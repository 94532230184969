/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import {
  Link,
  withRouter,
  RouteComponentProps,
  Redirect
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { RenderCmsComponent } from "bloomreach-experience-react-sdk";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { zoomNavigation } from "@zilker/store-components/src/static/zoom";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { load } from "@zilker/store-components";
import AppHeaderSearchMain from "../AppHeaderSearch/appheadersearch.main";
import AppHeaderLoginMain from "../AppHeaderLogin/appheaderlogin.main";
import AppHeaderLocaleMain from "../AppHeaderLocale/appheaderlocale.main";
import AppHeaderNavigationMain from "../AppHeaderNavigation/appheadernavigation.main";
import AppHeaderTop from "../AppHeaderTop/appheadertop.main";
import BulkOrderMain from "../BulkOrder/bulkorder.main";
import CartPopUp from "../CartPopUp/cartpopup";
import { ReactComponent as BulkCart } from "../../../app/src/images/header-icons/bulk-cart.svg";
import { cortexFetch } from "../utils/Cortex";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import MenuEditButton from "../Bloomreach/components/MenuEditButton/menu-edit-button";
import detectIEBrowser from "../../../app/src/validation/detectIEBrowser";
import JobDropdownComponent from "../JobDropdownComponent/JobDropdownComponent";
import BranchDropdownComponent from "../BranchDropdownComponent/BranchDropdownComponent";
import ClientDropdownComponent from "../ClientDropdownComponent/ClientDropdownComponent";

import "./appheader.main.less";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface AppHeaderMainProps extends RouteComponentProps {
  onCurrencyChange: (...args: any[]) => any;
  onLocaleChange: (...args: any[]) => any;
  onContinueCart: (...args: any[]) => any;
  brSuggest: (...args: any[]) => any;
  isInStandaloneMode: boolean;
  locationSearchData: string;
  appHeaderLinks: {
    [key: string]: any;
  };
  appHeaderLoginLinks: {
    [key: string]: any;
  };
  appHeaderNavigationLinks: {
    [key: string]: any;
  };
  appHeaderTopLinks: {
    [key: string]: any;
  };
  appModalLoginLinks: {
    [key: string]: any;
  };
  match: any;
  history: any;
}

interface AppHeaderMainState {
  areBranchesFetched: boolean;
  cartData: any;
  isLoading: boolean;
  isOffline: boolean;
  isSearchFocused: boolean;
  isBulkModalOpened: boolean;
  // multiCartData: any;
  isDesktop: boolean;
  isMobile: boolean;
  totalQuantity: number;
  guestLogged: boolean;
  ssoLogged: boolean;
  cortexNavigations: any;
  scrollClass: boolean;
  areContractsFetched: boolean;
}

class AppHeaderMain extends React.Component<
  AppHeaderMainProps,
  AppHeaderMainState
> {
  static contextType = MainContext;

  requestedNavigationHttp: boolean;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = epConfig);
    this.requestedNavigationHttp = false;
    this.state = {
      areBranchesFetched: false,
      totalQuantity: 0,
      cartData: undefined,
      isLoading: true,
      isOffline: false,
      isSearchFocused: false,
      isBulkModalOpened: false,
      // multiCartData: undefined,
      isDesktop: false,
      isMobile: false,
      guestLogged: false,
      ssoLogged: false,
      scrollClass: false,
      cortexNavigations: undefined,
      areContractsFetched: false
    };

    this.handleBulkModalClose = this.handleBulkModalClose.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this);
    this.openModal = this.openModal.bind(this);
    this.redirectToMainPage = this.redirectToMainPage.bind(this);
    this.goBack = this.goBack.bind(this);
    this.fetchCartData = this.fetchCartData.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.generatePageTitle = this.generatePageTitle.bind(this);
  }

  componentDidMount() {
    load();
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
    this.fetchCartData();
    this.fetchBranches();
    this.fetchContracts();
    window.onscroll = () => this.updateScroll();
    window.addEventListener("focus", this.onFocus);
  }

  componentDidUpdate(prevProps, prevState) {
    this.fetchCartData();
    this.fetchBranches();
    this.fetchContracts();

    const {
      location: { state },
      history
    } = this.props;
    const { isBulkModalOpened } = this.state;

    if (state && state.modal && !isBulkModalOpened) {
      this.openModal();
      history.replace();
    }

    const { guestLogged, ssoLogged, cortexNavigations } = prevState;
    const {
      context: {
        auth: { guestLoggedIn, isLoggedIn }
      }
    } = this;

    if (guestLoggedIn && !guestLogged && !cortexNavigations) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ guestLogged: true }, () => {
        if (!this.requestedNavigationHttp) {
          this.requestedNavigationHttp = true;
          this.fetchNavigationData();
        }
      });
    }
    if (isLoggedIn && !ssoLogged && !cortexNavigations) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ssoLogged: true }, () => {
        if (!this.requestedNavigationHttp) {
          this.requestedNavigationHttp = true;
          this.fetchNavigationData();
        }
      });
    }
    if (!guestLoggedIn && guestLogged) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ guestLogged: false });
    }
    if (!isLoggedIn && ssoLogged) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ssoLogged: false });
    }
  }

  onFocus() {
    const {
      context: {
        cart: { getCartDetails },
        auth: { isLoggedIn }
      }
    } = this;

    if (isLoggedIn) {
      getCartDetails();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
    window.removeEventListener("focus", this.onFocus);
  }

  fetchNavigationData() {
    const {
      context: {
        auth: { logout }
      },
      props: { history }
    } = this;

    cortexFetch(`/?zoom=${zoomNavigation.join()}`)
      .then(res => checkResponse(res))
      .then(res => {
        if (res && res._navigations) {
          const cortexNavigations = res._navigations[0]._element;
          this.setState(
            {
              cortexNavigations
            },
            () => {
              this.requestedNavigationHttp = true;
            }
          );
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => fetchNavigationData => AppHeaderMain.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "fetchNavigationData => AppHeaderMain.tsx"
          });
        }
      });
  }

  handleIsOffline = isOfflineValue => {
    this.setState({
      isOffline: isOfflineValue
    });
  };

  handleInputFocus = () => {
    this.setState({
      isSearchFocused: true
    });
  };

  updatePredicate() {
    this.setState({
      isDesktop: window.innerWidth > 1092,
      isMobile: window.innerWidth < 768
    });
  }

  updateScroll() {
    if (window.innerWidth > 768 && window.scrollY > 75)
      this.setState({ scrollClass: true });
    else this.setState({ scrollClass: false });
  }

  fetchContracts() {
    const {
      user: {
        userProfile: { customerNumber }
      },
      contract: { fetchContracts, contractsList }
    } = this.context;
    const { areContractsFetched } = this.state;
    if (customerNumber && !contractsList && !areContractsFetched) {
      fetchContracts(customerNumber);
      this.setState({ areContractsFetched: true });
    }
  }

  fetchBranches() {
    const {
      branches,
      auth: { isTokenSet },
      cart: {
        cartDetails: { defaultCart }
      },
      user: { userProfile }
    } = this.context;
    const { branchesList, fetchBranches, fetchMotiliBranches } = branches;
    const { motiliCompanyId, customerNumber } = userProfile;
    const { areBranchesFetched } = this.state;

    const { useMotiliEntitledBranchesService } = Config;
    const { homeBranch, subuserHomeBranch } = userProfile;

    if (branches && !branchesList && isTokenSet && !areBranchesFetched) {
      // Condition for calling entitled branches service for Goodman/Daikin/Amana
      if (!useMotiliEntitledBranchesService && defaultCart && customerNumber) {
        const { selectedBranch } = defaultCart;
        fetchBranches(
          customerNumber,
          { distributors: selectedBranch.vendor },
          subuserHomeBranch || homeBranch
        );
        this.setState({
          areBranchesFetched: true
        });
      }

      // Condition for calling entitled branches service for Motili
      if (useMotiliEntitledBranchesService && motiliCompanyId) {
        fetchMotiliBranches(motiliCompanyId);
        this.setState({
          areBranchesFetched: true
        });
      }
    }
  }

  fetchCartData() {
    const { cartData, totalQuantity } = this.state;
    const { cart } = this.context;
    const { cartDetails, cartList } = cart;

    if (cartDetails) {
      const { defaultCart, totalCount } = cartDetails;
      if (!cartData && defaultCart) {
        this.setState({
          cartData: defaultCart,
          isLoading: false
        });
      }

      if (cartData && defaultCart && cartData.cartId !== defaultCart.cartId) {
        this.setState({
          cartData: defaultCart,
          isLoading: false
        });
      }

      // Uncomment if multicart logic is needed here.
      // As per DGE-3116, the cart list is not fetched initially anymore.
      // The number of available carts is not visible here initially.
      // if (!multiCartData && cartList) {
      //   this.setState({
      //     multiCartData: cartList
      //   });
      // }

      if (!totalQuantity && totalCount) {
        this.setState({
          totalQuantity: totalCount
        });
      }
    }
  }

  goBack() {
    const { history } = this.props;
    history.goBack();
  }

  openModal() {
    const { isBulkModalOpened, isMobile } = this.state;
    if (isMobile) {
      this.setState(
        {
          isBulkModalOpened: !isBulkModalOpened
        },
        () => {
          document.body.style.position = "fixed";
          document.body.style.top = `-${window.scrollY}px`;
        }
      );
    } else {
      this.setState({
        isBulkModalOpened: !isBulkModalOpened
      });
    }
  }

  handleBulkModalClose() {
    this.setState(
      {
        isBulkModalOpened: false
      },
      () => {
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      }
    );
  }

  redirectToMainPage() {
    const { history } = this.props;
    history.push("/");
  }

  /* eslint-disable class-methods-use-this */
  generatePageTitle(pathname: string): string {
    const pages = {
      "/myAccount/profile": `${intl.get("my-account")} ${intl.get("profile")}`,
      "/myAccount/creditInformation": `${intl.get("credit-information")}`,
      "/myAccount/statement": `${intl.get("statement")}`,
      "/myAccount/manageUsers": `${intl.get("manage-users")}`,
      "/myAccount/branchSettings": `${intl.get("branch-settings")}`,
      "/myAccount/orderHistory": `${intl.get("order-history")}`,
      "/myAccount/orderDetails": `${intl.get("order")} ${intl.get("details")}`,
      "/myAccount/invoiceQuery": `${intl.get("invoice-query")}`,
      "/myAccount/invoiceDetails": `${intl.get("invoice-information")}`,
      "/mycart": `${intl.get("my-cart")}`,
      "/checkout": `${intl.get("checkout")}`,
      "/order": `${intl.get("review-order")}`,
      "/purchaseReceipt": `${intl.get("order-confirmation")}`,
      "/warrantyLookup": `${intl.get("warranty-look-up")}`,
      "/myAccount/savedOrders": `${intl.get("saved-orders")}`,
      "/contactus": `${intl.get("contact-us")}`,
      "/help": `${intl.get("help")}`,
      "/aboutus": `${intl.get("about-us")}`,
      "/privacypolicy": `${intl.get("privacy-policy")}`,
      "/termsandconditions": `${intl.get("terms-and-conditions-title")}`,
      "/maintenance": `${intl.get("maintenance")}`,
      "/matchup": `${intl.get("match-up-title")}`,
      "/documentsLibrary": `${intl.get("documents-library")}`
    };

    if (pathname && pathname.includes("/myAccount/orderDetails")) {
      return pages["/myAccount/orderDetails"];
    }

    if (pathname && pathname.includes("/myAccount/invoiceDetails")) {
      return pages["/myAccount/invoiceDetails"];
    }

    if (
      pathname &&
      pathname.includes("/myAccount/savedOrders") &&
      pathname.length > 22
    ) {
      // If the pathname is longer than 22 (/myAccount/savedOrders.length), it means that includes order (cart) name and id.
      return intl.get("saved-order-details");
    }

    return pages[pathname] || "";
  }

  render() {
    const {
      isOffline,
      cartData,
      isLoading,
      isSearchFocused,
      isBulkModalOpened,
      isDesktop,
      // multiCartData,
      totalQuantity,
      cortexNavigations,
      scrollClass
    } = this.state;
    const {
      onCurrencyChange,
      onLocaleChange,
      onContinueCart,
      locationSearchData,
      isInStandaloneMode,
      appHeaderLinks,
      appHeaderLoginLinks,
      appHeaderNavigationLinks,
      appHeaderTopLinks,
      appModalLoginLinks,
      brSuggest,
      history,
      location: { state, pathname }
    } = this.props;

    const {
      pageMetadata: { title: pageTitle, description, canonicalURL }
    } = Config;

    const {
      context: { auth, user, branches, contract }
    } = this;
    const { isLoggedIn } = auth;
    const { userProfile } = user;
    const {
      givenName,
      company,
      customerNumber,
      jobNumberRequired,
      jobsArray,
      clientList
    } = userProfile;
    const { cart } = this.context;
    const { cartError } = cart;
    const {
      branchError,
      branchesErrorCode,
      branchesErrorMessage,
      findBranch
    } = branches;
    const availability = Boolean(cartData);
    const {
      currentCount,
      cartDetails: { totalCount, defaultCart }
    } = cart;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;

    const jobsArrayExists = jobsArray && jobsArray.length;
    const jobNumberExists = defaultCart && defaultCart.jobNumber;

    const shouldDisplayJobDropdown =
      jobsArrayExists || (jobNumberRequired || jobNumberExists);

    const homeBranchDetails = findBranch(
      userProfile.subuserHomeBranch || userProfile.homeBranch
    );

    const { contractError } = contract;

    return (
      <HelmetProvider>
        <header key="app-header" className="app-header">
          <Helmet>
            <title>
              {(state && state.name) ||
                this.generatePageTitle(pathname) ||
                pageTitle}
            </title>
            <meta
              name="description"
              content={
                (state && state.name) ||
                this.generatePageTitle(pathname) ||
                description
              }
            />
            <link rel="canonical" href={`${canonicalURL}${pathname}`} />
          </Helmet>
          <RenderCmsComponent path="header-banner-ticker" />

          {!detectIEBrowser() && isDesktop ? (
            <AppHeaderTop
              onCurrencyChange={onCurrencyChange}
              onLocaleChange={onLocaleChange}
              appHeaderTopLinks={appHeaderTopLinks}
              isLoggedIn={isLoggedIn}
              userProfile={userProfile}
              cart={cart}
              homeBranchDetails={homeBranchDetails}
            />
          ) : null}

          <div className="main-header container-fluid">
            <div
              className={`main-container container ${
                isInStandaloneMode ? "in-standalone" : ""
              }`}
            >
              {!detectIEBrowser() ? (
                <div className="toggle-btn-container">
                  {isInStandaloneMode ? (
                    <button
                      className="back-btn"
                      aria-label="back button"
                      type="button"
                      onClick={this.goBack}
                    >
                      <span className="icon glyphicon glyphicon-chevron-left" />
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    className="toggle-btn"
                    type="button"
                    data-toggle="collapse"
                    data-target=".collapsable-container"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon glyphicon glyphicon-align-justify" />
                  </button>
                </div>
              ) : null}

              <div className="logo-container">
                <RenderCmsComponent path="header-logo" />
              </div>
              {!detectIEBrowser() ? (
                <div className="search-container" tabIndex={-1}>
                  {/* {Config.bloomreachSearch.enable ? (
                  <BloomreachAppHeaderSearchMain
                    isMobileView={false}
                    onSearchPage={onSearchPage}
                  />
                ) : ( */}
                  <AppHeaderSearchMain
                    isMobileView={false}
                    brSuggest={brSuggest}
                    history={history}
                    isLoggedIn={isLoggedIn}
                  />
                  {/* )} */}
                </div>
              ) : null}
              {!detectIEBrowser() ? (
                <div className="search-toggle-btn-container">
                  <button
                    className="search-toggle-btn"
                    type="button"
                    data-toggle="collapse"
                    data-target=".collapsable-container"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={this.handleInputFocus}
                  >
                    <div className="search-icon" />
                  </button>
                </div>
              ) : null}
              {!detectIEBrowser() ? (
                <div className="icons-header-container">
                  {isLoggedIn && (
                    <div className="cart-link-wrapper">
                      <div
                        className={`cart-link-container multi-cart-dropdown dropdown ${
                          currentCount.show ? "show" : ""
                        }`}
                      >
                        <Link
                          className={`cart-link ${
                            currentCount.show ? "modal-arrow" : ""
                          }`}
                          to={{
                            pathname: appHeaderLinks.myCart,
                            state: {
                              prevUrl: window.location.href,
                              error:
                                state && state.error ? state.error : undefined
                            }
                          }}
                        >
                          <div className="cart-total-quantity">
                            {" "}
                            {totalCount}{" "}
                          </div>
                          <i className="icon-Cart" />
                          {intl.get("shopping-cart-nav")}
                        </Link>
                        <div
                          className={`${
                            scrollClass ? "multi-cart-container-scroll" : ""
                          } multi-cart-container dropdown-menu dropdown-menu-right ${
                            currentCount.show ? "show" : ""
                          }`}
                        >
                          <CartPopUp
                            appHeaderLinks={appHeaderLinks}
                            cartData={cart}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {Config.enableBulkCart &&
                    availability &&
                    cartData &&
                    cartData.addItemsToCart && (
                      <div className="bulk-container">
                        {isLoggedIn ? (
                          <button
                            className="bulk-button"
                            type="button"
                            onClick={this.openModal}
                          >
                            <i className="icon-bolt" />
                            <p className="quick-order">
                              {intl.get("quick-order-title")}
                            </p>
                          </button>
                        ) : null}
                      </div>
                    )}
                  <div className="login-container">
                    <AppHeaderLoginMain
                      isMobileView={false}
                      permission={availability}
                      onContinueCart={onContinueCart}
                      locationSearchData={locationSearchData}
                      appHeaderLoginLinks={appHeaderLoginLinks}
                      appModalLoginLinks={appModalLoginLinks}
                      fetchCartData={this.fetchCartData}
                      isLoggedIn={isLoggedIn}
                      history={history}
                    />
                  </div>
                </div>
              ) : null}
              {!detectIEBrowser() ? (
                <BulkOrderMain
                  isBulkModalOpened={isBulkModalOpened}
                  handleClose={this.handleBulkModalClose}
                  history={history}
                  auth={auth}
                />
              ) : null}
            </div>
            {!detectIEBrowser() ? (
              <div className="secondary-container">
                <AppHeaderSearchMain
                  isMobileView
                  isFocused={isSearchFocused}
                  brSuggest={brSuggest}
                  history={history}
                  isLoggedIn={isLoggedIn}
                />
              </div>
            ) : null}
          </div>

          {!isDesktop && (
            <div className="collapsable-container collapse collapsed">
              {/* <div className="search-container">
            {Config.bloomreachSearch.enable ? (
              <BloomreachAppHeaderSearchMain
                isMobileView
                isFocused={isSearchFocused}
                onSearchPage={onSearchPage}
              />
            ) : (
              <AppHeaderSearchMain
                isMobileView
                isFocused={isSearchFocused}
                onSearchPage={onSearchPage}
              />
            )}
          </div> */}

              {isLoggedIn && !detectIEBrowser() ? (
                <div className="mobile-user-information-container">
                  <p>
                    {intl.get("welcome")}, {givenName}
                  </p>
                  {company && customerNumber ? (
                    <p>{`${company} - ${customerNumber}`}</p>
                  ) : null}

                  {shouldDisplayJobDropdown ? <JobDropdownComponent /> : null}
                  {Config.showClientInformation &&
                    clientList &&
                    clientList.length &&
                    defaultCart && <ClientDropdownComponent />}

                  {Config.branchDropDownLocation === "header" &&
                  selectedBranch ? (
                    <BranchDropdownComponent
                      selectedBranch={selectedBranch.code}
                      isMobile
                    />
                  ) : null}
                </div>
              ) : null}
              {!detectIEBrowser() && userProfile ? (
                <div className="mobile-locale-container">
                  <AppHeaderLocaleMain
                    isMobileView
                    onCurrencyChange={onCurrencyChange}
                    onLocaleChange={onLocaleChange}
                    userProfile={userProfile}
                    isLoggedIn={isLoggedIn}
                  />
                </div>
              ) : null}

              {/* {(!Config.b2b.enable || (Config.b2b.enable && availability)) && (
            <div className="mobile-cart-link-container">
              <Link className="cart-link" to={appHeaderLinks.myCart}>
                <div
                  data-toggle="collapse"
                  data-target=".collapsable-container"
                >
                  {intl.get("shopping-cart-nav")}
                  <div className="cart-link-counter-container">
                    {cartData &&
                      totalQuantity !== 0 &&
                      !isLoading &&
                      !multiCartData && (
                        <span className="cart-link-counter">
                          {totalQuantity}
                        </span>
                      )}
                  </div>
                </div>
              </Link>
            </div>
          )} */}

              {/* <hr className="mobile-navigation-separator" /> */}

              {!detectIEBrowser() && (
                <div className="mobile-navigation-container">
                  {!isDesktop && cortexNavigations ? (
                    <AppHeaderNavigationMain
                      isOfflineCheck={this.handleIsOffline}
                      isMobileView
                      appHeaderNavigationLinks={appHeaderNavigationLinks}
                      cortexNavigations={cortexNavigations}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}
              {/* <hr className="mobile-navigation-separator" />
          <div className="mobile-login-container">
            <AppHeaderLoginMain isMobileView />
          </div> */}
            </div>
          )}

          {isOffline && !detectIEBrowser() ? (
            <div className="network-offline alert alert-primary fade in">
              <strong className="text-center">
                {intl.get("network-offline")}
              </strong>
            </div>
          ) : (
            ""
          )}

          <div className="central-container container-fluid">
            <div className="horizontal-menu container">
              {!detectIEBrowser() ? (
                <RenderCmsComponent
                  path="menu"
                  renderComponent={MenuEditButton}
                />
              ) : (
                <div>.</div>
              )}
              {isDesktop && cortexNavigations && !detectIEBrowser() ? (
                <AppHeaderNavigationMain
                  isOfflineCheck={this.handleIsOffline}
                  isOffline={isOffline}
                  isMobileView={false}
                  appHeaderNavigationLinks={appHeaderNavigationLinks}
                  cortexNavigations={cortexNavigations}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          {isLoggedIn &&
            (userProfile.hasError ||
              cartError ||
              branchError ||
              contractError) && (
              <Redirect
                to={{
                  pathname: "/maintenance",
                  state: {
                    error: {
                      e: {
                        message:
                          userProfile.hasError || cartError || branchError
                      },
                      errIn: "AppHeaderMain.tsx"
                    }
                  }
                }}
              />
            )}
          {isLoggedIn && branchesErrorCode && branchesErrorMessage && (
            <Redirect
              to={{
                pathname: "/maintenance",
                state: {
                  error: {
                    e: {
                      message: branchesErrorMessage
                    },
                    errIn: "AppHeaderMain.tsx",
                    errorCode: branchesErrorCode
                  }
                }
              }}
            />
          )}
        </header>
      </HelmetProvider>
    );
  }
}

export default withRouter(AppHeaderMain);
