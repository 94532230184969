import React, { useEffect, useState, useLayoutEffect } from "react";
import jsonpointer from "jsonpointer";
import {
  createLink,
  getImageUrl,
  parseAndRewriteLinks,
  getNestedObject,
  CmsEditButton
} from "bloomreach-experience-react-sdk";
import $ from "jquery";

import "./generic-list.less";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const GenericList = props => {
  const { configuration, pageModel, preview } = props;
  const [width, height] = useWindowSize();

  const data = getNestedObject(configuration, ["models", "pageable", "items"]);
  const params = getNestedObject(configuration, ["_meta", "paramsInfo"]);
  const refs = data.map(item => item.$ref);
  const contents = refs.map(ref => jsonpointer.get(pageModel, ref));
  const originalContents = refs.map(ref => jsonpointer.get(pageModel, ref));

  const mobileWidth = 768;
  const tabletWidth = 1024;

  const chunks = [];
  let chunkSize = 4; // Full size browser items per page
  if (width <= mobileWidth) {
    chunkSize = 1; // Mobile
  } else if (width <= tabletWidth) {
    chunkSize = 3; // Tablet
  }

  while (contents.length) {
    chunks.push(contents.splice(0, chunkSize));
  }

  const editButton = preview ? (
    <CmsEditButton configuration={configuration} preview={preview} />
  ) : null;

  const renderCarouselControls = () => {
    return (
      <>
        <a
          className="carousel-control-prev"
          href={`#carousel-title-${configuration.id}`}
          role="button"
          aria-label="previous slide"
          data-slide="prev"
        >
          <svg
            className="bi bi-chevron-left"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M13.354 3.646a.5.5 0 010 .708L7.707 10l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z"
              clipRule="evenodd"
            />
          </svg>
        </a>
        <a
          className="carousel-control-next"
          href={`#carousel-title-${configuration.id}`}
          role="button"
          aria-label="next slide"
          data-slide="next"
        >
          <svg
            className="bi bi-chevron-right"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z"
              clipRule="evenodd"
            />
          </svg>
        </a>
      </>
    );
  };

  const renderItems = () => {
    return chunks.map((items, index) => {
      return (
        <div
          className={`carousel-item ${index === 0 ? "active" : ""}`}
          key={items[0].id}
        >
          {items.map(item => {
            return (
              <div className="list-item" key={item.id}>
                <h3>{item.title}</h3>
                {item.content && item.content.value && (
                  <div>{parseAndRewriteLinks(item.content.value, preview)}</div>
                )}
              </div>
            );
          })}
        </div>
      );
    });
  };

  const renderEditButtons = () => {
    return originalContents.map((content, index) => {
      return (
        <div className="p-2">
          <span>{content.title}</span>
          <CmsEditButton configuration={content} preview={preview} />
        </div>
      );
    });
  };

  return (
    <>
      <div className="generic-list-container">
        <div className="container-fluid">
          <div className="container">
            {preview && (
              <div className="br-edit-menu">
                <div className="title">
                  <h2>Content Edit Menu</h2>
                  <small>This will not appear on the website</small>
                </div>
                <div className="menu d-flex">{renderEditButtons()}</div>
              </div>
            )}
            <div
              id={`carousel-title-${configuration.id}`}
              className="carousel row"
            >
              <div className="carousel-inner">{renderItems()}</div>
              {params.showPagination && renderCarouselControls()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenericList;
