import React, { useState } from "react";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { getImageUrl } from "bloomreach-experience-react-sdk";
import "../appfooter.main.less";

const AppFooterLogo = props => {
  const { content, manageContentButton, preview, pageModel } = props;
  const [columnVisible, setColumnVisibility] = useState(true);
  // const image = getImageUrl(content.image, pageModel, preview);

  if (!content || !pageModel) {
    return null;
  }
  function createMarkup() {
    return { __html: DOMPurify.sanitize(content.body.value) };
  }

  return (
    <>
      <div className="title-container d-none d-md-block">{content.title}</div>
      <div
        className="content-container d-none d-md-block"
        dangerouslySetInnerHTML={createMarkup()}
      />
      <div className="title-container-mobile d-flex d-md-none align-items-center">
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-between">
              <span>{content.title}</span>
              <button
                aria-label="show footer brand description contents"
                type="button"
                className="expand-menu"
                onClick={() => setColumnVisibility(!columnVisible)}
              >
                {columnVisible ? (
                  <i className="icon-minus" />
                ) : (
                  <i className="icon-plus" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {columnVisible ? (
        <div className="content-container-mobile d-block d-md-none">
          <div className="container">
            <div className="row">
              <div className="col" dangerouslySetInnerHTML={createMarkup()} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AppFooterLogo;
