/* eslint-disable no-nested-ternary */
/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import {
  AppFooterMain,
  AppHeaderMain,
  AppFooterLogo,
  ChatComponent,
  FacebookChat,
  HeaderLogo,
  HeaderBannerTicker,
  Messagecontainer,
  SimpleContent,
  getSelectedLocaleValue,
  Banner,
  Carousel,
  Video,
  GenericList,
  EnhancedBanner,
  EnhancedList,
  setSelectedLocaleValue,
  BlogList,
  Facets,
  AppFooterHorizontal,
  Grid,
  QuickServices,
  RichText,
  SearchComponent,
  CTA
} from "@zilker/store-components";
import {
  CmsPage,
  RenderCmsComponent,
  getApiUrl
} from "bloomreach-experience-react-sdk";
import React, { useContext } from "react";
import intl from "react-intl-universal";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import packageJson from "../package.json";
import "./App.less";
import AboutUsPage from "./containers/AboutUsPage";
import AccountPage from "./containers/AccountPage";
import AccountStatementPage from "./containers/AccountStatementPage";
import AddPaymentMethod from "./containers/AddPaymentMethod";
import CartPage from "./containers/CartPage";
import CategoryPage from "./containers/CategoryPage";
import ChangePasswordForm from "./containers/ChangePasswordPage";
import CheckoutAuthPage from "./containers/CheckoutAuthPage";
import CheckoutPage from "./containers/CheckoutPage";
import ContactUsPage from "./containers/ContactUsPage";
import HomePage from "./containers/HomePage";
import ErrorPage from "./containers/ErrorPage";
import OrderHistoryPage from "./containers/OrderHistoryPage";
import OrderReviewPage from "./containers/OrderReviewPage";
import ProductDetailPage from "./containers/ProductDetailPage";
import ProductsComparePage from "./containers/ProductsComparePage";
import ProfilePage from "./containers/ProfilePage";
import PurchaseReceiptPage from "./containers/PurchaseReceiptPage";
import RegistrationPage from "./containers/RegistrationPage";
// import ResetPasswordForm from "./containers/ResetPasswordPage";
import SearchResultsPage from "./containers/SearchResultsPage";
import ShippingReturnsPage from "./containers/ShippingReturns";
import TermsAndConditionsPage from "./containers/TermsAndConditionsPage";
import WishListsPage from "./containers/WishListsPage";
import WriteReview from "./containers/WriteReviewPage";
import { LoginRedirectPage } from "./containers/LoginRedirectPage";
import Config from "./ep.config.json";
import OrderHistoryDetails from "./containers/OrderHistoryDetails";
import BranchSettings from "./containers/BranchSettings";
import AccountMain from "./containers/b2b/AccountMain";
import * as LoginService from "./services/LoginService";
import { brSuggest } from "./services/SearchService";
import MainContextProvider, { MainContext } from "./contexts/MainContext";
import MatchUpPage from "./containers/MatchUpPage";
import SessionExpiredPage from "./containers/SessionExpiredPage";
import detectIEBrowser from "./validation/detectIEBrowser";
import WarrantyLookupPage from "./containers/WarrantyLookupPage";
import PartsFinderPage from "./containers/PartsFinderPage";
import ContractOrdersPage from "./containers/ContractOrdersPage";
import SystemBuilder from "./containers/SystemBuilder";
import LoginLandingPage from "./containers/LoginLandingPage";
import ProsExitPage from "./containers/ProsExitPage";
import DocumentsLibrary from "./containers/DocumentsLibrary";

declare global {
  interface Window {
    fbAsyncInit: any;
    dataLayer: any;
    zE: any;
  }
}

// eslint-disable-next-line
declare var FB: any;

function handleCurrencyChange() {
  window.location.reload();
}

function handleLocaleChange() {
  window.location.reload();
}

function handleContinueCart(history) {
  history.push("/");
}

function handleFbAsyncInit() {
  // eslint-disable-next-line
  window.fbAsyncInit = function() {
    // eslint-disable-next-line
    FB.init({
      appId: Config.facebook.applicationId,
      status: true,
      xfbml: true,
      version: "v2.10"
    });
  };
}

const locationData = window.location.search;
// @ts-ignore: Property 'standalone' does not exist on type 'Navigator'.
const isInStandaloneMode = window.navigator.standalone;
const appFooterLinks = {
  aboutus: "/aboutus",
  contactus: "/contactus",
  shippingreturns: "/shippingreturns",
  termsandconditions: "/termsandconditions",
  shareFacebook: "/",
  shareTwitter: "/",
  shareInstagram: "/"
};
const appHeaderLinks = {
  mainPage: "/",
  myCart: "/mycart"
};
const appHeaderLoginLinks = {
  profile: "/myAccount/profile",
  myOrders: "/myAccount/orderHistory",
  wishlists: "/wishlists",
  myContracts: "/myAccount/contractOrders"
};
const appHeaderNavigationLinks = {
  categories: "/category/",
  subCategories: "/category/",
  warrantyLookup: "/warrantyLookup"
};
const appHeaderTopLinks = {
  shippingreturns: "/shippingreturns",
  aboutus: "/aboutus",
  contactus: "/contactus"
};
const appModalLoginLinks = {
  registration: "/registration"
};

const BR_ORIGIN = new URL(Config.brXM.origin);
const BR_CONTEXT_PATH = Config.brXM.contextPath;
let BR_CHANNEL_PATH = Config.brXM.defaultChannel;

// hostname and URL-path are used for detecting if site is viewed in CMS preview
// and for fetching Page Model for the viewed page
let previewLocale = "";
if (window.location.pathname.indexOf("_cmsinternal") > 0) {
  const paths = window.location.pathname.split("/");
  previewLocale = paths[paths.findIndex(e => e === "_cmsinternal") + 1];
} else {
  previewLocale = "NOTINTERNAL";
}
let locale = getSelectedLocaleValue(Config);
const channel = Config.brXM.channels.filter(l => l.language === locale);
const previewChannel = Config.brXM.channels.filter(
  l => l.value === previewLocale
);

if (previewChannel[0] && previewChannel[0].language) {
  locale = previewChannel[0].language;
  setSelectedLocaleValue(locale, Config);
}

if (previewChannel.length === 1) {
  BR_CHANNEL_PATH = previewChannel[0].value;
} else if (channel.length === 1) {
  BR_CHANNEL_PATH = channel[0].value;
}

const urlConfig = {
  scheme: BR_ORIGIN.protocol.slice(0, -1),
  hostname: BR_ORIGIN.hostname,
  port: BR_ORIGIN.port,
  contextPath: BR_CONTEXT_PATH,
  channelPath: BR_CHANNEL_PATH
};

const cmsUrls = {
  preview: urlConfig,
  live: urlConfig
};

const componentDefinitions = {
  "Home Page": { component: HomePage },
  "My Account Page": { component: AccountPage },
  "About Us": { component: AboutUsPage },
  "Branch Settings": { component: BranchSettings },
  "Account Main": { component: AccountMain },
  "Account Statement": { component: AccountStatementPage },
  "Contact Us": { component: ContactUsPage },
  "Cart Page": { component: CartPage },
  "Change Password": { component: ChangePasswordForm },
  Checkout: { component: CheckoutPage },
  "Add A Payment": { component: AddPaymentMethod },
  "My Cart": { component: CartPage },
  "Order History": { component: OrderHistoryPage },
  "Order Details": { component: OrderHistoryDetails },
  "Order Review": { component: OrderReviewPage },
  // "Password Reset": { component: ResetPasswordForm },
  "Products Compare": { component: ProductsComparePage },
  Profile: { component: ProfilePage },
  "Purchase Reciept": { component: PurchaseReceiptPage },
  Registration: { component: RegistrationPage },
  Search: { component: SearchResultsPage },
  "Sign In": { component: CheckoutAuthPage },
  "Shipping Returns": { component: ShippingReturnsPage },
  "Terms And Conditions": { component: TermsAndConditionsPage },
  Wishlists: { component: WishListsPage },
  "Write a review": { component: WriteReview },
  Maintenance: { component: ErrorPage },
  Category: { component: CategoryPage },
  "Product Details": { component: ProductDetailPage },
  "Header Logo": { component: HeaderLogo, wrapInContentComponent: true },
  "Footer Logo": { component: AppFooterLogo, wrapInContentComponent: true },
  "Header Banner Ticker": {
    component: HeaderBannerTicker,
    wrapInContentComponent: true
  },
  "Simple Content": { component: SimpleContent, wrapInContentComponent: true },
  Banner: { component: Banner, wrapInContentComponent: true },
  Carousel: { component: Carousel },
  Video: { component: Video, wrapInContentComponent: false },
  "Generic List": { component: GenericList, wrapInContentComponent: false },
  "Enhanced Banner": {
    component: EnhancedBanner,
    wrapInContentComponent: false
  },
  "Enhanced List": {
    component: EnhancedList,
    wrapInContentComponent: false
  },
  "Blog List": {
    component: BlogList,
    wrapInContentComponent: false
  },
  "Blog Posts by Author": {
    component: BlogList,
    wrapInContentComponent: false
  },
  Facets: {
    component: Facets,
    wrapInContentComponent: false
  },
  "Match up": { component: MatchUpPage },
  "Session Expired": { component: SessionExpiredPage },
  "Warranty Lookup": { component: WarrantyLookupPage },
  "Parts Finder": { component: PartsFinderPage },
  "Contract Orders": { component: ContractOrdersPage },
  Grid: { component: Grid },
  "Quick Services": { component: QuickServices },
  "System Finder": { component: SystemBuilder },
  "Documents Library": { component: DocumentsLibrary },
  "Rich Text": { component: RichText },
  "Search Component": { component: SearchComponent },
  CTA: { component: CTA }
};

const createLink = (href, linkText, className) => (
  <Link className={className} to={href}>
    {linkText()}
  </Link>
);

const VersionContainer = props => {
  const { appVersion, componentsVersion } = props;
  return (
    <div className="version" style={{ display: "none" }}>
      <span>{`${intl.get("app-version")}: ${appVersion}`}</span>
      <span>{`${intl.get("components-version")}: ${componentsVersion}`}</span>
    </div>
  );
};

const CMS = props => {
  // DGE-4202 Encoding double quotes to work around a bug in brXM.
  // The cloud version of brXM rejects urls that contain double quotes,
  // both url-encoded and not due to a bug in ingress. The below logic can be
  // removed once the issue is fixed on the brXM side.
  const pathDoubleQuoteEscaped = window.location.pathname.replace(
    "%22",
    "%26quot%3B"
  );
  const searchDoubleQuoteEscaped = window.location.search.replace(
    "%22",
    "%26quot%3B"
  );
  const request = {
    hostname: window.location.hostname,
    path: pathDoubleQuoteEscaped + searchDoubleQuoteEscaped
  };
  const { history } = props;

  const context = useContext<{ auth: any }>(MainContext);
  const {
    auth: { setLogin, setGuestLoggedIn }
  } = context;
  return (
    <CmsPage
      debug
      componentDefinitions={componentDefinitions}
      cmsUrls={cmsUrls}
      request={request}
      createLink={createLink}
    >
      <AppHeaderMain
        onCurrencyChange={handleCurrencyChange}
        onLocaleChange={handleLocaleChange}
        onContinueCart={() => {
          setLogin(true);
          setGuestLoggedIn(false);
          handleContinueCart(history);
        }}
        locationSearchData={locationData}
        isInStandaloneMode={isInStandaloneMode}
        appHeaderLinks={appHeaderLinks}
        appHeaderLoginLinks={appHeaderLoginLinks}
        appHeaderNavigationLinks={appHeaderNavigationLinks}
        appHeaderTopLinks={appHeaderTopLinks}
        appModalLoginLinks={appModalLoginLinks}
        brSuggest={brSuggest}
      />
      {/* <Messagecontainer message={error} closeContainerHandler={()=>{}}/> */}
      <div className="main-body-container d-flex flex-column" role="main">
        <RenderCmsComponent path="main" />
      </div>
      {Config.footer === "default-footer" && (
        <AppFooterMain key="app-footer" appFooterLinks={appFooterLinks} />
      )}
      {Config.footer === "horizontal-footer" && (
        <RenderCmsComponent
          path="footer-menu"
          renderComponent={AppFooterHorizontal}
        />
      )}
    </CmsPage>
  );
};
const Root = props => {
  const { componentsData } = props;

  function isPrivateComponent(): any {
    return LoginService.isLoggedIn() ? CMS : redirectToHomePage();
  }

  function redirectToHomePage(): any {
    return () => <Redirect to="/" />;
  }

  const context = useContext<{ auth: any }>(MainContext);
  const {
    auth: { guestTokenError }
  } = context;

  return [
    <VersionContainer
      key="version-container"
      componentsVersion={componentsData.version}
      appVersion={packageJson.version}
    />,
    <FacebookChat
      key="facebook-chat"
      config={Config.facebook}
      handleFbAsyncInit={handleFbAsyncInit}
    />,
    <div
      key="app-content"
      className={detectIEBrowser() ? "ie-app-content" : "app-content"}
    >
      {detectIEBrowser() ? (
        <>
          <Route path="/maintenance" component={CMS} />
          <Redirect
            to={{
              pathname: "/maintenance",
              state: {
                error: {
                  e: { message: "IE browser" },
                  errIn: "IE browser",
                  renderIEMessage: true
                }
              }
            }}
          />
        </>
      ) : guestTokenError ? (
        <>
          <Route path="/maintenance" component={CMS} />
          <Redirect to="/maintenance" />
        </>
      ) : (
        <Switch>
          <Route
            path="/myAccount/:subPage?/:detail?/:zoom(.*)?"
            component={isPrivateComponent()}
          />
          <Route
            path="/checkout/:kinpayPrid?"
            component={isPrivateComponent()}
          />
          <Route path="/search/:keywords" component={CMS} />
          <Route path="/itemdetail/:productId" component={CMS} />
          <Route path="/category/:keywords" component={CMS} />
          <Route path="/mycart" component={isPrivateComponent()} />
          {/* <Route path="/password_reset" component={CMS} /> */}
          <Route path="/warrantyLookup" component={isPrivateComponent()} />
          <Route
            path="/partsFinder"
            component={
              Config.partsFinderPageDisplay ? CMS : redirectToHomePage()
            }
          />
          <Route
            path="/systemBuilder"
            component={
              Config.cortexApi.scope !== "motili"
                ? isPrivateComponent()
                : redirectToHomePage()
            }
          />
          <Route
            path="/documentsLibrary"
            component={
              Config.documentsLibraryPageDisplay ? CMS : redirectToHomePage()
            }
          />
          <Route path="/" component={CMS} exact />
          <Route path="/(.*)" component={CMS} />
          <Redirect to="/" />
        </Switch>
      )}
    </div>,
    <ChatComponent key="chat-component" />
  ];
};

const App = withRouter<any, any>(Root);

const AppWithRouter = props => {
  const { componentsData } = props;
  return (
    <Router>
      <MainContextProvider>
        <Switch>
          <Route path="/loggedin" exact component={LoginRedirectPage} />
          <Route path="/login" exact component={LoginLandingPage} />
          <Route path="/prosexit/:url" component={ProsExitPage} />
          <Route
            path="/"
            exact={false}
            render={passedProps => (
              <App {...passedProps} componentsData={componentsData} />
            )}
          />
        </Switch>
      </MainContextProvider>
    </Router>
  );
};
export default AppWithRouter;
