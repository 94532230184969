/* eslint-disable react/no-unused-state */
import React from "react";
import intl from "react-intl-universal";
import { Redirect } from "react-router-dom";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import {
  BreadCrumbsComponent,
  logSearchTermResultPageThroughSegment
} from "@zilker/store-components";
import { zoomCategoryItems } from "@zilker/store-components/src/static/zoom";
import {
  checkEntitlementSku,
  getAvailability
} from "@elasticpath/ref-store/src/services/connectServices";
import { addToCart } from "@elasticpath/ref-store/src/services/EpServices";
import {
  navigationLookup,
  cortexFetchNavigationLookupForm
} from "../utils/CortexLookup";
import { cortexFetch } from "../utils/Cortex";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import SearchFacetNavigationMain from "../SearchFacetNavigation/searchfacetnavigation.main";
import FeaturedProducts from "../FeaturedProducts/featuredproducts.main";
import ProductListPagination from "../ProductListPagination/productlistpagination.main";
import ProductListLoadMore from "../ProductListLoadmore/productlistloadmore";
import {
  getProductPrice,
  generateImageUrl,
  ProductPriceRequest
} from "../../../app/src/utils/mappings/productDetails";

import "./categoryitems.main.less";
import SortProductMenu from "../SortProductMenu/sortproductmenu.main";
import { MainContext } from "../../../app/src/contexts/MainContext";
import SearchResultsItem from "../SearchResultsPage/search.results.item";

let Config: IEpConfig | any = {};

interface CategoryItemsMainProps {
  categoryProps: {
    [key: string]: any;
  };
  onProductFacetSelection?: (...args: any[]) => any;
  productLinks?: {
    [key: string]: any;
  };
  match: any;
  history: any;
}
interface CategoryItemsMainState {
  isLoading: boolean;
  categoryModel: any;
  loadSortedProduct: boolean;
  categoryModelDisplayName: any;
  categoryModelParentDisplayName: any;
  categoryModelId: any;
  productPrices: any;
  productsWithDetails: any;
  errorMessage: any;
  addCartButtonIds: number[];
  hasUserProfileLoaded: any;
  scrollToTop: boolean;
}
class CategoryItemsMain extends React.Component<
  CategoryItemsMainProps,
  CategoryItemsMainState
> {
  _isMounted = false;

  static contextType = MainContext;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    this.state = {
      isLoading: true,
      categoryModel: { links: [] },
      loadSortedProduct: false,
      categoryModelDisplayName: undefined,
      categoryModelParentDisplayName: undefined,
      categoryModelId: undefined,
      productPrices: null,
      productsWithDetails: null,
      errorMessage: null,
      addCartButtonIds: [],
      hasUserProfileLoaded: false,
      scrollToTop: false
    };

    this.handleProductsChange = this.handleProductsChange.bind(this);
    this.handleSortSelection = this.handleSortSelection.bind(this);
    this.handleFacetSelection = this.handleFacetSelection.bind(this);
    this.fetchProductPrices = this.fetchProductPrices.bind(this);
    this.validateEntitlementAndAvailability = this.validateEntitlementAndAvailability.bind(
      this
    );
    this.onAddToCart = this.onAddToCart.bind(this);
    this.toggleScrollVisibility = this.toggleScrollVisibility.bind(this);
  }

  componentDidMount() {
    const {
      auth: { isLoggedIn },
      user: { userProfile },
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;
    this._isMounted = true;
    if (isLoggedIn) {
      const { customerNumber } = userProfile;
      const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
      const hasUserProfileLoaded = !!(customerNumber && selectedBranch);

      this.setState({ hasUserProfileLoaded });
    }

    const { match } = this.props;
    this.getCategoryData(match);
    window.addEventListener("scroll", this.toggleScrollVisibility);
  }

  componentDidUpdate(prevProps, prevState) {
    const { match: prevMatch } = prevProps;
    const {
      match,
      history: { location }
    } = this.props;
    const { categoryModel } = this.state;
    const { hasUserProfileLoaded } = prevState;

    const navClickOrigin = location.state ? location.state.navClick : false;

    this.handleContextPopulated(hasUserProfileLoaded, categoryModel);

    if (
      JSON.stringify(match) !== JSON.stringify(prevMatch) &&
      match.url !== "/maintenance" &&
      match.url !== "/matchup" &&
      navClickOrigin
    ) {
      this.getCategoryData(match);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.toggleScrollVisibility);
  }

  toggleScrollVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        scrollToTop: true
      });
    } else {
      this.setState({
        scrollToTop: false
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  handleContextPopulated(hasUserProfileLoaded, categoryModel) {
    const {
      user: { userProfile },
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;

    const { customerNumber } = userProfile;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    const hasProfilePopulated = !!(customerNumber && selectedBranch);

    if (hasProfilePopulated !== hasUserProfileLoaded) {
      this.setState({ hasUserProfileLoaded: hasProfilePopulated });
      this.fetchProductPrices(categoryModel);
    }
  }

  getCategoryData(match) {
    const {
      context: {
        auth: { logout }
      },
      props: { history }
    } = this;

    if (this._isMounted) {
      this.setState({ isLoading: true });
    }

    let categoryId = match.params;
    let categoryUrl = "";
    if (!categoryId["0"] || categoryId["0"] === undefined) {
      categoryId = match.params.id;
    } else {
      categoryId = match.params.id;
      categoryUrl = match.params["0"];
    }
    const offerSearchUrl = history.location.pathname
      .split("/")
      .slice(3, history.location.pathname.split("/").length)
      .join("/");

    if (offerSearchUrl) {
      categoryUrl = `/${offerSearchUrl}`;
    }

    cortexFetchNavigationLookupForm()
      // eslint-disable-next-line consistent-return
      .then(() => {
        if (categoryId) {
          return navigationLookup(categoryId)
            .then((res: any) => {
              this.fetchProductPrices(res);

              if (this._isMounted) {
                const productList = this.generateProductList(res);
                try {
                  const impressionProducts = productList.map(product => {
                    return {
                      id: product._code[0].code,
                      name: product.title,
                      brand: product._definition[0].details.find(
                        detail => detail.name === "brand"
                      )["display-value"],
                      price: ""
                    };
                  });
                  logSearchTermResultPageThroughSegment(
                    "categoryitems",
                    res["display-name"],
                    impressionProducts
                  );
                } catch (error) {
                  console.error(error);
                }
                this.setState({
                  categoryModel: res,
                  categoryModelDisplayName: res["display-name"],
                  categoryModelParentDisplayName: res._parent
                    ? res._parent[0]["display-name"]
                    : "",
                  categoryModelId: categoryId,
                  productsWithDetails: productList
                });
              }
            })
            .catch(e => {
              if (checkTokensExpired(e)) {
                this._isMounted = false;
                logout().catch(err =>
                  pushToMaintenace(history, {
                    e: err,
                    errIn:
                      "Logout => cortexFetchNavigationLookupForm => CartLineItem.tsx"
                  })
                );
              }
              pushToMaintenace(history, {
                e,
                errIn: "cortexFetchNavigationLookupForm => CartLineItem.tsx"
              });
            });
        }
      })
      .then(resNav => {
        if (categoryUrl) {
          navigationLookup(categoryUrl).then(res => {
            const { categoryModel } = this.state;
            const productNode = categoryModel._offers ? "_offers" : "_items";

            this.fetchProductPrices({
              ...categoryModel,
              [productNode]: [res]
            });
            if (this._isMounted) {
              this.setState(prevState => ({
                categoryModel: {
                  ...prevState.categoryModel,
                  [productNode]: [res]
                },
                isLoading: false,
                productsWithDetails: this.generateProductList({
                  ...categoryModel,
                  [productNode]: [res]
                })
              }));
            }
          });
        } else if (this._isMounted) {
          this.setState({
            isLoading: false
          });
        }
        return resNav;
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          this._isMounted = false;
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => cortexFetchNavigationLookupForm => CartLineItem.tsx"
            })
          );
        }
        pushToMaintenace(history, {
          e,
          errIn: "cortexFetchNavigationLookupForm => CartLineItem.tsx"
        });
      });
  }

  // eslint-disable-next-line class-methods-use-this
  generateProductList(res) {
    let products = [];
    if (res) {
      let productList;
      if (res._offers) {
        [productList] = res._offers || [[]];
      } else {
        productList = (res._items && res._items) || res || [[]];
      }
      // const [productList] = res._offers || res._items || [[]];
      // const products = productList._element ? productList._element : productList;
      products =
        (productList && productList._element && productList._element) || [];
    }

    return (
      products &&
      products.map(p => ({
        ...p,
        title: p._definition[0]["display-name"],
        thumb_image: generateImageUrl(p._definition[0].details)
      }))
    );
  }

  validateEntitlementAndAvailability(products) {
    const { productPrices } = this.state;
    const {
      auth: { logout },
      history,
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { findBranch },
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;
    const skus = products.map(product => product._code[0].code).join("|");
    const { selectedBranch } = defaultCart;
    const { latitude, longitude } = findBranch(selectedBranch.code);

    Promise.all([
      checkEntitlementSku(customerNumber, skus, selectedBranch.vendor),
      getAvailability(
        customerNumber,
        selectedBranch.code,
        skus,
        selectedBranch.vendor,
        latitude,
        longitude
      )
    ])
      .then(response => {
        const { data } = response[0];
        const availabilityResponse = response[1].data;
        if (this._isMounted) {
          this.setState({
            productsWithDetails: this.addPriceAndEntitlementAndAvailabilityAndAvailability(
              products,
              data,
              productPrices,
              availabilityResponse
            )
          });
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          this._isMounted = false;
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => validateEntitlementAndAvailability => CartLineItem.tsx"
            })
          );
        }
        pushToMaintenace(history, {
          e,
          errIn: "validateEntitlementAndAvailability => CartLineItem.tsx"
        });
      });
  }

  fetchProductPrices(productData) {
    const { auth, user, cart, job } = this.context;
    const { history } = this.props;
    const products = this.generateProductList(productData);

    const { customerNumber } = user.userProfile;

    const {
      cartDetails: { defaultCart }
    } = cart;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;

    if (auth.isLoggedIn && selectedBranch && customerNumber) {
      if (products.length) {
        const skus = products.map((product: any) => product._code[0].code);
        const body: ProductPriceRequest = {
          customerNumber,
          branchNumber: selectedBranch.code,
          skus
        };

        if (defaultCart.jobNumber || job.persistedJobNumber) {
          body.jobNumber = defaultCart.jobNumber || job.persistedJobNumber;
        }

        getProductPrice(
          body,
          res => {
            if (this._isMounted) {
              this.setState({ productPrices: res.items }, () => {
                this.validateEntitlementAndAvailability(products);
              });
            }
          },
          e =>
            pushToMaintenace(history, {
              e,
              errIn: "getProductPrice => CategoryItemMain.tsx"
            })
        );
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  addPriceAndEntitlementAndAvailabilityAndAvailability(
    items,
    productEntitlements,
    productPrices,
    productAvailability
  ) {
    return items.map(item => {
      const priceDetails =
        productPrices &&
        productPrices.find(product => product.sku === item._code[0].code);

      const productPrice = priceDetails ? priceDetails.total : "";

      const itemWithPrice = { ...item, productPrice };

      const entitlementDetails =
        productEntitlements &&
        productEntitlements.find(p => p.sku === item._code[0].code);

      const entitled = entitlementDetails ? entitlementDetails.entitled : "";

      itemWithPrice.entitled = entitled;

      const itemAvailability = productAvailability
        ? productAvailability.itemAvailability
        : "";

      const availabilityDetails =
        itemAvailability &&
        itemAvailability.find(p => p.sku === item._code[0].code);

      const branchAvailability = availabilityDetails
        ? availabilityDetails.availableAtBranch
        : "";
      const regionAvailability = availabilityDetails
        ? availabilityDetails.availableInResult
        : "";

      itemWithPrice.branchAvailability = branchAvailability;
      itemWithPrice.regionAvailability = regionAvailability;

      return itemWithPrice;
    });
  }

  handleSortSelection(event) {
    const { categoryModel } = this.state;
    const { history } = this.props;
    const {
      context: {
        auth: { logout }
      }
    } = this;
    const selfUri = event.target.value;
    if (this._isMounted) {
      this.setState({
        loadSortedProduct: true
      });
    }

    cortexFetch(
      `${selfUri}?followlocation&zoom=${zoomCategoryItems.sort().join()}`,
      {
        method: "post"
      }
    )
      .then(res => checkResponse(res))
      .then(res => {
        const productNode = categoryModel._offers ? "_offers" : "_items";
        if (this._isMounted) {
          this.setState(prevState => ({
            categoryModel: {
              ...prevState.categoryModel,
              [productNode]: [res._offersearchresult[0]]
            },
            loadSortedProduct: false
          }));
        }
      })
      .catch(e => {
        if (this._isMounted) {
          this.setState({
            loadSortedProduct: false
          });
        }
        if (checkTokensExpired(e)) {
          this._isMounted = false;
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleSortSelection => CartLineItem.tsx"
            })
          );
        }
        pushToMaintenace(history, {
          e,
          errIn: "handleSortSelection => CartLineItem.tsx"
        });
      });
  }

  handleProductsChange(products) {
    const { categoryModel } = this.state;
    const productNode = categoryModel._offers ? "_offers" : "_items";

    this.fetchProductPrices({ ...categoryModel, [productNode]: [products] });
    if (this._isMounted) {
      this.setState(prevState => ({
        categoryModel: {
          ...prevState.categoryModel,
          [productNode]: [products]
        },
        productsWithDetails: this.generateProductList({
          ...categoryModel,
          [productNode]: [products]
        })
      }));
    }
  }

  handleFacetSelection(res) {
    const { categoryModelId } = this.state;
    const { onProductFacetSelection, match } = this.props;
    onProductFacetSelection(res, categoryModelId);
    this.getCategoryData(match);
  }

  onAddToCart(e, item?: any) {
    const { id: pid } = e.target;
    const { history } = this.props;
    const { addCartButtonIds } = this.state;
    const {
      cart: {
        setSuccesCartPopupMessage,
        cartDetails: {
          defaultCart: { addItemsToCart }
        },
        getCartDetails
      },
      auth: { logout }
    } = this.context;

    const orderItems = {
      code: pid,
      quantity: 1
    };

    this.setState({
      addCartButtonIds: addCartButtonIds.concat(pid)
    });

    addToCart(addItemsToCart.self.uri, { items: [orderItems] })
      .then(res => getCartDetails())
      .then(() => {
        // Update pop up message under cart icon
        setSuccesCartPopupMessage(1);

        if (this._isMounted) {
          this.setState(prevState => {
            const { addCartButtonIds: buttonIds } = prevState;
            return {
              isLoading: false,
              addCartButtonIds: buttonIds.filter(buttonId => {
                return buttonId !== pid;
              })
            };
          });
        }
      })
      .catch(err => {
        if (checkTokensExpired(err)) {
          logout().catch(error =>
            pushToMaintenace(history, {
              e: error,
              errIn: "Logout => onAddToCart => CartLineItem.tsx"
            })
          );
        }
        if (this._isMounted) {
          this.setState(
            prevState => {
              const { addCartButtonIds: buttonIds } = prevState;
              return {
                isLoading: false,
                addCartButtonIds: buttonIds.filter(buttonId => {
                  return buttonId !== pid;
                })
              };
            },
            () => {
              if (this._isMounted) {
                pushToMaintenace(history, {
                  e: err,
                  errIn: "onAddToCart => CartLineItem.tsx"
                });
              }
            }
          );
        }
      });
  }

  render() {
    const {
      isLoading,
      categoryModel,
      categoryModelId,
      categoryModelDisplayName,
      loadSortedProduct,
      productsWithDetails,
      errorMessage,
      addCartButtonIds,
      scrollToTop
    } = this.state;

    const { auth } = this.context;

    const { productLinks, history } = this.props;
    let products: { [key: string]: any } = {};
    let productList = "";
    let noProducts = true;
    let featuredOffers = {};
    if (categoryModel._offers) {
      [products] = categoryModel._offers;
      [productList] = categoryModel._offers;
    } else {
      products = categoryModel._items ? categoryModel._items[0] : categoryModel;
      productList = categoryModel._items
        ? categoryModel._items[0]
        : categoryModel;
    }

    if (categoryModel._featuredoffers) {
      [featuredOffers] = categoryModel._featuredoffers;
    }
    const categoryModelIdString = categoryModelId;

    noProducts = !products || !products._element || !products.pagination;

    const crumbs = [
      { param: "home", name: "Home" },
      { param: "nocrumb", name: categoryModelDisplayName }
    ];

    if (isLoading) {
      return <div className="loader" />;
    }

    return (
      <div className="category-items-container">
        <div className="container" data-region="categoryTitleRegion">
          {!noProducts ? (
            <div>
              <BreadCrumbsComponent breadCrumbsMap={crumbs} />
              <div className="top-right">
                <h4 className="category-title bullet">
                  {categoryModelDisplayName}
                </h4>
                <div className="label total-results">
                  <span className="total-results-value">
                    {intl.get("viewing")}
                    &nbsp;
                    {products.pagination["results-on-page"]}
                  </span>
                  &nbsp;
                  <span className="results-displayed-value">
                    {intl.get("of")}
                    &nbsp;
                    {products.pagination.results}
                    &nbsp;
                    {intl.get("products")}
                  </span>
                </div>
              </div>
              <SearchFacetNavigationMain
                productData={products}
                onFacetSelection={this.handleFacetSelection}
                history={history}
                auth={auth}
              />
              {scrollToTop && (
                <button
                  type="button"
                  className="scroll-to-top-button ep-btn"
                  onClick={this.handleScrollToTop}
                >
                  <i className="icon-chevron-up" />
                </button>
              )}
              <div className="products-container">
                <FeaturedProducts
                  productData={featuredOffers}
                  itemDetailLink={productLinks.itemDetail}
                />
                <SortProductMenu
                  handleSortSelection={this.handleSortSelection}
                  categoryModel={categoryModel}
                />
                <ProductListPagination
                  paginationDataProps={products}
                  titleString={categoryModelIdString}
                  isTop
                  productListPaginationLinks={productLinks}
                />
                <div
                  className={`${loadSortedProduct ? "loading-product" : ""}`}
                >
                  <div
                    className={`${
                      loadSortedProduct ? "sort-product-loader" : ""
                    }`}
                  />
                  {/* {auth.isLoggedIn && productPrices ? (
                      <ProductListMain
                        productData={productList}
                        productListLinks={productLinks}
                        productPrices={productPrices}
                      />
                    ) : (
                      <ProductListMain
                        productData={productList}
                        productListLinks={productLinks}
                      />
                    )} */}
                  <div className="product-list-section">
                    {productsWithDetails &&
                      productsWithDetails.map(p => (
                        <div
                          key={JSON.stringify(p._code)}
                          className="single-product-card"
                        >
                          <SearchResultsItem
                            item={{ ...p, pid: p._code[0].code }}
                            onAddToCart={this.onAddToCart}
                            key={p._code[0].code}
                            clickedButtonLoading={addCartButtonIds.includes(
                              p._code[0].code
                            )}
                          />
                        </div>
                      ))}
                  </div>
                </div>
                <ProductListLoadMore
                  dataProps={products}
                  handleDataChange={this.handleProductsChange}
                  onLoadMore={navigationLookup}
                  history={history}
                  auth={auth}
                />
              </div>
            </div>
          ) : (
            <h3 className="view-title">{intl.get("no-products-found")}</h3>
          )}
        </div>
      </div>
    );
  }
}

export default CategoryItemsMain;
