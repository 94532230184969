/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-multi-assign */
import React, { useEffect } from "react";
import { useMainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import intl from "react-intl-universal";

declare const window: any;

const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

interface ZopimChatProps {
  zendeskKey: string;
  handleError?: (...args: any) => any;
}

/**
 * This is component that is responsible for rendering Zendesk Zopim Chat.
 * Please refer to this document for API details:
 * https://api.zopim.com/files/meshim/widget/controllers/LiveChatAPI-js.html
 */
const ZopimChat: React.FC<ZopimChatProps> = ({ zendeskKey, handleError }) => {
  const {
    auth: { isLoggedIn },
    user: { userProfile }
  } = useMainContext();
  const { givenName, email, familyName, customerNumber } = userProfile;

  const setVisitorNameAndEmail = () => {
    if (email && givenName && familyName && customerNumber)
      window.$zopim(function() {
        window.$zopim.livechat.set({
          name: `${givenName} ${familyName} - ${customerNumber}`,
          email
        });
      });
  };

  // On component mount:
  useEffect(() => {
    /** This is the ZenDesk Zopim Chat snippet:
     * <script type="text/javascript">
        window.$zopim||(function(d,s){let z=$zopim=function(c){z.push(c)},$=z.s=
        d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
        push(o)};z=[];z.set.=[];$.async=!0;$.setAttribute("charset","utf-8");
        $.src="https://v2.zopim.com/?<zendeskKey>";z.t=+new Date;$.
        type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script");
      </script>
     */
    if (!window.$zopim && !document.getElementsByClassName("zopim")[0]) {
      const loadZenDesk = (function(document, script, id) {
        return new Promise<void>((resolve, reject) => {
          const zopim: any = (window.$zopim = function(c) {
            zopim._.push(c);
          });
          const $: any = (zopim.s = document.createElement(script));
          const element = document.getElementsByTagName(script)[0];

          zopim.set = function(o) {
            zopim.set._.push(o);
          };

          zopim._ = [];
          zopim.set._ = [];
          $.async = !0;
          $.setAttribute("charset", "utf-8");
          $.src = `https://v2.zopim.com/?${id}`;
          zopim.t = +new Date();
          $.type = "text/javascript";
          element && element.parentNode.insertBefore($, element);

          $.onload = () => {
            resolve();
          };

          $.onerror = error => {
            handleError && handleError(error);
            reject(new Error(intl.get("zopim-chat-error")));
          };
        });
      })(document, "script", zendeskKey);

      loadZenDesk
        .then(() => {
          setVisitorNameAndEmail();
        })
        .catch(error => {
          console.log(error);
          handleError && handleError(error);
        });
    }

    return () => {
      if (!canUseDOM || !window.$zopim) return false;
      delete window.$zopim;
    };
  }, []);

  useEffect(() => {
    setVisitorNameAndEmail();
  }, [isLoggedIn, givenName, email, familyName, customerNumber]);

  return null;
};

export default ZopimChat;
