import { useState, useEffect } from "react";
import sha256 from "crypto-js/sha256";
import { getConfig } from "@zilker/store-components";
import { fetchProfile } from "../services/EpServices";
import * as LoginService from "../services/LoginService";
import {
  checkTokensExpired,
  generateAddressString,
  createNotificationsPhoneList
} from "../utils/helpers";

const initialUserProfile = {
  customerNumber: "",
  customerRoles: "",
  homeBranch: "",
  familyName: "",
  givenName: "",
  phone: "",
  fax: "",
  preferedCurrency: "",
  preferredLocale: "",
  email: "",
  company: "",
  optInSmsAlert: "",
  optInOrderStatusEmail: "",
  optInDeliveryStatusEmail: "",
  businessNumber: "",
  dateOfBirth: "",
  htmlEmail: "",
  poNumberRequired: "",
  taxЕxemptionId: "",
  custAttribute1: "",
  custAttribute2: "",
  addresses: "",
  profileUri: "",
  defaultShippingAddress: "",
  extendedBillingAddress: "",
  defaultShippingAddressString: "",
  chargeCreditCode: "",
  creditCardAllowed: "",
  cashAllowed: "",
  creditLineAllowed: "",
  isEligibleForLoyalty: "",
  subuserHomeBranch: "",
  subuserHomeBranchVendor: "",
  homeBranchVendor: "",
  onlineBillPayLink: "",
  optOutMarketing: "",
  accountUsers: "",
  showPriceOnPickedTicket: "",
  subUserOptOutMarketing: "",
  roles: "",
  subUserRoles: "",
  motiliCompanyId: "",
  isTSMDealer: "",
  hasError: false
};

const TSM_ROLE = "TSM_DEMO";
// eslint-disable-next-line import/prefer-default-export
export const ProfileContext = () => {
  const [userProfile, setProfile] = useState(initialUserProfile);
  const checkLogin = LoginService.isLoggedIn();

  useEffect(() => {
    if (checkLogin) {
      getUserProfile();
    }
  }, [checkLogin]);

  function getUserProfile() {
    fetchUserProfile(setProfile);
  }

  function removeUserProfile() {
    setProfile(initialUserProfile);
  }

  return {
    userProfile,
    getUserProfile,
    removeUserProfile
  };
};

function fetchUserProfile(setProfile, setBranches) {
  let profile;
  const { config } = getConfig();
  fetchProfile()
    .then(res => {
      let addressInfo = {
        locality: "",
        "extended-address": "",
        "postal-code": "",
        region: "",
        "street-address": "",
        "country-name": ""
      };

      let extendedBillingAddress = "";

      let defaultShippingAddress = {
        "country-name": "",
        "extended-address": "",
        locality: "",
        organization: "",
        "phone-number": "",
        "postal-code": "",
        region: "",
        "street-address": "",
        uri: ""
      };

      let defaultShippingAddressString = "";

      const defaultProfile = res._defaultprofile[0];

      const createSmsPhoneList = () => {
        if (defaultProfile["sub-user-email"]) {
          return createNotificationsPhoneList(
            defaultProfile["sub-user-phone"],
            defaultProfile["sms-phone-numbers"]
          );
        }
        return createNotificationsPhoneList(
          defaultProfile.phone,
          defaultProfile["sms-phone-numbers"]
        );
      };

      const smsPhoneNumbersList = createSmsPhoneList();

      if (defaultProfile._addresses[0]._shippingaddresses) {
        // eslint-disable-next-line prefer-destructuring
        defaultShippingAddress =
          defaultProfile._addresses[0]._shippingaddresses[0]._default[0]
            .address;
        defaultShippingAddress.uri =
          defaultProfile._addresses[0]._shippingaddresses[0]._default[0].self.uri;

        defaultShippingAddressString = generateAddressString(
          defaultShippingAddress
        );
      }

      if (defaultProfile._addresses[0]._billingaddresses) {
        addressInfo =
          defaultProfile._addresses[0]._billingaddresses[0]._default[0].address;

        extendedBillingAddress = generateAddressString(addressInfo);
      }

      const email = defaultProfile._emails[0]._element
        ? defaultProfile._emails[0]._element[0].email
        : "";

      if (defaultProfile["job-number-name-array"]) {
        defaultProfile.jobsArray = JSON.parse(
          defaultProfile["job-number-name-array"],
          (key, value) => (value === "[]" ? JSON.parse(value) : value)
        );
      }

      if (defaultProfile["client-list"]) {
        defaultProfile.clientList = JSON.parse(defaultProfile["client-list"]);
      }

      if (
        defaultProfile._accountusers &&
        defaultProfile._accountusers[0] &&
        defaultProfile._accountusers[0]._element
      ) {
        defaultProfile.accountUsers = defaultProfile._accountusers[0]._element.map(
          accountuser => ({
            email: accountuser.email,
            firstName: accountuser["first-name"],
            lastName: accountuser["last-name"]
          })
        );
      }

      const {
        cortexApi: { scope }
      } = config;

      profile = {
        customerNumber: defaultProfile["customer-number"],
        customerRoles: defaultProfile["customer-roles"],
        initialHomeBranch: defaultProfile["home-branch"],
        initialHomeBranchVendor: defaultProfile["home-branch-vendor"],
        initialMotiliHomeBranch: defaultProfile["motili-home-branch"],
        initialMotiliHomeBranchVendor:
          defaultProfile["motili-home-branch-vendor"],
        initialSubUserHomeBranch: defaultProfile["sub-user-home-branch"],
        initialSubUserHomeBranchVendor:
          defaultProfile["sub-user-home-branch-vendor"],
        initialSubUserMotiliHomeBranch:
          defaultProfile["sub-user-motili-home-branch"],
        initialSubUserMotiliHomeBranchVendor:
          defaultProfile["sub-user-motili-home-branch-vendor"],
        homeBranch:
          scope === "motili"
            ? defaultProfile["motili-home-branch"] ||
              defaultProfile["home-branch"]
            : defaultProfile["home-branch"],
        homeBranchVendor:
          scope === "motili"
            ? defaultProfile["motili-home-branch-vendor"] ||
              defaultProfile["home-branch-vendor"]
            : defaultProfile["home-branch-vendor"] || "GOODMAN",
        familyName: defaultProfile["family-name"],
        givenName: defaultProfile["given-name"],
        phone: defaultProfile.phone,
        fax: defaultProfile.fax,
        preferedCurrency: defaultProfile["preferred-currency"],
        preferredLocale: defaultProfile["preferred-locale"],
        email,
        company: defaultProfile.company,
        city: addressInfo.locality,
        postalCode: addressInfo["postal-code"],
        region: addressInfo.region,
        street: addressInfo["street-address"],
        country: addressInfo["country-name"],
        optInSmsAlert: defaultProfile["opt-in-sms-alert"],
        optInOrderStatusEmail: defaultProfile["opt-in-order-status-email"],
        optInDeliveryStatusEmail:
          defaultProfile["opt-in-delivery-status-email"],
        smsPhoneNumbers: smsPhoneNumbersList,
        orderStatusEmails: defaultProfile["order-status-emails"],
        deliveryStatusEmails: defaultProfile["delivery-status-emails"],
        businessNumber: defaultProfile["business-number"],
        dateOfBirth: defaultProfile["date-of-birth"],
        htmlEmail: defaultProfile["html-email"],
        poNumberRequired: defaultProfile["po-number-required"],
        taxЕxemptionId: defaultProfile["tax-exemption-id"],
        custAttribute1: defaultProfile.cust_Attribute1,
        custAttribute2: defaultProfile.cust_Attribute2,
        addresses: defaultProfile._addresses,
        profileUri: defaultProfile.self.uri,
        defaultShippingAddress,
        extendedBillingAddress,
        defaultShippingAddressString,
        jobsArray: defaultProfile.jobsArray,
        subuserFirstName: defaultProfile["sub-user-first-name"],
        subuserLastName: defaultProfile["sub-user-last-name"],
        subuserEmail: defaultProfile["sub-user-email"],
        subuserHomeBranch:
          scope === "motili"
            ? defaultProfile["sub-user-motili-home-branch"] ||
              defaultProfile["sub-user-home-branch"]
            : defaultProfile["sub-user-home-branch"],
        subuserHomeBranchVendor:
          scope === "motili"
            ? defaultProfile["sub-user-motili-home-branch-vendor"] ||
              defaultProfile["sub-user-home-branch-vendor"]
            : defaultProfile["sub-user-home-branch-vendor"],
        subuserPhone: defaultProfile["sub-user-phone"],
        chargeCreditCode: defaultProfile["charge-credit-code"],
        creditCardAllowed: defaultProfile["credit-card-allowed"],
        cashAllowed: defaultProfile["cash-allowed"],
        creditLineAllowed: defaultProfile["credit-line-allowed"],
        jobNameRequired: defaultProfile["job-name-required"],
        jobNumberRequired: defaultProfile["job-number-required"] === "true",
        isCanadianUser: addressInfo["country-name"] === "CA",
        isEligibleForLoyalty: defaultProfile["eligible-for-loyalty"],
        clientList: defaultProfile.clientList,
        onlineBillPayLink:
          addressInfo["country-name"] === "CA"
            ? config.onlineBillingWebsiteCA
            : config.onlineBillingWebsiteUS,
        optOutMarketing: defaultProfile["opt-out-marketing"],
        subUserOptOutMarketing: defaultProfile["sub-user-opt-out-marketing"],
        accountUsers: defaultProfile.accountUsers,
        showPriceOnPickedTicket:
          defaultProfile["show-price-on-picked-ticket"] === "true",
        roles: defaultProfile.roles ? JSON.parse(defaultProfile.roles) : "",
        subUserRoles: defaultProfile["sub-user-roles"]
          ? JSON.parse(defaultProfile["sub-user-roles"])
          : "",
        motiliCompanyId: defaultProfile["motili-company-id"]
      };

      profile.isTSMDealer =
        (profile.roles && profile.roles.includes(TSM_ROLE)) ||
        (profile.subUserRoles && profile.subUserRoles.includes(TSM_ROLE));

      const hashedEmail = (profile.subuserEmail
        ? sha256(profile.subuserEmail)
        : sha256(profile.email)
      ).toString();

      // sends information about logged in user to Segment
      window.analytics.identify(hashedEmail, {
        companyAddress1: profile.street,
        companyAddress2: "",
        companyCity: profile.city,
        companyState: profile.country,
        companyZip: profile.postalCode,
        customerNumber: profile.customerNumber,
        email: profile.subuserEmail ? profile.subuserEmail : profile.email,
        firstName: profile.subuserFirstName
          ? profile.subuserFirstName
          : profile.givenName,
        lastName: profile.subuserLastName
          ? profile.subuserLastName
          : profile.familyName,
        phone: profile.phone
      });
      window.analytics.group(profile.customerNumber, {
        address1: profile.street,
        address2: "",
        city: profile.city,
        state: profile.country,
        zip: profile.postalCode,
        customerNumber: profile.customerNumber,
        companyName: profile.company,
        email: profile.email,
        phone: profile.phone
      });

      setProfile(profile);
    })
    .catch(error => {
      // If tokens are expired delegate session expired handling to
      // appheader.main fetchNavigationData
      if (!checkTokensExpired(error)) {
        profile = initialUserProfile;
        profile.hasError = error.message;
        setProfile(profile);
      }
    });
}
