import React, { useState, useEffect } from "react";
import intl from "react-intl-universal";
import DOMPurify from "dompurify";

import { createDangerousMarkup } from "../../../app/src/utils/helpers";
import { getConfig } from "../utils/ConfigProvider";

import "./ProductDetailTabsComponent.less";

interface ProductDetailTabsComponentProps {
  productData: any;
  currentTab: { name: string; visible: boolean };
  productDocuments: Array<{ assetType: string; url: string }>;
}

const Tabs = {
  SPECIFICATIONS: "specifications",
  WARRANTY: "warranty",
  GENERAL: "general",
  DOCUMENTS: "documents"
};

const ProductDetailTabsComponent = React.forwardRef<
  HTMLDivElement,
  ProductDetailTabsComponentProps
>((props, ref) => {
  const [selectedTab, setSelectedTab] = useState({ name: "", visible: false });
  const { productData, currentTab, productDocuments } = props;

  const { config } = getConfig();

  useEffect(() => {
    setSelectedTab(currentTab);
  }, []);

  useEffect(() => {
    if (currentTab.name !== selectedTab.name) {
      setSelectedTab(currentTab);
    }
  }, [currentTab]);

  const attributesNoOutput = [
    "full_image",
    "long_description",
    "url",
    "warranty",
    "product_features",
    "short_description",
    "thumb_image",
    "branches_available",
    "vendor_number",
    "dimensional_drawing",
    "parts_manual",
    "service_manual",
    "specification_sheet",
    "warranty_certificate",
    "wire_diagram",
    "product_brochure",
    "accessories",
    "submittal_data_sheet"
  ];

  const splitString = givenString => {
    const sentinels = ["\\|", "|", ";", "\\n", "\n"];
    const splitNumberedListRegex = /\d{1,}\.\s/;
    const findAllSemicolonsRegex = /;/g;
    const sentinelRes = sentinels.find(
      sentinel => givenString.split(sentinel).length > 1
    );
    let resultString = givenString;
    const numberedListLength =
      givenString.split(splitNumberedListRegex).length - 1;
    const delimitedListLength = givenString.split(sentinelRes).length;
    if (
      (sentinelRes === ";" || sentinelRes === "|") &&
      numberedListLength !== delimitedListLength
    ) {
      resultString = `* ${givenString}`;
      if (sentinelRes === ";") {
        resultString = resultString.replace(findAllSemicolonsRegex, ";* ");
      }
    }
    return resultString.split(sentinelRes);
  };

  /**
   * ## renderAttributes
   * @description Renders list of Product details under the "General" tab in the PDP.
   */

  const renderAttributes = () => {
    if (productData._definition[0].details) {
      return productData._definition[0].details
        .filter(attribute => {
          return (
            !attributesNoOutput.includes(attribute.name) &&
            attribute["display-value"] &&
            attribute["display-value"] !== ""
          );
        })
        .map(attribute => (
          <ul className="itemdetail-attribute" key={attribute.name}>
            <li className="itemdetail-attribute-label-col">
              {attribute["display-name"]}
            </li>
            <li className="itemdetail-attribute-value-col">
              {attribute["display-value"].startsWith("http") ||
              attribute["display-value"].startsWith("https") ? (
                <a
                  href={attribute["display-value"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {attribute["display-value"]}
                </a>
              ) : (
                <ul key={attribute.name}>
                  {splitString(attribute["display-value"]).map(feature => (
                    <li key={`attribute-${Math.random()}`}>{feature}</li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        ));
    }
    return null;
  };

  const renderDocuments = () => {
    return (
      <div className="product-documents">
        {productDocuments.map(({ assetType, url }) => (
          <a
            className="product-document"
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            key={url}
          >
            <h3>{assetType}</h3>
          </a>
        ))}
      </div>
    );
  };

  /**
   * ## showTab
   *
   * @param e - event object
   *
   * @description Toggles selected Product details tab.
   */
  const showTab = e => {
    let name;
    if (e.target.tagName === "BUTTON") {
      // eslint-disable-next-line prefer-destructuring
      name = e.target.name;
    } else {
      // eslint-disable-next-line prefer-destructuring
      name = e.target.parentElement.name;
    }
    if (selectedTab.name === name) {
      setSelectedTab({ name, visible: !selectedTab.visible });
    } else {
      setSelectedTab({ name, visible: true });
    }
  };

  const onTabClick = name => {
    setSelectedTab({
      name,
      visible: true
    });
  };

  /**
   * ## renderTabsDesktop
   *
   * @description Renders Product details tabs with style for the desktop screen.
   */
  const renderTabsDesktop = () => {
    if (!productData._definition[0].details) {
      return null;
    }
    const warranty = productData._definition[0].details.find(
      item => item.name === "warranty"
    );
    const features = productData._definition[0].details.find(
      item => item.name === "product_features"
    );
    const featureList = features ? splitString(features["display-value"]) : [];
    const warrantyList = warranty ? splitString(warranty["display-value"]) : [];

    return (
      <>
        <div className="tabs">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  !selectedTab.name || selectedTab.name === Tabs.SPECIFICATIONS
                    ? "active"
                    : ""
                }`}
                id="specification-tab"
                data-toggle="tab"
                href="#specifications"
                role="tab"
                aria-controls="specifications"
                aria-selected="true"
                onClick={() => onTabClick(Tabs.SPECIFICATIONS)}
              >
                <h2 className="bullet override-h2-as-h4">
                  {intl.get("specifications")}
                </h2>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  selectedTab.name === Tabs.WARRANTY ? "active" : ""
                }`}
                id="warranty-tab"
                data-toggle="tab"
                href="#warranty"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => onTabClick(Tabs.WARRANTY)}
              >
                <h2 className="bullet override-h2-as-h4">
                  {intl.get("warranty")}
                </h2>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  selectedTab.name === Tabs.GENERAL ? "active" : ""
                }`}
                id="general-tab"
                data-toggle="tab"
                href="#general"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => onTabClick(Tabs.GENERAL)}
              >
                <h2 className="bullet override-h2-as-h4">
                  {intl.get("general")}
                </h2>
              </a>
            </li>
            {config.showDocumentsTab &&
            productDocuments &&
            productDocuments.length ? (
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    selectedTab.name === Tabs.DOCUMENTS ? "active" : ""
                  }`}
                  id="documents-tab"
                  data-toggle="tab"
                  href="#documents"
                  role="tab"
                  aria-controls="documents"
                  aria-selected="true"
                  onClick={() => onTabClick(Tabs.DOCUMENTS)}
                >
                  <h2 className="bullet override-h2-as-h4">
                    {intl.get("documents")}
                  </h2>
                </a>
              </li>
            ) : null}
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                !selectedTab.name || selectedTab.name === Tabs.SPECIFICATIONS
                  ? "show active"
                  : ""
              }`}
              id="specifications"
              role="tabpanel"
              aria-label="specifications"
            >
              {featureList.length > 0 && (
                <ul className="product-features-list">
                  {featureList.map(item => {
                    if (item.startsWith("*")) {
                      return (
                        <li key={`_${Math.random()}`}>
                          <i className="icon-chevron-right" />
                          {item.replace("*", "")}
                        </li>
                      );
                    }
                    return <li key={`_${Math.random()}`}>{item}</li>;
                  })}
                </ul>
              )}
            </div>
            <div
              className={`tab-pane fade ${
                selectedTab.name === Tabs.WARRANTY ? "show active" : ""
              }`}
              id="warranty"
              role="tabpanel"
              aria-label="profile tab"
            >
              {warrantyList.length > 0 && (
                <ul className="product-features-list">
                  {warrantyList.map(item => {
                    if (item.startsWith("*")) {
                      return (
                        <li key={`_${Math.random()}`}>
                          <i className="icon-chevron-right" />
                          {item.replace("*", "")}
                        </li>
                      );
                    }
                    return <li key={`_${Math.random()}`}>{item}</li>;
                  })}
                </ul>
              )}
            </div>
            <div
              className={`tab-pane fade ${
                selectedTab.name === Tabs.GENERAL ? "show active" : ""
              }`}
              id="general"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div
                className="itemdetail-component"
                data-region="itemDetailAttributeRegion"
              >
                <div className="itemdetail-details">{renderAttributes()}</div>
              </div>
            </div>
            {config.showDocumentsTab &&
            productDocuments &&
            productDocuments.length ? (
              <div
                className={`tab-pane fade ${
                  selectedTab.name === Tabs.DOCUMENTS ? "active show" : ""
                }`}
                id="documents"
                role="tabpanel"
                aria-label="documents"
              >
                {renderDocuments()}
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  /**
   * ## renderTabsMobile
   *
   * @description Renders Product details tabs with style for the mobile screens.
   */
  const renderTabsMobile = () => {
    if (!productData._definition[0].details) {
      return null;
    }
    const warranty = productData._definition[0].details.find(
      item => item.name === "warranty"
    );
    const features = productData._definition[0].details.find(
      item => item.name === "product_features"
    );
    const featureList = features ? splitString(features["display-value"]) : [];
    const warrantyList = warranty ? splitString(warranty["display-value"]) : [];

    return (
      <>
        <div className="tabs-mobile">
          <ul className="nav nav-tabs-mobile">
            <li className="nav-item-mobile">
              <button
                type="button"
                className="nav-link-mobile"
                name="specifications"
                onClick={showTab}
              >
                <h4 className="bullet">{intl.get("specifications")}</h4>
                {selectedTab.name === Tabs.SPECIFICATIONS &&
                selectedTab.visible ? (
                  <i className="icon-minus" />
                ) : (
                  <i className="icon-plus" />
                )}
              </button>
            </li>
            {selectedTab.name === Tabs.SPECIFICATIONS && selectedTab.visible ? (
              <div className="tab-content">
                {featureList.length > 0 && (
                  <ul className="product-features-list">
                    {featureList.map(item => {
                      if (item.startsWith("*")) {
                        return (
                          <li key={`_${Math.random()}`}>
                            <i className="icon-chevron-right" />
                            {item.replace("*", "")}
                          </li>
                        );
                      }
                      return <li key={`_${Math.random()}`}>{item}</li>;
                    })}
                  </ul>
                )}
              </div>
            ) : null}
            <li className="nav-item-mobile">
              <button
                type="button"
                className="nav-link-mobile"
                name="warranty"
                onClick={showTab}
              >
                <h4 className="bullet">{intl.get("warranty")}</h4>
                {selectedTab.name === Tabs.WARRANTY && selectedTab.visible ? (
                  <i className="icon-minus" />
                ) : (
                  <i className="icon-plus" />
                )}
              </button>
            </li>
            {selectedTab.name === Tabs.WARRANTY && selectedTab.visible ? (
              <div className="tab-content">
                {warrantyList.length > 0 && (
                  <ul className="product-features-list">
                    {warrantyList.map(item => {
                      if (item.startsWith("*")) {
                        return (
                          <li key={`_${Math.random()}`}>
                            <i className="icon-chevron-right" />
                            {item.replace("*", "")}
                          </li>
                        );
                      }
                      return <li key={`_${Math.random()}`}>{item}</li>;
                    })}
                  </ul>
                )}
              </div>
            ) : null}
            <li className="nav-item-mobile">
              <button
                type="button"
                name="general"
                className="nav-link-mobile"
                onClick={showTab}
              >
                <h4 className="bullet">{intl.get("general")}</h4>
                {selectedTab.name === Tabs.GENERAL && selectedTab.visible ? (
                  <i className="icon-minus" />
                ) : (
                  <i className="icon-plus" />
                )}
              </button>
            </li>
            {selectedTab.name === Tabs.GENERAL && selectedTab.visible ? (
              <div className="tab-content">
                <div className="itemdetail-details">{renderAttributes()}</div>
              </div>
            ) : null}
            {config.showDocumentsTab &&
            productDocuments &&
            productDocuments.length ? (
              <li className="nav-item-mobile">
                <button
                  type="button"
                  className="nav-link-mobile"
                  name="documents"
                  onClick={showTab}
                >
                  <h4 className="bullet">{intl.get("documents")}</h4>
                  {selectedTab.name === Tabs.DOCUMENTS &&
                  selectedTab.visible ? (
                    <i className="icon-minus" />
                  ) : (
                    <i className="icon-plus" />
                  )}
                </button>
              </li>
            ) : null}
            {config.showDocumentsTab &&
            productDocuments &&
            productDocuments.length &&
            selectedTab.name === Tabs.DOCUMENTS &&
            selectedTab.visible ? (
              <div className="tab-content">{renderDocuments()}</div>
            ) : null}
          </ul>
        </div>
      </>
    );
  };

  return (
    <div ref={ref}>
      {renderTabsMobile()}
      {renderTabsDesktop()}
    </div>
  );
});

export default ProductDetailTabsComponent;
