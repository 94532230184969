/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { FC } from "react";
import intl from "react-intl-universal";

import "./Popup.less";

interface PopupProps {
  closePopUp: () => any;
  availabilityLabel: string;
  isCallButtonActive: boolean;
  popupStyle: string;
  page: string;
}

const Popup: FC<PopupProps> = ({
  closePopUp,
  availabilityLabel,
  isCallButtonActive,
  popupStyle,
  page
}) => {
  let popupInfo;

  switch (true) {
    case isCallButtonActive:
      popupInfo = <p>{intl.get("call-branch-to-order-popup-info")}</p>;
      break;
    case availabilityLabel === "Available":
      popupInfo = (
        <p>
          <span>{intl.get("available-label")}: </span>
          {intl.get("available-popup-info")}
        </p>
      );
      break;
    case availabilityLabel === "Limited Availability":
      popupInfo = (
        <p>
          <span>{intl.get("limited")}: </span>
          {intl.get("limited-availability-popup-info")}
        </p>
      );
      break;
    case availabilityLabel === "Available In Your Region":
      popupInfo = (
        <p>
          {`${intl.get("available-in-your-region")}: ${intl.get(
            "available-in-your-region-popup-info"
          )}`}
        </p>
      );
      break;
    case availabilityLabel === "Available In Your Region - Click Here":
      popupInfo = (
        <p>{intl.get("available-in-your-region-click-here-popup-info")}</p>
      );
      break;
    case availabilityLabel === "Limited In Your Region":
      popupInfo = (
        <p>
          {`${intl.get("limited-in-your-region")}: ${intl.get(
            "available-in-your-region-popup-info"
          )}`}
        </p>
      );
      break;
    case availabilityLabel === "Limited In Your Region - Click Here":
      popupInfo = (
        <p>{intl.get("limited-in-your-region-click-here-popup-info")}</p>
      );
      break;
    default:
      popupInfo = <p>{intl.get("not-available-popup-info")}</p>;
  }

  const message =
    page === "pdp" && !isCallButtonActive ? (
      <div className="popup-message">
        <p>
          <span>{intl.get("available-label")}:</span>{" "}
          {intl.get("available-popup-info")}
        </p>
        <p>
          <span>{intl.get("limited")}:</span>{" "}
          {intl.get("limited-availability-popup-info")}
        </p>
        <p>
          <span>{`${intl.get("available-for-back-order-label")}:`}</span>{" "}
          {intl.get("not-available-popup-info")}
        </p>
        <p>
          <span>{`${intl.get("call-branch-to-order")}:`}</span>{" "}
          {intl.get("call-branch-to-order-popup-info")}
        </p>
      </div>
    ) : (
      <div className="popup-message">{popupInfo}</div>
    );

  return (
    <div className={`popup-container ${popupStyle}`}>
      <div className="popup-nav-container">
        <button className="close-btn" type="button" onClick={closePopUp}>
          <i className="icon-close-x" />
        </button>
        <div className="popup-menu">{message}</div>
      </div>
    </div>
  );
};

export default Popup;
