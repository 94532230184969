/* eslint-disable camelcase */
import React, { FC, useState, useContext, useEffect } from "react";
import intl from "react-intl-universal";
import { Link } from "react-router-dom";
import {
  checkTokensExpired,
  pushToMaintenace,
  generateSpecificErrorMessage
} from "../../../app/src/utils/helpers";
import { addToCart } from "../../../app/src/services/EpServices";
import { MainContext } from "../../../app/src/contexts/MainContext";
import {
  PartDetails,
  PartsFinderSearchParams
} from "../../../app/src/containers/PartsFinderPage";
import { productAdded } from "../utils/Segment";
import { getConfig } from "../utils/ConfigProvider";
import missingImage from "../../../app/src/images/img_missing_horizontal@2x.png";

import "./PartsFinderTableItem.less";

interface PartsFinderTableItemProps {
  key: string;
  part: PartDetails;
  price: string;
  entitled: boolean;
  handlePartChecked: (...any) => any;
  updateSelectedQuantity: (...any) => any;
  isMobile: boolean;
  history: any;
  searchParams: PartsFinderSearchParams;
  formatAvailability: (...any) => any;
}

const PartsFinderTableItem: FC<PartsFinderTableItemProps> = ({
  part,
  price,
  entitled,
  handlePartChecked,
  updateSelectedQuantity,
  isMobile,
  history,
  searchParams,
  formatAvailability
}) => {
  const [quantity, setQuantity] = useState<string>("1");
  const [checked, setChecked] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);

  const context = useContext<{ cart: any; auth: any }>(MainContext);
  const { cart, auth } = context;
  const { isLoggedIn, logout } = auth;

  const { config } = getConfig();

  const partAvailability: string = part.exists
    ? formatAvailability(part, quantity)
    : "";

  const isPartInvalid =
    !part.exists ||
    !entitled ||
    (!price || price === "0") ||
    (config.checkAvailability &&
      partAvailability === intl.get("call-for-availability"));

  const disablePartForGuest = !price && !partAvailability;
  useEffect(() => {
    if (checked !== undefined) {
      handlePartChecked(part, quantity, price, checked);
    }
  }, [checked]);

  useEffect(() => {
    if (checked) {
      updateSelectedQuantity(part, quantity);
    }
  }, [quantity]);

  const onCheckboxClick = () => {
    setChecked(!checked);
  };

  const handleQuantityChange = e => {
    const { value } = e.target;
    setQuantity(value);
  };

  const checkQuantity = e => {
    const { value } = e.target;
    if (Number(value) <= 0) {
      setQuantity("1");
    }
  };

  const handleAddToCart = () => {
    const {
      getCartDetails,
      setSuccesCartPopupMessage,
      setErrorCartPopupMessage,
      cartDetails: {
        defaultCart: { addItemsToCart }
      }
    } = cart;
    const items = [{ code: part.pid, quantity: Number(quantity) }];
    setLoading(true);

    addToCart(addItemsToCart.self.uri, { items })
      .then(res => getCartDetails())
      .then(() => {
        // sends information to Segment when user adds a product
        productAdded(
          part.title,
          part.pid,
          !price || price === intl.get("pending") ? 0 : Number(price),
          part.brand,
          part.class,
          Number(quantity)
        );
      })
      .then(() => {
        setLoading(false);
        setSuccesCartPopupMessage(quantity);
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleAddToCart => PartsFinderTableItem.tsx"
            })
          );
        } else {
          console.error(e);
          setLoading(false);
          setErrorCartPopupMessage(generateSpecificErrorMessage(e));
        }
      });
  };

  const renderForDesktop = () => {
    return (
      <div
        className={`part-table-row ${
          disablePartForGuest || (isPartInvalid && isLoggedIn) ? "disabled" : ""
        }`}
      >
        {isLoggedIn && (
          <div className="checkbox">
            <label
              className="part-checkbox-label"
              htmlFor="part-checkbox"
              id="part-checkbox-label"
            >
              <input type="checkbox" id="checkbox" />
              <span
                className={`part-checkbox ${
                  checked ? "show-checkmark" : "hide-checkmark"
                }`}
                onClick={onCheckboxClick}
                onKeyDown={onCheckboxClick}
                role="checkbox"
                aria-checked="false"
                tabIndex={0}
                aria-labelledby="part-checkbox-label"
              />
            </label>
          </div>
        )}
        <div className="sku">
          <Link
            id={part.pid}
            to={{
              pathname: `/itemdetail/${part.pid}`,
              state: { partsFinderSearchParams: searchParams }
            }}
          >
            {part.pid}
          </Link>
        </div>
        <div className="image">
          <img
            src={part.thumb_image || config.missingImagePlaceholderUrl}
            alt={part.title || intl.get("none-available")}
            onError={e => {
              const element: any = e.target;
              element.src = missingImage;
            }}
          />
        </div>
        <div className="description">{part.description}</div>
        {isLoggedIn && (
          <div className="quantity">
            {part.exists && (
              <span className="input-group-btn">
                <div className="quantity-col form-content form-content-quantity">
                  <input
                    className="form-control-quantity"
                    type="number"
                    min="1"
                    max="9999"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={checkQuantity}
                  />
                </div>
              </span>
            )}
          </div>
        )}
        {isLoggedIn && (
          <div className="availability label">{partAvailability}</div>
        )}
        {isLoggedIn && <div className="price">${price}</div>}
        {isLoggedIn && (
          <div className="add-to-cart-button">
            {!loading ? (
              <button
                type="button"
                className="ep-btn"
                onClick={handleAddToCart}
                disabled={isPartInvalid}
              >
                {isPartInvalid ? intl.get("call") : intl.get("add-to-cart")}
              </button>
            ) : (
              <div className="miniLoader" />
            )}
          </div>
        )}
      </div>
    );
  };

  const renderForMobile = () => {
    return (
      <div
        className={`mobile-part-table-row ${
          isPartInvalid && isLoggedIn ? "disabled" : ""
        }`}
      >
        {isLoggedIn && (
          <div className="checkbox">
            <label
              className="part-checkbox-label"
              htmlFor="part-checkbox"
              id="part-checkbox-label"
            >
              <input type="checkbox" id="checkbox" />
              <span
                className={`part-checkbox ${
                  checked ? "show-checkmark" : "hide-checkmark"
                }`}
                onClick={onCheckboxClick}
                onKeyDown={onCheckboxClick}
                role="checkbox"
                aria-checked="false"
                tabIndex={0}
                aria-labelledby="part-checkbox-label"
              />
            </label>
          </div>
        )}
        <div className="part-info">
          <div className="part-image">
            <img
              src={part.thumb_image || config.missingImagePlaceholderUrl}
              alt={part.title || intl.get("none-available")}
              onError={e => {
                const element: any = e.target;
                element.src = missingImage;
              }}
            />
          </div>
          <div className="part-details">
            <p className="sku">
              <Link
                id={part.pid}
                to={{
                  pathname: `/itemdetail/${part.pid}`,
                  state: searchParams
                }}
              >
                {part.pid}
              </Link>
            </p>
            <p>{part.description}</p>
            {isLoggedIn && <p className="label">{partAvailability}</p>}
            {isLoggedIn && (
              <div className="price-and-quantity">
                <p className="price">${price}</p>
                {part.exists && (
                  <div className="quantity">
                    <span className="input-group-btn">
                      <div className="quantity-col form-content form-content-quantity">
                        <input
                          className="form-control-quantity"
                          type="number"
                          min="1"
                          max="9999"
                          value={quantity}
                          onChange={handleQuantityChange}
                          onBlur={checkQuantity}
                        />
                      </div>
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isLoggedIn && (
          <div className="add-to-cart-button">
            {!loading ? (
              <button
                type="button"
                className="ep-btn"
                onClick={handleAddToCart}
                disabled={isPartInvalid}
              >
                {isPartInvalid ? intl.get("call") : intl.get("add-to-cart")}
              </button>
            ) : (
              <div className="miniLoader" />
            )}
          </div>
        )}
      </div>
    );
  };

  return <>{!isMobile ? renderForDesktop() : renderForMobile()}</>;
};

export default PartsFinderTableItem;
