import React, { FC, useState } from "react";

// import "./search.results.menu.item.less";

interface SearchResultsMenuItemProps {
  data: any;
  itemChecked: boolean;
  onFacetClicked: (any) => any;
}

const SearchResultsMenuItem: FC<SearchResultsMenuItemProps> = ({
  data,
  itemChecked,
  onFacetClicked
}) => {
  const [isChecked, setChecked] = useState<boolean>(itemChecked);
  const [classNameSuffix, setClassNameSuffix] = useState<string>("");

  const toggleChecked = (event: any): void => {
    const {
      target: { checked }
    } = event;

    onFacetClicked(event);
    setChecked(checked);
    setClassNameSuffix(checked ? "active" : "");
  };

  const id = `${data.name}-${data.type}`;

  return (
    <div className={`facet-item ${classNameSuffix}`}>
      <input
        id={id}
        type="checkbox"
        name={data.type}
        onChange={toggleChecked}
        value={data.name}
        checked={isChecked}
      />
      <label htmlFor={id}>
        <span className="facet-name">{data.name}</span>
        <span className="facet-number">{`(${data.count})`}</span>
      </label>
    </div>
  );
};

export default SearchResultsMenuItem;
