/* eslint-disable class-methods-use-this */
import React from "react";
import intl from "react-intl-universal";
import { History } from "history";
import {
  OrderItemDetails,
  page,
  productAdded,
  getConfig
} from "@zilker/store-components";
import MessageContainer from "@zilker/store-components/src/MessageContainer/messagecontainer";
import {
  noOp,
  checkTokensExpired,
  simpleToLocaleDate,
  pushToMaintenace,
  isoToSimpleDate,
  openInvoiceReport,
  formatNullString,
  generateSpecificErrorMessage
} from "../utils/helpers";
import { getInvoiceReport, getOrderHistory } from "../services/connectServices";
import { MainContext } from "../contexts/MainContext";
import MethodOfShipment from "../utils/mappings/methodOfShipment";
import OrderStatusList from "../utils/mappings/statusList";
import { addToCart } from "../services/EpServices";

import "./OrderHistoryDetails.less";

interface OrderDetailsPayment {
  amount: string;
  authNumber: string;
  cardType: string;
  invoiceNumber: string;
  message: string;
  paymentMethod: string;
  prid: string;
  referenceNumber: string;
  resultCode: string;
  status: string;
  transactionType: string;
  type: string;
}

enum PaymentType {
  ACCOUNT = "ACCOUNT",
  CASH = "CASH",
  CARD = "CARD"
}

interface MotiliOrderDetailsData {
  orderedOn: string;
  estFulfillmentDate: string;
  actualFulfillmentDate: string;
  lineItems: Array<MotiliOrderLineItem>;
  contractorId: string;
  extReferenceNumber1: string;
  extPoNumber: string;
  fulfillmentTypeId: string;
  fulfillmentTo: string;
  trackingNumber: string;
  trackingUrl: string;
  trackingCompany: string;
  eCommOrderStatus: string;
  eCommPickedStatus: string;
  fulfillmentToStdAddress: string;
  fulfillmentToStdDeliveryLine?: string;
  fulfillmentToStdLastLine?: string;
  fulfillmentToStdCity?: string;
  fulfillmentToStdCountry?: string;
  fulfillmentToStdPostalCode?: string;
  vendorOrderNumber: string;
  vendorDistributionCenter: MotiliBranchDetails;
  statusId: string;
}

interface MotiliBranchDetails {
  rawAddress: string;
  vendorBranchNumber: string;
  vendorBranchContactEmail: string;
}

interface MotiliOrderLineItem {
  quantity: string | number;
  display: string;
  details: string;
}

interface OrderDetailsData {
  branchNumber: string;
  deliveryBranchAddress: string;
  deliveryBranchEmail: string;
  shipFromBranchNumber: string;
  orderPlacedDate: string;
  promisedShipDate: string;
  shipDate: string;
  details: Array<any>;
  customerNumber: string;
  orderNumber: string;
  purchaseOrderNumber: string;
  orderMethodOfShipment: string;
  orderedByName: string;
  trackingNumber: string;
  orderStatusLabel: string;
  pickStatusLabel: string;
  shippingAddress1: string;
  shippingAddress2: string;
  shippingCity: string;
  shippingState: string;
  shippingZip: string;
  shipCode: string;
  shipCodeDescription: string;
  subtotal: string;
  otherCharges: any;
  tax: string;
  gst: string;
  total: string;
  paymentType?: PaymentType;
  payment?: OrderDetailsPayment;
  invoiceDate?: string;
}

interface OrderLineItem {
  quantityOrdered: string | number;
  sku: string;
  unitPrice: string;
  linePrice: string;
  quantityShipped: string;
  quantityBackOrdered: string;
  description: string;
}

interface OrderHistoryDetailsPageProps {
  history: History;
  match: any;
  auth: any;
  user: any;
  branches: any;
  pageConfig: PageConfigProps;
}

interface PageConfigProps {
  title: string;
  topButtons: boolean;
  showSerialNumber: boolean;
  showInvoiceDate: boolean;
}

interface ResultMessage {
  debugMessages: string;
  id: string;
  type: string;
}

interface OrderHistoryDetailsPageState {
  isLoadingReorder: boolean;
  isLoadingReorderOne: boolean;
  purchaseDetails: any;
  clickedItemId: any;
  resultMessage: ResultMessage;
  noInvoiceError: string;
}

const emptyMessage: ResultMessage = {
  debugMessages: "",
  id: "",
  type: ""
};

let Config;

class OrderHistoryDetailsPage extends React.Component<
  OrderHistoryDetailsPageProps,
  OrderHistoryDetailsPageState
> {
  static contextType = MainContext;

  _isMounted = false;

  constructor(props) {
    super(props);
    Config = getConfig().config;
    this.state = {
      isLoadingReorder: false,
      isLoadingReorderOne: false,
      purchaseDetails: undefined,
      clickedItemId: undefined,
      resultMessage: emptyMessage,
      noInvoiceError: ""
    };
    this.fetchOrderDetails = this.fetchOrderDetails.bind(this);
    this.renderOrderItems = this.renderOrderItems.bind(this);
    this.onReorderAllClick = this.onReorderAllClick.bind(this);
    this.onReorderOneClick = this.onReorderOneClick.bind(this);
    this.closeMessageContainer = this.closeMessageContainer.bind(this);
    this.onPrintInvoiceClicked = this.onPrintInvoiceClicked.bind(this);
    this.renderOrderDetailsButtons = this.renderOrderDetailsButtons.bind(this);
    this.renderPaymentCardDetails = this.renderPaymentCardDetails.bind(this);
  }

  componentDidMount() {
    const {
      match,
      branches: { branchesList }
    } = this.props;
    const params = match.url.split("/");
    const orderNumber = params[params.length - 1];
    this._isMounted = true;
    if (branchesList) {
      this.fetchOrderDetails(orderNumber);
    }

    page();
  }

  componentDidUpdate(prevProps) {
    const {
      match,
      branches: { branchesList }
    } = this.props;
    const params = match.url.split("/");
    const orderNumber = params[params.length - 1];
    if (prevProps.branches.branchesList !== branchesList) {
      this.fetchOrderDetails(orderNumber);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  formatOrderDetails(
    detailsData: MotiliOrderDetailsData | OrderDetailsData
  ): OrderDetailsData {
    const {
      cortexApi: { scope }
    } = Config;

    if (scope === "motili") {
      const motiliData = detailsData as MotiliOrderDetailsData;
      const statusList = OrderStatusList();

      const branchData: MotiliBranchDetails | {} =
        "vendorDistributionCenter" in motiliData
          ? motiliData.vendorDistributionCenter
          : {};

      const motiliOrderData = {
        branchNumber:
          "vendorBranchNumber" in branchData
            ? branchData.vendorBranchNumber
            : "",
        deliveryBranchAddress:
          "rawAddress" in branchData ? branchData.rawAddress : "",
        deliveryBranchEmail:
          "vendorBranchContactEmail" in branchData
            ? branchData.vendorBranchContactEmail
            : "",
        shipFromBranchNumber: undefined,
        orderPlacedDate: isoToSimpleDate(motiliData.orderedOn),
        promisedShipDate: isoToSimpleDate(motiliData.estFulfillmentDate),
        shipDate: isoToSimpleDate(motiliData.actualFulfillmentDate),
        details: motiliData.lineItems.map(
          (item: MotiliOrderLineItem): OrderLineItem => ({
            quantityOrdered: item.quantity,
            sku: item.display,
            unitPrice: undefined,
            linePrice: undefined,
            quantityShipped: undefined,
            quantityBackOrdered: undefined,
            description: item.details
          })
        ),
        customerNumber: motiliData.contractorId,
        orderNumber: motiliData.extReferenceNumber1,
        vendorOrderNumber: motiliData.vendorOrderNumber,
        purchaseOrderNumber: motiliData.extPoNumber,
        orderMethodOfShipment: motiliData.fulfillmentTypeId,
        orderedByName: motiliData.fulfillmentTo,
        trackingNumber:
          motiliData.trackingNumber ||
          motiliData.trackingUrl ||
          motiliData.trackingCompany,
        orderStatusLabel: statusList[motiliData.statusId],
        pickStatusLabel: motiliData.eCommPickedStatus,
        // If motiliData.fulfillmentToStdAddress exists, it will include the full address. Otherwise, will use separate properties.
        shippingAddress1:
          motiliData.fulfillmentToStdAddress ||
          motiliData.fulfillmentToStdDeliveryLine,
        shippingAddress2: motiliData.fulfillmentToStdAddress
          ? undefined
          : motiliData.fulfillmentToStdLastLine,
        shippingCity: motiliData.fulfillmentToStdAddress
          ? undefined
          : motiliData.fulfillmentToStdCity,
        shippingState: motiliData.fulfillmentToStdCountry,
        shippingZip: motiliData.fulfillmentToStdAddress
          ? undefined
          : motiliData.fulfillmentToStdPostalCode,
        shipCode: undefined,
        shipCodeDescription: undefined,
        subtotal: undefined,
        otherCharges: undefined,
        tax: undefined,
        gst: undefined,
        total: undefined
      };

      return motiliOrderData;
    }

    return detailsData as OrderDetailsData;
  }

  fetchOrderDetails(orderNumber) {
    const {
      auth: { logout },
      branches: { findBranch, airPurifierBranch },
      history
    } = this.props;
    const {
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;

    const {
      cortexApi: { scope }
    } = Config;

    const query: string = `orderNumbers=${orderNumber}${
      scope === "motili" ? "" : "&includeDetails=true"
    }`;

    getOrderHistory(customerNumber, query)
      .then(res => {
        const order: OrderDetailsData | {} = res.data.length
          ? this.formatOrderDetails(res.data[0])
          : {};

        const purchaseDetails: any = { ...order };
        // PGL-364: fix delivery branch info bug
        let deliveryBranch; // initialized as undefined
        if ("branchNumber" in purchaseDetails) {
          deliveryBranch =
            purchaseDetails.branchNumber === "300"
              ? airPurifierBranch
              : findBranch(purchaseDetails.branchNumber);
        }
        if (deliveryBranch) {
          purchaseDetails.deliveryBranchAddress =
            deliveryBranch.formattedAddress;
          purchaseDetails.deliveryBranchEmail = deliveryBranch.email;
        }

        const createdAtDateLocale: string =
          "orderPlacedDate" in order
            ? simpleToLocaleDate(order.orderPlacedDate)
            : "";
        const promisedShipDateLocale: string =
          "promisedShipDate" in order
            ? simpleToLocaleDate(order.promisedShipDate)
            : "";
        const shipDateLocale: string =
          "shipDate" in order ? simpleToLocaleDate(order.shipDate) : "";
        const invoiceDate: string =
          "invoiceDate" in order ? simpleToLocaleDate(order.invoiceDate) : "";

        purchaseDetails.createdAt = createdAtDateLocale;
        purchaseDetails.promisedShipDate = promisedShipDateLocale;
        purchaseDetails.shipDate = shipDateLocale;
        purchaseDetails.invoiceDate = invoiceDate;

        if (this._isMounted) {
          this.setState({ purchaseDetails });
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => getOrderHistory => OrderHistoryDetailsPage.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "getOrderHistory => OrderHistoryDetailsPage.tsx"
          });
        }
      });
  }

  renderOrderItems(orderItems) {
    const { isLoadingReorderOne, clickedItemId } = this.state;
    const {
      pageConfig: { showSerialNumber }
    } = this.props;

    return (
      orderItems &&
      orderItems.map((orderItem: any, i) => {
        const key = orderItem.sku + i;
        return (
          <OrderItemDetails
            key={key}
            details={orderItem}
            noOp={noOp}
            isLoadingReorderOne={isLoadingReorderOne}
            clickedItemId={clickedItemId}
            onReorderOneClick={this.onReorderOneClick}
            showSerialNumber={showSerialNumber}
          />
        );
      })
    );
  }

  onReorderOneClick(event, skuNumber) {
    const {
      currentTarget: {
        dataset: { id }
      }
    } = event;
    const { isLoadingReorderOne, purchaseDetails } = this.state;

    if (!isLoadingReorderOne && this._isMounted) {
      this.setState({
        clickedItemId: id,
        isLoadingReorderOne: true
      });

      const orderItems = purchaseDetails.details
        .filter(item => item.sku === skuNumber)
        .map((item: any) => {
          return {
            code: item.sku,
            quantity: item.quantityOrdered,
            price: item.unitPrice,
            name: item.description
          };
        });

      this.onAddToCart(orderItems);
    }
  }

  onReorderAllClick() {
    const { isLoadingReorder, purchaseDetails } = this.state;

    if (!isLoadingReorder && this._isMounted) {
      this.setState({
        isLoadingReorder: true
      });

      const orderItems = purchaseDetails.details
        .filter(item => item.sku !== "")
        .map((item: any) => {
          return {
            code: item.sku,
            quantity: item.quantityOrdered,
            price: item.unitPrice,
            name: item.description
          };
        });

      this.onAddToCart(orderItems);
    }
  }

  onAddToCart(orderItems) {
    const {
      auth: { logout },
      history
    } = this.props;

    const {
      purchaseDetails: { details }
    } = this.state;

    const {
      cart: {
        cartDetails: {
          defaultCart: { addItemsToCart }
        },
        getCartDetails,
        setSuccesCartPopupMessage
      }
    } = this.context;

    const itemQuantity = orderItems.reduce(
      (total, current) => total + Number(current.quantity),
      0
    );

    addToCart(addItemsToCart.self.uri, { items: orderItems })
      .then(res => {
        orderItems.forEach(item => {
          // sends information to Segment for every product user adds
          productAdded(item.name, item.id, item.price, "", "", item.quantity);
        });
      })
      .then(() => {
        return getCartDetails();
      })
      .then(() => {
        setSuccesCartPopupMessage(itemQuantity);
        if (this._isMounted) {
          this.setState({
            isLoadingReorder: false,
            isLoadingReorderOne: false
          });
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => addToCart => OrderHistoryDetailsPage.tsx"
            })
          );
        } else if (this._isMounted) {
          this.setState({
            resultMessage: {
              debugMessages:
                generateSpecificErrorMessage(e) ||
                intl.get("custom-error-order-history-details"),
              id:
                generateSpecificErrorMessage(e) ||
                intl.get("custom-error-order-history-details"),
              type: "error"
            },
            isLoadingReorder: false,
            isLoadingReorderOne: false
          });
        }
      });
  }

  closeMessageContainer() {
    if (this._isMounted) {
      this.setState({
        resultMessage: emptyMessage
      });
    }
  }

  onPrintInvoiceClicked() {
    const {
      purchaseDetails: { orderNumber }
    } = this.state;
    const {
      auth: { logout },
      history,
      user: {
        userProfile: { customerNumber }
      }
    } = this.props;
    // const orderNumber = "S540187";

    this.setState({ noInvoiceError: "" });

    getInvoiceReport(orderNumber, customerNumber)
      .then(invoice => openInvoiceReport(invoice, orderNumber))
      .catch(e => {
        if (e.response && e.response.status === 400) {
          this.setState({
            noInvoiceError: intl.get("invoice-error")
          });
        } else if (checkTokensExpired(e)) {
          this._isMounted = false;
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => getInvoiceReport => OrderHistoryDetailsPage.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "getInvoiceReport => OrderHistoryDetailsPage.tsx"
          });
        }
      });
  }

  renderOrderDetailsButtons(
    isLoadingReorder: boolean,
    noInvoiceError: string,
    topButtons: boolean = false
  ) {
    const { purchaseDetails } = this.state;
    const buttons = (
      <>
        <div
          className={`order-details-buttons ${topButtons ? "top-buttons" : ""}`}
        >
          {Config.showPrintInvoice &&
          purchaseDetails.orderStatusLabel === intl.get("invoiced") ? (
            <button
              type="button"
              className="ep-btn"
              onClick={this.onPrintInvoiceClicked}
              aria-label={intl.get("print-invoice")}
            >
              {intl.get("print-invoice").toUpperCase()}
            </button>
          ) : (
            // An empty div keeps the flex layout.
            <div />
          )}
          <button
            type="button"
            className="ep-btn primary"
            onClick={this.onReorderAllClick}
            aria-label={intl.get("reorder-all")}
          >
            {isLoadingReorder ? (
              <div className="miniLoader" />
            ) : (
              intl.get("reorder-all")
            )}
          </button>
        </div>
        <p className="invoice-error">{noInvoiceError}</p>
      </>
    );

    return buttons;
  }

  renderPaymentCardDetails(): any {
    const {
      purchaseDetails: { payment }
    } = this.state;

    let paymentCardDetailsElement = null;

    if (payment) {
      try {
        const { authNumber, cardType, referenceNumber } = payment;
        paymentCardDetailsElement = (
          <div>
            <span className="label">{intl.get("auth-number")}:</span>
            <p>
              <span>{formatNullString(authNumber)}</span>
            </p>
            <span className="label">{intl.get("payment-card-type")}:</span>
            <p>
              <span>{formatNullString(cardType)}</span>
            </p>
            <span className="label">{intl.get("reference-number")}:</span>
            <p>
              <span>{formatNullString(referenceNumber)}</span>
            </p>
          </div>
        );
      } catch (error) {
        console.error(
          "renderPaymentCardDetails => OrderHistoryDetais.tsx ",
          error
        );
      }
    }

    return paymentCardDetailsElement;
  }

  render() {
    const {
      purchaseDetails,
      resultMessage,
      isLoadingReorder,
      noInvoiceError
    } = this.state;

    const {
      user: { userProfile }
    } = this.context;

    const {
      pageConfig: { title, topButtons, showInvoiceDate }
    } = this.props;

    if (purchaseDetails) {
      return (
        <div className="order-details-container">
          <MessageContainer
            message={resultMessage}
            closeContainerHandler={this.closeMessageContainer}
          />
          {topButtons
            ? this.renderOrderDetailsButtons(
                isLoadingReorder,
                noInvoiceError,
                topButtons
              )
            : null}
          <div className="container main-order-details">
            <div className="order-and-dates">
              <div className="order-info content-box">
                <h4 className="bullet">{title}</h4>
                <div>
                  <div>
                    <p>
                      <span className="label">
                        {intl.get("customer-number")}:
                      </span>
                      <span>{purchaseDetails.customerNumber}</span>
                    </p>
                    <p>
                      <span className="label">{intl.get("order-number")}:</span>
                      <span>{purchaseDetails.orderNumber}</span>
                    </p>
                    <p>
                      <span className="label">{intl.get("p-o-number")}:</span>
                      <span>{purchaseDetails.purchaseOrderNumber}</span>
                    </p>
                    <p>
                      <span className="label">{intl.get("ship-method")}:</span>
                      <span>
                        {
                          MethodOfShipment()[
                            purchaseDetails.orderMethodOfShipment
                          ]
                        }
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="label">
                        {intl.get("customer-name")}:
                      </span>
                      <span>{purchaseDetails.orderedByName}</span>
                    </p>
                    <p>
                      <span className="label">
                        {intl.get("shipping-information")}:
                      </span>
                      <span>
                        {purchaseDetails.shipCode &&
                        purchaseDetails.shipCodeDescription
                          ? `${purchaseDetails.shipCode} ${purchaseDetails.shipCodeDescription}`
                          : ""}
                      </span>
                    </p>
                    <p>
                      <span className="label">{intl.get("tracking")}:</span>
                      <span>
                        {purchaseDetails.trackingNumber
                          ? purchaseDetails.trackingNumber
                          : ""}
                      </span>
                    </p>
                    <p>
                      <span className="label">{intl.get("ship-via")}:</span>
                      <span>{purchaseDetails.shipFromBranchNumber}</span>
                    </p>
                    {Config.showVendorOrderNumber ? (
                      <p>
                        <span className="label">
                          {intl.get("vendor-order-number")}:
                        </span>
                        <span>
                          {purchaseDetails.vendorOrderNumber
                            ? purchaseDetails.vendorOrderNumber
                            : ""}
                        </span>
                      </p>
                    ) : null}
                    <p>
                      <span className="label">{intl.get("status")}:</span>
                      <span>
                        {purchaseDetails.orderStatusLabel}{" "}
                        {purchaseDetails.backOrdered
                          ? `/ ${intl.get("back-ordered")}`
                          : ""}
                      </span>
                    </p>
                    <p>
                      <span className="label">{intl.get("pick-status")}:</span>
                      <span>{purchaseDetails.pickStatusLabel || null}</span>
                    </p>
                    {Config.orderHistoryShowJob && (
                      <p>
                        <span className="label">{intl.get("job")}:</span>
                        <span>
                          {`${purchaseDetails.jobNumber ||
                            ""} ${purchaseDetails.jobName || ""}`}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="dates-payment-wrapper">
                <div className="dates content-box">
                  <h4 className="bullet">{intl.get("date")}</h4>
                  <div>
                    <span className="label">{intl.get("date-ordered")}:</span>
                    <p>
                      <span>
                        {purchaseDetails.createdAt
                          ? purchaseDetails.createdAt
                          : null}
                      </span>
                    </p>
                    <span className="label">{intl.get("date-promised")}:</span>
                    <p>
                      <span>
                        {purchaseDetails.promisedShipDate
                          ? purchaseDetails.promisedShipDate
                          : null}
                      </span>
                    </p>
                    <span className="label">{intl.get("date-shipped")}:</span>
                    <p>
                      <span>
                        {purchaseDetails.shipDate
                          ? purchaseDetails.shipDate
                          : null}
                      </span>
                    </p>
                    {showInvoiceDate && (
                      <span className="label">
                        {intl.get("date-invoiced")}:
                      </span>
                    )}
                    <p>
                      <span>
                        {showInvoiceDate && purchaseDetails.invoiceDate}
                      </span>
                    </p>
                  </div>
                </div>
                {Config.showOrderHistoryDetailsPayment && (
                  <div className="payment content-box">
                    <h4 className="bullet">{intl.get("payment")}</h4>
                    <div>
                      <span className="label">{intl.get("payment-type")}:</span>
                      <p>
                        <span>{purchaseDetails.paymentType || ""}</span>
                      </p>

                      {purchaseDetails.paymentType === PaymentType.CARD &&
                        this.renderPaymentCardDetails()}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="addresses-and-totals">
              <div className="addresses content-box">
                <h4 className="bullet">{intl.get("addresses")}</h4>
                <div>
                  <div className="shipping">
                    <p className="label">{intl.get("ship-to")}:</p>
                    <p>
                      <span>{purchaseDetails.shippingAddress1}</span>
                    </p>
                    <p>
                      <span>{purchaseDetails.shippingAddress2}</span>
                      {purchaseDetails.shippingCity}{" "}
                      {purchaseDetails.shippingState}{" "}
                      {purchaseDetails.shippingZip}
                    </p>
                  </div>
                  <div className="branch">
                    <p className="label">{intl.get("branch")}:</p>#
                    {purchaseDetails.branchNumber}
                    <p>{purchaseDetails.deliveryBranchAddress}</p>
                    <a href={`mailto:${purchaseDetails.deliveryBranchEmail}`}>
                      {purchaseDetails.deliveryBranchEmail}
                    </a>
                  </div>
                </div>
              </div>
              {Config.calculatePrice && (
                <div className="totals content-box">
                  <h4 className="bullet">{intl.get("total")}</h4>
                  <div className="totals-table">
                    <div className="totals-row">
                      <span className="label">{intl.get("sub-total")}:</span>
                      <p className="value">${purchaseDetails.subtotal}</p>
                    </div>
                    <div className="totals-row">
                      <span className="label">
                        {intl.get("delivery-and-handling")}:
                      </span>
                      <p className="value">$ {purchaseDetails.otherCharges}</p>
                    </div>
                    <div className="totals-row">
                      <span className="label">
                        {userProfile.isCanadianUser
                          ? intl.get("canada-pst-tax")
                          : intl.get("tax")}
                        :
                      </span>
                      <p className="value">$ {purchaseDetails.tax}</p>
                    </div>
                    {userProfile.isCanadianUser ? (
                      <div className="totals-row">
                        <span className="label">
                          {intl.get("gst-tax").toUpperCase()}:
                        </span>
                        <p className="value">$ {purchaseDetails.gst}</p>
                      </div>
                    ) : null}
                    <div className="totals-row">
                      <span className="label">{intl.get("total")}:</span>
                      <p className="value">${purchaseDetails.total}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="items-main content-box content-table">
              <h4 className="bullet">{intl.get("items")}</h4>
              <div className="items table-labels">
                <div>
                  <p className="label">{intl.get("quantity-abbr")}</p>
                </div>
                <div>
                  <p className="label">{intl.get("product")}#</p>
                </div>
                <div>
                  <p className="label">{intl.get("description")}</p>
                </div>
                {Config.calculatePrice && (
                  <div>
                    <p className="label">{intl.get("price")}</p>
                  </div>
                )}
                {Config.calculatePrice && (
                  <div>
                    <p className="label">{intl.get("extended-price")}</p>
                  </div>
                )}
                {Config.orderHistoryQTYShipped && (
                  <div>
                    <p className="label">{intl.get("qty-shipped")}</p>
                  </div>
                )}
                {Config.showBackOrderedQuantity && (
                  <div>
                    <p className="label">{intl.get("qty-b-o")}</p>
                  </div>
                )}
                <div>
                  <p className="label">{intl.get("action")}</p>
                </div>
              </div>
              {this.renderOrderItems(purchaseDetails.details)}
            </div>
            {!topButtons
              ? this.renderOrderDetailsButtons(isLoadingReorder, noInvoiceError)
              : null}
          </div>
        </div>
      );
    }
    return (
      <>
        <div style={{ width: "100%" }}>
          <div className="loader" />
        </div>
      </>
    );
  }
}

export default OrderHistoryDetailsPage;
