/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import Modal from "react-responsive-modal";
import {
  checkTokensExpired,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { updateDefaultProfile } from "@elasticpath/ref-store/src/services/EpServices";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import Input from "../common/InputComponent/Input";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { resetPassword } from "../../../app/src/services/EpServices";

import "./profileInfo.main.less";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface ProfileInfoMainProps {
  profileInfo: {
    [key: string]: any;
  };
  onChange: (...args: any[]) => any;
  isDisabled?: boolean;
  history: any;
  auth: any;
  userProfile: any;
  isPrimaryUser: boolean;
  editable: boolean;
}

interface ProfileInfoMainState {
  inEditMode: boolean;
  failedSubmit: boolean;
  firstName: any;
  lastName: any;
  phone: any;
  errorMessage: string;
  resetPasswordModalOpened: boolean;
  newPassword: string;
  confirmPassword: string;
  passwordError: string;
}

class ProfileInfoMain extends React.Component<
  ProfileInfoMainProps,
  ProfileInfoMainState
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = epConfig);
    const { profileInfo } = this.props;
    this.state = {
      inEditMode: false,
      failedSubmit: false,
      firstName: profileInfo.firstName,
      lastName: profileInfo.lastName,
      phone: profileInfo.phone,
      errorMessage: "",
      resetPasswordModalOpened: false,
      newPassword: "",
      confirmPassword: "",
      passwordError: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitPersonalInfoChange = this.submitPersonalInfoChange.bind(this);
    this.showResetPasswordModal = this.showResetPasswordModal.bind(this);
    this.renderResetPasswordModal = this.renderResetPasswordModal.bind(this);
    this.onPasswordPopulated = this.onPasswordPopulated.bind(this);
    this.clearPasswordInputs = this.clearPasswordInputs.bind(this);
    this.shouldDisablePasswordSave = this.shouldDisablePasswordSave.bind(this);
    this.setPasswordError = this.setPasswordError.bind(this);
    this.onSaveNewPassword = this.onSaveNewPassword.bind(this);
    this.closeResetPasswordModal = this.closeResetPasswordModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      profileInfo: { firstName, lastName, phone }
    } = this.props;
    const {
      profileInfo: {
        firstName: prevFirstName,
        lastName: prevLastName,
        phone: prevPhone
      }
    } = prevProps;
    if (
      firstName !== prevFirstName ||
      lastName !== prevLastName ||
      phone !== prevPhone
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        firstName,
        lastName,
        phone
      });
    }
  }

  handleInputChange(event: any): void {
    const { name, value } = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
      errorMessage: ""
    }));
  }

  cancel(): void {
    const { profileInfo } = this.props;
    this.setState({
      inEditMode: false,
      errorMessage: "",
      firstName: profileInfo.firstName,
      lastName: profileInfo.lastName,
      phone: profileInfo.phone
    });
  }

  editPersonalInfo(): void {
    this.setState({
      inEditMode: true,
      errorMessage: ""
    });
  }

  validatePhoneNumber = (phoneNumber: string): boolean => {
    const phoneRegex: RegExp = new RegExp(
      /^[(]{0,1}[0-9]{3}[)]{0,1}[-|\s]{0,1}[0-9]{3}[-]{0,1}[0-9]{4}$/
    );

    if (!phoneRegex.test(phoneNumber)) {
      this.setState({
        errorMessage: intl.get("invalid-phone-number")
      });
      return false;
    }

    return true;
  };

  submitPersonalInfoChange(event: any): void {
    event.preventDefault();

    const { firstName, lastName, phone } = this.state;
    const {
      history,
      auth: { logout },
      userProfile,
      isPrimaryUser,
      onChange
    } = this.props;

    const isValid: boolean = this.validatePhoneNumber(phone);

    const updatedProfile = isPrimaryUser
      ? {
          "given-name": firstName,
          "family-name": lastName,
          phone
        }
      : {
          "sub-user-first-name": firstName,
          "sub-user-last-name": lastName,
          "sub-user-phone": phone
        };

    if (isValid) {
      updateDefaultProfile(updatedProfile, userProfile)
        .then(() => {
          this.cancel();
          onChange();
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn:
                  "Logout => submitPersonalInfoChange => ProfileInfoMain.tsx"
              })
            );
          } else {
            const malformedEmailErrorCode =
              e &&
              e.messages &&
              e.messages[0] &&
              e.messages[0].id &&
              e.messages[0].id === "field.invalid.email.format"
                ? intl.get("malformed-email-address-error-code")
                : null;

            pushToMaintenace(history, {
              e,
              errIn: "submitPersonalInfoChange => ProfileInfoMain.tsx",
              errorCode: malformedEmailErrorCode
            });
          }
        });
    }
  }

  showResetPasswordModal(isOpened: boolean): void {
    this.setState({ resetPasswordModalOpened: isOpened });
  }

  onPasswordPopulated(event): void {
    event.persist();
    this.setState(prevState => {
      const updatedState = {
        ...prevState,
        [event.target.name]: event.target.value.trim(),
        passwordError: ""
      };

      return updatedState;
    });
  }

  clearPasswordInputs(): void {
    this.setState({
      newPassword: "",
      confirmPassword: ""
    });
  }

  shouldDisablePasswordSave(): boolean {
    const { newPassword, confirmPassword } = this.state;
    if (!newPassword || !confirmPassword) return true;
    if (newPassword !== confirmPassword) return true;

    return false;
  }

  setPasswordError(error: any): void {
    this.setState({
      passwordError:
        error.message && typeof error.message === "string"
          ? error.message
          : intl.get("password-reset-error")
    });
  }

  closeResetPasswordModal(): void {
    this.clearPasswordInputs();
    this.setPasswordError({ message: "" });
    this.showResetPasswordModal(false);
  }

  async onSaveNewPassword(): Promise<any> {
    const { confirmPassword } = this.state;
    const {
      modal: { setPasswordResetMessage }
    } = this.context;

    const {
      auth: { logout },
      history,
      profileInfo: { email }
    } = this.props;

    try {
      await resetPassword(confirmPassword);
      setPasswordResetMessage(true);
      this.closeResetPasswordModal();
    } catch (error) {
      if (checkTokensExpired(error)) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "Logout => onSaveNewPassword => ProfileInfoMain.tsx"
          })
        );
      } else {
        this.setPasswordError(error);
      }
    }
  }

  renderResetPasswordModal(): any {
    const {
      resetPasswordModalOpened,
      confirmPassword,
      newPassword,
      passwordError
    } = this.state;

    const modal = (
      <Modal
        open={resetPasswordModalOpened}
        onClose={this.closeResetPasswordModal}
      >
        <div className="modal-lg reset-password-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">{intl.get("reset-password")}</h2>
            </div>
            <div className="modal-body">
              {passwordError && (
                <p className="reset-password-error">{passwordError}</p>
              )}
              <div className="reset-password-inputs">
                <Input
                  type="password"
                  label={intl.get("password")}
                  ariaLabel={intl.get("password")}
                  inputHandler={this.onPasswordPopulated}
                  inputName="newPassword"
                  required
                  value={newPassword}
                />
                <Input
                  type="password"
                  label={intl.get("confirm-password")}
                  ariaLabel={intl.get("confirm-password")}
                  inputHandler={this.onPasswordPopulated}
                  inputName="confirmPassword"
                  required
                  value={confirmPassword}
                />
              </div>
              <div>
                <div className="edit-order-buttons">
                  <button
                    type="button"
                    className="ep-btn"
                    aria-label={intl.get("cancel")}
                    onClick={this.closeResetPasswordModal}
                  >
                    {intl.get("cancel")}
                  </button>
                  <button
                    type="button"
                    aria-label={intl.get("save")}
                    className="ep-btn primary"
                    onClick={this.onSaveNewPassword}
                    disabled={this.shouldDisablePasswordSave()}
                  >
                    {intl.get("save")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
    return modal;
  }

  render() {
    const {
      inEditMode,
      failedSubmit,
      firstName,
      lastName,
      phone,
      errorMessage
    } = this.state;
    const { profileInfo, isDisabled, editable } = this.props;
    if (inEditMode) {
      return (
        <div
          className="personal-information-container"
          data-region="profilePersonalInfoRegion"
        >
          <div>
            <form
              className="form-horizontal"
              onSubmit={this.submitPersonalInfoChange}
            >
              <div data-region="componentAddressFormRegion">
                <div className="address-form-container profile-info-edit-container">
                  <div
                    className="feedback-label address-form-feedback-container"
                    data-region="componentAddressFeedbackRegion"
                  >
                    {failedSubmit ? intl.get("failed-to-save-message") : ""}
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="firstName"
                      data-el-label="addressForm.firstName"
                      className="control-label address-form-label profile-info-firstname-form-label"
                    >
                      <span className="required-label">*</span>{" "}
                      {intl.get("first-name")}
                    </label>
                    <div className="address-form-input profile-info-firstname-form-input input-component">
                      {/* eslint-disable-next-line max-len */}
                      <input
                        id="registration_form_firstName"
                        name="firstName"
                        className="form-control"
                        type="text"
                        value={firstName}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="lastName"
                      data-el-label="addressForm.lastName"
                      className="control-label address-form-label profile-info-lastname-form-label"
                    >
                      <span className="required-label">*</span>{" "}
                      {intl.get("last-name")}
                    </label>
                    <div className="address-form-input profile-info-lastname-form-input input-component">
                      {/* eslint-disable-next-line max-len */}
                      <input
                        id="registration_form_lastName"
                        name="lastName"
                        className="form-control"
                        type="text"
                        value={lastName}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="Phone"
                      data-el-label="addressForm.phone"
                      className="control-label address-form-label profile-info-phone-form-label"
                    >
                      <span className="required-label">*</span>{" "}
                      {intl.get("phone")}
                    </label>
                    <div className="address-form-input profile-info-phone-form-input input-component">
                      <input
                        id="registration_form_phone"
                        name="phone"
                        className="form-control"
                        type="text"
                        defaultValue={phone}
                        onChange={this.handleInputChange}
                        required
                        placeholder="999-999-9999"
                      />
                      <span className="phone-error-message">
                        {errorMessage}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group create-address-btn-container profile-info-btn-container">
                <button
                  className="ep-btn primary profile-info-save-btn"
                  aria-label={intl.get("save")}
                  data-el-label="addressForm.save"
                  type="submit"
                >
                  {intl.get("save")}
                </button>
                <button
                  className="ep-btn profile-info-cancel-btn"
                  aria-label={intl.get("cancel")}
                  data-el-label="addressForm.cancel"
                  type="button"
                  onClick={() => {
                    this.cancel();
                  }}
                >
                  {intl.get("cancel")}
                </button>
              </div>
            </form>
          </div>
        </div>
      );
    }
    if (profileInfo) {
      return (
        <div
          className="personal-information-container"
          data-region="profilePersonalInfoRegion"
        >
          <div>
            {this.renderResetPasswordModal()}
            <div className="personal-info-container">
              <div className="personal-info-email">
                <span className="info-label" data-el-label="profile.email">
                  {intl.get("email")}:
                </span>
                <span
                  className="info-value"
                  id="profile_personal_info_email"
                  data-el-value="email"
                >
                  {profileInfo.email}
                </span>
              </div>
              <div className="personal-info-firstName">
                <span className="info-label" data-el-label="profile.firstName">
                  {intl.get("first-name")}:
                </span>
                <span
                  className="info-value"
                  id="profile_personal_info_firstName"
                  data-el-value="firstName"
                >
                  {profileInfo.firstName}
                </span>
              </div>
              <div className="personal-info-lastName">
                <span className="info-label" data-el-label="profile.lastName">
                  {intl.get("last-name")}:
                </span>
                <span
                  className="info-value"
                  id="profile_personal_info_lastName"
                  data-el-value="lastName"
                >
                  {profileInfo.lastName}
                </span>
              </div>
              {profileInfo.phone && (
                <div className="personal-info-phone">
                  <span className="info-label" data-el-label="profile.phone">
                    {intl.get("phone")}:
                  </span>
                  <span
                    className="info-value"
                    id="profile_personal_info_phone"
                    data-el-value="phone"
                  >
                    {profileInfo.phone}
                  </span>
                </div>
              )}
              {Config.showCompanyName && profileInfo.companyName && (
                <div className="personal-info-companyName">
                  <span
                    className="info-label"
                    data-el-label="profile.companyName"
                  >
                    {intl.get("company-name")}:
                  </span>
                  <span
                    className="info-value"
                    id="profile_personal_info_companyName"
                    data-el-value="companyName"
                  >
                    {profileInfo.companyName}
                  </span>
                </div>
              )}
            </div>
            {editable && (
              <div className="profile-personal-info-buttons">
                <button
                  className="ep-btn primary"
                  aria-label={intl.get("edit")}
                  type="button"
                  id="profile_personal_info_edit_button"
                  disabled={isDisabled}
                  onClick={() => {
                    this.editPersonalInfo();
                  }}
                >
                  {intl.get("edit")}
                </button>
                <button
                  className="ep-btn primary"
                  aria-label={intl.get("reset-password")}
                  type="button"
                  onClick={() => {
                    this.showResetPasswordModal(true);
                  }}
                >
                  {intl.get("reset-password")}
                </button>
              </div>
            )}
          </div>
        </div>
      );
    }
    return <div className="loader" />;
  }
}

export default ProfileInfoMain;
