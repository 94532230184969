/* eslint-disable no-param-reassign */
/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { FC, useEffect, useState, useRef } from "react";
import intl from "react-intl-universal";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import {
  PurchaseDetailsMain,
  OrderTableMain,
  orderCompleted,
  page,
  getConfig
} from "@zilker/store-components";
import { useMainContext } from "../contexts/MainContext";
import { getMincronOrderId } from "../services/connectServices";

import "./PurchaseReceiptPage.less";
import {
  checkResponse,
  checkTokensExpired,
  pushToMaintenace
} from "../utils/helpers";

enum OrderLogStatus {
  PREPARING = "PREPARING",
  SUBMITTED = "SUBMITTED",
  FAILED = "FAILED",
  EXCEPTION = "EXCEPTION"
}

const PurchaseReceiptPage: FC<RouteComponentProps> = ({ history }) => {
  const context = useMainContext();
  const {
    auth,
    order: {
      orderData,
      purchaseData,
      branch,
      job,
      poNumber,
      promoCodes,
      setPromoCodes,
      contractNumber
    },
    user
  } = context;
  const {
    userProfile: { customerNumber }
  } = user;

  const { config } = getConfig();
  const { scope } = config.cortexApi;
  const { defaultChannel } = config.brXM;
  const { useEPOrderNumber } = config;
  const showTitle = defaultChannel !== "motili";

  const [mincronOrderID, setMincronOrderId] = useState<string>();

  const timer = useRef<any>(null);

  const handleReorderAllProducts = () => {
    history.push("/mycart");
  };

  const itemDetailLink = "/itemdetail";

  useEffect(() => {
    window.scrollTo(0, 0);
    if (orderData) {
      const orderUri = orderData._order[0].self.uri;
      const orderId = orderUri.slice(
        orderUri.lastIndexOf(`${scope}/`) + `${scope}/`.length
      );

      const total = orderData._order[0]._total[0].cost[0].amount;
      const tax = orderData._order[0]._tax[0].total.amount;
      const { currency } = orderData._order[0]._total[0].cost[0];

      const products = orderData._lineitems[0]._element.map(product => {
        return {
          product_id: product._item[0]._code[0].code,
          sku: product._item[0]._code[0].code,
          name: product._item[0]._definition[0]["display-name"],
          price: config.calculatePrice ? product._total[0].cost[0].amount : 0,
          quantity: product.quantity
        };
      });

      // sends information to Segment when user completes the order
      orderCompleted(orderId, total, tax, currency, products);
    }

    page();
    return () => {
      setPromoCodes(null);
    };
  }, []);

  useEffect(() => {
    if (purchaseData && !mincronOrderID && !useEPOrderNumber) {
      pullOrderNumFromMincron(purchaseData["purchase-number"], 0);
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  function pullOrderNumFromMincron(epOrderId: string, limit: number): void {
    if (mincronOrderID || limit === 25) {
      return;
    }
    getMincronOrderId(customerNumber, epOrderId)
      .then(res => {
        if (res && res.data && res.data.length) {
          switch (res.data[0].orderStatus) {
            case OrderLogStatus.PREPARING: {
              limit += 1;
              setMincronOrderId(intl.get("pending"));
              timer.current = setTimeout(() => {
                pullOrderNumFromMincron(epOrderId, limit);
              }, 4000);
              break;
            }
            case OrderLogStatus.SUBMITTED: {
              setMincronOrderId(res.data[0].orderNumber);
              break;
            }
            case OrderLogStatus.FAILED || OrderLogStatus.EXCEPTION: {
              setMincronOrderId(intl.get("order-log-failure-id"));
              console.error(
                intl.get("order-log-failure-message", {
                  status: res.data[0].orderStatus
                })
              );
              break;
            }
            default: {
              console.error(res.data[0]);
              setMincronOrderId(intl.get("order-log-failure-id"));
            }
          }
        } else {
          limit += 1;
          setMincronOrderId(intl.get("pending"));
          timer.current = setTimeout(() => {
            pullOrderNumFromMincron(epOrderId, limit);
          }, 4000);
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          auth.logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => getMincronOrderId => PurchaseReceiptPage.tsx"
            })
          );
        } else {
          console.error(e);
          setMincronOrderId(intl.get("order-log-failure-id"));
        }
      });
  }

  if (!auth.isLoggedIn || !orderData || !purchaseData)
    return <Redirect to={{ pathname: "/" }} />;

  return (
    <div className="app-main">
      <>
        <div
          className="purchase-receipt-container"
          id="purchaseReceiptContainer"
        >
          <PurchaseDetailsMain
            data={{
              ...purchaseData,
              branch,
              job,
              order: orderData._order,
              poNumber,
              orderID: !useEPOrderNumber
                ? mincronOrderID
                : `ecomm_${purchaseData["purchase-number"]}`,
              promoCodes,
              contractNumber
            }}
            onReorderAllProducts={handleReorderAllProducts}
            itemDetailLink={itemDetailLink}
            history={history}
            auth={auth}
            user={user}
          />
        </div>
        <div className="order-items-container container">
          <div className="order-items-list">
            {showTitle && <h4 className="bullet"> {intl.get("items")}</h4>}
            <OrderTableMain data={orderData} itemDetailLink={itemDetailLink} />
          </div>
        </div>
      </>
    </div>
  );
};

export default withRouter(PurchaseReceiptPage);
