/* eslint-disable import/prefer-default-export */

import { getConfig } from "./ConfigProvider";

// declaration of analytics property on window
declare global {
  interface Window {
    analytics: any;
  }
}

export const load = () => {
  const { config } = getConfig();
  window.analytics.load(config.segmentWriteKey);
};

export const page = () => {
  window.analytics.page();
};

export const productViewed = (
  name: string,
  id: string,
  price: number,
  brand: string,
  category: string
) => {
  const { config } = getConfig();
  const userTraits = JSON.parse(localStorage.getItem("ajs_user_traits"));
  const properties: any = {
    name,
    id,
    price: config.calculatePrice ? price : 0,
    brand,
    category
  };
  const email = userTraits ? userTraits.email : "";
  if (email) {
    properties.email = email;
  }
  window.analytics.track("Product Viewed", properties);
};

export const productClicked = (
  name: string,
  id: string,
  price: number,
  brand: string,
  category: string
) => {
  const { config } = getConfig();
  const userTraits = JSON.parse(localStorage.getItem("ajs_user_traits"));
  const properties: any = {
    name,
    id,
    price: config.calculatePrice ? price : 0,
    brand,
    category
  };
  const email = userTraits ? userTraits.email : "";
  if (email) {
    properties.email = email;
  }
  window.analytics.track("Product Clicked", properties);
};

export const productAdded = (
  name: string,
  id: string,
  price: number,
  brand: string,
  category: string,
  quantity: number
) => {
  const { config } = getConfig();
  const userTraits = JSON.parse(localStorage.getItem("ajs_user_traits"));
  const properties: any = {
    name,
    id,
    price: config.calculatePrice ? price : 0,
    brand,
    category,
    quantity
  };
  const email = userTraits ? userTraits.email : "";
  if (email) {
    properties.email = email;
  }
  window.analytics.track("Product Added", properties);
};

export const productListViewed = (category: string) => {
  const userTraits = JSON.parse(localStorage.getItem("ajs_user_traits"));
  const properties: any = {
    category
  };
  const email = userTraits ? userTraits.email : "";
  if (email) {
    properties.email = email;
  }
  window.analytics.track("Product List Viewed", properties);
};

export const productListFiltered = (
  category: string,
  filters: {
    type: string;
    value: string;
  }[]
) => {
  const userTraits = JSON.parse(localStorage.getItem("ajs_user_traits"));
  const properties: any = {
    category,
    filters
  };
  const email = userTraits ? userTraits.email : "";
  if (email) {
    properties.email = email;
  }
  window.analytics.track("Product List Filtered", properties);
};

export const checkoutStarted = (orderId: string) => {
  const userTraits = JSON.parse(localStorage.getItem("ajs_user_traits"));
  const properties: any = {
    order_id: orderId
  };
  const email = userTraits ? userTraits.email : "";
  if (email) {
    properties.email = email;
  }
  window.analytics.track("Checkout Started", properties);
};

export const checkoutStepCompleted = (step: number) => {
  const userTraits = JSON.parse(localStorage.getItem("ajs_user_traits"));
  const properties: any = {
    step
  };
  const email = userTraits ? userTraits.email : "";
  if (email) {
    properties.email = email;
  }
  window.analytics.track("Checkout Step Completed", properties);
};

export const orderCompleted = (
  orderId: string,
  total: number,
  tax: number,
  currency: string,
  products: any
) => {
  const { config } = getConfig();
  const userTraits = JSON.parse(localStorage.getItem("ajs_user_traits"));
  const properties: any = {
    order_id: orderId,
    total: config.calculatePrice ? total : 0,
    tax: config.calculatePrice ? tax : 0,
    currency,
    products
  };
  const email = userTraits ? userTraits.email : "";
  if (email) {
    properties.email = email;
  }
  window.analytics.track("Order Completed", properties);
};

export const productsSearched = (query: string) => {
  const userTraits = JSON.parse(localStorage.getItem("ajs_user_traits"));
  const properties: any = {
    query
  };
  const email = userTraits ? userTraits.email : "";
  if (email) {
    properties.email = email;
  }
  window.analytics.track("Products Searched", properties);
};

export const logAutoSuggestTermThroughSegment = (
  uri: string,
  searchTerm: string,
  inputValue: string
) => {
  const userTraits = JSON.parse(localStorage.getItem("ajs_user_traits"));
  const properties: any = {
    uri,
    searchTerm,
    inputValue
  };
  const email = userTraits ? userTraits.email : "";
  if (email) {
    properties.email = email;
  }
  window.analytics.track("logAutoSuggest", properties);
};

export const logSearchTermResultPageThroughSegment = (
  uri: string,
  searchTerm: string,
  resultList: any
) => {
  const userTraits = JSON.parse(localStorage.getItem("ajs_user_traits"));
  const properties: any = {
    uri,
    searchTerm,
    resultList
  };
  const email = userTraits ? userTraits.email : "";
  if (email) {
    properties.email = email;
  }
  window.analytics.track("logSearchTermResultPage", properties);
};
