/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */
/* eslint-disable class-methods-use-this */

import React from "react";
import intl from "react-intl-universal";
import { History } from "history";
import { debounce } from "lodash";
import { Parser } from "json2csv";
import { Input, OrderItem, productAdded, page } from "@zilker/store-components";
import MessageContainer from "@zilker/store-components/src/MessageContainer/messagecontainer";
import {
  noOp,
  dkFormatDate,
  checkTokensExpired,
  checkResponse,
  pushToMaintenace,
  isoToSimpleDate,
  openInvoiceReport,
  getUniqueArray,
  simpleToLocaleDate
} from "../utils/helpers";
import OrderStatusList from "../utils/mappings/statusList";
import {
  getInvoiceReport,
  getOrderHistory,
  getOrderHistoryDetails
} from "../services/connectServices";
import getClickedOption from "../validation/orderHistoryViewSelect";
import { MainContext } from "../contexts/MainContext";
import Config from "../ep.config.json";
import { generateCancelToken, isCanceled } from "../services/HttpService";
import { addToCart } from "../services/EpServices";

import "./OrderHistoryPage.less";

const {
  cortexApi: { scope }
} = Config;

const statusQueryParameter = scope === "motili" ? "status" : "orderStatus";

export interface OrderItemData {
  createdAt?: string;
  details?: any[];
  orderNumber: string;
  sourceSystem?: string;
  submitterEmail?: string;
  updatedAt?: string;
  __v?: number;
  _id?: string;
  id?: string;
  orderPlacedDate?: string;
  purchaseOrderNumber?: string;
  jobNumber?: string;
  jobName?: string;
  orderStatusLabel?: string;
  backOrdered?: string;
  total?: number;
  orderStatusCode?: string;
  branchNumber?: number;
}

interface MotiliOrderItemData {
  extReferenceNumber1: string;
  orderedOn: string;
  extPoNumber: string;
  eCommOrderStatus: string;
  id: string;
  statusId: string;
  updated?: string;
}

enum ErrorId {
  FILTERS = "FILTERS",
  LOAD_MORE = "LOAD_MORE"
}

interface Filters {
  skus?: string;
  orderNumbers?: string;
  orderStatus?: string;
  dateFrom?: string;
  dateTo?: string;
  limit?: number;
  skip?: number;
  poNumbers?: string;
  jobNumbers?: string;
  status?: string;
  branchNumber?: string;
}

interface ResultMessage {
  debugMessages: string;
  id: string;
  type: string;
}

interface OrderHistoryPageState {
  isLoadingHistory: boolean;
  isLoadingReorder: boolean;
  isLoadingFilters: boolean;
  orderHistoryData: any[];
  clickedItemId: number;
  filters: Filters;
  filterVisible: boolean;
  loadedAll: boolean;
  resultMessage: ResultMessage;
  checkedOrderNumbers: any;
  invoiceErrors: { errorMessage: string }[] | null;
  allInvoicesError: { debugMessage: string; errorText: string } | null;
  selectedViewOption: number;
  sortKey: string;
  sortSeq: number;
  downloadError: string;
  isUserAdmin: boolean;
}

interface PageConfigProps {
  title: string;
  usePrintInvoice: boolean;
  showStatusField: boolean;
  selectMultipleLines: boolean;
  orderStatus: string;
  showExportToCsv: boolean;
}

interface AccountDetails {
  uri: string;
  isBuyerAdmin: boolean;
  legalName: string;
  name: string;
  registrationId: string;
  associates: any[];
  primaryEmail: string;
  hasError?: boolean;
}

interface OrderHistoryPageProps {
  history: History;
  auth: any;
  user: any;
  cart: any;
  accountDetails: AccountDetails;
  pageConfig: PageConfigProps; // Controls if Invoice Inquiry page or Order History page should be rendered.
}

const emptyMessage: ResultMessage = {
  debugMessages: "",
  id: "",
  type: ""
};

const viewOptions = [9, 15, 24];

const emptyFilters: Filters = {
  skus: "",
  orderNumbers: "",
  [statusQueryParameter]: "",
  dateFrom: undefined,
  dateTo: undefined,
  limit: viewOptions[0],
  skip: 0,
  poNumbers: "",
  jobNumbers: "",
  branchNumber: ""
};

enum SortSeq {
  "DESC",
  "ASC"
}

const SortKey = {
  ORDER_NUMBER: scope === "motili" ? "extReferenceNumber1" : "orderNumber",
  ORDER_DATE: scope === "motili" ? "formattedDate" : "orderPlacedDate",
  PO_NUMBER: scope === "motili" ? "extPoNumber" : "purchaseOrderNumber",
  JOB: "jobName",
  STATUS: scope === "motili" ? "statusId" : "orderStatusLabel",
  TOTAL: "total",
  UPDATED_AT: scope === "motili" ? "updated" : "updatedAt",
  BRANCH_NUMBER: scope === "motili" ? "" : "branchNumber"
};

class OrderHistoryPage extends React.Component<
  OrderHistoryPageProps,
  OrderHistoryPageState
> {
  static contextType = MainContext;

  viewOptions: Array<number>;

  timeout = null;

  miliseconds = 500;

  _isMounted = false;

  cancelToken = null;

  constructor(props) {
    super(props);
    this.viewOptions = viewOptions;

    this.state = {
      isLoadingHistory: false,
      isLoadingReorder: false,
      isLoadingFilters: false,
      orderHistoryData: undefined,
      clickedItemId: 0,
      filters: emptyFilters,
      filterVisible: true,
      loadedAll: false,
      resultMessage: emptyMessage,
      checkedOrderNumbers: new Set(),
      invoiceErrors: null,
      allInvoicesError: null,
      selectedViewOption: this.viewOptions[0],
      sortKey: SortKey.ORDER_DATE,
      sortSeq: SortSeq.DESC,
      downloadError: "",
      isUserAdmin: false
    };

    this.fetchOrderHistory = this.fetchOrderHistory.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.fetchFilteredData = debounce(this.fetchFilteredData.bind(this), 1000);
    this.generateQueryString = this.generateQueryString.bind(this);
    this.onLoadLimitChange = this.onLoadLimitChange.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.onToggleFilters = this.onToggleFilters.bind(this);
    this.onReorderClick = this.onReorderClick.bind(this);
    this.closeMessageContainer = this.closeMessageContainer.bind(this);
    this.applyViewFilter = this.applyViewFilter.bind(this);
    this.formatOrderItems = this.formatOrderItems.bind(this);
    this.onOrderItemSelected = this.onOrderItemSelected.bind(this);
    this.checkOrderItem = this.checkOrderItem.bind(this);
    this.uncheckOrderItem = this.uncheckOrderItem.bind(this);
    this.onPrintInvoiceClick = this.onPrintInvoiceClick.bind(this);
    this.fetchInvoice = this.fetchInvoice.bind(this);
    this.renderPrintInvoiceButton = this.renderPrintInvoiceButton.bind(this);
    this.sortTable = this.sortTable.bind(this);
    this.downloadDetailedOrderHistory = this.downloadDetailedOrderHistory.bind(
      this
    );
  }

  componentDidMount() {
    const { orderHistoryData, filters } = this.state;
    const { accountDetails } = this.props;
    this._isMounted = true;

    this.setState({
      isUserAdmin: accountDetails.isBuyerAdmin
    });

    const skip = orderHistoryData ? orderHistoryData.length : 0;
    const query = this.generateQueryString({ skip, limit: filters.limit });

    this.fetchOrderHistory(query, filters);

    page();
  }

  componentDidUpdate(prevProps) {
    const { pageConfig: previousPageConfig } = prevProps;
    const { pageConfig } = this.props;

    if (
      this._isMounted &&
      JSON.stringify(previousPageConfig) !== JSON.stringify(pageConfig)
    ) {
      // If the page config props change, it means that navigation happened to/from order history <=> invoice inquiry.
      this.resetPageData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  resetPageData() {
    const filters: Filters = emptyFilters;
    this.setState(
      {
        filters,
        selectedViewOption: this.viewOptions[0],
        orderHistoryData: null
      },
      () => {
        const query = this.generateQueryString({ limit: filters.limit });

        this.fetchOrderHistory(query, filters);
      }
    );
  }

  fetchOrderHistory(
    query: string = "",
    filters: Filters = {},
    /* Parameter errorId indicates where the error happened - in Filters section, or on Load More.
     If not present - error happened on initial data loading. */
    errorId?: ErrorId
  ) {
    const {
      auth: { logout },
      history
    } = this.props;
    const {
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;

    getOrderHistory(customerNumber, query, this.cancelToken)
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(res => {
        const { orderHistoryData } = this.state;
        const ordersData = res.data.length
          ? this.formatOrderItems(res.data)
          : [];

        // if skip (offset previously) is greater than 0, load more button is clicked, so concat values
        const concatedOrderHistoryData =
          filters.skip > 0
            ? getUniqueArray(orderHistoryData.concat(ordersData))
            : ordersData;

        const loadedAll =
          filters.skip > 0
            ? JSON.stringify(orderHistoryData) ===
              JSON.stringify(concatedOrderHistoryData)
            : false;

        if (this._isMounted) {
          this.setState({
            isLoadingHistory: false,
            isLoadingFilters: false,
            orderHistoryData: concatedOrderHistoryData,
            loadedAll
          });
        }
      })
      .catch(e => {
        if (!isCanceled(e)) {
          if (checkTokensExpired(e)) {
            this._isMounted = false;
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => fetchOrderHistory => OrderHistoryPage.tsx"
              })
            );
          } else if (this._isMounted && errorId) {
            const resultMessage: ResultMessage = {
              debugMessages: intl.get("custom-error-order-history"),
              id: errorId,
              type: "error"
            };
            this.setState({
              resultMessage,
              isLoadingHistory: false,
              isLoadingFilters: false
            });
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "fetchOrderHistory => OrderHistoryPage.tsx"
            });
          }
        }
      });
  }

  generateQueryString(filters: Filters, generateCSVQuery?: boolean) {
    const {
      pageConfig: { orderStatus }
    } = this.props;
    const { sortKey, sortSeq } = this.state;

    // This is for the Invoice Inquiry page.
    const optionalParameter =
      !generateCSVQuery && (scope !== "motili" && orderStatus === "I")
        ? "&orderStatus=I"
        : "";
    const csvString = generateCSVQuery ? "&download=CSV" : "";

    let query = `sortKey=${sortKey}&sortSeq=${SortSeq[sortSeq]}${optionalParameter}${csvString}`;

    Object.keys(filters).map(key => {
      let value = filters[key];
      if (value) {
        // adjust date format
        if (["dateFrom", "dateTo"].includes(key)) {
          value = dkFormatDate(value);
        }
        if (key === "orderNumbers" && scope !== "motili") {
          value = value.toUpperCase();
        }
        if (query.length > 1) {
          query += `&${key}=${value}`;
        } else {
          query += `${key}=${value}`;
        }
      }

      return value;
    });

    return query;
  }

  onFilterChange(
    event: { target: { name: any; value: any } },
    delay: boolean = false
  ) {
    let { filters } = this.state;
    const { value, name } = event.target;

    filters = {
      ...filters,
      [name]: value,
      skip: 0
    };

    if (this._isMounted) {
      this.setState(
        {
          isLoadingFilters: true,
          filters: { ...filters, [name]: value },
          resultMessage: emptyMessage
        },
        () => {
          if (delay) {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(this.fetchFilteredData, this.miliseconds);
          } else {
            this.fetchFilteredData();
          }
        }
      );
    }
  }

  fetchFilteredData() {
    const { filters } = this.state;
    const query = this.generateQueryString(filters);
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
    this.cancelToken = generateCancelToken();
    this.fetchOrderHistory(query, filters, ErrorId.FILTERS);
  }

  onLoadLimitChange(event) {
    const { filters } = this.state;
    const { value } = event.target;

    if (this._isMounted) {
      this.setState(
        {
          filters: { ...filters, limit: +value, skip: 0 },
          selectedViewOption: value
        },
        () => {
          const query = this.generateQueryString({
            ...filters,
            limit: +value,
            skip: 0
          });

          this.fetchOrderHistory(
            query,
            {
              ...filters,
              limit: +value,
              skip: 0
            },
            ErrorId.FILTERS
          );
        }
      );
    }
  }

  onLoadMore() {
    const { orderHistoryData, filters, selectedViewOption } = this.state;

    if (this._isMounted) {
      this.setState({ isLoadingHistory: true });
    }

    const newFilters: Filters = {
      ...filters,
      skip: orderHistoryData.length,
      limit: selectedViewOption
    };
    this.setState({ filters: newFilters }, () => {
      const query = this.generateQueryString(newFilters);
      this.fetchOrderHistory(query, newFilters, ErrorId.LOAD_MORE);
    });
  }

  onToggleFilters() {
    if (window.innerWidth < 768) {
      const { filterVisible } = this.state;

      if (this._isMounted) {
        this.setState({
          filterVisible: !filterVisible
        });
      }
    }
  }

  onReorderClick(event) {
    const {
      currentTarget: {
        dataset: { id: orderNumber }
      }
    } = event;
    const { isLoadingReorder, orderHistoryData } = this.state;

    if (!isLoadingReorder && orderHistoryData.length) {
      const order = orderHistoryData.filter(
        orderData => orderData.orderNumber === orderNumber
      );

      if (this._isMounted) {
        this.setState({
          isLoadingReorder: true,
          clickedItemId: orderNumber
        });
      }

      const orderItems =
        order[0] &&
        order[0].details
          .filter(item => item.sku !== "")
          .map((item: any) => {
            return {
              code: item.sku,
              quantity: item.quantityOrdered,
              name: item.description,
              price: item.linePrice
            };
          });

      this.onAddToCart(orderItems);
    } else {
      const message: ResultMessage = {
        debugMessages: intl.get("reorder-please-wait"),
        id: "",
        type: "needinfo"
      };
      if (this._isMounted) {
        this.setState({
          resultMessage: message
        });
      }
    }
  }

  onAddToCart(orderItems) {
    const {
      cart: {
        cartDetails: {
          defaultCart: { addItemsToCart }
        }
      }
    } = this.context;
    addToCart(addItemsToCart.self.uri, orderItems)
      .then(res => {
        // sends information to Segment for every product user adds
        orderItems.map(item => {
          productAdded(
            item.name,
            item.code,
            item.price,
            null,
            null,
            item.quantity
          );
          return item;
        });

        let itemQuantity = 0;
        orderItems.map(item => {
          itemQuantity += Number(item.quantity);
          return itemQuantity;
        });
        const {
          cart: { getCartDetails, setSuccesCartPopupMessage },
          history
        } = this.props;

        getCartDetails()
          .then(resp => {
            return setSuccesCartPopupMessage(itemQuantity);
          })
          .catch(e =>
            pushToMaintenace(history, {
              e,
              errIn: "getCartDetails => OrderHistoryPage.tsx"
            })
          );
        if (this._isMounted) {
          this.setState({
            isLoadingReorder: false
          });
        }
      })
      .catch(e => {
        const {
          auth: { logout },
          history
        } = this.props;
        if (checkTokensExpired(e)) {
          this._isMounted = false;
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => onAddToCart => OrderHistoryPage.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "onAddToCart => OrderHistoryPage.tsx"
          });
        }
      });
  }

  closeMessageContainer() {
    if (this._isMounted) {
      this.setState({
        resultMessage: emptyMessage
      });
    }
  }

  applyViewFilter(e) {
    const { filters } = this.state;
    const { selectedLimit, target } = getClickedOption(e);

    const {
      filters: { limit }
    } = this.state;

    if (`${limit}` === `${selectedLimit}` && target === "OPTION") {
      const query = this.generateQueryString({
        ...filters,
        skip: 0
      });

      this.fetchOrderHistory(
        query,
        {
          ...filters,
          skip: 0
        },
        ErrorId.FILTERS
      );
    }
  }

  formatOrderItems(
    data: Array<OrderItemData | MotiliOrderItemData>
  ): Array<OrderItemData> {
    if (scope === "motili") {
      return data.map((orderItem: MotiliOrderItemData) => {
        const statusList = OrderStatusList();
        const formattedDate = isoToSimpleDate(orderItem.orderedOn);
        const motiliOrderItem: OrderItemData = {
          orderNumber: orderItem.extReferenceNumber1,
          orderPlacedDate: formattedDate,
          purchaseOrderNumber: orderItem.extPoNumber,
          orderStatusLabel: statusList[orderItem.statusId],
          id: orderItem.id,
          _id: `${orderItem.id}-${orderItem.extReferenceNumber1}`
        };

        return motiliOrderItem;
      });
    }

    return data as Array<OrderItemData>;
  }

  checkOrderItem(orderNumber: string): void {
    this.setState(({ checkedOrderNumbers }) => ({
      checkedOrderNumbers: new Set(checkedOrderNumbers).add(orderNumber)
    }));
  }

  uncheckOrderItem(orderNumber: string): void {
    this.setState(({ checkedOrderNumbers }) => {
      const newChecked = new Set(checkedOrderNumbers);
      newChecked.delete(orderNumber);

      return {
        checkedOrderNumbers: newChecked
      };
    });
  }

  onOrderItemSelected(id: string): void {
    const { checkedOrderNumbers } = this.state;
    if (this._isMounted && id) {
      if (checkedOrderNumbers.has(id)) {
        this.uncheckOrderItem(id);
      } else {
        this.checkOrderItem(id);
      }
    }
  }

  // eslint-disable-next-line consistent-return
  async fetchInvoice(orderNumber: string, customerNumber: string) {
    try {
      const invoiceResponse = await getInvoiceReport(
        orderNumber,
        customerNumber
      );

      return { invoiceResponse, orderNumber };
    } catch (invoiceError) {
      const {
        auth: { logout },
        history
      } = this.props;

      if (invoiceError.response && invoiceError.response.status === 400) {
        return {
          errorMessage: intl.get("single-invoice-error", {
            invoiceNumber: orderNumber
          })
        };
      }
      if (checkTokensExpired(invoiceError)) {
        this._isMounted = false;
        logout().catch(tokenExpErr =>
          pushToMaintenace(history, {
            e: tokenExpErr,
            errIn: "Logout => fetchInvoice => OrderHistoryPage.tsx"
          })
        );
      } else {
        pushToMaintenace(history, {
          e: invoiceError,
          errIn: "fetchInvoice => OrderHistoryPage.tsx"
        });
      }
    }
  }

  onPrintInvoiceClick(): void {
    const {
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;

    const { checkedOrderNumbers } = this.state;
    const {
      auth: { logout },
      history
    } = this.props;

    const orderNumberList = [...checkedOrderNumbers];

    if (orderNumberList.length && customerNumber) {
      const promiseList = orderNumberList.map(
        (orderNumber: string): Promise<any> => {
          return this.fetchInvoice(orderNumber, customerNumber);
        }
      );

      Promise.all(promiseList)
        .then(responseList => {
          if (responseList && responseList.length) {
            const unavailableInvoices: Array<any> = responseList.filter(
              response => response.errorMessage
            );

            if (unavailableInvoices.length && this._isMounted) {
              this.setState({ invoiceErrors: unavailableInvoices });
            } else if (this._isMounted) {
              this.setState(
                {
                  invoiceErrors: null,
                  allInvoicesError: null
                },
                () => {
                  responseList.forEach(({ invoiceResponse, orderNumber }) =>
                    openInvoiceReport(invoiceResponse, orderNumber)
                  );
                }
              );
            }
          }
        })
        .catch(err => {
          const errorText = intl.get("multi-invoices-error");
          const { message } = err;

          if (checkTokensExpired(err)) {
            this._isMounted = false;
            logout().catch(tokenExpErr =>
              pushToMaintenace(history, {
                e: tokenExpErr,
                errIn: "Logout => onPrintInvoiceClick => OrderHistoryPage.tsx"
              })
            );
          } else if (this._isMounted) {
            this.setState({
              allInvoicesError: { debugMessage: message, errorText }
            });
          }
        });
    }
  }

  sortTable(e: any): void {
    const { sortSeq, filters, orderHistoryData } = this.state;
    const sortKey: string = e.currentTarget.id;
    const newFilters: Filters = {
      ...filters,
      skip: 0,
      limit: orderHistoryData.length
    };
    this.setState(
      {
        sortKey,
        sortSeq: Number(!sortSeq),
        filters: newFilters
      },
      () => {
        const query = this.generateQueryString(newFilters);
        this.fetchOrderHistory(query, newFilters);
      }
    );
  }

  renderPrintInvoiceButton() {
    const { checkedOrderNumbers, invoiceErrors, allInvoicesError } = this.state;

    const invoiceErrorMessages =
      invoiceErrors && invoiceErrors.length
        ? invoiceErrors.map(singleError => (
            <div
              key={singleError.errorMessage}
              className="invoice-error-message"
            >
              <span>{singleError.errorMessage}</span>
              <button
                type="button"
                className="close-invoice-error-button"
                aria-label={intl.get("close-invoice-error")}
                onClick={() => {
                  if (this._isMounted) {
                    this.setState(prevState => {
                      const newErrors = prevState.invoiceErrors.filter(
                        errItem =>
                          errItem.errorMessage !== singleError.errorMessage
                      );

                      return {
                        ...prevState,
                        invoiceErrors: newErrors
                      };
                    });
                  }
                }}
              >
                <i className="icon-close-x" />
              </button>
            </div>
          ))
        : null;

    const genericInvoiceErrorMessage = allInvoicesError ? (
      <div className="invoice-error-message">
        <span>{allInvoicesError}</span>
        <button
          type="button"
          className="close-invoice-error-button"
          aria-label={intl.get("close-invoice-error")}
          onClick={() => this.setState({ allInvoicesError: null })}
        >
          <i className="icon-close-x" />
        </button>
      </div>
    ) : null;

    return (
      <div className="print-invoice-container">
        <button
          type="button"
          className="ep-btn"
          onClick={this.onPrintInvoiceClick}
          aria-label={intl.get("print-invoice")}
          disabled={checkedOrderNumbers.size === 0}
        >
          {intl.get("print-invoice").toUpperCase()}
        </button>
        {invoiceErrorMessages}
        {genericInvoiceErrorMessage}
      </div>
    );
  }

  formatOrderHistoryDetails(data) {
    const res = data.map(
      ({
        _id,
        orderNumber,
        orderType,
        orderedDate,
        orderedBy,
        poNumber,
        jobNumber,
        jobName,
        orderStatus,
        branchNumber,
        lineNumber,
        productSku,
        productDescription,
        productQuantity,
        quantityShipped,
        invoiceNumber,
        invoiceDate,
        paymentType,
        shipAddress1,
        shipAddress2,
        shipCity,
        shipState,
        shippedDate,
        price,
        linePrice,
        ...more
      }) => {
        return {
          orderType,
          orderNumber,
          orderedDate: orderedDate ? simpleToLocaleDate(orderedDate) : "",
          orderedBy,
          poNumber,
          jobNumber,
          jobName,
          orderStatus,
          branchNumber,
          lineNumber,
          productSku,
          productDescription,
          productQuantityOrdered: productQuantity,
          invoiceNumber,
          invoiceDate: invoiceDate ? simpleToLocaleDate(invoiceDate) : "",
          paymentType,
          shipAddress1,
          shipAddress2,
          shipCity,
          shipState,
          shippedDate: shippedDate ? simpleToLocaleDate(shippedDate) : "",
          quantityShipped,
          price,
          linePrice,
          ...more
        };
      }
    );

    return res;
  }

  downloadDetailedOrderHistory() {
    const {
      user: {
        userProfile: { customerNumber }
      },
      auth: { logout }
    } = this.context;
    const { filters, orderHistoryData } = this.state;
    const { history } = this.props;
    this.setState({
      downloadError: ""
    });
    const limit = orderHistoryData.length;

    const query = this.generateQueryString(
      {
        ...filters,
        limit
      },
      true
    );

    getOrderHistoryDetails(customerNumber, query)
      .then(res => {
        const csv = new Parser().parse(
          this.formatOrderHistoryDetails(res.data)
        );
        const url = window.URL.createObjectURL(new Blob([csv]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "order-history-report.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(e => {
        console.error(e);
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => downloadDetailedOrderHistory => OrderHistoryPage.tsx"
            })
          );
        } else {
          this.setState({
            downloadError: intl.get("order-history-download-error")
          });
        }
      });
  }

  render() {
    const {
      isLoadingHistory,
      isLoadingFilters,
      isLoadingReorder,
      orderHistoryData,
      clickedItemId,
      filters,
      filterVisible,
      loadedAll,
      resultMessage,
      checkedOrderNumbers,
      selectedViewOption,
      sortKey,
      sortSeq,
      downloadError,
      isUserAdmin
    } = this.state;
    const {
      user: {
        userProfile: { jobsArray }
      }
    } = this.context;

    const { pageConfig } = this.props;

    const {
      title,
      usePrintInvoice,
      showStatusField,
      selectMultipleLines,
      orderStatus,
      showExportToCsv
    } = pageConfig;

    const statusList = {
      "": intl.get("all"),
      ...OrderStatusList()
    };

    const {
      brXM: { defaultChannel },
      orderHistoryShowJob,
      orderHistorySearchBySKU,
      orderHistoryShowTotal,
      orderHistorySorting,
      orderHistoryCSVExport,
      orderHistoryShowBranchNumber
    } = Config;
    const loadMoreButtonStyle =
      defaultChannel === "motili" ? "primary wide" : "secondary";

    const loadMoreButton = (
      <button
        type="button"
        className={`ep-btn ${loadMoreButtonStyle}`}
        onClick={this.onLoadMore}
        aria-label={intl.get("load-more")}
      >
        {isLoadingHistory ? (
          <div className="loader-container">
            <div className="miniLoader" />
          </div>
        ) : (
          intl.get("load-more")
        )}
      </button>
    );

    return orderHistoryData ? (
      <div
        className={`order-history-container content-box content-table ${
          isLoadingHistory ? `loading-history` : ``
        }`}
      >
        <h2 className="bullet">{title}</h2>
        {resultMessage && resultMessage.id === ErrorId.FILTERS ? (
          <MessageContainer
            message={resultMessage}
            closeContainerHandler={this.closeMessageContainer}
          />
        ) : null}
        <div className="table-filter-container">
          <div className="table-filter-heading">
            <div
              className="table-filter-info"
              onClick={this.onToggleFilters}
              onKeyDown={noOp}
              role="button"
              tabIndex={0}
            >
              <i className="icon-hamburger-menu" />
              {intl.get("filter")}
            </div>
            <div className="table-filter-display">
              <span>{intl.get("view")}</span>
              <select
                aria-label={intl.get("view")}
                name="limit"
                onChange={this.onLoadLimitChange}
                onClick={this.applyViewFilter}
                value={selectedViewOption}
              >
                {this.viewOptions.map((value, index) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {filterVisible && (
            <div className="row table-filter-filters">
              <div className={`col-12 col-sm-${showStatusField ? "4" : "6"}`}>
                <Input
                  label={`${intl.get("search")}:`}
                  type="text"
                  ariaLabel={intl.get("search")}
                  inputName="orderNumbers"
                  inputHandler={e => this.onFilterChange(e, true)}
                  value={filters.orderNumbers}
                  placeholder={intl.get("order-number")}
                />
              </div>
              <div className={`col-12 col-sm-${showStatusField ? "4" : "6"}`}>
                <Input
                  label="&nbsp;"
                  type="text"
                  ariaLabel="poNumber"
                  inputName="poNumbers"
                  inputHandler={e => this.onFilterChange(e, true)}
                  value={filters.poNumbers}
                  placeholder={intl.get("p-o-number")}
                />
              </div>
              {orderHistorySearchBySKU && (
                <div className={`col-12 col-sm-${showStatusField ? "4" : "6"}`}>
                  <Input
                    label="&nbsp;"
                    type="text"
                    inputName="skus"
                    ariaLabel="skus"
                    inputHandler={e => this.onFilterChange(e, true)}
                    value={filters.skus}
                    placeholder="Product Sku"
                  />
                </div>
              )}
              {orderHistoryShowBranchNumber && (
                <>
                  <div className="col-12 col-sm-4">
                    <Input
                      label="&nbsp;"
                      type="text"
                      inputName="branchNumber"
                      ariaLabel="branchNumber"
                      inputHandler={e => this.onFilterChange(e, true)}
                      value={filters.branchNumber}
                      placeholder={intl.get("branch-number")}
                    />
                  </div>
                  <div className="col-12 col-sm-8" />
                </>
              )}
              {showStatusField && (
                <div
                  className={`col-12 col-sm-${
                    orderHistorySearchBySKU ? "6" : "4"
                  } table-filter-status`}
                >
                  <div className="input-component">
                    <p className="label">{intl.get("status")}:</p>
                    <select
                      name={statusQueryParameter}
                      aria-label="order status"
                      value={filters[statusQueryParameter]}
                      onChange={this.onFilterChange}
                    >
                      {Object.keys(statusList).map(value => (
                        <option key={value} value={value}>
                          {statusList[value]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {orderHistoryShowJob && (
                <div className="col-12 col-sm-6 table-filter-status">
                  <div className="input-component">
                    <p className="label">{intl.get("job")}:</p>
                    <select
                      name="jobNumbers"
                      aria-label="job"
                      value={filters.jobNumbers}
                      onChange={this.onFilterChange}
                    >
                      <option key="" value="">
                        {intl.get("all")}
                      </option>
                      {jobsArray &&
                        jobsArray.map(job => (
                          <option key={job.jobNumber} value={job.jobNumber}>
                            {`${job.jobNumber}: ${job.jobName || ""}`}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              )}
              <div className="col-12 col-sm-6 input-component">
                <span className="label">{intl.get("date-from")}:</span>
                <input
                  type="date"
                  aria-label={intl.get("date-from")}
                  name="dateFrom"
                  onChange={this.onFilterChange}
                  value={filters.dateFrom || ""}
                  placeholder="mm/dd/yyyy"
                />
              </div>
              <div className="col-12 col-sm-6 input-component">
                <span className="label">{intl.get("date-to")}:</span>
                <input
                  type="date"
                  aria-label={intl.get("date-to")}
                  name="dateTo"
                  onChange={this.onFilterChange}
                  value={filters.dateTo || ""}
                  placeholder="mm/dd/yyyy"
                />
              </div>
              {orderHistoryCSVExport && showExportToCsv && isUserAdmin ? (
                <div className="col-12 col-sm-12 export-csv-button">
                  <button
                    type="button"
                    onClick={this.downloadDetailedOrderHistory}
                    className="ep-btn"
                  >
                    {intl.get("download-detailed-order-history")}
                  </button>
                </div>
              ) : null}
              <div className="col-12 col-sm-12 error">{downloadError}</div>
            </div>
          )}
        </div>
        {usePrintInvoice ? this.renderPrintInvoiceButton() : null}
        <div className="table-container">
          <table className="table">
            <thead className="thead table-labels">
              {orderHistorySorting ? (
                <tr className="thead-row">
                  <th
                    className="label sort"
                    id={SortKey.ORDER_NUMBER}
                    onClick={this.sortTable}
                  >
                    {sortKey === SortKey.ORDER_NUMBER &&
                      (sortSeq === 0 ? (
                        <i className="icon-chevron-down" />
                      ) : (
                        <i className="icon-chevron-up" />
                      ))}
                    {intl.get("order-number")}
                  </th>
                  <th
                    className="label sort"
                    id={SortKey.ORDER_DATE}
                    onClick={this.sortTable}
                  >
                    {sortKey === SortKey.ORDER_DATE &&
                      (sortSeq === 0 ? (
                        <i className="icon-chevron-down" />
                      ) : (
                        <i className="icon-chevron-up" />
                      ))}
                    {intl.get("ordered-date")}
                  </th>
                  {orderHistoryShowBranchNumber && (
                    <th
                      className="label sort"
                      id={SortKey.BRANCH_NUMBER}
                      onClick={this.sortTable}
                    >
                      {sortKey === SortKey.BRANCH_NUMBER &&
                        (sortSeq === 0 ? (
                          <i className="icon-chevron-down" />
                        ) : (
                          <i className="icon-chevron-up" />
                        ))}
                      {intl.get("branch-number")}
                    </th>
                  )}
                  <th
                    className="label sort"
                    id={SortKey.PO_NUMBER}
                    onClick={this.sortTable}
                  >
                    {sortKey === SortKey.PO_NUMBER &&
                      (sortSeq === 0 ? (
                        <i className="icon-chevron-down" />
                      ) : (
                        <i className="icon-chevron-up" />
                      ))}
                    {intl.get("p-o-number")}
                  </th>
                  {orderHistoryShowJob && (
                    <th
                      className="label sort"
                      id={SortKey.JOB}
                      onClick={this.sortTable}
                    >
                      {sortKey === SortKey.JOB &&
                        (sortSeq === 0 ? (
                          <i className="icon-chevron-down" />
                        ) : (
                          <i className="icon-chevron-up" />
                        ))}
                      {intl.get("job")}
                    </th>
                  )}
                  <th
                    className="label sort"
                    id={SortKey.STATUS}
                    onClick={this.sortTable}
                  >
                    {sortKey === SortKey.STATUS &&
                      (sortSeq === 0 ? (
                        <i className="icon-chevron-down" />
                      ) : (
                        <i className="icon-chevron-up" />
                      ))}
                    {intl.get("status")}
                  </th>
                  {orderHistoryShowTotal && (
                    <th
                      className="label sort"
                      id={SortKey.TOTAL}
                      onClick={this.sortTable}
                    >
                      {sortKey === SortKey.TOTAL &&
                        (sortSeq === 0 ? (
                          <i className="icon-chevron-down" />
                        ) : (
                          <i className="icon-chevron-up" />
                        ))}
                      {intl.get("total")}
                    </th>
                  )}
                  {/* <th className="label">{intl.get("action")}</th> */}
                </tr>
              ) : (
                <tr className="thead-row">
                  <th className="label">{intl.get("order-number")}</th>
                  <th className="label">{intl.get("ordered-date")}</th>
                  <th className="label">{intl.get("p-o-number")}</th>
                  {orderHistoryShowBranchNumber && (
                    <th className="label">{intl.get("branch-number")}</th>
                  )}
                  {orderHistoryShowJob && (
                    <th className="label">{intl.get("job")}</th>
                  )}
                  <th className="label">{intl.get("status")}</th>
                  {orderHistoryShowTotal && (
                    <th className="label">{intl.get("total")}</th>
                  )}
                </tr>
              )}
            </thead>
            <tbody className="tbody">
              {!isLoadingFilters && orderHistoryData.length ? (
                orderHistoryData.map(data => {
                  return (
                    <OrderItem
                      key={data.orderNumber}
                      data={data}
                      onReorderClick={this.onReorderClick}
                      isLoadingReorder={isLoadingReorder}
                      clickedItemId={clickedItemId}
                      noOp={noOp}
                      showCheckbox={selectMultipleLines}
                      onOrderItemSelected={this.onOrderItemSelected}
                      itemSelected={checkedOrderNumbers.has(data.orderNumber)}
                      isInvoice={orderStatus === "I"}
                    />
                  );
                })
              ) : (
                <tr>
                  <td className="no-results" colSpan={8}>
                    {isLoadingFilters ? (
                      <div className="miniLoader" />
                    ) : (
                      intl.get("no-results-found")
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {resultMessage && resultMessage.id === ErrorId.LOAD_MORE ? (
            <MessageContainer
              message={resultMessage}
              closeContainerHandler={this.closeMessageContainer}
            />
          ) : null}
          {!loadedAll && loadMoreButton}
        </div>
      </div>
    ) : (
      <>
        <div style={{ width: "100%" }}>
          <div className="loader" />
        </div>
      </>
    );
  }
}

export default OrderHistoryPage;
