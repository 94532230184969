import React from "react";

import "./search.results.scroll.to.top.button.less";

const SearchResultsScroppToTopButton = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <button
      type="button"
      className="scroll-to-top-button ep-btn"
      onClick={handleScrollToTop}
    >
      <i className="icon-chevron-up" />
    </button>
  );
};

export default SearchResultsScroppToTopButton;
