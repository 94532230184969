/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { zoomAddessForm } from "@zilker/store-components/src/static/zoom";
import { cortexFetch } from "../utils/Cortex";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";

import "./addressform.main.less";
import { AddressDetails } from "../AddressItem/AddressItem";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface AddressFormMainProps {
  addressData?: {
    [key: string]: any;
  };
  onCloseModal?: (...args: any[]) => any;
  fetchData?: (...args: any[]) => any;
  history?: any;
  auth?: any;
  onNewAddressWarning?: (addressUri: string) => void;
}
interface AddressFormMainState {
  geoData: any;
  firstName: string;
  lastName: string;
  address: string;
  extendedAddress: string;
  city: string;
  country: string;
  subCountry: string;
  postalCode: string;
  failedSubmit: boolean;
  addressForm: any;
  errorMsg: string;
  defaultAddress: AddressDetails;
  loadingAddress: boolean;
  type: any;
  jobNumber: string;
  jobName: string;
  phoneNumber: string;
}

interface AddressMessage {
  type: string;
  id: string;
  "debug-message": string;
  data: {
    message: string;
    status: string;
  };
}

class AddressFormMain extends React.Component<
  AddressFormMainProps,
  AddressFormMainState
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = getConfig());
    this.state = {
      geoData: [],
      firstName: "",
      lastName: "",
      address: "",
      extendedAddress: "",
      city: "",
      country: "",
      subCountry: "",
      postalCode: "",
      failedSubmit: false,
      addressForm: undefined,
      errorMsg: "",
      defaultAddress: null,
      loadingAddress: false,
      type: "",
      jobNumber: null,
      jobName: null,
      phoneNumber: ""
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.submitAddress = this.submitAddress.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    this.fetchGeoData();
    const { addressData } = this.props;
    if (addressData && addressData.address) {
      this.fetchAddressData(addressData.address);
    } else {
      this.fetchAddressForm();
    }

    const {
      user: {
        userProfile: { defaultShippingAddress }
      }
    } = this.context;
    this.setState({
      defaultAddress: defaultShippingAddress
    });
  }

  onInputChange(event: any): void {
    const { name, value } = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  submitAddress(event) {
    event.preventDefault();
    const {
      addressData,
      fetchData,
      onCloseModal,
      auth: { logout },
      history,
      onNewAddressWarning
    } = this.props;

    const {
      user: { getUserProfile }
    } = this.context;

    const {
      addressForm,
      firstName,
      lastName,
      address,
      extendedAddress,
      city,
      country,
      subCountry,
      postalCode,
      phoneNumber,
      type
    } = this.state;
    let link;
    let methodType;

    if (addressData && addressData.address) {
      link = addressData.address;
      methodType = "put";
    } else {
      link = addressForm;
      methodType = "post";
    }

    cortexFetch(`${link}?followlocation`, {
      method: methodType,
      body: JSON.stringify({
        name: {
          "given-name": firstName,
          "family-name": lastName
        },
        address: {
          "street-address": address,
          "extended-address": extendedAddress,
          locality: city,
          "country-name": country,
          region: subCountry,
          "postal-code": postalCode,
          "phone-number": phoneNumber
        },
        type: type || {
          billing: "false",
          shipping: "true"
        }
      })
    })
      .then(res => {
        if (res.status === 400) {
          return res.json();
        }
        return res;
      })
      .then(res => {
        const onSuccess = data => data;
        if (addressData && addressData.address) {
          checkResponse(res, onSuccess);
          return null;
        }
        return checkResponse(res);
      })
      .then(newAddressResponse => {
        if (newAddressResponse) {
          const newAddressUri = newAddressResponse.self.uri;
          const warningMessage: AddressMessage =
            newAddressResponse.messages && newAddressResponse.messages[0];
          if (warningMessage) {
            onNewAddressWarning(newAddressUri);
          }

          this.setState({ failedSubmit: false }, () => {
            getUserProfile();
            // Pass newly created address as a second, optional parameter, for sending a request to set it as a delivery address for the order.
            fetchData(undefined, newAddressUri);
            onCloseModal();
          });
        } else {
          this.setState({ failedSubmit: false }, () => {
            getUserProfile();
            fetchData();
            onCloseModal();

            if (methodType === "put") {
              onNewAddressWarning(addressData.address);
            }
          });
        }
      })
      .catch(e => {
        this.setState({ failedSubmit: true });
        if (
          e &&
          e.mssagess &&
          e.messages[0] &&
          e.messages[0]["debug-message"]
        ) {
          const errorMsg = e.messages[0]["debug-message"];
          this.setState({ errorMsg });
          return;
        }
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => submitAddress => AddressFormMain.tsx"
            })
          );
        } else {
          const malformedEmailErrorCode =
            e &&
            e.messages &&
            e.messages[0] &&
            e.messages[0].id &&
            e.messages[0].id === "field.invalid.email.format"
              ? intl.get("malformed-email-address-error-code")
              : null;

          pushToMaintenace(history, {
            e,
            errIn: "submitAddress => AddressFormMain.tsx",
            errorCode: malformedEmailErrorCode
          });
        }
      });
  }

  fetchGeoData(): void {
    const {
      auth: { logout },
      history
    } = this.props;
    const {
      user: {
        userProfile: { defaultShippingAddress }
      }
    } = this.context;
    // 7.4 Will expose the countries API at the root. In versions earlier than 7.4 we have to invoke geographies ourselves.
    cortexFetch(
      `/geographies/${
        Config.cortexApi.scope
      }/countries/?zoom=${zoomAddessForm.join()}`
    )
      .then(res => checkResponse(res))
      .then(res => {
        const countries = res._element.filter(
          element => element.name === defaultShippingAddress["country-name"]
        );
        this.setState({
          geoData: countries
        });
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => fetchGeoData => AddressFormMain.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "fetchGeoData => AddressFormMain.tsx"
          });
        }
      });
  }

  fetchAddressData(addressLink: string): void {
    const {
      auth: { logout },
      history
    } = this.props;

    cortexFetch(addressLink)
      .then(res => checkResponse(res))
      .then(res => {
        this.setState({
          firstName: res.name["given-name"],
          lastName: res.name["family-name"],
          address: res.address["street-address"],
          extendedAddress: res.address["extended-address"]
            ? res.address["extended-address"]
            : "",
          city: res.address.locality,
          country: res.address["country-name"],
          subCountry: res.address.region,
          postalCode: res.address["postal-code"],
          type: res.type,
          loadingAddress: true,
          jobNumber: res["job-number"],
          jobName: res["job-name"],
          phoneNumber: res.address["phone-number"]
        });
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => fetchAddressData => AddressFormMain.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "fetchAddressData => AddressFormMain.tsx"
          });
        }
      });
  }

  fetchAddressForm(): void {
    const {
      auth: { logout },
      history
    } = this.props;

    cortexFetch("/?zoom=defaultprofile:addresses:addressform")
      .then(res => checkResponse(res))
      .then(res => {
        const addressFormLink = res._defaultprofile[0]._addresses[0]._addressform[0].links.find(
          link => link.rel === "createaddressaction"
        ).uri;
        this.setState({
          addressForm: addressFormLink
        });
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => fetchAddressForm => AddressFormMain.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "fetchAddressForm => AddressFormMain.tsx"
          });
        }
      });
  }

  cancel(): void {
    const { onCloseModal } = this.props;
    onCloseModal();
  }

  renderCountries(): any {
    const { geoData } = this.state;
    if (geoData.length) {
      const sortedCountries = [].concat(geoData).sort((a, b) => {
        if (a["display-name"] > b["display-name"]) {
          return 1;
        }
        return -1;
      });
      return sortedCountries.map(country => (
        <option key={country.name} value={country.name}>
          {country["display-name"]}
        </option>
      ));
    }
    return null;
  }

  renderSubCountries(): any {
    const { country, geoData, subCountry } = this.state;
    if (country && geoData.length) {
      const countryData = geoData.find(element => element.name === country);
      if (countryData._regions[0]._element) {
        const sortedRegions = []
          .concat(countryData._regions[0]._element)
          .sort((a, b) => {
            if (a["display-name"] > b["display-name"]) {
              return 1;
            }
            return -1;
          });
        return (
          <div data-region="addressRegionsRegion" className="form-group">
            <div>
              <label
                htmlFor="subCountry"
                data-el-label="addressForm.region"
                className="control-label"
              >
                <span className="required-label">*</span>{" "}
                {country === "CA" ? intl.get("province") : intl.get("state")}
              </label>
              <div className="form-input activity-indicator-loading-region">
                <select
                  id="subCountry"
                  name="subCountry"
                  className="form-control"
                  value={subCountry}
                  onChange={this.onInputChange}
                  required
                >
                  <option value="" />
                  {sortedRegions.map(region => (
                    <option key={region.name} value={region.name}>
                      {region["display-name"]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      }
      return null;
    }
    return (
      <div data-region="addressRegionsRegion" className="form-group">
        <div>
          <label
            htmlFor="subCountry"
            data-el-label="addressForm.region"
            className="control-label"
          >
            <span className="required-label">*</span> {intl.get("state")}
          </label>
          <div className="form-input activity-indicator-loading-region">
            <select
              id="subCountry"
              name="subCountry"
              className="form-control"
              value={subCountry}
              onChange={this.onInputChange}
            >
              <option value="" />
            </select>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      failedSubmit,
      firstName,
      lastName,
      address,
      extendedAddress,
      city,
      country,
      subCountry,
      postalCode,
      errorMsg,
      defaultAddress,
      loadingAddress,
      type,
      jobNumber,
      jobName,
      phoneNumber
    } = this.state;

    const { addressData } = this.props;
    let isDefaultShippingAddress: boolean;
    if (addressData && addressData.address) {
      isDefaultShippingAddress =
        defaultAddress && defaultAddress.uri === addressData.address;
    } else {
      isDefaultShippingAddress = false;
    }
    const isBilling: boolean = type && type.billing === "true";
    const isJobLinkedAddress: boolean = jobNumber !== null || jobName !== null;

    return (
      <div className="address-form-component container" data-region="appMain">
        <div
          className="feedback-label feedback-container"
          data-region="componentAddressFeedbackRegion"
        >
          {failedSubmit && errorMsg}
        </div>
        {loadingAddress || !addressData ? (
          <>
            {addressData && !phoneNumber && (
              <p className="address-type-msg">
                {intl.get("phone-number-is-mandatory-field")}
              </p>
            )}
            {isBilling && (
              <div>
                <p className="address-type-msg">
                  {intl.get("billing-address-msg")}
                </p>
              </div>
            )}
            {isDefaultShippingAddress && !isBilling && (
              <div>
                <p className="address-type-msg">
                  {intl.get("default-address-msg")}
                </p>
              </div>
            )}
            {isJobLinkedAddress && (
              <p className="address-type-msg">
                {intl.get("job-linked-address-msg")}
              </p>
            )}
            <form className="form-horizontal" onSubmit={this.submitAddress}>
              <div className="form-group">
                <label
                  htmlFor="firstName"
                  data-el-label="addressForm.firstName"
                  className="control-label"
                >
                  <span className="required-label">*</span>{" "}
                  {intl.get("first-name")}
                </label>
                <div className="form-input">
                  {/* eslint-disable-next-line max-len */}
                  <input
                    id="registration_form_firstName"
                    name="firstName"
                    className="form-control"
                    type="text"
                    value={firstName}
                    onChange={this.onInputChange}
                    required
                    maxLength={100}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="lastName"
                  data-el-label="addressForm.lastName"
                  className="control-label"
                >
                  <span className="required-label">*</span>{" "}
                  {intl.get("last-name")}
                </label>
                <div className="form-input">
                  {/* eslint-disable-next-line max-len */}
                  <input
                    id="registration_form_lastName"
                    name="lastName"
                    className="form-control"
                    type="text"
                    value={lastName}
                    onChange={this.onInputChange}
                    required
                    maxLength={100}
                  />
                </div>
              </div>
              {/* eslint-disable-next-line no-nested-ternary */}
              {!isBilling &&
              !isDefaultShippingAddress &&
              !isJobLinkedAddress ? (
                <>
                  <div className="form-group">
                    <label
                      htmlFor="address"
                      data-el-label="addressForm.streetAddress"
                      className="control-label"
                    >
                      <span className="required-label">*</span>{" "}
                      {intl.get("street-address")}
                    </label>
                    <div className="form-input">
                      <input
                        id="address"
                        name="address"
                        className="form-control"
                        type="text"
                        value={address}
                        onChange={this.onInputChange}
                        required
                        maxLength={30}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="extendedAddress"
                      data-el-label="addressForm.extendedAddress"
                      className="control-label"
                    >
                      {intl.get("extended-address")}
                    </label>
                    <div className="form-input">
                      {/* eslint-disable-next-line max-len */}
                      <input
                        id="extendedAddress"
                        name="extendedAddress"
                        className="form-control"
                        type="text"
                        value={extendedAddress}
                        onChange={this.onInputChange}
                        maxLength={30}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="city"
                      data-el-label="addressForm.city"
                      className="control-label"
                    >
                      <span className="required-label">*</span>{" "}
                      {intl.get("city")}
                    </label>
                    <div className="form-input">
                      <input
                        id="city"
                        name="city"
                        className="form-control"
                        type="text"
                        value={city}
                        onChange={this.onInputChange}
                        required
                        maxLength={25}
                      />
                    </div>
                  </div>
                  <div
                    data-region="addressCountryRegion"
                    className="form-group"
                    style={{ display: "block" }}
                  >
                    <label
                      htmlFor="country"
                      data-el-label="addressForm.country"
                      className="control-label"
                    >
                      <span className="required-label">*</span>{" "}
                      {intl.get("country")}
                    </label>
                    <div className="form-input">
                      <select
                        id="country"
                        name="country"
                        className="form-control"
                        value={country}
                        onChange={this.onInputChange}
                        required
                      >
                        <option value="" />
                        {this.renderCountries()}
                      </select>
                    </div>
                  </div>
                  {this.renderSubCountries()}
                  <div className="form-group">
                    <label
                      htmlFor="postalCode"
                      data-el-label="addressForm.postalCode"
                      className="control-label"
                    >
                      <span className="required-label">*</span>{" "}
                      {country === "CA"
                        ? intl.get("postal-code")
                        : intl.get("zip-code")}
                    </label>
                    <div className="form-input">
                      <input
                        id="postalCode"
                        name="postalCode"
                        className="form-control"
                        type="text"
                        value={postalCode}
                        onChange={this.onInputChange}
                        required
                        maxLength={10}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="phoneNumber"
                      data-el-label="addressForm.phoneNumber"
                      className="control-label"
                    >
                      <span className="required-label">*</span>{" "}
                      {intl.get("phone-number")}
                    </label>
                    <div className="form-input">
                      <input
                        id="phoneNumber"
                        name="phoneNumber"
                        className="form-control"
                        type="text"
                        value={phoneNumber}
                        onChange={this.onInputChange}
                        required
                        maxLength={30}
                      />
                    </div>
                  </div>
                </>
              ) : !phoneNumber || isJobLinkedAddress ? (
                <div className="form-group">
                  <label
                    htmlFor="phoneNumber"
                    data-el-label="addressForm.phoneNumber"
                    className="control-label"
                  >
                    <span className="required-label">*</span>{" "}
                    {intl.get("phone-number")}
                  </label>
                  <div className="form-input">
                    <input
                      id="phoneNumber"
                      name="phoneNumber"
                      className="form-control"
                      type="text"
                      value={phoneNumber}
                      onChange={this.onInputChange}
                      required
                      maxLength={30}
                    />
                  </div>
                </div>
              ) : null}
              <div className="form-group form-btn-group">
                <div className="control-label" />
                <div className="form-input btn-container">
                  <button
                    className="ep-btn"
                    aria-label={intl.get("cancel")}
                    data-el-label="addressForm.cancel"
                    type="button"
                    onClick={() => {
                      this.cancel();
                    }}
                  >
                    {intl.get("cancel")}
                  </button>
                  <button
                    className="ep-btn primary address-save-btn"
                    aria-label={intl.get("save")}
                    data-el-label="addressForm.save"
                    type="submit"
                  >
                    {intl.get("save")}
                  </button>
                </div>
              </div>
            </form>
          </>
        ) : (
          <div className="loader" />
        )}
      </div>
    );
  }
}

export default AddressFormMain;
