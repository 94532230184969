import React, { useEffect, useState, useLayoutEffect } from "react";
import jsonpointer from "jsonpointer";
import {
  createLink,
  getImageUrl,
  parseAndRewriteLinks,
  getNestedObject,
  CmsEditButton
} from "bloomreach-experience-react-sdk";
import $ from "jquery";

import "./enhanced-list.less";
import EnhancedListCarouselTitle from "./enhanced-list.carousel-title";
import EnhancedListGrid from "./enhanced-list.grid";

const EnhancedList = props => {
  const { configuration, pageModel, preview } = props;

  const params = getNestedObject(configuration, ["_meta", "paramsInfo"]);
  const isSecondaryCarousel =
    params.displayMode === "carousel_titled_secondary";

  const renderCarouselType = () => {
    switch (params.displayMode) {
      case "carousel_titled":
        return (
          <EnhancedListCarouselTitle
            configuration={configuration}
            pageModel={pageModel}
            preview={preview}
          />
        );
      case "carousel_titled_secondary":
        return (
          <EnhancedListCarouselTitle
            configuration={configuration}
            pageModel={pageModel}
            preview={preview}
          />
        );
      case "grid":
        return (
          <EnhancedListGrid
            configuration={configuration}
            pageModel={pageModel}
            preview={preview}
          />
        );
      default:
        return <span>Default List</span>;
    }
  };

  return (
    <div
      className={`enhanced-list-container${
        isSecondaryCarousel ? " secondary-carousel" : ""
      }`}
    >
      {renderCarouselType()}
    </div>
  );
};

export default EnhancedList;
