import React from "react";
import jsonpointer from "jsonpointer";
import {
  createLink,
  getImageUrl,
  parseAndRewriteLinks,
  getNestedObject,
  CmsEditButton
} from "bloomreach-experience-react-sdk";

const Facets = props => {
  const { configuration, pageModel, preview } = props;
  const facets = getNestedObject(configuration, ["models", "facets"]);
  const contents = jsonpointer.get(pageModel, facets.$ref);

  // The facet component is currently unsupported by Bloomreach Essentials Blog Module
  // for sites using the PageModel API.
  return <span />;
};

export default Facets;
