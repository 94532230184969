import React, { useState } from "react";
import intl from "react-intl-universal";

import "./PreferencesContainer.less";

/**
 * ## getUserMarketingPreferences
 * @param userProfile any - the user profile object from Profile Context
 * @description Evaluates if the user is sub user or primary one, and returns the indicatining opt-out property
 * name and value as boolean. The value returned corresponds to the UI checkbox.
 *
 * If the user has been opt out from receiving the marketing communications - the value will be "true" - checkbox won't be
 * checked. If the opt out property is "false" - then the user receives marketing communications - checkbox will be selected.
 *
 * Example: (default)
 * {... "sub-user-opt-out-marketing" :"false",...} => this is sub user, that receives marketing communications.
 */
const getUserMarketingPreferences = (userProfile: any): [string, boolean] => {
  const isSubUser = userProfile.subUserOptOutMarketing;
  if (isSubUser) {
    return [
      "sub-user-opt-out-marketing",
      userProfile.subUserOptOutMarketing === "false"
    ];
  }
  /**
   * If the sub user property is empty (equal to an empty string) - then this is a primary user.
   */
  return ["opt-out-marketing", userProfile.optOutMarketing === "false"];
};

interface PreferencesContainerProps {
  userProfile: any;
  onSaveChanges: (...args) => any;
}

const PreferencesContainer: React.FC<PreferencesContainerProps> = ({
  userProfile,
  onSaveChanges
}) => {
  /**
   * When the property (sub-user-)opt-out-marketing on the user's profile is set to false, it means that the user will
   * receive marketing communications. If the user unchecks the checkbox, this value should be set to true, to let user opt out.
   */

  const [, initialMarketingChecked] = getUserMarketingPreferences(userProfile);

  const [
    marketingCommunicationsChecked,
    setMarketingCommunicationsChecked
  ] = useState<boolean>(initialMarketingChecked);

  const handleCheckboxClick = () => {
    setMarketingCommunicationsChecked(!marketingCommunicationsChecked);
  };

  const handleSaveClick = () => {
    const [propertyName, userMarketingCommunicationsChecked]: [
      string,
      boolean
    ] = getUserMarketingPreferences(userProfile);

    if (userMarketingCommunicationsChecked !== marketingCommunicationsChecked) {
      onSaveChanges({
        [propertyName]: `${!marketingCommunicationsChecked}` // checkbox selection is opposite to user opt-out property value
      });
    }
  };

  return (
    <div className="content-box">
      <h4 className="bullet">{intl.get("preferences")}</h4>
      <div className="preferences-content">
        <span className="label">{intl.get("marketing")}</span>
        <label
          className="preferences-label"
          htmlFor="marketing"
          id="marketing-label"
        >
          <input type="checkbox" name="marketing" id="checkbox" />
          <span
            className={`preferences-checkbox
           ${
             marketingCommunicationsChecked
               ? "show-checkmark"
               : "hide-checkmark"
           }`}
            onClick={handleCheckboxClick}
            onKeyDown={handleCheckboxClick}
            role="checkbox"
            aria-checked="false"
            tabIndex={0}
            aria-labelledby="marketing-label"
            id="marketingCommunicationsChecked"
          />
          {intl.get("receives-marketing-communications")}
        </label>
        <button
          aria-label={intl.get("save")}
          type="button"
          className="ep-btn primary"
          onClick={handleSaveClick}
        >
          {intl.get("save")}
        </button>
      </div>
    </div>
  );
};

export default PreferencesContainer;
