import React from "react";
import jsonpointer from "jsonpointer";
import {
  parseAndRewriteLinks,
  getNestedObject
} from "bloomreach-experience-react-sdk";

import "./header-banner-ticker.less";

const HeaderBannerTicker = props => {
  const { content } = props;
  const { text, backgroundColor, textColor, size } = content;
  const style = {
    backgroundColor,
    color: textColor,
    fontSize: size
  };
  return (
    content && (
      <div className="header-banner-ticker" style={style}>
        {text}
      </div>
    )
  );
};

export default HeaderBannerTicker;
