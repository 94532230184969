/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */
import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import intl from "react-intl-universal";
import Modal from "react-responsive-modal";

import {
  AddressContainer,
  AddressFormMain,
  CheckoutSummaryList,
  cortexFetch,
  PaymentMethodContainer,
  PaymentMethods,
  ShippingOptionContainer,
  GeneralOrderInformation,
  Messagecontainer,
  getConfig,
  zoom,
  checkoutStarted,
  page,
  AddPromotionContainer
} from "@zilker/store-components";

import "./CheckoutPage.less";
import {
  NotificationsStatus,
  NotificationData,
  NotificationsError
} from "@zilker/store-components/src/Notifications/Notifications";
import { MainContext } from "../contexts/MainContext";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace,
  noOp,
  formatInventoryAvailability,
  createNotificationsPhoneList,
  formatDGAInventory
} from "../utils/helpers";
import {
  validateNotifications,
  validateGeneralOrderInformation,
  validateRequiredMethod
} from "../validation/checkoutValidation";
import {
  getAvailabilityMotili,
  getAvailabilityDGA
} from "../services/connectServices";
import { ContractOrder } from "./ContractOrdersPage";

interface CheckoutPageProps {
  match: any;
  history: any;
}

interface GeneralOrderInformationForm {
  // See DGE-3145. Alternate email input is removed.
  // "alternate-email-address": string;
  "date-needed": string;
  "order-comments": string;
  "po-number": string;
  "shipping-instructions": string;
  date: string;
  time: string;
  "ship-complete": boolean;
  "show-pick-tickets-price": boolean;
  phone: string;
}

const initialOrderInformation: GeneralOrderInformationForm = {
  // "alternate-email-address": "",
  "date-needed": "",
  "order-comments": "",
  "po-number": "",
  "shipping-instructions": "",
  date: "",
  time: "",
  "ship-complete": false,
  "show-pick-tickets-price": false,
  phone: ""
};

const initialOrderInformationErrors = {
  // "alternate-email-address": "",
  "po-number": "",
  date: "",
  time: "",
  phone: ""
};

enum JobAddressActions {
  CHANGE = "change",
  ADD_NEW = "new",
  CLOSE = ""
}

interface ClientEntity {
  name: string;
  id: string;
}

interface ClientInformation {
  "client-name": string;
  "client-id": string;
}

const defaultClient: ClientEntity = {
  name: "",
  id: ""
};

// If the showUserNotifications flag is set to false, then all notifications are disabled.
const disabledNotifications: NotificationsStatus = {
  smsChecked: false,
  orderStatusChecked: false,
  deliveryStatusChecked: false
};

interface CheckoutPageState {
  orderData: any;
  isLoading: boolean;
  profileData: any;
  // showGiftCard: boolean;
  certificates: any;
  openNewPaymentModal: boolean;
  openAddressModal: boolean;
  addressUrl: any;
  cartOrderDetails: any;
  selectedPayment: string;
  paymentLoading: boolean;
  deliveryLoading: boolean;
  kinpayUrl: string;
  kinpayPrid: string;
  kinpayRedirectPrid: string;
  disableCompleteOrder: boolean;
  openKinpayModal: boolean;
  error: string;
  shippingOptions: any;
  shippingAddresses: any;
  selectedShippingOption: any;
  selectedShippingAddress: any;
  cartItems: any;
  cartDataFetched: any;
  missingFields: string;
  orderInformation: GeneralOrderInformationForm;
  notificationsStatus: NotificationsStatus;
  notificationsData: NotificationData;
  orderInformationErrors: any;
  notificationsErrors: NotificationsError;
  backOrdered: boolean;
  openJobAddressModal: JobAddressActions;
  pendingShippingAddress: any;
  jobNumber: any;
  jobName: any;
  newAddressUri: string;
  selectedClient: ClientEntity;
  currentBranch: number;
  blockKinpay: boolean;
  selectedDeliveryMethodError: string;
  selectedPaymentMethodError: string;
  cashUserOnlyError: string;
  inventoryError: string;
}

let Config;

class CheckoutPage extends React.Component<
  CheckoutPageProps,
  CheckoutPageState
> {
  static contextType = MainContext;

  _isMounted = false;

  _kinpayApproved = false;

  private generaOrderInformationRef: React.RefObject<HTMLDivElement>;

  private fulfillmentRef: React.RefObject<HTMLDivElement>;

  private billingRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    Config = getConfig().config;
    const {
      match: {
        params: { kinpayPrid }
      }
    } = this.props;

    this._kinpayApproved = false;

    this.state = {
      orderData: undefined,
      isLoading: false,
      profileData: undefined,
      // showGiftCard: false,
      certificates: [],
      openNewPaymentModal: false,
      openAddressModal: false,
      addressUrl: undefined,
      cartOrderDetails: undefined,
      selectedPayment: "",
      paymentLoading: false,
      deliveryLoading: false,
      kinpayUrl: "",
      kinpayPrid: "",
      kinpayRedirectPrid: kinpayPrid,
      disableCompleteOrder: true,
      openKinpayModal: false,
      error: "",
      shippingOptions: null,
      shippingAddresses: null,
      selectedShippingOption: null,
      selectedShippingAddress: null,
      cartItems: undefined,
      cartDataFetched: undefined,
      missingFields: "",
      orderInformation: initialOrderInformation,
      notificationsStatus: undefined,
      notificationsData: undefined,
      orderInformationErrors: initialOrderInformationErrors,
      notificationsErrors: undefined,
      backOrdered: undefined,
      openJobAddressModal: JobAddressActions.CLOSE,
      pendingShippingAddress: null,
      jobNumber: null,
      jobName: null,
      newAddressUri: "",
      selectedClient: defaultClient,
      currentBranch: null,
      blockKinpay: false,
      selectedDeliveryMethodError: "",
      selectedPaymentMethodError: "",
      cashUserOnlyError: "",
      inventoryError: ""
    };

    this.generaOrderInformationRef = React.createRef();
    this.fulfillmentRef = React.createRef();
    this.billingRef = React.createRef();

    /**
     * @todo - Move bulk of these methods out of Component class.
     */

    this.fetchOrderData = this.fetchOrderData.bind(this);
    this.newAddress = this.newAddress.bind(this);
    this.editAddress = this.editAddress.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.newPayment = this.newPayment.bind(this);
    this.reviewOrder = this.reviewOrder.bind(this);
    this.handleCertificate = this.handleCertificate.bind(this);
    this.handleCloseAddressModal = this.handleCloseAddressModal.bind(this);
    this.handleCloseNewPaymentModal = this.handleCloseNewPaymentModal.bind(
      this
    );
    this.renderShippingAddress = this.renderShippingAddress.bind(this);
    this.renderNewAddressModal = this.renderNewAddressModal.bind(this);
    this.renderShippingAddressSelector = this.renderShippingAddressSelector.bind(
      this
    );
    this.renderShippingOptions = this.renderShippingOptions.bind(this);
    this.renderShippingOptionsSelector = this.renderShippingOptionsSelector.bind(
      this
    );
    // this.renderBillingAddress = this.renderBillingAddress.bind(this);
    // this.renderBillingAddressSelector = this.renderBillingAddressSelector.bind(
    //   this
    // );
    // this.renderPayments = this.renderPayments.bind(this);
    // this.renderPaymentSelector = this.renderPaymentSelector.bind(this);

    this.editOrderDetails = this.editOrderDetails.bind(this);

    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.generateShippingOptions = this.generateShippingOptions.bind(this);
    this.generateShippingAddresses = this.generateShippingAddresses.bind(this);
    this.onCompleteOrder = this.onCompleteOrder.bind(this);
    this.handleOrderInformationChange = this.handleOrderInformationChange.bind(
      this
    );
    this.handleNotificationsStatusChange = this.handleNotificationsStatusChange.bind(
      this
    );
    this.handleNotificationsDataChange = this.handleNotificationsDataChange.bind(
      this
    );
    this.handleShipCompleteChange = this.handleShipCompleteChange.bind(this);
    this.renderJobAddressModal = this.renderJobAddressModal.bind(this);
    this.controlJobAddressModal = this.controlJobAddressModal.bind(this);
    this.isAddressAssociatedWithJob = this.isAddressAssociatedWithJob.bind(
      this
    );
    this.setNewAddressUri = this.setNewAddressUri.bind(this);
    this.renderSelectedClient = this.renderSelectedClient.bind(this);
    this.onClientSelect = this.onClientSelect.bind(this);
    this.setSelectedClient = this.setSelectedClient.bind(this);
    this.renderPromotions = this.renderPromotions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("message", this.handleKinpayCallback);

    const {
      cart: {
        cartDetails: { totalCount, cartDataFetched, defaultCart }
      },
      user: { userProfile }
    } = this.context;

    const jobNumber = defaultCart ? defaultCart.jobNumber : null;
    const jobName = defaultCart ? defaultCart.jobName : null;

    const { clientList } = userProfile;
    // eslint-disable-next-line no-nested-ternary
    const selectedClient: ClientEntity = defaultCart
      ? { id: defaultCart.clientId, name: defaultCart.clientName }
      : clientList && clientList.length
      ? { name: clientList[0].name, id: clientList[0].id }
      : defaultClient;

    const selectedBranch = defaultCart ? defaultCart.selectedBranch : {};

    this._isMounted = true;

    this.setState({
      cartItems: totalCount,
      cartDataFetched,
      jobName,
      jobNumber,
      selectedClient,
      currentBranch: selectedBranch.code
    });

    this.populateSelectedPaymentOption();

    this.checkBackOrderedItems();

    // this.fetchGiftCardsData();

    const { kinpayRedirectPrid } = this.state;

    if (
      kinpayRedirectPrid &&
      kinpayRedirectPrid === sessionStorage.getItem("kinpayPrid")
    ) {
      this.setState({ disableCompleteOrder: false });
    }

    page();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      cart: {
        cartDetails: { totalCount, cartDataFetched, defaultCart }
      },
      branches: { branchesList },
      user: {
        userProfile: { creditCardAllowed, cashAllowed, creditLineAllowed }
      }
    } = this.context;

    const jobNumber = defaultCart ? defaultCart.jobNumber : null;
    const jobName = defaultCart ? defaultCart.jobName : null;

    const {
      cartItems: prevCartItems,
      cartDataFetched: prevCartDataFetched,
      jobNumber: prevJobNumber,
      jobName: prevJobName,
      selectedClient: prevSelectedClient
    } = prevState;

    if (
      (cartDataFetched !== prevCartDataFetched ||
        totalCount !== prevCartItems) &&
      defaultCart &&
      branchesList
    ) {
      this.updateCartState(totalCount, cartDataFetched);
    }

    const { selectedPayment, currentBranch } = this.state;
    if (
      creditLineAllowed &&
      cashAllowed &&
      creditCardAllowed &&
      !selectedPayment
    ) {
      this.populateSelectedPaymentOption();
    }

    if (prevJobName !== jobName || prevJobNumber !== jobNumber) {
      this.updateJobState(jobNumber, jobName);
    }

    if (defaultCart && prevSelectedClient.id !== defaultCart.clientId) {
      this.setSelectedClient({
        id: defaultCart.clientId,
        name: defaultCart.clientName
      });
    }

    const selectedBranch = defaultCart ? defaultCart.selectedBranch : {};
    if (
      defaultCart &&
      branchesList &&
      (!currentBranch || selectedBranch.code !== currentBranch)
    ) {
      this.setCurrentBranch(selectedBranch.code);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    sessionStorage.removeItem("kinpayPrid");
    window.removeEventListener("message", this.handleKinpayCallback);
  }

  updateJobState(jobNumber, jobName) {
    this.setState({ jobNumber, jobName });
    this.fetchOrderData();
  }

  updateCartState(cartItems, cartDataFetched) {
    this.setState({ cartItems, cartDataFetched });
  }

  async populateSelectedPaymentOption() {
    const {
      user: {
        userProfile: { cashAllowed, creditLineAllowed, creditCardAllowed }
      }
    } = this.context;
    const {
      cortexApi: { scope }
    } = Config;
    let selectedPayment = "";

    if (creditLineAllowed === "true") {
      selectedPayment = PaymentMethods.creditLine;
    } else if (creditLineAllowed === "false" && cashAllowed === "true") {
      selectedPayment = PaymentMethods.cash;
    } else if (
      creditLineAllowed === "false" &&
      cashAllowed === "false" &&
      creditCardAllowed === "true"
    ) {
      selectedPayment = PaymentMethods.kinpay;
    }

    if (scope === "motili") {
      selectedPayment = PaymentMethods.billToClient;
    }

    if (selectedPayment) {
      this.setState({
        selectedPayment
      });
      await this.fetchCartOrderDetailsForm();
      await this.fetchOrderData();
      await this.handlePaymentChange(selectedPayment);
    }
  }

  fetchProfileData() {
    const { auth } = this.context;

    if (auth.isLoggedIn) {
      const { zoomArrayProfile } = zoom;
      const { history } = this.props;
      const {
        context: {
          auth: { logout }
        }
      } = this;
      cortexFetch(`/?zoom=${zoomArrayProfile.join()}`)
        .then(res => checkResponse(res))
        .then(res => {
          if (this._isMounted) {
            this.setState({
              profileData: res._defaultprofile[0]
            });
          }
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => fetchProfileData => CheckoutPage.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "fetchProfileData => CheckoutPage.tsx"
            });
          }
        });
    }
  }

  formatCartOrderDetails(cartOrderDetails) {
    const { _cartorderdetailsform } = cartOrderDetails;
    const {
      user: { userProfile }
    } = this.context;
    const {
      email: defaultEmail,
      subuserEmail,
      showPriceOnPickedTicket
    } = userProfile;

    const { displayPriceOnPickedTickets } = Config;

    const orderInformation: GeneralOrderInformationForm = {
      // "alternate-email-address":
      //   _cartorderdetailsform[0]["alternate-email-address"] || "",
      "date-needed": _cartorderdetailsform[0]["date-needed"],
      "order-comments": _cartorderdetailsform[0]["order-comments"] || "",
      "po-number": _cartorderdetailsform[0]["po-number"] || "",
      "shipping-instructions":
        _cartorderdetailsform[0]["shipping-instructions"] || "",
      date: _cartorderdetailsform[0]["date-needed"].split(" ")[0],
      time: "",
      "ship-complete":
        _cartorderdetailsform[0]["ship-complete"] === null
          ? false
          : _cartorderdetailsform[0]["ship-complete"],
      "show-pick-tickets-price":
        // eslint-disable-next-line no-nested-ternary
        _cartorderdetailsform[0]["show-pick-tickets-price"] === null
          ? displayPriceOnPickedTickets
            ? showPriceOnPickedTicket
            : false
          : _cartorderdetailsform[0]["show-pick-tickets-price"],
      phone: ""
    };

    const clientInformation: ClientInformation = {
      "client-name": _cartorderdetailsform[0]["client-name"] || "",
      "client-id": _cartorderdetailsform[0]["client-id"] || ""
    };

    const smsPhoneNumbersList = createNotificationsPhoneList(
      _cartorderdetailsform[0].phone,
      _cartorderdetailsform[0]["sms-phone-numbers"]
    );

    const notificationsStatus = Config.showUserNotifications
      ? {
          smsChecked: _cartorderdetailsform[0]["receive-sms-alerts"],
          orderStatusChecked:
            _cartorderdetailsform[0]["receive-order-status-emails"],
          deliveryStatusChecked: true
        }
      : disabledNotifications;

    const notificationsData = {
      phoneNumbers: smsPhoneNumbersList ? smsPhoneNumbersList.split("|") : [],
      orderStatusEmails: _cartorderdetailsform[0]["order-status-emails"]
        ? _cartorderdetailsform[0]["order-status-emails"].split("|")
        : [subuserEmail || defaultEmail],
      deliveryStatusEmail:
        _cartorderdetailsform[0]["delivery-status-emails"] ||
        (subuserEmail || defaultEmail)
    };

    return {
      orderInformation,
      notificationsStatus,
      notificationsData,
      clientInformation
    };
  }

  async fetchCartOrderDetailsForm() {
    const { history } = this.props;
    const {
      auth: { logout }
    } = this.context;
    cortexFetch(`/?zoom=${zoom.zoomFetchCartOrderDetailsForm}`)
      .then(res => checkResponse(res))
      .then(res => {
        const { _cartorderdetails } = res._defaultcart[0]._order[0];
        const cartOrderDetails = this.formatCartOrderDetails(
          _cartorderdetails[0]
        );

        let populatedClient;
        if (
          cartOrderDetails.clientInformation["client-name"] &&
          cartOrderDetails.clientInformation["client-id"]
        ) {
          populatedClient = {
            name: cartOrderDetails.clientInformation["client-name"],
            id: cartOrderDetails.clientInformation["client-id"]
          };
        }
        this.setState(prevState => {
          return {
            ...prevState,
            cartOrderDetails: _cartorderdetails[0],
            orderInformation: cartOrderDetails.orderInformation,
            notificationsStatus: Config.showUserNotifications
              ? cartOrderDetails.notificationsStatus
              : disabledNotifications,
            notificationsData: cartOrderDetails.notificationsData,
            selectedClient: populatedClient || prevState.selectedClient
          };
        });
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => fetchCartOrderDetailsForm => CheckoutPage.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "fetchCartOrderDetailsForm => CheckoutPage.tsx"
          });
        }
      });
  }

  async fetchOrderData(newAddressUri?: string) {
    const { auth } = this.context;
    if (auth.isLoggedIn) {
      const { zoomFetchOrder } = zoom;
      const { history } = this.props;
      const {
        context: {
          auth: { logout }
        }
      } = this;

      await cortexFetch(`/?zoom=${zoomFetchOrder.sort().join()}`)
        .then(res => checkResponse(res))
        .then(res => {
          const shippingOptions = this.generateShippingOptions(
            res._defaultcart[0]
          );

          const shippingAddresses = this.generateShippingAddresses(
            res._defaultcart[0]
          );

          const selectedShippingOption = shippingOptions.find(
            opt => opt.checked
          );

          if (newAddressUri) {
            // Send request to select newly created address as delivery (shipping) address for the order.
            const newAddress = shippingAddresses.find(
              address => address.self.uri === newAddressUri
            );
            const newAddressSelect = newAddress.selectaction;
            this.handleChange(newAddressSelect);
          }

          const selectedShippingAddress = shippingAddresses.find(
            address => address.checked
          );

          if (this._isMounted) {
            if (res && res._defaultcart && res._defaultcart[0]._order) {
              this.setState(prevState => {
                return {
                  ...prevState,
                  orderData: res._defaultcart[0],
                  isLoading: false,
                  shippingOptions,
                  shippingAddresses,
                  selectedShippingOption,
                  selectedShippingAddress
                };
              });
            }
          }

          const orderUri = res._defaultcart[0]._order[0].self.uri;

          const {
            cortexApi: { scope }
          } = Config;
          const orderId = orderUri.slice(
            orderUri.lastIndexOf(`${scope}/`) + `${scope}/`.length
          );

          // sends information to Segment when user starts the checkout flow
          checkoutStarted(orderId);

          const { messages } = res._defaultcart[0]._order[0];
          const messageData = {
            debugMessages: "",
            type: "",
            id: ""
          };
          if (messages.length > 0) {
            let debugMessages = "";
            for (let i = 0; i < messages.length; i++) {
              debugMessages = debugMessages.concat(
                `${messages[i]["debug-message"]} \n `
              );
            }
            messageData.debugMessages = debugMessages;
            messageData.type = messages[0].type;
            messageData.id = messages[0].id;
          }
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => fetchOrderData => CheckoutPage.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "fetchOrderData => CheckoutPage.tsx"
            });
          }
        });
    }
  }

  newAddress() {
    if (this._isMounted) {
      this.setState({
        openAddressModal: true,
        addressUrl: undefined
      });
    }
  }

  editAddress(addressLink) {
    if (this._isMounted) {
      this.setState({
        openAddressModal: true,
        addressUrl: { address: addressLink }
      });
    }
  }

  editOrderDetails() {
    const {
      cartOrderDetails,
      orderInformation,
      notificationsStatus,
      notificationsData,
      backOrdered,
      selectedClient
    } = this.state;

    const {
      smsChecked,
      orderStatusChecked,
      deliveryStatusChecked
    } = notificationsStatus;
    const {
      phoneNumbers,
      orderStatusEmails,
      deliveryStatusEmail
    } = notificationsData;

    const {
      order: { setPoNumber },
      user: { getUserProfile }
    } = this.context;
    const link = cartOrderDetails._cartorderdetailsform[0].links[0].uri;
    const payload = {
      ...orderInformation,
      "date-needed": this.formatDate(orderInformation.date),
      "receive-sms-alerts": smsChecked,
      "receive-order-status-emails": orderStatusChecked,
      "receive-delivery-status-email": deliveryStatusChecked,
      "sms-phone-numbers": smsChecked
        ? phoneNumbers.filter(phoneNumber => phoneNumber).join("|")
        : "",
      "order-status-emails": orderStatusChecked
        ? orderStatusEmails.filter(email => email).join("|")
        : "",
      "delivery-status-emails": deliveryStatusChecked
        ? deliveryStatusEmail
        : "",
      "ship-complete":
        Config.showShipComplete && backOrdered
          ? orderInformation["ship-complete"]
          : null,
      "client-name": selectedClient.name,
      "client-id": selectedClient.id
    };

    setPoNumber(orderInformation["po-number"]);

    // eslint-disable-next-line consistent-return
    return cortexFetch(link, {
      method: "post",
      body: JSON.stringify(payload)
    })
      .then(res => {
        if (res.status === 400) {
          return res.json();
        }
        return res;
      })
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(() => getUserProfile())
      .catch(e => {
        this.setState({
          orderInformationErrors: {
            // "alternate-email-address": undefined,
            "po-number": undefined
          }
        });
        if (e.messages) {
          e.messages.map(message => {
            const { orderInformationErrors } = this.state;
            const field = message.data["field-name"]
              .split(/(?=[A-Z])/)
              .join("-")
              .toLowerCase();

            this.setState({
              orderInformationErrors: {
                ...orderInformationErrors,
                [field]: message.id
              }
            });
            return message;
          });
        }
      });
  }

  handleDelete(link) {
    const { history } = this.props;
    const {
      context: {
        auth: { isLoggedIn, logout },
        user: { getUserProfile }
      }
    } = this;
    if (isLoggedIn) {
      cortexFetch(link, {
        method: "delete"
      })
        .then(res => {
          const onSuccess = data => data;
          return checkResponse(res, onSuccess);
        })
        .then(() => {
          getUserProfile();
          this.fetchOrderData();
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => handleDelete => CheckoutPage.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "handleDelete => CheckoutPage.tsx"
            });
          }
        });
    }
  }

  handleChange = async link => {
    const { history } = this.props;
    const {
      auth: { isLoggedIn, logout }
    } = this.context;

    this.setState({ deliveryLoading: true });

    try {
      if (isLoggedIn) {
        await cortexFetch(link, {
          method: "post"
        })
          .then(res => {
            const onSuccess = data => data;
            return checkResponse(res, onSuccess);
          })
          .then(() => this.fetchOrderData())
          .then(() => this.setState({ deliveryLoading: false }))

          .catch(e => {
            this.setState({ deliveryLoading: false });
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn: "Logout => handleChange => CheckoutPage.tsx"
                })
              );
            } else {
              pushToMaintenace(history, {
                e,
                errIn: "handleChange => CheckoutPage.tsx"
              });
            }
          });
      }
    } catch (e) {
      this.setState({ deliveryLoading: false });
      pushToMaintenace(history, {
        e,
        errIn: "handleChange => CheckoutPage.tsx"
      });
    }
  };

  updateBranchAndJob() {
    const {
      order,
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { findBranch },
      job
    } = this.context;

    const jobNumber = defaultCart ? defaultCart.jobNumber : null;
    const jobName = defaultCart ? defaultCart.jobName : null;

    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    const selectedBranchDetails =
      selectedBranch && findBranch(selectedBranch.code);

    order.setBranch(selectedBranchDetails);

    if (jobNumber && jobName) {
      order.setJob({ jobNumber, jobName });

      /**
       * After the order is completed, user's session is still active
       * and we can assume the user wants to keep using the same job.
       * That's why we persist the job name and number.
       */

      job.setPersistedJobNumber(jobNumber);
      job.setPersistedJobName(jobName);
    }
  }

  async checkContract() {
    const {
      cart: {
        cartDetails: {
          defaultCart: { cartOrderDetailsForm }
        }
      },
      user: {
        userProfile: { customerNumber }
      },
      contract: { fetchContract }
    } = this.context;
    if (cartOrderDetailsForm["contract-number"]) {
      const contract: ContractOrder = await fetchContract(
        customerNumber,
        cartOrderDetailsForm["contract-number"]
      );
      const { status } = contract;
      if (status === "L") {
        this.setState({
          blockKinpay: true
        });
      }
    }
  }

  async handlePaymentChange(paymentOption) {
    const {
      auth: { logout },
      user: {
        userProfile: { isCanadianUser }
      }
    } = this.context;
    const { history } = this.props;
    const {
      orderData: { _order },
      selectedPayment
    } = this.state;

    if (this._isMounted) {
      this.setState({ paymentLoading: true });
    }

    if (paymentOption === PaymentMethods.kinpay) {
      await this.checkContract();
    } else {
      this.setState({
        blockKinpay: false
      });
    }

    try {
      const choices = _order[0]._paymenttypeinfo[0]._selector[0];

      const [postPaymentChoiceLink] = Object.keys(choices)
        .filter(key => key === "_choice" || key === "_chosen")
        .map(key => choices[key])
        .reduce((flattened, nested) => {
          return flattened.concat(nested);
        }, [])
        .filter(choiceArray =>
          paymentOption
            ? choiceArray._description[0]["payment-type"] === paymentOption
            : choiceArray._description[0]["payment-type"] === selectedPayment
        )
        .map(choice => choice.self.uri);

      await cortexFetch(postPaymentChoiceLink, {
        method: "post"
      });
      if (this._isMounted) {
        this.setState({
          selectedPayment: paymentOption,
          paymentLoading: false,
          selectedPaymentMethodError: "",
          cashUserOnlyError: ""
        });
      }
      this.updateBranchAndJob();
      if (isCanadianUser) {
        await this.fetchOrderData();
      }
    } catch (e) {
      if (checkTokensExpired(e)) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "Logout => handlePaymentChange => CheckoutPage.tsx"
          })
        );
      } else if (this._isMounted) {
        this.setState({
          paymentLoading: false,
          isLoading: false
        });
        pushToMaintenace(history, {
          e,
          errIn: "handlePaymentChange => CheckoutPage.tsx"
        });
      }
    }
  }

  // KinPay returns data to the window, this function handles the callback data
  handleKinpayCallback = async event => {
    const messageFromSender = event.data;
    /**
     * @todo handle each message error type individually rather than catch all
     */
    if (messageFromSender.message === "Approved" && !this._kinpayApproved) {
      this._kinpayApproved = true;
      this.completeOrder();
    } else if (
      typeof messageFromSender === "object" &&
      "message" in messageFromSender
    ) {
      setTimeout(() => {
        if (this._isMounted) {
          this.setState({
            disableCompleteOrder: true
          });
        }
        this.closeKinpayModal();
        this.showRejectedTestMessage();
      }, 3000);
    }
  };

  completeOrder = async () => {
    const {
      context: {
        auth: { isLoggedIn, logout }
      }
    } = this;

    const { history } = this.props;

    const { zoomOrderReview, zoomPurchase } = zoom;

    try {
      if (isLoggedIn) {
        let res = await cortexFetch(`/?zoom=${zoomOrderReview.sort().join()}`);

        res = await checkResponse(res);

        const orderData = res._defaultcart[0];

        const purchaseform = orderData._order[0]._purchaseform[0].links.find(
          link => link.rel === "submitorderaction"
        ).uri;

        const { order } = this.context;

        // this.trackTransactionAnalytics(orderData);

        let purchaseData = await cortexFetch(
          `${purchaseform}?followlocation=true&zoom=${zoomPurchase
            .sort()
            .join()}`,
          {
            method: "post"
          }
        );

        purchaseData = await checkResponse(purchaseData);

        this.updateCartContext();

        order.setOrderData(orderData);
        order.setPurchaseData(purchaseData);
        this.updateBranchAndJob();

        history.push("/purchaseReceipt");
      }
    } catch (e) {
      if (checkTokensExpired(e)) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "Logout => completeOrder => CheckoutPage.tsx"
          })
        );
      } else {
        pushToMaintenace(history, {
          e,
          errIn: "completeOrder => CheckoutPage.tsx"
        });
      }
    }
  };

  updateCartContext = async () => {
    const {
      cart: { getCartDetails }
    } = this.context;

    await getCartDetails();
  };

  payWithCreditCard = async () => {
    // Kinpay returns data to the window, this block handles the callback data
    const { history } = this.props;

    if (!this.validateCheckoutForm()) {
      return;
    }

    try {
      if (this._isMounted) {
        this.setState({ paymentLoading: true });
      }

      const {
        orderData: { _order }
      } = this.state;

      const {
        auth: { logout }
      } = this.context;

      const { scope } = Config.cortexApi;

      const orderUri = _order[0].self.uri;
      const orderId = orderUri.slice(
        orderUri.lastIndexOf(scope) + scope.length
      );

      const fetchLink = `/orderdetails/${scope}${orderId}/kinpayrequesturl/form?followlocation&format=standardlinks,zoom.nodatalinks&`;

      const kinpayLinkResponse = await this.editOrderDetails()
        .then(() => {
          return cortexFetch(fetchLink, {
            method: "post",
            body: JSON.stringify({})
          });
        })
        .then(res => checkResponse(res))
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => payWithCreditCard => CheckoutPage.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "payWithCreditCard => CheckoutPage.tsx"
            });
          }
        });
      // kinpayLinkResponse = await kinpayLinkResponse.json();

      const {
        "kinpay-url": kinpayUrl,
        "kinpay-prid": kinpayPrid
      } = kinpayLinkResponse;

      if (kinpayUrl && kinpayPrid) {
        sessionStorage.setItem("kinpayPrid", kinpayPrid);
        if (this._isMounted) {
          this.setState({
            kinpayUrl: `${kinpayUrl}?option=postMessage`,
            kinpayPrid,
            openKinpayModal: true,
            paymentLoading: false
          });
        }
      } else {
        this.setState({
          paymentLoading: false
        });
      }
    } catch (e) {
      if (this._isMounted) {
        this.setState({
          paymentLoading: false
        });
        pushToMaintenace(history, {
          e: { message: intl.get("unable-to-pay-with-credit-card") },
          errIn: "payWithCreditCard => CheckoutPage.tsx"
        });
      }
    }
  };

  closeKinpayModal = () => {
    if (this._isMounted) {
      this.setState({ openKinpayModal: false });
    }
  };

  newPayment() {
    if (this._isMounted) {
      this.setState({ openNewPaymentModal: true });
    }
  }

  reviewOrder() {
    const { history } = this.props;
    history.push("/order");
  }

  handleCertificate(certificate) {
    if (this._isMounted) {
      this.setState({
        certificates: certificate
      });
    }
  }

  handleCloseAddressModal() {
    if (this._isMounted) {
      this.setState({ openAddressModal: false });
    }
  }

  handleCloseNewPaymentModal() {
    if (this._isMounted) {
      this.setState({ openNewPaymentModal: false });
    }
  }

  generateShippingAddresses(orderData): Array<any> {
    const shippingAddresses = [];

    if (
      orderData._order[0]._deliveries &&
      orderData._order[0]._deliveries[0]._element[0]._destinationinfo
    ) {
      const destination =
        orderData._order[0]._deliveries[0]._element[0]._destinationinfo[0]
          ._destination;
      if (destination) {
        const [description] = destination;
        description.checked = true;
        // Keep uri of the address so we can filter out the default shipping address.
        description.uri = destination[0].self.uri;

        shippingAddresses.push(description);
      }
      const selector =
        orderData._order[0]._deliveries[0]._element[0]._destinationinfo[0]
          ._selector;

      if (selector) {
        const choices = selector[0]._choice;
        choices.map(choice => {
          const [description] = choice._description;
          description.selectaction = choice.links.find(
            link => link.rel === "selectaction"
          ).uri;
          description.checked = false;
          // Keep uri of the address so we can filter out the default shipping address.
          description.uri = choice._description[0].self.uri;

          shippingAddresses.push(description);
          return description;
        });
      }
    }

    return shippingAddresses;
  }

  handleShippingAddressChange(shippingAddress) {
    if (this._isMounted) {
      if (this.isAddressAssociatedWithJob()) {
        this.controlJobAddressModal(JobAddressActions.CHANGE);
        // When user opens modal, we need to save the selected address as pending address in state.
        this.setState({
          pendingShippingAddress: shippingAddress
        });
      } else if (shippingAddress && !shippingAddress.address["phone-number"]) {
        // if address is missing phone number open edit address modal with warning message
        this.editAddress(shippingAddress.self.uri);
      } else {
        this.setState(
          {
            selectedShippingAddress: shippingAddress
          },
          () => {
            const addressChanged = shippingAddress.selectaction;
            if (addressChanged) {
              this.handleChange(shippingAddress.selectaction);
            }
          }
        );
      }
    }
  }

  renderShippingAddress() {
    const {
      selectedShippingAddress,
      shippingAddresses,
      newAddressUri
    } = this.state;
    const {
      user: {
        userProfile: { defaultShippingAddress, addresses }
      }
    } = this.context;

    if (shippingAddresses && shippingAddresses.length) {
      return shippingAddresses.map((shippingAddress, index) => {
        const {
          name,
          address,
          selectaction,
          checked,
          messages
        } = shippingAddress;
        const jobName = shippingAddress["job-name"];
        const jobNumber = shippingAddress["job-number"];

        const shippingKey = shippingAddress.address["street-address"]
          .replace(/\s+/g, "")
          .concat("_", index);

        const isDefaultAddress =
          defaultShippingAddress &&
          defaultShippingAddress.uri === shippingAddress.uri;

        /**
         * Check the latest address that has been edited or added and validate if it has warning message in the
         * messages array. Display warning below the address if the EP returned warning message for the invalid address.
         * If the address has been edited and warning disappeared, don;t show the message below the address.
         */
        const displayWarningMessage = Boolean(
          newAddressUri &&
            shippingAddress.uri === newAddressUri &&
            messages.length
        );

        // const isAssociatedAddress =
        //   selectedShippingAddress &&
        //   selectedShippingAddress.uri === shippingAddress.uri;

        // const hideDeleteButton = isAssociatedAddress || isDefaultAddress;

        return (
          <div className="form-group" key={shippingKey}>
            <div className="form-check">
              <input
                type="radio"
                name={shippingKey}
                id={shippingKey}
                aria-label={`shipping option ${Object.values(
                  shippingAddress.address
                )}`}
                className="form-check-input"
                checked={
                  selectedShippingAddress
                    ? selectedShippingAddress.uri === shippingAddress.uri
                    : checked
                }
                onChange={() =>
                  this.handleShippingAddressChange(shippingAddress)
                }
              />
              <label className="form-check-label" htmlFor={shippingKey}>
                <AddressContainer name={name} address={address} />
                {displayWarningMessage && (
                  <div className="new-address-warning">
                    <p>{intl.get("new-address-warning")}</p>
                  </div>
                )}
                {/** Allow editing on all addresses now. But for the default and job linking account shipping addresses only allow First and Last name */}
                <button
                  aria-label={intl.get("edit")}
                  className="ep-btn small mr-2"
                  type="button"
                  onClick={() => {
                    this.editAddress(shippingAddress.self.uri);
                  }}
                >
                  {intl.get("edit")}
                </button>
                {jobName || jobNumber ? (
                  <div className="checkout-address-job-info">
                    <div>
                      <span className="label">
                        {`${intl.get("job-name")}: `}
                      </span>
                      <span>{jobName}</span>
                    </div>
                    <div>
                      <span className="label">
                        {`${intl.get("job-number")}: `}
                      </span>
                      <span>{jobNumber}</span>
                    </div>
                  </div>
                ) : null}

                {/** Prevent deleting of the default and associated shipping addresses. */}
                {/* {!hideDeleteButton ? (
                  <button
                    aria-label={intl.get("delete")}
                    className="ep-btn small"
                    type="button"
                    onClick={() => {
                      this.handleDelete(shippingAddress.self.uri);
                    }}
                  >
                    {intl.get("delete")}
                  </button>
                ) : null} */}
              </label>
            </div>
          </div>
        );
      });
    }
    return (
      <div>
        <p>{intl.get("no-shipping-address-message")}</p>
      </div>
    );
  }

  renderNewAddressModal() {
    const { openAddressModal, addressUrl } = this.state;
    const { history } = this.props;
    const {
      context: { auth }
    } = this;
    const newOrEdit =
      addressUrl && addressUrl.address ? intl.get("edit") : intl.get("new");

    return (
      <Modal open={openAddressModal} onClose={this.handleCloseAddressModal}>
        <div className="modal-lg new-address-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                {newOrEdit} {intl.get("address")}
              </h2>
            </div>
            <div className="modal-body">
              <AddressFormMain
                onCloseModal={this.handleCloseAddressModal}
                fetchData={this.fetchOrderData}
                addressData={addressUrl}
                history={history}
                auth={auth}
                onNewAddressWarning={this.setNewAddressUri}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderShippingAddressSelector() {
    const { orderData, selectedShippingOption } = this.state;
    const deliveries = orderData._order[0]._deliveries;
    const { messages } = orderData._order[0];
    const needShipmentDetails = messages.find(
      message => message.id === "need.shipping.address"
    );

    /**
     * The user can still have shipping address in his address book.
     * But since those addresses are not associated with the order, the ._order[0]._deliveries is not sent.
     */

    if (needShipmentDetails || deliveries) {
      return (
        <>
          <h3 className="section-subtitle">
            {intl.get("fulfillment-address")}
          </h3>
          {selectedShippingOption &&
          selectedShippingOption.name.includes("Branch_Truck") ? (
            <div>
              {this.renderShippingAddress()}
              <button
                className="ep-btn primary wide"
                aria-label={intl.get("add-new-address")}
                type="button"
                onClick={() => {
                  if (this.isAddressAssociatedWithJob()) {
                    this.controlJobAddressModal(JobAddressActions.ADD_NEW);
                  } else {
                    this.newAddress();
                  }
                }}
              >
                {intl.get("add-new-address")}
              </button>
            </div>
          ) : (
            <span>{intl.get("no-delivery-information")}</span>
          )}
        </>
      );
    }

    return null;
  }

  generateShippingOptions(orderData: any): Array<any> {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      user: {
        userProfile: { customerRoles }
      }
    } = this.context;

    const BRANCHES_VIRTUAL = intl.get("virtual-branches");
    const isVirtualBranchUser =
      customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

    const shippingOptions = [];
    // PGL-364: Updates to MCB50YSAU and MCKB70YSAU
    const skus =
      defaultCart.items &&
      defaultCart.items.map(item => item._item[0]._code[0].code);
    const hasSpecialAirPurifiers = skus.find(
      sku => sku === "MCB50YSAU" || sku === "MCKB70YSAU"
    );
    if (
      orderData._order[0]._deliveries &&
      orderData._order[0]._deliveries[0]._element[0]._shippingoptioninfo
    ) {
      const shippingOption =
        orderData._order[0]._deliveries[0]._element[0]._shippingoptioninfo[0]
          ._shippingoption;
      if (shippingOption) {
        const [description] = shippingOption;
        description.checked = true;
        shippingOptions.push(description);
      }
      const selector =
        orderData._order[0]._deliveries[0]._element[0]._shippingoptioninfo[0]
          ._selector;
      if (selector && selector[0]._choice) {
        const choices = selector[0]._choice;
        choices.map(choice => {
          const [description] = choice._description;
          description.selectaction = choice.links.find(
            link => link.rel === "selectaction"
          ).uri;
          description.checked = false;
          shippingOptions.push(description);
          return description;
        });
      }
    }

    // Sort the shipping options
    const pickupOpt = shippingOptions.find(elem =>
      elem.name.includes("Pickup")
    );
    const branchTruckOpt = shippingOptions.find(elem =>
      elem.name.includes("Branch_Truck")
    );

    if (pickupOpt && branchTruckOpt) {
      if (isVirtualBranchUser || hasSpecialAirPurifiers) {
        return [branchTruckOpt];
      }
      return [pickupOpt, branchTruckOpt];
    }

    return shippingOptions;
  }

  renderShippingOptions() {
    const { shippingOptions, selectedShippingOption } = this.state;

    if (shippingOptions && shippingOptions.length) {
      return shippingOptions.map(option => (
        <div className="form-group" key={option["display-name"]}>
          <div className="form-check">
            <input
              type="radio"
              name="shippingOption"
              aria-label={`shipping ${option["display-name"]}`}
              id={option["display-name"]}
              className="form-check-input"
              checked={
                selectedShippingOption
                  ? selectedShippingOption["display-name"] ===
                    option["display-name"]
                  : option.checked
              }
              onChange={async () => {
                // Make API call to save option

                if (this._isMounted) {
                  this.setState(
                    {
                      selectedShippingOption: option,
                      selectedDeliveryMethodError: ""
                    },
                    () => {
                      const optionChanged = option.selectaction;
                      if (optionChanged) {
                        this.handleChange(option.selectaction);
                      }
                    }
                  );
                }
              }}
            />
            <label
              className="form-check-label"
              htmlFor={option["display-name"]}
            >
              <ShippingOptionContainer option={option} />
            </label>
          </div>
        </div>
      ));
    }

    return (
      <div>
        <p>{intl.get("no-shipping-options-message")}</p>
      </div>
    );
  }

  checkBackOrderedItems() {
    const {
      auth: { logout },
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { branchesList, findBranch },
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;
    const { history } = this.props;
    const {
      cortexApi: { scope }
    } = Config;

    if (Config.showShipComplete && defaultCart && branchesList) {
      const skus =
        defaultCart.items &&
        defaultCart.items.map(item => item._item[0]._code[0].code);
      const skusMotili = skus && skus.join("|");
      const { selectedBranch, clientId } = defaultCart;
      const { latitude, longitude } = findBranch(selectedBranch.code);
      if (scope === "motili") {
        getAvailabilityMotili(skusMotili, latitude, longitude, clientId)
          .then(({ data }) => {
            const inventoryAvailability = formatInventoryAvailability(data);
            const backOrderedArray = defaultCart.items.map(item => {
              const inventoryItem = inventoryAvailability.find(
                ia => ia.sku === item._item[0]._code[0].code
              );
              const backOrdered = inventoryItem
                ? inventoryItem.branchAvailability < item.quantity
                : true;

              return backOrdered;
            });
            this.setState({
              backOrdered: backOrderedArray.includes(true)
            });
          })
          .catch(e => {
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn: "Logout => checkBackOrderedItems => CheckoutPage.tsx"
                })
              );
            } else if (selectedBranch.vendor !== "GOODMAN") {
              /* See DGE-3133. This is related only to Motili store, if the distributor branch
                  is other than "GOODMAN". 
                */
              this.setState({
                backOrdered: true
              });
            } else {
              pushToMaintenace(history, {
                e,
                errIn: "checkBackOrderedItems => CheckoutPage.tsx"
              });
            }
          });
      } else {
        const branchNumber = selectedBranch.code;
        getAvailabilityDGA(customerNumber, skus, branchNumber)
          .then(({ data }) => {
            if (data.error || (data.result && !data.result.length)) {
              console.error("Inventory error");
              this.setState({
                inventoryError: intl.get("inventory-error")
              });
            } else {
              const inventoryAvailability = formatDGAInventory(data);
              const backOrderedArray = defaultCart.items.map(item => {
                const inventoryItem = inventoryAvailability.find(
                  ia => ia.sku === item._item[0]._code[0].code
                );
                const backOrdered = inventoryItem
                  ? inventoryItem.branchAvailability < item.quantity
                  : true;

                return backOrdered;
              });
              this.setState({
                backOrdered: backOrderedArray.includes(true),
                inventoryError: ""
              });
            }
          })
          .catch(e => {
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn: "Logout => checkBackOrderedItems => CheckoutPage.tsx"
                })
              );
            } else {
              pushToMaintenace(history, {
                e,
                errIn: "checkBackOrderedItems => CheckoutPage.tsx"
              });
            }
          });
      }
    }
  }

  handleShipCompleteChange() {
    const { orderInformation } = this.state;
    this.setState({
      orderInformation: {
        ...orderInformation,
        "ship-complete": !orderInformation["ship-complete"]
      }
    });
  }

  renderShippingOptionsSelector() {
    const {
      orderData,
      selectedShippingOption,
      backOrdered,
      orderInformation,
      selectedDeliveryMethodError
    } = this.state;
    const {
      user: {
        userProfile: { customerRoles }
      },
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { findBranch, airPurifierBranch }
    } = this.context;
    // PGL-364
    const skus =
      defaultCart.items &&
      defaultCart.items.map(item => item._item[0]._code[0].code);
    const hasSpecialAirPurifiers = skus.find(
      sku => sku === "MCB50YSAU" || sku === "MCKB70YSAU"
    );
    // PGL-364
    let selectedBranch = defaultCart
      ? findBranch(defaultCart.selectedBranch.code)
      : null;
    // PGL-364
    if (hasSpecialAirPurifiers) {
      selectedBranch = airPurifierBranch;
    }

    const BRANCHES_VIRTUAL = intl.get("virtual-branches");
    const isVirtualBranchUser =
      customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

    /**
     * The user can still have shipping address in his address book.
     * But since those addresses are not associated with the order, the ._order[0]._deliveries is not sent.
     */
    // const deliveries = orderData._order[0]._deliveries;
    // if (deliveries && deliveries[0]._element[0]._destinationinfo) {
    return (
      <div>
        <h3
          className={`section-subtitle ${
            selectedDeliveryMethodError ? "validation-error-border" : ""
          }`}
        >
          <span className="star">*</span>
          {intl.get("fulfillment-method")}
        </h3>
        <p className="section-subtitle-error">{selectedDeliveryMethodError}</p>
        {this.renderShippingOptions()}
        {Config.showShipComplete && backOrdered && (
          <div className="ship-complete-option">
            <label
              className="ship-complete-label"
              htmlFor="ship-complete"
              id="ship-complete-label"
              onClick={this.handleShipCompleteChange}
              onKeyDown={this.handleShipCompleteChange}
            >
              <input type="checkbox" name="shipComplete" id="checkbox" />
              <span
                className={`ship-complete-checkbox ${
                  orderInformation["ship-complete"]
                    ? "show-checkmark"
                    : "hide-checkmark"
                }`}
                onClick={this.handleShipCompleteChange}
                onKeyDown={this.handleShipCompleteChange}
                role="checkbox"
                aria-checked="false"
                tabIndex={0}
                aria-labelledby="ship-complete-label"
                id="ship-complete"
              />
              {intl.get("ship-complete")}
            </label>
            <p className="ship-complete-instructions">
              *
              {isVirtualBranchUser
                ? intl.get("ship-complete-instructions-virtual-branch")
                : intl.get("ship-complete-instructions")}
            </p>
          </div>
        )}
        <div className="delivery-method-branch-detils">
          {(!selectedShippingOption &&
            !isVirtualBranchUser &&
            !hasSpecialAirPurifiers) ||
          (selectedShippingOption &&
            selectedShippingOption.name.includes("Pickup")) ? (
            <span>{intl.get("pickup-instructions")}:</span>
          ) : (
            <span>{intl.get("branch-truck-instructions")}:</span>
          )}
          {selectedBranch && (
            <span className="branch-details">
              {selectedBranch.branchName.toUpperCase()}
            </span>
          )}
        </div>
      </div>
    );
    // }
    // return null;
  }

  renderKinpayModal() {
    const { kinpayUrl, kinpayPrid, openKinpayModal } = this.state;

    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%"
      }
    };

    return kinpayUrl && kinpayPrid ? (
      <Modal
        open={openKinpayModal}
        onClose={this.closeKinpayModal}
        styles={{ modal: styles.modal }}
      >
        <iframe
          className="kinpay-modal"
          src={kinpayUrl}
          frameBorder="0"
          title="kinpay window"
        />
      </Modal>
    ) : null;
  }

  showTestMessage() {
    const { kinpayRedirectPrid, disableCompleteOrder } = this.state;

    return !disableCompleteOrder &&
      kinpayRedirectPrid === sessionStorage.getItem("kinpayPrid") ? (
      <div className="kinpay-success">
        <Messagecontainer
          message={{
            type: "needinfo",
            debugMessages: intl.get("successfull-kinpay-payment")
          }}
          closeContainerHandler={this.closeTostMessage}
        />
      </div>
    ) : null;
  }

  showRejectedTestMessage() {
    const { disableCompleteOrder } = this.state;

    return disableCompleteOrder ? (
      <div className="kinpay-fail">
        <Messagecontainer
          message={{
            type: "needinfo",
            debugMessages: intl.get("unable-to-pay-with-credit-card")
          }}
          closeContainerHandler={this.closeTostMessage}
        />
      </div>
    ) : null;
  }

  closeTostMessage = () => {
    if (this._isMounted) {
      this.setState({ kinpayRedirectPrid: "" });
    }
  };

  resetMissingFields = () => {
    this.setState({
      missingFields: ""
    });
  };

  onCompleteOrder() {
    if (this.validateCheckoutForm()) {
      this.editOrderDetails().then(() => this.reviewOrder());
    }
  }

  handleOrderInformationChange(name, value) {
    this.setState(prevState => ({
      ...prevState,
      orderInformation: {
        ...prevState.orderInformation,
        [name]: value
      },
      orderInformationErrors: {
        ...prevState.orderInformationErrors,
        [name]: ""
      }
    }));
  }

  handleNotificationsStatusChange(name) {
    this.setState(prevState => ({
      ...prevState,
      notificationsStatus: {
        ...prevState.notificationsStatus,
        [name]: !prevState.notificationsStatus[name]
      },
      notificationsErrors: null
    }));
  }

  handleNotificationsDataChange(name, value) {
    this.setState(prevState => ({
      ...prevState,
      notificationsData: {
        ...prevState.notificationsData,
        [name]: value
      },
      notificationsErrors: null
    }));
  }

  formatDate(date: string): string {
    let newDate = "";
    if (date) {
      newDate = `${date} 09:00`;
    }
    return newDate;
  }

  scrollToErrorSection(ref) {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  formatErrorMessageString(
    generalOrderInformation: Array<string>,
    notifications: Array<any>,
    selectedDeliveryMethod: string,
    selectedPaymentMethod: string,
    cashOnlyErrorMessage: string
  ): string {
    const reduceErrorMessage = (
      errors: Array<string>,
      initialMessage: string
    ) =>
      errors.reduce((result, current) => {
        if (current) {
          return !result ? `${current}\n` : `${result}${current}\n`;
        }
        return "";
      }, initialMessage);

    let errorMessage: string;
    errorMessage = reduceErrorMessage(generalOrderInformation, "");
    errorMessage = notifications.reduce((result: string, current: any) => {
      if (typeof current === "string" && current.length) {
        return !result ? `${current}\n` : `${result}${current}\n`;
      }
      return current.length ? reduceErrorMessage(current, result) : result;
    }, errorMessage);
    errorMessage = selectedDeliveryMethod
      ? `${errorMessage}${selectedDeliveryMethod}\n`
      : errorMessage;
    errorMessage = selectedPaymentMethod
      ? `${errorMessage}${selectedPaymentMethod}\n`
      : errorMessage;
    errorMessage = cashOnlyErrorMessage
      ? `${errorMessage}${cashOnlyErrorMessage}\n`
      : errorMessage;

    return errorMessage;
  }

  validateCheckoutForm() {
    const {
      orderInformation,
      notificationsData,
      notificationsStatus,
      selectedPayment,
      selectedShippingOption
    } = this.state;
    const {
      user: {
        userProfile: {
          creditLineAllowed,
          poNumberRequired,
          subuserFirstName,
          subuserLastName,
          subuserEmail,
          subuserPhone
        }
      }
    } = this.context;

    const phoneNumberRequired =
      (subuserFirstName || subuserLastName || subuserEmail) &&
      (!subuserPhone ||
        subuserPhone === "9999999999" ||
        subuserPhone === "999-999-9999" ||
        orderInformation.phone);

    const generalOrderInformationValidation = validateGeneralOrderInformation(
      orderInformation,
      poNumberRequired,
      phoneNumberRequired
    );

    const hasCashUserOnlyError =
      selectedPayment === PaymentMethods.creditLine &&
      creditLineAllowed === "false";

    const disabledNotificationsValidation = {
      isValid: true,
      errors: {
        deliveryStatusEmail: "",
        orderStatusEmails: [],
        phoneNumbers: []
      }
    };
    // Do not validate notifications fields if user notifications are hidden with setting showUserNotifications to false.
    const notificationsValidation = Config.showUserNotifications
      ? validateNotifications(notificationsData, notificationsStatus)
      : disabledNotificationsValidation;

    const selectedDeliveryMethodValidation = validateRequiredMethod(
      selectedShippingOption,
      "fulfillment-method"
    );

    const selectedPaymentMethodValidation = validateRequiredMethod(
      selectedPayment,
      "payment-method"
    );
    const cashOnlyErrorMessage = intl.get("cash-only-error");

    // Format error message that is displayed in the sidebar
    // Error message is concatenation of all error messages
    // (orderInformationDataValidation.errors + notificationsValidation.errors + selectedDeliveryMethodValidation.errors + selectedPaymentValidation.errors)
    const errorMessage: string = this.formatErrorMessageString(
      Object.values(generalOrderInformationValidation.errors),
      Object.values(notificationsValidation.errors),
      selectedDeliveryMethodValidation.errors,
      selectedPaymentMethodValidation.errors,
      hasCashUserOnlyError ? cashOnlyErrorMessage : ""
    );

    this.setState(
      {
        orderInformationErrors: generalOrderInformationValidation.isValid
          ? initialOrderInformationErrors
          : generalOrderInformationValidation.errors,
        notificationsErrors: notificationsValidation.isValid
          ? null
          : notificationsValidation.errors,
        selectedDeliveryMethodError: selectedDeliveryMethodValidation.errors,
        selectedPaymentMethodError: selectedPaymentMethodValidation.errors,
        missingFields: errorMessage,
        cashUserOnlyError: hasCashUserOnlyError ? cashOnlyErrorMessage : ""
      },
      () => {
        if (
          !generalOrderInformationValidation.isValid ||
          !notificationsValidation.isValid
        ) {
          this.scrollToErrorSection(this.generaOrderInformationRef);
        } else if (!selectedDeliveryMethodValidation.isValid) {
          this.scrollToErrorSection(this.fulfillmentRef);
        } else if (
          !selectedPaymentMethodValidation.isValid ||
          hasCashUserOnlyError
        ) {
          this.scrollToErrorSection(this.billingRef);
        }
      }
    );

    return (
      generalOrderInformationValidation.isValid &&
      notificationsValidation.isValid &&
      selectedDeliveryMethodValidation.isValid &&
      selectedPaymentMethodValidation.isValid &&
      !hasCashUserOnlyError
    );
  }

  renderJobAddressModal() {
    const { openJobAddressModal } = this.state;

    const changeAddress = openJobAddressModal === JobAddressActions.CHANGE;
    const newAddress = openJobAddressModal === JobAddressActions.ADD_NEW;

    const closeModal = () => {
      this.setState({
        openJobAddressModal: JobAddressActions.CLOSE,
        pendingShippingAddress: null
      });
    };

    let confirmHandler = () => {};

    if (changeAddress) {
      // If the user is changing address via radio button.
      confirmHandler = () => {
        const { pendingShippingAddress } = this.state;
        if (this._isMounted && pendingShippingAddress) {
          // Make API call to save address
          this.setState(
            {
              selectedShippingAddress: pendingShippingAddress,
              openJobAddressModal: JobAddressActions.CLOSE,
              pendingShippingAddress: null
            },
            () => {
              const { selectedShippingAddress } = this.state;
              const addressChanged =
                selectedShippingAddress && selectedShippingAddress.selectaction;
              if (addressChanged) {
                this.handleChange(selectedShippingAddress.selectaction);
              }
            }
          );
        }
      };
    } else if (newAddress) {
      // If the user is creating a new address.
      confirmHandler = () => {
        if (this._isMounted) {
          closeModal();
        }
        this.newAddress();
      };
    }

    const modal = (
      <Modal
        open={changeAddress || newAddress}
        onClose={closeModal}
        classNames={{ modal: "cart-selection-modal-content" }}
      >
        <div className="modal-lg job-address-modal">
          <div className="modal-content" id="simplemodal-container">
            <div className="modal-header">
              <h2 className="modal-title">{intl.get("info")}</h2>
            </div>
            <div className="modal-body">
              <div id="cart_selection_modal_form">
                <p>{intl.get("job-address-info")}</p>
                <div className="action-row">
                  <div className="form-input btn-container">
                    <button
                      aria-label={intl.get("continue")}
                      type="button"
                      className="ep-btn primary wide"
                      onClick={confirmHandler}
                    >
                      {intl.get("continue")}
                    </button>
                    <button
                      aria-label={intl.get("cancel")}
                      type="button"
                      className="ep-btn primary wide"
                      onClick={closeModal}
                    >
                      {intl.get("cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );

    return modal;
  }
  /**
   * ## controlJobAddressModal
   * @param flag enum JobAddressActions
   * @description The values in enum control if the modal is going to be opened or closed;
   * and if it is opened - the action that is triggered on "Continue" click.
   * The enum has values for scenarios when the user wants to change the address, create a new
   * address, and just close the modal.
   */

  controlJobAddressModal(flag: JobAddressActions) {
    this.setState({ openJobAddressModal: flag });
  }

  /**
   * @description Compare the selected job name and number (populated on the default cart) with the
   * job name and number from the currently selected shipping address. If the job name or the number are the same,
   * and the user tries to change the address, the warning popup will be displayed.
   */
  isAddressAssociatedWithJob(): boolean {
    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;
    const { selectedShippingAddress } = this.state;

    // Default cart has selected job number and name.
    const cartJobNumber = defaultCart ? defaultCart.jobNumber : null;
    const cartJobName = defaultCart ? defaultCart.jobName : null;

    // Job number and name associated with selected shipping address.
    const selectedShippingAddressJobName = selectedShippingAddress
      ? selectedShippingAddress["job-name"]
      : null;
    const selectedShippingAddressJobNumber = selectedShippingAddress
      ? selectedShippingAddress["job-number"]
      : null;

    let addressHasJobAssociated = false;

    // Check if selected shipping address has the same job as default cart.
    if (selectedShippingAddressJobName && selectedShippingAddressJobNumber) {
      addressHasJobAssociated =
        selectedShippingAddressJobName === cartJobName ||
        selectedShippingAddressJobNumber === cartJobNumber;
    }

    return addressHasJobAssociated;
  }

  setNewAddressUri(newAddressUri: string): void {
    if (this._isMounted && newAddressUri) {
      this.setState({ newAddressUri });
    }
  }

  setSelectedClient(client: ClientEntity): void {
    this.setState({
      selectedClient: client
    });
  }

  setCurrentBranch(branchNumber: number): void {
    this.setState(
      {
        currentBranch: branchNumber
      },
      () => this.checkBackOrderedItems()
    );
  }

  onClientSelect(client: ClientEntity): void {
    if (client && this._isMounted) {
      this.setSelectedClient({ name: client.name, id: client.id.toString() });
    }
  }

  renderSelectedClient(): any {
    const {
      user: {
        userProfile: { clientList }
      }
    } = this.context;

    const { selectedClient } = this.state;

    return clientList && clientList.length ? (
      <div className="content-box client-list-wrapper">
        <h4>{intl.get("selected-client")}</h4>

        <label htmlFor="client-dropdown" className="label">
          {`${intl.get("ordering-on-behalf")} `}
          <span>*</span>
        </label>
        <span>{`${selectedClient.name} ${selectedClient.id}`}</span>
      </div>
    ) : null;
  }

  renderPromotions(): any {
    const { orderData } = this.state;
    const { history } = this.props;
    const { auth } = this.context;

    if (
      orderData &&
      orderData._order &&
      orderData._order[0] &&
      orderData._order[0]._cartorderdetails &&
      orderData._order[0]._cartorderdetails[0] &&
      orderData._order[0]._cartorderdetails[0]._cartorderdetailsform &&
      orderData._order[0]._cartorderdetails[0]._cartorderdetailsform[0]
    ) {
      const data = {
        cartOrderDetailsForm:
          orderData._order[0]._cartorderdetails[0]._cartorderdetailsform[0]
      };

      return (
        <AddPromotionContainer
          isDeletingPromoCode={false}
          hideAddPromotionButton
          hideDeletePromotionButton
          data={data}
          history={history}
          auth={auth}
          /** When hideAddPromotionButton and hideDeletePromotionButton are true, onSubmittedPromotion is never called.
           * Thus, passing noOp function as a placeholder for required prop.
           */
          onSubmittedPromotion={noOp}
        />
      );
    }

    return null;
  }

  render() {
    const {
      orderData,
      isLoading,
      certificates,
      selectedPayment,
      paymentLoading,
      deliveryLoading,
      disableCompleteOrder,
      cartItems,
      cartDataFetched,
      missingFields,
      error,
      selectedShippingOption,
      cartOrderDetails,
      orderInformation,
      notificationsStatus,
      notificationsData,
      orderInformationErrors,
      notificationsErrors,
      shippingOptions,
      shippingAddresses,
      blockKinpay,
      selectedPaymentMethodError,
      cashUserOnlyError
    } = this.state;

    const {
      user: { userProfile }
    } = this.context;

    const openChat = () => window.zE.activate();

    // // Prevent user from seeing the checkout page if the cart is empty.
    const kinpayRedirectPrid = sessionStorage.getItem("kinpayPrid");
    if (!kinpayRedirectPrid && cartDataFetched && cartItems === 0) {
      return <Redirect to="/" />;
    }

    if (orderData && !isLoading) {
      const { history } = this.props;
      const {
        context: { auth }
      } = this;

      return (
        <>
          <div className="checkout-container-new container-fluid d-flex flex-column flex-grow-1">
            {this.renderKinpayModal()}
            {this.renderJobAddressModal()}
            <div className="container d-flex flex-column flex-grow-1">
              <div className="row flex-grow-1">
                <div className="col-12 d-flex flex-xl-row flex-column">
                  <div className="checkout-body flex-fill">
                    <div
                      className="content-box general-order-information"
                      ref={this.generaOrderInformationRef}
                    >
                      <h4 className="section-title bullet general-order-information-title">
                        {intl.get("general-order-information")}
                      </h4>
                      {this.showTestMessage()}
                      {userProfile ? (
                        <GeneralOrderInformation
                          orderInformationData={orderInformation}
                          notificationsStatus={notificationsStatus}
                          notificationsData={notificationsData}
                          poNumberRequired={
                            Config.POAlwaysRequired
                              ? true
                              : userProfile.poNumberRequired === "true"
                          }
                          handleOrderInformationChange={
                            this.handleOrderInformationChange
                          }
                          handleNotificationsStatusChange={
                            this.handleNotificationsStatusChange
                          }
                          handleNotificationsDataChange={
                            this.handleNotificationsDataChange
                          }
                          orderInformationErrors={orderInformationErrors}
                          notificationsErrors={notificationsErrors}
                        />
                      ) : null}
                    </div>

                    {Config.showClientInformation &&
                      this.renderSelectedClient()}

                    <div
                      className="content-box fulfillment"
                      ref={this.fulfillmentRef}
                    >
                      <h4 className="section-title bullet">
                        {intl.get("fulfillment")}
                      </h4>
                      {deliveryLoading ? (
                        <div className="loader" />
                      ) : (
                        <div className="row">
                          <div className="col-12 col-md-6 border-right">
                            {this.renderShippingOptionsSelector()}
                          </div>
                          <div className="col-12 col-md-6">
                            {this.renderShippingAddressSelector()}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="content-box billing" ref={this.billingRef}>
                      <h4 className="section-title bullet">
                        {intl.get("billing")}
                      </h4>
                      {/* <div className="row">
                      <div className="col-12">
                        {this.renderBillingAddressSelector()}
                      </div>
                      </div> */}
                      {this.renderNewAddressModal()}
                      <div className="row">
                        <div className="col-12">
                          {paymentLoading ? (
                            <div className="loader" />
                          ) : (
                            <PaymentMethodContainer
                              selectedPayment={selectedPayment}
                              changePayment={this.handlePaymentChange}
                              submitCreditCardPayment={this.payWithCreditCard}
                              isDelivery={
                                !!(
                                  selectedShippingOption &&
                                  selectedShippingOption.name.includes(
                                    "Branch_Truck"
                                  )
                                )
                              }
                              blockKinpay={blockKinpay}
                              missingFields={missingFields}
                              resetMissingFields={this.resetMissingFields}
                              selectedPaymentMethodError={
                                selectedPaymentMethodError
                              }
                              cashUserOnlyError={cashUserOnlyError}
                              openChat={openChat}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="checkout-sidebar">
                    <div className="checkout-sidebar-sticky">
                      <h2 className="section-title">
                        {intl.get("order-summary")}
                      </h2>
                      {Config.enablePromotion && this.renderPromotions()}
                      <CheckoutSummaryList
                        data={orderData}
                        giftCards={certificates}
                        onChange={() => {
                          this.fetchOrderData();
                        }}
                        history={history}
                        auth={auth}
                      />
                      <button
                        className="ep-btn primary wide"
                        aria-label={intl.get("complete-order")}
                        type="button"
                        disabled={
                          (selectedPayment === PaymentMethods.kinpay &&
                            disableCompleteOrder) ||
                          (shippingOptions && !shippingOptions.length) ||
                          (shippingAddresses && !shippingAddresses.length)
                        }
                        onClick={this.onCompleteOrder}
                      >
                        {intl.get("complete-order")}
                      </button>
                      {Config.calculatePrice ? (
                        <p className="price-disclaimer">
                          *
                          {intl.get(
                            "commodity-prices-may-vary-at-final-checkout"
                          )}
                        </p>
                      ) : null}
                      {missingFields && (
                        <div className="required-fields-fail">
                          <Messagecontainer
                            message={{
                              type: "",
                              debugMessages: missingFields
                            }}
                            closeContainerHandler={this.resetMissingFields}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <div className="checkout-container container-fluid">
        <div className="checkout-container-inner container">
          <div
            data-region="checkoutTitleRegion"
            className="checkout-title-container"
            style={{ display: "block" }}
          >
            <div>
              <h1 className="view-title">{intl.get("checkout-summary")}</h1>
            </div>
          </div>
          <div className="checkout-main-container">
            <div className="loader" />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CheckoutPage);
