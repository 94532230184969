/**
 * Refactored component from productdisplayitem.main.tsx. This component handles loading the dynamic
 * PDP Components that load in below the fold. These components are driven by the ep.config.json files
 * and control the order that they are inserted onto the page.
 */
import React, { FC, Ref, useContext } from "react";

import {
  getConfig,
  IEpConfig
} from "@zilker/store-components/src/utils/ConfigProvider";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";

import ProductRecommendationsDisplayMain from "../../ProductRecommendations/productrecommendations.main";
import ProductDetailTabsComponent from "../../ProductDetailTabsComponent/ProductDetailTabsComponent";
import { ItemDescription } from "../productdisplayitem.interfaces";

interface ProductLongDescriptionProps {
  productData: any;
  currentTab: any;
  productDocuments: any;
  productId: string;
  titleRef: Ref<HTMLDivElement>;
  productDetailsTabsRef: Ref<HTMLDivElement>;
}

const PDPComponents: FC<ProductLongDescriptionProps> = ({
  productData,
  currentTab,
  productDocuments,
  productId,
  titleRef,
  productDetailsTabsRef
}) => {
  const context = useContext<{ auth: any; user: any; cart: any }>(MainContext);
  const {
    auth: { isLoggedIn },
    user: {
      userProfile: { customerNumber }
    },
    cart: {
      cartDetails: { defaultCart }
    }
  } = context;
  const hasUserProfileLoaded = !!(
    defaultCart &&
    defaultCart.selectedBranch &&
    customerNumber
  );
  const { config }: { config: IEpConfig } = getConfig();

  const renderPDPLayout = (component: string) => {
    switch (component) {
      case "recommended-products":
        return (
          <ProductRecommendationsDisplayMain
            productDetails={generateProductDetails()}
            titleRef={titleRef}
            key="recommended-products"
            productDataDetails={productData._definition[0].details}
          />
        );
      case "tabs":
        return (
          <ProductDetailTabsComponent
            productData={productData}
            currentTab={currentTab}
            productDocuments={productDocuments}
            key="tabs"
            ref={productDetailsTabsRef}
          />
        );
      default:
        return null;
    }
  };

  /**
   * ## generateProductDetails
   * @description Extracts title, brand and SKU from product data.
   */
  const generateProductDetails = () => {
    const result = {
      title: "",
      brand: "",
      sku: productId
    };

    if (productData) {
      const productDetails = productData._definition
        ? productData._definition[0].details
        : [];
      if (productDetails.length) {
        const productTitle = productData._definition
          ? productData._definition[0]["display-name"]
          : "";

        const productBrand = productDetails.find(
          (detail: ItemDescription) => detail.name === "brand"
        );

        if (productTitle && productBrand) {
          result.title = productTitle;
          result.brand = productBrand.value;
        }
      }
    }

    return result;
  };

  let result;
  if (isLoggedIn) {
    result = hasUserProfileLoaded ? (
      config.pdpComponentsOrder.map(component => renderPDPLayout(component))
    ) : (
      <div className="miniLoader" />
    );
  } else {
    result = config.pdpComponentsOrder.map(component =>
      renderPDPLayout(component)
    );
  }
  return <>{result}</>;
};

export default PDPComponents;
