import React, { FC } from "react";
import intl from "react-intl-universal";

import "./search.results.availability.checkbox.less";

interface SearchResultsAvailabilityCheckoboxProps {
  checked: boolean;
  handler: (event: any) => void;
}

const SearchResultsAvailabilityCheckobox: FC<
  SearchResultsAvailabilityCheckoboxProps
> = ({ checked, handler }) => (
  <div className="label filter-availability-label">
    <label id="filter-availability-label" htmlFor="sortByAvailability">
      <span>{intl.get("show-available-items-first")}</span>
      <input
        name="sortByAvailability"
        id="sortByAvailability"
        type="checkbox"
        checked={checked}
        onChange={handler}
      />
    </label>
  </div>
);

export default SearchResultsAvailabilityCheckobox;
