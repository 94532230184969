import React from "react";
import intl from "react-intl-universal";
import {
  page,
  BranchMap,
  BranchListComponent,
  getConfig
} from "@zilker/store-components";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
} from "../utils/helpers";
import {
  changeBranchAndVendorOnCurrentOrder,
  updateDefaultProfile
} from "../services/EpServices";

import "./BranchSettings.less";

interface BranchDetails {
  address: string;
  address1: string;
  addressee: string;
  branchManager: string;
  branchName: string;
  branchNumber: string;
  city: string;
  country: string;
  createdAt: any;
  customBranchEmail?: string;
  distance?: string;
  distributor: string;
  devisionCode: string;
  devisionName: string;
  ehub: boolean;
  email: string;
  emergencyContact: string;
  fax: string;
  formattedAddress: string;
  geocoded: boolean;
  items?: any;
  latitude: string;
  location: any;
  locationType: string;
  longitude: string;
  otherContact: string;
  phone: string;
  regionCode: string;
  regionName: string;
  state: string;
  timezone: string;
  updatedAt: string;
  zip: string;
  _id: string;
}
interface BranchSettingsPageProps {
  history: any;
  auth: any;
  user: any;
  cart: any;
  branches: any;
}

interface BranchSettingsPageState {
  branches: Array<BranchDetails>;
  userHomeBranch: BranchDetails;
  openModal: boolean;
  newSelectedBranch: BranchDetails;
}

let Config;

class BranchSettingsPage extends React.Component<
  BranchSettingsPageProps,
  BranchSettingsPageState
> {
  _isMounted = false;

  constructor(props) {
    super(props);
    Config = getConfig().config;
    this.state = {
      branches: null,
      userHomeBranch: undefined,
      openModal: false,
      newSelectedBranch: undefined
    };
    this.changeBranch = this.changeBranch.bind(this);
    this.changeHomeBranch = this.changeHomeBranch.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchBranches();

    page();
  }

  componentDidUpdate(prevProps) {
    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.props;
    const { branches } = this.state;
    if (
      prevProps.cart.cartDetails.defaultCart &&
      defaultCart &&
      prevProps.cart.cartDetails.defaultCart.selectedBranch.code !==
        defaultCart.selectedBranch.code
    ) {
      this.fetchBranches();
      return;
    }
    if (!branches) {
      this.fetchBranches();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeBranch(branchNumber: string, closeModal = undefined) {
    const {
      history,
      auth: { logout },
      cart: {
        getCartDetails,
        cartDetails: {
          defaultCart: { cartOrderDetailsForm }
        }
      },
      branches: { findBranch }
    } = this.props;

    const branch: BranchDetails = findBranch(branchNumber);
    const fetchLink: string = cartOrderDetailsForm.links[0].uri;

    changeBranchAndVendorOnCurrentOrder(branch, fetchLink)
      .then(() => {
        return getCartDetails();
      })
      .then(() => {
        if (closeModal) {
          closeModal();
        }
        sessionStorage.setItem("notified-branch", "true");
      })
      .catch((e: any) => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => changeBranchOnCurrentOrder => BranchSettings.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "changeBranchOnCurrentOrder => BranchSettings.tsx"
          });
        }
      });
  }

  changeHomeBranch(branchNumber: string, closeBranchMapPopup = undefined) {
    const {
      user,
      auth: { logout },
      history,
      branches: { findBranch }
    } = this.props;
    const { userProfile, getUserProfile } = user;

    updateDefaultProfile(
      {
        "home-branch": branchNumber
      },
      userProfile
    )
      .then(() => {
        return getUserProfile();
      })
      .then(() => {
        const userHomeBranch = findBranch(branchNumber);
        this.setState({
          userHomeBranch
        });
        if (closeBranchMapPopup) {
          closeBranchMapPopup();
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err => {
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => changeHomeBranch => BranchSettings.tsx"
            });
          });
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "changeHomeBranch => BranchSettings.tsx"
          });
        }
      });
  }

  fetchBranches() {
    const {
      history,
      user: { userProfile },
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { branchesList, findBranch }
    } = this.props;

    if (branchesList && userProfile && defaultCart && this._isMounted) {
      if (!userProfile.subuserHomeBranch && !userProfile.homeBranch) {
        // User missing home branch (No home branch defined in DCS profile)
        pushToMaintenace(history, {
          e: { message: intl.get("no-home-branch-error") },
          errIn: "fetchBranches => BranchSettings.tsx",
          errorCode: intl.get("missing-home-branch-error-code")
        });
      } else {
        const userHomeBranch = findBranch(
          userProfile.subuserHomeBranch || userProfile.homeBranch
        );
        const selectedBranch = findBranch(defaultCart.selectedBranch.code);
        if (userHomeBranch) {
          this.setState({
            branches: branchesList,
            userHomeBranch,
            newSelectedBranch: selectedBranch
          });
        } else {
          // Home branch does no longer exist in the list of entitled branches
          pushToMaintenace(history, {
            e: { message: intl.get("unentitled-home-branch") },
            errIn: "fetchBranches => BranchSettings.tsx",
            errorCode: intl.get("home-branch-not-in-branches-list-error-code")
          });
        }
      }
    }
  }

  renderBranchList() {
    const { branches, userHomeBranch } = this.state;
    return (
      <BranchListComponent
        branches={branches}
        homeBranch={userHomeBranch}
        changeBranch={this.changeBranch}
      />
    );
  }

  render() {
    const {
      branches,
      openModal,
      newSelectedBranch,
      userHomeBranch
    } = this.state;

    const { defaultChannel } = Config.brXM;
    const motiliStyle = defaultChannel === "motili";

    const {
      user: {
        userProfile: { customerRoles }
      }
    } = this.props;
    const BRANCHES_VIRTUAL = intl.get("virtual-branches");
    const isVirtualBranchUser =
      customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

    if (branches) {
      return (
        <div className="branch container">
          <h2 className={motiliStyle ? "bullet" : "no-bottom-border"}>
            {intl.get("branch-settings")}
          </h2>
          {userHomeBranch && newSelectedBranch && !isVirtualBranchUser && (
            <BranchMap
              defaultBranch={userHomeBranch.branchNumber}
              currentBranch={newSelectedBranch.branchNumber}
              setCurrentBranch={this.changeBranch}
              setHomeBranch={this.changeHomeBranch}
              places={branches}
            />
          )}
          {!motiliStyle ? (
            <div className="branch-heading">
              <p className="label right">{intl.get("select-branch-store")}:</p>
              <div className="left">
                <p className="label branch-name">
                  {intl.get("selected-branch")}:
                </p>
                {newSelectedBranch && (
                  <p className="branch-name">{newSelectedBranch.branchName}</p>
                )}
              </div>
            </div>
          ) : (
            <h3 className="branch-settings-subtitle">
              {intl.get("personal-information")}
            </h3>
          )}
          {this.renderBranchList()}
        </div>
      );
    }

    return <div className="loader" />;
  }
}

export default BranchSettingsPage;
