/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { Redirect } from "react-router-dom";
import Modal from "react-responsive-modal";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { adminFetch } from "../utils/Cortex";
import "./appmodalcartselect.main.less";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

const zoomArray = [
  "authorizationcontexts",
  "authorizationcontexts:element",
  "authorizationcontexts:element:element",
  "authorizationcontexts:element:element:accesstokenform"
];

interface AppModalCartSelectMainProps {
  handleModalClose: (...args: any[]) => any;
  handleCartModalCloseButton: (...args: any[]) => any;
  openModal: boolean;
  onContinueCart?: (...args: any[]) => any;
  fetchCartData?: (...args: any[]) => any;
  closeSsoLoader?: (...args: any[]) => any;
  history: any;
  auth: any;
}
interface AppModalCartSelectMainState {
  orgAuthServiceData: any;
  selectedCart: string;
  continueLoader: boolean;
  shopForProcess: boolean;
  showModal: boolean;
}

class AppModalCartSelectMain extends React.Component<
  AppModalCartSelectMainProps,
  AppModalCartSelectMainState
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = getConfig());
    this.state = {
      orgAuthServiceData: undefined,
      selectedCart: "0",
      continueLoader: false,
      shopForProcess: false,
      showModal: false
    };
    this.continueCart = this.continueCart.bind(this);
    this.fetchOrganizationData = this.fetchOrganizationData.bind(this);
    this.handleCartChange = this.handleCartChange.bind(this);
  }

  componentDidMount() {
    if (!this.isLoggingInProcess()) {
      this.setState(
        {
          shopForProcess: true
        },
        this.fetchOrganizationData
      );
    } else {
      this.fetchOrganizationData();
    }
  }

  /**
   * ## fetchOrganizationData
   *
   * @param
   *
   * @remarks This function fetches carts available to the user for both
   * ssoLogin and ShopFor process.
   *
   * @returns void
   */

  fetchOrganizationData() {
    const { closeSsoLoader, handleModalClose, history } = this.props;

    const {
      context: {
        auth: { logout }
      }
    } = this;

    const { token } = this.loginOrShopForTokenRole();

    const headers = {
      "Content-Type": "application/json",
      Authorization: token
    };

    adminFetch(`/?zoom=${zoomArray.join()}`, { headers })
      .then(res => checkResponse(res))
      .then(res => {
        const orgAuthServiceData = res._authorizationcontexts[0]._element.find(
          element =>
            element.name.toUpperCase() === Config.cortexApi.scope.toUpperCase()
        );

        const updateStateObject = this.organizationDataStateObject(
          orgAuthServiceData
        );

        closeSsoLoader();

        this.setState(updateStateObject, () => {
          if (!updateStateObject.showModal && orgAuthServiceData._element) {
            const multipleCompanies = orgAuthServiceData._element.length !== 1;
            this.continueCart(null, multipleCompanies);
          }
        });
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => fetchOrganizationData => AppModalCartSelectMain.tsx"
            })
          );
        } else {
          handleModalClose();
          closeSsoLoader();
          pushToMaintenace(history, {
            e,
            errIn: "fetchOrganizationData => AppModalCartSelectMain.tsx"
          });
        }
      });
  }

  /**
   * ## organizationDataStateObject
   *
   * @param
   *
   * @remarks This function creates an object containing fetched carts,
   * and an index of a currently selected cart (index is usefull to
   * mark the selected cart in the Shop For modal).
   *
   * @returns {orgAuthServiceData: any, selectedCart: string}
   */

  organizationDataStateObject(orgAuthServiceData) {
    const { shopForProcess } = this.state;

    const updateStateObject: {
      orgAuthServiceData: any;
      selectedCart: string;
      showModal: boolean;
    } = {
      orgAuthServiceData,
      selectedCart: "0",
      showModal: orgAuthServiceData._element
        ? orgAuthServiceData._element.length !== 1
        : true
    };

    const cartName = localStorage.getItem(`${Config.cortexApi.scope}_b2bCart`);

    if (cartName && shopForProcess) {
      let currentSelectedCart = orgAuthServiceData._element.findIndex(cart => {
        return cart.name === cartName;
      });
      currentSelectedCart = (currentSelectedCart || 0).toString();
      updateStateObject.selectedCart = currentSelectedCart;
    }

    // Check if user has Motili account and return appropriate account that should be selected
    const motiliAccount: string =
      orgAuthServiceData._element &&
      this.checkForMotiliAccount(orgAuthServiceData._element);

    if (motiliAccount) {
      updateStateObject.selectedCart = motiliAccount;
      updateStateObject.showModal = false;
    }

    return updateStateObject;
  }

  /**
   * ## continueCart
   *
   * @param
   *
   * @remarks This function fetches and sets tokens for the selected cart.
   * It is the final stage of both ssoLogin and ShopFor process.
   *
   * @returns void
   */

  continueCart(event, multipleCompanies) {
    const { selectedCart, orgAuthServiceData, shopForProcess } = this.state;
    const {
      onContinueCart,
      fetchCartData,
      closeSsoLoader,
      handleModalClose,
      history
    } = this.props;

    const {
      context: {
        auth: { logout },
        cart: { getCartDetails },
        user: { getUserProfile }
      }
    } = this;

    this.setState({ continueLoader: true });

    const { token, userRole } = this.loginOrShopForTokenRole();

    const headers = {
      "Content-Type": "application/json",
      Authorization: token
    };

    if (userRole === "REGISTERED") {
      const selectedCartData = orgAuthServiceData._element[selectedCart];
      adminFetch(
        `${selectedCartData._accesstokenform[0].self.uri}/?followlocation=true`,
        {
          method: "post",
          headers,
          body: JSON.stringify({})
        }
      )
        .then(res => checkResponse(res))
        .then(tokensData => {
          this.setTokensToLocalStorage(tokensData.token, selectedCartData.name);

          return fetchCartData();
        })
        .then(() => {
          if (shopForProcess) {
            getUserProfile();
            getCartDetails();
          } else {
            this.removeTokensFromContext(multipleCompanies);
          }
          onContinueCart();
          handleModalClose();
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => continueCart => AppModalCartSelectMain.tsx"
              })
            );
          } else {
            closeSsoLoader();
            handleModalClose();
            pushToMaintenace(history, {
              e,
              errIn: "continueCart => AppModalCartSelectMain.tsx"
            });
          }
        });
    }
  }

  /**
   * ## setTokensToLocalStorage
   *
   * @param token string - token to set to localStorage
   * @param cartName string - name of the selected cart to set to localStorage
   *
   * @remarks This function sets tokens to localStorage. If this is during
   * the sso login process, it will also set tokens that are temporarily in
   * AuthContext, if this is ShopFor process, it will only set _b2bCart,
   * _oAuthToken and DK_oAuthToken.
   *
   * @returns void
   */

  setTokensToLocalStorage(token: string, cartName: string) {
    const { shopForProcess } = this.state;
    const {
      context: {
        auth: {
          tempSsoTokens: {
            _oAuthTokenAuthService,
            _oAuthRole,
            _oAuthScope,
            _oAuthUserName
          }
        }
      }
    } = this;

    if (!shopForProcess) {
      localStorage.setItem(
        `${getConfig().config.cortexApi.scope}_oAuthRole`,
        _oAuthRole
      );
      localStorage.setItem(
        `${getConfig().config.cortexApi.scope}_oAuthScope`,
        _oAuthScope
      );
      localStorage.setItem(
        `${getConfig().config.cortexApi.scope}_oAuthTokenAuthService`,
        _oAuthTokenAuthService
      );
      localStorage.setItem(
        `${getConfig().config.cortexApi.scope}_oAuthUserName`,
        _oAuthUserName
      );
    }

    localStorage.setItem(`${Config.cortexApi.scope}_b2bCart`, cartName);
    localStorage.setItem(
      `${Config.cortexApi.scope}_oAuthToken`,
      `Bearer ${token}`
    );
    localStorage.setItem(`DK_oAuthToken`, `Bearer ${token}`);
  }

  /**
   * ## removeTokensFromContext
   *
   * @param
   *
   * @remarks This function removes tokens from AuthContext that are placed there
   * temporarily for the sso login process.
   *
   * @returns void
   */

  removeTokensFromContext(multipleCompanies = true) {
    const {
      context: {
        auth: { setTempSsoTokens }
      }
    } = this;
    const removeTempTokens = {
      _oAuthTokenAuthService: "",
      _oAuthRole: "",
      _oAuthScope: "",
      _oAuthUserName: ""
    };

    setTempSsoTokens(prevState => {
      return { ...prevState, ...removeTempTokens, multipleCompanies };
    });
  }

  /**
   * ## loginOrShopForTokenRole
   *
   * @param
   *
   * @remarks This function returns _oAuthTokenAuthService and _oAuthRole
   * If this is during the sso login process, it will return them from context
   * If this is ShopFor process, it will return them from localStorage.
   *
   * @returns { token: string, userRole: string }
   */

  loginOrShopForTokenRole() {
    const {
      context: {
        auth: {
          tempSsoTokens: { _oAuthTokenAuthService, _oAuthRole }
        }
      }
    } = this;

    const token =
      _oAuthTokenAuthService ||
      localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_oAuthTokenAuthService`
      );
    const userRole =
      _oAuthRole ||
      localStorage.getItem(`${getConfig().config.cortexApi.scope}_oAuthRole`);

    return {
      token,
      userRole
    };
  }

  /**
   * ## isLoggingInProcess
   *
   * @param
   *
   * @remarks This function determens wether the sso login process is occuring
   * (The alternative to sso login process is ShopFor process).
   *
   * @returns boolean
   */

  isLoggingInProcess() {
    const {
      context: {
        auth: {
          tempSsoTokens: { _oAuthTokenAuthService }
        }
      }
    } = this;

    return !!_oAuthTokenAuthService;
  }

  /**
   * ## checkForMotiliAccount
   *
   * @param accounts: any - list of accounts associated with the user
   *
   * @description Some Motili users can also have a Goodman/Daikin/Amana account
   * For this users we won't show modal for selecting account
   * If user is in Motili store, we will automatically pick account with name format "Company_Mxxx"
   * If user is in Goodman/Daikin/Amana store, we will automatically pick account with name format that does not have "M" after the "Company_"
   * This users can only have two accounts -  one for Motili and one for Goodman/Daikin/Amana
   *
   * Function returns index of a account that should be selected
   *
   * @returns string
   */
  // eslint-disable-next-line class-methods-use-this
  checkForMotiliAccount(accounts: any): string {
    const {
      cortexApi: { scope }
    } = Config;

    const motiliAccount = accounts.find(account =>
      account.name.startsWith("Company_M")
    );
    const motiliAccountIndex: number = accounts.indexOf(motiliAccount);
    let selectedAccount: string;

    if (motiliAccount) {
      if (scope === "motili") {
        selectedAccount = motiliAccountIndex.toString();
      } else {
        selectedAccount = Number(!motiliAccountIndex).toString();
      }

      return selectedAccount;
    }

    return null;
  }

  handleCartChange(event) {
    this.setState({
      selectedCart: event.target.value
    });
  }

  renderCartOption() {
    const { selectedCart, orgAuthServiceData } = this.state;

    if (orgAuthServiceData && orgAuthServiceData._element) {
      return orgAuthServiceData._element.map((division, index) => {
        if (division) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="radio" key={`${division.name}-${index}`}>
              <label
                htmlFor={`cart-selection-option${index}`}
                className="custom-radio-button"
              >
                <input
                  id={`cart-selection-option${index}`}
                  type="radio"
                  value={index}
                  checked={selectedCart === `${index}`}
                  onChange={this.handleCartChange}
                />
                <span className="helping-el" />
                <span className="label-text">{division.name}</span>
              </label>
            </div>
          );
        }
        return null;
      });
    }
    return (
      <div className="radio division-message">
        <label htmlFor="cart-selection-option">
          <span className="label-text">{intl.get("no-divisions-found")}</span>
        </label>
      </div>
    );
  }

  render() {
    const {
      selectedCart,
      orgAuthServiceData,
      continueLoader,
      shopForProcess,
      showModal
    } = this.state;
    const {
      handleModalClose,
      openModal,
      handleCartModalCloseButton
    } = this.props;

    const selectedCartName =
      orgAuthServiceData && orgAuthServiceData._element
        ? orgAuthServiceData._element[selectedCart].name
        : "";

    if (!orgAuthServiceData) {
      return null;
    }

    return !showModal ? null : (
      <Modal
        open={openModal && !!orgAuthServiceData}
        onClose={shopForProcess ? handleModalClose : handleCartModalCloseButton}
        classNames={{ modal: "cart-selection-modal-content" }}
      >
        <div className="modal-lg">
          <div className="modal-content" id="simplemodal-container">
            <div className="modal-header">
              <h2 className="modal-title">
                {orgAuthServiceData && !orgAuthServiceData._element
                  ? intl.get("change-carts-permission-denied")
                  : intl.get("change-carts")}
              </h2>
            </div>

            <div className="modal-body">
              <div id="cart_selection_modal_form">
                <div className="carts-selection-region">
                  {this.renderCartOption()}
                </div>
                <div className="action-row">
                  <div className="form-input btn-container">
                    {continueLoader ? (
                      <div className="miniLoader" />
                    ) : (
                      <button
                        onClick={
                          (orgAuthServiceData &&
                            !orgAuthServiceData._element) ||
                          !orgAuthServiceData
                            ? handleCartModalCloseButton
                            : this.continueCart
                        }
                        className="ep-btn primary wide"
                        id="continue_with_cart_button"
                        aria-label={
                          (orgAuthServiceData &&
                            !orgAuthServiceData._element) ||
                          !orgAuthServiceData
                            ? intl.get("change-carts-ok")
                            : `${intl.get("continue-with")} ${selectedCartName}`
                        }
                        data-cmd="continue"
                        // data-toggle="collapse"
                        // data-target=".navbar-collapse"
                        type="submit"
                      >
                        {(orgAuthServiceData && !orgAuthServiceData._element) ||
                        !orgAuthServiceData
                          ? intl.get("change-carts-ok")
                          : `${intl.get("continue-with")} ${selectedCartName}`}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AppModalCartSelectMain;
