/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { RenderCmsComponent } from "bloomreach-experience-react-sdk";
import AppFooterMenu from "./AppFooterColumns/appfootermenu";
import { getConfig } from "../utils/ConfigProvider";
import detectIEBrowser from "../../../app/src/validation/detectIEBrowser";
import "./appfooter.main.less";
import ZendeskWidget from "../ZendeskWidget/ZendeskWidget.js";

interface AppFooterMainProps {
  appFooterLinks?: {
    [key: string]: any;
  };
}

let intl = { get: str => str };

function AppFooterMain(props: AppFooterMainProps) {
  ({ intl } = getConfig());
  const { config } = getConfig();
  const year = new Date().getFullYear();
  const domain = window.location.hostname;
  return (
    <>
      <footer className="app-footer d-none d-md-block">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col">
                <RenderCmsComponent path="footer-logo" />
              </div>
              {!detectIEBrowser() ? (
                <div className="col">
                  <RenderCmsComponent
                    path="footer-menu"
                    renderComponent={AppFooterMenu}
                  />
                </div>
              ) : (
                <div className="col" />
              )}
            </div>
          </div>
        </div>
        <div className="copyright-container container-fluid d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col">
                <div>
                  &copy;
                  {`${year} ${domain}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <footer className="app-footer d-block d-md-none">
        <RenderCmsComponent path="footer-logo" />
        {!detectIEBrowser() ? (
          <RenderCmsComponent
            path="footer-menu"
            renderComponent={AppFooterMenu}
          />
        ) : null}
        <div className="copyright-container container-fluid d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <div>
                  &copy;
                  {`${year} ${domain}`}
                </div>
              </div>
            </div>
          </div>
        </div>
        {config.chatEnabled && <ZendeskWidget />}
      </footer>
    </>
  );
}

export default AppFooterMain;
