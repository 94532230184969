/* eslint-disable class-methods-use-this */
import { getConfig, cortexFetch, adminFetch } from "@zilker/store-components";
import * as TokenService from "./TokenService";
import { checkResponse } from "../utils/helpers";
// import { checkTokensExpired } from "../utils/helpers";

/**
 * @member {function}
 * @desc Generate POST form body.
 */
export const _generateFormBody = userDetails => {
  const userFormBody = [];
  let userFormBodyString = "";
  Object.keys(userDetails).forEach(encodedKey => {
    const encodedValue = userDetails[encodedKey];
    userFormBody.push(`${encodedKey}=${encodedValue}`);
  });
  userFormBodyString = userFormBody.join("&");

  return userFormBodyString;
};

/**
 * @member {function}
 * @desc Indicates if a user is logged in.
 */
export const isLoggedIn = () => {
  return !!localStorage.getItem(
    `${getConfig().config.cortexApi.scope}_oAuthUserName`
  );
};

/**
 * @member {function}
 * @desc Do public login (proxy to Token service).
 */
export const doPublicLogin = () => {
  return TokenService.getEPTokens().then(tokens =>
    TokenService.setEPTokens(tokens)
  );
};

/**
 * @member {function}
 * @desc Log in a registered user.
 * @param {string} username
 * @param {string} password
 */
export const doRegisteredLogin = ({ username, password }) => {
  return new Promise((resolve, reject) => {
    const registeredUserDetails = {
      username,
      password,
      grant_type: "password",
      role: "REGISTERED",
      scope: getConfig().config.cortexApi.scope
    };

    cortexFetch("/oauth2/tokens", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
        Authorization: localStorage.getItem(
          `${getConfig().config.cortexApi.scope}_oAuthToken`
        )
      },
      body: _generateFormBody(registeredUserDetails)
    })
      .then(res => {
        const onSuccess = data => resolve(data.json());
        const onError = data => reject(data);
        return checkResponse(res, onSuccess, onError);
      })
      .catch(error => reject(error));
  });
};

/**
 * @member {function}
 * @desc Invalidate the current session and log out. Clears localStorage.
 */
export const doLogout = (history = undefined) => {
  const { config } = getConfig();

  return adminFetch("/oauth2/tokens", {
    method: "delete"
  })
    .then(res => {
      const onSuccess = data => data;
      return checkResponse(res, onSuccess);
    })
    .then(res => {
      localStorage.removeItem(`${config.cortexApi.scope}_oAuthRole`);
      localStorage.removeItem(`${config.cortexApi.scope}_oAuthScope`);
      localStorage.removeItem(`${config.cortexApi.scope}_oAuthToken`);
      localStorage.removeItem(`${config.cortexApi.scope}_oAuthUserName`);
      localStorage.removeItem(`${config.cortexApi.scope}_b2bCart`);
      localStorage.removeItem(
        `${config.cortexApi.scope}_oAuthTokenAuthService`
      );
      localStorage.removeItem(`${config.cortexApi.scope}_keycloakSessionState`);
      localStorage.removeItem(`${config.cortexApi.scope}_keycloakCode`);
      localStorage.removeItem("DK_oAuthToken");
      localStorage.setItem("ajs_user_id", "");
      localStorage.setItem("ajs_user_traits", JSON.stringify({}));
      localStorage.removeItem("recent_searches");
      localStorage.removeItem("request_id");
      return res;
    })
    .catch(error => {
      throw error;
    });
};
