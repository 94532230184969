import React from "react";
import { Redirect } from "react-router-dom";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { cortexFetch } from "../utils/Cortex";

import "./searchfacetnavigation.main.less";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface SearchFacetNavigationMainProps {
  productData: {
    [key: string]: any;
  };
  onFacetSelection?: (res: any) => any;
  history: any;
  auth: any;
}

interface SearchFacetNavigationMainState {
  facetModel: any;
  showFilterMobileMenu: boolean;
}

class SearchFacetNavigationMain extends React.Component<
  SearchFacetNavigationMainProps,
  SearchFacetNavigationMainState
> {
  constructor(props) {
    super(props);
    const { productData } = this.props;
    const epConfig = getConfig();
    Config = getConfig().config;
    ({ intl } = epConfig);
    this.state = {
      facetModel: productData,
      showFilterMobileMenu: false
    };
    this.handleFacetSelection = this.handleFacetSelection.bind(this);
    this.handleOpenFilterMenu = this.handleOpenFilterMenu.bind(this);
    this.handleCloseFilterMenu = this.handleCloseFilterMenu.bind(this);
  }

  componentDidMount() {
    document.body.style.overflow = "unset";
  }

  handleFacetSelection(facetUri) {
    const {
      onFacetSelection,
      history,
      auth: { logout }
    } = this.props;

    cortexFetch(
      `${decodeURIComponent(
        facetUri
      )}?followlocation=true&zoom=offersearchresult`,
      {
        method: "post",
        body: JSON.stringify({})
      }
    )
      .then(res => checkResponse(res))
      .then(res => {
        onFacetSelection(res);
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => handleFacetSelection => SearchFacetNavigationMain.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "handleFacetSelection => SearchFacetNavigationMain.tsx"
          });
        }
      });
  }

  renderFacetSelectorsChosen(facetselector) {
    if (facetselector[0]._chosen) {
      const facetValues = facetselector[0]._chosen.map(chosen => {
        return {
          value: chosen._description[0].value,
          uri: chosen._selectaction[0].self.uri,
          description: chosen._description,
          selector: chosen._selector
        };
      });

      const sortedfacetValues = facetValues.sort((a, b) =>
        a.value.localeCompare(b.value)
      );

      if (sortedfacetValues.length) {
        return facetValues.map(chosen => {
          if (chosen.description && chosen.selector) {
            return (
              <li className="list-group-item facet-value" key={chosen.value}>
                <button
                  type="button"
                  className="form-check-label chosen"
                  onClick={() =>
                    this.handleFacetSelection(encodeURIComponent(chosen.uri))
                  }
                >
                  <span className="icon icon-check-square chosen" />
                  <span className="name">{chosen.value}</span>
                </button>
              </li>
            );
          }
          return null;
        });
      }
    }
    return null;
  }

  renderFacetSelectors(facetselector) {
    if (facetselector[0]._choice) {
      const facetValues = facetselector[0]._choice.map(choice => {
        return {
          value: choice._description[0].value,
          uri: choice._selectaction[0].self.uri,
          description: choice._description,
          selector: choice._selector,
          count: choice._description[0].count
        };
      });

      const sortedfacetValues = facetValues.sort((a, b) =>
        a.value.localeCompare(b.value)
      );

      if (sortedfacetValues.length) {
        return sortedfacetValues.map(choice => {
          if (choice.description && choice.selector) {
            return (
              <li className="list-group-item facet-value" key={choice.value}>
                <button
                  type="button"
                  className="form-check-label choice"
                  onClick={() =>
                    this.handleFacetSelection(encodeURIComponent(choice.uri))
                  }
                >
                  <span className="icon icon-square" />
                  <span className="name">{`${choice.value}`}</span>
                  <span className="number">{`(${choice.count})`}</span>
                </button>
              </li>
            );
          }
          return null;
        });
      }
    }
    return null;
  }

  renderFacets() {
    const { facetModel } = this.state;
    const { history } = this.props;
    if (facetModel) {
      let sortedFacetNames = null;
      try {
        const facets = facetModel._facets[0]._element;

        sortedFacetNames = facets.sort((a, b) =>
          a["display-name"].localeCompare(b["display-name"])
        );
      } catch (e) {
        pushToMaintenace(history, {
          e,
          errIn: "renderFacets => SearchFacetNavigationMain.tsx"
        });
      }

      if (sortedFacetNames) {
        return sortedFacetNames.map(facet => {
          if (facet["display-name"]) {
            const facetDisplayNameId = facet["display-name"]
              .toLowerCase()
              .replace(/ /g, "_");
            return (
              <div
                className="card"
                key={facet["display-name"]}
                id={`${facetDisplayNameId}_facet`}
              >
                <div className="card-header">
                  <a
                    className="facet"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="true"
                    aria-controls={`${facetDisplayNameId}_facet_values`}
                    href={`#${facetDisplayNameId}_facet_values`}
                  >
                    <h4 className="card-title bullet">
                      {facet["display-name"]}
                    </h4>
                  </a>
                </div>
                <div
                  id={`${facetDisplayNameId}_facet_values`}
                  className="collapse show"
                >
                  <ul className="list-group list-group-flush">
                    {this.renderFacetSelectorsChosen(facet._facetselector)}
                    {this.renderFacetSelectors(facet._facetselector)}
                  </ul>
                </div>
              </div>
            );
          }
          return null;
        });
      }
    }
    return null;
  }

  handleOpenFilterMenu() {
    this.setState({ showFilterMobileMenu: true });
    document.body.style.overflow = "hidden";
  }

  handleCloseFilterMenu() {
    this.setState({ showFilterMobileMenu: false });
    document.body.style.overflow = "unset";
  }

  render() {
    const { facetModel, showFilterMobileMenu } = this.state;
    if (
      facetModel._facets &&
      facetModel._facets.length > 0 &&
      facetModel._element
    ) {
      const chosenFacets = facetModel._facets[0]._element.filter(
        el => el._facetselector[0]._chosen
      );
      return (
        <div className="product-list-facet-navigation-component">
          <div className="col-xs-12 col-sm-12">
            <div className="filter-btn-wrap">
              <button
                type="button"
                aria-label="filter button"
                className={`filter-btn ep-btn secondary ${
                  chosenFacets.length > 0 ? "filtered" : ""
                }`}
                onClick={this.handleOpenFilterMenu}
              >
                <span className="icon-filter" />
                <span className="btn-text">{intl.get("filter")}</span>
                <span className="check-icon" />
              </button>
            </div>
            <div
              className={`${
                showFilterMobileMenu ? "show-filter-mobile-menu" : ""
              } card-stack`}
              id="accordion"
            >
              <div className="close-filter-mobile-menu-wrap">
                <h2>{intl.get("filter")}</h2>
                <button
                  type="button"
                  aria-label={intl.get("filter")}
                  className="close-filter-mobile-menu"
                  onClick={this.handleCloseFilterMenu}
                />
              </div>
              <div className="facets-container content-box">
                {this.renderFacets()}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return "";
  }
}

export default SearchFacetNavigationMain;
