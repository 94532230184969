import React, { FC, useState, useContext, useEffect } from "react";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";

import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { updateDefaultProfile } from "@elasticpath/ref-store/src/services/EpServices";
import {
  checkTokensExpired,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

import "./appmodalphoneupdate.less";

interface AppModalPhoneUpdateProps {
  history: any;
}

const AppModalPhoneUpdate: FC<AppModalPhoneUpdateProps> = ({ history }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const context = useContext<{ user: any; auth: any }>(MainContext);
  const {
    user: { userProfile, getUserProfile },
    auth: { logout }
  } = context;
  const {
    subuserFirstName,
    subuserLastName,
    subuserEmail,
    subuserPhone
  } = userProfile;

  const { config }: { config: IEpConfig } = getConfig();

  useEffect(() => {
    const phoneUpdateModalOpened = JSON.parse(
      localStorage.getItem("phoneUpdateModalOpened")
    );
    if (
      config.showPhoneNumberPopUp &&
      !phoneUpdateModalOpened &&
      (subuserFirstName || subuserLastName || subuserEmail) &&
      (!subuserPhone ||
        subuserPhone === "9999999999" ||
        subuserPhone === "999-999-9999") &&
      !isModalOpen
    ) {
      setIsModalOpen(true);
      localStorage.setItem("phoneUpdateModalOpened", "true");
    }
  }, [subuserFirstName, subuserLastName, subuserEmail, subuserPhone]);

  const closeModal = () => setIsModalOpen(false);

  const handleInputChange = e => {
    const { value } = e.target;
    setPhoneNumber(value);
    setErrorMessage("");
  };

  const validatePhoneNumber = (): boolean => {
    const phoneRegex: RegExp = new RegExp(
      /^[(]{0,1}[0-9]{3}[)]{0,1}[-|\s]{0,1}[0-9]{3}[-]{0,1}[0-9]{4}$/
    );

    if (!phoneRegex.test(phoneNumber)) {
      setErrorMessage(intl.get("invalid-phone-number"));
      return false;
    }

    return true;
  };

  const submitPhoneNumber = () => {
    if (validatePhoneNumber()) {
      updateDefaultProfile({ "sub-user-phone": phoneNumber }, userProfile)
        .then(() => {
          getUserProfile();
          setIsModalOpen(false);
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => submitPhoneNumber => appmodalphoneupdate.tsx"
              })
            );
          } else {
            setErrorMessage(intl.get("submit-phone-number-error"));
          }
        });
    }
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        classNames={{ modal: "cart-selection-modal-content" }}
      >
        <div>
          <div className="modal-content" id="simplemodal-container">
            <div className="modal-header">
              <h2 className="modal-title">{intl.get("info")}</h2>
            </div>
            <div className="modal-body">
              <div className="content">
                <p>{intl.get("empty-phone-number-message-1")}</p>
                <div>
                  <span>{`${intl.get("phone-number")}: `}</span>
                  <input
                    name="phone-number"
                    value={phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
                <p className="error">{errorMessage}</p>
              </div>

              <div className="buttons">
                <button
                  onClick={closeModal}
                  className="ep-btn primary wide"
                  type="button"
                >
                  {intl.get("continue-without-saving")}
                </button>
                <button
                  onClick={submitPhoneNumber}
                  className="ep-btn primary wide"
                  type="button"
                >
                  {intl.get("submit-phone-number")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AppModalPhoneUpdate;
