/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { cortexFetch } from "../utils/Cortex";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import Input from "../common/InputComponent/Input";
import { MainContext } from "../../../app/src/contexts/MainContext";
import MessageContainer from "../MessageContainer/messagecontainer";

import "./add.promotion.container.less";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface AddPromotionContainerProps {
  data: {
    [key: string]: any;
  };
  history: any;
  auth: any;
  hideAddPromotionButton?: boolean;
  hideDeletePromotionButton?: boolean;
  onSubmittedPromotion: (...args: any[]) => any;
  isDeletingPromoCode?: boolean;
}

interface AddPromotionContainerState {
  isPromotionFormOpen: boolean;
  promotionCode: string;
  loader: boolean;
  promotionMessage: string;
  appliedPromoCodes: any;
  promotionValidationMessage: string;
  isDeletingPromotion: boolean;
}

class AddPromotionContainer extends React.Component<
  AddPromotionContainerProps,
  AddPromotionContainerState
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = getConfig());
    this.state = {
      isPromotionFormOpen: false,
      promotionCode: "",
      loader: false,
      promotionMessage: "",
      appliedPromoCodes: [],
      promotionValidationMessage: "",
      isDeletingPromotion: false
    };
    this.setPromotionCode = this.setPromotionCode.bind(this);
    this.submitPromotionCode = this.submitPromotionCode.bind(this);
    this.clearPromotionValidationMessage = this.clearPromotionValidationMessage.bind(
      this
    );
  }

  componentDidMount() {
    const {
      data: { cartOrderDetailsForm }
    } = this.props;
    const appliedPromoCodes = cartOrderDetailsForm["daikin-promo-codes"]
      ? cartOrderDetailsForm["daikin-promo-codes"].split("|")
      : [];
    this.setState({
      appliedPromoCodes,
      isDeletingPromotion: false
    });
  }

  componentDidUpdate(prevProps) {
    const {
      data: { cartOrderDetailsForm }
    } = this.props;
    const {
      data: { cartOrderDetailsForm: prevCartOrderDetailsForm }
    } = prevProps;
    if (
      cartOrderDetailsForm["daikin-promo-codes"] !==
      prevCartOrderDetailsForm["daikin-promo-codes"]
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        appliedPromoCodes:
          cartOrderDetailsForm["daikin-promo-codes"] &&
          cartOrderDetailsForm["daikin-promo-codes"].split("|")
      });
    }
  }

  setPromotionCode(event) {
    this.setState({ promotionCode: event.target.value });
  }

  openPromotionForm() {
    this.setState({
      isPromotionFormOpen: true
    });
  }

  closePromotionForm() {
    this.setState({
      isPromotionFormOpen: false,
      promotionMessage: ""
    });
  }

  submitPromotionCode(event) {
    this.setState({ loader: true });
    event.preventDefault();
    const {
      history,
      auth: { logout },
      onSubmittedPromotion,
      data: { cartOrderDetailsForm }
    } = this.props;
    const { promotionCode } = this.state;
    const {
      cart: { getCartDetails }
    } = this.context;

    const couponFormLink = cartOrderDetailsForm.links[0].uri;

    if (!promotionCode || promotionCode === "" || !promotionCode.trim()) {
      this.closePromotionForm();
      this.setState({
        promotionMessage: intl.get("empty-promo-code-message"),
        promotionCode: "",
        loader: false
      });
      return;
    }

    cortexFetch(couponFormLink, {
      method: "post",
      body: JSON.stringify({
        "daikin-promo-codes": promotionCode.trim(),
        "update-mode": "PROMO_CODES"
      })
    })
      .then(res => (res.ok ? res : res.json()))
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(res => {
        return getCartDetails();
      })
      .then(() => {
        this.closePromotionForm();
        this.setState({
          promotionCode: "",
          loader: false
        });
        onSubmittedPromotion();
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => submitPromotionCode => AddPromotionContainer.tsx"
            })
          );
        }
        this.closePromotionForm();

        this.setState(previousState => {
          const errorMessage =
            e && e.messages && e.messages[0] ? e.messages[0] : null;

          const updatedState = {
            ...previousState,
            promotionCode: "",
            loader: false
          };

          if (errorMessage) {
            // If EP error exists - check for invalid promo code error - will have "id":"request.invalid.promocode".
            if (errorMessage.id === "request.invalid.promocode") {
              return {
                ...updatedState,
                promotionValidationMessage: intl.get(
                  "promo-codes-validation-message"
                )
              };
            }
            // If it is not promo code error, return the error message.
            return {
              ...updatedState,
              promotionMessage: e.messages[0]["debug-message"]
            };
          }

          // If there is no EP error, return generic error message.
          return {
            ...updatedState,
            promotionMessage: intl.get("custom-error-apply-promotion")
          };
        });
      });
  }

  deletePromotionCode(promotionCode) {
    const {
      data: { cartOrderDetailsForm },
      onSubmittedPromotion,
      auth: { logout },
      history
    } = this.props;
    const {
      cart: { getCartDetails }
    } = this.context;

    const couponFormLink = cartOrderDetailsForm.links[0].uri;

    this.setState({
      isDeletingPromotion: true
    });

    cortexFetch(couponFormLink, {
      method: "post",
      body: JSON.stringify({
        "daikin-promo-codes": promotionCode,
        "update-mode": "PROMO_CODES_REMOVE"
      })
    })
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(() => {
        this.setState({
          promotionMessage: ""
        });
        return getCartDetails();
      })
      .then(() => onSubmittedPromotion())
      .then(() => {
        this.setState({
          isDeletingPromotion: false
        });
      })
      .catch(e => {
        this.setState({
          isDeletingPromotion: false
        });
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => deletePromotionCode => AddPromotionContainer.tsx"
            })
          );
        }
        this.setState({
          promotionCode: "",
          promotionMessage: intl.get("custom-error-delete-promotion")
        });
      });
  }

  clearPromotionValidationMessage() {
    this.setState({ promotionValidationMessage: "" });
  }

  render() {
    const {
      isPromotionFormOpen,
      promotionCode,
      loader,
      promotionMessage,
      appliedPromoCodes,
      promotionValidationMessage,
      isDeletingPromotion
    } = this.state;

    const {
      hideAddPromotionButton = false,
      hideDeletePromotionButton = false,
      isDeletingPromoCode
    } = this.props;

    if (isPromotionFormOpen) {
      return (
        <div className="add-promotion-container">
          <form className="form-horizontal" onSubmit={this.submitPromotionCode}>
            <div>
              <div className="form-group">
                <Input
                  placeholder={intl.get("add-promotion-title")}
                  label=""
                  ariaLabel={intl.get("add-promotion-title")}
                  inputHandler={this.setPromotionCode}
                  inputName="PromotionCode"
                  type="text"
                  value={promotionCode}
                />
              </div>
            </div>
            <div className="form-group">
              {!loader ? (
                <button
                  id="apply_promotion"
                  aria-label={intl.get("apply-promotion")}
                  className="ep-btn primary wide"
                  type="submit"
                >
                  {intl.get("apply-promotion")}
                </button>
              ) : (
                <div className="miniLoader" />
              )}
              <button
                id="cancel_add_promotion"
                aria-label={intl.get("cancel")}
                className="ep-btn wide"
                type="button"
                onClick={() => {
                  this.closePromotionForm();
                }}
              >
                {intl.get("cancel")}
              </button>
            </div>
          </form>
        </div>
      );
    }
    return (
      <>
        {!hideAddPromotionButton && (
          <div className="add-promotion-container">
            <button
              className="open-promotion-btn ep-btn wide"
              type="button"
              id="open_promotion_form_button"
              aria-label={intl.get("add-promotion")}
              onClick={() => {
                this.clearPromotionValidationMessage();
                this.openPromotionForm();
              }}
            >
              {intl.get("add-promotion")}
            </button>
            <p className="promotion-message">{promotionMessage}</p>
          </div>
        )}
        {promotionValidationMessage && (
          <div className="promotion-validation-message">
            <MessageContainer
              message={{
                type: "error",
                debugMessages: promotionValidationMessage
              }}
              closeContainerHandler={() =>
                this.clearPromotionValidationMessage()
              }
            />
          </div>
        )}
        <div
          className={`applied-promo-codes-container${
            hideDeletePromotionButton ? " with-padding" : ""
          }`}
        >
          <p className="applied-promo-codes-title">
            {`${intl.get("applied-promotion-codes")}${
              hideDeletePromotionButton ? ":" : ""
            }`}
          </p>
          <div className="applied-promo-codes">
            {appliedPromoCodes &&
              appliedPromoCodes.map(
                (promoCode: string, index: number, self: string[]) => {
                  if (hideDeletePromotionButton) {
                    return (
                      <span className="promo-code-name" key={promoCode}>
                        {` ${promoCode}${
                          index !== self.length - 1 ? ", " : ""
                        } `}
                      </span>
                    );
                  }
                  return !isDeletingPromoCode && !isDeletingPromotion ? (
                    <div className="promo-code" id={promoCode} key={promoCode}>
                      <p className="promo-code-name">{promoCode}</p>
                      <button
                        className="promo-code-remove-btn"
                        type="button"
                        onClick={() => this.deletePromotionCode(promoCode)}
                      >
                        <i className="icon-close-x" />
                      </button>
                    </div>
                  ) : (
                    <div className="miniLoader" key={promoCode} />
                  );
                }
              )}
          </div>
        </div>
      </>
    );
  }
}
export default AddPromotionContainer;
