import React, { FC, useState, useContext, useEffect } from "react";
import intl from "react-intl-universal";
import {
  checkResponse,
  generateSpecificErrorMessage,
  checkTokensExpired,
  pushToMaintenace
} from "../../../app/src/utils/helpers";
import { cortexFetch } from "../utils/Cortex";
import { getConfig } from "../utils/ConfigProvider";
import ContractItem, { ContractItemDetails } from "./ContractItem";
import { ContractOrder } from "../../../app/src/containers/ContractOrdersPage";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { checkEntitlementSku } from "../../../app/src/services/connectServices";
import { addToCart } from "../../../app/src/services/EpServices";
import MessageContainer from "../MessageContainer/messagecontainer";

import "./ContractItemsTable.less";

interface ContractItemsTableProps {
  contract: ContractOrder;
  release: boolean;
  history: any;
}

interface ItemToRelease {
  code: string;
  quantity: number;
  "contract-control-line-number": string;
}

const ContractItemsTable: FC<ContractItemsTableProps> = ({
  contract,
  release,
  history
}) => {
  const [editable, setEditable] = useState<boolean>(release);
  const [itemsToRelease, setItemsToRelease] = useState<ItemToRelease[]>([]);
  const [releaseLoading, setReleaseLoading] = useState<boolean>(false);
  const [releaseAllLoading, setReleaseAllLoading] = useState<boolean>(false);
  const [itemsWithDetails, setItemsWithDetails] = useState<
    Array<ContractItemDetails>
  >(null);
  const [error, setError] = useState<string>("");

  const context = useContext<{
    cart: any;
    auth: any;
    job: any;
    branches: any;
    user: any;
  }>(MainContext);

  const { config } = getConfig();

  const { details: items }: { details: Array<ContractItemDetails> } = contract;

  useEffect(() => {
    fetchItemsDetails();
  }, []);

  useEffect(() => {
    if (releaseAllLoading) {
      releaseItems();
    }
  }, [itemsToRelease]);

  const fetchItemsDetails = () => {
    const codes = items.map(item => item.productNo);
    let details: Array<ContractItemDetails>;
    const {
      auth: { logout }
    } = context;
    /**
     * 1. Call EP to get item description
     * 2. Call enetitlement service
     */
    cortexFetch(
      `/items/${config.cortexApi.scope}/lookups/batches/form/?zoom=element,element:definition,element:code&followlocation=true`,
      {
        method: "post",
        body: JSON.stringify({ codes })
      }
    )
      .then(res => checkResponse(res))
      .then(res => {
        details = items.map(item => {
          const itemDetails = res._element.find(
            el => el._code[0].code === item.productNo
          );
          return {
            ...item,
            description: itemDetails
              ? itemDetails._definition[0]["display-name"]
              : ""
          };
        });
        const skus = items.map(item => item.productNo).join("|");
        return checkEntitlementSku(contract.custNo, skus);
      })
      .then(res => {
        details = details.map(itemDetails => {
          const { data } = res;
          const entitledItem = data.find(
            el => el.sku === itemDetails.productNo
          );
          return {
            ...itemDetails,
            entitled: entitledItem ? entitledItem.entitled : false
          };
        });
        setItemsWithDetails(details);
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => fetchItemsDetails => ContractItemsTable.tsx"
            })
          );
        }
        setItemsWithDetails(items);
      });
  };

  const onReleaseButtonClick = () => {
    if (editable && itemsToRelease.length) {
      setReleaseLoading(true);
      releaseItems();
    } else {
      setEditable(!editable);
      setItemsWithDetails(
        itemsWithDetails.map(item => ({ ...item, exeededQty: false }))
      );
    }
  };

  const onReleaseAllButtonClick = () => {
    const allItems: Array<ItemToRelease> = itemsWithDetails
      .filter(({ orderQty, releasedQty }) => orderQty > releasedQty)
      .filter(({ entitled }) => entitled)
      .map(({ productNo, orderQty, releasedQty, lineCtlNo }) => ({
        code: productNo,
        quantity: Number(orderQty) - Number(releasedQty),
        "contract-control-line-number": lineCtlNo
      }));
    setItemsToRelease(allItems);
    setReleaseAllLoading(true);
  };

  const handleQuantityEntered = (
    productNumber: string,
    quantity: string,
    lineCtlNo: string
  ) => {
    const item: ItemToRelease = {
      code: productNumber,
      quantity: Number(quantity),
      "contract-control-line-number": lineCtlNo
    };
    let updatedItems = [...itemsToRelease];
    const existingItemIndex = updatedItems.findIndex(
      ({ code }) => code === productNumber
    );
    setItemsWithDetails(
      itemsWithDetails.map(itemDetails => ({
        ...itemDetails,
        exeededQty: false
      }))
    );

    if (existingItemIndex >= 0) {
      if (!quantity) {
        updatedItems = updatedItems.filter(
          updatedItem => updatedItem.code !== item.code
        );
      } else {
        updatedItems[existingItemIndex] = item;
      }
    } else {
      updatedItems.push(item);
    }

    setError("");
    setItemsToRelease(updatedItems);
  };

  const resetTable = () => {
    setReleaseLoading(false);
    setReleaseAllLoading(false);
    setEditable(false);
    setItemsToRelease([]);
    setItemsWithDetails(
      itemsWithDetails.map(item => ({ ...item, exeededQty: false }))
    );
  };

  const validateItems = () => {
    const {
      cart: {
        cartDetails: {
          defaultCart: { items: cartItems }
        }
      }
    } = context;

    const itemsWithWarning = itemsWithDetails.map(itemDetails => {
      const itemToRelease = itemsToRelease.find(
        item => item.code === itemDetails.productNo
      );
      const cartItem =
        cartItems &&
        cartItems.find(
          item => item._item[0]._code[0].code === itemDetails.productNo
        );
      const cartQuantity = cartItem ? cartItem.quantity : 0;
      const quantityEntered = itemToRelease ? itemToRelease.quantity : 0;
      const hasError =
        Number(itemDetails.releasedQty) >= Number(itemDetails.orderQty);

      if (
        !hasError &&
        quantityEntered + cartQuantity + Number(itemDetails.releasedQty) >
          Number(itemDetails.orderQty)
      ) {
        return {
          ...itemDetails,
          exeededQty: true
        };
      }
      return { ...itemDetails, exeededQty: false };
    });

    setItemsWithDetails(itemsWithWarning);
    return itemsWithWarning.every(item => !item.exeededQty);
  };

  const validateBranch = (branchNumber, pricing) => {
    const {
      branches: { findBranch }
    } = context;
    const branchDetails = findBranch(branchNumber);

    if (branchDetails) {
      // Branch is in the list of entitled branches - pass branch to EP
      return {
        "branch-number": branchNumber,
        "branch-vendor": branchDetails.distirbutor
      };
    }

    // Branch is not in the list of entitled branches
    if (pricing === "Y") {
      // Contract is pricing only - it's not dependent on the branch so we don't have to pass branch to EP
      return {
        "branch-number": "",
        "branch-vendor": ""
      };
    }
    // Contract is not pricing only - it's dependent on the branch which is not entitled so we'll block add to cart
    return null;
  };

  const validateJob = (jobNumber, jobName) => {
    const {
      user: {
        userProfile: { jobsArray }
      }
    } = context;

    // Account does not have jobs - don't pass job params to EP
    if (jobsArray && !jobsArray.length) {
      return {};
    }

    const job = jobsArray.find(
      j =>
        j.jobNumber === jobNumber &&
        j.jobName.toUpperCase() === jobName.toUpperCase()
    );

    if (job) {
      // Job is in the list of user's jobs - pass job to EP
      return {
        "job-number": jobNumber,
        "job-name": jobName
      };
    }

    // Job is not in the list of user's jobs - block add to cart
    return null;
  };

  const releaseItems = () => {
    const {
      cart: {
        setSuccesCartPopupMessage,
        setErrorCartPopupMessage,
        getCartDetails,
        cartDetails: {
          defaultCart: { addItemsToCart }
        }
      }
    } = context;

    const { orderNo, branchNo, jobNo, jobName, pricing, poNo } = contract;

    const branchParams = validateBranch(branchNo, pricing);
    if (!branchParams) {
      setError(intl.get("contract-error-missing-branch"));
      setReleaseLoading(false);
      return;
    }

    const jobParams = validateJob(jobNo, jobName);
    if (!jobParams) {
      setError(intl.get("contract-error-missing-job"));
      setReleaseLoading(false);
      return;
    }

    if (pricing === "N" && !validateItems()) {
      setError(intl.get("exeeded-quantity-error"));
      setReleaseLoading(false);
      return;
    }

    setError("");
    const totalQuantity = itemsToRelease.reduce(
      (acc, current) => acc + current.quantity,
      0
    );
    const body = {
      ...branchParams,
      "job-number": jobNo,
      "job-name": jobName,
      "contract-number": orderNo,
      pricing,
      "po-number": poNo,
      items: itemsToRelease
    };

    addToCart(addItemsToCart.self.uri, body)
      .then(() => getCartDetails())
      .then(() => setSuccesCartPopupMessage(totalQuantity))
      .then(() => resetTable())
      .catch(err => {
        setErrorCartPopupMessage(generateSpecificErrorMessage(err));
        resetTable();
      });
  };

  const renderReleaseButtons = () => {
    return (
      <div className="release-buttons">
        {!releaseLoading ? (
          <button
            type="button"
            className="ep-btn primary release-btn"
            onClick={onReleaseButtonClick}
          >
            {intl.get("release")}
          </button>
        ) : (
          <div className="miniLoader" />
        )}
      </div>
    );
  };

  const renderContractItemsTable = () => {
    return (
      <table>
        <thead className="table-labels">
          <tr>
            <th className="label">{intl.get("quantity-abbr")}</th>
            <th className="label">{intl.get("product")}#</th>
            <th className="label">{intl.get("description")}</th>
            <th className="label">{intl.get("price")}</th>
            <th className="label">{editable && intl.get("qty-ordered")}</th>
            <th className="label">{intl.get("qty-released")}</th>
          </tr>
        </thead>
        <tbody>
          {itemsWithDetails ? (
            itemsWithDetails.map(item => (
              <ContractItem
                key={item.productNo}
                item={item}
                editable={editable}
                handleQuantityEntered={handleQuantityEntered}
                resetQty={!itemsToRelease.length}
                contract={contract}
              />
            ))
          ) : (
            <tr>
              <td colSpan={6}>
                <div className="miniLoader" />{" "}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <div className="contract-items-table content-table content-box">
      <h4 className="bullet">{intl.get("items")}</h4>
      {renderReleaseButtons()}
      {error && (
        <MessageContainer
          message={{
            debugMessages: error,
            type: "error"
          }}
          closeContainerHandler={() => setError("")}
        />
      )}
      {renderContractItemsTable()}
    </div>
  );
};

export default ContractItemsTable;
