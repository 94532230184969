import React, { FC, useState } from "react";
import intl from "react-intl-universal";

import BranchDropdownComponent from "../BranchDropdownComponent/BranchDropdownComponent";

import "./search.results.branch.selection.menu.less";

interface SearchResultsBranchSelectionMenuProps {
  selectedBranch: any;
}

const SearchResultsBranchSelectionMenu: FC<
  SearchResultsBranchSelectionMenuProps
> = ({ selectedBranch }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [classNameSuffix, setClassNameSufix] = useState<string>("");

  const toggleMenu = (): void => {
    if (window.innerWidth <= 768) {
      setIsOpened(!isOpened);
      if (isOpened) setClassNameSufix("");
      if (!isOpened) setClassNameSufix("opened");
    }
  };

  return (
    <div className={`search-results-branch-selection ${classNameSuffix}`}>
      <div
        className="search-results-menu-title ep-btn secondary"
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
        role="button"
        aria-hidden="true"
      >
        <h4>{intl.get("select-branch")}</h4>
      </div>
      <div
        className={`branch-selection-container content-box ${classNameSuffix}`}
      >
        {selectedBranch ? (
          <>
            <div className="branch-selection-title">
              <h4 className="bullet">
                {`${intl.get("viewing-inventory-for")}:`}
                <i
                  className="icon-times"
                  onClick={toggleMenu}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}
                />
              </h4>
              <span>{selectedBranch.branchName}</span>
            </div>
            <div className="branch-selecion-body">
              <span>{`${intl.get("select-another-branch")}:`}</span>
              <BranchDropdownComponent
                selectedBranch={selectedBranch.branchNumber}
                displayName
              />
            </div>
          </>
        ) : (
          <div className="loader" />
        )}
      </div>
    </div>
  );
};

export default SearchResultsBranchSelectionMenu;
