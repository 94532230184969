import React from "react";
import { Link } from "react-router-dom";
import jsonpointer from "jsonpointer";
import { getNestedObject, getImageUrl } from "bloomreach-experience-react-sdk";

import RichText from "../RichText/rich-text";
import { isLinkExternal } from "../../../../../app/src/utils/helpers";

import "./cta.less";

const CTA = ({ configuration, pageModel, preview }) => {
  const ref = getNestedObject(configuration, ["models", "document", "$ref"]);
  const content = ref && jsonpointer.get(pageModel, ref);

  if (!content) return null;

  const { buttonText, link, image } = content;

  const [isExternal, url] = isLinkExternal(link);

  return (
    <Link
      to={{ pathname: url }}
      target={isExternal ? "_blank" : "_self"}
      rel={isExternal ? "noopener noreferrer" : ""}
      className="cta container"
    >
      <div className="image-container">
        <img src={getImageUrl(image, pageModel, preview)} alt="Non Available" />
      </div>
      <div className="content-container">
        <RichText
          configuration={configuration}
          pageModel={pageModel}
          preview={preview}
        />
      </div>
      <div className="button-container">
        <button className="ep-btn primary" type="button">
          {buttonText}
        </button>
      </div>
    </Link>
  );
};

export default CTA;
