import React, { useContext } from "react";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";

import { MainContext } from "../../../app/src/contexts/MainContext";

import "./search.no.results.less";

const SearchNoResults = () => {
  const context = useContext<{ navigation: any }>(MainContext);
  const {
    navigation: { navigation }
  } = context;

  /** We are rendering links for all subcategories of Equpment cateogry and link for Parts and Accessories category  */
  return (
    <div className="search-no-results">
      <h2 className="no-search-result-title" id="no_search_result">
        {intl.get("no-search-results")}
      </h2>
      <div className="suggestions">
        <div className="search-tips">
          <h2 className="search-tips-title">{intl.get("search-tips")}</h2>
          <ul className="search-tips-list">
            <li>{intl.get("search-tip-1")}</li>
            <li>{intl.get("search-tip-2")}</li>
            <li>{intl.get("search-tip-3")}</li>
          </ul>
        </div>
        <div className="category-suggestions">
          <h2 className="category-suggestions-title">
            {intl.get("browse-by-category")}
          </h2>
          {navigation && (
            <ul className="category-suggestions-list">
              {Object.entries(navigation).map((category: [string, any]) =>
                category[0] === "Equipment" ? (
                  Object.entries(category[1]).map(
                    (subCategory: [string, any]) =>
                      subCategory[0] !== "name" &&
                      subCategory[0] !== "show" && (
                        <li key={subCategory[0]}>
                          <Link
                            to={{
                              pathname: `/category/${subCategory[1].name}`,
                              state: { name: subCategory[0] }
                            }}
                          >
                            {subCategory[0]}
                          </Link>
                        </li>
                      )
                  )
                ) : (
                  <li key={category[0]}>
                    <Link
                      to={{
                        pathname: `/search/${category[0]}`,
                        state: {
                          searchType: "keyword",
                          searchTerm: category[0],
                          name: category[0]
                        }
                      }}
                    >
                      {category[0]}
                    </Link>
                  </li>
                )
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchNoResults;
