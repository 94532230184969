import React, { FC } from "react";
import {
  CmsEditButton,
  getNestedObject
} from "bloomreach-experience-react-sdk";
import BRlink from "../BRlink/brlink";

interface HeaderUtilityMenuProps {
  configuration: any;
  preview: any;
}

const HeaderUtilityMenu: FC<HeaderUtilityMenuProps> = ({
  configuration,
  preview
}) => {
  const renderMenu = () => {
    return configuration.models.menu.siteMenuItems.map(menuItem => (
      <li key={menuItem.name}>
        <BRlink configuration={menuItem} key={menuItem.name} />
      </li>
    ));
  };

  if (!getNestedObject(configuration, ["models", "menu", "siteMenuItems", 0])) {
    return null;
  }

  const menuConfiguration = getNestedObject(configuration, ["models", "menu"]);
  const editButton = preview ? (
    <CmsEditButton configuration={menuConfiguration} preview={preview} />
  ) : null;

  return (
    <ul>
      {editButton && editButton}
      {renderMenu()}
    </ul>
  );
};

export default HeaderUtilityMenu;
