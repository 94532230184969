import React, { FC } from "react";

import "./bread.crumbs.component.less";
import { NavLink } from "react-router-dom";

interface urlParamInterface {
  param: string;
  name: string;
  state?: boolean;
  start?: number;
  sku?: string;
  facets?: any;
  itemsLoaded?: number;
  sortByAvailability?: boolean;
}

interface Props {
  breadCrumbsMap: urlParamInterface[];
}

const BreadCrumbs: FC<Props> = ({ breadCrumbsMap }) => {
  return <ul className="bread-crumbs">{createCrumbsList(breadCrumbsMap)}</ul>;
};

function createCrumbsList(breadCrumbsMap: urlParamInterface[]) {
  return breadCrumbsMap.map((urlParam: urlParamInterface, index: number) => {
    const key = urlParam.name + index;
    const {
      param,
      name,
      state,
      start,
      sku,
      facets,
      itemsLoaded,
      sortByAvailability
    } = urlParam;
    const breadCrumbUrl = param !== "home" ? `/${param}` : "";
    let breadCrumb: JSX.Element;

    if (param === "nocrumb") {
      breadCrumb = <li key={key}>{name}</li>;
    } else {
      breadCrumb = (
        <li key={key}>
          {state ? (
            <NavLink
              to={{
                pathname: `${encodeURI(breadCrumbUrl)}`,
                state: {
                  name,
                  start,
                  sku,
                  facets,
                  itemsLoaded,
                  sortByAvailability,
                  prevUrl: window.location.href
                }
              }}
              key={key}
            >
              {name ? name.replace(/%25/g, "%") : ""}
            </NavLink>
          ) : (
            <NavLink to={`${encodeURI(breadCrumbUrl)}`} key={key}>
              {name ? name.replace(/%25/g, "%") : ""}
            </NavLink>
          )}
        </li>
      );
    }

    return breadCrumb;
  });
}

export default BreadCrumbs;
