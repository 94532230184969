import React, { FC } from "react";
import intl from "react-intl-universal";
import { ContractOrder } from "../../../app/src/containers/ContractOrdersPage";

import "./ContractOrderTable.less";

const tableColumns = {
  orderNo: "contract-number",
  description: "description",
  orderPlaced: "ordered-date",
  expiration: "expiry-date",
  poNo: "p-o-number",
  jobNo: "job-number",
  jobName: "job-name"
};

interface ContractOrderTableProps {
  contracts: Array<ContractOrder>;
  loading: boolean;
  history: any;
}

const ContractOrderTable: FC<ContractOrderTableProps> = ({
  contracts,
  loading,
  history
}) => (
  <div className="contract-orders-table">
    <table>
      <thead className="table-labels">
        <tr>
          {Object.values(tableColumns).map((value: string) => (
            <th className="label" key={value}>
              {intl.get(value)}
            </th>
          ))}
          <th className="label">{intl.get("action")}</th>
        </tr>
      </thead>
      <tbody>
        {!loading && contracts ? (
          contracts.map(contract => (
            <tr
              className="table-item"
              key={contract.orderNo}
              onClick={() =>
                history.push(`/myAccount/contractDetails/${contract.orderNo}`)
              }
            >
              {Object.keys(tableColumns).map((key: string) => (
                <td key={`${key}_${contract[key]}`}>
                  <span className="table-item-label">
                    {`${intl.get(tableColumns[key])}: `}
                  </span>
                  {contract[key]}
                </td>
              ))}
              <td
                className="action"
                onClick={e => {
                  e.stopPropagation();
                  history.push({
                    pathname: `/myAccount/contractDetails/${contract.orderNo}`,
                    state: { release: true }
                  });
                }}
                role="presentation"
              >
                <span className="table-item-label">
                  {`${intl.get("action")}: `}
                </span>
                {intl.get("release")}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={8}>
              <div className="miniLoader" />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

export default ContractOrderTable;
