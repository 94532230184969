import React from "react";
import jsonpointer from "jsonpointer";
import {
  parseAndRewriteLinks,
  getNestedObject,
  CmsEditButton
} from "bloomreach-experience-react-sdk";
import useBanners from "../../../CustomHooks/useBanners";

import "./enhanced-banner.less";

const EnhancedBanner = props => {
  const { configuration, pageModel, preview } = props;
  const ref = getNestedObject(configuration, ["models", "document", "$ref"]);
  const params = getNestedObject(configuration, ["_meta", "params"]);
  let content;
  if (ref) content = jsonpointer.get(pageModel, ref);

  const [banner] = useBanners([content]);

  let contentHtml;
  if (banner && banner.content.value) {
    contentHtml = parseAndRewriteLinks(banner.content.value, preview);
  }

  const style = {
    backgroundColor: params.backgroundColor
      ? params.backgroundColor
      : "transparent",
    color: params.fontColor ? params.fontColor : null
  };

  const renderDisplayMode = () => {
    if (!params.displayMode || params.displayMode === "") {
      return (
        <div className="jumbotron">
          <p>Default Display Mode</p>
        </div>
      );
    }

    switch (params.displayMode) {
      case "text_only":
        return (
          banner && (
            <div className="jumbotron text d-flex flex-lg-row flex-column">
              <div className="title-container col-12 col-lg-4 p-0">
                <h1>{banner.title}</h1>
              </div>
              <div className="content-container col-12 col-lg-8 p-0">
                {contentHtml}
              </div>
            </div>
          )
        );
      case "full":
        return (
          banner && (
            <div className="jumbotron full">
              <div className="title-container col-12">
                <h2>{banner.title}</h2>
              </div>
              <div className="content-container col-12">{contentHtml}</div>
              <div className="link-container col-12">
                <a
                  aria-label={banner.linktitle}
                  className="ep-btn primary"
                  href={banner.url}
                >
                  {banner.linktitle}
                </a>
              </div>
            </div>
          )
        );
      default:
        return (
          <div className="jumbotron">
            <p>Unrecognized Display Mode</p>
          </div>
        );
    }
  };

  return (
    <div className="enhanced-banner-container container-fluid" style={style}>
      <CmsEditButton configuration={banner} preview={preview} />
      <div className="container">{renderDisplayMode()}</div>
    </div>
  );
};

export default EnhancedBanner;
