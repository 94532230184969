import React, { useState, FC } from "react";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import intl from "react-intl-universal";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { useMainContext } from "../../../app/src/contexts/MainContext";
import UserRoles from "../../../app/src/utils/mappings/userRoles";

import "./account.menu.component.less";

const AccountMenuComponent: FC<RouteComponentProps> = ({ location }) => {
  const [open, setOpen] = useState<boolean>(false);
  const context = useMainContext();
  const [classNameSuffix, setClassNameSuffix] = useState<string>("");

  const toggleMenu = (): void => {
    if (window.innerWidth <= 768) {
      setOpen(!open);
      if (open) setClassNameSuffix("");
      if (!open) setClassNameSuffix(" account-menu-open");
    }
  };

  const {
    account: {
      accountDetails: { isBuyerAdmin }
    },
    user: {
      userProfile: { onlineBillPayLink, roles, subUserRoles }
    },
    contract: { contractsList }
  } = context;

  const { config }: { config: IEpConfig } = getConfig();
  const {
    statementPageDisplay,
    creditInformationPageDisplay,
    showManageUsers,
    showOnlineBillPay,
    invoicePageDisplay,
    contractOrderPageDisplay,
    orderHistoryCSVExport
  } = config;

  const viewCreditRole =
    subUserRoles && subUserRoles.length
      ? subUserRoles.includes(UserRoles().viewCredit)
      : roles.includes(UserRoles().viewCredit);
  const viewStatementsRole =
    subUserRoles && subUserRoles.length
      ? subUserRoles.includes(UserRoles().viewStatements)
      : roles.includes(UserRoles().viewStatements);

  return (
    <div className="account-menu content-box">
      <div
        className="account-menu-title"
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
        role="presentation"
      >
        <h1>{`${intl.get("my-account")}`}</h1>
        <i
          title="Account Menu"
          aria-label="Open Account Menu"
          className={open ? "icon-minus" : "icon-plus"}
        />
      </div>
      <div className={`account-menu-content${classNameSuffix}`}>
        <div className="content-segment">
          <ul>
            <li>
              <NavLink
                to="/myAccount/profile"
                activeClassName="active-side-menu"
                onClick={toggleMenu}
              >
                {`${intl.get("account-settings")}`}
              </NavLink>
            </li>

            {creditInformationPageDisplay && viewCreditRole && (
              <li>
                <NavLink
                  to="/myAccount/creditInformation"
                  activeClassName="active-side-menu"
                  onClick={toggleMenu}
                >
                  {`${intl.get("credit-information")}`}
                </NavLink>
              </li>
            )}
            {statementPageDisplay && viewStatementsRole && (
              <li>
                <NavLink
                  to="/myAccount/statement"
                  activeClassName="active-side-menu"
                  onClick={toggleMenu}
                >
                  {`${intl.get("statement")}`}
                </NavLink>
              </li>
            )}
            {invoicePageDisplay && (
              <li>
                <NavLink
                  to="/myAccount/invoiceQuery"
                  activeClassName="active-side-menu"
                  onClick={toggleMenu}
                  className={
                    location.pathname.startsWith("/myAccount/invoiceDetails")
                      ? "active-side-menu"
                      : ""
                  }
                >
                  {`${intl.get("invoice-inquiry")}`}
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                to="/myAccount/branchSettings"
                activeClassName="active-side-menu"
                onClick={toggleMenu}
              >
                {`${intl.get("branch-settings")}`}
              </NavLink>
            </li>
            {showManageUsers && isBuyerAdmin && (
              <li>
                <NavLink
                  to="/myAccount/manageUsers"
                  activeClassName="active-side-menu"
                  onClick={toggleMenu}
                >
                  {`${intl.get("manage-users")}`}
                </NavLink>
              </li>
            )}
            {showOnlineBillPay && (
              <li>
                <a
                  href={onlineBillPayLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.get("online-billing-website")}
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="content-segment">
          <div className="account-menu-title">
            <h1 className="my-orders-title">{`${intl.get("my-orders")}`}</h1>
          </div>
          <ul>
            <li>
              <NavLink
                to="/myAccount/savedOrders"
                activeClassName="active-side-menu"
                onClick={toggleMenu}
              >
                {`${intl.get("saved-orders")}`}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/myAccount/orderHistory"
                activeClassName="active-side-menu"
                onClick={toggleMenu}
                className={
                  location.pathname.startsWith("/myAccount/orderDetails")
                    ? "active-side-menu"
                    : ""
                }
              >
                {intl.get("order-history")}
                {orderHistoryCSVExport && isBuyerAdmin && (
                  <span>{intl.get("new-download-feature")}</span>
                )}
              </NavLink>
            </li>
            {contractOrderPageDisplay &&
            contractsList &&
            contractsList.length ? (
              <li>
                <NavLink
                  to="/myAccount/contractOrders"
                  activeClassName="active-side-menu"
                  onClick={toggleMenu}
                  className={
                    location.pathname.startsWith("/myAccount/contractDetails")
                      ? "active-side-menu"
                      : ""
                  }
                >
                  {`${intl.get("contract-orders")}`}
                </NavLink>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AccountMenuComponent);
