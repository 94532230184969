import React from "react";
import {
  createLink,
  getImageUrl,
  parseAndRewriteLinks
} from "bloomreach-experience-react-sdk";

import "./banner.less";

const Banner = props => {
  const { content, manageContentButton, pageModel, preview } = props;
  const image = getImageUrl(content.image, pageModel, preview);

  let contentHtml;
  if (content.content && content.content.value) {
    contentHtml = parseAndRewriteLinks(content.content.value, preview);
  }

  const link = content.link ? content.link.$ref : null;
  // createLink takes linkText as a function so that it can contain HTML elements
  const linkText = () => (content.linkTitle ? content.linkTitle : "Read More");
  const className = "ep-btn secondary";

  return (
    <div className="container-fluid p-0">
      <div className="jumbotron has-edit-button">
        {manageContentButton}
        <div className="row">
          <div className="col-xl-6 col-12 p-0">
            <div className="banner-text">
              {content.title && <h1>{content.title}</h1>}
              <div className="banner-content">{contentHtml}</div>
              <div className="banner-link">
                {link && createLink("ref", link, linkText, className)}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-12 p-0">
            {image && (
              <figure>
                <img src={image} alt={content.title} />
              </figure>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
