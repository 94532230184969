import { useState } from "react";
import { priceCatalog } from "../services/connectServices";
import { formatNumberValue } from "../utils/helpers";

// eslint-disable-next-line import/prefer-default-export
export const OrderContext = () => {
  const [orderData, setOrderData] = useState(null);
  const [purchaseData, setPurchaseData] = useState(null);
  const [branch, setBranch] = useState(null);
  const [job, setJob] = useState(null);
  const [poNumber, setPoNumber] = useState(null);
  const [promoCodes, setPromoCodes] = useState(null);
  const [backOrderedQuantity, setBackOrderedQuantity] = useState([]);
  const [contractNumber, setContractNumber] = useState("");

  const fetchAvailableQuantity = async (
    customerNumber,
    branchNumber,
    skus,
    jobNumber
  ) => {
    const body = { customerNumber, branchNumber, skus, jobNumber };

    const { data } = await priceCatalog(body);

    return data ? data.items : [];
  };

  /**
   * @description this fucntion will invoke DCS pricing service, which returns informaiton about real time availability
   * Depending on the item's quantity that is ordered and quantity availabiale from the service response,
   * we will calculate and determinate which items are backordered
   */
  const checkAvailability = async (
    customerNumber,
    branchNumber,
    items,
    jobNumber
  ) => {
    const skus = items.map(item => item._item[0]._code[0].code);

    const availability = await fetchAvailableQuantity(
      customerNumber,
      branchNumber,
      skus,
      jobNumber
    );

    const result = items.map(item => {
      const { sku, quantityAvailable } = availability.length
        ? availability.find(
            details => details.sku === item._item[0]._code[0].code
          )
        : {};
      return {
        sku,
        quantity:
          item.quantity >= formatNumberValue(quantityAvailable)
            ? item.quantity - formatNumberValue(quantityAvailable)
            : 0
      };
    });
    setBackOrderedQuantity(result);
  };

  return {
    orderData,
    setOrderData,
    purchaseData,
    setPurchaseData,
    branch,
    setBranch,
    job,
    setJob,
    poNumber,
    setPoNumber,
    promoCodes,
    setPromoCodes,
    backOrderedQuantity,
    setBackOrderedQuantity,
    contractNumber,
    setContractNumber,
    checkAvailability
  };
};
