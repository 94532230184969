import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import intl from "react-intl-universal";

import { extractPhoneAndFax } from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig } from "../utils/ConfigProvider";
import { useMainContext } from "../../../app/src/contexts/MainContext";
import { BranchDetails } from "../DefaultBranchInfoCard/DefaultBranchInfoCard";

import "./branch.map.component.less";
// TODO
/*
    - set token - PLACEHOLDER_TOKEN
    - set google map api key- PLACEHOLDER_GOOGLE_MAP_API_KEY
    - current console warning is due to google map react library, there is a ticket in for this to be 
    resolved by google-map-react team it will NOT affect the application
    - handle setDefaultBranch function and set up set currentBranch
*/

function Marker({
  place,
  show,
  setCurrentBranch,
  setHomeBranch,
  currentBranch,
  defaultBranch,
  setDisplayMarker,
  lat,
  lng
}) {
  const [markerHover, setMarkerHover] = useState<boolean>();
  const [currentBranchLoader, setCurrentBranchLoader] = useState<boolean>(
    false
  );
  const [homeBranchLoader, setHomeBranchLoader] = useState<boolean>(false);

  const {
    user: { userProfile },
    cart: {
      cartDetails: {
        defaultCart: { items, cartOrderDetailsForm }
      }
    }
  } = useMainContext();
  const isCartAssociatedToContract =
    items &&
    items.length &&
    cartOrderDetailsForm["contract-number"] &&
    cartOrderDetailsForm.pricing.toUpperCase() === "N";

  const { config } = getConfig();

  const closeInfoWindow = event => {
    event.stopPropagation();
    setDisplayMarker(null);
  };

  const handleCurrentBranchChange = event => {
    event.stopPropagation();
    setCurrentBranchLoader(true);
    setCurrentBranch(place.branchNumber, () => {
      setDisplayMarker(null);
      setCurrentBranchLoader(false);
    });
  };

  const handleHomeBranchChange = event => {
    event.stopPropagation();
    setHomeBranchLoader(true);
    setHomeBranch(place.branchNumber, () => {
      setDisplayMarker(null);
      setHomeBranchLoader(false);
    });
  };

  return (
    <div>
      <div
        className={`marker ${
          // eslint-disable-next-line no-nested-ternary
          place.branchNumber === currentBranch
            ? "current-branch"
            : place.branchNumber === defaultBranch
            ? "home-branch"
            : ""
        } ${markerHover ? "selected" : ""}`}
        onMouseEnter={() => setMarkerHover(true)}
      />

      {/* Below is info window component */}
      {show === place.branchNumber && (
        <div className="info-window">
          <button type="button" onClick={closeInfoWindow} className="close-btn">
            X
          </button>
          <div className="info-window-header">
            <h3 className="info-window-title">
              {intl.get("branch-name")}: {place.branchName}
            </h3>
            <p>
              {intl.get("branch-number")}: {place.branchNumber}
            </p>
          </div>
          <div className="info-window-status">
            {place.branchNumber === defaultBranch && <b>Default branch</b>}

            {place.branchNumber === currentBranch && <b>Current branch</b>}
          </div>
          <div>
            <p>
              {place.address1} <br />
              {place.city}, {place.state}, {place.zip}
            </p>
          </div>
          <div>
            <p>
              {intl.get("call-us-at")}:{" "}
              <a
                className="value show-on-mobile"
                href={`tel:${extractPhoneAndFax(place).phone}`}
              >
                {extractPhoneAndFax(place).phone}
              </a>
              <span className="value hide-on-mobile">
                {extractPhoneAndFax(place).phone}
              </span>
            </p>
          </div>
          <div className="info-window-buttons">
            {!currentBranchLoader ? (
              <button
                className={`set-branch-btn current ${
                  isCartAssociatedToContract ? "disable-change" : ""
                }`}
                type="button"
                onClick={handleCurrentBranchChange}
                disabled={isCartAssociatedToContract}
              >
                {intl.get("set-current-branch")}
              </button>
            ) : (
              <div className="miniLoader" />
            )}
            {config.canUpdateDefaultBranch &&
              !userProfile.subuserEmail &&
              (!homeBranchLoader ? (
                <button
                  className="set-branch-btn home"
                  type="button"
                  onClick={handleHomeBranchChange}
                >
                  {intl.get("set-home-branch")}
                </button>
              ) : (
                <div className="miniLoader" />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

interface BranchMapProps {
  defaultBranch: string;
  currentBranch: string;
  setCurrentBranch: (...args: any[]) => any;
  setHomeBranch: (...args: any[]) => any;
  places: Array<BranchDetails>;
}

const BranchMap: React.FC<BranchMapProps> = props => {
  const {
    defaultBranch,
    currentBranch,
    setCurrentBranch,
    setHomeBranch,
    places
  } = props;
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [displayMarker, setDisplayMarker] = useState<string>(null);
  const [center, setCenter] = useState<Array<number>>([37.42216, -122.08427]);

  // START MAP FUNCTIONS
  // eslint-disable-next-line no-shadow
  const getMapBounds = maps => {
    const bounds = new maps.LatLngBounds();

    places.forEach(place => {
      if (place.latitude && place.longitude) {
        bounds.extend(
          new maps.LatLng(
            place.latitude, // lat
            place.longitude // lng
          )
        );
      }
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  // Fit map to its bounds after the api is loaded
  // eslint-disable-next-line no-shadow
  const apiIsLoaded = (map, maps) => {
    // Get bounds by our places
    const bounds = getMapBounds(maps);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Set map Zoom
    const zoom = map.getZoom();
    map.setZoom(zoom < 5 ? 5 : zoom);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };
  // END MAP FUNCTIONS

  const _onChildClick = (key, childProps) => {
    setCenter([childProps.lat, childProps.lng]);

    if (displayMarker && displayMarker === childProps.place.branchNumber) {
      setDisplayMarker(null);
    } else {
      setDisplayMarker(childProps.place.branchNumber);
    }
  };

  const renderMap = () => (
    // TODO - update height once placed
    <div className="branch-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: getConfig().config.googleMapApiKey }}
        center={center}
        defaultZoom={17}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
        onChildClick={_onChildClick}
      >
        {places.map(place => {
          try {
            return (
              <Marker
                show={displayMarker}
                setCurrentBranch={setCurrentBranch}
                setHomeBranch={setHomeBranch}
                key={place.branchNumber}
                lat={place.latitude}
                lng={place.longitude}
                place={place}
                currentBranch={currentBranch}
                defaultBranch={defaultBranch}
                setDisplayMarker={setDisplayMarker}
              />
            );
          } catch (err) {
            if (place && (!place.latitude || !place.longitude)) {
              console.error(
                `${intl.get("branch")} #${place.branchNumber} ${intl.get(
                  "missing-location-error"
                )}`
              );
            } else {
              console.error(err);
            }
          }
          return null;
        })}
      </GoogleMapReact>
    </div>
  );

  return (
    <>
      {places.length ? renderMap() : null}
      {errorMsg || null}
    </>
  );
};

export default BranchMap;
