import intl from "react-intl-universal";

interface MethodOfShipmentInterface {
  [key: string]: string;
}

const MethodOfShipment = () => {
  const statusList: MethodOfShipmentInterface = {
    P: intl.get("pickup"),
    S: intl.get("third-party-ship"),
    O: intl.get("our-truck"),
    DELIVERY: intl.get("delivery-method"),
    PICK_UP: intl.get("pick-up-method")
  };

  return statusList;
};

export default MethodOfShipment;
