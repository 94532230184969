/**
 * Refactored component from productdisplayitem.main.tsx. This component handles retrieving the data needed
 * for add to cart and the display of the inventory messaging.
 */
import React, { FC, useContext, useState, useEffect } from "react";

import intl from "react-intl-universal";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import {
  getAvailabilityDGA,
  checkEntitlementSku,
  getAvailability
} from "@elasticpath/ref-store/src/services/connectServices";

import { useHistory, NavLink } from "react-router-dom";

import {
  handleCustomException,
  formatInventoryAvailability,
  InventoryAvailabilityInterface,
  convertUnitOfMeasure
} from "@elasticpath/ref-store/src/utils/helpers";

import {
  getConfig,
  IEpConfig
} from "@zilker/store-components/src/utils/ConfigProvider";

import ProductAvailability from "./ProductAvailability";
import AddProductToCartComponent from "../../AddProductToCartComponent/AddProductToCartComponent";
import { CatalogPriceResponse } from "../productdisplayitem.interfaces";
import AppHeaderLoginMain from "../../AppHeaderLogin/appheaderlogin.main";

interface ProductAddToCartSectionProps {
  productId: string;
  productData: any;
  priceData: CatalogPriceResponse;
}

const ProductAddToCartSection: FC<ProductAddToCartSectionProps> = ({
  productId,
  productData,
  priceData
}) => {
  const [inventoryAvailability, setInventoryAvailability] = useState(null);
  const [inventoryError, setInventoryError] = useState<string>("");
  const [skuEntitled, setSkuEntitled] = useState<boolean>(true);
  const [validQuantity, setValidQuantity] = useState<boolean>(true);
  const [itemQty, setItemQty] = useState<number>(1);
  const [branchAvailability, setBranchAvailability] = useState(null);
  const [regionAvailability, setRegionAvailability] = useState(null);
  const [unitOfMeasure, setUnitOfMeasure] = useState<number>(1);

  const history = useHistory();
  const { config }: { config: IEpConfig } = getConfig();

  const DEFAULT_UOM: number = 1.0;

  const context = useContext<{
    auth: any;
    user: any;
    cart: any;
    branches: any;
  }>(MainContext);
  const {
    branches: { branchesList, findBranch },
    user: {
      userProfile: { customerNumber }
    },
    auth: { isLoggedIn },
    cart: {
      cartDetails: { defaultCart }
    }
  } = context;
  const {
    systemBuilderPageDisplay,
    cortexApi: { scope }
  } = config;

  const currentBranchNumber =
    defaultCart && defaultCart.selectedBranch
      ? defaultCart.selectedBranch.code
      : undefined;

  const appHeaderLoginLinks = {
    profile: "/myAccount/profile",
    wishlists: "/wishlists"
  };

  const appModalLoginLinks = {
    registration: "/registration"
  };

  // Refresh if the product Id, branch list is still loading, or current selected branch changes.
  useEffect(() => {
    if (isLoggedIn && branchesList) {
      if (scope !== "motili") {
        handleDGAInventory(currentBranchNumber);
      } else if (scope === "motili" && productId) {
        handleMotiliInventory(productId);
      }
    }
  }, [productId, branchesList, currentBranchNumber]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchEntitlement(productId);
    }
  }, [productId]);

  const formatUnitOfMeasure = items => {
    const UOM = items.find(
      item => item.items && item.items[0] && item.items[0].unitOfMeasure
    );
    if (UOM) {
      return Number(UOM.items[0].unitOfMeasure);
    }
    return DEFAULT_UOM;
  };

  /**
   * ## handleMotiliInventory
   * @param sku string
   *
   * @description Validates if item is available on current branch and in the region.
   */
  const handleMotiliInventory = async (sku: string) => {
    if (defaultCart && branchesList) {
      const { selectedBranch, clientId } = defaultCart;
      const { latitude, longitude } = findBranch(selectedBranch.code);

      let availability: Array<InventoryAvailabilityInterface>;
      getAvailability(
        customerNumber,
        selectedBranch.code,
        sku,
        selectedBranch.vendor,
        latitude,
        longitude,
        true,
        clientId
      )
        .then(({ data }) => {
          availability = formatInventoryAvailability(data);
          setInventoryAvailability(availability);
          const inventoryItem =
            inventoryAvailability && inventoryAvailability.length
              ? inventoryAvailability[0]
              : {
                  branchAvailability: 0,
                  regionAvailability: 0,
                  unitOfMeasure: 1
                };
          const convertedUnitOfMeasure = convertUnitOfMeasure(
            unitOfMeasure,
            itemQty
          );
          setBranchAvailability(inventoryItem.branchAvailability);
          setValidQuantity(convertedUnitOfMeasure === 0);
          setRegionAvailability(inventoryItem.regionAvailability);
        })
        .catch(error => {
          setBranchAvailability(0);
          setRegionAvailability(0);
          handleAvailabilityError(error);
        });
    }
  };

  /**
   * ## validateEntitlement
   * @param sku string
   *
   * @description Validates if the logged in user is entitled for the SKU
   */
  const fetchEntitlement = async (sku: string) => {
    const {
      auth: { logout }
    } = context;
    const encodedSku = encodeURIComponent(sku);

    if (config.entitlementCheck && defaultCart) {
      const { selectedBranch } = defaultCart;
      try {
        const { data } = await checkEntitlementSku(
          customerNumber,
          encodedSku,
          selectedBranch.vendor
        );

        setSkuEntitled(data ? data[0].entitled : !config.entitlementCheck);
      } catch (error) {
        const errorPath =
          "validateEntitlement => checkEntitlementSku => AuthProductDetailsComponent.tsx";
        handleCustomException(error, logout, history, errorPath);
      }
    }
  };

  const handleDGAInventory = branchToCheck => {
    const needDetails = true;
    const limit = 3;
    const skip = 0;
    getAvailabilityDGA(
      customerNumber,
      [productId],
      branchToCheck,
      needDetails,
      limit,
      skip
    )
      .then(inventoryResponse => {
        if (
          inventoryResponse.data.error ||
          (inventoryResponse.data.result &&
            !inventoryResponse.data.result.allBranches)
        ) {
          console.error("Inventory error");
          setInventoryError(intl.get("inventory-error"));
        } else {
          const UOM = formatUnitOfMeasure(
            inventoryResponse.data.result.allBranches
          );
          setUnitOfMeasure(UOM);
          const convertedUnitOfMeasure = convertUnitOfMeasure(UOM, itemQty);

          setInventoryAvailability(inventoryResponse.data.result.allBranches);
          setValidQuantity(convertedUnitOfMeasure === 0);
          setInventoryError("");
        }
      })
      .catch(error => {
        handleAvailabilityError(error);
      });
  };

  /**
   * ## populateitemQuantity
   * @param quantity number
   *
   * @description Sets selected item quantity in the state.
   */
  const populateitemQuantity = (quantity: number) => {
    const convertedUnitOfMeasure = convertUnitOfMeasure(
      unitOfMeasure,
      quantity
    );

    setValidQuantity(convertedUnitOfMeasure === 0);
    setItemQty(quantity);
  };

  const handleAvailabilityError = error => {
    console.error("Inventory error", error);
    setInventoryError(intl.get("inventory-error"));
  };
  const isPriceZero = priceData && `${priceData.total}` === "0";
  const isCallButtonActive =
    (config.calculatePrice && isPriceZero) ||
    !skuEntitled ||
    (config.checkAvailability &&
      !branchAvailability &&
      !regionAvailability &&
      !validQuantity);

  const renderAuthenticatedAddtoCartHTML = () => {
    return (
      <>
        <ProductAvailability
          productId={productId}
          inventoryAvailability={inventoryAvailability}
          itemQty={itemQty}
          branchAvailability={branchAvailability}
          regionAvailability={regionAvailability}
          inventoryError={inventoryError}
          isCallButtonActive={isCallButtonActive}
        />
        <div className="add-to-cart-container">
          <AddProductToCartComponent
            productId={productId}
            productData={productData}
            itemPriceDetails={priceData}
            populateitemQuantity={populateitemQuantity}
            skuEntitled={skuEntitled}
            branchAvailability={null}
            regionAvailability={null}
            validQuantity={validQuantity}
          />
          {systemBuilderPageDisplay && (
            <p className="system-builder-message">
              {intl.get("need-complete-system")}
              <NavLink to="/systemBuilder" className="system-builder-link">
                {intl.get("system-builder")}
              </NavLink>
            </p>
          )}
        </div>
        {!validQuantity && (
          <p className="invalid-quantity-message">
            {intl.get("unit-of-measure-error-message", {
              product: intl.get("product"),
              unitOfMeasure
            })}
          </p>
        )}
      </>
    );
  };

  const renderUnAuthenticatedAddToCartHTML = () => {
    return (
      <div className="atc-placeholder-logged-out">
        {intl.get("anonymous-browse-product-cta1")}
        &nbsp;
        <AppHeaderLoginMain
          history={history}
          permission
          appHeaderLoginLinks={appHeaderLoginLinks}
          appModalLoginLinks={appModalLoginLinks}
          isLoggedIn={false}
        />
        {intl.get("anonymous-browse-product-cta2")}
      </div>
    );
  };

  return (
    <>
      {isLoggedIn
        ? renderAuthenticatedAddtoCartHTML()
        : renderUnAuthenticatedAddToCartHTML()}
    </>
  );
};

export default ProductAddToCartSection;
