/* eslint-disable camelcase */
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import intl from "react-intl-universal";
import {
  Input,
  PartsFinderTable,
  page,
  getConfig
} from "@zilker/store-components";
import MessageContainer from "@zilker/store-components/src/MessageContainer/messagecontainer";
import {
  getPartsAndManifests,
  priceCatalog,
  checkEntitlementSku,
  getAvailabilityMotili,
  getAvailabilityDGA
} from "../services/connectServices";
import { brSearch } from "../services/SearchService";
import { ProductPriceRequest } from "../utils/mappings/productDetails";
import { MainContext } from "../contexts/MainContext";
import {
  handleCustomException,
  formatInventoryAvailability,
  InventoryAvailabilityInterface,
  formatDGAInventory
} from "../utils/helpers";

import "./PartsFinderPage.less";

interface Part {
  partNumber: string;
  description: string;
  substitutePart: string;
}
export interface PartDetails {
  brand?: string;
  class?: string;
  description: string;
  full_image?: string;
  pid: string;
  price_range?: Array<number>;
  sale_price?: number;
  sale_price_range?: Array<number>;
  thumb_image?: string;
  title?: string;
  url?: string;
  variants?: any;
  exists?: boolean; // set to false if part doesn't exist  in brSM catalog
}
export interface PriceDetails {
  discount: number;
  list: number;
  netEach: number;
  quantity: number;
  sku: string;
  taxable: string;
  total: number;
  valid: boolean;
}

export interface EntitlementDetails {
  brand: string;
  entitled: boolean;
  sku: string;
  valid: boolean;
}

export interface PartsFinderSearchParams {
  sku: string;
  serialNumber: string;
  prevUrl: string;
}

interface PartsFinderProps extends RouteComponentProps {
  history: any;
}
interface PartsFinderState {
  sku: string;
  serialNumber: string;
  parts: Array<PartDetails>;
  prices: Array<PriceDetails>;
  availability: Array<InventoryAvailabilityInterface>;
  entitlements: Array<EntitlementDetails>;
  loading: boolean;
  errorMessage: string;
  isMobile: boolean;
  scrolled: boolean;
  uniqueSkus: Array<string>;
  uniqueModelNumbers: Array<{ mfgModelNumber: string; parts: Array<Part> }>;
  selectedSku: string;
  jobNumber: string;
  inventoryError: string;
}

let Config;
class PartsFinderPage extends Component<PartsFinderProps, PartsFinderState> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    Config = getConfig().config;
    this.state = {
      sku: "",
      serialNumber: "",
      parts: [],
      prices: [],
      availability: [],
      entitlements: [],
      loading: false,
      errorMessage: "",
      isMobile: false,
      scrolled: false,
      uniqueSkus: [],
      uniqueModelNumbers: [],
      selectedSku: "",
      jobNumber: "",
      inventoryError: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.updateWindowSize = this.updateWindowSize.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;
    page();
    this.updateWindowSize();
    window.addEventListener("resize", this.updateWindowSize);

    // Check if we are navigating back to Parts finder page (history.action==="POP") and if we persisted search params in session storage
    // That means we are navigating back from PDP since there we are storing search params in session storage
    const searchParams: PartsFinderSearchParams = this.restoreSessionStorage();
    if (history.action === "POP" && searchParams) {
      const { sku, serialNumber } = searchParams;
      this.setState(
        {
          sku,
          serialNumber
        },
        () => {
          this.handleSearch();
        }
      );
    } else {
      this.removeSessionStorage();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;

    // Logic for scrolling to clicked part when user navigates back from PDP
    const { scrolled, parts, sku } = this.state;
    const { jobNumber } = prevState;
    let currJobNumber = jobNumber;
    if (defaultCart) {
      currJobNumber = defaultCart.jobNumber;
    }
    if (currJobNumber !== jobNumber && parts.length > 0 && sku) {
      // Job has changed, so we fetch pricing data
      this.fetchPartsPrices(parts);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ jobNumber: currJobNumber });
    }

    const currentSku = sessionStorage.getItem("sku");
    const partToScroll = document.getElementById(`${currentSku}`);
    if (partToScroll && !scrolled) {
      partToScroll.scrollIntoView();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        scrolled: true
      });
    }
  }

  componentWillUnmount() {
    this.removeSessionStorage();
    window.removeEventListener("resize", this.updateWindowSize);
  }

  updateWindowSize() {
    this.setState({
      isMobile: window.innerWidth <= 768
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
      uniqueSkus: [],
      uniqueModelNumbers: []
    }));
  }

  handleModelButtonClick(model) {
    this.setState({
      loading: true,
      errorMessage: "",
      parts: [],
      prices: [],
      entitlements: [],
      availability: [],
      selectedSku: ""
    });

    this.fetchPartsDetails(model.parts, model.mfgModelNumber);
  }

  // eslint-disable-next-line class-methods-use-this
  restoreSessionStorage(): PartsFinderSearchParams {
    return JSON.parse(sessionStorage.getItem("partsFinderSearchParams"));
  }

  // eslint-disable-next-line class-methods-use-this
  removeSessionStorage() {
    sessionStorage.removeItem("partsFinderSearchParams");
    sessionStorage.removeItem("sku");
  }

  validatePartsResponse(data, uniqueSku) {
    const { sku, serialNumber } = this.state;
    /**
     * ## Entered only sku
     * data: Array<{ mfgModelNumber: string; parts: Array<Part> }>
     *  */
    if (sku && !serialNumber) {
      this.setState({
        uniqueModelNumbers: data,
        errorMessage: "",
        loading: false
      });
      return;
    }
    /**
     * ## Entered onlu serial number
     * */
    if (!sku && !uniqueSku && serialNumber) {
      if (data.every(element => typeof element === "string")) {
        /**
         * If entered serial number is not unique we are getting list of skus that match that serial number
         * data: Array<string>
         */
        this.setState({
          uniqueSkus: data,
          errorMessage: "",
          loading: false,
          selectedSku: ""
        });
      } else {
        /**
         * If entered serial number is unique we are getting list of model numbers with parts
         * data: Array<{mfgModelNumber: string, modelNumber: string, parts: Array<Part>}>
         */
        this.setState({
          uniqueModelNumbers: data,
          errorMessage: "",
          loading: false
        });
      }
      return;
    }

    /**
     * Entered both sku and serial number
     * data: Array<Part>
     */

    const skuToUse = sku !== "" ? sku : uniqueSku;
    this.fetchPartsDetails(data, skuToUse);
  }

  async handleSearch(uniqueSku: string = "") {
    const { sku, serialNumber } = this.state;
    this.setState({
      loading: true,
      errorMessage: "",
      parts: [],
      prices: [],
      entitlements: [],
      availability: []
    });

    /**
     * We are calling 5 services here:
     * 1. DCS to get list of parts
     * 2. brSM to get part and substitutes details
     * 3. DCS to get entitlement for parts
     * 4. DCS to get prices for parts
     * 5. DCS to get availavility for parts
     */
    try {
      // Call DCS to get list of parts
      const partsResponse = await getPartsAndManifests(
        sku || uniqueSku,
        serialNumber
      );

      const { data } = partsResponse;
      if (data.length) {
        this.validatePartsResponse(data, uniqueSku);
      } else {
        this.setState({
          errorMessage: intl.get("parts-finder-error-message"),
          loading: false,
          parts: [],
          selectedSku: uniqueSku
        });
      }
    } catch (error) {
      this.setState({
        errorMessage: intl.get("parts-finder-error-message"),
        loading: false,
        parts: [],
        selectedSku: ""
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  formatFqParam(parts: Array<Part>): string {
    // Search for substitute's part number or original part number if substitute is empty
    let fqParam = parts.reduce((prevPartNumbers, currPart) => {
      return !prevPartNumbers
        ? `pid:("${currPart.substitutePart.trim() ||
            currPart.partNumber.trim()}"`
        : `${prevPartNumbers} OR "${currPart.substitutePart.trim() ||
            currPart.partNumber.trim()}"`;
    }, "");
    fqParam += ")";

    return fqParam;
  }

  // eslint-disable-next-line class-methods-use-this
  formatFinalParts(
    parts: Array<Part>,
    partsDetails: Array<PartDetails>
  ): Array<PartDetails> {
    const finalParts: Array<PartDetails> = [];
    // Filter response - if substitute part number exists in the reponse use that one, if it doesn't search for original part number
    parts.forEach(part => {
      const finalPart = partsDetails.find(
        partDetails =>
          part.substitutePart === partDetails.pid ||
          part.partNumber === partDetails.pid
      );
      if (finalPart) {
        finalPart.exists = true;
        finalParts.push(finalPart);
      } else {
        finalParts.push({
          pid: part.substitutePart || part.partNumber,
          description: part.description,
          exists: false
        });
      }
    });
    return finalParts;
  }

  async fetchPartsDetails(parts, sku) {
    const {
      auth: { isLoggedIn }
    } = this.context;
    const { uniqueModelNumbers } = this.state;
    const fqParam = this.formatFqParam(parts);
    const { location } = this.props;
    const { state } = location;
    let prev = "";
    if (!(typeof state === "undefined")) {
      prev = location.state.prevUrl;
    }
    const prevUrl = prev;
    try {
      // Call brSM to get substitutes details
      const partsDetailsResponse = await brSearch(
        "",
        "keyword",
        0,
        105,
        "",
        fqParam,
        window.location.href,
        prevUrl
      );
      const {
        response: { docs: partsDetails }
      } = partsDetailsResponse.data;

      const finalParts = this.formatFinalParts(parts, partsDetails);

      if (isLoggedIn) {
        // Call DCS to get entitlement for parts
        this.fetchPartsEntitlement(finalParts);

        // Call DCS to get prices for parts
        this.fetchPartsPrices(finalParts);

        // Call DCS to get availavility for parts
        this.fetchPartsAvailability(finalParts);
      }

      this.setState({
        parts: finalParts,
        errorMessage: "",
        loading: false,
        selectedSku: sku
      });
    } catch (error) {
      this.setState({
        errorMessage: intl.get("parts-finder-error-message"),
        loading: false,
        parts: []
      });
    }
  }

  async fetchPartsEntitlement(parts: Array<PartDetails>) {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      user: {
        userProfile: { customerNumber }
      },
      auth: { logout }
    } = this.context;
    const { history } = this.props;
    const { selectedBranch } = defaultCart;
    const skus = parts
      .filter(part => part.exists)
      .map(part => part.pid.trim())
      .join("|");
    try {
      const {
        data
      }: { data: Array<EntitlementDetails> } = await checkEntitlementSku(
        customerNumber,
        skus,
        selectedBranch.vendor
      );
      this.setState({
        entitlements: data
      });
    } catch (error) {
      const errorPath =
        "fetchPartsEntitlement => checkEntitlementSku => PartsFinderPage.tsx";
      handleCustomException(error, logout, history, errorPath);
    }
  }

  async fetchPartsPrices(parts: Array<PartDetails>) {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      auth: { logout },
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;
    const { history } = this.props;
    const { selectedBranch, jobNumber } = defaultCart;
    const priceRequestBody: ProductPriceRequest = {
      customerNumber,
      branchNumber: selectedBranch.code,
      skus: parts.filter(part => part.exists).map(part => part.pid.trim()),
      jobNumber
    };
    try {
      const { data } = await priceCatalog(priceRequestBody);
      const { items }: { items: Array<PriceDetails> } = data;
      this.setState({
        prices: items
      });
    } catch (error) {
      const errorPath =
        "fetchPartsPrices => priceCatalog => PartsFinderPage.tsx";
      handleCustomException(error, logout, history, errorPath);
    }
  }

  async fetchPartsAvailability(parts: Array<PartDetails>) {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { findBranch },
      auth: { logout },
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;
    const { history } = this.props;
    const { selectedBranch, clientId } = defaultCart;
    const { latitude, longitude } = findBranch(selectedBranch.code);

    const skus = parts.filter(part => part.exists).map(part => part.pid.trim());
    const skusMotili = skus && skus.join("|");

    try {
      let inventoryAvailability: Array<InventoryAvailabilityInterface>;
      if (Config.cortexApi.scope === "motili") {
        const { data } = await getAvailabilityMotili(
          skusMotili,
          latitude,
          longitude,
          clientId
        );
        inventoryAvailability = formatInventoryAvailability(data);
        this.setState({
          availability: inventoryAvailability
        });
      } else {
        const branchNumber = selectedBranch.code;
        const { data } = await getAvailabilityDGA(
          customerNumber,
          skus,
          branchNumber
        );
        if (data.error || (data.result && !data.result.length)) {
          console.error("Inventory error");
          this.setState({
            inventoryError: intl.get("inventory-error")
          });
        } else {
          inventoryAvailability = formatDGAInventory(data);
          this.setState({
            availability: inventoryAvailability,
            inventoryError: ""
          });
        }
      }
    } catch (error) {
      console.error("Inventory error", error);
      this.setState({
        inventoryError: intl.get("inventory-error")
      });
      const errorPath =
        "fetchPartsAvailability => getAvailability => PartsFinderPage.tsx";
      handleCustomException(error, logout, history, errorPath);
    }
  }

  render() {
    const {
      sku,
      serialNumber,
      loading,
      errorMessage,
      parts,
      prices,
      availability,
      isMobile,
      entitlements,
      uniqueSkus,
      uniqueModelNumbers,
      selectedSku,
      inventoryError
    } = this.state;
    const { history } = this.props;
    const prevUrl = window.location.href;
    return (
      <div className="parts-finder-container container">
        {inventoryError && (
          <MessageContainer
            message={{
              type: "basic",
              debugMessages: inventoryError
            }}
            closeContainerHandler={null}
          />
        )}
        <div className="content-box">
          <h4 className="bullet">{intl.get("parts-finder")}</h4>
          <p className="instructions-form">
            {intl.get("parts-finder-instructions")}
          </p>
          <div className="search-box">
            <Input
              label={intl.get("equipment-sku")}
              type="text"
              inputName="sku"
              ariaLabel="sku"
              value={sku}
              placeholder={intl.get("parts-finder-sku_placeholder")}
              inputHandler={this.handleInputChange}
            />
            <p className="or-label">{intl.get("capital-or")}</p>
            <Input
              label={intl.get("serial-number")}
              type="text"
              inputName="serialNumber"
              ariaLabel="serialNumber"
              value={serialNumber}
              inputHandler={this.handleInputChange}
            />
            {!loading ? (
              <button
                type="button"
                className="ep-btn primary"
                onClick={() => this.handleSearch()}
              >
                {intl.get("search")}
              </button>
            ) : (
              <div className="loader-wrapper">
                <div className="miniLoader" />
              </div>
            )}
            <button
              type="button"
              className="ep-btn secondary"
              onClick={() => window.location.reload()}
            >
              {intl.get("reset")}
            </button>
          </div>
          {uniqueModelNumbers && (
            <div className="unique-model-numbers">
              {uniqueModelNumbers.map(model => (
                <button
                  type="button"
                  className={`ep-btn ${
                    selectedSku === model.mfgModelNumber
                      ? "primary"
                      : `${Config.cortexApi.scope} secondary`
                  }`}
                  key={model.mfgModelNumber}
                  onClick={() => {
                    this.handleModelButtonClick(model);
                  }}
                  disabled={loading}
                >
                  <i className="icon-search" /> {model.mfgModelNumber}
                </button>
              ))}
            </div>
          )}
          {uniqueSkus && (
            <div className="unique-skus">
              {uniqueSkus.map(uniqueSku => (
                <button
                  type="button"
                  className={`ep-btn ${
                    selectedSku === uniqueSku ? "primary" : "secondary"
                  }`}
                  key={uniqueSku}
                  onClick={() => this.handleSearch(uniqueSku)}
                  disabled={loading}
                >
                  <i className="icon-search" />
                  {uniqueSku}
                </button>
              ))}
            </div>
          )}
          <p className="parts-error-message">{errorMessage}</p>
          {parts && parts.length ? (
            <PartsFinderTable
              parts={parts}
              prices={prices}
              availability={availability}
              entitlements={entitlements}
              isMobile={isMobile}
              history={history}
              searchParams={{ sku, serialNumber, prevUrl }}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(PartsFinderPage);
