import React, { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import intl from "react-intl-universal";
import { History } from "history";

import { SavedOrderCard, page, getConfig } from "@zilker/store-components";
import SavedOrderDetails from "./SavedOrderDetails";
import { useMainContext } from "../contexts/MainContext";

import "./SavedOrders.less";
import { checkTokensExpired, pushToMaintenace } from "../utils/helpers";

interface SavedOrdersProps {
  history: History;
  match: any;
  auth: any;
}

const SavedOrders: FC<SavedOrdersProps> = ({
  match: { params },
  history,
  auth: { logout }
}) => {
  const { detail, zoom } = params;
  const { config } = getConfig();
  const { defaultChannel } = config.brXM;
  const hideDots = defaultChannel === "motili";
  const {
    cart: { cartList, getCartList, cartListError }
  } = useMainContext();

  const [carts, setCarts] = useState<any>(cartList);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    page();
    setShowLoader(true);
    getCartList().catch(e => {
      if (checkTokensExpired(e)) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "Logout => getCartList => SavedOrders.tsx"
          })
        );
      }
    });

    return () => setShowLoader(false);
  }, []);

  useEffect(() => {
    if (cartList) {
      setCarts(cartList);
      setShowLoader(false);
    }
  }, [cartList]);

  if (cartListError) {
    return (
      <Redirect
        to={{
          pathname: "/maintenance",
          state: {
            error: {
              e: { message: cartListError },
              errIn: "Cart list error in the cart context => SavedOrders.tsx"
            }
          }
        }}
      />
    );
  }

  return detail && zoom ? (
    <SavedOrderDetails
      orderName={detail}
      link={zoom}
      history={history}
      logout={logout}
    />
  ) : (
    <div className="saved-orders content-box content-table">
      <h2 className="bullet">{intl.get("saved-orders")}</h2>
      <div className="saved-orders-labels">
        <span>{`${intl.get("name")}${hideDots ? "" : ":"} `}</span>
        <span>{`${intl.get("actions")}${hideDots ? "" : ":"} `}</span>
      </div>
      <SavedOrderCard history={history} carts={carts} />
      {showLoader && <div className="loader" />}
    </div>
  );
};

export default SavedOrders;
