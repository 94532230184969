import React from "react";
import {
  checkTokensExpired,
  pushToMaintenace,
  handleCustomException,
  generateSpecificErrorMessage,
  formatDGAInventory,
  formatInventoryAvailability,
  InventoryAvailabilityInterface,
  convertUnitOfMeasure
} from "@elasticpath/ref-store/src/utils/helpers";
import {
  addToCart,
  changeBranchAndVendorOnCurrentOrder
} from "@elasticpath/ref-store/src/services/EpServices";
import { ProductPriceRequest } from "@elasticpath/ref-store/src/utils/mappings/productDetails";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import {
  priceCatalog,
  checkEntitlementSku,
  getAvailabilityMotili,
  getAvailabilityDGA
} from "@elasticpath/ref-store/src/services/connectServices";
import BarcodeScanner from "../BarcodeScanner/barcodescanner";
import {
  cortexFetchItemLookupForm,
  itemLookup,
  searchLookup
} from "../utils/CortexLookup";
import QuickOrderForm from "../QuickOrderForm/quickorderform";
import AvailableBranchList from "../AvailableBranchList/availablebranchlist.main";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { productAdded } from "../utils/Segment";

import "./bulkorder.main.less";

let Config: IEpConfig | any = {};
let intl = { get: (str, ...args: any[]) => str };

interface EPProductInterface {
  _addtocartform: Array<any>;
  _addtowishlistform: Array<any>;
  _availability: Array<any>;
  _code: Array<any>;
  _definition: Array<any>;
  _price: Array<any>;
  _recommendations: Array<any>;
  links: Array<any>;
  messages: Array<any>;
  self: any;
}

interface QuickOrderItem {
  code: string;
  isDuplicated: boolean;
  isValidField: boolean;
  key: string;
  product: EPProductInterface;
  quantity: number;
  entitlementError: true;
  skuErrorMessage: "";
}

interface BulkOrderProps {
  isBulkModalOpened: boolean;
  handleClose: (...args: any[]) => any;
  history: any;
  auth: any;
}

interface BulkOrderState {
  items: any[];
  bulkOrderItems: any[];
  defaultItemsCount: number;
  defaultItem: {
    code: string;
    quantity: number;
    product: {};
    isValidField: boolean;
    isDuplicated: boolean;
  };
  isLoading: boolean;
  isQuickOrderLoading: boolean;
  csvText: string;
  bulkOrderErrorMessage: string;
  bulkOrderDuplicatedErrorMessage: string;
  bulkOrderNotValid: boolean;
  isBarcodeScannerOpen: boolean;
  barcodeScannerError: string;
  failedRequestError: string;
  clearQuickOrder: boolean;
  failedEntitlementRequest: boolean;
  loadingValidation: boolean;
  addToCartErrorMessage: string;
  branchModalOpen: boolean;
  closeBranchChangeModal: () => void;
  chosenBranchNumber: string;
}

export class BulkOrder extends React.Component<BulkOrderProps, BulkOrderState> {
  static contextType = MainContext;

  bulkRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);

    this.bulkRef = React.createRef();

    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = getConfig());
    const defaultItem = {
      code: "",
      quantity: 1,
      product: {},
      isValidField: false,
      isDuplicated: false,
      entitlementError: false,
      skuErrorMessage: ""
    };
    const defaultItemsCount = 10;

    this.state = {
      items: Array(defaultItemsCount)
        .fill(defaultItem)
        .map((item, index) => ({ ...item, key: `quick-order-sku-${index}` })),
      bulkOrderItems: [],
      defaultItemsCount,
      defaultItem,
      isLoading: false,
      isQuickOrderLoading: false,
      csvText: "",
      bulkOrderErrorMessage: "",
      bulkOrderDuplicatedErrorMessage: "",
      bulkOrderNotValid: true,
      isBarcodeScannerOpen: false,
      barcodeScannerError: "",
      failedRequestError: "",
      clearQuickOrder: false,
      failedEntitlementRequest: false,
      loadingValidation: false,
      addToCartErrorMessage: "",
      branchModalOpen: false,
      closeBranchChangeModal: () => {},
      chosenBranchNumber: ""
    };
    this.addAllToCart = this.addAllToCart.bind(this);
    this.quickFormSubmit = this.quickFormSubmit.bind(this);
    this.handleBarcodeClick = this.handleBarcodeClick.bind(this);
    this.handleBarcodeModalClose = this.handleBarcodeModalClose.bind(this);
    this.handleBarcodeScanned = this.handleBarcodeScanned.bind(this);
    this.validateBulkItems = this.validateBulkItems.bind(this);
    this.handleRequestException = this.handleRequestException.bind(this);
    this.handleExpiredToken = this.handleExpiredToken.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleBranchModalOpen = this.handleBranchModalOpen.bind(this);
    this.changeBranch = this.changeBranch.bind(this);
    this.handleBranchChangePriceUpdate = this.handleBranchChangePriceUpdate.bind(
      this
    );
    this.handleQuickOrderPriceSuccess = this.handleQuickOrderPriceSuccess.bind(
      this
    );
  }

  componentDidMount() {
    this.setEventListener();
  }

  componentDidUpdate() {
    this.setEventListener();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setEventListener() {
    const { isBulkModalOpened } = this.props;
    const { branchModalOpen } = this.state;
    if (isBulkModalOpened && !branchModalOpen) {
      document.addEventListener("mousedown", this.handleClickOutside);
    } else {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  }

  handleClickOutside(event) {
    const { handleClose } = this.props;
    if (!this.bulkRef.current.contains(event.target)) {
      handleClose();
    }
  }

  /**
   * ## addAllToCart
   * @param orderItems Array<QuickOrderItem> | Array<{code:string, quantity:number | string}>
  
   * @param isQuickOrder boolean - if this is true, then items are quick order items,
   * if false, then the items are bulk order items.
   */
  addAllToCart(orderItems: Array<any>, isQuickOrder: boolean) {
    if (!orderItems) return;
    const { defaultItemsCount, defaultItem } = this.state;
    const {
      cart: {
        setSuccesCartPopupMessage,
        setErrorCartPopupMessage,
        getCartDetails,
        cartDetails: {
          defaultCart: { addItemsToCart }
        }
      }
    } = this.context;

    let analyticsItems = [];

    if (isQuickOrder) {
      try {
        analyticsItems = orderItems
          .filter(item => item.code !== "")
          .map(item => {
            return {
              name: item.product._definition[0].details.find(
                detail => detail.name === "short_description"
              )["display-value"],
              id: item.code,
              price:
                !item.product.price ||
                item.product.price === intl.get("pending")
                  ? 0
                  : item.product.price,
              brand: item.product._definition[0].details.find(
                detail => detail.name === "brand"
              )["display-value"],
              category: item.product._definition[0].details.find(
                detail => detail.name === "product_category"
              )["display-value"],
              // variant: "",
              quantity: item.quantity
            };
          });
      } catch (error) {
        // We don't want to break the flow if analytics data is not complete.
        console.error(error);
      }
    } else {
      analyticsItems = orderItems
        .filter((item: any) => item.code !== "")
        .map(item => {
          return {
            id: item.code,
            quantity: item.quantity,
            name: "",
            price: item.price,
            brand: "",
            category: ""
          };
        });
    }

    this.setState({ isLoading: true });

    const items = orderItems
      .filter(item => item.code !== "")
      .map(item => ({
        code: item.code.toUpperCase().trim(),
        quantity: item.quantity
      }));

    const totalQuantity = items.reduce((total, item) => {
      return total + item.quantity;
    }, 0);

    addToCart(addItemsToCart.self.uri, { items })
      .then(() => {
        if (analyticsItems.length) {
          // sends information to Segment for every product user adds
          analyticsItems.forEach(item => {
            productAdded(
              item.name,
              item.id,
              item.price,
              item.brand,
              item.category,
              item.quantity
            );
          });
        }
      })
      .then(() => {
        return getCartDetails();
      })
      .then(() => {
        setSuccesCartPopupMessage(totalQuantity);
        this.setState({
          isLoading: false,
          items: Array(defaultItemsCount)
            .fill(defaultItem)
            .map((item, index) => ({
              ...item,
              key: `quick-order-sku-${index}`
            })),
          csvText: "",
          bulkOrderErrorMessage: "",
          bulkOrderDuplicatedErrorMessage: "",
          clearQuickOrder: true,
          failedEntitlementRequest: false,
          addToCartErrorMessage: ""
        });
      })
      .catch(err => {
        this.setState({
          addToCartErrorMessage: generateSpecificErrorMessage(err)
        });
        this.handleRequestException(err);
        this.handleExpiredToken(err);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  generateErrorMessage(messages: Array<any>, orderItems: Array<any>): string {
    return messages.reduce((total: string, message: any) => {
      const sku =
        orderItems.find(item => message["debug-message"].includes(item.code))
          .code || "";

      return `${total} ${sku} ${intl.get("sku-invalid-message")}\n`;
    }, "");
  }

  // eslint-disable-next-line class-methods-use-this
  parseCsvText(csvText: string) {
    const bulkOrderItems = csvText
      .split("\n")
      .filter(l => l.trim().length)
      .map(l => l.split(/[ ,;]+/))
      .map(p => ({
        code: p[0] || "",
        quantity:
          Number.isNaN(parseInt(p[1], 10)) ||
          parseInt(p[1], 10).toString() !== p[1]
            ? 0
            : parseInt(p[1], 10)
      }));
    return bulkOrderItems;
  }

  // eslint-disable-next-line class-methods-use-this
  validateEntitlement(
    entitlements: Array<{
      sku: string;
      entitled: boolean;
      valid: boolean;
    }>,
    skus: Array<string>
  ): [string, boolean] {
    let entitlementErrorMessage;
    entitlementErrorMessage = skus.map(sku =>
      !entitlements.find(elem => elem.sku === sku)
        ? `${sku} ${intl.get("sku-invalid-message")}\n`
        : ""
    );
    entitlementErrorMessage = entitlementErrorMessage.concat(
      entitlements.map(elem =>
        !elem.valid || !elem.entitled
          ? `${elem.sku} ${intl.get("sku-invalid-message")}\n`
          : ""
      )
    );
    const areEntitled =
      skus.length === entitlements.length &&
      entitlements.every(elem => elem.entitled === true && elem.valid === true);

    return [entitlementErrorMessage.join(""), areEntitled];
  }

  // eslint-disable-next-line class-methods-use-this
  validatePrice(catalogItems: Array<any>): [string, boolean] {
    const catalogErrorMessage = catalogItems.map(catalogItem => {
      if (!catalogItem.total || `${catalogItem.total}` === "0") {
        return `${intl.get("product-message-without-price", {
          SKUCode: catalogItem.sku
        })} ${intl.get("clear-invalid-sku")}\n`;
      }
      return "";
    });

    const arePriced = catalogItems.every(
      item => item.total || `${item.total}` !== "0"
    );

    return [catalogErrorMessage.join(""), arePriced];
  }

  // eslint-disable-next-line class-methods-use-this
  validateAvailability(
    bulkOrderItems: Array<any>,
    itemsAvailability: Array<any>
  ): [string, boolean] {
    const availabilityErrorMessage = bulkOrderItems.map(item => {
      const inventoryItem = itemsAvailability.find(ia => ia.sku === item.code);
      if (!inventoryItem && Config.checkAvailability) {
        return `${item.code} ${intl.get("item-unavailable-message")}\n`;
      }
      if (
        inventoryItem &&
        Config.checkAvailability &&
        !inventoryItem.branchAvailability &&
        !inventoryItem.regionAvailability
      ) {
        return `${item.code} ${intl.get("item-unavailable-message")}\n`;
      }
      return "";
    });

    const areAvailable = Config.checkAvailability
      ? itemsAvailability.every(
          item => item.branchAvailability || item.regionAvailability
        )
      : true;

    return [availabilityErrorMessage.join(""), areAvailable];
  }

  // eslint-disable-next-line class-methods-use-this
  validateUnitOfMeasure(
    bulkOrderItems: Array<any>,
    inventoryAvailability: Array<any>
  ): [string, boolean] {
    const unitOfMeasureErrorMessage = bulkOrderItems.map(item => {
      const inventoryItem = inventoryAvailability.find(
        ia => ia.sku === item.code
      );
      const unitOfMeasure = inventoryItem
        ? Number(inventoryItem.unitOfMeasure)
        : 1;
      const convertedUnitOfMeasure = convertUnitOfMeasure(
        unitOfMeasure,
        item.quantity
      );
      if (unitOfMeasure && convertedUnitOfMeasure !== 0) {
        return `${intl.get("unit-of-measure-error-message", {
          product: item.code,
          unitOfMeasure: inventoryItem.unitOfMeasure
        })}\n`;
      }
      return "";
    });

    const isUnitOfMeasureValid = bulkOrderItems.every(item => {
      const inventoryItem = inventoryAvailability.find(
        ia => ia.sku === item.code
      );
      const unitOfMeasure = inventoryItem
        ? Number(inventoryItem.unitOfMeasure)
        : 1;
      const convertedUnitOfMeasure = convertUnitOfMeasure(
        unitOfMeasure,
        item.quantity
      );
      return convertedUnitOfMeasure === 0;
    });

    return [unitOfMeasureErrorMessage.join(""), isUnitOfMeasureValid];
  }

  // eslint-disable-next-line class-methods-use-this
  validateQuantity(
    items: Array<{ code: string; quantity: number }>
  ): [string, boolean] {
    const quantityErrorMessage = items.map(({ code, quantity }) =>
      typeof quantity !== "number" || quantity <= 0
        ? `${intl.get("invalid-quantity", { sku: code })}\n`
        : ""
    );
    const isQuantityValid = items.every(
      ({ quantity }) => typeof quantity === "number" && quantity > 0
    );
    return [quantityErrorMessage.join(""), isQuantityValid];
  }

  setBulkOrderValidity(
    inventoryAvailability: Array<InventoryAvailabilityInterface>,
    bulkOrderItems,
    bulkOrderNotValid,
    bulkOrderErrorMessage
  ) {
    const [availabilityErrorMessage, areAvailable] = this.validateAvailability(
      bulkOrderItems,
      inventoryAvailability
    );
    let unitOfMeasureErrorMessage = "";
    let isUnitOfMeasureValid = true;
    if (areAvailable) {
      [
        unitOfMeasureErrorMessage,
        isUnitOfMeasureValid
      ] = this.validateUnitOfMeasure(bulkOrderItems, inventoryAvailability);
    }
    const bulkOrderNowInvalid =
      bulkOrderNotValid || !areAvailable || !isUnitOfMeasureValid;
    const newBulkOrderErrorMessage = `${bulkOrderErrorMessage}${availabilityErrorMessage}${unitOfMeasureErrorMessage}`;
    this.setState({
      bulkOrderNotValid: bulkOrderNowInvalid,
      bulkOrderErrorMessage: newBulkOrderErrorMessage
    });
  }

  async validateBulkItems() {
    const {
      auth: { isLoggedIn, logout },
      user: { userProfile },
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;
    const { customerNumber } = userProfile;
    const { selectedBranch } = defaultCart;
    const { bulkOrderItems } = this.state;
    const { history } = this.props;
    const {
      cortexApi: { scope }
    } = Config;

    this.setState({
      loadingValidation: true
    });

    if (isLoggedIn && bulkOrderItems && bulkOrderItems.length) {
      const skuList = bulkOrderItems.map(product =>
        product.code.toUpperCase().trim()
      );
      const skus = skuList.join("|");

      let bulkOrderNotValid = false;
      let bulkOrderErrorMessage = "";

      const [quantityErrorMessage, isQuantityValid] = this.validateQuantity(
        bulkOrderItems
      );

      bulkOrderNotValid = !isQuantityValid;
      bulkOrderErrorMessage = quantityErrorMessage;

      if (Config.entitlementCheck && selectedBranch) {
        try {
          const {
            data
          }: {
            data: Array<{
              sku: string;
              entitled: boolean;
              valid: boolean;
            }>;
          } = await checkEntitlementSku(
            customerNumber,
            skus,
            selectedBranch.vendor
          );

          const [
            entitlementErrorMessage,
            areEntitled
          ] = this.validateEntitlement(data, skuList);

          bulkOrderNotValid = !areEntitled;
          bulkOrderErrorMessage = `${bulkOrderErrorMessage}${entitlementErrorMessage}`;
        } catch (error) {
          const errorPath =
            "validateBulkItems => checkEntitlementSku => bulkorder.main.tsx";
          handleCustomException(error, logout, history, errorPath);
          this.setState({ failedEntitlementRequest: true });
        }
      }

      if (Config.calculatePrice) {
        try {
          const bulkSkus = bulkOrderItems.map(item =>
            item.code.toUpperCase().trim()
          );
          const { data } = await this.fetchPrice(selectedBranch.code, bulkSkus);
          const catalogItems = data.items;

          const [catalogErrorMessage, arePriced] = this.validatePrice(
            catalogItems
          );

          const bulkOrderItemsWithPrice = bulkOrderItems.map(bulkItem => {
            const item = catalogItems.find(
              catalogItem =>
                catalogItem.sku.toLowerCase() === bulkItem.code.toLowerCase()
            );
            return {
              ...bulkItem,
              price: item.total
            };
          });

          this.setState({
            bulkOrderItems: bulkOrderItemsWithPrice
          });

          bulkOrderNotValid = bulkOrderNotValid || !arePriced;
          bulkOrderErrorMessage = `${bulkOrderErrorMessage}${catalogErrorMessage}`;
        } catch (error) {
          bulkOrderNotValid = true;
          bulkOrderErrorMessage = `${intl.get("custom-error-catalog")}`;

          this.handleRequestException(error);
          this.handleExpiredToken(error);
        }
      }
      const branchNumber = selectedBranch.code;
      try {
        const { data } = await this.fetchAvailability(
          branchNumber,
          skuList,
          skus
        );
        let inventoryAvailability: Array<InventoryAvailabilityInterface>;
        if (scope !== "motili") {
          inventoryAvailability = formatDGAInventory(data);
        } else {
          inventoryAvailability = formatInventoryAvailability(data);
        }
        this.setBulkOrderValidity(
          inventoryAvailability,
          bulkOrderItems,
          bulkOrderNotValid,
          bulkOrderErrorMessage
        );
      } catch (error) {
        console.error(error);
        this.setState({
          bulkOrderErrorMessage: intl.get("custom-error-availability")
        });
      }
    }
    this.setState({
      loadingValidation: false
    });
  }

  handleCsvChange(newCsvValue) {
    const bulkOrderItems = this.parseCsvText(newCsvValue);
    this.checkDuplication(bulkOrderItems);
    this.setState({
      bulkOrderItems,
      csvText: newCsvValue,
      bulkOrderErrorMessage: "",
      bulkOrderDuplicatedErrorMessage: "",
      failedRequestError: "",
      bulkOrderNotValid: true,
      failedEntitlementRequest: false,
      addToCartErrorMessage: ""
    });
  }

  async handleBranchChangePriceUpdate(
    chosenBranchNumber: string,
    closeBranchChangeModal?: () => void
  ) {
    const { history } = this.props;
    const { items } = this.state;
    const {
      auth: { logout },
      branches: { findBranch },
      cart: {
        cartDetails: { defaultCart },
        getCartDetails
      },
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;
    const {
      cortexApi: { scope }
    } = Config;
    if (defaultCart) {
      const { cartOrderDetailsForm, selectedBranch } = defaultCart;
      // Display loader
      this.setState({ isQuickOrderLoading: true });
      const branch = findBranch(chosenBranchNumber);
      const fetchLink = cartOrderDetailsForm.links[0].uri;
      const skuList = items
        .filter(item => {
          return item.code !== "";
        })
        .map(product => product.code.toUpperCase().trim());
      const skus = skuList.join("|");
      changeBranchAndVendorOnCurrentOrder(branch, fetchLink)
        .then(async () => {
          // Request product (SKU) entitlement.
          getCartDetails().then(async () => {
            if (Config.entitlementCheck && branch) {
              try {
                const { data } = await checkEntitlementSku(
                  customerNumber,
                  skus,
                  selectedBranch.vendor
                );
                if (!data || !data.length || !data[0].entitled) {
                  const newItems = items.map(item => {
                    return {
                      ...item,
                      entitlementError: true,
                      skuErrorMessage: `${intl.get(
                        "sku-entitled-false-message"
                      )}`
                    };
                  });
                  this.setState({
                    items: newItems,
                    isQuickOrderLoading: false
                  });
                }
              } catch (error) {
                const errorPath = "checkEntitlementSku => quickorderform.tsx";
                handleCustomException(error, logout, history, errorPath);
                const newItems = items.map(item => {
                  return {
                    ...item,
                    entitlementError: true
                  };
                });
                this.setState({ items: newItems, isQuickOrderLoading: false });
              }
            }
          });
          // Request product's catalog price.
          if (Config.calculatePrice) {
            try {
              const { data } = await this.fetchPrice(
                chosenBranchNumber,
                skuList
              );
              this.handleQuickOrderPriceSuccess(data.items);
            } catch (error) {
              const errorPath = "priceCatalog => quickorderform.tsx";
              handleCustomException(error, logout, history, errorPath);
              const newItems = items.map(item => {
                return {
                  ...item,
                  skuErrorMessage: `${intl.get(
                    "product-message-without-price",
                    {
                      SKUCode: item.code
                    }
                  )}`
                };
              });
              this.setState({ items: newItems, isQuickOrderLoading: false });
            }
          }
          try {
            // Request product availability.
            const { data } = await this.fetchAvailability(
              chosenBranchNumber,
              skuList,
              skus
            );
            let inventoryAvailability: Array<InventoryAvailabilityInterface>;
            if (scope !== "motili") {
              inventoryAvailability = formatDGAInventory(data);
            } else {
              inventoryAvailability = formatInventoryAvailability(data);
            }

            this.handleQuickOrderAvailabilitySuccess(inventoryAvailability);
          } catch (error) {
            let errorPath = "";
            if (scope !== "motili") {
              errorPath = "getAvailabilityDGA => quickorderform.tsx";
            } else {
              errorPath = "getAvailabilityMotili => quickorderform.tsx";
            }
            handleCustomException(error, logout, history, errorPath);
            this.setState(prevState => ({
              ...prevState,
              isQuickOrderLoading: false
            }));
            // submit all items
            items.forEach((item, i) => {
              if (item.code !== "") {
                const { code, quantity } = item;
                const updatedItem = {
                  code,
                  quantity
                };
                this.quickFormSubmit(updatedItem, i);
              }
            });
          }
        })
        .then(() => {
          if (closeBranchChangeModal) {
            closeBranchChangeModal();
            this.handleBranchModalOpen(false);
          }
          sessionStorage.setItem("notified-branch", "true");
        })
        .catch((e: any) => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => changeBranch => QuickOrderForm.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "changeBranch => QuickOrderForm.tsx"
            });
          }
        });
    }
  }

  quickFormSubmit(updatedItem, index) {
    const { items } = this.state;
    let duplicatedField = false;
    if (updatedItem.code !== "") {
      const itemIndex = items.findIndex(item => item.code === updatedItem.code);
      if (itemIndex !== index && itemIndex !== -1) {
        duplicatedField = true;
      }
    }
    const submittedItems = items.map((stateItem, i) =>
      index === i
        ? { ...stateItem, ...updatedItem, isDuplicated: duplicatedField }
        : stateItem
    );
    this.setState({
      items: submittedItems,
      bulkOrderErrorMessage: "",
      clearQuickOrder: false,
      addToCartErrorMessage: ""
    });
  }

  checkDuplication(bulkOrderItems) {
    let isDuplicated = false;
    const arrayItems = bulkOrderItems.map(item => item.code).sort();
    const results = [];
    for (let i = 0; i < arrayItems.length - 1; i++) {
      if (arrayItems[i] !== "" && arrayItems[i + 1] === arrayItems[i]) {
        if (results.indexOf(arrayItems[i]) === -1) {
          results.push(arrayItems[i]);
        }
        isDuplicated = true;
      }
    }
    const SKUCodes = results.join(", ");
    if (isDuplicated) {
      this.setState({
        bulkOrderDuplicatedErrorMessage: `${intl.get(
          "duplicated-error-message",
          { SKUCodes }
        )}`
      });
    }
  }

  handleBarcodeClick() {
    this.setState({ isBarcodeScannerOpen: true });
  }

  handleBarcodeModalClose() {
    this.setState({ isBarcodeScannerOpen: false });
  }

  handleBarcodeScanned(barcode) {
    this.setState({ barcodeScannerError: "" });
    // TODO: should this here be changed? Search doesn't work with cortex any more
    searchLookup(barcode)
      .then((res: any) => {
        const foundItem = res._element[0];
        const { code } = foundItem._items[0]._element[0]._code[0];
        cortexFetchItemLookupForm()
          .then(() => itemLookup(code, false))
          .then(product => {
            if (foundItem) {
              this.setState(state => {
                const emptyItem = state.items.find(item => item.code === "");
                const isDuplicated = Boolean(
                  state.items.find(item => item.code === code)
                );
                const updatedItems = state.items.map(item => {
                  if (item.key === emptyItem.key) {
                    return {
                      ...item,
                      code,
                      product,
                      isDuplicated
                    };
                  }
                  return item;
                });
                return {
                  ...state,
                  items: updatedItems,
                  isBarcodeScannerLoading: false
                };
              });
            } else {
              this.setState({
                barcodeScannerError: intl.get("no-results-found")
              });
            }
          })
          .catch(err => {
            this.handleRequestException(err);
            this.handleExpiredToken(err);
          });
      })
      .catch(err => {
        this.setState({
          barcodeScannerError: intl.get("no-results-found")
        });
        this.handleExpiredToken(err);
      });
  }

  handleRequestException(err) {
    this.setState({
      failedRequestError: err.message,
      isLoading: false
    });
  }

  handleBranchModalOpen(branchModalOpen) {
    this.setState({
      branchModalOpen
    });
  }

  handleExpiredToken(e) {
    const {
      history,
      auth: { logout }
    } = this.props;

    if (checkTokensExpired(e)) {
      logout().catch(err =>
        pushToMaintenace(history, {
          e: err,
          errIn: "Logout => handleExpiredToken => BulkOrderMain.tsx"
        })
      );
    }
  }

  handleQuickOrderAvailabilitySuccess(
    inventoryAvailability: Array<InventoryAvailabilityInterface>
  ) {
    const { items } = this.state;
    const newItems = items.map(item => {
      let productData = item.product;
      if (item.code !== "") {
        const itemdata = inventoryAvailability.find(inventoryItem => {
          return item.code === inventoryItem.sku;
        });
        productData = {
          ...itemdata
        };
      }
      const noBranchAvailability =
        !productData.branchAvailability && !productData.regionAvailability;

      return {
        ...item,
        product: productData,
        isUnavailable: item.code !== "" ? noBranchAvailability : false
      };
    });
    this.setState({ items: newItems, isQuickOrderLoading: false });
    // submit all items
    newItems.forEach((newItem, i) => {
      if (newItem.code !== "") {
        const { code, quantity, product } = newItem;
        const updatedItem = {
          code,
          quantity,
          product
        };
        this.quickFormSubmit(updatedItem, i);
      }
    });
  }

  handleQuickOrderPriceSuccess(data) {
    const { items } = this.state;
    let newItems = items.map(item => {
      let newPrice = item.price;
      if (item.code !== "") {
        const itemdata = data.find(dataItem => {
          return item.code === dataItem.sku;
        });
        newPrice = itemdata.total;
      }
      return {
        ...item,
        price: newPrice
      };
    });
    this.setState({ items: newItems });
    if (data[0].total === 0) {
      newItems = items.map(item => {
        return {
          ...item,
          skuErrorMessage: `${intl.get("product-message-without-price", {
            SKUCode: item.code
          })}`
        };
      });
      this.setState({ items: newItems, isQuickOrderLoading: false });
    }
  }

  fetchAvailability(branchNumber, skuList, skus) {
    const {
      branches: { findBranch },
      cart: {
        cartDetails: { defaultCart }
      },
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;
    const {
      cortexApi: { scope }
    } = Config;

    const { clientId } = defaultCart;
    const { latitude, longitude } = findBranch(branchNumber);

    if (scope === "motili") {
      return getAvailabilityMotili(skus, latitude, longitude, clientId);
    }
    return getAvailabilityDGA(customerNumber, skuList, branchNumber);
  }

  fetchPrice(branchNumber, skus) {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      job: { persistedJobNumber },
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;

    const { jobNumber } = defaultCart;

    const body: ProductPriceRequest = {
      customerNumber,
      branchNumber,
      skus,
      jobNumber
    };

    if (jobNumber || persistedJobNumber) {
      body.jobNumber = jobNumber || persistedJobNumber;
    }

    return priceCatalog(body);
  }

  changeBranch(chosenBranchNumber, closeModal) {
    this.handleBranchChangePriceUpdate(chosenBranchNumber, closeModal);
  }

  render() {
    const { isBulkModalOpened, handleClose, history } = this.props;
    const {
      items,
      bulkOrderItems,
      csvText,
      isLoading,
      isQuickOrderLoading,
      bulkOrderErrorMessage,
      isBarcodeScannerOpen,
      barcodeScannerError,
      bulkOrderDuplicatedErrorMessage,
      bulkOrderNotValid,
      failedRequestError,
      clearQuickOrder,
      failedEntitlementRequest,
      loadingValidation,
      addToCartErrorMessage,
      branchModalOpen
    } = this.state;

    const {
      branches: { findBranch },
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;
    const {
      cortexApi: { scope }
    } = Config;

    let selectedBranch;
    let clientId = "";
    if (defaultCart) {
      selectedBranch = findBranch(defaultCart.selectedBranch.code);
      // eslint-disable-next-line prefer-destructuring
      clientId = defaultCart.clientId;
    }

    const modalItems = items
      .filter(item => {
        return item.code !== "";
      })
      .map(item => ({
        sku: item.code,
        quantity: item.quantity
      }));

    const isValid = Boolean(
      items.find(item => item.code !== "" && item.isValidField === false)
    );
    const isEmpty = Boolean(
      items.find(item => item.code !== "" && item.isValidField === true)
    );
    const duplicatedFields = Boolean(
      items.find(item => item.code !== "" && item.isDuplicated === true)
    );
    const isQuantityZero = Boolean(items.find(item => !item.quantity));

    const isInvalidPrice = Boolean(
      items.find(
        item =>
          item &&
          item.product &&
          item.product._price &&
          item.product._price[0]["purchase-price"][0].display === 0
      )
    );

    const isQuanityInvalid = Boolean(
      items.find(
        item =>
          item &&
          item.product &&
          item.product.unitOfMeasure &&
          item.quantity &&
          item.quantity % Number(item.product.unitOfMeasure) !== 0 &&
          !Number.isNaN(item.quantity % Number(item.product.unitOfMeasure))
      )
    );

    const areItemsUnavailable = Boolean(
      items.find(
        item => item && item.product && item.product.branchAvailability === 0
      )
    );

    const hasEntitlementFailed = Boolean(
      items.find(item => item && item.product && item.product.entitlementError)
    );

    const isDisabledButton =
      !Config.b2b.enable ||
      isValid ||
      !isEmpty ||
      isQuantityZero ||
      duplicatedFields ||
      isInvalidPrice ||
      isQuanityInvalid ||
      hasEntitlementFailed;

    const isDisabledBulkAddToCart =
      !csvText ||
      !Config.b2b.enable ||
      !!bulkOrderErrorMessage ||
      !!bulkOrderDuplicatedErrorMessage ||
      bulkOrderNotValid ||
      !!failedRequestError ||
      failedEntitlementRequest;

    const isMotili = scope === "motili";

    return (
      <div
        className={`bulk-order-component ${
          !isBulkModalOpened ? "hideModal" : ""
        }`}
        ref={this.bulkRef}
      >
        <div
          role="presentation"
          className="bulk-order-close-button"
          onClick={() => {
            handleClose();
          }}
        >
          <p className="bulk-order-hide">{intl.get("hide")}</p>
        </div>
        <div className="bulk-modal">
          <p className="view-title">{intl.get("order-form")}</p>
          <ul className="nav nav-tabs itemdetail-tabs" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="quick-order-tab"
                data-toggle="tab"
                href="#quick-order"
                role="tab"
                aria-selected="true"
              >
                {intl.get("quick-order-title")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="bulk-order-tab"
                data-toggle="tab"
                href="#bulk-order"
                role="tab"
                aria-selected="false"
              >
                {intl.get("bulk-order-title")}
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="quick-order"
              role="tabpanel"
              aria-labelledby="quick-order-tab"
            >
              <div className="button-container">
                <button
                  className="ep-btn primary small"
                  id="add_to_cart_quick_order_button"
                  aria-label={intl.get("add-all-to-cart")}
                  disabled={isDisabledButton}
                  type="submit"
                  onClick={() => {
                    this.addAllToCart(items, true);
                  }}
                >
                  {hasEntitlementFailed
                    ? intl.get("call")
                    : intl.get("add-all-to-cart")}
                </button>
                {areItemsUnavailable && !isMotili && (
                  <AvailableBranchList
                    branchModalOpen={branchModalOpen}
                    selectedBranch={selectedBranch}
                    clientId={clientId}
                    items={modalItems}
                    history={history}
                    handleBranchModalOpen={this.handleBranchModalOpen}
                    changeBranch={this.changeBranch}
                    useButton
                  />
                )}
                {barcodeScannerError && (
                  <div className="bulk-order-error-message">
                    {barcodeScannerError}
                  </div>
                )}
                {isLoading ? <div className="miniLoader" /> : ""}
              </div>
              <div className="quickOrderRegion" data-region="quickOrderRegion">
                {items.map((item, i) => {
                  return (
                    <QuickOrderForm
                      item={item}
                      key={item.key}
                      onItemSubmit={updatedItem =>
                        this.quickFormSubmit(updatedItem, i)
                      }
                      clearQuickOrder={clearQuickOrder}
                      isQuickOrderLoading={isQuickOrderLoading}
                    />
                  );
                })}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="bulk-order"
              role="tabpanel"
              aria-labelledby="bulk-order-tab"
            >
              <p className="content-error-message">{addToCartErrorMessage}</p>
              <div className="button-container">
                <button
                  className="ep-btn primary small"
                  id="add_to_cart_bulk_order_button"
                  aria-label={intl.get("add-all-to-cart")}
                  type="submit"
                  disabled={isDisabledBulkAddToCart}
                  onClick={() => {
                    this.addAllToCart(bulkOrderItems, false);
                  }}
                >
                  {failedEntitlementRequest
                    ? intl.get("call")
                    : intl.get("add-all-to-cart")}
                </button>
                {isLoading ? <div className="miniLoader" /> : ""}
              </div>
              <div
                className="tab-bulk-order"
                id="bulkOrderRegion"
                data-region="bulkOrderRegion"
              >
                <p>{intl.get("enter-product-sku-and-quantity")}</p>
                <p>{intl.get("item-#1-qty")}</p>
                <p>{intl.get("item-#2-qty")}</p>
                <p className="bulk-text-area-title">
                  <b>{intl.get("enter-product-sku-and-quantity-in-input")}</b>
                </p>
                <textarea
                  className="bulk-csv"
                  rows={5}
                  value={csvText}
                  onChange={event => this.handleCsvChange(event.target.value)}
                />
                <div className="button-container">
                  <button
                    type="button"
                    className="ep-btn primary small"
                    onClick={this.validateBulkItems}
                    disabled={!isDisabledBulkAddToCart}
                  >
                    {intl.get("validate")}
                  </button>
                  {loadingValidation && <div className="miniLoader" />}
                </div>
                {bulkOrderDuplicatedErrorMessage && (
                  <p className="content-error-message">
                    {bulkOrderDuplicatedErrorMessage}
                  </p>
                )}
                {bulkOrderErrorMessage && (
                  <p className="content-error-message">
                    {bulkOrderErrorMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {isBarcodeScannerOpen && (
          <BarcodeScanner
            isModalOpen={isBarcodeScannerOpen}
            handleModalClose={this.handleBarcodeModalClose}
            handleCodeFound={this.handleBarcodeScanned}
          />
        )}
      </div>
    );
  }
}

export default BulkOrder;
