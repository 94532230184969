import React, { FC, useState, useEffect, useContext } from "react";
import intl from "react-intl-universal";

import {
  priceCatalog,
  checkEntitlementSku,
  getAvailabilityMotili,
  getAvailabilityDGA
} from "@elasticpath/ref-store/src/services/connectServices";
import {
  formatInventoryAvailability,
  formatDGAInventory,
  generateSpecificErrorMessage,
  InventoryAvailabilityInterface
} from "@elasticpath/ref-store/src/utils/helpers";
import { addToCart } from "@elasticpath/ref-store/src/services/EpServices";
import {
  EntitlementDetails,
  PriceDetails
} from "@elasticpath/ref-store/src/containers/PartsFinderPage";
import { ProductPriceRequest } from "@elasticpath/ref-store/src/utils/mappings/productDetails";
import MultipleItemsCarousel from "../MultipleItemsCarousel/MultipleItemsCarousel";
import {
  Doc,
  Metadata
} from "../../../models/BloomreachPathsAndRecommResponse";
import SearchResultsItem from "../SearchResultsPage/search.results.item";
import { SingleProduct } from "../../../app/src/utils/searchUtils";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";

import "./CartRecommendedProductsWidget.less";

declare let BrTrk: any;
interface CartRecommendedProductsWidgetInterface {
  products: Doc[];
  metadata: Metadata;
}

const CartRecommendedProductsWidget: FC<
  CartRecommendedProductsWidgetInterface
> = ({ products, metadata }) => {
  const [productsWithDetails, setProductsWithDetails] = useState<
    Array<SingleProduct>
  >(products);
  const [prices, setPrices] = useState<Array<PriceDetails>>(null);
  const [inventory, setInventory] = useState<
    Array<InventoryAvailabilityInterface>
  >(null);
  const [entitlements, setEntitlements] = useState<Array<EntitlementDetails>>(
    null
  );
  const [addCartButtonIds, setAddCartButtonsIds] = useState<Array<string>>([]);
  const context = useContext<{ user: any; cart: any; branches: any }>(
    MainContext
  );

  const { config }: { config: IEpConfig } = getConfig();

  useEffect(() => {
    trackWidgetViewEvent();
  }, []);

  useEffect(() => {
    if (
      products.map(({ pid }) => pid).join() !==
      productsWithDetails.map(({ pid }) => pid).join()
    ) {
      setProductsWithDetails(products);
      setPrices(null);
      setEntitlements(null);
      setInventory(null);
      trackWidgetViewEvent();
    }
  }, [products]);

  useEffect(() => {
    if (!prices) fetchPrices();
    if (!entitlements) fetchEntitlement();
  }, [productsWithDetails]);

  useEffect(() => {
    if (!inventory) fetchInventory();
  }, [inventory]);

  useEffect(() => {
    if (prices) updateProductDetails(prices);
  }, [prices]);

  useEffect(() => {
    if (inventory) updateProductDetails(inventory);
  }, [inventory]);

  useEffect(() => {
    if (entitlements) updateProductDetails(entitlements);
  }, [entitlements]);

  const fetchPrices = async () => {
    const {
      user: {
        userProfile: { customerNumber }
      },
      cart: {
        cartDetails: { defaultCart }
      }
    } = context;
    const skus = products.map(product => product.pid);

    const { selectedBranch } = defaultCart;
    const body: ProductPriceRequest = {
      customerNumber,
      branchNumber: selectedBranch.code,
      skus
    };
    try {
      const { data } = await priceCatalog(body);
      setPrices(data.items);
    } catch (err) {
      console.error(err);
      setPrices([]);
    }
  };

  const fetchInventory = async () => {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { findBranch },
      user: {
        userProfile: { customerNumber }
      }
    } = context;
    const {
      cortexApi: { scope }
    } = config;

    const skus = productsWithDetails.map(product => product.pid);
    const skusMotili = skus && skus.join("|");
    const { selectedBranch } = defaultCart;
    const { latitude, longitude } = findBranch(selectedBranch.code);

    try {
      let inventoryAvailability: Array<InventoryAvailabilityInterface>;
      if (scope === "motili") {
        const { data } = await getAvailabilityMotili(
          skusMotili,
          latitude,
          longitude
        );
        inventoryAvailability = formatInventoryAvailability(data);
      } else {
        const branchNumber = selectedBranch.code;

        const { data } = await getAvailabilityDGA(
          customerNumber,
          skus,
          branchNumber
        );
        inventoryAvailability = formatDGAInventory(data);
      }

      setInventory(inventoryAvailability);
    } catch (err) {
      console.error(err);
      setInventory([]);
    }
  };

  const fetchEntitlement = async () => {
    const {
      user: {
        userProfile: { customerNumber }
      }
    } = context;
    const skus = productsWithDetails.map(product => product.pid).join(",");
    try {
      const {
        data
      }: { data: Array<EntitlementDetails> } = await checkEntitlementSku(
        customerNumber,
        skus
      );
      setEntitlements(data);
    } catch (err) {
      console.error(err);
      setEntitlements([]);
    }
  };

  const updateProductDetails = details => {
    const result = productsWithDetails.map(product => {
      const productDetails =
        details.find(detail => detail.sku === product.pid) || {};
      return {
        ...product,
        productPrice: product.productPrice || productDetails.total || 0,
        entitled: product.entitled || productDetails.entitled || false,
        branchAvailability:
          product.branchAvailability || productDetails.branchAvailability || 0,
        regionAvailability:
          product.regionAvailability || productDetails.regionAvailability || 0
      };
    });
    setProductsWithDetails(result);
  };

  const onAddToCart = e => {
    const code = e.target.id;
    const items = [
      {
        code,
        quantity: 1
      }
    ];

    setAddCartButtonsIds(addCartButtonIds.concat(code));

    const {
      cart: {
        setSuccesCartPopupMessage,
        setErrorCartPopupMessage,
        getCartDetails,
        cartDetails: {
          defaultCart: { addItemsToCart }
        }
      }
    } = context;

    addToCart(addItemsToCart.self.uri, { items })
      .then(() => getCartDetails())
      .then(() => {
        setSuccesCartPopupMessage(1);
        setAddCartButtonsIds(prevState =>
          prevState.filter(buttonId => buttonId !== code)
        );
        trackWidgetAddToCartEvent(code);
      })
      .catch(err => {
        console.error(err);
        setAddCartButtonsIds(prevState =>
          prevState.filter(buttonId => buttonId !== code)
        );
        setErrorCartPopupMessage(generateSpecificErrorMessage(err));
      });
  };

  const trackWidgetViewEvent = () => {
    const {
      widget: { rid, id, type }
    } = metadata;
    const widgetWiewData = {
      wrid: rid,
      wid: id,
      wty: type
    };

    if (typeof BrTrk !== "undefined")
      BrTrk.getTracker().logEvent(
        "widget",
        "widget-view",
        widgetWiewData,
        true
      );
  };

  const trackWidgetClickEvent = (item: SingleProduct) => {
    const {
      widget: { rid, id, type }
    } = metadata;
    const widgetWiewData = {
      wrid: rid,
      wid: id,
      wty: type,
      item_id: item.pid
    };
    if (typeof BrTrk !== "undefined")
      BrTrk.getTracker().logEvent(
        "widget",
        "widget-click",
        widgetWiewData,
        true
      );
  };

  const trackWidgetAddToCartEvent = (pid: string) => {
    const {
      widget: { rid, id, type }
    } = metadata;

    const widgetAtcData = {
      wrid: rid,
      wid: id,
      wty: type,
      item_id: pid,
      sku: pid
    };
    if (typeof BrTrk !== "undefined")
      BrTrk.getTracker().logEvent("cart", "widget-add", widgetAtcData);
  };

  return (
    <div className="cart-recommended-products-widget">
      <h1 className="section-title bullet">
        {intl.get("customers-also-purchased")}
      </h1>
      {productsWithDetails.length && (
        <MultipleItemsCarousel
          items={productsWithDetails}
          numOfItems={productsWithDetails.length}
          carouselConfig={config["cart-recommended-products-carousel"]}
          displayComponent={item => (
            <SearchResultsItem
              item={item}
              onAddToCart={onAddToCart}
              key={item.pid}
              clickedButtonLoading={addCartButtonIds.includes(item.pid)}
              onClick={clickedItem => trackWidgetClickEvent(clickedItem)}
            />
          )}
        />
      )}
    </div>
  );
};

export default CartRecommendedProductsWidget;
