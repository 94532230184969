import React from "react";
import intl from "react-intl-universal";
import { Redirect } from "react-router-dom";

import { generateSpecificErrorMessage } from "@elasticpath/ref-store/src/utils/helpers";
import { addToCart } from "@elasticpath/ref-store/src/services/EpServices";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { productAdded } from "../utils/Segment";
import { getConfig } from "../utils/ConfigProvider";

import "./AddProductToCartComponent.less";

/**
 *
 * This is rendered if user is logged in and entitled for SKU.
 */

interface AddProductToCartProps {
  productId: string;
  productData: any;
  skuEntitled: any;
  itemPriceDetails: any;
  branchAvailability: any;
  regionAvailability: any;
  validQuantity: boolean;
  populateitemQuantity: (...args) => any;
}
interface AddProductToCartState {
  itemQuantity: number;
  showLoader: boolean;
}

class AddProductToCartComponent extends React.Component<
  AddProductToCartProps,
  AddProductToCartState
> {
  static contextType = MainContext;

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      itemQuantity: 1,
      showLoader: false
    };

    this.addToCart = this.addToCart.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleQuantityDecrement = this.handleQuantityDecrement.bind(this);
    this.handleQuantityIncrement = this.handleQuantityIncrement.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * ## addToCart
   * @param event
   *
   * @description  Adds products to cart and sets cart context.
   */
  addToCart(event): void {
    const { itemQuantity } = this.state;
    const { productId, productData, itemPriceDetails } = this.props;
    const {
      cart: {
        cartDetails: {
          defaultCart: { addItemsToCart }
        },
        getCartDetails,
        setSuccesCartPopupMessage,
        setErrorCartPopupMessage
      }
    } = this.context;

    const items = [
      {
        code: productId,
        quantity: itemQuantity
      }
    ];

    this.setState({
      showLoader: true
    });

    // Populate Cart with the item.
    addToCart(addItemsToCart.self.uri, { items })
      .then(() => {
        const name = productData._definition[0]["display-name"];
        const id = productData._code[0].code;
        const category = productData._parentcategory[0].name;
        const price =
          !itemPriceDetails ||
          !itemPriceDetails.total ||
          itemPriceDetails.total === intl.get("pending")
            ? 0
            : itemPriceDetails.total;
        const brand = productData._definition[0].details.find(
          field => field.name === "brand"
        ).value;

        // sends information to Segment when user adds a product
        productAdded(name, id, price, brand, category, itemQuantity);
      })
      .then(() => {
        return getCartDetails();
      })
      .then(() => {
        setSuccesCartPopupMessage(itemQuantity);
        if (this._isMounted) {
          this.setState({
            showLoader: false
          });
        }
      })
      .catch(err => {
        console.error(err);
        if (this._isMounted) {
          this.setState({
            showLoader: false
          });
          setErrorCartPopupMessage(generateSpecificErrorMessage(err));
        }
      });

    event.preventDefault();
  }

  handleQuantityChange(event): void {
    const { populateitemQuantity } = this.props;

    if (event.target.value === "") {
      this.setState({ itemQuantity: 1 });
      populateitemQuantity(1);
    } else {
      this.setState({ itemQuantity: parseInt(event.target.value, 10) });
      populateitemQuantity(parseInt(event.target.value, 10));
    }
  }

  handleQuantityDecrement(): void {
    const { populateitemQuantity } = this.props;
    const { itemQuantity } = this.state;

    if (itemQuantity > 1) {
      const newItemQuantity = itemQuantity - 1;
      this.setState({ itemQuantity: newItemQuantity });
      populateitemQuantity(newItemQuantity);
    }
  }

  handleQuantityIncrement(): void {
    const { populateitemQuantity } = this.props;

    const { itemQuantity } = this.state;
    const newItemQuantity = itemQuantity + 1;
    this.setState({ itemQuantity: newItemQuantity });
    populateitemQuantity(newItemQuantity);
  }

  render() {
    const { itemQuantity, showLoader } = this.state;
    const {
      productData,
      itemPriceDetails,
      skuEntitled,
      branchAvailability,
      regionAvailability,
      validQuantity
    } = this.props;
    const {
      cart: { cartError }
    } = this.context;

    const { config } = getConfig();

    const isPriceZero = itemPriceDetails && `${itemPriceDetails.total}` === "0";

    if (cartError) {
      return (
        <Redirect
          to={{
            pathname: "/maintenance",
            state: {
              error: {
                e: { message: cartError },
                errIn: "AddToProductCartComponent.tsx"
              }
            }
          }}
        />
      );
    }

    return (
      <form
        className="itemdetail-addtocart-form form-horizontal"
        onSubmit={event => {
          this.addToCart(event);
        }}
      >
        <div className="form-group qty-selector">
          <div className="input-group-btn">
            <button
              type="button"
              aria-label="subtract one item from your cart"
              className="quantity-left-minus btn btn-number"
              data-type="minus"
              data-field=""
              onClick={this.handleQuantityDecrement}
            >
              <span className="icon-minus" />
            </button>
            <div className="quantity-col form-content form-content-quantity">
              <input
                id="product_display_quantity_field"
                aria-label="item quantity"
                className="product-display-item-quantity-select form-control form-control-quantity"
                type="number"
                step="1"
                min="1"
                max="9999"
                value={itemQuantity}
                onChange={this.handleQuantityChange}
              />
            </div>
            <button
              type="button"
              aria-label="add one item from your cart"
              className="quantity-right-plus btn btn-number"
              data-type="plus"
              data-field=""
              onClick={this.handleQuantityIncrement}
            >
              <span className="icon-plus" />
            </button>
          </div>
        </div>
        <div className="form-group-submit">
          <div className="form-content form-content-submit col-sm-offset-4">
            {showLoader ? (
              <div className="miniLoader" />
            ) : (
              <button
                aria-label={intl.get("add-to-cart")}
                className="ep-btn primary wide btn-itemdetail-addtocart"
                disabled={
                  (productData && !productData._addtocartform) ||
                  (config.calculatePrice &&
                    (!itemPriceDetails || isPriceZero)) ||
                  !skuEntitled ||
                  (config.checkAvailability &&
                    !branchAvailability &&
                    !regionAvailability) ||
                  !validQuantity
                }
                id="product_display_item_add_to_cart_button"
                type="submit"
              >
                <span>
                  {(config.calculatePrice && isPriceZero) ||
                  !skuEntitled ||
                  (config.checkAvailability &&
                    !branchAvailability &&
                    !regionAvailability &&
                    !validQuantity)
                    ? intl.get("call")
                    : intl.get("add-to-cart")}
                </span>
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default AddProductToCartComponent;
