import React from "react";
import { withRouter } from "react-router-dom";
import intl from "react-intl-universal";
import DOMPurify from "dompurify";

import "./WarrantyLookupPage.less";
import { Input } from "@zilker/store-components";
import { pushToMaintenace } from "../utils/helpers";
import {
  getWarrantyEntitlements,
  getWarrantyModels
} from "../services/connectServices";

interface WarrantyLookupProps {
  match: any;
  history: any;
}

interface entitlementsInterface {
  serialNumber: string;
  model: string;
  description: string;
  registrationDate: string;
  manufactureDate: string;
  installDate: string;
  lastName: string;
  lastNameMatches: string;
  desktopText: any;
  mobileText: any;
  terms: string;
}

interface WarrantyLookupState {
  serialNumber: string;
  equipmentModel: any;
  installType: any;
  lastName: string;
  loadingModels: boolean;
  loadingEntitlements: boolean;
  models: string[];
  entitlements: entitlementsInterface;
  width: number;
  invalidSerial: boolean;
}

class WarrantyLookupPage extends React.Component<
  WarrantyLookupProps,
  WarrantyLookupState
> {
  constructor(props) {
    super(props);

    this.state = {
      serialNumber: "",
      equipmentModel: undefined,
      installType: "RES",
      lastName: "",
      loadingModels: false,
      loadingEntitlements: false,
      models: [],
      entitlements: undefined,
      width: undefined,
      invalidSerial: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.removeWarrantyInformation = this.removeWarrantyInformation.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  async handleOnBlur(e) {
    try {
      const { serialNumber, invalidSerial } = this.state;

      if (invalidSerial) {
        this.setState({ invalidSerial: false });
      }

      if (!serialNumber.trim()) {
        return;
      }

      this.setState({ loadingModels: true });

      let modelsResponse: Array<any> = [];
      try {
        const { data } = await getWarrantyModels(
          encodeURIComponent(serialNumber)
        );
        modelsResponse = data;
      } catch (error) {
        this.setState({ invalidSerial: true, loadingModels: false });
        return;
      }

      if (!modelsResponse.length) {
        this.setState({ invalidSerial: true, loadingModels: false });
        return;
      }
      const models = modelsResponse.map(model => {
        return model.model;
      });
      this.setState({ models, loadingModels: false });
    } catch (err) {
      const { history } = this.props;
      this.setState({ loadingModels: false }, () => {
        pushToMaintenace(history, {
          e: err,
          errIn: "handleOnBlur => WarrantyLookupPage.tsx"
        });
      });
    }
  }

  createSelectOptions() {
    const { models } = this.state;
    if (!models.length) return null;
    const modelsOptions = models.map(model => {
      return (
        <option key={model} value={model}>
          {model}
        </option>
      );
    });
    return (
      <>
        <option value="" disabled selected>
          {intl.get("please-select-model")}
        </option>

        {modelsOptions}
      </>
    );
  }

  setEntitlementsResponse(entitlementsResponse) {
    const {
      serialNumber,
      model,
      description,
      registrationDate,
      manufactureDate,
      installDate,
      lastName,
      lastNameMatches,
      desktopText,
      mobileText,
      terms
    } = entitlementsResponse;

    this.setState({
      entitlements: {
        serialNumber,
        model,
        description,
        registrationDate,
        manufactureDate,
        installDate,
        lastName,
        lastNameMatches,
        desktopText,
        mobileText,
        terms
      },
      serialNumber: "",
      lastName: "",
      equipmentModel: undefined,
      installType: undefined,
      loadingEntitlements: false
    });
  }

  async submitForm(e) {
    try {
      e.preventDefault();
      this.setState({ loadingEntitlements: true });
      const {
        serialNumber,
        equipmentModel,
        installType,
        lastName
      } = this.state;

      if (!serialNumber || !equipmentModel) {
        return;
      }

      const { data: entitlementsResponse } = await getWarrantyEntitlements(
        serialNumber,
        equipmentModel,
        installType,
        lastName
      );

      this.setEntitlementsResponse(entitlementsResponse);
    } catch (err) {
      const { history } = this.props;
      this.setState({ loadingEntitlements: false }, () => {
        pushToMaintenace(history, {
          e: err,
          errIn: "submitForm => WarrantyLookupPage.tsx"
        });
      });
    }
  }

  renderWarrantyLookupForm() {
    const {
      serialNumber,
      lastName,
      loadingModels,
      loadingEntitlements,
      invalidSerial
    } = this.state;
    return (
      <form className="warranty-lookup-form" onSubmit={this.submitForm}>
        <h2 className="section-title bullet">{intl.get("warranty-lookup")}</h2>
        <Input
          label={intl.get("serial-number")}
          type="text"
          inputName="serialNumber"
          value={serialNumber}
          ariaLabel="serialNumber"
          inputHandler={this.handleInputChange}
          required
          onBlurHandler={this.handleOnBlur}
        />
        {loadingModels && <div className="miniLoader" />}
        {invalidSerial && (
          <div className="invalid-serial">
            {intl.get("select-valid-serial")}
          </div>
        )}

        <div className="input-component">
          <p className="label">
            <span>*</span>
            {intl.get("equipment-model")}
          </p>
          <select
            name="equipmentModel"
            required
            onChange={this.handleInputChange}
          >
            {this.createSelectOptions()}
          </select>
        </div>
        <div className="input-component">
          <p className="label">{intl.get("install-type")}</p>
          <select name="installType" onChange={this.handleInputChange}>
            <option value="RES">{intl.get("residential")}</option>
            <option value="COM">{intl.get("commercial")}</option>
            <option value="APT">{intl.get("apartment")}</option>
            <option value="MFY">{intl.get("multi-family")}</option>
            <option value="NCN">{intl.get("new-construction")}</option>
          </select>
        </div>
        <Input
          label={intl.get("last-name")}
          type="text"
          inputName="lastName"
          value={lastName}
          ariaLabel="lastName"
          inputHandler={this.handleInputChange}
        />
        {loadingEntitlements ? (
          <div className="loader" />
        ) : (
          <button className="ep-btn primary" type="submit">
            {intl.get("search")}
          </button>
        )}
      </form>
    );
  }

  createCoverageTables(desktopText, mobileText) {
    const { width } = this.state;
    const tableResource = width > 768 ? desktopText : mobileText;
    const { standard, registered, extended } = tableResource;
    return (
      <>
        {standard !== "&nbsp;" ? (
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(standard) }}
          />
        ) : null}
        {registered !== "&nbsp;" ? (
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(registered) }}
          />
        ) : null}
        {extended !== "&nbsp;" ? (
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(extended) }}
          />
        ) : null}
      </>
    );
  }

  renderWarrantyInformation() {
    const {
      entitlements: {
        serialNumber,
        model,
        description,
        registrationDate,
        manufactureDate,
        installDate,
        lastName,
        lastNameMatches,
        desktopText,
        mobileText,
        terms
      }
    } = this.state;

    return (
      <div className="warranty-information">
        <h4 className="section-title bullet">{intl.get("warranty-lookup")}</h4>
        <div className="warranty-info-main">
          <h5>{intl.get("warranty-information")}</h5>
          <p>
            {intl.get("serial-number")}: {serialNumber || null}
          </p>
          <p>
            {intl.get("model")}: {model}
          </p>
          <p>
            {intl.get("model-desc")}: {description || null}
          </p>
          <p>
            {intl.get("reg-date")}:{" "}
            {registrationDate
              ? new Date(registrationDate).toLocaleDateString("en-US")
              : null}
          </p>
          <p>
            {intl.get("mfg-date")}:{" "}
            {manufactureDate
              ? new Date(manufactureDate).toLocaleDateString("en-US")
              : null}
          </p>
          <p>
            {intl.get("install-date")}:{" "}
            {installDate
              ? new Date(installDate).toLocaleDateString("en-US")
              : null}
          </p>
        </div>

        <div className="standard-coverage">
          {this.createCoverageTables(desktopText, mobileText)}
        </div>
        <div className="warranty-additional-info">
          <div dangerouslySetInnerHTML={{ __html: terms }} />
        </div>
        <button
          className="ep-btn primary"
          type="button"
          onClick={this.removeWarrantyInformation}
        >
          {intl.get("search")}
        </button>
      </div>
    );
  }

  removeWarrantyInformation(e) {
    this.setState({
      entitlements: undefined,
      models: [],
      installType: "RES"
    });
  }

  render() {
    const { entitlements } = this.state;

    return (
      <div className="warranty-lookup container-fluid d-flex flex-column flex-grow-1">
        <div className="container d-flex flex-column flex-grow-1">
          <div className="centralization-container">
            <div className="warranty-lookup-container">
              <div className="warrenty-lookup">
                {entitlements
                  ? this.renderWarrantyInformation()
                  : this.renderWarrantyLookupForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(WarrantyLookupPage);
