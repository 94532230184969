import React from "react";
import jsonpointer from "jsonpointer";
import {
  getImageUrl,
  parseDate,
  parseAndRewriteLinks,
  getNestedObject
} from "bloomreach-experience-react-sdk";

import "./rich-text.less";

const RichText = props => {
  const { configuration, pageModel, preview } = props;
  const ref = getNestedObject(configuration, ["models", "document", "$ref"]);
  const params = getNestedObject(configuration, ["_meta", "params"]);

  let content = null;
  if (ref) {
    content = jsonpointer.get(pageModel, ref);
  }

  let contentHtml;
  if (content && content.content.value) {
    contentHtml = parseAndRewriteLinks(content.content.value, preview);
  }

  return <div className="rich-text-container container">{contentHtml}</div>;
};

export default RichText;
