import React, { FC, useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";
import { History } from "history";
import {
  SavedOrderDetailComponent,
  page,
  productAdded,
  getConfig,
  cortexFetch,
  zoom,
  AlternateBranchList,
  Messagecontainer
} from "@zilker/store-components";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace,
  generateSpecificErrorMessage,
  groupProsItems,
  InventoryAvailabilityInterface,
  formatInventoryAvailability,
  formatDGAInventory,
  formatAlternateBranchesInventory
} from "../utils/helpers";
import { MainContext } from "../contexts/MainContext";

import { addToCart, updateProsGroupQuantity } from "../services/EpServices";
import {
  getAvailability,
  getAvailabilityDGA
} from "../services/connectServices";
import "./SavedOrderDetails.less";

type summaryInfo = {
  amount: number;
  currency: string;
  display: string;
};

interface SavedOrderDetailsProps {
  orderName: string;
  link: string;
  history: History;
  logout: any;
}

interface CartDetailInterface {
  self: { type: string; uri: string; href: string };
  messages: [];
  _item: any[];
  _price: any[];
  _total: any[];
  configuration: {};
  quantity: number;
  branchAvailability?: number;
  regionAvailabiliy?: number;
}

interface OrderSummaryInterface {
  subTotal: summaryInfo;
  total: summaryInfo;
  tax: summaryInfo;
}

const SavedOrderDetails: FC<SavedOrderDetailsProps> = ({
  orderName,
  link,
  history,
  logout
}) => {
  const [cartData, setCartData] = useState<CartDetailInterface[]>([]);
  const [orderSummary, setOrderSummary] = useState<OrderSummaryInterface>();
  const [loading, setLoading] = useState<boolean>(true);
  const [miniLoader, setMiniLoader] = useState<boolean>(false);
  const [qtyChangeDisabled, setQtyChangeDisabled] = useState<boolean>(false);
  const [removeItemDisabled, setRemoveItemDisabled] = useState<boolean>(false);
  const [savedOrder, setSavedOrder] = useState<any>(null);
  const [invalidSku, setInvalidSku] = useState<string>("");
  const [invalidSkuError, setInvalidSkuError] = useState<string>("");
  const [groupedItems, setGroupedItems] = useState<any>({});
  const [activeGroups, setActiveGroups] = useState<Array<string>>([]);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [alternateBranches, setAlternateBranches] = useState<Array<any>>([]);
  const [branch, setBranch] = useState<{ code: number; vendor: string }>(null);
  const [inventoryError, setInventoryError] = useState<string>("");

  const context = useContext<{ cart: any; user: any; branches: any }>(
    MainContext
  );
  const {
    cart: {
      setCartDetails,
      setSuccesCartPopupMessage,
      setErrorCartPopupMessage,
      getCartDetails,
      cartDetails: { defaultCart, isCartUpdated },
      checkCartWarning
    },
    user: {
      userProfile: { customerNumber, customerRoles }
    },
    branches: { branchesList, findBranch }
  } = context;
  const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
  const BRANCHES_VIRTUAL = intl.get("virtual-branches");
  const isVirtualBranchUser =
    customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

  const { config } = getConfig();
  const { defaultChannel } = config.brXM;
  const isMotili = defaultChannel === "motili";

  const addDefaultCart = () => {
    const items = cartData.map(({ _item, quantity }) => ({
      code: _item[0]._code[0].code,
      quantity
    }));

    const { addItemsToCart } = defaultCart;

    return addToCart(addItemsToCart.self.uri, { items });
  };

  const addCartToDefaultCart = () => {
    const { _addcarttodefaultcartform } = savedOrder;
    const addToDefaultCartLink = _addcarttodefaultcartform[0].links[0].uri;

    return cortexFetch(addToDefaultCartLink, {
      method: "post",
      body: JSON.stringify({})
    })
      .then(res => {
        const onSuccess = data => data;
        const onError = data => {
          if (!data.ok) {
            return data.json().then(json => {
              throw json;
            });
          }
          throw data;
        };
        return checkResponse(res, onSuccess, onError);
      })
      .catch(err => {
        throw err;
      });
  };

  const addItemToDefaultCart = (foundItem: CartDetailInterface[]) => {
    const items = foundItem.map(({ _item, quantity }) => ({
      code: _item[0]._code[0].code,
      quantity
    }));

    const { addItemsToCart } = defaultCart;
    return addToCart(addItemsToCart.self.uri, { items });
  };

  const handleAddToCart = () => {
    setMiniLoader(true);

    const analyticsItems = cartData.map(element => {
      return {
        id: element._item[0]._code[0].code,
        quantity: element.quantity,
        category: element._item[0]._definition[0].details.find(
          detail => detail.name.toLowerCase() === "product_category"
        ).value,
        name: element._item[0]._definition[0]["display-name"],
        brand: element._item[0]._definition[0].details.find(
          detail => detail.name === "brand"
        ).value,
        price: element._price[0]["list-price"][0].amount
      };
    });

    const itemQuantity = cartData.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);

    const addToCartFn =
      orderName === intl.get("default-cart")
        ? addDefaultCart
        : addCartToDefaultCart;

    addToCartFn()
      .then(() => {
        // sends information to Segment for every product user adds
        analyticsItems.forEach(item =>
          productAdded(
            item.name,
            item.id,
            item.price,
            item.brand,
            item.category,
            item.quantity
          )
        );
      })
      .then(() => {
        return getCartDetails();
      })
      .then(() => {
        setMiniLoader(false);
        setSuccesCartPopupMessage(itemQuantity);
      })
      .catch(e => {
        setMiniLoader(false);

        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleAddToCart => SavedOrderDetails.tsx"
            })
          );
        } else {
          setErrorCartPopupMessage(generateSpecificErrorMessage(e));
        }
      });
  };

  const handleAddSingleItemTypeToCart = (
    sku: string,
    setReorderSpinner: (value: React.SetStateAction<boolean>) => void
  ) => {
    setReorderSpinner(true);
    const foundItem = cartData.filter(
      item => item._item[0]._code[0].code === sku
    );

    if (foundItem.length > 0) {
      const analyticsItems = {
        id: foundItem[0]._item[0]._code[0].code,
        quantity: foundItem[0].quantity,
        category: foundItem[0]._item[0]._definition[0].details.find(
          detail => detail.name.toLowerCase() === "product_category"
        ).value,
        name: foundItem[0]._item[0]._definition[0]["display-name"],
        brand: foundItem[0]._item[0]._definition[0].details.find(
          detail => detail.name === "brand"
        ).value,
        price: foundItem[0]._price[0]["list-price"][0].amount
      };

      const itemQuantity = foundItem[0].quantity;
      addItemToDefaultCart(foundItem)
        .then(() => {
          // sends information to Segment
          productAdded(
            analyticsItems.name,
            analyticsItems.id,
            analyticsItems.price,
            analyticsItems.brand,
            analyticsItems.category,
            analyticsItems.quantity
          );
        })
        .then(() => {
          return getCartDetails();
        })
        .then(() => {
          setReorderSpinner(false);
          setSuccesCartPopupMessage(itemQuantity);
        })
        .catch(e => {
          setReorderSpinner(false);
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn:
                  "Logout => handleAddSingleItemTypeToCart => SavedOrderDetails.tsx"
              })
            );
          } else {
            setErrorCartPopupMessage(generateSpecificErrorMessage(e));
          }
        });
    }
    setReorderSpinner(false);
  };

  const disableChangesFromItem = (val: boolean): void => {
    setQtyChangeDisabled(!!invalidSku || val);
    setRemoveItemDisabled(val);
  };

  /**
   * @description Function to retrieve the single saved order details, using the order ID
   * ( part of the link variable). Saved order details include order name, total, order items -
   *  with price and quantity.
   */
  const getSavedOrderDetails = () => {
    return cortexFetch(
      `/${link}?zoom=${zoom.zoomFetchSavedOrder.sort().join()}`
    )
      .then(res => checkResponse(res))
      .then(savedOrderResponse => {
        setSavedOrder(checkCartWarning(savedOrderResponse));
      })
      .catch(err => {
        if (checkTokensExpired(err)) {
          logout().catch(logoutErr =>
            pushToMaintenace(history, {
              e: logoutErr,
              errIn: "Logout => getSavedOrderDetails => SavedOrderDetails.tsx"
            })
          );
        } else {
          console.error(err);
          pushToMaintenace(history, {
            e: err,
            errIn: "getSavedOrderDetails => SavedOrderDetails.tsx"
          });
        }
      });
  };

  const findAvailabilityService = (orderItems, currentBranch) => {
    const { clientId } = defaultCart;
    const { code, vendor } = currentBranch;
    const { latitude, longitude } = findBranch(code);
    const skusArr = orderItems.map(({ _item }) => _item[0]._code[0].code);

    if (!isMotili) {
      const needDetails = true;
      return getAvailabilityDGA(
        customerNumber,
        skusArr,
        code.toString(),
        needDetails
      );
    }
    const skusString = skusArr.join("|");
    return getAvailability(
      customerNumber,
      code.toString(),
      skusString,
      vendor,
      latitude,
      longitude,
      true,
      clientId
    );
  };

  const fetchAvailability = async (orderItems, currentBranch) => {
    if (branchesList && orderItems) {
      const { data } = await findAvailabilityService(orderItems, currentBranch);
      let inventoryAvailability: InventoryAvailabilityInterface[];
      let altBranchData = data;
      if (!isMotili) {
        if (data.error || !data.result.itemAvailability) {
          console.error("Inventory error");
          setInventoryError(intl.get("inventory-error"));
        } else {
          altBranchData = data.result;
          const needDetails = true;
          inventoryAvailability = formatDGAInventory(data, needDetails);
          setInventoryError("");
        }
      } else {
        inventoryAvailability = formatInventoryAvailability(data);
      }

      if (isMotili || (!isMotili && altBranchData.itemAvailability)) {
        const itemsWithAvailability = orderItems.map(item => {
          const inventoryItem = inventoryAvailability.find(
            ia => ia.sku === item._item[0]._code[0].code
          );
          return inventoryItem
            ? {
                ...item,
                branchAvailability: inventoryItem.branchAvailability,
                regionAvailability: inventoryItem.regionAvailability
              }
            : {
                ...item,
                branchAvailability: 0,
                regionAvailability: 0
              };
        });
        setCartData(itemsWithAvailability);
        setGroupedItems(groupProsItems(itemsWithAvailability));
        setAlternateBranches(formatAlternateBranchesInventory(altBranchData));
      }
    }
  };

  const populateSavedOrderData = () => {
    let subTotal;
    let total;
    let tax;
    let errorMessage = "";
    const errPriceObj = {
      display: intl.get("pending"),
      amount: intl.get("pending"),
      currency: ""
    };
    // If messages[0] exists that means there is some pricing error and we will display error message and "pending" in that case
    if (
      savedOrder._total &&
      savedOrder._total[0].messages &&
      savedOrder._total[0].messages[0] &&
      savedOrder._total[0].messages[0].type === "error"
    ) {
      subTotal = errPriceObj;
      total = errPriceObj;
      tax = errPriceObj;
      const { sku } = savedOrder._total[0].messages[0].data;
      setInvalidSku(sku);
      setQtyChangeDisabled(true);
      errorMessage = intl.get("invalid-sku-error-message", {
        sku
      });
    } else {
      try {
        [subTotal] = savedOrder._total[0].cost;
        [total] = savedOrder._order[0]._total[0].cost;
        tax = savedOrder._order[0]._tax[0].total;
      } catch (e) {
        console.error(e);
        subTotal = errPriceObj;
        total = errPriceObj;
        tax = errPriceObj;
      }
      setInvalidSku("");
      setQtyChangeDisabled(false);
    }
    setOrderSummary({ subTotal, total, tax });
    setCartData(savedOrder._lineitems[0]._element);
    const groupedProsItems = groupProsItems(savedOrder._lineitems[0]._element);
    setGroupedItems(groupedProsItems);
    setActiveGroups(Object.keys(groupedProsItems));
    setLoading(false);
    setInvalidSkuError(errorMessage);
    fetchAvailability(savedOrder._lineitems[0]._element, selectedBranch);
    setBranch(selectedBranch);
  };

  const handleGroupItemsUpdate = (
    prosId: string,
    quantity: string,
    setLoader: any
  ) => {
    const cartId = link.split("/")[2];
    const prosItems = groupedItems[prosId];
    updateProsGroupQuantity(prosItems, quantity, cartId)
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(() => {
        return orderName === intl.get("default-cart")
          ? getCartDetails()
          : getSavedOrderDetails();
      })
      .then(() => {
        setLoader(false);
        disableChangesFromItem(false);
      })
      .catch(e => {
        setLoader(false);
        disableChangesFromItem(false);
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleGroupItemsUpdate => SavedOrderDetails.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "handleGroupItemsUpdate => SavedOrderDetails.tsx"
          });
        }
      });
  };

  useEffect(() => {
    page();
    getSavedOrderDetails();
  }, []);

  useEffect(() => {
    if (orderName === intl.get("default-cart")) {
      if (
        (defaultCart && (cartData && !cartData.length)) ||
        isCartUpdated ||
        (branch && branch.code !== selectedBranch.code)
      ) {
        setCartData(defaultCart.items);
        const groupedProsItems = groupProsItems(defaultCart.items);
        setGroupedItems(groupedProsItems);
        setActiveGroups(Object.keys(groupedProsItems));
        const subTotal = defaultCart.total;
        const total = defaultCart.orderCost;
        const tax = defaultCart.orderTax;
        setOrderSummary({ subTotal, total, tax });
        setLoading(false);
        setCartDetails(prevState => ({
          ...prevState,
          isCartUpdated: false
        }));
        fetchAvailability(defaultCart.items, selectedBranch);
        setBranch(selectedBranch);
      }
    } else if (
      (savedOrder && !cartData.length) ||
      (branch && branch.code !== selectedBranch.code)
    ) {
      populateSavedOrderData();
    }
  });

  useEffect(() => {
    if (orderName !== intl.get("default-cart")) {
      if (savedOrder) {
        populateSavedOrderData();
      }
    }
  }, [savedOrder]);

  useEffect(() => {
    if (!invalidSku) setQtyChangeDisabled(false);
  }, [invalidSku]);

  useEffect(() => {
    if (branchesList && cartData && cartData.length && branch) {
      fetchAvailability(cartData, branch);
    }
  }, [branchesList]);

  const toggleActiveGroup = prosId => {
    const group = activeGroups.find(activeGroup => activeGroup === prosId);
    const updatedGroups = group
      ? activeGroups.filter(activeGroup => activeGroup !== prosId)
      : [...activeGroups, prosId];
    setActiveGroups(updatedGroups);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const openModal = () => {
    setIsModalOpened(true);
  };

  const renderAlternateBranchesModal = () => {
    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%",
        height: "650px"
      }
    };

    return (
      <Modal
        open={isModalOpened}
        onClose={closeModal}
        styles={{
          modal: styles.modal
        }}
      >
        <div>
          <AlternateBranchList
            orderInfo={{
              orderName,
              orderItemsLength: cartData.length
            }}
            products={cartData.map(
              ({ _item, quantity, branchAvailability }) => ({
                sku: _item[0]._code[0].code,
                qtyEntered: quantity,
                qtyAvailable: branchAvailability
              })
            )}
            qtyColumnHeader={intl.get("stock-status")}
            history={history}
            branches={alternateBranches}
            showCustomErrorMessage={false}
            descriptiveAvailability
          />
        </div>
      </Modal>
    );
  };

  const renderWarningMessage = () => {
    const hasError =
      savedOrder &&
      savedOrder.messages[0] &&
      savedOrder.messages[0].type === "warning";

    const openChat = () => window.zE.activate();

    if (hasError) {
      return (
        <Messagecontainer
          message={{
            type: "danger-message",
            debugMessages: intl.get("price-calculation-error"),
            isMsgEmphasized: true
          }}
          closeContainerHandler={null}
          stayOpen
          button={{
            text: intl.get("support"),
            action: openChat,
            className: "support-btn"
          }}
        />
      );
    }
    if (inventoryError) {
      return (
        <Messagecontainer
          message={{
            type: "basic",
            debugMessages: inventoryError
          }}
          closeContainerHandler={null}
        />
      );
    }
    return null;
  };

  if (loading) return <div className="loader" />;

  return (
    <>
      <div className="saved-order-details content-box content-table">
        {savedOrder && cartData ? renderAlternateBranchesModal() : null}
        <h2 className={`saved-order-title ${isMotili ? "bullet" : ""}`}>
          <NavLink to="/myAccount/savedOrders" className="back-button">
            <span>{intl.get("back")}</span>
          </NavLink>
          <span className="title">{orderName}</span>
          {!isVirtualBranchUser && (
            <button
              type="button"
              className="availability-button"
              onClick={openModal}
            >
              <i className="icon-home" />
              {intl.get("check-order-availability")}
            </button>
          )}
        </h2>

        <p className="cart-error-message">{invalidSkuError}</p>
        {renderWarningMessage()}
        <div className="cards-headers table-labels">
          <div className="card-header-product label">
            {`${intl.get("product")}${isMotili ? ":" : ""}`}
          </div>
          {config.calculatePrice && (
            <div className="card-header-price label">{intl.get("price")}</div>
          )}
          <div className="card-header-quantity label">
            {`${intl.get("quantity")}${isMotili ? ":" : ""}`}
          </div>
          <div className="card-header-availability label">
            {intl.get("availability")}
          </div>
          {config.calculatePrice && (
            <div className="card-header-ext-price label">
              {intl.get("ext-price")}
            </div>
          )}
        </div>

        {cartData && cartData.length
          ? Object.keys(groupedItems)
              .reverse()
              .map(group =>
                groupedItems[group].map((item, index) => (
                  <SavedOrderDetailComponent
                    cartDetail={item}
                    key={`${item._item[0]._code[0].code} ${group || ""}`}
                    setIsChangeDisabled={disableChangesFromItem}
                    qtyChangeDisabled={qtyChangeDisabled}
                    removeItemDisabled={removeItemDisabled}
                    refreshCartDetails={
                      orderName === intl.get("default-cart")
                        ? // Refresh the default cart.
                          getCartDetails
                        : // Refresh single saved order.
                          getSavedOrderDetails
                    }
                    hasError={item._item[0]._code[0].code === invalidSku}
                    prosId={group}
                    first={index === 0}
                    handleGroupItemsUpdate={handleGroupItemsUpdate}
                    activePros={activeGroups}
                    toggleActivePros={toggleActiveGroup}
                    handleAddSingleItemTypeToCart={
                      handleAddSingleItemTypeToCart
                    }
                    inventoryError={inventoryError}
                  />
                ))
              )
          : null}
      </div>
      {orderSummary && cartData && cartData.length ? (
        <div className="saved-order-details-add-to-cart-box">
          {config.calculatePrice && (
            <div>
              <span className="saved-order-details-total-label">
                {intl.get("todays-subtotal").toUpperCase()}:
              </span>
              <span className="saved-order-details-total-value">
                {orderSummary.subTotal.display}
              </span>
            </div>
          )}
          {config.calculatePrice && (
            <div className="saved-order-details-total">
              <span className="saved-order-details-total-label">
                {intl.get("estimated-total").toUpperCase()}:
              </span>
              <span className="saved-order-details-total-value">
                {orderSummary.total.display}
              </span>
            </div>
          )}
          <div>
            {miniLoader ? (
              <div className="miniLoader" />
            ) : (
              <button
                type="button"
                onClick={handleAddToCart}
                className="ep-btn primary"
                disabled={!!invalidSkuError}
              >
                {intl.get("add-to-cart")}
              </button>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SavedOrderDetails;
