import React, { useState, useEffect, useContext } from "react";
import jsonpointer from "jsonpointer";
import { getNestedObject, getImageUrl } from "bloomreach-experience-react-sdk";
import Slider from "react-slick";

import { MainContext } from "../../../../../app/src/contexts/MainContext";
import PromotionsGridCard from "../../../GridCards/promotions.grid.card";
import EcommGridCard from "../../../GridCards/ecomm.grid.card";
import MainBannerGridCard from "../../../GridCards/mainbanner.grid.card";
import BusinessOpsGridCard from "../../../GridCards/businessops.grid.card";
import ProductTechOpsGridCard from "../../../GridCards/producttechops.gird.card";

import "./grid.less";

enum GridTypes {
  MAIN_BANNER = "main-banner",
  PROMOTION = "promotion",
  E_COMM_TOOLS = "e-comm-tools",
  PRODUCT_TECH_OPS = "product-tech-ops",
  BUSINESS_OPS = "business-ops"
}

const Grid = ({ configuration, pageModel, preview }) => {
  const [grid, setGrid] = useState(null);

  const context = useContext<{ auth: any }>(MainContext);
  const {
    auth: { isLoggedIn }
  } = context;

  useEffect(() => {
    const ref = getNestedObject(configuration, ["models", "document", "$ref"]);
    const content = ref && jsonpointer.get(pageModel, ref);
    setGrid(content);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const renderGridCard = gridCard => {
    const {
      image,
      id,
      type,
      backgroundImage,
      mobileBackgroundImage
    } = gridCard;
    const cardDesktopBackgroundImage = getImageUrl(
      backgroundImage,
      pageModel,
      preview
    );
    const cardMobileBackgroundImage = getImageUrl(
      mobileBackgroundImage,
      pageModel,
      preview
    );
    const cardImage = getImageUrl(image, pageModel, preview);
    const props = {
      gridCard: {
        ...gridCard,
        backgroundImage: cardDesktopBackgroundImage,
        mobileBackgroundImage: cardMobileBackgroundImage,
        image: cardImage
      },
      isLoggedIn,
      id
    };

    switch (type) {
      case GridTypes.MAIN_BANNER:
        return <MainBannerGridCard {...props} key={id} />;
      case GridTypes.PROMOTION:
        return <PromotionsGridCard {...props} key={id} />;
      case GridTypes.E_COMM_TOOLS:
        return <EcommGridCard {...props} key={id} />;
      case GridTypes.PRODUCT_TECH_OPS:
        return <ProductTechOpsGridCard {...props} key={id} />;
      case GridTypes.BUSINESS_OPS:
        return <BusinessOpsGridCard {...props} key={id} />;
      default:
        return null;
    }
  };

  const renderGridRow = () => {
    const {
      backgroundColor,
      backgroundImage,
      title,
      id,
      gridCards,
      titleColor,
      titleBottomBorderColor,
      gridCarousel
    } = grid;

    return (
      <div
        className={`grid-row container ${
          backgroundColor && backgroundImage && titleBottomBorderColor
            ? "grid-row-product-tech-ops"
            : ""
        }`}
        style={(() => {
          if (backgroundColor && backgroundImage && titleBottomBorderColor) {
            return {
              background: `linear-gradient(${backgroundColor}, ${backgroundColor}), url(${getImageUrl(
                backgroundImage,
                pageModel,
                preview
              )})`
            };
          }
          return {
            backgroundColor,
            backgroundImage: `url(${getImageUrl(
              backgroundImage,
              pageModel,
              preview
            )})`
          };
        })()}
        id={id}
      >
        {title && (
          <h2
            className="grid-row-title"
            style={{
              color: titleColor,
              borderBottom: `3px solid ${titleBottomBorderColor}`
            }}
          >
            {title}
          </h2>
        )}

        <div className="grid-cards-container">
          {gridCarousel && (
            <Slider
              slidesToShow={1}
              dots
              arrows={false}
              autoplay
              dotsClass="grid-carousel-pagination"
              draggable
              pauseOnDotsHover
              pauseOnFocus
              pauseOnHover
              autoplaySpeed={
                gridCarousel.autoplaySpeed
                  ? gridCarousel.autoplaySpeed * 1000
                  : 3000
              }
              className={`grid-carousel flex-${gridCarousel.carouselWidth}`}
            >
              {gridCarousel.gridCards.map(gridCard => renderGridCard(gridCard))}
            </Slider>
          )}
          {gridCards.map(gridCard => renderGridCard(gridCard))}
        </div>
      </div>
    );
  };

  return <>{grid && renderGridRow()}</>;
};

export default Grid;
