import React from "react";
import jsonpointer from "jsonpointer";
import {
  createLink,
  getImageUrl,
  parseAndRewriteLinks,
  getNestedObject,
  CmsEditButton
} from "bloomreach-experience-react-sdk";

import "./video.less";

const Video = props => {
  const { configuration, pageModel, preview } = props;

  const params = getNestedObject(configuration, ["_meta", "paramsInfo"]);
  const ref = getNestedObject(configuration, ["models", "document", "$ref"]);
  let content = null;
  if (ref) {
    content = jsonpointer.get(pageModel, ref);
  }

  const manageContentButton = preview ? (
    <CmsEditButton configuration={content} preview={preview} />
  ) : null;

  return (
    <div className="container">
      {manageContentButton && manageContentButton}
      <div className="row">
        <div className="col-12">
          {content && (
            <iframe
              className={`video-iframe ${
                params.width === 0 && params.height === 0 ? "unsized" : ""
              }`}
              width={params.width > 0 ? params.width : ""}
              height={params.height > 0 ? params.height : ""}
              title={content.title}
              src={content.link}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Video;
