/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { useState } from "react";
import Modal from "react-responsive-modal";
import {
  checkTokensExpired,
  checkResponse,
  isInvalidEmail,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { cortexFetch } from "../utils/Cortex";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import Input from "../common/InputComponent/Input";

import "./b2b.editassociate.less";

let Config: IEpConfig | any = {};
let intl = { get: (str, ...args: any[]) => str };

interface SubUserInputs {
  email: string;
  firstName: string;
  lastName: string;
  rolesSelected: string;
  existingUserMessage: string;
}

interface B2bEditAssociateProps {
  isOpen: boolean;
  handleClose: () => void;
  handleUpdate: () => void;
  associateEmail: string;
  accountName: string;
  subAccountName: string;
  addAssociateUri: string;
  rolesSelector: any;
  isSelf: boolean;
  isAddAssociateOpen: boolean;
  history: any;
  auth: any;
  user: any;
}

const initialInputs: SubUserInputs = {
  email: "",
  firstName: "",
  lastName: "",
  rolesSelected: "",
  existingUserMessage: ""
};

interface Role {
  roleName: string;
  selectRoleURI: string;
  selected: boolean;
  message?: string;
}

enum RoleTypes {
  BUYER = "BUYER",
  BUYER_ADMIN = "BUYER_ADMIN",
  CATALOG_BROWSER = "CATALOG_BROWSER",
  ECOMM_VIEW_CREDIT = "ECOMM_VIEW_CREDIT",
  ECOMM_VIEW_STATEMENTS = "ECOMM_VIEW_STATEMENTS"
}

interface ErrorObj {
  errors: SubUserInputs;
  isValid: boolean;
}

const B2bEditAssociate: React.FC<B2bEditAssociateProps> = props => {
  const [changedRoles, setChangedRoles] = useState<Array<Role>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subUser, setSubUser] = useState<SubUserInputs>({ ...initialInputs });
  const [errors, setErrors] = useState<SubUserInputs>({ ...initialInputs });
  const [adminWarning, setAdminWarning] = useState<boolean>(false);
  const { user } = props;
  const {
    userProfile: { company, customerNumber }
  } = user;
  const epConfig = getConfig();
  Config = epConfig.config;
  ({ intl } = epConfig);

  const renderRoleSelection = () => {
    const { rolesSelector } = props;

    let allAssociateRoles: Array<Role> = [];
    if (rolesSelector) {
      if (rolesSelector._choice) {
        rolesSelector._choice.forEach(choiceElement => {
          allAssociateRoles.push({
            roleName: choiceElement._description[0].name,
            selectRoleURI: choiceElement._selectaction[0].self.uri,
            selected: false
          });
        });
      }
      if (rolesSelector._chosen) {
        rolesSelector._chosen.forEach(chosenElement => {
          allAssociateRoles.push({
            roleName: chosenElement._description[0].name,
            selectRoleURI: chosenElement._selectaction[0].self.uri,
            selected: true
          });
        });
      }
      allAssociateRoles.sort((a, b) => {
        if (a.roleName > b.roleName) {
          return 1;
        }
        if (a.roleName < b.roleName) {
          return -1;
        }
        return 0;
      });
    } else {
      allAssociateRoles = [
        {
          roleName: RoleTypes.CATALOG_BROWSER,
          selectRoleURI: "",
          selected: false
        },
        {
          roleName: RoleTypes.BUYER_ADMIN,
          selectRoleURI: "",
          selected: false
        },
        {
          roleName: RoleTypes.ECOMM_VIEW_CREDIT,
          selectRoleURI: "",
          selected: false
        },
        {
          roleName: RoleTypes.ECOMM_VIEW_STATEMENTS,
          selectRoleURI: "",
          selected: false
        }
      ];
    }

    return (
      <div>
        <p className="role-message">{intl.get("admin-user-message")}</p>
        <div className="role-checkbox-container">
          {allAssociateRoles
            .filter(
              role =>
                role &&
                role.roleName &&
                (role.roleName.toUpperCase() === RoleTypes.ECOMM_VIEW_CREDIT ||
                  role.roleName.toUpperCase() ===
                    RoleTypes.ECOMM_VIEW_STATEMENTS)
            )
            .map(role => (
              <div key={role.roleName} className="role-checkbox-item">
                <div className="role-checkbox">
                  <input
                    id={role.roleName}
                    type="checkbox"
                    defaultChecked={role.selected}
                    onChange={e => handleRoleChange(role, e)}
                    className="style-checkbox"
                  />
                  <label htmlFor={role.roleName} />
                  <label htmlFor={role.roleName} className="role-title">
                    {intl.get(role.roleName.toLowerCase()) || role.roleName}
                  </label>
                </div>
              </div>
            ))}
        </div>
        <p className="role-message admin-role">
          *{intl.get("admin-user-description")}
        </p>
        {allAssociateRoles
          .filter(
            role =>
              role &&
              role.roleName &&
              role.roleName.toUpperCase() === RoleTypes.BUYER_ADMIN
          )
          .map(role => (
            <div key={role.roleName} className="role-checkbox-item">
              <div className="role-checkbox">
                <input
                  id={role.roleName}
                  type="checkbox"
                  defaultChecked={role.selected}
                  onChange={e => handleRoleChange(role, e)}
                  className="style-checkbox"
                />
                <label htmlFor={role.roleName} />
                <label htmlFor={role.roleName} className="role-title">
                  {intl.get(role.roleName.toLowerCase()) || role.roleName}
                </label>
              </div>
            </div>
          ))}
      </div>
    );
  };

  const renderAdminWarningMessage = () => {
    return (
      <Modal
        open={adminWarning}
        onClose={() => setAdminWarning(false)}
        classNames={{
          modal: "admin-warning-modal",
          overlay: "admin-warning-overlay",
          closeButton: "b2b-dialog-close-btn"
        }}
      >
        <div className="admin-warning-body">
          <p>{intl.get("admin-warning")}</p>
          <button
            type="button"
            className="ep-btn primary"
            onClick={() => setAdminWarning(false)}
          >
            {intl.get("ok")}
          </button>
        </div>
      </Modal>
    );
  };

  const handleRoleChange = (role: Role, e: any): void => {
    const changes: Array<Role> = changedRoles;

    const roleIndex = changes.findIndex(r => r.roleName === role.roleName);
    if (roleIndex !== -1) {
      changes.splice(roleIndex, 1);
    } else {
      changes.push(role);
    }

    const isAdminWarning: boolean =
      Boolean(
        changes.find((ch: Role) => ch.roleName === RoleTypes.BUYER_ADMIN)
      ) && role.roleName === RoleTypes.BUYER_ADMIN;

    setAdminWarning(isAdminWarning);
    setChangedRoles(changes);

    setErrors({ ...initialInputs });
  };

  const handleErrorResponse = (err): void => {
    const errorObj: SubUserInputs = { ...initialInputs };
    errorObj.existingUserMessage = err.messages[0]["debug-message"];
    setErrors(errorObj);
    setIsLoading(false);
  };

  const handleSubAccountAdded = (
    email: string,
    firstname: string,
    lastname: string,
    roles: Array<string>
  ): void => {
    const { scope } = getConfig().config.cortexApi;
    const { addAssociateUri, handleUpdate } = props;
    const url: string = `/subusermessage/${scope}/form`;

    const accountguid: string = addAssociateUri.slice(
      addAssociateUri.lastIndexOf("am/") + 3,
      addAssociateUri.lastIndexOf("/form")
    );

    setIsLoading(true);

    cortexFetch(url, {
      method: "post",
      body: JSON.stringify({
        accountguid,
        email,
        roles,
        firstname,
        lastname
      })
    })
      .then(res => {
        if (res.status === 400) {
          return res.json();
        }
        return res;
      })
      .then(res => {
        const onSuccess = data => data;

        return checkResponse(res, onSuccess);
      })
      .then(() => {
        setIsLoading(false);

        handleModalClose();
        handleUpdate();
      })
      .catch(err => {
        if (err.messages && err.messages[0]) {
          handleErrorResponse(err);
          return;
        }
        handleExpiredToken(err);
      });
  };

  const validateFormInputs = (
    email: string,
    firstName: string,
    lastName: string
  ): ErrorObj => {
    const errorObj: SubUserInputs = { ...initialInputs };

    errorObj.email = isInvalidEmail(email);

    if (!firstName.trim()) {
      errorObj.firstName = intl.get("field-cannot-be-empty");
    }

    if (!lastName.trim()) {
      errorObj.lastName = intl.get("field-cannot-be-empty");
    }

    const isValid: boolean = !Object.values(errorObj).filter(value => value)
      .length;

    return {
      errors: errorObj,
      isValid
    };
  };

  const handleAddAssociateClicked = (): any => {
    const { email, firstName, lastName } = subUser;

    const { errors: errorMessages, isValid }: ErrorObj = validateFormInputs(
      email,
      firstName,
      lastName
    );

    const roles: Array<string> = changedRoles.map(role =>
      role.roleName.replace(" ", "_").toUpperCase()
    );
    // comment this out. Always add "BUYER" role if (!roles.length) {
    roles.push(RoleTypes.BUYER);
    // comment this out. Always add "BUYER" role}

    if (!isValid || !roles.length) {
      setErrors(errorMessages);

      return;
    }

    handleSubAccountAdded(email, firstName, lastName, roles);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;

    setSubUser(prevState => {
      const updatedState = { ...subUser };
      updatedState[name] = value;

      return { ...prevState, ...updatedState };
    });

    setErrors({ ...initialInputs });
  };

  const handleModalClose = (): void => {
    const { handleClose } = props;
    setSubUser({ ...initialInputs });
    setErrors({ ...initialInputs });
    setChangedRoles([]);
    handleClose();
    setAdminWarning(false);
  };

  const handleExpiredToken = err => {
    const {
      history,
      auth: { logout }
    } = props;

    if (checkTokensExpired(err)) {
      logout().catch(e =>
        pushToMaintenace(history, {
          e,
          errIn: "Logout => handleExpiredToken => B2BEditAssociate.tsx"
        })
      );
    } else {
      pushToMaintenace(history, {
        e: err,
        errIn: "handleExpiredToken => B2BEditAssociate.tsx"
      });
    }
  };

  const { isOpen, accountName } = props;
  const { email, firstName, lastName } = subUser;

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      classNames={{
        modal: "b2b-edit-associate-dialog",
        closeButton: "b2b-dialog-close-btn"
      }}
    >
      {renderAdminWarningMessage()}
      <h4 className="dialog-header bullet">{intl.get("add-user")}</h4>
      <div className="dialog-content">
        {errors && errors.existingUserMessage ? (
          <p className="validation-error"> {errors.existingUserMessage}</p>
        ) : null}
        <div className="am-columns">
          <div className="am-field-editor">
            <div className="associate-input-title">{intl.get("email")}</div>
            <Input
              label=""
              type="email"
              inputName="email"
              ariaLabel={intl.get("email")}
              inputHandler={handleInputChange}
              value={email}
              errors={errors}
              required
            />
          </div>

          <div>
            <div className="account-title">{intl.get("account")}</div>
            <p>{company}</p>
            <p>{customerNumber}</p>
          </div>
        </div>
        <div className="am-columns">
          <div className="am-field-editor">
            <div className="associate-input-title">
              {intl.get("first-name")}
            </div>
            <Input
              label=""
              type="text"
              inputName="firstName"
              ariaLabel={intl.get("first-name")}
              inputHandler={handleInputChange}
              value={firstName}
              errors={errors}
              required
            />
          </div>
        </div>

        <div className="am-columns">
          <div className="am-field-editor">
            <div className="associate-input-title">{intl.get("last-name")}</div>
            <Input
              label=""
              type="text"
              inputName="lastName"
              ariaLabel={intl.get("last-name")}
              inputHandler={handleInputChange}
              value={lastName}
              errors={errors}
              required
            />
          </div>
        </div>

        <div className="am-columns">
          <div className="checkbox-role-title">{intl.get("role")}</div>
          {renderRoleSelection()}
        </div>
        {errors && errors.rolesSelected ? (
          <p className="validation-error"> {errors.rolesSelected}</p>
        ) : null}
      </div>
      <div className="dialog-footer add-associate-buttons">
        <button className="ep-btn" type="button" onClick={handleModalClose}>
          {intl.get("cancel").toUpperCase()}
        </button>
        <button
          className="ep-btn primary"
          type="button"
          onClick={handleAddAssociateClicked}
        >
          {intl.get("save").toUpperCase()}
        </button>
      </div>
      {isLoading ? (
        <div className="loader-wrapper">
          <div className="miniLoader" />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default B2bEditAssociate;
