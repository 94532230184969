/* eslint-disable no-nested-ternary */
/**
 * Refactored component from productdisplayitem.main.tsx. This component handles displaying the inventory
 * availability messaging.
 */
import React, { FC, useContext, useState } from "react";
import Modal from "react-responsive-modal";

import intl from "react-intl-universal";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { useHistory } from "react-router-dom";
import { getConfig } from "@zilker/store-components";
import { ReactComponent as InfoIcon } from "../../../../app/src/images/icons/info-icon.svg";

import { formatAvailabilityLabel } from "../../../../app/src/utils/helpers";

import AlternateBranchList from "../../AlternateBranchList/AlternateBranchList";
import Popup from "../../Popup/Popup";

import "./ProductAvailability.less";

interface ProductAvailabilityProps {
  productId: string;
  inventoryAvailability: any[];
  itemQty: number;
  branchAvailability: any;
  regionAvailability: any;
  inventoryError: string;
  isCallButtonActive: boolean;
}

const ProductAvailability: FC<ProductAvailabilityProps> = ({
  productId,
  inventoryAvailability,
  itemQty,
  branchAvailability,
  regionAvailability,
  inventoryError,
  isCallButtonActive
}) => {
  const [openAlternateBranchModal, setOpenAlternateBranchModal] = useState<
    boolean
  >(false);
  const [openAvailabilityPopUp, setOpenAvailabilityPopUp] = useState<boolean>(
    false
  );

  const specialAirFilters = ["MCB50YSAU", "MCKB70YSAU"];

  const history = useHistory();

  const context = useContext<{
    auth: any;
    user: any;
    cart: any;
    branches: any;
  }>(MainContext);
  const {
    branches: { branchesList },
    user: {
      userProfile: { customerRoles }
    }
  } = context;
  const BRANCHES_VIRTUAL = intl.get("virtual-branches");
  const isVirtualBranchUser =
    customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);
  const MAX_BRANCHES_TO_DISPLAY = 3;

  const pdpPopupStyle = "pdp-popup";
  const pdPage = "pdp";

  const { config } = getConfig();
  const {
    cortexApi: { scope }
  } = config;

  const openAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(true);
  };

  const closeAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(false);
  };

  const openAvailabilityPopUpModal = () => {
    setOpenAvailabilityPopUp(true);
  };

  const closeAvailabilityPopUpModal = () => {
    setOpenAvailabilityPopUp(false);
  };

  const renderAlternateBranchesModal = () => {
    const productSkuCode = productId;

    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%",
        height: "650px"
      }
    };

    return (
      <Modal
        open={openAlternateBranchModal}
        onClose={closeAlternateBranchesModal}
        styles={{
          modal: styles.modal
        }}
      >
        <div>
          <AlternateBranchList
            product={{
              sku: productSkuCode,
              qtyAvailable: 0,
              qtyEntered: 1
            }}
            qtyColumnHeader={intl.get("stock-status")}
            history={history}
            branches={branchesList}
            itemQty={itemQty}
          />
        </div>
      </Modal>
    );
  };

  /**
   * Helper method to render the availablity HTML for Virtual Branches.
   * Checks the member branches that make up a virtual branch to see if any of them have inventory.
   * @returns True if a member branch has inventory, false otherwise.
   */
  const renderVirtualBranchAvailability = () => {
    let availableAtVirtualBranch = null;
    if (isVirtualBranchUser && inventoryAvailability) {
      const memberBranchWithInventory = inventoryAvailability.find(
        displayBranch => {
          return (
            displayBranch &&
            displayBranch.items &&
            displayBranch.items.length > 0 &&
            displayBranch.items[0].qtyAvailable - itemQty >= 0
          );
        }
      );
      if (memberBranchWithInventory) {
        if (memberBranchWithInventory.items[0].qtyAvailable - itemQty < 5) {
          availableAtVirtualBranch = (
            <span className="label"> {intl.get("limited-availability")}</span>
          );
        } else {
          availableAtVirtualBranch = (
            <span className="label"> {intl.get("available-label")}</span>
          );
        }
      } else {
        availableAtVirtualBranch = (
          <span className="label">
            {" "}
            {intl.get("available-for-back-order-label")}
          </span>
        );
      }
    }
    return availableAtVirtualBranch;
  };

  // PGL-364: Updates to MCB50YSAU and MCKB70YSAU
  const isSpecialAirFilter = specialAirFilters.includes(productId);

  const renderAvailabilityLabel = () => {
    if (isSpecialAirFilter) {
      return (
        <span className="label">
          {`${intl.get("special-air-purifier-pdp-msg")}`}
        </span>
      );
    }
    if (isCallButtonActive) {
      return (
        <span className="label">{`${intl.get("call-branch-to-order")}`}</span>
      );
    }
    if (!isVirtualBranchUser && scope !== "motili") {
      return inventoryAvailability
        .slice(0, MAX_BRANCHES_TO_DISPLAY)
        .map(displayBranch => {
          if (
            displayBranch &&
            displayBranch.items &&
            displayBranch.items.length > 0 &&
            displayBranch.items[0].qtyAvailable - itemQty >= 0
          ) {
            if (displayBranch.items[0].qtyAvailable - itemQty < 5) {
              return (
                <span className="label" key={displayBranch.branchName}>
                  {`${intl.get("limited-availability-at")} ${
                    displayBranch.branchName
                  }`}
                </span>
              );
            }
            return (
              <span className="label" key={displayBranch.branchName}>
                {`${intl.get("available-at")} ${displayBranch.branchName}`}
              </span>
            );
          }
          return (
            <span className="label" key={displayBranch.branchName}>
              {`${intl.get("available-for-backorder-at")} ${
                displayBranch.branchName
              }`}
            </span>
          );
        });
    }
    if (scope === "motili") {
      return (
        <div className="d-flex h3 align-items-center">
          <span className="label">
            {formatAvailabilityLabel(branchAvailability, regionAvailability)}
          </span>
        </div>
      );
    }
    return null;
  };

  return inventoryAvailability && !inventoryError ? (
    <>
      {renderAlternateBranchesModal()}

      <div className="d-flex h3 align-items-center availability-container">
        <div className="d-flex flex-column">
          {renderAvailabilityLabel()}
          {isVirtualBranchUser && renderVirtualBranchAvailability()}
        </div>
        {/* PGL-364: remove iventory icon */}
        {!isSpecialAirFilter && (
          <div className="info-btn">
            {openAvailabilityPopUp && (
              <Popup
                closePopUp={closeAvailabilityPopUpModal}
                availabilityLabel={null}
                isCallButtonActive={isCallButtonActive}
                popupStyle={pdpPopupStyle}
                page={pdPage}
              />
            )}
            <InfoIcon
              className="info-icon"
              onClick={openAvailabilityPopUpModal}
            />
          </div>
        )}
        {!isVirtualBranchUser && !isSpecialAirFilter && (
          <span className="px-4">|</span>
        )}
        {!isVirtualBranchUser && !isSpecialAirFilter && (
          <button
            className="d-flex align-items-center open-alternate-branches"
            type="button"
            onClick={openAlternateBranchesModal}
          >
            <div>
              <i className="icon-home" />
              {`  ${
                scope === "motili"
                  ? intl.get("check-availability-by-branch")
                  : intl.get("check-all-branches")
              }`}
            </div>
          </button>
        )}
      </div>
    </>
  ) : (
    <div className="d-flex h3 align-items-center">
      {inventoryError ? (
        <span>{inventoryError}</span>
      ) : (
        <>
          <span className="label">
            {`${intl.get("checking-availability")}`}
          </span>
          <div className="miniLoader" />
        </>
      )}
    </div>
  );
};

export default ProductAvailability;
