/* eslint-disable camelcase */
import React, { FC, useContext, useState } from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";
import { formatAvailabilityLabel } from "@elasticpath/ref-store/src/utils/helpers";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { getConfig } from "../utils/ConfigProvider";
import AppHeaderLoginMain from "../AppHeaderLogin/appheaderlogin.main";
import AlternateBranchList from "../AlternateBranchList/AlternateBranchList";
import Popup from "../Popup/Popup";
import { ReactComponent as InfoIcon } from "../../../app/src/images/icons/info-icon.svg";

import "./search.results.item.less";

const imgMissingHorizontal =
  "/site/binaries/content/gallery/placeholder/placeholder.jpg";

const appHeaderLoginLinks = {
  profile: "/myAccount/profile",
  wishlists: "/wishlists"
};

const appModalLoginLinks = {
  registration: "/registration"
};

interface SearchResultsItemProps extends RouteComponentProps {
  history: any;
  item: any;
  onAddToCart: (any, item) => any;
  titleRef?: any;
  clickedButtonLoading?: boolean;
  start?: number;
  facets?: any;
  itemsLoaded?: number;
  sortByAvailability?: boolean;
  onClick?: (pid: any) => void;
}

/**
 * ## renderProductPrice
 *
 * @param price any
 * @param isLoggedIn boolean
 *
 * @description Renders product price or displays mini loader.
 * If user is not authenticated, the price won't be displayed.
 */
const renderProductPrice = (price: any, isLoggedIn: boolean) => {
  const { config } = getConfig();
  if (config.calculatePrice) {
    if (isLoggedIn && price === 0) return "";

    if (isLoggedIn && price && price.toString() === intl.get("pending")) {
      return <div className="item-price">{price}</div>;
    }

    if (isLoggedIn && price) {
      return <div className="item-price">${price}</div>;
    }

    if (isLoggedIn && !price) {
      return (
        <div className="pdp-loader-wrapper">
          <div className="miniLoader" />
        </div>
      );
    }
  } else {
    return "";
  }

  return null;
};

const SearchResultsItem: FC<SearchResultsItemProps> = ({
  item,
  onAddToCart,
  titleRef = undefined,
  clickedButtonLoading,
  start,
  facets,
  history,
  itemsLoaded,
  sortByAvailability,
  onClick
}) => {
  const context = useContext<{ auth: any; user: any; branches: any }>(
    MainContext
  );

  const prevUrl = window.location.href;

  const { config } = getConfig();
  const {
    cortexApi: { scope }
  } = config;

  const {
    auth: { isLoggedIn },
    branches: { branchesList },
    user: {
      userProfile: { customerRoles }
    }
  } = context;

  const BRANCHES_VIRTUAL = intl.get("virtual-branches");
  const isVirtualBranchUser =
    customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

  const [openAlternateBranchModal, setOpenAlternateBranchModal] = useState<
    boolean
  >(false);
  const [openAvailabilityPopUp, setOpenAvailabilityPopUp] = useState<boolean>(
    false
  );

  const onItemClick = () => {
    if (titleRef) {
      window.scrollTo(
        0,
        titleRef.current.scrollIntoView({ behavior: "smooth" })
      );
    }

    if (onClick) onClick(item);
  };

  let imageUrl;
  try {
    imageUrl = item.full_image || item.thumb_image;
  } catch (error) {
    imageUrl = config.missingImagePlaceholderUrl;
  }

  const isPriceZero = item && `${item.productPrice}` === "0";

  const isCallButtonActive =
    (config.calculatePrice && isPriceZero) ||
    !item.entitled ||
    (config.checkAvailability &&
      !item.branchAvailability &&
      !item.regionAvailability);

  const openAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(true);
  };

  const openAvailabilityPopUpModal = () => {
    setOpenAvailabilityPopUp(true);
  };

  const closeAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(false);
  };

  const closeAvailabilityPopUpModal = () => {
    setOpenAvailabilityPopUp(false);
  };

  const renderAlternateBranchesModal = () => {
    const productSkuCode = item.pid;

    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%",
        height: "650px"
      }
    };

    return (
      <Modal
        open={openAlternateBranchModal}
        onClose={closeAlternateBranchesModal}
        styles={{
          modal: styles.modal
        }}
      >
        <div>
          <AlternateBranchList
            product={{
              sku: productSkuCode,
              qtyAvailable: 0,
              qtyEntered: 1
            }}
            qtyColumnHeader={intl.get("stock-status")}
            history={history}
            branches={branchesList}
            itemQty={1}
          />
        </div>
      </Modal>
    );
  };

  const formatAvailabilityDGA = (branchAvailability, regionAvailability) => {
    if (isCallButtonActive) {
      return <span>{intl.get("call-branch-to-order")}</span>;
    }
    if (branchAvailability > 0) {
      if (branchAvailability <= 5) {
        return <span>{intl.get("limited-availability")}</span>;
      }
      return <span>{intl.get("available-label")}</span>;
    }
    if (
      branchAvailability === 0 &&
      !isVirtualBranchUser &&
      regionAvailability > 0
    ) {
      if (regionAvailability > 5) {
        return (
          <button type="button" onClick={openAlternateBranchesModal}>
            {intl.get("available-in-your-region-click-here")}
          </button>
        );
      }
      if (regionAvailability <= 5) {
        return (
          <button type="button" onClick={openAlternateBranchesModal}>
            {intl.get("limited-in-your-region-click-here")}
          </button>
        );
      }
    }
    return <span>{intl.get("available-for-back-order-label")}</span>;
  };

  /** Various parameters from PLP are passed to PDP and Breadcrumbs components, using location state object.
   * The purpose is to persist those values between the pages - when the user navigates PLP => PDP => PLP,
   * those parameters are saved - start, facets, name, itemsLoaded (number of items loaded on PLP),
   * sortByAvailability (is the sort checkbox selected).
   */
  const navLinkRouteParams = {
    pathname: `/itemdetail/${item.pid}`,
    state: {
      searchResultParams: {
        start,
        facets,
        name: item.title,
        itemsLoaded,
        sortByAvailability,
        prevUrl
      }
    }
  };
  // PGL-364: Updates to MCB50YSAU and MCKB70YSAU
  let inventoryLabel: string = "";
  // PGL-364: remove iventory icon
  const isSpecialAirPurifiier =
    item.pid === "MCB50YSAU" || item.pid === "MCKB70YSAU";
  if (scope === "motili") {
    inventoryLabel = formatAvailabilityLabel(
      item.branchAvailability,
      item.regionAvailability
    );
  } else if (isSpecialAirPurifiier) {
    inventoryLabel = intl.get("special-air-purifier-inventory-msg");
  }

  const availabilityLabel = isLoggedIn
    ? formatAvailabilityDGA(item.branchAvailability, item.regionAvailability)
    : null;

  const searchResultsItemPopupStyle = "upper-right";
  const searchResultsPage = "search-results";

  return (
    <>
      {renderAlternateBranchesModal()}
      <div
        key={item.pid}
        className="search-result-item content-box"
        id={item.pid}
      >
        <div className="item-image-container">
          <NavLink to={navLinkRouteParams} onClick={onItemClick} tabIndex={-1}>
            <img
              alt={item.title}
              src={imageUrl}
              onError={e => {
                const element: any = e.target;
                element.src = imgMissingHorizontal;
              }}
            />
          </NavLink>
        </div>
        <div className="item-info-container">
          <div className="item-title" title={item.title}>
            <NavLink
              to={navLinkRouteParams}
              onClick={onItemClick}
              tabIndex={-1}
            >
              {item.title}
            </NavLink>
          </div>
          <div className="item-sku">
            <NavLink
              to={navLinkRouteParams}
              tabIndex={-1}
              onClick={onItemClick}
            >
              <span className="label">{intl.get("product")}: </span>
              <span className="product-sku">{item.pid}</span>
            </NavLink>
          </div>
          {/* TODO: should we localize prices */}
          <div className="container-price-popup">
            {renderProductPrice(item.productPrice, isLoggedIn)}
          </div>
          {isLoggedIn ? (
            <div className="item-availability">
              <span className="label">
                {inventoryLabel || availabilityLabel}
              </span>
              {!isSpecialAirPurifiier && (
                <div className="info-popup-btn">
                  {openAvailabilityPopUp && (
                    <Popup
                      closePopUp={closeAvailabilityPopUpModal}
                      availabilityLabel={availabilityLabel.props.children}
                      isCallButtonActive={isCallButtonActive}
                      popupStyle={searchResultsItemPopupStyle}
                      page={searchResultsPage}
                    />
                  )}
                  <InfoIcon
                    className="info-icon"
                    onClick={openAvailabilityPopUpModal}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="price-availability-placeholder-logged-out ep-btn primary">
              <AppHeaderLoginMain
                history={history}
                permission
                appHeaderLoginLinks={appHeaderLoginLinks}
                appModalLoginLinks={appModalLoginLinks}
                isLoggedIn={false}
                descriptiveButtonText={intl.get("login-message")}
              />
            </div>
          )}
        </div>

        {isLoggedIn &&
          (clickedButtonLoading ? (
            <div className="miniLoader" />
          ) : (
            <div className="item-button">
              <button
                tabIndex={-1}
                type="button"
                className="ep-btn primary"
                onClick={event => {
                  onAddToCart(event, item);
                }}
                id={item.pid}
                disabled={
                  (config.calculatePrice &&
                    (!item.productPrice || isPriceZero)) ||
                  !item.entitled ||
                  (config.checkAvailability &&
                    !item.branchAvailability &&
                    !item.regionAvailability)
                }
              >
                {(config.calculatePrice && isPriceZero) ||
                !item.entitled ||
                (config.checkAvailability &&
                  !item.branchAvailability &&
                  !item.regionAvailability)
                  ? intl.get("call")
                  : intl.get("add-to-cart")}
              </button>
            </div>
          ))}
      </div>
    </>
  );
};

export default withRouter(SearchResultsItem);
