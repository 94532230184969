import React, { FC, useContext } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import intl from "react-intl-universal";

import {
  BreadCrumbsComponent,
  AccountMenuComponent,
  Messagecontainer
} from "@zilker/store-components";

import UrlToString from "../utils/mappings/urlToString";
import urlToAccountComponent from "../utils/mappings/urlToAccountComponent";

import { MainContext } from "../contexts/MainContext";

import "./AccountPage.less";

interface AccountMainRouterProps {
  subPage: string;
  detail: string;
  zoom: string;
  history: any;
  match: any;
}

const AccountPage: FC<RouteComponentProps<AccountMainRouterProps>> = ({
  match,
  history
}) => {
  const context = useContext<{
    auth: any;
    user: any;
    account: any;
    cart: any;
    branches: any;
    order: any;
    modal: any;
    contract: any;
  }>(MainContext);
  const { auth, user, account, cart, branches, modal, contract } = context;

  const { passwordResetMessage, setPasswordResetMessage } = modal;

  const {
    userProfile: { customerNumber }
  } = user;
  const { subPage, detail, zoom } = match.params;
  const UrlToStringMap = UrlToString();
  const breadCrumbsMap = breadCrumbsMapped(
    UrlToStringMap,
    subPage,
    detail,
    zoom
  );

  return customerNumber ? (
    <div className="account-page container-fluid">
      <div className="container">
        <div className="row">
          <div className={`col-${passwordResetMessage ? "3" : "12"}`}>
            <BreadCrumbsComponent breadCrumbsMap={breadCrumbsMap} />
          </div>
          {passwordResetMessage && (
            <div className="col-9 reset-password-toast">
              <Messagecontainer
                message={{
                  type: "basic",
                  debugMessages: intl.get("password-successfully-changed")
                }}
                closeContainerHandler={() => setPasswordResetMessage(false)}
              />
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-3 col-12">
            <AccountMenuComponent />
          </div>
          <div className="account-subpage-container col-md-9 col-12">
            {urlToAccountComponent(
              subPage,
              { history, match },
              { auth, user, account, cart, branches, contract }
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="loader" />
  );
};

function breadCrumbsMapped(UrlToStringMap, subPage, detail, zoom) {
  const generateParam = (detailParam: any, subPageParam: string): string => {
    if (detailParam) {
      if (subPageParam === "orderDetails") {
        return "myAccount/orderHistory";
      }
      if (subPageParam === "invoiceDetails") {
        return "myAccount/invoiceQuery";
      }
      if (subPageParam === "contractDetails") {
        return "myAccount/contractOrders";
      }

      return `myAccount/${subPageParam}`;
    }

    return "nocrumb";
  };

  const breadCrumbsMap = [
    { param: "home", name: "Home" },
    {
      param: subPage !== "profile" ? "myAccount/profile" : "nocrumb",
      name: UrlToStringMap.myAccount
    },
    {
      param: generateParam(detail, subPage),
      name: UrlToStringMap[subPage]
    },
    {
      param: "nocrumb",
      name: detail || ""
    }
  ];

  return breadCrumbsMap.filter(urlParam => !!urlParam.param);
}

export default withRouter(AccountPage);
