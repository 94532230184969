import React from "react";
import Slider from "react-slick";

// This is CSS needed for carousel to work. Node module: slick-carousel.
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./MultipleItemsCarousel.less";

interface CarouselConfig {
  mobile: number;
  tablet: number;
  carouselId: string;
  mobileItems: number;
  tabletItems: number;
  desktopItems: number;
}

interface MultipleItemsCarouselProps {
  items: Array<any>;
  numOfItems: number;
  /**
   * @description  Function that returns JSX component and passes props to it.
   * For example: item => <Component data={item} />
   */
  displayComponent: (...args: any) => any;
  carouselConfig: CarouselConfig;
}

const MultipleItemsCarousel: React.FC<MultipleItemsCarouselProps> = props => {
  const generateItemsToShow = (
    itemsLength: number
  ): {
    tablet: number;
    mobile: number;
    desktop: number;
  } => {
    const { carouselConfig } = props;
    const { mobileItems, tabletItems, desktopItems } = carouselConfig;

    let mobileItemsNum: number = mobileItems;
    let tabletItemsNum: number = tabletItems;
    let desktopItemsNum: number = desktopItems;

    if (itemsLength <= desktopItemsNum) {
      desktopItemsNum = itemsLength;
    }
    if (itemsLength <= tabletItemsNum) {
      tabletItemsNum = itemsLength;
    }
    if (itemsLength <= mobileItemsNum) {
      mobileItemsNum = itemsLength;
    }

    return {
      desktop: desktopItemsNum,
      tablet: tabletItemsNum,
      mobile: mobileItemsNum
    };
  };

  const setAccessible = () => {
    setTimeout(() => {
      const elements = Array.from(
        document.getElementsByClassName("slick-slide slick-active")
      ).map(el => {
        return el.getElementsByClassName("item-sku")[0].children[0];
      });
      elements.forEach(el => el.setAttribute("tabIndex", "0"));
    }, 100);
  };

  const generateCarouselSettings = (): any => {
    const { numOfItems, carouselConfig } = props;
    const { mobile, tablet } = carouselConfig;

    // Required to set tabs on currently viewed items
    setAccessible();

    return {
      accessibility: true,
      arrows: true,
      infinite: true,
      afterChange: setAccessible(),
      speed: 500,
      dots: true,
      slidesToShow: generateItemsToShow(numOfItems).desktop,
      slidesToScroll: generateItemsToShow(numOfItems).desktop,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: tablet,
          settings: {
            accessibility: true,
            arrows: true,
            infinite: true,
            slidesToShow: generateItemsToShow(numOfItems).tablet,
            slidesToScroll: generateItemsToShow(numOfItems).tablet
          }
        },
        {
          breakpoint: mobile,
          settings: {
            accessibility: true,
            arrows: true,
            slidesToShow: generateItemsToShow(numOfItems).mobile,
            slidesToScroll: generateItemsToShow(numOfItems).mobile
          }
        }
      ]
    };
  };

  const { items, displayComponent } = props;

  const settings = generateCarouselSettings();

  return (
    <div className="multi-items-carousel">
      <Slider {...settings}>
        {items.map(item => (
          <div key={item.pid} className="single-carousel-item">
            {displayComponent(item)}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MultipleItemsCarousel;
