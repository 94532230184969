/**
 * Refactored version of productdisplayitem.main.tsx to be a Functional Component instead of a Class
 * based component.
 */
import React, { useContext, useState, useEffect, useRef, FC } from "react";
import { BreadCrumbsComponent } from "@zilker/store-components";
import { useHistory } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import intl from "react-intl-universal";

import {
  getConfig,
  IEpConfig
} from "@zilker/store-components/src/utils/ConfigProvider";
import {
  checkTokensExpired,
  pushToMaintenace,
  handleCustomException
} from "@elasticpath/ref-store/src/utils/helpers";
import { getAssets } from "@elasticpath/ref-store/src/services/connectServices";

import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import {
  getProductPrice,
  ProductPriceRequest
} from "@elasticpath/ref-store/src/utils/mappings/productDetails";
import { cortexFetchItemLookupForm, itemLookup } from "../utils/CortexLookup";

import IndiRecommendationsDisplayMain from "../IndiRecommendations/indirecommendations.main";
import BundleConstituentsDisplayMain from "../BundleConstituents/bundleconstituents.main";

import { productViewed } from "../utils/Segment";
import ProductImage from "./FunctionalComponents/ProductImage";
import ProductPrice from "./FunctionalComponents/ProductPrice";
import ProductLongDescription from "./FunctionalComponents/ProductLongDescription";
import ProductAddToCartSection from "./FunctionalComponents/ProductAddToCartSection";
import PDPComponents from "./FunctionalComponents/PDPComponents";

// Types
import {
  ProductDisplayItemMainProps,
  ItemDescription
} from "./productdisplayitem.interfaces";

import "./productdisplayitem.main.less";

interface CatalogPriceResponse {
  discount: number;
  lineControlNumber: string;
  list: number;
  netEach: number;
  quantity: number;
  quantityAvailable: number;
  sku: string;
  taxable: boolean;
  total: string;
  valid: boolean;
}

interface currentTabObject {
  name: string;
  visible: boolean;
}

const FunctionalProductDisplayItemMain: FC<ProductDisplayItemMainProps> = ({
  productId,
  location,
  itemDetailLink
}) => {
  // State
  const [curProductId, setCurProductId] = useState<String>();
  const [productData, setProductData] = useState<any>();
  const [crumbs, setCrumbs] = useState([]);
  const [priceDetails, setPriceDetails] = useState<CatalogPriceResponse>(null);
  const [productDocuments, setProductDocuments] = useState<any>(null);
  // TODO - See if this can be eliminated from State on this component.
  const [currentTab, setCurrentTab] = useState<currentTabObject>({
    name: "",
    visible: false
  });

  const history = useHistory();
  const titleRef = useRef<HTMLDivElement>();
  const productDetailsTabsRef = useRef<HTMLDivElement>();
  const context = useContext<{ auth: any; user: any; cart: any; job: any }>(
    MainContext
  );
  const {
    user: {
      userProfile: { customerNumber }
    },
    auth: { isLoggedIn, logout },
    cart: {
      cartDetails: { defaultCart }
    },
    job: { persistedJobNumber }
  } = context;

  const { config }: { config: IEpConfig } = getConfig();
  const { state } = location;
  const {
    pageMetadata: {
      title: pageTitle,
      description: metaDescription,
      canonicalURL
    }
  } = config;
  const { pathname } = history.location;

  const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
  const jobNumber = defaultCart ? defaultCart.jobNumber : null;

  // Clear current productData and Price state to refresh loading indicators when navigating
  // from an item on the Recommended Products Carousel.
  if (curProductId && productId !== curProductId) {
    setProductData(null);
    setPriceDetails(null);
    setCurProductId(productId);
  }

  // Only retrieve Product Data and Product Documents on initial page load.
  useEffect(() => {
    setCurProductId(productId);
    fetchProductData();
    if (config.showDocumentsTab) {
      fetchProductDocuments();
    }
  }, [productId]);

  // Recalculate price when the productId, selectedBranch, jobNumber, or customerNumber changes.
  // selectedBranch, jobNumber, and customerNumber are all required to calculate price.
  useEffect(() => {
    const { scope } = config.cortexApi;
    if (isLoggedIn && scope !== "motili") {
      fetchPrice();
    }
  }, [productId, selectedBranch, jobNumber, customerNumber]);

  sessionStorage.setItem("sku", productId);

  // Save data to session storage if we navigated from PLP/Search result page
  // so we can go back to the last search results
  if (state && state.searchResultParams) {
    sessionStorage.setItem(
      "searchResultParams",
      JSON.stringify(state.searchResultParams, (key, value) =>
        value instanceof Set ? Array.from(value) : value
      )
    );
  }

  // Save data to session storage if we navigated from Parts Finder page
  // so we can go back to the last search results
  if (state && state.partsFinderSearchParams) {
    sessionStorage.setItem(
      "partsFinderSearchParams",
      JSON.stringify(state.partsFinderSearchParams)
    );
  }

  /**
   * ## fetchProductData
   * @param productId string
   *
   * @description Fetches data from the EP and sets the breadcrumbs.
   * If the device supports augmented reality (AR), set the flag in the state.
   */
  const fetchProductData = () => {
    if (productId && pathname.includes("itemdetail")) {
      cortexFetchItemLookupForm()
        .then(() => itemLookup(productId, false))
        .then((res: any) => {
          // Removed OOTB EP arKit logic since this isn't used.
          // See productdisplayitem.main.tsx for OOTB

          if (
            res._definition &&
            res._definition[0].details.length > 0 &&
            res._parentcategory &&
            res._parentcategory[0]
          ) {
            const { scope } = config.cortexApi;
            const scopeUpper = capitalizeWord(scope);

            // NOTE: regex to remove special characters from category name,
            // since it's being used to create category (crumbs) url
            const categoryNameRegex = new RegExp(/[\s-/]/g);

            let categoryNameUrl = "";
            let categoryName = "";
            let name = "";
            let id = "";
            let brand = "";
            let prop65required;

            try {
              categoryName = res._parentcategory[0].name;
              categoryNameUrl = `${scopeUpper}${categoryName.replace(
                categoryNameRegex,
                ""
              )}`;
              name = res._definition[0]["display-name"];
              id = res._code[0].code;
              brand = res._definition[0].details.find(
                detail => detail.name === "brand"
              ).value;

              prop65required = res._definition[0].details.find(
                detail => detail.name.toLowerCase() === "prop_65_label_required"
              );

              if (!isLoggedIn) {
                // sends information to Segment when user views on a product
                productViewed(name, id, null, brand, categoryName);
              }
            } catch (error) {
              handleException(error);
            }

            const crumb = [
              { param: "home", name: "Home" },
              {
                param: `category/${categoryNameUrl}`,
                name: categoryName,
                state: true,
                start:
                  location.state &&
                  location.state.searchResultParams &&
                  location.state.searchResultParams.start,
                sku: id,
                facets:
                  location.state &&
                  location.state.searchResultParams &&
                  location.state.searchResultParams.facets,
                itemsLoaded:
                  location.state &&
                  location.state.searchResultParams &&
                  location.state.searchResultParams.itemsLoaded,
                sortByAvailability:
                  location.state &&
                  location.state.searchResultParams &&
                  location.state.searchResultParams.sortByAvailability
              },
              {
                param: "nocrumb",
                name
              }
            ];
            setProductData(res);
            setCrumbs(crumb);
          }
        })
        .catch(err => {
          handleException(err);
        });
    }
  };

  const capitalizeWord = word => {
    return [...word]
      .map((letter, i) => {
        if (i === 0) return letter.toUpperCase();
        return letter;
      })
      .join("");
  };

  /**
   * ## handleException
   *
   * @description Logs the error message if it exists. Sets the generic error message
   * to component's state. Redirects the user to /maintenance page.
   */
  const handleException = (e: any = undefined) => {
    if (checkTokensExpired(e)) {
      logout().catch(err =>
        pushToMaintenace(history, {
          e: err,
          errIn: "Logout => handleException => productdisplayidem.main.tsx"
        })
      );
    } else {
      pushToMaintenace(history, {
        e,
        errIn: "handleException => productdisplayidem.tsx"
      });
    }
  };

  /**
   * ## populatePrice
   *
   * @param data any - response payload
   *
   * @description Method that takes the product pricing details and
   * populates it to the component's state based on the successful response status code.
   */
  const populatePrice = (data: any) => {
    const {
      items: [itemPriceDetails]
    } = data;

    if (
      productData &&
      productData._parentcategory &&
      productData._parentcategory[0] &&
      productData._definition &&
      productData._definition[0] &&
      itemPriceDetails
    ) {
      const categoryName = productData._parentcategory[0].name;
      const name = productData._definition[0]["display-name"];
      const id = productId;
      const brand = productData._definition[0].details.find(
        detail => detail.name === "brand"
      ).value;
      const price = itemPriceDetails.total;

      // sends information to Segment when user views a product
      productViewed(name, id, price, brand, categoryName);
    }
    setPriceDetails(itemPriceDetails);
  };

  /**
   * ## fetchPrice
   *
   * @param productId string - SKU of the product
   * @param productQuantity? string - quantity of the product (opt)
   *
   * @description Method that fetches the pricing details for the
   * product - based on its SKU code.
   */
  const fetchPrice = () => {
    if (isLoggedIn && selectedBranch && customerNumber) {
      const body: ProductPriceRequest = {
        customerNumber,
        branchNumber: selectedBranch.code,
        skus: [productId],
        jobNumber
      };

      if (jobNumber || persistedJobNumber) {
        body.jobNumber = jobNumber || persistedJobNumber;
      }

      getProductPrice(
        body,
        data => {
          populatePrice(data);
        },
        e => {
          const errorPath =
            "handlePriceCatalogException => productdisplayidem.main.tsx";

          handleCustomException(e, logout, history, errorPath);

          const noPriceResult: CatalogPriceResponse = {
            discount: 0,
            lineControlNumber: "1",
            list: 0,
            netEach: 0,
            quantity: 0,
            quantityAvailable: 0,
            sku: "",
            taxable: true,
            total: intl.get("pending"),
            valid: true
          };

          setPriceDetails(noPriceResult);
        }
      );
    }
  };

  /**
   * ## fetchProductDocuments
   * @param productId string
   *
   * @description Fetches products documents from DCS
   */
  const fetchProductDocuments = async () => {
    try {
      const { data } = await getAssets(productId);

      const documents =
        data && data.result && data.result.length
          ? data.result[0].assets.map(asset => ({
              ...asset,
              assetType: asset.assetType.replace(/([A-Z])/g, " $1")
            }))
          : null;

      setProductDocuments(documents);
    } catch (e) {
      console.error(e);
    }
  };

  const onDocumentsLinkClick = () => {
    productDetailsTabsRef.current.scrollIntoView({ behavior: "smooth" });
    setCurrentTab({ name: "documents", visible: true });
  };

  if (productData && productData._definition && productData._code) {
    const productSku =
      productData &&
      productData._code &&
      productData._code.length &&
      productData._code[0].code;

    let desc = "";
    if (productData._definition[0].details) {
      desc = productData._definition[0].details.find(
        (item: ItemDescription) => item.name === "long_description"
      );
    }
    return (
      <HelmetProvider>
        <div className="itemdetail-component container" ref={titleRef}>
          <Helmet>
            <title>{(state && state.name) || pageTitle}</title>
            <meta
              name="description"
              content={
                (desc && desc["display-value"]) ||
                (state && state.name) ||
                metaDescription
              }
            />
            <link rel="canonical" href={`${canonicalURL}${pathname}`} />
          </Helmet>
          <BreadCrumbsComponent breadCrumbsMap={crumbs} />

          <div className="itemdetail-assets">
            <div
              data-region="itemDetailAssetRegion"
              style={{ display: "block" }}
            >
              <div className="itemdetail-asset-container">
                <ProductImage productData={productData} />
              </div>
            </div>
          </div>

          <div className="itemdetail-details">
            <div
              data-region="itemDetailTitleRegion"
              style={{ display: "block" }}
            >
              <div>
                <h1
                  className="itemdetail-title"
                  id={`category_item_title_${productSku}`}
                >
                  {productData._definition &&
                    productData._definition[0]["display-name"]}
                </h1>
                <div
                  className="itemdetail-title-sku"
                  id={`category_item_sku_${productSku}`}
                >
                  <span className="label">{intl.get("product")} #:</span>
                  <span className="product-sku">{productSku}</span>
                </div>
              </div>
            </div>
            <ProductPrice priceDetails={priceDetails} />
            {productDocuments && productDocuments.length ? (
              <button
                className="documents-tab-link"
                type="button"
                onClick={onDocumentsLinkClick}
              >
                {intl.get("documents-link-text")}
              </button>
            ) : null}
            <div
              data-region="itemDetailAvailabilityRegion"
              style={{ display: "block" }}
            >
              <ProductLongDescription productData={productData} />
            </div>
            <div
              className="itemdetail-addtocart"
              data-region="itemDetailAddToCartRegion"
              style={{ display: "block" }}
            >
              <ProductAddToCartSection
                productId={productId}
                productData={productData}
                priceData={priceDetails}
              />
            </div>
          </div>
          {/** TODO: PD Note - Figure out if this is needed/used. */}
          <BundleConstituentsDisplayMain
            productData={productData}
            itemDetailLink={itemDetailLink}
          />
          <PDPComponents
            productData={productData}
            currentTab={currentTab}
            productDocuments={productDocuments}
            productId={productId}
            titleRef={titleRef}
            productDetailsTabsRef={productDetailsTabsRef}
          />
          {/** TODO: PD Note - Figure out if this is needed/used. */}
          <IndiRecommendationsDisplayMain
            render={["carousel", "product"]}
            configuration={config.indi}
            keywords={productData._code[0].code}
          />
        </div>
      </HelmetProvider>
    );
  }
  return <div className="loader" />;
};

export default FunctionalProductDisplayItemMain;
