import React, { useEffect, useState, useLayoutEffect } from "react";
import jsonpointer from "jsonpointer";
import {
  createLink,
  getImageUrl,
  parseAndRewriteLinks,
  getNestedObject,
  CmsEditButton
} from "bloomreach-experience-react-sdk";

const EnhancedListGrid = props => {
  const { configuration, pageModel, preview } = props;

  const params = getNestedObject(configuration, ["_meta", "params"]);

  const titleFontColor = params.titleColor ? params.titleColor : null;
  const titleBGColor = params.titleBGColor ? params.titleBGColor : null;

  const titleStyle = {
    backgroundColor: titleBGColor,
    color: titleFontColor
  };

  const contentItems = getNestedObject(configuration, [
    "models",
    "pageable",
    "items"
  ]);
  const refs = contentItems.map(item => item.$ref);
  const contents = refs.map(ref => jsonpointer.get(pageModel, ref));

  let itemClass = "grid-item col-12 col-lg-6";
  if (contents.length > 2) {
    itemClass = "grid-item col-6 col-lg-4";
  }

  const renderItems = () => {
    return contents.map((content, index) => {
      return (
        <div className={itemClass} key={content.id}>
          <CmsEditButton configuration={content} preview={preview} />
          <a href={content.url}>
            <img
              src={getImageUrl(content.image, pageModel, preview)}
              alt={content.title}
            />
            <div className="item-title" style={titleStyle}>
              <h3>{content.title}</h3>
            </div>
            <div className="item-text" style={titleStyle}>
              {parseAndRewriteLinks(content.content, preview)}
            </div>
          </a>
        </div>
      );
    });
  };

  return (
    <div className="list-grid-container container-fluid">
      <div className="container">
        <div className="row">{renderItems()}</div>
      </div>
    </div>
  );
};

export default EnhancedListGrid;
