/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { useState, useEffect, useContext } from "react";
import intl from "react-intl-universal";
import { Link } from "react-router-dom";
import { RenderCmsComponent } from "bloomreach-experience-react-sdk";
import "./paymentmethod.container.less";
import { Messagecontainer } from "@zilker/store-components";
import RichText from "../Bloomreach/components/RichText/rich-text";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

interface PaymentMethodContainerProps {
  selectedPayment: string;
  changePayment: (...args: any[]) => any;
  submitCreditCardPayment?: (...args: any[]) => any;
  isDelivery: boolean;
  blockKinpay: boolean;
  missingFields: string;
  resetMissingFields: () => void;
  selectedPaymentMethodError: string;
  cashUserOnlyError: string;
  openChat: (...args: any[]) => any;
}

export enum PaymentMethods {
  creditLine = "CREDIT_ACCOUNT",
  cash = "CASH",
  kinpay = "KINPAY",
  billToClient = "BILL_TO_CLIENT"
}

const PaymentMethodContainer: React.FC<PaymentMethodContainerProps> = (
  props: PaymentMethodContainerProps
) => {
  const {
    selectedPayment,
    changePayment,
    submitCreditCardPayment,
    isDelivery,
    blockKinpay,
    missingFields,
    resetMissingFields,
    selectedPaymentMethodError,
    cashUserOnlyError,
    openChat
  } = props;

  const [accepted, setAccepted] = useState<boolean>(false);
  const context = useContext<{ user: any; cart: any }>(MainContext);
  const {
    user: {
      userProfile: {
        creditCardAllowed,
        creditLineAllowed,
        cashAllowed,
        isTSMDealer
      }
    }
  } = context;

  const { config }: { config: IEpConfig } = getConfig();

  function handleOptionChange(e) {
    // eslint-disable-next-line no-nested-ternary
    const payment = e.target.value
      ? e.target.value
      : cashAllowed === "true"
      ? PaymentMethods.cash
      : PaymentMethods.kinpay;

    changePayment(payment);
    if (selectedPayment === PaymentMethods.kinpay) {
      setAccepted(true);
    }
  }

  function handleCheckboxChange(e) {
    setAccepted(!accepted);
  }

  return (
    <div className="payment-method-container">
      <h3
        className={`section-subtitle ${
          selectedPaymentMethodError || cashUserOnlyError
            ? "validation-error-border"
            : ""
        }`}
      >
        <span className="star">*</span>
        {intl.get("payment-method")}
      </h3>
      <p className="section-subtitle-error">{selectedPaymentMethodError}</p>
      {cashUserOnlyError && (
        <div className="section-subtitle-error">
          <p className="subtitle-error-paragraph">
            {intl.get("credit-line-unavailable-pt1")}
            <span>{intl.get("credit-line-unavailable-pt2")}</span>
            {intl.get("credit-line-unavailable-pt3")}
            <button type="button" className="support-btn" onClick={openChat}>
              {intl.get("support-button")}
            </button>
          </p>
          <p className="subtitle-error-paragraph">
            {intl.get("credit-line-unavailable-pt4")}
          </p>
        </div>
      )}
      <div className="row">
        {config.cortexApi.scope !== "motili" ? (
          <div className="col-12 col-md-6">
            <div className="form-group">
              <div className="form-check">
                <input
                  type="radio"
                  id="credit-line"
                  value={PaymentMethods.creditLine}
                  className="form-check-input"
                  onChange={handleOptionChange}
                  checked={selectedPayment === PaymentMethods.creditLine}
                  disabled={creditLineAllowed === "false"}
                />
                <label className="form-check-label" htmlFor="credit-line">
                  {intl.get(PaymentMethods.creditLine)}
                </label>
              </div>
            </div>

            <div className="form-group">
              <div className="form-check">
                <input
                  type="radio"
                  id="cash"
                  value=""
                  className="form-check-input"
                  onChange={handleOptionChange}
                  disabled={
                    cashAllowed === "false" && creditCardAllowed === "false"
                  }
                  checked={
                    selectedPayment === PaymentMethods.cash ||
                    selectedPayment === PaymentMethods.kinpay
                  }
                />
                <label className="form-check-label" htmlFor="cash">
                  {intl.get("cash-payment")}
                </label>
              </div>
            </div>

            <div
              className={`cash-options ${
                selectedPayment === PaymentMethods.cash ||
                selectedPayment === PaymentMethods.kinpay
                  ? "selected-cash"
                  : ""
              }`}
            >
              <div className="form-group">
                <div className="form-check">
                  <input
                    type="radio"
                    id="cash-payment"
                    value={PaymentMethods.cash}
                    className="form-check-input"
                    onChange={handleOptionChange}
                    checked={selectedPayment === PaymentMethods.cash}
                    disabled={cashAllowed === "false"}
                  />
                  <label className="form-check-label" htmlFor="cash-payment">
                    {intl.get(PaymentMethods.cash)}
                  </label>
                </div>
              </div>
              {!isTSMDealer && (
                <div className="form-group">
                  <div className="form-check">
                    <input
                      type="radio"
                      id="credit-card"
                      value={PaymentMethods.kinpay}
                      className="form-check-input"
                      onChange={handleOptionChange}
                      checked={selectedPayment === PaymentMethods.kinpay}
                      disabled={creditCardAllowed === "false"}
                    />
                    <label className="form-check-label" htmlFor="credit-card">
                      {intl.get(PaymentMethods.kinpay)}
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="col-12 col-md-6">
            <div className="form-group">
              <div className="form-check">
                <input
                  type="radio"
                  id="billed-to-client"
                  value={PaymentMethods.billToClient}
                  className="form-check-input"
                  onChange={handleOptionChange}
                  checked={selectedPayment === PaymentMethods.billToClient}
                />
                <label className="form-check-label" htmlFor="billed-to-client">
                  {intl.get(PaymentMethods.billToClient)}
                </label>
              </div>
            </div>
          </div>
        )}

        <div className="col-12 col-md-6">
          {selectedPayment === PaymentMethods.kinpay && !blockKinpay ? (
            <div className="content-box">
              {isDelivery ? (
                <div className="disclaimer">
                  <RenderCmsComponent
                    path="main/checkout"
                    renderComponent={RichText}
                  />
                  <div className="form-group">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="accept"
                        value="accept"
                        className="form-check-input"
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="accept">
                        {intl.get("Accept")}
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="pay-button">
                <button
                  className="ep-btn primary"
                  aria-label={intl.get("pay-with-credit-card")}
                  type="submit"
                  disabled={isDelivery && !accepted}
                  onClick={submitCreditCardPayment}
                >
                  {intl.get("pay-with-credit-card")}
                </button>
              </div>
              {missingFields && (
                <Messagecontainer
                  message={{
                    type: "",
                    debugMessages: missingFields
                  }}
                  closeContainerHandler={resetMissingFields}
                />
              )}
            </div>
          ) : null}
          {blockKinpay && (
            <p className="kinpay-block-error-message">
              {intl.get("locked-contract-error-1")}{" "}
              <Link to="contactus">{intl.get("contact-us").toLowerCase()}</Link>
              {intl.get("locked-contract-error-2")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodContainer;
