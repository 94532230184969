import React, { useEffect } from "react";
import jsonpointer from "jsonpointer";
import {
  getImageUrl,
  parseAndRewriteLinks,
  getNestedObject,
  CmsEditButton
} from "bloomreach-experience-react-sdk";
import $ from "jquery";
import { isLinkExternal } from "../../../../../app/src/utils/helpers";
import useBanners from "../../../CustomHooks/useBanners";

import "./carousel.less";

const Carousel = props => {
  const { configuration, pageModel, preview } = props;
  const data = getNestedObject(configuration, ["models", "pageable", "items"]);
  const params = getNestedObject(configuration, ["_meta", "paramsInfo"]);
  const refs = data.map(item => item.$ref);
  const contents = refs.map(ref => jsonpointer.get(pageModel, ref));

  const banners = useBanners(contents);

  useEffect(() => {
    $(".carousel-container .carousel").carousel();
  });

  const editButton = preview ? (
    <CmsEditButton configuration={configuration} preview={preview} />
  ) : null;

  const containerStyles = {} as any;
  if (params.carouselBackgroundColor) {
    containerStyles.backgroundColor = params.carouselBackgroundColor;
  }

  const renderBannerLink = (banner: any) => {
    const buttonStyle = {} as any;
    if (banner.buttonBGColor) {
      buttonStyle.backgroundColor = banner.buttonBGColor;
    }
    if (banner.url) {
      const [isExternal, url] = isLinkExternal(banner.url);
      if (isExternal) {
        return (
          <a
            href={url}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            className="ep-btn cta"
            style={buttonStyle}
          >
            {banner.linktitle ? banner.linktitle : "Read More"}
          </a>
        );
      }
      return (
        <a href={url} className="ep-btn cta" style={buttonStyle}>
          {banner.linktitle ? banner.linktitle : "Read More"}
        </a>
      );
    }
    return null;
  };

  const renderCarouselIndicator = () => {
    return banners.length > 1
      ? banners.map((banner, index) => {
          return (
            <li
              key={banner.id}
              data-target="#carouselMain"
              data-slide-to={index}
              className={`${index === 0 ? "active" : ""}`}
            />
          );
        })
      : null;
  };

  const renderCarouselItems = () => {
    let hasBackground = false;

    return banners.map((banner, index) => {
      const carouselStyle = {} as any;
      if (banner.backgroundImage) {
        hasBackground = true;

        const backgroundImage = getImageUrl(
          banner.backgroundImage,
          pageModel,
          preview
        );
        carouselStyle.backgroundImage = `url(${backgroundImage})`;
        carouselStyle.backgroundSize = "cover";
      }
      return (
        <div
          className={`carousel-item ${index === 0 ? "active" : ""} ${
            hasBackground ? "carousel-item-background" : ""
          }`}
          key={banner.id}
          style={carouselStyle}
        >
          <CmsEditButton
            configuration={banner}
            preview={preview}
            aria-label={banner.title}
          />
          <div className="row marquee-width">
            <div
              className="col-12 col-lg-6 p-0 d-flex align-items-center1"
              style={{ zIndex: 1 }}
            >
              <div className="carousel-caption">
                {banner.title && <h3>{banner.title}</h3>}
                <div className="carousel-body">
                  {parseAndRewriteLinks(banner.content.value, preview)}
                </div>
                <div className="carousel-link">{renderBannerLink(banner)}</div>
              </div>
            </div>
            <div className="col-12 col-lg-6 carousel-image p-0">
              {banner.image && (
                <img
                  src={getImageUrl(banner.image, pageModel, preview)}
                  alt={banner.title}
                />
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className="carousel-container d-flex justify-content-center"
      style={containerStyles}
    >
      {editButton && editButton}
      <div
        id="carouselMain"
        className="carousel slide"
        data-ride={params.cycle ? "carousel" : false}
        data-pause={params.pause ? "hover" : false}
      >
        <div className="carousel-inner">{renderCarouselItems()}</div>
        {params.showNavigation ? (
          <ol className="carousel-indicators">{renderCarouselIndicator()}</ol>
        ) : null}
      </div>
    </div>
  );
};

export default Carousel;
