import { httpGetNoToken } from "./HttpService";
import Config from "../ep.config.json";
import { getBRCookie } from "../utils/helpers";

const { scope } = Config.cortexApi;

function generateServiceUrl(
  type: string,
  url: string,
  refUrl: string,
  branchIds?: string[]
) {
  const {
    baseUri,
    accountId,
    domainKey,
    widgetId,
    widgetType,
    rows,
    fieldList,
    filterByBranchAvailability
  } = Config.bloomreachWidget.config[type];

  const brUID2 = getBRCookie(); // cookie parameter _br_uid_2
  const requestId = localStorage.getItem("request_id");
  let branchQuery = "";
  if (branchIds) {
    const branchesAvailableStr = filterByBranchAvailability
      ? encodeURIComponent(
          "branches_available".concat(":(", branchIds.join(" OR "), ")")
        )
      : "";
    branchQuery = "&filters=".concat(branchesAvailableStr);
  }

  return `${baseUri}/${widgetType}/${widgetId}?${brUID2}&account_id=${accountId}&url=${url}&domain_key=${domainKey}&ref_url=${refUrl}&request_id=${requestId}&view_id=${scope}&fields=${fieldList}&rows=${rows}`;
}

// eslint-disable-next-line import/prefer-default-export
export async function getItemWidget(
  itemIds: string,
  url: string,
  refUrl: string = "",
  branchIds?: string[]
) {
  const type = "recommendedProducts";
  const itemWidgetUrl = `${generateServiceUrl(
    type,
    url,
    refUrl,
    branchIds
  )}&item_ids=${itemIds}`;

  return httpGetNoToken(itemWidgetUrl);
}

export async function getSimilarProductsWidget(
  itemIds: string,
  url: string,
  itemClass: string,
  refUrl: string = "",
  tonnage?: string
) {
  const type = "similarProducts";
  const productClassesArray = [
    "Split Air Conditioner",
    "Split Heat Pump",
    "Coil",
    "Air Handler"
  ];
  const isClassFiltered = productClassesArray.includes(itemClass);
  const filterUrl =
    isClassFiltered && tonnage
      ? `&filter=tonnage:"${tonnage}"&sort_by=efficiency+asc&filter=class:"${itemClass}"`
      : "";
  const similarProdsUrl = `${generateServiceUrl(
    type,
    url,
    refUrl
  )}&item_ids=${itemIds}${filterUrl}`;

  return httpGetNoToken(similarProdsUrl);
}
