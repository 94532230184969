import React, { useState } from "react";
import * as intl from "react-intl-universal";
import { extractPhoneAndFax } from "@elasticpath/ref-store/src/utils/helpers";
import Modal from "react-responsive-modal";

import SingleBranch from "../SingleBranch/SingleBranch";
import { BranchDetails } from "../DefaultBranchInfoCard/DefaultBranchInfoCard";

import "./BranchListComponent.less";

interface BranchListComponentProps {
  branches: Array<BranchDetails>;
  homeBranch: BranchDetails;
  changeBranch?: (...args: any[]) => any;
}

const BranchListComponent: React.FC<BranchListComponentProps> = ({
  branches,
  homeBranch,
  changeBranch
}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseConfirmModal = (): void => {
    setLoading(false);
    setOpenConfirmModal(false);
  };

  const handleOpenConfirmModal = e => {
    const branchNumber: string = e.target.name;
    setSelectedBranch(branchNumber);
    setOpenConfirmModal(true);
  };

  return (
    <div className="branch container">
      <Modal open={openConfirmModal} onClose={handleCloseConfirmModal}>
        <div className="branch-select-modal">
          <div className="modal-header">
            <h2 className="modal-title">{intl.get("change-branch")}</h2>
          </div>
          <p>{intl.get("change-branch-info")}</p>
          <div className="confirm-btn">
            {!loading ? (
              <button
                aria-label={intl.get("confirm")}
                type="button"
                className="ep-btn primary"
                onClick={() => {
                  setLoading(true);
                  changeBranch(selectedBranch, handleCloseConfirmModal);
                }}
              >
                {intl.get("confirm")}
              </button>
            ) : (
              <div className="miniLoader" />
            )}
          </div>
        </div>
      </Modal>
      {branches.map(branch => {
        return (
          <SingleBranch
            key={branch.branchNumber}
            branchName={branch.branchName}
            branchNumber={branch.branchNumber}
            address={branch.formattedAddress}
            phone={extractPhoneAndFax(branch).phone}
            fax={extractPhoneAndFax(branch).fax}
            email={branch.email}
            userHomeBranch={homeBranch}
            openModal={handleOpenConfirmModal}
          />
        );
      })}
    </div>
  );
};

export default BranchListComponent;
