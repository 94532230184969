import React, { useEffect, useState, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import jsonpointer from "jsonpointer";
import uuidv4 from "uuid/v4";

import {
  createLink,
  getImageUrl,
  parseAndRewriteLinks,
  getNestedObject,
  CmsEditButton
} from "bloomreach-experience-react-sdk";
import { getConfig } from "../../../utils/ConfigProvider";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const EnhancedListCarouselTitle = props => {
  const { configuration, pageModel, preview, displayMode } = props;
  const [width, height] = useWindowSize();

  const params = getNestedObject(configuration, ["_meta", "paramsInfo"]);
  const titleFontColor = params.titleColor ? params.titleColor : null;
  const titleBGColor = params.titleBGColor ? params.titleBGColor : null;
  const secondaryView = params.displayMode === "carousel_titled_secondary";
  const listItemClasses = secondaryView
    ? "col-12 col-md-6 col-lg-4"
    : "col-12 col-md-4 col-lg-3";

  const titleStyle = {
    backgroundColor: titleBGColor,
    color: titleFontColor
  };

  const contentItems = getNestedObject(configuration, [
    "models",
    "pageable",
    "items"
  ]);

  if (!contentItems) {
    return <span>No content items</span>;
  }
  const refs = contentItems.map(item => item.$ref);
  const contents = refs.map(ref => jsonpointer.get(pageModel, ref));
  const originalContents = refs.map(ref => jsonpointer.get(pageModel, ref));

  const mobileWidth = 375;
  const tabletWidth = 768;

  const chunks = [];
  let chunkSize = 0;
  if (secondaryView) {
    chunkSize = 3;
    if (width <= mobileWidth) {
      chunkSize = 1; // Mobile
    } else if (width <= tabletWidth) {
      chunkSize = 3; // Tablet
    }
  } else {
    chunkSize = 8;
    if (width <= mobileWidth) {
      chunkSize = 1; // Mobile
    } else if (width <= tabletWidth) {
      chunkSize = 3; // Tablet
    }
  }

  while (contents.length) {
    chunks.push(contents.splice(0, chunkSize));
  }

  const renderCarouselIndicator = () => {
    return chunks.map((chunk, index) => {
      return (
        <li
          key={chunk[0].id}
          data-target={`#carousel-title-${configuration.id}`}
          data-slide-to={index}
          className={`${index === 0 ? "active" : ""}`}
        />
      );
    });
  };

  const renderCarouselControls = () => {
    return (
      <>
        <a
          className="carousel-control-prev"
          href={`#carousel-title-${configuration.id}`}
          role="button"
          aria-label="previous slide"
          data-slide="prev"
        >
          <svg
            className="bi bi-chevron-left"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M13.354 3.646a.5.5 0 010 .708L7.707 10l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z"
              clipRule="evenodd"
            />
          </svg>
        </a>
        <a
          className="carousel-control-next"
          href={`#carousel-title-${configuration.id}`}
          role="button"
          aria-label="next slide"
          data-slide="next"
        >
          <svg
            className="bi bi-chevron-right"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z"
              clipRule="evenodd"
            />
          </svg>
        </a>
      </>
    );
  };

  const renderEditButtons = () => {
    return originalContents.map((content, index) => {
      return (
        <div className="p-2" key={uuidv4()}>
          <span>{content.title}</span>
          <CmsEditButton configuration={content} preview={preview} />
        </div>
      );
    });
  };

  const formatLinksState = item => {
    const searchType = item.url.includes("search") ? "keyword" : "category";
    const name = item.url.includes("search") ? "" : item.title;
    const searchTerm = item.url.includes("search")
      ? item.url.split("/search/")[1]
      : "";

    return {
      searchType,
      name,
      searchTerm
    };
  };

  const isLinkExternal = url => {
    const {
      config: {
        pageMetadata: { canonicalURL }
      }
    } = getConfig();

    if (url.includes(canonicalURL)) {
      const internalLink = url.substring(canonicalURL.length);
      return [false, internalLink];
    }
    if (url.startsWith("www") || url.startsWith("http")) {
      return [true, url];
    }
    return [false, url];
  };

  const renderItems = () => {
    return chunks.map((items, index) => {
      return (
        <div
          className={`carousel-item ${index === 0 ? "active" : ""}`}
          key={items[0].id}
        >
          {items.map(item => {
            const [external, url] = isLinkExternal(item.url);
            return (
              <div className={`list-item ${listItemClasses}`} key={item.id}>
                {external ? (
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    <div className="w-100 custom-border">
                      <img
                        src={getImageUrl(item.image, pageModel, preview)}
                        alt={item.title}
                      />
                    </div>
                    <h3>{item.title}</h3>
                    <div>{parseAndRewriteLinks(item.content, preview)}</div>
                  </a>
                ) : (
                  <NavLink
                    to={{
                      pathname: url,
                      state: formatLinksState(item)
                    }}
                  >
                    <div className="w-100 custom-border">
                      <img
                        src={getImageUrl(item.image, pageModel, preview)}
                        alt={item.title}
                      />
                    </div>
                    <h3>{item.title}</h3>
                    <div>{parseAndRewriteLinks(item.content, preview)}</div>
                  </NavLink>
                )}
              </div>
            );
          })}
        </div>
      );
    });
  };

  const renderTitle = () => {
    if (secondaryView) {
      return (
        <div className="container-fluid" style={titleStyle}>
          <div className="container carousel-title">
            <h2>{params.title}</h2>
            <div className="carousel-title-controls">
              {chunks.length > chunkSize && renderCarouselControls()}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid carousel-title" style={titleStyle}>
        <div className="container">
          <h2>{params.title}</h2>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`carousel-with-title-container ${
          secondaryView ? "secondary" : ""
        }`}
      >
        {renderTitle()}
        <div className="container-fluid">
          <div className="container">
            {preview && (
              <div className="br-edit-menu">
                <div className="title">
                  <h2>Content Edit Menu</h2>
                  <small>This will not appear on the website</small>
                </div>
                <div className="menu d-flex">{renderEditButtons()}</div>
              </div>
            )}
            <div
              id={`carousel-title-${configuration.id}`}
              className="carousel slide row"
            >
              <div className="carousel-inner">{renderItems()}</div>
              {!secondaryView &&
                chunks.length > chunkSize &&
                renderCarouselControls()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnhancedListCarouselTitle;
