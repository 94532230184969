import React, { useState, useEffect, useRef } from "react";
import intl from "react-intl-universal";
import uuidv4 from "uuid/v4";
import {
  MatchUpAside,
  MatchUpBundle,
  page,
  getConfig
} from "@zilker/store-components";
import { Redirect, withRouter, RouteComponentProps } from "react-router-dom";

import { getMatchUps } from "../services/connectServices";
import { useMainContext } from "../contexts/MainContext";
import "./MatchUpPage.less";
import { checkTokensExpired, pushToMaintenace } from "../utils/helpers";
import { MatchUpBundleInterface } from "../../../components/src/MatchUpBundle/MatchUpBundle";

interface MatchUpPageProps extends RouteComponentProps {
  history: any;
  match: any;
}

const MatchUpPage: React.FC<MatchUpPageProps> = props => {
  const domain = window.location.hostname;
  const [matchUp, setMatchUp] = useState<MatchUpBundleInterface[] | undefined>(
    undefined
  );

  const [open, setOpen] = useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<any>();

  const mainContext = useMainContext();
  const {
    auth,
    cart: {
      cartDetails: { defaultCart }
    },
    branches: { findBranch }
  } = mainContext;
  const { isLoggedIn, logout } = auth;
  const { history } = props;

  const { config } = getConfig();

  useEffect(() => {
    page();
  }, []);

  useEffect(() => {
    if (defaultCart) {
      const branch = findBranch(defaultCart.selectedBranch.code);
      setSelectedBranch(branch);
      getMatchUps(
        defaultCart.selectedBranch.code,
        config.cortexApi.scope.toUpperCase()
      )
        .then(({ data }) => {
          setMatchUp(data);
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => getMatchUps => MatchUpPage.tsx"
              })
            );
          } else if (
            defaultCart.selectedBranch.vendor !== "GOODMAN" &&
            config.cortexApi.scope === "motili"
          ) {
            /* See DGE-3133 and DGE-3143. This is related only to Motili store, if the distributor branch
              is other than "GOODMAN". 
            */
            console.error("getMatchUps => MatchUpPage.tsx", e);
            setMatchUp([]);
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "getMatchUps => MatchUpPage.tsx"
            });
          }
        });
    }
  }, [defaultCart]);

  const bundleRefs = {};

  const bundleContainer = useRef(null);

  function scrollBundles(id: string): void {
    const current = bundleRefs[id];
    current.scrollIntoView();
    if (open) {
      setOpen(!open);
    }
    // const isScrollDirUp =
    //   bundleContainer.current.getBoundingClientRect().top < 0 &&
    //   bundleContainer.current.scrollTop > current.offsetTop;

    // if (isScrollDirUp) {
    //   const scrollTargetPosition = bundleContainer.current.getBoundingClientRect()
    //     .top;
    //   const elementToScroll = window;
    //   elementToScroll.scrollTo(0, scrollTargetPosition);
    // }

    // const scrollTargetPosition = current.offsetTop;
    // const elementToScroll = bundleContainer.current;

    // elementToScroll.scrollTo(0, scrollTargetPosition);
  }

  function getAsideMatchups() {
    return matchUp.map(match => {
      return (
        <MatchUpAside
          key={uuidv4()}
          matchUp={match}
          scrollBundles={scrollBundles}
        />
      );
    });
  }

  function getBundles() {
    return matchUp.map(bundle => {
      return (
        <MatchUpBundle key={uuidv4()} bundle={bundle} bundleRefs={bundleRefs} />
      );
    });
  }

  const toggleMenu = (): string => {
    if (window.innerWidth <= 768) {
      return open ? "open" : "";
    }
    return "";
  };

  if (!isLoggedIn) return <Redirect to="/" />;

  if (!matchUp)
    return (
      <div className="matchUpMain">
        <div className="loader" />
      </div>
    );

  if (!matchUp.length) {
    return (
      <div className="container matchUpMain">
        <div className="noBundles content-box">
          <i className="icon-exclamation" />
          {intl.get("no-matchup")}
        </div>
      </div>
    );
  }

  return (
    <div className="container matchUpMain">
      <button
        type="button"
        onClick={() => setOpen(!open)}
        onKeyDown={() => setOpen(!open)}
        className="match-up-nav content-box"
      >
        {intl.get("match-up")}
      </button>
      <aside className={`matchupMainAside ${toggleMenu()} content-box`}>
        {getAsideMatchups()}
      </aside>
      <div className="bundles" ref={bundleContainer}>
        <h3 className="bullet">
          {domain} {intl.get("manufactoring")}
        </h3>
        {selectedBranch && (
          <h4 className="branch-name">
            {intl.get("branch")} {selectedBranch.branchName}{" "}
            {intl.get("match-up")}
          </h4>
        )}
        {getBundles()}
      </div>
    </div>
  );
};

export default withRouter(MatchUpPage);
