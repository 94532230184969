import React, { useState } from "react";
import intl from "react-intl-universal";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { simpleToLocaleDate } from "@elasticpath/ref-store/src/utils/helpers";
import { OrderItemData } from "../../../app/src/containers/OrderHistoryPage";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

import "./OrderItem.less";

interface OrderItemProps extends RouteComponentProps {
  history: any;
  data: OrderItemData;
  onReorderClick: (any?) => any;
  isLoadingReorder: boolean;
  clickedItemId: number;
  noOp: () => any;
  showCheckbox: boolean;
  itemSelected?: boolean;
  onOrderItemSelected: (id: string) => void;
  isInvoice: boolean;
}

const OrderItem: React.FC<OrderItemProps> = ({
  history,
  data,
  onReorderClick,
  clickedItemId,
  isLoadingReorder,
  noOp,
  showCheckbox = false,
  itemSelected = false,
  onOrderItemSelected,
  isInvoice = false
}) => {
  const [inHover, setHover] = useState<boolean>(false);
  const { config }: { config: IEpConfig } = getConfig();
  const {
    orderHistoryShowJob,
    orderHistoryShowTotal,
    orderHistoryShowBranchNumber
  } = config;

  const itemDetailsPath = isInvoice ? "invoiceDetails" : "orderDetails";
  const ariaLabelText = `${
    isInvoice ? intl.get("invoice") : intl.get("order")
  } ${data.orderNumber} ${intl.get("details")}`;

  const onInputChecked = (e): void => {
    e.stopPropagation();
    const { id } = e.target;
    onOrderItemSelected(id);
  };

  const displayOrderTotal = (): JSX.Element => {
    const orderTotal: string =
      (data.orderStatusLabel &&
        data.orderStatusLabel.toLowerCase() === "invoiced") ||
      (data.orderStatusCode && data.orderStatusCode.toLowerCase() === "i")
        ? `$${data.total}`
        : "";

    const orderTotalElement: JSX.Element = (
      <td>
        <Link
          to={`/myAccount/${itemDetailsPath}/${data.orderNumber}`}
          onClick={e => e.stopPropagation()}
          className={inHover ? "highlight-text" : ""}
          aria-label={ariaLabelText}
        >
          <span className="small-table-label">{intl.get("total")}:</span>
          <span>{orderTotal}</span>
        </Link>
      </td>
    );

    return orderTotalElement;
  };

  return (
    <tr
      className="tbody-row table-item cursor-pointer"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      key={data.id}
      onClick={() =>
        history.push(`/myAccount/${itemDetailsPath}/${data.orderNumber}`)
      }
    >
      <td className={showCheckbox ? "order-item-checkbox-td" : ""}>
        {showCheckbox && (
          <label
            className="order-item-label"
            htmlFor="order-item"
            id="order-item-label"
          >
            <input type="checkbox" name="order-item" id="checkbox" />
            <span
              className={`order-item-checkbox ${
                itemSelected ? "show-checkmark" : "hide-checkmark"
              }`}
              onClick={onInputChecked}
              onKeyDown={onInputChecked}
              role="checkbox"
              aria-checked="false"
              tabIndex={0}
              aria-labelledby="order-item-label"
              id={data.orderNumber}
            />
          </label>
        )}
        <Link
          to={`/myAccount/${itemDetailsPath}/${data.orderNumber}`}
          onClick={e => e.stopPropagation()}
          className={`${inHover ? "highlight-text" : ""} ${
            showCheckbox ? "order-item-link" : ""
          }`}
          aria-label={ariaLabelText}
        >
          <span className="small-table-label">{intl.get("order-number")}:</span>
          <span className={`${showCheckbox ? "order-item-number" : ""}`}>
            {data.orderNumber || ""}
          </span>
        </Link>
      </td>
      <td>
        <Link
          to={`/myAccount/${itemDetailsPath}/${data.orderNumber}`}
          onClick={e => e.stopPropagation()}
          className={inHover ? "highlight-text" : ""}
          aria-label={ariaLabelText}
        >
          <span className="small-table-label">{intl.get("ordered-date")}:</span>
          <span>{simpleToLocaleDate(data.orderPlacedDate) || ""}</span>
        </Link>
      </td>
      {orderHistoryShowBranchNumber && (
        <td>
          <Link
            to={`/myAccount/${itemDetailsPath}/${data.orderNumber}`}
            onClick={e => e.stopPropagation()}
            className={inHover ? "highlight-text" : ""}
            aria-label={ariaLabelText}
          >
            <span className="small-table-label">
              {intl.get("branch-number")}:
            </span>
            <span>{`${data.branchNumber}` || ""}</span>
          </Link>
        </td>
      )}
      <td>
        <Link
          to={`/myAccount/${itemDetailsPath}/${data.orderNumber}`}
          onClick={e => e.stopPropagation()}
          className={inHover ? "highlight-text" : ""}
          aria-label={ariaLabelText}
        >
          <span className="small-table-label">{intl.get("p-o-number")}:</span>
          <span>{data.purchaseOrderNumber || ""}</span>
        </Link>
      </td>
      {orderHistoryShowJob && (
        <td>
          <Link
            to={`/myAccount/${itemDetailsPath}/${data.orderNumber}`}
            onClick={e => e.stopPropagation()}
            className={inHover ? "highlight-text" : ""}
            aria-label={ariaLabelText}
          >
            <span className="small-table-label">{intl.get("job")}:</span>
            <span>{`${data.jobNumber || ""} ${data.jobName || ""}`}</span>
          </Link>
        </td>
      )}
      <td>
        <Link
          to={`/myAccount/${itemDetailsPath}/${data.orderNumber}`}
          onClick={e => e.stopPropagation()}
          className={inHover ? "highlight-text" : ""}
          aria-label={ariaLabelText}
        >
          <span className="small-table-label">{intl.get("status")}:</span>
          <span>
            {data.orderStatusLabel || ""}
            {data.backOrdered ? `/ ${intl.get("back-ordered")}` : ""}
          </span>
        </Link>
      </td>
      {orderHistoryShowTotal ? displayOrderTotal() : null}
      {/* <td>
        <div className="d-flex flex-column flex-lg-row justify-content-center">
          <span
            data-id={data.orderNumber}
            className="reorder-button ep-btn ico-button"
            onClick={onReorderClick}
            onKeyDown={noOp}
            role="button"
            tabIndex={0}
          >
            {isLoadingReorder && clickedItemId === data.orderNumber ? (
              <span className="miniLoader" />
            ) : (
              <i className="icon-reorder" />
            )}
            {intl.get("reorder")}
          </span>
          <Link
            className="ep-btn ico-button align-self-center"
            to={`/myAccount/orderDetails/${data.orderNumber}`}
          >
            <i className="icon-document" />
            {intl.get("view")}
          </Link>
        </div>
      </td> */}
    </tr>
  );
};

export default withRouter(OrderItem);
