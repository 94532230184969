import React from "react";
import {
  getImageUrl,
  parseDate,
  parseAndRewriteLinks
} from "bloomreach-experience-react-sdk";

import "./simple-content.less";

const SimpleContent = props => {
  const { content, pageModel, manageContentButton } = props;
  const image = getImageUrl(content.image, pageModel);

  let contentHtml;
  if (content.content && content.content.value) {
    contentHtml = parseAndRewriteLinks(content.content.value);
  }

  return (
    <div className="container-fluid has-edit-button">
      <div className="container">
        {manageContentButton && manageContentButton}
        <h2 className="blog-post-title">{content.title}</h2>
        <p className="blog-post-meta">
          {content.date && (
            <span className="blog-post-date">{parseDate(content.date)}</span>
          )}
          {content.author && (
            <span className="author">
              <a href="#pagination">{content.author}</a>
            </span>
          )}
        </p>
        {content.introduction && <p>{content.introduction}</p>}
        {image && (
          <figure>
            <img src={image} alt={content.title} />
          </figure>
        )}
        {contentHtml && contentHtml}
      </div>
    </div>
  );
};

export default SimpleContent;
