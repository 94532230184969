/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import {
  RouteComponentProps,
  withRouter,
  Redirect,
  Link
} from "react-router-dom";
import intl from "react-intl-universal";
import {
  OrderTableMain,
  ShippingOptionContainer,
  CheckoutSummaryList,
  AddressContainer,
  cortexFetch,
  OrderDetailsComponent,
  page,
  getConfig,
  AddPromotionContainer
} from "@zilker/store-components";
import {
  zoomOrderReview,
  zoomPurchase
} from "@zilker/store-components/src/static/zoom";
/* eslint-disable-next-line import/no-useless-path-segments */
import { MainContext } from "../contexts/MainContext";

import "./OrderReviewPage.less";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace,
  noOp,
  formatInventoryAvailability
} from "../utils/helpers";
import { ContractOrder } from "./ContractOrdersPage";

interface OrderReviewPageProps extends React.Component<RouteComponentProps> {
  match: any;
  history: any;
}

interface OrderReviewPageState {
  orderData: any;
  giftCertificateEntity: any;
  isLoading: boolean;
  selectedBranchDetails: any;
  blockComplitingOrder: boolean;
}

let Config;

class OrderReviewPage extends React.Component<
  OrderReviewPageProps,
  OrderReviewPageState
> {
  static contextType = MainContext;

  _isMounted = false;

  constructor(props) {
    super(props);
    Config = getConfig().config;
    this.state = {
      orderData: undefined,
      giftCertificateEntity: [],
      isLoading: false,
      selectedBranchDetails: undefined,
      blockComplitingOrder: false
    };

    this.fetchGiftCards = this.fetchGiftCards.bind(this);
    this.findHasSpecialAirPurifiers = this.findHasSpecialAirPurifiers.bind(
      this
    );
    this.fetchOrderData = this.fetchOrderData.bind(this);
    this.completeOrder = this.completeOrder.bind(this);
    this.goToCheckOut = this.goToCheckOut.bind(this);
    // this.trackTransactionAnalytics = this.trackTransactionAnalytics.bind(this);
    this.renderShippingOption = this.renderShippingOption.bind(this);
    this.renderShippingAddress = this.renderShippingAddress.bind(this);
    this.renderPaymentMethod = this.renderPaymentMethod.bind(this);
    this.renderPromotions = this.renderPromotions.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchOrderData();
    this.fetchGiftCards();

    this.checkBackOrderedItems();

    page();
  }

  componentDidUpdate() {
    const {
      context: {
        cart: {
          cartDetails: { defaultCart }
        },
        branches: { findBranch },
        order: { backOrderedQuantity }
      }
    } = this;
    const { selectedBranchDetails, orderData } = this.state;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    const foundBranch = selectedBranch && findBranch(selectedBranch.code);
    if (!selectedBranchDetails && foundBranch) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedBranchDetails: foundBranch });
    }

    if (orderData && selectedBranchDetails && !backOrderedQuantity.length) {
      this.checkBackOrderedItems();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchOrderData() {
    const { history } = this.props;
    const {
      context: {
        auth: { isLoggedIn, logout },
        cart: {
          cartDetails: { defaultCart }
        },
        branches: { findBranch }
      }
    } = this;
    if (isLoggedIn) {
      const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
      const foundBranch = selectedBranch && findBranch(selectedBranch.code);
      cortexFetch(`/?zoom=${zoomOrderReview.sort().join()}`)
        .then(res => checkResponse(res))
        .then(res => {
          if (this._isMounted) {
            this.setState({
              orderData: res._defaultcart[0],
              selectedBranchDetails: foundBranch
            });
          }
          if (
            res._defaultcart &&
            res._defaultcart[0]._order &&
            res._defaultcart[0]._order[0].messages[0]
          ) {
            const messageData = {
              debugMessages: "",
              type: "",
              id: ""
            };
            let debugMessages = "";
            const messagesArray = res._defaultcart[0]._order[0].messages;
            for (let i = 0; i < messagesArray.length; i++) {
              debugMessages = debugMessages.concat(
                `${messagesArray[i]["debug-message"]} \n `
              );
            }
            messageData.debugMessages = debugMessages;
            messageData.type = messagesArray[0].type;
            messageData.id = messagesArray[0].id;
          }
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => fetchOrderData => OrderReviewPage.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "fetchOrderData => OrderReviewPage.tsx"
            });
          }
        });
    }
  }

  checkBackOrderedItems() {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      order: { checkAvailability },
      user: {
        userProfile: { customerNumber, jobNumber }
      }
    } = this.context;
    if (Config.showBackOrderedQuantity && defaultCart && customerNumber) {
      const { items, selectedBranch } = defaultCart;
      checkAvailability(customerNumber, selectedBranch.code, items, jobNumber);
    }
  }

  fetchGiftCards() {
    const { history } = this.props;
    const {
      context: {
        auth: { isLoggedIn, logout }
      }
    } = this;
    const chosenGiftCertificates =
      JSON.parse(localStorage.getItem("chosenGiftCertificatesArr")) || [];
    chosenGiftCertificates.forEach(card => {
      if (isLoggedIn) {
        cortexFetch(
          `/giftcertificates/${Config.cortexApi.scope}/lookup/form?followlocation=true`,
          {
            method: "post",
            body: JSON.stringify({
              "gift-certificate-code": card
            })
          }
        )
          .then(data => checkResponse(data))
          .then(data => {
            if (this._isMounted) {
              this.setState(prevState => ({
                giftCertificateEntity: [
                  ...prevState.giftCertificateEntity,
                  data
                ]
              }));
            }
          })
          .catch(e => {
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn: "Logout => fetchGiftCards => OrderReviewPage.tsx"
                })
              );
            } else {
              pushToMaintenace(history, {
                e,
                errIn: "fetchGiftCards => OrderReviewPage.tsx"
              });
            }
          });
      }
    });
  }

  updateCartContext = async () => {
    const {
      cart: { setCartDetails, getCartDetails }
    } = this.context;

    setCartDetails(prevState => ({
      ...prevState,
      totalCount: 0
    }));
    await getCartDetails();
  };

  findHasSpecialAirPurifiers() {
    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;
    // PGL-364
    const skus = defaultCart
      ? defaultCart.items &&
        defaultCart.items.map(item => item._item[0]._code[0].code)
      : [];
    return skus.find(sku => sku === "MCB50YSAU" || sku === "MCKB70YSAU");
  }

  async checkContractStatus() {
    const {
      cart: {
        cartDetails: {
          defaultCart: { cartOrderDetailsForm }
        }
      },
      user: {
        userProfile: { customerNumber }
      },
      contract: { fetchContract }
    } = this.context;
    if (cartOrderDetailsForm["contract-number"]) {
      const contract: ContractOrder = await fetchContract(
        customerNumber,
        cartOrderDetailsForm["contract-number"]
      );
      const { status } = contract;

      this.setState({
        blockComplitingOrder: status === "L",
        isLoading: false
      });

      return status === "L";
    }
    return false;
  }

  async completeOrder() {
    if (this._isMounted) {
      this.setState({
        isLoading: true
      });
    }

    // If order is released from the contract we need to check contract's status
    // and block compliting order if contract is locked
    const isContractLocked = await this.checkContractStatus();

    if (isContractLocked) {
      return;
    }

    const { orderData, selectedBranchDetails } = this.state;
    const { history } = this.props;
    const {
      context: {
        auth: { isLoggedIn, logout },
        cart: {
          cartDetails: { defaultCart }
        },
        branches: { findBranch, airPurifierBranch }
      }
    } = this;

    const {
      jobNumber,
      jobName,
      clientId,
      clientName,
      cartOrderDetailsForm
    } = defaultCart;
    // PGL-364
    const hasSpecialAirPurifiers = this.findHasSpecialAirPurifiers();

    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    // PGL-364
    let selectedBranchData = selectedBranch && findBranch(selectedBranch.code);

    if (hasSpecialAirPurifiers) {
      selectedBranchData = airPurifierBranch;
    }

    const purchaseform = orderData._order[0]._purchaseform[0].links.find(
      link => link.rel === "submitorderaction"
    ).uri;

    const appliedPromoCodes = cartOrderDetailsForm["daikin-promo-codes"]
      ? cartOrderDetailsForm["daikin-promo-codes"].split("|").join(", ")
      : null;
    const contractNumber = cartOrderDetailsForm["contract-number"];

    const { order, job, client } = this.context;
    const poNumber = order.poNumber || cartOrderDetailsForm["po-number"];
    // We persist client in the context so that we could use it to associate next order wit
    // the same client
    client.setPersistedClient({ id: clientId, name: clientName });

    // this.trackTransactionAnalytics();

    if (isLoggedIn) {
      cortexFetch(
        `${purchaseform}?followlocation=true&zoom=${zoomPurchase
          .sort()
          .join()}`,
        {
          method: "post"
        }
      )
        .then(res => checkResponse(res))
        .then(res => {
          if (this._isMounted) {
            this.setState({
              isLoading: false
            });
          }

          this.updateCartContext();

          order.setOrderData(orderData);
          order.setPurchaseData(res);
          const analyticsItems = orderData._lineitems[0]._element.map(
            element => {
              return {
                name: element._item[0]._definition[0].details.find(
                  detail => detail.name === "short_description"
                )["display-value"],
                id: element._item[0]._code[0].code,
                price: element._total[0].cost[0].amount,
                brand: element._item[0]._definition[0].details.find(
                  detail => detail.name === "brand"
                )["display-value"],
                category: element._item[0]._definition[0].details.find(
                  detail => detail.name === "product_category"
                )["display-value"],
                variant: "",
                quantity: element.quantity
              };
            }
          );

          order.setBranch(selectedBranchData);
          order.setPromoCodes(appliedPromoCodes);
          order.setContractNumber(contractNumber);
          order.setPoNumber(poNumber);

          if (jobNumber && jobName) {
            order.setJob({ jobNumber, jobName });

            /**
             * After the order is completed, user's session is still active
             * and we can assume the user wants to keep using the same job.
             * That's why we persist the job name and number.
             */

            job.setPersistedJobNumber(jobNumber);
            job.setPersistedJobName(jobName);
          }
          history.push("/purchaseReceipt");
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => completeOrder => OrderReviewPage.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "completeOrder => OrderReviewPage.tsx"
            });
          }
        });
    }
  }

  goToCheckOut() {
    const { history } = this.props;
    history.push("/checkout");
  }

  // trackTransactionAnalytics() {
  //   const { orderData } = this.state;
  //   if (isAnalyticsConfigured()) {
  //     const deliveries = orderData._order[0]._deliveries
  //       ? orderData._order[0]._deliveries[0]._element[0]._shippingoptioninfo[0]
  //           ._shippingoption[0].cost[0].display
  //       : "";
  //     orderData._lineitems[0]._element.map(product => {
  //       const categoryTag = product._item[0]._definition[0].details
  //         ? product._item[0]._definition[0].details.find(
  //             detail => detail["display-name"] === "Tag"
  //           )
  //         : "";
  //       return trackAddItemAnalytics(
  //         orderData.self.uri.split(`/carts/${Config.cortexApi.scope}/`)[1],
  //         product._item[0]._definition[0]["display-name"],
  //         product._item[0]._price[0]["purchase-price"][0].currency,
  //         product._item[0]._price[0]["purchase-price"][0].amount,
  //         categoryTag !== undefined && categoryTag !== ""
  //           ? categoryTag["display-value"]
  //           : "",
  //         product.quantity
  //       );
  //     });
  //   }
  // }

  renderShippingOption() {
    const { orderData, selectedBranchDetails } = this.state;
    // PGL-364
    const {
      branches: { airPurifierBranch }
    } = this.context;
    const hasSpecialAirPurifiers = this.findHasSpecialAirPurifiers();
    const fulfillmentBranchDetails = hasSpecialAirPurifiers
      ? airPurifierBranch
      : selectedBranchDetails;
    const deliveries = orderData._order[0]._deliveries;
    if (deliveries) {
      const [
        option
      ] = orderData._order[0]._deliveries[0]._element[0]._shippingoptioninfo[0]._shippingoption;
      return (
        <div className="order-option-container">
          <h3>{intl.get("fulfillment-method")}</h3>
          <ShippingOptionContainer option={option} />
          <span>{intl.get("branch").toUpperCase()}:</span>
          {fulfillmentBranchDetails && (
            <span>{fulfillmentBranchDetails.branchName.toUpperCase()}</span>
          )}
          {fulfillmentBranchDetails && (
            <span>{fulfillmentBranchDetails.formattedAddress}</span>
          )}
          {fulfillmentBranchDetails && (
            <a href={`mailto:${fulfillmentBranchDetails.email}`}>
              {fulfillmentBranchDetails.email}
            </a>
          )}
        </div>
      );
    }
    return null;
  }

  renderShippingAddress() {
    const { orderData } = this.state;
    const deliveries = orderData._order[0]._deliveries;
    const { history } = this.props;
    if (deliveries) {
      let shippingAddress = null;
      let option = null;
      try {
        [
          shippingAddress
        ] = orderData._order[0]._deliveries[0]._element[0]._destinationinfo[0]._destination;
        [
          option
        ] = orderData._order[0]._deliveries[0]._element[0]._shippingoptioninfo[0]._shippingoption;
      } catch (e) {
        console.error(e);
        pushToMaintenace(history, {
          e,
          errIn: "renderShippingAddress => OrderReviewPage.tsx"
        });
      }

      const { name, address } = shippingAddress;
      return (
        <div className="order-option-container">
          <h3>{intl.get("fulfillment-address")}</h3>
          <AddressContainer name={name} address={address} option={option} />
        </div>
      );
    }
    return null;
  }

  renderBillingAddress() {
    const { orderData } = this.state;
    const [
      billingAddress
    ] = orderData._order[0]._billingaddressinfo[0]._billingaddress;
    const { name, address } = billingAddress;
    return (
      <div className="order-option-container">
        <h3>{intl.get("billing-address")}</h3>
        <AddressContainer name={name} address={address} />
      </div>
    );
  }

  renderPaymentMethod() {
    const { orderData } = this.state;
    const { history } = this.props;
    let displayName;
    try {
      const paymentMethodType =
        orderData._order[0]._paymenttypeinfo[0]._selector[0]._chosen[0]
          ._description[0]["payment-type"];
      displayName = intl.get(paymentMethodType);
    } catch (error) {
      pushToMaintenace(history, {
        e: error,
        errIn: "renderPaymentMethod => OrderReviewPage.tsx"
      });
    }
    return (
      <div className="order-option-container">
        <h3>{intl.get("payment-method")}</h3>
        <span>{displayName}</span>
      </div>
    );
  }

  renderPromotions(cartOrderDetailsForm: any): any {
    const { history } = this.props;
    const { auth } = this.context;

    if (cartOrderDetailsForm) {
      const data = { cartOrderDetailsForm };

      return (
        <AddPromotionContainer
          isDeletingPromoCode={false}
          hideAddPromotionButton
          hideDeletePromotionButton
          data={data}
          history={history}
          auth={auth}
          /** When hideAddPromotionButton and hideDeletePromotionButton are true, onSubmittedPromotion is never called.
           * Thus, passing noOp function as a placeholder for required prop.
           */
          onSubmittedPromotion={noOp}
        />
      );
    }

    return null;
  }

  render() {
    const {
      orderData,
      giftCertificateEntity,
      isLoading,
      selectedBranchDetails,
      blockComplitingOrder
    } = this.state;
    const { history } = this.props;
    const {
      auth,
      branches: { airPurifierBranch },
      cart: {
        cartDetails: { defaultCart }
      },
      order,
      user: {
        userProfile: { isTSMDealer }
      }
    } = this.context;
    const { backOrderedQuantity } = order;

    const jobNumber = defaultCart ? defaultCart.jobNumber : null;
    const jobName = defaultCart ? defaultCart.jobName : null;
    // PGL-364
    const hasSpecialAirPurifiers = this.findHasSpecialAirPurifiers();
    let branch = defaultCart
      ? defaultCart.selectedBranch
      : selectedBranchDetails;
    // PGL-364
    if (hasSpecialAirPurifiers) {
      const annotatedPurifierBranch = {
        code: airPurifierBranch.branchNumber,
        vendor: defaultCart.selectedBranch.vendor
      };
      branch = annotatedPurifierBranch;
    }

    const isValid =
      orderData &&
      orderData._order[0]._purchaseform[0].links[0] &&
      orderData._order[0]._purchaseform[0].links.find(
        link => link.rel === "submitorderaction"
      ).uri;
    const itemDetailLink = "/itemdetail";

    const { defaultChannel } = Config.brXM;
    const showTitle = defaultChannel !== "motili";

    const cartOrderDetailsForm = defaultCart
      ? defaultCart.cartOrderDetailsForm
      : null;
    const contractNumber =
      cartOrderDetailsForm && cartOrderDetailsForm["contract-number"];
    let { poNumber } = order;
    if (!poNumber) {
      poNumber = cartOrderDetailsForm && cartOrderDetailsForm["po-number"];
    }

    if (!auth.isLoggedIn) return <Redirect to={{ pathname: "/" }} />;

    return (
      <div className="app-main">
        {orderData ? (
          <div className="order-container">
            <div className="order-container-inner">
              <div className="order-title-container">
                <h2 className="view-title bullet">
                  {intl.get("review-your-order")}
                </h2>

                {branch ? (
                  <OrderDetailsComponent
                    branch={{ branchNumber: branch.code }}
                    job={{ jobNumber, jobName }}
                    contractNumber={contractNumber}
                    poNumber={poNumber}
                  />
                ) : null}

                <div className="order-options-container">
                  {orderData._order[0]._deliveries &&
                    this.renderShippingOption()}
                  {orderData._order[0]._deliveries &&
                    this.renderShippingAddress()}
                  {orderData._order[0]._billingaddressinfo &&
                    this.renderBillingAddress()}
                  {orderData._order[0]._paymenttypeinfo &&
                    this.renderPaymentMethod()}
                </div>
              </div>
            </div>

            <div className="order-items-container">
              <OrderTableMain
                data={orderData}
                itemDetailLink={itemDetailLink}
              />
            </div>

            <div className="order-checkout-sidebar">
              <div className="order-checkout-sidebar-inner">
                <h4 className="total-title bullet">{intl.get("total")}</h4>
                {Config.enablePromotion &&
                  this.renderPromotions(cartOrderDetailsForm)}

                <div className="order-checkout-summary-container">
                  <CheckoutSummaryList
                    data={orderData}
                    giftCards={giftCertificateEntity}
                    onChange={() => {
                      this.fetchOrderData();
                    }}
                    history={history}
                    auth={auth}
                  />
                </div>
                <div className="checkout-submit-container">
                  <button
                    className="ep-btn primary wide btn-cmd-submit-order"
                    disabled={isTSMDealer || !isValid}
                    type="button"
                    aria-label={intl.get("complete-purchase")}
                    onClick={() => {
                      this.completeOrder();
                    }}
                  >
                    {intl.get("complete-purchase")}
                  </button>
                  <br />
                  <button
                    className="ep-btn wide btn-cmd-edit-order"
                    type="button"
                    aria-label={intl.get("edit")}
                    onClick={this.goToCheckOut}
                  >
                    {intl.get("edit")}
                  </button>

                  <div className="warning-messages">
                    {backOrderedQuantity && backOrderedQuantity.length
                      ? backOrderedQuantity.some(item => item.quantity > 0) && (
                          <p>*{intl.get("backordered-items-warning")}</p>
                        )
                      : null}
                    {Config.calculatePrice ? (
                      <p className="price-disclaimer">
                        *
                        {intl.get(
                          "commodity-prices-may-vary-at-final-checkout"
                        )}
                      </p>
                    ) : null}
                    {blockComplitingOrder && (
                      <p className="block-error-message">
                        {intl.get("locked-contract-error-1")}{" "}
                        <Link to="contactus">
                          {intl.get("contact-us").toLowerCase()}
                        </Link>
                        {intl.get("locked-contract-error-2")}
                      </p>
                    )}
                  </div>
                  {isLoading && <div className="miniLoader" />}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="order-main-container">
            <div className="loader" />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(OrderReviewPage);
