import React from "react";
import "./RegistrationSubmittedModal.less";

// Interface
export interface RegistrationModal {
  message: string;
  onModalClose: (...args: any[]) => any;
}

// Component
function RegistrationSubmittedModal({
  message,
  onModalClose
}: RegistrationModal) {
  return (
    <div className="registration-submitted-modal-background">
      <div className="registration-submitted-modal">
        <button type="button" className="close-modal" onClick={onModalClose}>
          <i className="icon-times" />
        </button>
        <p>{message}</p>
        <button
          aria-label="ok"
          className="ep-btn primary"
          type="button"
          onClick={onModalClose}
        >
          OK
        </button>
      </div>
    </div>
  );
}

export default RegistrationSubmittedModal;
