import React, { FC, useState } from "react";
import intl from "react-intl-universal";
import { getConfig } from "../utils/ConfigProvider";
import { useMainContext } from "../../../app/src/contexts/MainContext.js";

import SearchResultsMenuSection from "./search.results.menu.section";
import SearchResultsBranchSelectionMenu from "./search.results.branch.selection.menu";

import "./search.results.menu.less";

interface SearchResultsMenuProps {
  facets: any;
  filtersChecked: any;
  onFacetClicked: (filter: string) => any;
  selectedBranch?: any;
}

const SearchResultsMenu: FC<SearchResultsMenuProps> = ({
  facets,
  filtersChecked,
  onFacetClicked,
  selectedBranch
}) => {
  const [isOpened, setIsOpen] = useState<boolean>(false);
  const [classNameSuffix, setClassNameSuffix] = useState<string>("");

  const { config } = getConfig();

  const {
    auth: { isLoggedIn },
    branches: { findBranch }
  } = useMainContext();

  const toggleMenu = (): void => {
    if (window.innerWidth <= 768) {
      setIsOpen(!isOpened);
      if (isOpened) setClassNameSuffix("");
      if (!isOpened) setClassNameSuffix("opened");
    }
  };

  const branch = selectedBranch && findBranch(selectedBranch.code);

  return (
    <div className={`search-results-menu ${classNameSuffix}`}>
      {isLoggedIn && config.branchDropDownLocation === "plpLeft" && (
        <SearchResultsBranchSelectionMenu selectedBranch={branch} />
      )}
      <div
        className="search-results-menu-title ep-btn secondary"
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
        role="button"
        aria-hidden="true"
      >
        <i className="icon-filter" />
        <h4>{intl.get("filter")}</h4>
        <i
          title="Search Results Menu"
          aria-label="Open Search Results Menu"
          className={isOpened ? "icon-times" : "icon-plus"}
        />
      </div>
      <div
        className={`search-results-menu-items content-box ${classNameSuffix}`}
      >
        {Object.keys(facets).map((facetName, i) => {
          const key = `${facetName}-${i}`;
          return (
            <SearchResultsMenuSection
              key={key}
              name={facetName}
              data={facets[facetName]}
              filtersChecked={filtersChecked}
              onFacetClicked={onFacetClicked}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SearchResultsMenu;
