import React, { useState, useEffect } from "react";
import { useRouteMatch, Redirect } from "react-router-dom";
import intl from "react-intl-universal";
import Modal from "react-responsive-modal";
import {
  ProfileInfoMain,
  ProfileAddressesMain,
  AddressFormMain,
  NotificationsContainer,
  DefaultBranchInfoCard,
  RichText,
  Messagecontainer,
  page,
  PreferencesContainer,
  getConfig
} from "@zilker/store-components";
import {
  NotificationsStatus,
  NotificationData
} from "@zilker/store-components/src/Notifications/Notifications";
import { RenderCmsComponent } from "bloomreach-experience-react-sdk";
import MessageContainer from "@zilker/store-components/src/MessageContainer/messagecontainer";
import { DebugMessage } from "../../../components/src/MessageContainer/messagecontainer";
import { checkTokensExpired, pushToMaintenace } from "../utils/helpers";
import { updateDefaultProfile } from "../services/EpServices";

import "./ProfilePage.less";

interface UserAddresses {
  self: any;
  messages: Array<any>;
  links: Array<any>;
  _addressform: Array<any>;
  _billingaddresses: Array<any>;
  _element: Array<any>;
}
interface UserProfile {
  customerNumber: string;
  homeBranch: string;
  homeBranchVendor: string;
  familyName: string;
  givenName: string;
  phone: string;
  fax: string;
  preferedCurrency: string;
  preferredLocale: string;
  email: string;
  company: string;
  city: string;
  postalCode: string;
  region: string;
  street: string;
  country: string;
  optInSmsAlert: string;
  optInOrderStatusEmail: string;
  optInDeliveryStatusEmail: string;
  smsPhoneNumbers: any;
  orderStatusEmails: any;
  deliveryStatusEmails: any;
  businessNumber: string;
  dateOfBirth: string;
  htmlEmail: string;
  poNumberRequired: string;
  taxЕxemptionId: string;
  custAttribute1: string;
  custAttribute2: string;
  addresses: Array<UserAddresses>;
  profileUri: string;
  defaultShippingAddress: any;
  extendedBillingAddress: any;
  defaultShippingAddressString: string;
  jobsArray: any;
  subuserFirstName: string;
  subuserLastName: string;
  subuserEmail: string;
  subuserHomeBranch: string;
  subuserHomeBranchVendor: string;
  initialHomeBranch: string;
  initialHomeBranchVendor: string;
  initialMotiliHomeBranch: string;
  initialMotiliHomeBranchVendor: string;
  initialSubUserHomeBranch: string;
  initialSubUserHomeBranchVendor: string;
  initialSubUserMotiliHomeBranch: string;
  initialSubUserMotiliHomeBranchVendor: string;
  subuserPhone: string;
  chargeCreditCode: string;
  creditCardAllowed: string;
  cashAllowed: string;
  creditLineAllowed: string;
  jobNameRequired: string;
  jobNumberRequired: string;
  isCanadianUser: boolean;
  isEligibleForLoyalty: string;
  clientList: any;
  onlineBillPayLink: string;
  companyName: string;
  subUserOptOutMarketing: string;
  optOutMarketing: string;
  accountUsers: any;
  showPriceOnPickedTicket: boolean;
  roles: any;
  subUserRoles: any;
}

interface ProfileInfoInterface {
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  companyName?: string;
}

const initialDebugMessage: DebugMessage = {
  type: "",
  debugMessages: ""
};

interface ResponseMessage {
  redirect: boolean;
  notifications?: DebugMessage;
  preferences?: DebugMessage;
}

interface NewAddress {
  openAddressModal: boolean;
  addressUrl: {
    [key: string]: string;
  };
}

interface ProfilePageProps {
  history: any;
  match: any;
  auth: any;
  user: any;
}

const ProfilePage: React.FC<ProfilePageProps> = ({
  history,
  match,
  auth,
  user
}) => {
  const { userProfile }: { userProfile: UserProfile } = user;

  const { config } = getConfig();
  const { defaultChannel }: { defaultChannel: string } = config.brXM;
  const showPageTitle: boolean = defaultChannel === "motili";

  const primaryUserInfo: ProfileInfoInterface = {
    email: userProfile.email,
    firstName: userProfile.givenName,
    lastName: userProfile.familyName,
    phone: userProfile.phone,
    companyName: userProfile.company
  };

  const subUserInfo: ProfileInfoInterface = {
    email: userProfile.subuserEmail,
    firstName: userProfile.subuserFirstName,
    lastName: userProfile.subuserLastName,
    phone: userProfile.subuserPhone
  };
  // const [dataPolicyData, setDataPolicyData] = useState(null);
  // const [showResetPasswordButton, setShowResetPasswordButton] = useState(null);
  /** 
   * TODO: These are not present in the EP response default profile:
    res is response for user default profile:
    
    if (res && res._passwordresetform) {
    setShowResetPasswordButton({ showResetPasswordButton: true });
    }

    setDataPolicyData({dataPolicyData: res["_data-policies"]
      ? res["_data-policies"][0]
      : null})
  */
  const [newAddress, setNewAddress] = useState<NewAddress>({
    openAddressModal: false,
    addressUrl: { address: "" }
  });

  const path = useRouteMatch().url.includes("myAccount")
    ? "main/myAccount/profile"
    : "main/profile";

  const [responseMessage, setResponseMessage] = useState<ResponseMessage>({
    redirect: false,
    notifications: initialDebugMessage,
    preferences: initialDebugMessage
  });

  const [userMessage, setUserMessage] = useState<string>("");

  const [newAddressUri, setNewAddressUri] = useState<string>("");

  useEffect(() => {
    page();
  }, []);

  const fetchProfileData = async (): Promise<any> => {
    await user.getUserProfile();
  };

  // const changePassword = () => {
  // const history = useHistory();
  //   history.push("/password_change", { returnPage: "/profile" });
  // };

  const handleNewAddress = (): void => {
    setNewAddress({
      openAddressModal: true,
      addressUrl: undefined
    });
  };

  const handleEditAddress = (addressLink: string): void => {
    setNewAddress({
      openAddressModal: true,
      addressUrl: { address: addressLink }
    });
  };

  const handleCloseAddressModal = (): void => {
    setNewAddress(prevState => ({ ...prevState, openAddressModal: false }));
  };

  const closeMessageContainer = (sectionName: string): void => {
    setResponseMessage(prevState => ({
      ...prevState,
      [sectionName]: initialDebugMessage
    }));
  };

  const renderNewAddressModal = () => {
    const { openAddressModal, addressUrl } = newAddress;
    const newOrEdit: string =
      addressUrl && addressUrl.address ? intl.get("edit") : intl.get("new");
    return (
      <Modal open={openAddressModal} onClose={handleCloseAddressModal}>
        <div className="modal-lg new-address-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                {newOrEdit} {intl.get("address")}
              </h2>
            </div>
            <div className="modal-body">
              <AddressFormMain
                onCloseModal={handleCloseAddressModal}
                fetchData={fetchProfileData}
                addressData={addressUrl}
                history={history}
                auth={auth}
                onNewAddressWarning={setNewAddressUri}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const showMessage = (message: string): void => {
    setUserMessage(message);
  };

  const closeMessage = (): void => setUserMessage("");

  /**
   * ## updateProfileSection
   * @param updates any - updated profile fields
   * @param sectionName string - "preferences", "notifications"
   *
   * @description Function calls service for updating default profile,
   * and then refreshes the profile data.
   */
  const updateProfileSection = (updates: any, sectionName: string): void => {
    updateDefaultProfile(updates, userProfile)
      .then(response => {
        const { type } = response;
        const resMessage: DebugMessage = {
          type,
          debugMessages: ""
        };

        resMessage.debugMessages = intl.get(`${sectionName}-alerts-success`);

        setResponseMessage(prevState => ({
          ...prevState,
          [sectionName]: resMessage
        }));
        setTimeout(() => {
          setResponseMessage(prevState => ({
            ...prevState,
            [sectionName]: initialDebugMessage
          }));
        }, 3000);

        // Refresh profile data after the update.
        fetchProfileData();
      })
      .catch(error => {
        console.error(error.message);
        if (checkTokensExpired(error)) {
          auth.logout().catch(logoutErr =>
            pushToMaintenace(history, {
              e: logoutErr,
              errIn: "Logout => updateProfileSection => ProfilePage.tsx"
            })
          );
        } else {
          setResponseMessage(prevState => ({
            ...prevState,
            redirect: true,
            [sectionName]: {
              type: "error",
              debugMessages: intl.get(`${sectionName}-alerts-error`)
            }
          }));
        }
      });
  };

  function updateNotifications(
    notifications: NotificationsStatus,
    userData: NotificationData
  ): void {
    const {
      smsChecked,
      orderStatusChecked,
      deliveryStatusChecked
    } = notifications;

    const { phoneNumbers, orderStatusEmails, deliveryStatusEmail } = userData;

    const updates = {
      "opt-in-sms-alert": smsChecked,
      "opt-in-order-status-email": orderStatusChecked,
      "opt-in-delivery-status-email": deliveryStatusChecked,
      "sms-phone-numbers": smsChecked
        ? phoneNumbers.filter(phoneNumber => phoneNumber).join("|")
        : "",
      "order-status-emails": orderStatusChecked
        ? orderStatusEmails.filter(email => email).join("|")
        : "",
      "delivery-status-emails": deliveryStatusChecked ? deliveryStatusEmail : ""
    };

    updateProfileSection(updates, "notifications");
  }

  const mailToButton = () => {
    const emailAddress: string = config.accountVerificationEmail;

    return (
      <div className="verification-form-card">
        <a
          href={`mailto:${emailAddress}`}
          target="_blank"
          rel="noopener noreferrer"
          className="ep-btn primary"
        >
          {intl.get("verify-account-button")}
        </a>
      </div>
    );
  };

  const updateAccountSection = () => (
    <div className="card card-body mb-6 content-box">
      <h4 className="bullet">{intl.get("update-your-information")} </h4>
      <div className="customer-information-form">
        <p>{intl.get("verify-account-paragraph")}</p>
        {mailToButton()}
      </div>
    </div>
  );

  const updateMarketingPreferences = (updatePayload: {
    [key: string]: string;
  }): void => {
    updateProfileSection(updatePayload, "preferences");
  };

  if (!auth.isLoggedIn) return <Redirect to={{ pathname: "/" }} />;

  return (
    <div className="profile-page-wrapper">
      {userProfile ? (
        <>
          {showPageTitle ? (
            <h2 className="bullet">{intl.get("account-settings")}</h2>
          ) : (
            ""
          )}
          <div className="row details columns-section">
            <div className="col-lg-6 col-md-12 col-12">
              {/* <div> */}
              <div className="card card-body h-100 content-box">
                {/* <div className="card card-body content-box"> */}
                <div className="primary-user-info-container">
                  <h4 className="bullet">{intl.get("admin-information")}</h4>
                  <ProfileInfoMain
                    profileInfo={primaryUserInfo}
                    onChange={fetchProfileData}
                    history={history}
                    auth={auth}
                    userProfile={userProfile}
                    isPrimaryUser
                    editable={!userProfile.subuserEmail}
                  />
                </div>
                {userProfile.subuserEmail && (
                  <div className="sub-user-info-container">
                    <h4 className="bullet">{intl.get("user-information")}</h4>
                    <ProfileInfoMain
                      profileInfo={subUserInfo}
                      onChange={fetchProfileData}
                      history={history}
                      auth={auth}
                      userProfile={userProfile}
                      isPrimaryUser={false}
                      editable
                    />
                  </div>
                )}
                {/* {showResetPasswordButton && (
                  <div className="personal-information-container">
                    <button
                      className="ep-btn primary change-password-btn wide"
                      type="button"
                      onClick={changePassword}
                    >
                      Reset Password
                    </button>
                  </div>
                )} */}
              </div>
            </div>

            {/* <div className="col-md-6 col-12 customer-info"> */}
            <div className="right-column-info">
              {config.accountVerificationEmail ? (
                updateAccountSection()
              ) : (
                <div className="card card-body mb-6 content-box">
                  {/* <div className="card card-body content-box"> */}
                  <h4 className="bullet">
                    {intl.get("customer-information-form")}
                  </h4>
                  <div className="customer-information-form">
                    <p>{intl.get("download-verficiation-form")}</p>

                    <div className="ep-btn primary verification-form-card">
                      <RenderCmsComponent
                        path={path}
                        renderComponent={RichText}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="card card-body content-box">
                {userProfile.homeBranch && (
                  <DefaultBranchInfoCard
                    homeBranchNumber={
                      userProfile.subuserHomeBranch || userProfile.homeBranch
                    }
                    history={history}
                    auth={auth}
                    user={user}
                  />
                )}
              </div>
            </div>
          </div>

          {userMessage ? (
            <Messagecontainer
              message={{
                type: "error",
                debugMessages: userMessage
              }}
              closeContainerHandler={closeMessage}
            />
          ) : null}
          <div className="row details">
            <div className="col">
              {userProfile.addresses ? (
                <ProfileAddressesMain
                  addresses={userProfile.addresses[0]}
                  onChange={fetchProfileData}
                  onAddNewAddress={handleNewAddress}
                  onEditAddress={handleEditAddress}
                  setUserMessage={showMessage}
                  history={history}
                  auth={auth}
                  newAddressUri={newAddressUri}
                />
              ) : (
                ""
              )}
              {renderNewAddressModal()}
            </div>
          </div>

          {/* <div className="row details">
              <div className="col-12">
                <h2 className="section-title">{intl.get("payment")}</h2>
              </div>

              <div className="col-md-6 col-12">
                <div className="card card-body">
                  {userProfile._paymentmethods ? (
                    <ProfilePaymentMethodsMain
                      paymentMethods={userProfile._paymentmethods[0]}
                      onChange={fetchProfileData}
                      disableAddPayment={disableAddPayment}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div> */}

          {config.GDPR.enable ? (
            <>
              <div className="row details">
                <div className="col-12">
                  <h2 className="section-title">{intl.get("gdpr")}</h2>
                </div>
                <div className="col-md-6 col-12">
                  <div className="card card-body">
                    {/* {dataPolicyData && dataPolicyData._element ? (
                      <ProfileGDPRMain
                        dataPolicies={dataPolicyData}
                        onChange={fetchProfileData}
                      />
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="profile-page-message-container">
            <MessageContainer
              message={responseMessage.preferences}
              closeContainerHandler={() => {
                closeMessageContainer("preferences");
              }}
            />
          </div>
          {config.showMarketingCommunications && (
            <div className="row details">
              <div className="col">
                {userProfile && (
                  <PreferencesContainer
                    userProfile={userProfile}
                    onSaveChanges={updateMarketingPreferences}
                  />
                )}
              </div>
            </div>
          )}
          <div className="profile-page-message-container">
            <MessageContainer
              message={responseMessage.notifications}
              closeContainerHandler={() => {
                closeMessageContainer("notifications");
              }}
            />
          </div>
          <div className="row details">
            <div className="col">
              <div>
                {config.showUserNotifications &&
                  userProfile &&
                  (userProfile.optInSmsAlert === "true" ||
                    userProfile.optInSmsAlert === "false" ||
                    userProfile.optInSmsAlert === null) && (
                    <NotificationsContainer
                      userProfile={userProfile}
                      updateNotifications={updateNotifications}
                    />
                  )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ProfilePage;
