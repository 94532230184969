import React, { useState, useEffect, FC, useRef, useContext } from "react";
import intl from "react-intl-universal";

import { InfoCardComponent, page } from "@zilker/store-components";

import { getCreditInformation } from "../services/connectServices";

import "./CreditInformation.less";
import { checkTokensExpired, pushToMaintenace } from "../utils/helpers";
import { MainContext } from "../contexts/MainContext";

interface CreditInformationInterface {
  creditLimit: number;
  balanceDue?: number;
  due30Days: number;
  due60Days: number;
  due90Days: number;
  due120Days: number;
  lastPaymentDate: string;
  lastPaymentAmount: number;
  average6MonthSLS: number;
  last12MonthSLS: number;
  dueCurrent: number;
  totalBalanceDue: number;
}

interface CreditInformationProps {
  history: any;
  auth: any;
}

const CreditInformation: FC<CreditInformationProps> = ({ history, auth }) => {
  const [creditInformation, setCreditInformation] = useState<
    CreditInformationInterface
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const context = useContext<{ user: any }>(MainContext);
  const {
    user: {
      userProfile: { customerNumber }
    }
  } = context;

  const isCancelled = useRef(false);

  const formatCurrency = value => {
    if (value || value === 0) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(value);
    }
    return "";
  };

  useEffect(() => {
    page();
    getCreditInformation(customerNumber)
      .then(data => {
        if (!isCancelled.current) {
          setCreditInformation(data);
          setLoading(false);
        }
      })
      .catch(e => {
        if (!isCancelled.current) {
          setLoading(false);
        }
        if (checkTokensExpired(e)) {
          auth.logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => getCreditInformation => CreditInformation.tsx"
            })
          );
        } else if (isCancelled.current) {
          pushToMaintenace(history, {
            e,
            errIn: "getCreditInformation => CreditInformation.tsx"
          });
        }
      });
    // eslint-disable-next-line no-return-assign
    return () => (isCancelled.current = true);
  }, []);

  return loading ? (
    <div className="loader" />
  ) : (
    <div className="credit-information credit-information-page-wrapper">
      <div className="content-box">
        <h4 className="bullet">{`${intl.get("account-overview")}`}</h4>
        <div className="overview">
          {creditInformation && (
            <InfoCardComponent
              name={`${intl.get("credit-limit")}:`}
              value={formatCurrency(creditInformation.creditLimit)}
            />
          )}
        </div>
      </div>
      <div className="balance-due content-box">
        <h4 className="bullet">{`${intl.get("balance-due")}:`}</h4>
        <div className="balance-cards">
          <InfoCardComponent
            name={`${intl.get("current")}:`}
            value={formatCurrency(creditInformation.dueCurrent)}
          />
          <InfoCardComponent
            name={`30 ${intl.get("days")}:`}
            value={formatCurrency(creditInformation.due30Days)}
          />
          <InfoCardComponent
            name={`60 ${intl.get("days")}:`}
            value={formatCurrency(creditInformation.due60Days)}
          />
          <InfoCardComponent
            name={`90 ${intl.get("days")}:`}
            value={formatCurrency(creditInformation.due90Days)}
          />
          <InfoCardComponent
            name={`120 ${intl.get("days")}:`}
            value={formatCurrency(creditInformation.due120Days)}
          />
          <div className="total-due">
            <p>{`${intl.get("total-due")}:`}</p>
            <h3>
              {creditInformation &&
                formatCurrency(creditInformation.totalBalanceDue)}
            </h3>
          </div>
        </div>
      </div>
      <div className="summary content-box">
        <h4 className="bullet">{`${intl.get("account-summary")}`}</h4>
        <div className="summary-grid">
          <InfoCardComponent
            name={`${intl.get("last-payment-date")}:`}
            value={creditInformation.lastPaymentDate}
          />
          <InfoCardComponent
            name={`${intl.get("last-payment-amount")}:`}
            value={formatCurrency(creditInformation.lastPaymentAmount)}
          />
          <InfoCardComponent
            name={`${intl.get("average-monthly-sales")} (${intl.get(
              "last-6-months"
            )}):`}
            value={formatCurrency(creditInformation.average6MonthSLS)}
          />
          <InfoCardComponent
            name={`${intl.get("last-12-months")} ${intl.get("total-sales")}:`}
            value={formatCurrency(creditInformation.last12MonthSLS)}
          />
        </div>
      </div>
    </div>
  );
};

export default CreditInformation;
