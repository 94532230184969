/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import {
  checkResponse,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { cortexFetch, adminFetch } from "./Cortex";

import { getConfig } from "./ConfigProvider";

let userFormBody = [];
let userFormBodyString = "";
let newaccountform = "";

/* eslint-disable camelcase */
interface PublicUserDetailsInterface {
  username?: string;
  password?: string;
  grant_type?: string;
  role?: string;
  scope?: string;
  code?: string;
  redirect_uri?: string;
  client_id?: string;
}
/* eslint-enable camelcase */

function generateFormBody(userDetails) {
  Object.keys(userDetails).forEach(encodedKey => {
    const encodedValue = userDetails[encodedKey];
    userFormBody.push(`${encodedKey}=${encodedValue}`);
  });
  userFormBodyString = userFormBody.join("&");
}

/**
 * @deprecated
 * Use LoginService from /services.
 */
export function login() {
  return new Promise((resolve, reject) => {
    if (
      localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_oAuthToken`
      ) === null
    ) {
      userFormBodyString = "";
      userFormBody = [];
      const publicUserDetails: PublicUserDetailsInterface = {
        username: "",
        password: "",
        grant_type: "password",
        role: "PUBLIC",
        scope: getConfig().config.cortexApi.scope
      };

      generateFormBody(publicUserDetails);
      cortexFetch("/oauth2/tokens", {
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
        },
        body: userFormBodyString
      })
        .then(res => checkResponse(res))
        .then(res => {
          if (
            localStorage.getItem(
              `${getConfig().config.cortexApi.scope}_oAuthTokenAuthService`
            ) === null
          ) {
            localStorage.setItem(
              `${getConfig().config.cortexApi.scope}_oAuthRole`,
              res.role
            );
          }
          localStorage.setItem(
            `${getConfig().config.cortexApi.scope}_oAuthScope`,
            res.scope
          );
          localStorage.setItem(
            `${getConfig().config.cortexApi.scope}_oAuthToken`,
            `Bearer ${res.access_token}`
          );
          localStorage.setItem(
            `${getConfig().config.cortexApi.scope}_oAuthUserName`,
            publicUserDetails.username
          );
          resolve(res);
        })
        .catch(error => reject(error));
    } else {
      resolve(userFormBodyString);
    }
  });
}

/**
 * @deprecated
 * Use LoginService from /services.
 */
export function loginRegistered(username, password) {
  return new Promise((resolve, reject) => {
    if (
      localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_oAuthToken`
      ) != null
    ) {
      userFormBodyString = "";
      userFormBody = [];
      const registeredUserDetails = {
        username,
        password,
        grant_type: "password",
        role: "REGISTERED",
        scope: getConfig().config.cortexApi.scope
      };

      generateFormBody(registeredUserDetails);
      cortexFetch("/oauth2/tokens", {
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: localStorage.getItem(
            `${getConfig().config.cortexApi.scope}_oAuthToken`
          )
        },
        body: userFormBodyString
      })
        .then(res => checkResponse(res))
        .then(res => {
          if (
            localStorage.getItem(
              `${getConfig().config.cortexApi.scope}_oAuthTokenAuthService`
            ) === null
          ) {
            localStorage.setItem(
              `${getConfig().config.cortexApi.scope}_oAuthRole`,
              res.role
            );
          }
          localStorage.setItem(
            `${getConfig().config.cortexApi.scope}_oAuthScope`,
            res.scope
          );
          localStorage.setItem(
            `${getConfig().config.cortexApi.scope}_oAuthToken`,
            `Bearer ${res.access_token}`
          );
          localStorage.setItem(
            `${getConfig().config.cortexApi.scope}_oAuthUserName`,
            registeredUserDetails.username
          );
          resolve(200);
        })
        .catch(error => reject(error));
    } else {
      resolve(userFormBodyString);
    }
  });
}

export function loginRegisteredAuthService(code, redirectUri, clientId) {
  if (
    localStorage.getItem(
      `${getConfig().config.cortexApi.scope}_oAuthTokenAuthService`
    ) === null
  ) {
    userFormBodyString = "";
    userFormBody = [];
    const registeredUserDetails: PublicUserDetailsInterface = {
      grant_type: "authorization_code",
      code,
      redirect_uri: redirectUri,
      client_id: clientId
    };
    generateFormBody(registeredUserDetails);
    return adminFetch("/oauth2/tokens", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      },
      body: userFormBodyString
    });
  }

  return Promise.reject(new Error("Failed to find tokens"));
}

/**
 * @deprecated
 * Use LoginService from /services.
 */
export function logout() {
  return new Promise((resolve, reject) => {
    cortexFetch("/oauth2/tokens", {
      method: "delete"
    })
      .then(res => {
        localStorage.removeItem(
          `${getConfig().config.cortexApi.scope}_oAuthRole`
        );
        localStorage.removeItem(
          `${getConfig().config.cortexApi.scope}_oAuthScope`
        );
        localStorage.removeItem(
          `${getConfig().config.cortexApi.scope}_oAuthToken`
        );
        localStorage.removeItem(
          `${getConfig().config.cortexApi.scope}_oAuthUserName`
        );
        localStorage.removeItem(
          `${getConfig().config.cortexApi.scope}_b2bCart`
        );
        localStorage.removeItem(
          `${getConfig().config.cortexApi.scope}_oAuthTokenAuthService`
        );
        localStorage.removeItem(
          `${getConfig().config.cortexApi.scope}_openIdcSessionState`
        );
        localStorage.removeItem(
          `${getConfig().config.cortexApi.scope}_openIdcCode`
        );
        localStorage.removeItem(
          `${getConfig().config.cortexApi.scope}_keycloakSessionState`
        );
        localStorage.removeItem(
          `${getConfig().config.cortexApi.scope}_keycloakCode`
        );
        resolve(res);
      })
      .catch(error => reject(error));
  });
}

/**
 * @deprecated
 * Use LoginService from /services.
 */
export function logoutAccountManagementUser(history) {
  const config: any = getConfig().config.b2b;
  if (config.openId && config.openId.enable) {
    return new Promise((resolve, reject) => {
      adminFetch("/oauth2/tokens", {
        method: "delete",
        Authorization: localStorage.getItem(
          `${getConfig().config.cortexApi.scope}_oAuthTokenAuthService`
        )
      })
        .then(res => {
          logout();
          resolve(res);
        })
        .then(ress => {
          history.push("/");
        })
        .catch(error => reject(error));
    });
  }
  return logout()
    .then(() => {
      const keycloakLogoutRedirectUrl = `${
        getConfig().config.b2b.keycloak.logoutRedirectUrl
      }?redirect_uri=${encodeURIComponent(
        getConfig().config.b2b.keycloak.callbackUrl
      )}`;
      if (history) history.push(keycloakLogoutRedirectUrl);
      else window.location.href = keycloakLogoutRedirectUrl;
    })
    .catch(e =>
      pushToMaintenace(history, {
        e,
        errIn: "logout => AuthService.tsx"
      })
    );
}

export function getRegistrationForm() {
  return new Promise((resolve, reject) => {
    cortexFetch("/?zoom=newaccountform")
      .then(res => checkResponse(res))
      .then(res => {
        const registrationLink = res.links.find(
          link => link.rel === "newaccountform"
        );
        newaccountform = registrationLink.uri;
        resolve(registrationLink.uri);
      })
      .catch(error => reject(error));
  });
}

export function registerUser(lastname, firstname, username, password) {
  return new Promise((resolve, reject) => {
    cortexFetch(newaccountform, {
      method: "post",
      body: JSON.stringify({
        "family-name": lastname,
        "given-name": firstname,
        username,
        password
      })
    })
      .then(res => checkResponse(res))
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
}

export function getAccessToken(token) {
  const Config = getConfig().config;
  return new Promise((resolve, reject) => {
    cortexFetch(
      `/impersonation/${Config.cortexApi.scope}/form?followlocation`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem(
            `${Config.cortexApi.scope}_oAuthToken`
          )
        },
        body: JSON.stringify({
          "impersonation-token": token
        })
      }
    )
      .then(res => checkResponse(res))
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
}

const oidcDiscoveryEndpoint = "/.well-known/openid-configuration";
export async function discoverOIDCParameters() {
  try {
    const Config = getConfig().config;
    const data = await adminFetch(oidcDiscoveryEndpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem(
          `${Config.cortexApi.scope}_oAuthTokenAuthService`
        )
      }
    });
    const res = await data.json();
    return {
      clientId: res.account_management_client_id,
      scopes: res.account_management_required_scopes,
      authorizationEndpoint: res.authorization_endpoint,
      endSessionEndpoint: res.end_session_endpoint,
      issuer: res.issuer
    };
  } catch (error) {
    throw new Error("http-error");
  }
}
