import React, { FC } from "react";

import { Redirect } from "react-router-dom";
import intl from "react-intl-universal";
import { getConfig } from "@zilker/store-components";
import CreditInformation from "../../containers/CreditInformation";
import OrderHistoryPage from "../../containers/OrderHistoryPage";
import OrderHistoryDetails from "../../containers/OrderHistoryDetails";
import SavedOrders from "../../containers/SavedOrders";
import BranchSettings from "../../containers/BranchSettings";
import AccountStatementPage from "../../containers/AccountStatementPage";
import ProfilePage from "../../containers/ProfilePage";
import AccountMain from "../../containers/b2b/AccountMain";
import UserRoles from "./userRoles";
import ContractOrdersPage from "../../containers/ContractOrdersPage";
import ContractDetailsPage from "../../containers/ContractDetailsPage";

// TODO: maybe separate case conditions into some constants file

const urlToAccountComponent = (
  page,
  { history, match },
  { auth, user, account, cart, branches, contract }
) => {
  const {
    userProfile: { roles, subUserRoles }
  } = user;
  const { contractsList } = contract;
  const { accountDetails } = account;

  const { config } = getConfig();

  const viewCreditRole =
    subUserRoles && subUserRoles.length
      ? subUserRoles.includes(UserRoles().viewCredit)
      : roles.includes(UserRoles().viewCredit);
  const viewStatementsRole =
    subUserRoles && subUserRoles.length
      ? subUserRoles.includes(UserRoles().viewStatements)
      : roles.includes(UserRoles().viewStatements);

  switch (page) {
    case "profile":
      return (
        <ProfilePage history={history} match={match} auth={auth} user={user} />
      );
    case "creditInformation":
      return config.creditInformationPageDisplay && viewCreditRole ? (
        <CreditInformation history={history} auth={auth} />
      ) : null;
    case "statement":
      return config.statementPageDisplay && viewStatementsRole ? (
        <AccountStatementPage history={history} auth={auth} user={user} />
      ) : null;
    case "branchSettings":
      return (
        <BranchSettings
          history={history}
          auth={auth}
          user={user}
          cart={cart}
          branches={branches}
        />
      );
    case "manageUsers":
      return (
        <AccountMain
          history={history}
          auth={auth}
          account={account}
          user={user}
        />
      );
    case "savedOrders":
      return <SavedOrders history={history} match={match} auth={auth} />;
    case "orderHistory":
      return (
        <OrderHistoryPage
          history={history}
          auth={auth}
          user={user}
          cart={cart}
          accountDetails={accountDetails}
          pageConfig={{
            title: intl.get("order-history"),
            usePrintInvoice: false,
            showStatusField: true,
            selectMultipleLines: false,
            orderStatus: "",
            showExportToCsv: true
          }}
        />
      );
    case "orderDetails":
      return (
        <OrderHistoryDetails
          history={history}
          match={match}
          auth={auth}
          user={user}
          branches={branches}
          pageConfig={{
            title: intl.get("order-information"),
            topButtons: false,
            showSerialNumber: false,
            showInvoiceDate: false
          }}
        />
      );
    case "invoiceQuery":
      return config.invoicePageDisplay ? (
        <OrderHistoryPage
          history={history}
          auth={auth}
          user={user}
          cart={cart}
          accountDetails={accountDetails}
          pageConfig={{
            title: `${intl.get("invoice-query")}`,
            usePrintInvoice: true,
            showStatusField: false,
            selectMultipleLines: true,
            orderStatus: "I",
            showExportToCsv: false
          }}
        />
      ) : null;
    case "invoiceDetails":
      return (
        <OrderHistoryDetails
          history={history}
          match={match}
          auth={auth}
          user={user}
          branches={branches}
          pageConfig={{
            title: intl.get("invoice-information"),
            topButtons: true,
            showSerialNumber: true,
            showInvoiceDate: true
          }}
        />
      );
    case "contractOrders":
      return config.contractOrderPageDisplay &&
        contractsList &&
        contractsList.length ? (
        <ContractOrdersPage history={history} match={match} auth={auth} />
      ) : null;
    case "contractDetails":
      return config.contractOrderPageDisplay &&
        contractsList &&
        contractsList.length ? (
        <ContractDetailsPage history={history} match={match} />
      ) : null;
    default:
      return "Account Default Component"; // TODO: Replace with a real component
  }
};

export default urlToAccountComponent;
