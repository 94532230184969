import React from "react";
import intl from "react-intl-universal";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

interface OrderItemDetailsProps<T> {
  details: {
    [key: string]: any;
    serialNumbers?: any[];
  };
  noOp: () => any;
  isLoadingReorderOne: boolean;
  clickedItemId: number;
  onReorderOneClick: (...args: any) => void;
  showSerialNumber: boolean;
}

const OrderItemDetails: React.FC<OrderItemDetailsProps<Object>> = ({
  details,
  noOp,
  isLoadingReorderOne,
  clickedItemId,
  onReorderOneClick,
  showSerialNumber
}) => {
  const { config }: { config: IEpConfig } = getConfig();

  const renderSerialNumbers = () => {
    return showSerialNumber ? (
      <div className="serial-numbers-container">
        <h5 className="serial-numbers-title">
          {`${intl.get("serial-numbers")}:`}
        </h5>
        <div className="serial-numbers-content">
          {details.serialNumbers && details.serialNumbers.length
            ? details.serialNumbers.map(
                (serialNumber: string, index: number, self: string[]) => (
                  <span key={serialNumber}>
                    {` ${serialNumber}${
                      index !== self.length - 1 ? ", " : ""
                    } `}
                  </span>
                )
              )
            : ""}
        </div>
      </div>
    ) : null;
  };

  return (
    <>
      <div className="items item-table">
        <div>
          <p>{intl.get("quantity-abbr")}</p>
          <p>{details.quantityOrdered}</p>
        </div>
        <div>
          <p>{intl.get("product")}#</p>
          <p>{details.sku}</p>
        </div>
        <div>
          <p>{intl.get("description")}</p>
          <p className="text-right text-md-left">{details.description}</p>
        </div>
        {config.calculatePrice && (
          <div>
            <p>{intl.get("price")}</p>
            <p>${details.unitPrice}</p>
          </div>
        )}
        {config.calculatePrice && (
          <div>
            <p>{intl.get("extended-price")}</p>
            <p>${details.linePrice}</p>
          </div>
        )}
        {config.orderHistoryQTYShipped && (
          <div>
            <p>{intl.get("qty-shipped")}</p>
            <p>{details.quantityShipped}</p>
          </div>
        )}
        {config.showBackOrderedQuantity && (
          <div>
            <p>{intl.get("qty-b-o")}</p>
            <p>{details.quantityBackOrdered}</p>
          </div>
        )}
        <div>
          <p>{intl.get("action")}</p>
          <span
            data-id={details.sku}
            className="ico-button"
            onClick={e => onReorderOneClick(e, details.sku)}
            onKeyDown={noOp}
            role="button"
            tabIndex={0}
            aria-hidden="true"
          >
            {isLoadingReorderOne && clickedItemId === details.sku ? (
              <span className="miniLoader" />
            ) : (
              <i className="icon-reorder" />
            )}
            <span> {intl.get("reorder")}</span>
          </span>
        </div>
      </div>
      {renderSerialNumbers()}
    </>
  );
};

export default OrderItemDetails;
