/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { ReactComponent as CloseIcon } from "../../../app/src/images/icons/close-icon.svg";
import { ReactComponent as ErrorIcon } from "../../../app/src/images/icons/error-icon.svg";
import { ReactComponent as WarningIcon } from "../../../app/src/images/icons/warning-icon.svg";
import { ReactComponent as InfoIcon } from "../../../app/src/images/icons/info-icon.svg";

import "./messagecontainer.less";

export interface DebugMessage {
  type: string;
  debugMessages: string;
  isMsgEmphasized?: Boolean;
}

interface Button {
  text: string;
  action: (...args: any[]) => any;
  className?: string;
}

interface MessageContainerProps {
  message: DebugMessage;
  closeContainerHandler: (...args: any[]) => any;
  stayOpen?: boolean;
  button?: Button;
}

const MessageContainer: React.SFC<MessageContainerProps> = props => {
  const { message, closeContainerHandler, stayOpen, button } = props;

  const emphasizeMessage = debugMsg => {
    const messages = debugMsg.split(".");
    return (
      <>
        {`${messages[0]}. `}
        <span>{`${messages[1]}.`}</span>
        {messages[2]}
      </>
    );
  };

  if (message && message.debugMessages) {
    let messageType = "";
    if (message.type === "error") {
      messageType = "warning-message";
    } else if (message.type === "danger-message") {
      messageType = "danger-message";
    } else if (message.type === "needinfo" || message.type === "basic") {
      messageType = "info-message";
    }

    return (
      <div className="debug-messages-block">
        <div className="debug-messages-inner">
          <div className={`container debug-messages-container ${messageType}`}>
            <ErrorIcon className="debug-msg-icon error" />
            <WarningIcon className="debug-msg-icon warning" />
            <InfoIcon className="debug-msg-icon info" />
            <p>
              {message.isMsgEmphasized
                ? emphasizeMessage(message.debugMessages)
                : message.debugMessages}
              &nbsp;
              {button && (
                <button
                  type="button"
                  className={button.className}
                  onClick={button.action}
                >
                  {button.text}
                </button>
              )}
            </p>
            {!stayOpen && (
              <button
                type="button"
                className="close-btn"
                onClick={closeContainerHandler}
              >
                <CloseIcon />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default MessageContainer;
