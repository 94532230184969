/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";

import "./quickorderform.less";
import {
  checkTokensExpired,
  handleCustomException,
  pushToMaintenace,
  formatInventoryAvailability,
  InventoryAvailabilityInterface,
  formatAvailabilityLabel,
  formatGeneralDGAAvailabilityLabel,
  formatDGAInventory,
  convertUnitOfMeasure
} from "@elasticpath/ref-store/src/utils/helpers";
import { withRouter, RouteComponentProps, NavLink } from "react-router-dom";
import * as ConnectService from "@elasticpath/ref-store/src/services/connectServices";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import {
  generateImageUrl,
  ProductPriceRequest
} from "@elasticpath/ref-store/src/utils/mappings/productDetails";
import { changeBranchAndVendorOnCurrentOrder } from "@elasticpath/ref-store/src/services/EpServices";
import imgPlaceholder from "../../../app/src/images/img_missing_horizontal@2x.png";
import { cortexFetchItemLookupForm, itemLookup } from "../utils/CortexLookup";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

let Config: IEpConfig | any = {};
let intl = { get: (str, ...args: any[]) => str };

interface QuickOrderFormProps extends RouteComponentProps {
  item: {
    [key: string]: any;
  };
  onItemSubmit: (...args: any[]) => any;
  match: any;
  history: any;
  clearQuickOrder: boolean;
  isQuickOrderLoading: boolean;
}

interface QuickOrderFormState {
  code: any | string;
  product: any | {};
  quantity: any | number;
  isLoading: boolean;
  skuErrorMessage: any | string;
  prevPropItem: any;
  isUnavailable: boolean;
  jobNumber: string;
  inventoryError: string;
}

class QuickOrderForm extends React.Component<
  QuickOrderFormProps,
  QuickOrderFormState
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = getConfig());
    const { item } = this.props;
    this.state = {
      code: "",
      quantity: 1,
      product: {},
      isLoading: false,
      skuErrorMessage: "",
      prevPropItem: item,
      isUnavailable: false,
      jobNumber: "",
      inventoryError: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRemoveSku = this.handleRemoveSku.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleQtyChange = this.handleQtyChange.bind(this);
    this.getProductInfo = this.getProductInfo.bind(this);
    this.handleProductError = this.handleProductError.bind(this);
    this.fetchEntitlement = this.fetchEntitlement.bind(this);
    this.fetchPrice = this.fetchPrice.bind(this);
    this.fetchAvailability = this.fetchAvailability.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;
    const { code, product } = this.state;
    const {
      item,
      isQuickOrderLoading,
      clearQuickOrder,
      onItemSubmit
    } = this.props;

    const { jobNumber } = prevState;

    let currJobNumber = jobNumber;
    // if bulkOrder issued the request
    if (isQuickOrderLoading !== prevProps.isQuickOrderLoading) {
      const newProduct = {
        ...product,
        ...item.product
      };
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isLoading: isQuickOrderLoading, product: newProduct });
    }
    if (defaultCart) {
      currJobNumber = defaultCart.jobNumber;
    }
    if (currJobNumber !== jobNumber) {
      if (code) {
        this.getProductInfo(code.trim());
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ jobNumber: currJobNumber });
    }
    if (clearQuickOrder) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ quantity: 1, code: "", product: {} });
      onItemSubmit({ code: "" });
    }
    if (prevProps.item.code !== item.code) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ quantity: 1 });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  static getDerivedStateFromProps(nextProps, prevState) {
    const { code, prevPropItem } = prevState;
    if (!prevNextPropsChange(nextProps.item, prevPropItem)) {
      return null;
    }
    const { item } = nextProps;
    const { onItemSubmit } = nextProps;
    if (item.code !== code) {
      if (!item.code) {
        return {
          code: item.code,
          product: item.product,
          quantity: item.quantity,
          prevPropItem: nextProps.item
        };
      }

      if (
        !item._price ||
        `${item._price[0]["purchase-price"][0].display}` === "0"
      ) {
        return {
          code,
          prevPropItem: nextProps.item,
          skuErrorMessage: `${intl.get("product-message-without-price", {
            SKUCode: code
          })}`
        };
      }
      onItemSubmit({ isValidField: true });
      return {
        skuErrorMessage: "",
        product: item,
        code,
        prevPropItem: nextProps.item
      };
    }
    return null;
  }

  async getProductInfo(productId) {
    const {
      branches: { findBranch },
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;

    // Display loader
    this.setState({ isLoading: true });

    // Trim product SKU of whitespaces and format it.
    const formattedProductId = productId.toUpperCase().trim();

    // Set flags to control the requests.
    let cortexLookUpSuccess = false;

    // Call Cortex item look up form.
    try {
      await cortexFetchItemLookupForm();
      cortexLookUpSuccess = true;
    } catch (error) {
      this.handleProductError(
        error,
        formattedProductId,
        "cortexFetchItemLookupForm"
      );
    }

    // Look up product details.
    try {
      if (cortexLookUpSuccess) {
        const itemLookupResponse = await itemLookup(formattedProductId, false);

        this.setState({ product: itemLookupResponse }, () => {
          const startFlow = async () => {
            // Request product (SKU) entitlement.
            await this.fetchEntitlement(formattedProductId);

            // Request product's catalog price.
            if (Config.calculatePrice)
              await this.fetchPrice(formattedProductId);

            // Request product availability.
            await this.fetchAvailability(formattedProductId);
          };

          startFlow();
        });
      }
    } catch (error) {
      this.handleProductError(error, formattedProductId, "itemLookup");
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { code, quantity, product } = this.state;
    if (code === "") return;
    const { onItemSubmit } = this.props;
    onItemSubmit({ code, quantity, product });
    this.setState({
      product: {},
      isLoading: true
    });
    this.getProductInfo(code.trim());
  }

  handleChange(event) {
    if (event.target.value === "") {
      this.handleRemoveSku();
    } else {
      this.setState({ code: event.target.value, skuErrorMessage: "" });
    }
  }

  handleRemoveSku() {
    const { onItemSubmit } = this.props;
    this.setState({
      skuErrorMessage: "",
      isLoading: false,
      code: "",
      product: {},
      quantity: 1,
      isUnavailable: false
    });
    onItemSubmit({
      code: "",
      quantity: 1,
      product: {},
      isValidField: false,
      isDuplicated: false
    });
  }

  async handleQuantityDecrement() {
    const { code, quantity } = this.state;
    const { onItemSubmit } = this.props;
    if (quantity > 1) {
      const updatedQuantity = quantity - 1;
      await this.setState({ quantity: updatedQuantity });
      await onItemSubmit({ code, quantity: updatedQuantity });
      await this.getProductInfo(code);
    }
  }

  async handleQuantityIncrement() {
    const { quantity, code } = this.state;
    const { onItemSubmit } = this.props;
    const updatedQuantity = quantity + 1;
    await this.setState({ quantity: updatedQuantity });
    await onItemSubmit({ code, quantity: updatedQuantity });
    await this.getProductInfo(code);
  }

  async handleQtyChange(event) {
    const { code, product } = this.state;
    const { onItemSubmit } = this.props;
    const updatedQuantity = Number(event.target.value);
    await this.setState({ quantity: updatedQuantity });
    await onItemSubmit({ code, product, quantity: updatedQuantity });
    await this.getProductInfo(code);
  }

  handleProductError(error: any, productId: string, errorPath: string): void {
    const {
      context: {
        auth: { logout }
      },
      state: { code, quantity },
      props: { onItemSubmit, history }
    } = this;

    error.json().then(json => {
      if (json.messages && json.messages[0]) {
        if (json.messages[0].id === "item.not.visible") {
          this.setState({
            skuErrorMessage: intl.get("item-not-visible-error", {
              sku: productId
            }),
            isLoading: false
          });
        } else {
          this.setState({
            skuErrorMessage: `${productId} ${intl.get("sku-invalid-message")}`,
            isLoading: false
          });
        }
      }
    });

    if (checkTokensExpired(error)) {
      logout().catch(err =>
        pushToMaintenace(history, {
          e: err,
          errIn: `Logout => ${errorPath} => QuickOrderForm.tsx`
        })
      );
    } else {
      onItemSubmit({
        code,
        quantity,
        product: {},
        isValidField: false
      });
    }
  }

  async fetchEntitlement(productId) {
    const {
      context: {
        user: {
          userProfile: { customerNumber }
        },
        auth: { logout },
        cart: {
          cartDetails: { defaultCart }
        }
      },
      props: { history }
    } = this;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;

    if (Config.entitlementCheck && selectedBranch) {
      try {
        const { data } = await ConnectService.checkEntitlementSku(
          customerNumber,
          productId,
          selectedBranch.vendor
        );

        if (!data || !data.length || !data[0].entitled) {
          this.setState(prevState => {
            return {
              ...prevState,
              skuErrorMessage: `${intl.get("sku-entitled-false-message")}`,
              product: { ...prevState.product, entitlementError: true },
              isLoading: false
            };
          });
        }
      } catch (error) {
        const errorPath = "checkEntitlementSku => quickorderform.tsx";
        handleCustomException(error, logout, history, errorPath);
        this.setState(prevState => ({
          ...prevState,
          product: { ...prevState.product, entitlementError: true },
          isLoading: false
        }));
      }
    }
  }

  async fetchPrice(productId) {
    const {
      context: {
        auth: { logout },
        cart: {
          cartDetails: { defaultCart }
        },
        job: { persistedJobNumber },
        user: {
          userProfile: { customerNumber }
        }
      },
      state: { quantity },
      props: { history }
    } = this;

    const { selectedBranch, jobNumber } = defaultCart;

    try {
      const body: ProductPriceRequest = {
        customerNumber,
        branchNumber: selectedBranch.code,
        skus: [productId],
        jobNumber
      };
      if (jobNumber || persistedJobNumber) {
        body.jobNumber = jobNumber || persistedJobNumber;
      }
      const { data } = await ConnectService.priceCatalog(body);

      // Update product data with price.
      this.setState(prevState => ({
        ...prevState,
        product: {
          ...prevState.product,
          price: data.items[0].total
        }
      }));

      if (data.items[0].total === 0) {
        this.setState(prevState => ({
          ...prevState,
          skuErrorMessage: `${intl.get("product-message-without-price", {
            SKUCode: productId
          })}`,
          isLoading: false
        }));
      }
    } catch (error) {
      const errorPath = "priceCatalog => quickorderform.tsx";
      handleCustomException(error, logout, history, errorPath);
      this.setState(prevState => ({
        ...prevState,
        isLoading: false,
        product: { ...prevState.product, price: intl.get("pending") }
      }));
    }
  }

  handleProductSubmit(
    inventoryAvailability: Array<InventoryAvailabilityInterface>
  ) {
    const { code, quantity, product } = this.state;
    const { onItemSubmit } = this.props;

    const inventoryItem = inventoryAvailability.length
      ? inventoryAvailability[0]
      : { branchAvailability: 0, regionAvailability: 0, unitOfMeasure: 1 };
    const productData = {
      ...product,
      ...inventoryItem
    };

    this.setState({
      product: productData,
      isLoading: false,
      isUnavailable:
        !productData.branchAvailability && !productData.regionAvailability
    });

    onItemSubmit({
      code,
      quantity,
      product: productData,
      isValidField: true
    });
  }

  async fetchAvailability(productId) {
    const {
      context: {
        auth: { logout },
        cart: {
          cartDetails: { defaultCart }
        },
        branches: { findBranch },
        user: {
          userProfile: { customerNumber }
        }
      },
      state: { code, quantity, product },
      props: { onItemSubmit, history }
    } = this;
    const {
      cortexApi: { scope }
    } = Config;

    const { selectedBranch, clientId } = defaultCart;
    const { latitude, longitude } = findBranch(selectedBranch.code);

    let inventoryAvailability: Array<InventoryAvailabilityInterface>;
    if (scope === "motili") {
      ConnectService.getAvailabilityMotili(
        productId,
        latitude,
        longitude,
        clientId
      )
        .then(({ data }) => {
          inventoryAvailability = formatInventoryAvailability(data);
          this.handleProductSubmit(inventoryAvailability);
        })
        .catch(error => {
          const errorPath = "getAvailabilityMotili => quickorderform.tsx";
          handleCustomException(error, logout, history, errorPath);
          this.setState(prevState => ({
            ...prevState,
            isLoading: false
          }));
          onItemSubmit({
            code,
            quantity,
            isValidField: true
          });
        });
    } else {
      const branchNumber = selectedBranch.code;
      ConnectService.getAvailabilityDGA(
        customerNumber,
        [productId],
        branchNumber
      )
        .then(({ data }) => {
          if (data.result && !data.result.length) {
            console.error("Inventory error");
            this.setState({
              inventoryError: intl.get("inventory-error")
            });
          } else {
            inventoryAvailability = formatDGAInventory(data);
            this.handleProductSubmit(inventoryAvailability);
            this.setState({
              inventoryError: ""
            });
          }
        })
        .catch(error => {
          console.error("Inventory error", error);
          const errorPath = "getAvailabilityDGA => quickorderform.tsx";
          handleCustomException(error, logout, history, errorPath);
          this.setState(prevState => ({
            ...prevState,
            isLoading: false,
            inventoryError: intl.get("inventory-error")
          }));
          onItemSubmit({
            code,
            quantity,
            isValidField: true
          });
        });
    }
  }

  render() {
    const { item } = this.props;
    const {
      code,
      product,
      isLoading,
      skuErrorMessage,
      quantity,
      isUnavailable,
      inventoryError
    } = this.state;
    let productImage;

    if (Object.keys(product).length && product._definition) {
      productImage = generateImageUrl(product._definition[0].details);
    }

    const unitOfMeasureNum = Number(product.unitOfMeasure);
    const convertedUnitOfMeasure = convertUnitOfMeasure(
      unitOfMeasureNum,
      quantity
    );
    const isSpecialAirPurifier = code === "MCB50YSAU" || code === "MCKB70YSAU";

    let availabilityMessage = "";
    const { scope } = Config.cortexApi;
    // PGL-364: Updates to MCB50YSAU and MCKB70YSAU
    if (isSpecialAirPurifier) {
      availabilityMessage = intl.get("special-air-purifier-inventory-msg");
    } else if (scope !== "motili") {
      const selectedQty = item.code !== "" ? quantity : 1;
      availabilityMessage = formatGeneralDGAAvailabilityLabel(
        product.branchAvailability,
        selectedQty,
        product.regionAvailability
      );
    } else {
      availabilityMessage = formatAvailabilityLabel(
        product.branchAvailability,
        product.regionAvailability
      );
    }

    return (
      <div key={item.code} className="bulk-item-wrap">
        <div className="bulk-item">
          <div className="bulk-item-col quick-order-sku-wrap">
            <label htmlFor="item_sku_label" className="control-label">
              {intl.get("quick-order-sku-title")}
            </label>
            <div className="sku-field-wrap">
              <form className="form-horizontal" onSubmit={this.handleSubmit}>
                <input
                  className={`sku-input ${
                    skuErrorMessage ||
                    (Config.checkAvailability && isUnavailable) ||
                    (product.unitOfMeasure && convertedUnitOfMeasure !== 0)
                      ? "input-code-error"
                      : ""
                  }`}
                  type="text"
                  value={code}
                  name="code"
                  onChange={this.handleChange}
                  onBlur={this.handleSubmit}
                />
                <span
                  role="presentation"
                  className={`clear-field-btn ${code === "" ? "hide" : ""} ${
                    skuErrorMessage !== "" ||
                    (Config.checkAvailability && isUnavailable) ||
                    (product.unitOfMeasure && convertedUnitOfMeasure !== 0)
                      ? "input-error-icon"
                      : ""
                  }`}
                  onClick={this.handleRemoveSku}
                />
              </form>
            </div>
          </div>
          <div className="bulk-item-col product-quantity-wrap">
            <label
              htmlFor="product_display_item_quantity_label"
              className="control-label control-quantity-label"
            >
              {intl.get("quantity-abbr")}
            </label>
            <div className="input-group-btn">
              <button
                type="button"
                className="quantity-left-minus btn btn-number"
                data-type="minus"
                data-field=""
                onClick={() => {
                  this.handleQuantityDecrement();
                }}
                disabled={!code || quantity <= 1}
              >
                <span className="glyphicon glyphicon-minus" />
              </button>
              <div className="quantity-col form-content form-content-quantity">
                <input
                  className="product-display-item-quantity-select form-control form-control-quantity"
                  type="number"
                  step="1"
                  min="1"
                  max="9999"
                  value={item.code !== "" ? quantity : 1}
                  onChange={this.handleQtyChange}
                />
              </div>
              <button
                type="button"
                className="quantity-right-plus btn btn-number"
                data-type="plus"
                data-field=""
                onClick={() => {
                  this.handleQuantityIncrement();
                }}
                disabled={!code}
              >
                <span className="glyphicon glyphicon-plus" />
              </button>
            </div>
          </div>
          {Config.calculatePrice && (
            <div className="total-price-item">
              <label
                htmlFor="item_price_label"
                className="control-label control-price-label"
              >
                {intl.get("each")}
              </label>
              {product && product.price ? (
                <p className="item-price">
                  {`${
                    product.price.toString() === intl.get("pending") ? "" : "$"
                  }${product.price}`}
                </p>
              ) : (
                <p>$0.00</p>
              )}
            </div>
          )}
        </div>
        {code && product._definition ? (
          <div className="show-product">
            <div className="product-image">
              <NavLink
                to={{
                  pathname: `/itemdetail/${code.trim()}`,
                  state: {
                    name: product._definition[0]["display-name"]
                  }
                }}
              >
                <img
                  src={productImage}
                  onError={e => {
                    const element: any = e.target;
                    element.src = imgPlaceholder;
                  }}
                  alt={
                    product._definition[0]["display-name"] ||
                    intl.get("none-available")
                  }
                  className="cart-lineitem-thumbnail"
                />
              </NavLink>
            </div>
            <div className="title-col" data-el-value="lineItem.displayName">
              <NavLink
                to={{
                  pathname: `/itemdetail/${code.trim()}`,
                  state: {
                    name: product._definition[0]["display-name"]
                  }
                }}
              >
                <p>{product._definition[0]["display-name"]}</p>
              </NavLink>
              <span className="label item-availability">
                {/* eslint-disable-next-line no-nested-ternary */}
                {isLoading && !inventoryError
                  ? intl.get("checking-availability")
                  : inventoryError
                  ? intl.get("available-for-back-order-label")
                  : availabilityMessage}
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
        {isLoading && !inventoryError ? <div className="miniLoader" /> : ""}
        {skuErrorMessage !== "" ? (
          <div className="container-error-message">
            <p className="content-error-message">{skuErrorMessage}</p>
          </div>
        ) : (
          ""
        )}
        {skuErrorMessage === "" && item.isDuplicated ? (
          <div className="container-error-message">
            <p className="content-error-message">
              {intl.get("quick-duplicated-error-message")}
            </p>
          </div>
        ) : (
          ""
        )}
        {inventoryError && (
          <div className="container-error-message">
            <p className="content-error-message">{inventoryError}</p>
          </div>
        )}
        {skuErrorMessage === "" &&
        !item.isDuplicated &&
        product.unitOfMeasure &&
        convertedUnitOfMeasure !== 0 ? (
          <div className="container-error-message">
            <p className="content-error-message">
              {intl.get("unit-of-measure-error-message", {
                product: intl.get("product"),
                unitOfMeasure: product.unitOfMeasure
              })}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function prevNextPropsChange(nextPropItem, prevPropItem) {
  const unchengedKeys = Object.keys(prevPropItem).reduce(
    (accumulatorArray, key) => {
      if (key === "product") {
        const productChange = prevNextPropsChange(
          nextPropItem[key],
          prevPropItem[key]
        );
        return !productChange ? accumulatorArray.concat(key) : accumulatorArray;
      }
      if (prevPropItem[key] === nextPropItem[key]) {
        return accumulatorArray.concat(key);
      }
      return accumulatorArray;
    },
    []
  );

  if (unchengedKeys.length !== Object.keys(prevPropItem).length) {
    return false;
  }
  return true;
}

export default withRouter(QuickOrderForm);
