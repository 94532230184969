import React, { FC } from "react";
import intl from "react-intl-universal";

import {
  EntitlementDetails,
  PriceDetails
} from "@elasticpath/ref-store/src/containers/PartsFinderPage";
import { SingleProduct } from "@elasticpath/ref-store/src/utils/searchUtils";
import { getConfig } from "../utils/ConfigProvider";
import SearchResultsItem from "./search.results.item";
import { productClicked } from "../utils/Segment";

import "./search.results.items.less";

interface SearchResultsItemsProps {
  products: SingleProduct[];
  productEntitlements: EntitlementDetails[];
  productAvailability: any;
  productPrices: PriceDetails[];
  addCartButtonIds: string[];
  start: number;
  rows: number;
  filtersChecked: { [name: string]: any };
  sortByAvailability: boolean;
  numFound: number;
  onAddToCart: (event: any, item?: SingleProduct) => void;
  onLoadMore: (...args) => void;
}

const SearchResultsItems: FC<SearchResultsItemsProps> = ({
  products,
  productAvailability,
  productEntitlements,
  productPrices,
  addCartButtonIds,
  start,
  rows,
  filtersChecked,
  sortByAvailability,
  numFound,
  onAddToCart,
  onLoadMore
}) => {
  const { config } = getConfig();

  const generateProductAvailability = (
    sku: string
  ): [number | string, number | string] => {
    const item =
      productAvailability && productAvailability.find(p => p.sku === sku);
    const branchAvailability = item ? item.branchAvailability : 0;
    const regionAvailability = item ? item.regionAvailability : 0;
    return [branchAvailability, regionAvailability];
  };

  const sortProductsByAvailability = (prev: any, next: any) => {
    if (prev.props.item.branchAvailability < next.props.item.branchAvailability)
      return 1;
    if (prev.props.item.branchAvailability > next.props.item.branchAvailability)
      return -1;

    if (prev.props.item.regionAvailability < next.props.item.regionAvailability)
      return 1;
    if (prev.props.item.regionAvailability > next.props.item.regionAvailability)
      return -1;
    return 0;
  };

  const numOfItemsLoaded = (): number => {
    return (start + 1) * rows >= numFound
      ? products.length
      : (start + 1) * rows;
  };

  const hasResults = !!products.length;
  const itemsLoaded = numOfItemsLoaded();

  const onSearchResultItemClick = item => {
    // sends information to Segment when user clicks on a product
    productClicked(
      item.title,
      item.pid,
      !item.productPrice || item.productPrice === intl.get("pending")
        ? 0
        : item.productPrice,
      item.brand,
      null
    );
  };

  const renderLoadMoreButton = () => {
    const allItemsLoaded = itemsLoaded >= numFound;

    return hasResults && !allItemsLoaded ? (
      <div className="text-center mt-3">
        <button
          type="button"
          className="ep-btn primary wide"
          onClick={onLoadMore}
          aria-label={intl.get("load-more")}
        >
          {intl.get("load-more")}
        </button>
      </div>
    ) : null;
  };

  const renderProducts = () => {
    let productsToRender = products.map(item => {
      let priceDetails;
      let itemWithPrice;

      if (productPrices) {
        try {
          priceDetails = productPrices.find(
            product => product.sku === item.pid
          );
          const productPrice = priceDetails
            ? priceDetails.total
            : intl.get("pending");
          itemWithPrice = { ...item, productPrice };
        } catch (error) {
          itemWithPrice = { ...item, productPrice: intl.get("pending") };
        }
      } else {
        itemWithPrice = { ...item, productPrice: "" };
      }

      const entitlementDetails =
        productEntitlements &&
        productEntitlements.find(p => p.sku === item.pid);

      const entitled = entitlementDetails
        ? entitlementDetails.entitled
        : !config.entitlementCheck;

      itemWithPrice.entitled = entitled;

      const [
        branchAvailability,
        regionAvailability
      ] = generateProductAvailability(item.pid);

      itemWithPrice.branchAvailability = branchAvailability;
      itemWithPrice.regionAvailability = regionAvailability;

      return (
        <SearchResultsItem
          key={item.pid}
          item={itemWithPrice}
          onAddToCart={onAddToCart}
          clickedButtonLoading={addCartButtonIds.includes(item.pid)}
          start={start}
          facets={filtersChecked}
          itemsLoaded={itemsLoaded}
          sortByAvailability={sortByAvailability}
          onClick={clickedItem => onSearchResultItemClick(clickedItem)}
        />
      );
    });
    if (sortByAvailability) {
      /* 
        Products are sorted as:
        The first should be the ones with available quantity at branch level, 
        next the region ones, and then the products with both values empty.
        */
      const sortedProductsToRender = [...productsToRender].sort(
        sortProductsByAvailability
      );

      productsToRender = sortedProductsToRender;
    }

    const limitIndex = numOfItemsLoaded();
    return productsToRender.slice(0, limitIndex);
  };

  return (
    <div className={`search-results ${hasResults ? "" : "no-results"}`}>
      <div className="search-results-container">{renderProducts()}</div>
      {renderLoadMoreButton()}
    </div>
  );
};

export default SearchResultsItems;
