import React from "react";
import { Redirect, withRouter, RouteComponentProps } from "react-router-dom";
import intl from "react-intl-universal";

import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import {
  processHttpResponse,
  handleCustomException,
  generateSpecificErrorMessage,
  formatInventoryAvailability,
  formatDGAInventory
} from "../../../app/src/utils/helpers";
import { addToCart } from "../../../app/src/services/EpServices";
import { brRelatedProducts } from "../../../app/src/services/SearchService";
import { getSimilarProductsWidget } from "../../../app/src/services/PathwaysAndRecommendationsService";
import {
  getProductPrice,
  ProductPriceRequest
} from "../../../app/src/utils/mappings/productDetails";
import SearchResultsItem from "../SearchResultsPage/search.results.item";
import { SingleProduct } from "../../../app/src/utils/searchUtils";
import {
  checkEntitlementSku,
  getAvailabilityMotili,
  getAvailabilityDGA
} from "../../../app/src/services/connectServices";
import { MainContext } from "../../../app/src/contexts/MainContext";
import MultipleItemsCarousel from "../MultipleItemsCarousel/MultipleItemsCarousel";
import { productAdded, productClicked } from "../utils/Segment";

import "./productrecommendations.main.less";
import { Metadata } from "../../../models/BloomreachPathsAndRecommResponse";

// Types

interface RelatedProduct extends SingleProduct {}

interface RelatedProductsResponse {
  numFound: number;
  start: number;
  docs: Array<RelatedProduct>;
}
interface ProductRecommendationsDisplayMainProps extends RouteComponentProps {
  productDetails: { title: string; brand: string; sku: string };
  titleRef?: any;
  history: any;
  productDataDetails: any[];
}

interface ProductRecommendationsDisplayMainState {
  items: Array<RelatedProduct>;
  message: {
    debugMessages: string;
    id: string;
    type: string;
  };
  redirect: boolean;
  productPrices: Array<any>;
  productEntitlements: Array<any>;
  addCartButtonIds: number[];
  productAvailability: any;
  displaySlider: boolean;
  avoidUpdate: boolean;
  metadata: Metadata;
}

let Config: IEpConfig;
declare let BrTrk: any;

/**
 * ## class ProductRecommendationsDisplayMain
 *
 * @description This component is responsible for rendering the "Related products"
 * carousel - component <MultipleItemsCarousel/>.
 */
class ProductRecommendationsDisplayMain extends React.Component<
  ProductRecommendationsDisplayMainProps,
  ProductRecommendationsDisplayMainState
> {
  _isMounted = false;

  static contextType = MainContext;

  constructor(props) {
    super(props);
    Config = getConfig().config;
    this.state = {
      items: null,
      message: null,
      redirect: false,
      productPrices: null,
      productEntitlements: null,
      addCartButtonIds: [],
      productAvailability: null,
      displaySlider: false,
      avoidUpdate: false,
      metadata: null
    };

    this.fetchRelatedProducts = this.fetchRelatedProducts.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onAddToCart = this.onAddToCart.bind(this);
    this.fetchProductPrices = this.fetchProductPrices.bind(this);
    this.validateEntitlement = this.validateEntitlement.bind(this);
    this.validateAvailbility = this.validateAvailbility.bind(this);
    this.addPriceAndEntitlementAndAvailability = this.addPriceAndEntitlementAndAvailability.bind(
      this
    );
    this.handleAddToCartError = this.handleAddToCartError.bind(this);
  }

  componentDidMount() {
    const {
      productDetails: { title, brand, sku }
    } = this.props;
    const {
      branches: { branchesInContext }
    } = this.context;

    this._isMounted = true;
    if (branchesInContext) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ avoidUpdate: true });
    }
    this.fetchRelatedProducts(title, brand, sku);
  }

  componentDidUpdate(prevProps) {
    const {
      productDetails: { title, brand, sku }
    } = this.props;
    const {
      branches: { branchesInContext }
    } = this.context;
    const { displaySlider, avoidUpdate } = this.state;

    const {
      productDetails: { sku: prevSku }
    } = prevProps;
    if (
      (sku && sku !== "undefined" && sku !== prevSku) ||
      (branchesInContext && !displaySlider && !avoidUpdate)
    ) {
      this.fetchRelatedProducts(title, brand, sku);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ avoidUpdate: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * ## onAddToCart
   *
   * @param event { target: { id: any }
   *
   * @description Calls Cortex API and processes the response-
   * sets the response message in the component's state.
   */
  onAddToCart(event: { target: { id: any } }, item?: any) {
    const { id: pid } = event.target;
    const { addCartButtonIds } = this.state;
    const {
      cart: {
        cartDetails: {
          defaultCart: { addItemsToCart }
        }
      }
    } = this.context;
    const {
      cortexApi: { scope }
    } = Config;

    const items = [
      {
        code: pid,
        quantity: 1
      }
    ];

    if (this._isMounted) {
      this.setState({
        addCartButtonIds: addCartButtonIds.concat(pid)
      });
    }

    addToCart(addItemsToCart.self.uri, { items })
      .then(res => {
        processHttpResponse(
          res,
          () => {
            // Update Cart Count in Context.
            const { cart } = this.context;

            cart
              .getCartDetails()
              .then(() => {
                cart.setSuccesCartPopupMessage(1);
              })
              .catch(err => this.handleAddToCartError(pid, err));

            if (this._isMounted) {
              this.setState(prevState => {
                const { addCartButtonIds: buttonIds } = prevState;
                return {
                  message: {
                    debugMessages: intl.get("reorder-success"),
                    id: "",
                    type: "needinfo"
                  },
                  addCartButtonIds: buttonIds.filter(buttonId => {
                    return buttonId !== pid;
                  })
                };
              });
            }
          },
          err => this.handleAddToCartError(pid, err)
        );
      })
      .then(() => {
        if (item) {
          // sends information to Segment when user adds a product
          productAdded(
            item.title,
            item.pid,
            !item.productPrice || item.productPrice === intl.get("pending")
              ? 0
              : item.productPrice,
            item.brand,
            null,
            1
          );
          if (scope !== "motili") this.trackWidgetAddToCartEvent(pid);
        }
      })
      .catch(error => this.handleAddToCartError(pid, error));
  }

  /**
   * ## fetchRelatedProducts
   *
   * @param title string
   * @param brand string
   * @param sku string
   *
   * @description Calls BR API and fetches products for "Related products" section.
   * Accepts query parameters for product title and brand.
   */
  fetchRelatedProducts(title: string, brand: string, sku: string): void {
    const { location, productDataDetails } = this.props;
    const {
      cortexApi: { scope }
    } = Config;
    const { state } = location;
    let prev = "";
    if (!(typeof state === "undefined")) {
      if (
        Object.prototype.hasOwnProperty.call(state, "partsFinderSearchParams")
      ) {
        prev = state.partsFinderSearchParams.prevUrl;
      } else if (
        Object.prototype.hasOwnProperty.call(state, "searchResultParams")
      ) {
        prev = state.searchResultParams.prevUrl;
      } else {
        prev = state.prevUrl;
      }
    }
    const productClass = productDataDetails.find(
      detail => detail.name === "class"
    ).value;
    let tonnage = "";

    const productClassesArray = [
      "Split Air Conditioner",
      "Split Heat Pump",
      "Coil",
      "Air Handler"
    ];
    const isClassFiltered = productClassesArray.includes(productClass);

    if (isClassFiltered) {
      tonnage = productDataDetails.find(detail => detail.name === "tonnage")
        .value;
    }
    const prevUrl = prev;
    if (scope === "motili") {
      brRelatedProducts(title, brand, sku, window.location.href, prevUrl).then(
        relatedProductsResponse => {
          this.processRelatedProducts(relatedProductsResponse);
        }
      );
    } else {
      getSimilarProductsWidget(
        sku,
        window.location.href,
        productClass,
        prevUrl,
        tonnage
      ).then(similarProductsResponse => {
        this.processRelatedProducts(similarProductsResponse);
      });
    }
  }

  /**
   * ## processRelatedProducts
   *
   * @param productsResponse RelatedProductsResponse
   * @description Get the list of prices for each product.
   */

  processRelatedProducts(productsResponse): void {
    const {
      branches: { branchesInContext },
      auth: { guestLoggedIn }
    } = this.context;
    const { displaySlider } = this.state;
    const {
      cortexApi: { scope }
    } = Config;
    const onSuccess = (data: {
      response: RelatedProductsResponse;
      metadata: Metadata;
    }) => {
      const {
        response: { docs },
        metadata
      } = data;

      const items = docs.map(singleProduct => {
        if (singleProduct.full_image) {
          return {
            ...singleProduct,
            thumb_image: singleProduct.full_image
          };
        }
        return singleProduct;
      });

      if (this._isMounted) {
        let newDisplaySliderVal = displaySlider;
        if ((branchesInContext && !displaySlider) || guestLoggedIn) {
          newDisplaySliderVal = true;
        }
        this.setState({ items, displaySlider: newDisplaySliderVal });
      }
      if (Config.calculatePrice && docs.length !== 0)
        this.fetchProductPrices(docs);
      this.validateEntitlement(docs);
      this.validateAvailbility(docs);
      this.setState({
        metadata
      });
      if (scope !== "motili") this.trackWidgetViewEvent();
    };
    processHttpResponse(productsResponse, onSuccess, this.handleError);
  }

  /**
   * ## fetchProductPrices
   *
   * @param products Array<RelatedProduct>
   * @description Get the list of prices for each product.
   */
  fetchProductPrices(products: RelatedProduct[]) {
    const { history } = this.props;
    const { auth, user, cart, job } = this.context;
    const { logout } = auth;
    const { customerNumber } = user.userProfile;

    const {
      cartDetails: { defaultCart }
    } = cart;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    const jobNumber = defaultCart ? defaultCart.jobNumber : null;

    if (auth.isLoggedIn && selectedBranch && customerNumber) {
      const skus = products.map((product: RelatedProduct) => product.pid);
      const body: ProductPriceRequest = {
        customerNumber,
        branchNumber: selectedBranch.code,
        skus,
        jobNumber: ""
      };

      if (defaultCart.jobNumber || job.persistedJobNumber) {
        body.jobNumber = defaultCart.jobNumber || job.persistedJobNumber;
      }

      getProductPrice(
        body,
        res => {
          if (this._isMounted) {
            this.setState({ productPrices: res.items });
          }
        },
        e => {
          const errorPath =
            "fetchProductPrices => productrecommendations.main.tsx";

          handleCustomException(e, logout, history, errorPath);

          if (this._isMounted) {
            this.setState({
              productPrices: null
            });
          }
        }
      );
    }
  }

  /**
   * ## validateEntitlement
   * @param docs Array<any>
   * @description Fetches the entitlement for each SKU for the logged user.
   */
  async validateEntitlement(docs) {
    const { history } = this.props;

    const {
      auth: { isLoggedIn, logout },
      cart: {
        cartDetails: { defaultCart }
      },
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;

    if (isLoggedIn && defaultCart && Config.entitlementCheck) {
      const { selectedBranch } = defaultCart;
      const skus = docs.map(product => product.pid).join("|");
      try {
        const { data } = await checkEntitlementSku(
          customerNumber,
          skus,
          selectedBranch.vendor
        );
        if (this._isMounted) {
          this.setState({
            productEntitlements: data
          });
        }
      } catch (error) {
        const errorPath =
          "validateEntitlement => checkEntitlementSku => productrecommendations.main.tsx";
        handleCustomException(error, logout, history, errorPath);
      }
    }
  }

  /**
   * ## validateAvailability
   * @param docs Array<any>
   * @description Fetches the availability for each SKU for the logged user.
   */
  async validateAvailbility(docs) {
    const { history } = this.props;

    const {
      auth: { isLoggedIn, logout },
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { branchesList, findBranch },
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;
    const {
      cortexApi: { scope }
    } = Config;

    if (isLoggedIn && defaultCart && branchesList) {
      const { selectedBranch, clientId } = defaultCart;
      const { latitude, longitude } = findBranch(selectedBranch.code);
      const skus = docs.map(product => product.pid);
      const skusMotili = skus && skus.join("|");

      try {
        let inventoryAvailability;
        if (scope === "motili") {
          const { data } = await getAvailabilityMotili(
            skusMotili,
            latitude,
            longitude,
            clientId
          );
          inventoryAvailability = formatInventoryAvailability(data);
        } else {
          const branchNumber = selectedBranch.code;

          const { data } = await getAvailabilityDGA(
            customerNumber,
            skus,
            branchNumber
          );
          inventoryAvailability = formatDGAInventory(data);
        }

        if (this._isMounted) {
          this.setState({
            productAvailability: inventoryAvailability
          });
        }
      } catch (error) {
        const errorPath =
          "validateAvailability => getAvailability => productrecommendations.main.tsx";
        handleCustomException(error, logout, history, errorPath);
      }
    }
  }

  /**
   * ## addPriceAndEntitlement
   * @description If prices and entitlements exist,
   * add them to items. If not, set an empty string so the child
   * component will handle that.
   */
  addPriceAndEntitlementAndAvailability() {
    const {
      items,
      productEntitlements,
      productPrices,
      productAvailability
    } = this.state;
    const { productDataDetails } = this.props;

    const productClass = productDataDetails.find(
      detail => detail.name === "class"
    ).value;

    const productClassesArray = [
      "Split Air Conditioner",
      "Split Heat Pump",
      "Coil",
      "Air Handler"
    ];
    const isClassFiltered = productClassesArray.includes(productClass);

    const {
      productsDisplayed
    } = Config.bloomreachWidget.config.similarProducts;

    const displayedItems = isClassFiltered
      ? items
      : items.slice(0, Number(productsDisplayed));

    return displayedItems.map(item => {
      let priceDetails;
      let itemWithDetails;

      try {
        priceDetails = productPrices.find(product => product.sku === item.pid);
        const productPrice = priceDetails ? priceDetails.total : "";
        itemWithDetails = { ...item, productPrice };
      } catch (error) {
        itemWithDetails = { ...item, productPrice: intl.get("pending") };
      }

      const entitlementDetails =
        productEntitlements &&
        productEntitlements.find(p => p.sku === item.pid);

      const entitled = entitlementDetails
        ? entitlementDetails.entitled
        : !Config.entitlementCheck;

      itemWithDetails.entitled = entitled;

      const inventoryItem =
        productAvailability &&
        productAvailability.find(p => p.sku === item.pid);

      itemWithDetails.branchAvailability = inventoryItem
        ? inventoryItem.branchAvailability
        : 0;
      itemWithDetails.regionAvailability = inventoryItem
        ? inventoryItem.regionAvailability
        : 0;

      return itemWithDetails;
    });
  }

  /**
   * ## handleError
   *
   * @param error string - message
   *
   * @description Sets the error message in the state and redirects to
   * maintenance page.
   */
  handleError(error?: string) {
    const errorMessage = error || intl.get("service-error");
    if (this._isMounted) {
      this.setState({
        message: {
          debugMessages: errorMessage,
          id: "",
          type: "error"
        },
        redirect: true
      });
    }
  }

  handleAddToCartError(pid: number, err: any = undefined): void {
    if (this._isMounted) {
      const {
        cart: { setErrorCartPopupMessage }
      } = this.context;
      setErrorCartPopupMessage(generateSpecificErrorMessage(err));
      this.setState(prevState => {
        const { addCartButtonIds: buttonIds } = prevState;
        return {
          addCartButtonIds: buttonIds.filter(buttonId => {
            return buttonId !== pid;
          })
        };
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onSearchResultItemClick(item) {
    const {
      cortexApi: { scope }
    } = Config;
    // sends information to Segment when user clicks on a product
    productClicked(
      item.title,
      item.pid,
      !item.productPrice || item.productPrice === intl.get("pending")
        ? 0
        : item.productPrice,
      item.brand,
      null
    );
    if (scope !== "motili") this.trackWidgetClickEvent(item);
  }

  /**
   * ## renderCarousel
   *
   * @description Renders carousel component for the related products on PDP.
   * If a product has any of the following classes:
   * "Split Air Conditioner", "Split Heat Pump", "Coil" and "Air Handler",
   * a filter is applied: only products that are entitled and available for purchase
   * are rendered in carousel
   */
  renderCarousel() {
    const { items, addCartButtonIds, displaySlider } = this.state;

    const { titleRef = undefined, productDataDetails } = this.props;

    const {
      auth: { guestLoggedIn }
    } = this.context;
    const {
      cortexApi: { scope }
    } = Config;

    const {
      productsDisplayed
    } = Config.bloomreachWidget.config.similarProducts;
    const productClass = productDataDetails.find(
      detail => detail.name === "class"
    ).value;
    const productClassesArray = [
      "Split Air Conditioner",
      "Split Heat Pump",
      "Coil",
      "Air Handler"
    ];
    const isClassFiltered = productClassesArray.includes(productClass);

    const itemsWithDetails = this.addPriceAndEntitlementAndAvailability();
    const filteredItemsWithDetails = itemsWithDetails.filter(el => {
      const isPriceZero = el && `${el.productPrice}` === "0";
      if (guestLoggedIn) {
        return (
          !Config.checkAvailability ||
          el.branchAvailability ||
          el.regionAvailability
        );
      }
      if (scope === "motili") {
        return (
          el.entitled &&
          (!Config.checkAvailability ||
            el.branchAvailability ||
            el.regionAvailability)
        );
      }
      return (
        Config.calculatePrice &&
        !isPriceZero &&
        el.entitled &&
        (!Config.checkAvailability ||
          el.branchAvailability ||
          el.regionAvailability)
      );
    });

    const carouselItems =
      filteredItemsWithDetails &&
      filteredItemsWithDetails.slice(0, Number(productsDisplayed));
    const itemsLength = items && items.slice(0, Number(productsDisplayed));

    return displaySlider ? (
      <MultipleItemsCarousel
        items={
          isClassFiltered
            ? carouselItems
            : this.addPriceAndEntitlementAndAvailability()
        }
        numOfItems={isClassFiltered ? carouselItems.length : itemsLength.length}
        carouselConfig={Config["mlt-carousel"]}
        displayComponent={item => (
          <SearchResultsItem
            item={item}
            onAddToCart={this.onAddToCart}
            key={item.pid}
            titleRef={titleRef}
            clickedButtonLoading={addCartButtonIds.includes(item.pid)}
            onClick={clickedItem => this.onSearchResultItemClick(clickedItem)}
          />
        )}
      />
    ) : (
      <div className="loader-container">
        <div className="loader" />
      </div>
    );
  }

  trackWidgetViewEvent = () => {
    const {
      metadata: {
        widget: { rid, id, type }
      }
    } = this.state;
    const widgetViewData = {
      wrid: rid,
      wid: id,
      wty: type
    };
    if (typeof BrTrk !== "undefined")
      BrTrk.getTracker().logEvent(
        "widget",
        "widget-view",
        widgetViewData,
        true
      );
  };

  trackWidgetClickEvent = (item: SingleProduct) => {
    const {
      metadata: {
        widget: { rid, id, type }
      }
    } = this.state;
    const widgetClickData = {
      wrid: rid,
      wid: id,
      wty: type,
      item_id: item.pid
    };
    if (typeof BrTrk !== "undefined")
      BrTrk.getTracker().logEvent(
        "widget",
        "widget-click",
        widgetClickData,
        true
      );
  };

  trackWidgetAddToCartEvent = (pid: string) => {
    const {
      metadata: {
        widget: { rid, id, type }
      }
    } = this.state;
    const widgetAtcData = {
      wrid: rid,
      wid: id,
      wty: type,
      item_id: pid,
      sku: pid
    };
    if (typeof BrTrk !== "undefined")
      BrTrk.getTracker().logEvent("cart", "widget-add", widgetAtcData);
  };

  render() {
    const { items, redirect, message } = this.state;

    return items && items.length ? (
      <div className="content-back full-width">
        <div className="related-items-section container">
          <h2 className="bullet override-h2-as-h4">
            {intl.get("product-recommendations")}
          </h2>
          {this.renderCarousel()}
          {redirect && message.debugMessages && (
            <Redirect
              to={{
                pathname: "/maintenance",
                state: {
                  error: {
                    e: { message: message.debugMessages },
                    errIn: "ProductRecommendationsMain"
                  }
                }
              }}
            />
          )}
        </div>
      </div>
    ) : null;
  }
}

export default withRouter(ProductRecommendationsDisplayMain);
