import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import jsonpointer from "jsonpointer";
import { getNestedObject, getImageUrl } from "bloomreach-experience-react-sdk";
import { isLinkExternal } from "../../../../../app/src/utils/helpers";
import { MainContext } from "../../../../../app/src/contexts/MainContext";

import "./quick-services.less";

const QuickServices = ({ configuration, pageModel, preview }) => {
  const [quickServices, setQuickServices] = useState<any>(null);

  const context = useContext<{ auth: any }>(MainContext);

  const {
    auth: { isLoggedIn }
  } = context;

  useEffect(() => {
    const ref = getNestedObject(configuration, ["models", "document", "$ref"]);
    const content = ref && jsonpointer.get(pageModel, ref);
    setQuickServices(content);
  });

  const renderQuickServices = () => {
    const { cards } = quickServices;
    return cards.map(({ cellWidth, elements, id: cardId }) => (
      <div
        className="quick-services-card"
        style={{ flex: `${cellWidth} 0 0` }}
        id={cardId}
        key={cardId}
      >
        {elements.map(
          ({
            title,
            image,
            link,
            id: elementId,
            isLinkForLoggedInUserOnly,
            isQuickOrder
          }) => {
            const [isExternal, url] = isLinkExternal(link);
            const modalValue = isQuickOrder;
            return !isExternal ? (
              <Link
                to={{
                  pathname:
                    !isLoggedIn && isLinkForLoggedInUserOnly ? "/login" : url,
                  state: { modal: modalValue, prevUrl: window.location.href }
                }}
                className="quick-services-element"
                id={elementId}
                key={elementId}
                onClick={() => window.scrollTo(0, 0)}
              >
                <img
                  src={getImageUrl(image, pageModel, preview)}
                  alt="Non available"
                />
                <h4>{title}</h4>
              </Link>
            ) : (
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="quick-services-element"
                id={elementId}
              >
                <img
                  src={getImageUrl(image, pageModel, preview)}
                  alt="Non available"
                />
                <h4>{title}</h4>
              </a>
            );
          }
        )}
      </div>
    ));
  };

  return (
    <div className="quick-services container">
      {quickServices && renderQuickServices()}
    </div>
  );
};

export default QuickServices;
