import React, { FC, useState, useEffect, useContext } from "react";
import Modal from "react-responsive-modal";
import { withRouter, RouteComponentProps } from "react-router-dom";
import intl from "react-intl-universal";
import uuidv4 from "uuid/v4";
import { cortexFetch } from "@zilker/store-components";
import { MainContext } from "../../../app/src/contexts/MainContext";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
} from "../../../app/src/utils/helpers";

interface AppHeaderJobSelectProps extends RouteComponentProps {
  history: any;
  closeJobModal: () => void;
  initialJob: string;
  disableJobSelection: boolean;
}

const AppHeaderJobSelect: FC<AppHeaderJobSelectProps> = ({
  history,
  closeJobModal,
  initialJob,
  disableJobSelection
}) => {
  const context = useContext<{ auth: any; user: any; cart: any; job: any }>(
    MainContext
  );
  const [inputValue, setValue] = useState(initialJob);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const {
    user: {
      userProfile: { jobsArray }
    },
    cart: {
      cartDetails: { defaultCart },
      getCartDetails
    }
  } = context;
  const {
    auth: { logout },
    job: { setPersistedJobNumber, setPersistedJobName }
  } = context;

  const closeModal = () => {
    setIsModalOpen(false);
    closeJobModal();
  };

  const persistJobInContext = ({ jobNumber, jobName }) => {
    setPersistedJobNumber(jobNumber);
    setPersistedJobName(jobName);
  };

  const onContinue = () => {
    const link = defaultCart.cartOrderDetailsForm.links[0].uri;

    const jobSelected = jobsArray.find(job => job.jobNumber === inputValue);

    /**
     * After the order is completed, user's session is still active
     * and we can assume the user wants to keep using the same job.
     * That's why we persist the job name and number.
     */
    persistJobInContext(jobSelected);

    const body = {
      "update-mode": "JOB",
      "job-number": jobSelected.jobNumber,
      "job-name": jobSelected.jobName
    };

    if (disableJobSelection) {
      sessionStorage.setItem("jobChosen", "true");
      closeModal();
      return;
    }

    cortexFetch(link, {
      method: "post",
      body: JSON.stringify(body)
    })
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(() => {
        getCartDetails();
        sessionStorage.setItem("jobChosen", "true");
        closeModal();
      })
      .catch(e => {
        closeModal();
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => onContinue => AppHeaderJobSelect.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "onContinue => AppHeaderJobSelect.tsx"
          });
        }
      });
  };

  const renderJobOption = () => {
    return jobsArray.map((job, index) => (
      <div
        className={`radio ${
          disableJobSelection && inputValue !== job.jobNumber
            ? "disable-job"
            : ""
        }`}
        key={`${uuidv4()}-${job.jobNumber}`}
      >
        <label
          htmlFor={`cart-selection-option${index}`}
          className="custom-radio-button"
        >
          <input
            id={`cart-selection-option${index}`}
            type="radio"
            value={job.jobNumber}
            checked={inputValue === job.jobNumber}
            onChange={e => setValue(e.target.value)}
          />
          <span className="helping-el" />
          <span className="label-text">
            {job.jobNumber}:{job.jobName}
          </span>
        </label>
      </div>
    ));
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={logout}
        classNames={{ modal: "cart-selection-modal-content" }}
      >
        <div className="modal-lg">
          <div className="modal-content" id="simplemodal-container">
            <div className="modal-header">
              <h2 className="modal-title">{intl.get("job-number")}</h2>
            </div>

            <div className="modal-body">
              <div id="cart_selection_modal_form">
                <div className="carts-selection-region">
                  {jobsArray ? renderJobOption() : null}
                </div>

                <div className="action-row">
                  {disableJobSelection && (
                    <p className="job-change-warning">
                      {intl.get("job-selection-warning")}
                    </p>
                  )}
                  <div className="form-input btn-container">
                    <button
                      onClick={onContinue}
                      className="ep-btn primary wide"
                      id="continue_with_cart_button"
                      data-cmd="continue"
                      // data-toggle="collapse"
                      // data-target=".navbar-collapse"
                      type="submit"
                      disabled={!inputValue}
                    >
                      {intl.get("continue")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(AppHeaderJobSelect);
