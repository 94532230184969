import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import intl from "react-intl-universal";
import {
  cortexFetch,
  B2bEditAssociate,
  page,
  getConfig,
  Messagecontainer
} from "@zilker/store-components";
import { checkResponse } from "@elasticpath/ref-store/src/utils/helpers";
import Modal from "react-responsive-modal";
import { MainContext } from "../../contexts/MainContext";

import "./AccountMain.less";

interface AccountMainProps extends RouteComponentProps {
  account: any;
  history: any;
  auth: any;
  user: any;
}

interface AccountMainState {
  isLoading: boolean;
  isDeletingSubuser: boolean;
  isEditAssociateOpen: boolean;
  accountName: string;
  mainAccountName: string;
  selector: string;
  associateEditEmail: string;
  associates: {
    [key: string]: any;
  };
  userEmail: string;
  isAddAssociateOpen: boolean;
  addAssociateUri: string;
  error: string;
  deleteAssociateModalOpened: boolean;
  selectedAssociate: any;
}

class AccountMain extends React.Component<AccountMainProps, AccountMainState> {
  static contextType = MainContext;

  _isMounted = false;

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      isDeletingSubuser: false,
      isEditAssociateOpen: false,
      isAddAssociateOpen: false,
      accountName: "",
      mainAccountName: "",
      selector: "",
      associates: {},
      userEmail: "",
      associateEditEmail: "",
      addAssociateUri: "",
      error: "",
      deleteAssociateModalOpened: false,
      selectedAssociate: undefined
    };

    this.handleAccountSettingsUpdate = this.handleAccountSettingsUpdate.bind(
      this
    );
    this.handleEditAssociateClicked = this.handleEditAssociateClicked.bind(
      this
    );
    this.handleDeleteSubuser = this.handleDeleteSubuser.bind(this);
    this.handleAddAssociateClicked = this.handleAddAssociateClicked.bind(this);
    this.isEditAssociateClose = this.isEditAssociateClose.bind(this);
    this.getAccountData = this.getAccountData.bind(this);
    this.renderDesktopLayout = this.renderDesktopLayout.bind(this);
    this.renderMobileLayout = this.renderMobileLayout.bind(this);
    this.closeDeleteUserModal = this.closeDeleteUserModal.bind(this);
  }

  componentDidMount() {
    const {
      account: { accountDetails }
    } = this.props;
    this._isMounted = true;
    this.getAccountData(accountDetails);

    page();
  }

  componentDidUpdate(prevProps) {
    const {
      account: { accountDetails }
    } = prevProps;
    const {
      account: { accountDetails: newAccount }
    } = this.props;

    if (accountDetails.associates.length !== newAccount.associates.length) {
      this.getAccountData(newAccount);
      this.handleLoadingState();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleLoadingState() {
    this.setState({
      isDeletingSubuser: false
    });
  }

  getAccountData(account) {
    if (account.isBuyerAdmin) {
      const { associates, name, addAssociateUri, primaryEmail } = account;

      if (this._isMounted && associates && name && addAssociateUri) {
        this.setState({
          accountName: name,
          mainAccountName: name,
          isLoading: false,
          associates: associates.map(element => ({
            associate: {
              ...element._associate[0],
              deleteUri: element.self.uri
            },
            roles: element._roleinfo[0]
          })),
          userEmail: primaryEmail,
          addAssociateUri
        });
      }
    }
  }

  fetchAccountData = async () => {
    const {
      account: { getUserAccount }
    } = this.context;
    await getUserAccount();
  };

  handleAccountSettingsUpdate = async () => {
    await this.fetchAccountData();
    const {
      account: { accountDetails }
    } = this.context;

    this.getAccountData(accountDetails);
  };

  handleEditAssociateClicked(selector, associateEditEmail) {
    if (this._isMounted) {
      this.setState({
        isEditAssociateOpen: true,
        selector,
        associateEditEmail
      });
    }
  }

  handleAddAssociateClicked() {
    if (this._isMounted) {
      this.setState({ isEditAssociateOpen: true, isAddAssociateOpen: true });
    }
  }

  handleDeleteSubuser(email: string) {
    const { scope } = getConfig().config.cortexApi;
    const { selectedAssociate } = this.state;
    const {
      associate: { deleteUri }
    } = selectedAssociate;
    const url: string = `/deletesubuser/${scope}/form`;

    const accountguid: string = deleteUri.slice(
      deleteUri.lastIndexOf("am/") + 3,
      deleteUri.lastIndexOf("/associate")
    );

    this.setState({
      isDeletingSubuser: true
    });

    cortexFetch(url, {
      method: "post",
      body: JSON.stringify({
        accountguid,
        email
      })
    })
      .then(res => {
        if (res.status === 400) {
          return res.json();
        }
        return res;
      })
      .then(res => {
        const onSuccess = data => data;

        return checkResponse(res, onSuccess);
      })
      .then(() => {
        this.setState({
          deleteAssociateModalOpened: false
        });
        this.handleAccountSettingsUpdate();
      })
      .catch(err => {
        if (this._isMounted) {
          this.setState({
            error: err,
            isDeletingSubuser: false,
            deleteAssociateModalOpened: false
          });
        }
      });
  }

  openDeleteUserModal(associate) {
    this.setState({
      deleteAssociateModalOpened: true,
      selectedAssociate: associate
    });
  }

  closeDeleteUserModal() {
    this.setState({
      deleteAssociateModalOpened: false
    });
  }

  renderDeleteAssociateModal() {
    const { deleteAssociateModalOpened, selectedAssociate } = this.state;
    const associateEmail = selectedAssociate.associate._primaryemail[0].email;
    return (
      <Modal
        open={deleteAssociateModalOpened}
        onClose={this.closeDeleteUserModal}
      >
        <div className="modal-lg delete-associate-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Delete user</h2>
            </div>
            <div className="modal-body">
              <p>{intl.get("delete-user-confirmation")}</p>
              <div className="delete-associate-buttons">
                <button
                  type="button"
                  className="ep-btn"
                  onClick={this.closeDeleteUserModal}
                >
                  {intl.get("cancel")}
                </button>
                <button
                  type="button"
                  className="ep-btn primary"
                  onClick={e => this.handleDeleteSubuser(associateEmail)}
                >
                  <i className="icon-delete" />
                  {intl.get("delete")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  isEditAssociateClose() {
    if (this._isMounted) {
      this.setState({
        isEditAssociateOpen: false,
        associateEditEmail: "",
        selector: "",
        isAddAssociateOpen: false
      });
    }
  }

  openChat = () => window.zE.activate();

  renderMobileLayout() {
    const { associates, selectedAssociate, isDeletingSubuser } = this.state;
    const {
      user: { userProfile }
    } = this.context;
    const { accountUsers } = userProfile;

    return (
      <div className="associates-list-mobile">
        {associates && associates.length ? (
          associates.map((element: any) => {
            const associateEmail = element.associate._primaryemail[0].email;
            const associate = accountUsers.find(
              accountUser => accountUser.email === associateEmail
            );
            const associateName = associate
              ? `${associate.firstName} ${associate.lastName}`
              : "";
            return (
              <div key={associateEmail} className="associate-card">
                <div className="associate-row">
                  <span className="associate-label">
                    {`${intl.get("name")}: `}
                  </span>
                  <span>{associateName}</span>
                </div>
                <div className="associate-row">
                  <span className="associate-label">
                    {`${intl.get("roles")}: `}
                  </span>

                  {element.roles._roles.length &&
                  element.roles._roles[0]._element
                    ? element.roles._roles[0]._element
                        .map(r => intl.get(r.name.toLowerCase()) || r.name)
                        .join(", ")
                    : intl.get("none")}
                </div>
                <div className="associate-row">
                  <span className="associate-label">
                    {`${intl.get("email")}: `}
                  </span>

                  <span>{associateEmail}</span>
                </div>
                {/* eslint-disable no-nested-ternary */}
                {isDeletingSubuser &&
                selectedAssociate &&
                selectedAssociate.associate._primaryemail[0].email ===
                  associateEmail ? (
                  <div className="action">
                    <div className="miniLoader" />
                  </div>
                ) : userProfile.email === associateEmail ? null : (
                  <div className="action">
                    <button
                      className="ep-btn"
                      type="button"
                      onClick={e => this.openDeleteUserModal(element)}
                    >
                      <i className="icon-delete" />
                      {intl.get("delete").toUpperCase()}
                    </button>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <p className="no-associates">{intl.get("account-no-associates")}</p>
        )}
      </div>
    );
  }

  renderDesktopLayout() {
    const { associates, selectedAssociate, isDeletingSubuser } = this.state;
    const {
      user: { userProfile }
    } = this.context;
    const { accountUsers } = userProfile;

    return (
      <table
        className={`associates-table ${
          associates && associates.length === 0 ? "empty-table" : ""
        }`}
      >
        <thead>
          <tr>
            <th className="name-email">{intl.get("name-and-email")}</th>
            <th className="name">{`${intl.get("name").toUpperCase()}:`}</th>
            <th className="email">{`${intl.get("email").toUpperCase()}:`}</th>
            <th className="roles">{`${intl.get("roles").toUpperCase()}:`}</th>
            <th className="action">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {associates && associates.length === 0 && (
            <tr>
              <td>{intl.get("account-no-associates")}</td>
            </tr>
          )}
          {associates &&
            associates.map((element, index) => {
              const hasDarkBackground = index % 2 > 0 ? "dark" : "";
              const associateEmail = element.associate._primaryemail[0].email;
              const associate = accountUsers.find(
                accountUser => accountUser.email === associateEmail
              );
              const associateName = associate
                ? `${associate.firstName} ${associate.lastName}`
                : "";

              return (
                <tr
                  key={associateEmail}
                  className={`associates-table-row-${hasDarkBackground}`}
                >
                  <td className="name">
                    <div className="name-part">{associateName}</div>
                  </td>
                  <td className="email">
                    <div className="email-part">{associateEmail}</div>
                  </td>
                  <td className="name-email">
                    <div className="name-part">{associateName}</div>
                    <div className="email-part">{associateEmail}</div>
                  </td>
                  <td className="roles">
                    {element.roles._roles.length &&
                    element.roles._roles[0]._element
                      ? element.roles._roles[0]._element
                          .map(r => intl.get(r.name.toLowerCase()) || r.name)
                          .join(", ")
                      : intl.get("none")}
                  </td>
                  {/* eslint-disable no-nested-ternary */}
                  {isDeletingSubuser &&
                  selectedAssociate &&
                  selectedAssociate.associate._primaryemail[0].email ===
                    associateEmail ? (
                    <td className="action">
                      <div className="miniLoader" />
                    </td>
                  ) : userProfile.email === associateEmail ? null : (
                    <td className="action">
                      <button
                        className="delete-associate"
                        type="button"
                        onClick={e => this.openDeleteUserModal(element)}
                      >
                        <i className="icon-delete" />
                        {intl.get("delete").toUpperCase()}
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  }

  render() {
    const {
      isLoading,
      accountName,
      isEditAssociateOpen,
      selector,
      associateEditEmail,
      userEmail,
      addAssociateUri,
      isAddAssociateOpen,
      mainAccountName,
      error,
      selectedAssociate
    } = this.state;
    const {
      history,
      account: { accountDetails },
      user
    } = this.props;
    const {
      context: { auth }
    } = this;
    const selectedAssociateEmail =
      selectedAssociate && selectedAssociate.associate._primaryemail[0].email;

    return (
      <div className="content-box">
        {isLoading ? (
          <div className="loader" />
        ) : (
          <div>
            <div className="account-component">
              <div className="associates-container">
                <h4 className="bullet">
                  {intl.get("account-users").toUpperCase()}
                </h4>
                {error ? (
                  <div className="error-container">
                    <Messagecontainer
                      message={{
                        type: "danger-message",
                        debugMessages: intl.get("delete-subuser-error", {
                          selectedAssociateEmail
                        })
                      }}
                      closeContainerHandler={null}
                      stayOpen
                      button={{
                        text: intl.get("support"),
                        action: this.openChat,
                        className: "support-btn"
                      }}
                    />
                  </div>
                ) : null}
                {this.renderMobileLayout()}
                {this.renderDesktopLayout()}
                {selectedAssociate && this.renderDeleteAssociateModal()}
                <div className="add-associate-container">
                  <button
                    type="button"
                    aria-label={intl.get("add-user")}
                    className="ep-btn primary"
                    onClick={this.handleAddAssociateClicked}
                  >
                    {intl.get("add-user").toUpperCase()}
                  </button>
                </div>
              </div>

              <B2bEditAssociate
                handleClose={this.isEditAssociateClose}
                handleUpdate={this.handleAccountSettingsUpdate}
                accountName={mainAccountName}
                user={user}
                subAccountName={accountName}
                rolesSelector={selector}
                isSelf={associateEditEmail === userEmail}
                associateEmail={associateEditEmail}
                isOpen={isEditAssociateOpen}
                isAddAssociateOpen={isAddAssociateOpen}
                addAssociateUri={addAssociateUri}
                history={history}
                auth={auth}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(AccountMain);
