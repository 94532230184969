import React from "react";
import jsonpointer from "jsonpointer";
import {
  createLink,
  getImageUrl,
  parseAndRewriteLinks,
  getNestedObject,
  CmsEditButton
} from "bloomreach-experience-react-sdk";

const BlogList = props => {
  const { configuration, pageModel, preview } = props;
  const contentItems = getNestedObject(configuration, [
    "models",
    "pageable",
    "items"
  ]);
  if (!contentItems) {
    return <span>No Content</span>;
  }
  const refs = contentItems.map(item => item.$ref);
  const contents = refs.map(ref => jsonpointer.get(pageModel, ref));

  const renderItems = () => {
    return contents.map((content, index) => {
      return (
        <div className="row mb-3" key={content.id}>
          <CmsEditButton configuration={content} preview={preview} />
          <div className="col-12">
            <h3>{content.title}</h3>
          </div>
          <div className="col-12">
            {parseAndRewriteLinks(content.content.value, preview)}
          </div>
        </div>
      );
    });
  };

  return <div className="container">{renderItems()}</div>;
};

export default BlogList;
