/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import { checkResponse } from "@elasticpath/ref-store/src/utils/helpers";
import {
  navigationFormZoomArray,
  itemFormZoomArray,
  purchaseFormZoomArray,
  searchFormZoomArray,
  batchFormZoomArray
} from "@zilker/store-components/src/static/zoom";
import { cortexFetch } from "./Cortex";
import { getConfig } from "./ConfigProvider";

export function cortexFetchNavigationLookupForm() {
  return new Promise((resolve, reject) => {
    if (
      localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_navigationLookupForm`
      ) === null
    ) {
      cortexFetch("/?zoom=lookups:navigationlookupform")
        .then(res => checkResponse(res))
        .then(res => {
          const navigationForm = res._lookups[0]._navigationlookupform[0].links.find(
            link => link.rel === "navigationlookupaction"
          ).uri;
          localStorage.setItem(
            `${getConfig().config.cortexApi.scope}_navigationLookupForm`,
            navigationForm
          );
          resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    } else {
      resolve();
    }
  });
}

export function cortexFetchItemLookupForm() {
  return new Promise((resolve, reject) => {
    if (
      localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_itemLookupForm`
      ) === null
    ) {
      cortexFetch("/?zoom=lookups:itemlookupform")
        .then(res => {
          const onSuccess = data => data;
          return checkResponse(res, onSuccess);
        })
        .then(res => res.json())
        .then(res => {
          const itemForm = res._lookups[0]._itemlookupform[0].links.find(
            link => link.rel === "itemlookupaction"
          ).uri;
          localStorage.setItem(
            `${getConfig().config.cortexApi.scope}_itemLookupForm`,
            itemForm
          );
          resolve(res);
        })
        .catch(error => reject(error));
    } else {
      resolve();
    }
  });
}

export function cortexFetchBatchItemLookupForm() {
  return new Promise((resolve, reject) => {
    if (
      localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_batchLookupForm`
      ) === null
    ) {
      cortexFetch(
        "/?zoom=lookups:batchitemslookupform,lookups:batchofferslookupform"
      )
        .then(res => checkResponse(res))
        .then(res => {
          let batchForm = "";
          if (res._lookups[0]._batchofferslookupform) {
            batchForm = res._lookups[0]._batchofferslookupform[0].links.find(
              link => link.rel === "batchofferslookupaction"
            ).uri;
          } else {
            batchForm = res._lookups[0]._batchitemslookupform[0].links.find(
              link => link.rel === "batchitemslookupaction"
            ).uri;
          }
          localStorage.setItem(
            `${getConfig().config.cortexApi.scope}_batchLookupForm`,
            batchForm
          );
          resolve();
        })
        .catch(error => reject(error));
    } else {
      resolve();
    }
  });
}

export function cortexFetchPurchaseLookupForm() {
  return new Promise((resolve, reject) => {
    if (
      localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_purchaseLookupForm`
      ) === null
    ) {
      cortexFetch("/?zoom=lookups:purchaselookupform")
        .then(res => checkResponse(res))
        .then(res => {
          const purchaseForm = res._lookups[0]._purchaselookupform[0].links.find(
            link => link.rel === "purchaselookupaction"
          ).uri;
          localStorage.setItem(
            `${getConfig().config.cortexApi.scope}_purchaseLookupForm`,
            purchaseForm
          );
          resolve();
        })
        .catch(error => reject(error));
    } else {
      resolve();
    }
  });
}

export function navigationLookup(navigationLookupCode) {
  return new Promise((resolve, reject) => {
    if (
      navigationLookupCode.includes("/") &&
      navigationLookupCode.includes(
        getConfig().config.cortexApi.scope.toLowerCase()
      )
    ) {
      cortexFetch(
        `/${navigationLookupCode}?zoom=${navigationFormZoomArray.join()}`
      )
        .then(res => checkResponse(res))
        .then(res => resolve(res))
        .catch(error => reject(error));
    } else {
      cortexFetch(
        `${localStorage.getItem(
          `${getConfig().config.cortexApi.scope}_navigationLookupForm`
        )}?zoom=${navigationFormZoomArray.join()}&followlocation=true`,
        {
          method: "post",
          body: JSON.stringify({
            code: navigationLookupCode
          })
        }
      )
        .then(res => {
          const onError = err => {
            localStorage.removeItem(
              `${getConfig().config.cortexApi.scope}_navigationLookupForm`
            );
            throw err;
          };
          return checkResponse(res, undefined, onError);
        })
        .then(res => resolve(res))
        .catch(error => reject(error));
    }
  });
}

export function itemLookup(itemLookupCode, reload = true) {
  return new Promise((resolve, reject) => {
    cortexFetch(
      `${localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_itemLookupForm`
      )}?zoom=${itemFormZoomArray.join()}&followlocation=true`,
      {
        method: "post",
        body: JSON.stringify({
          code: itemLookupCode.toUpperCase()
        })
      }
    )
      .then(res => {
        const onError = err => {
          localStorage.removeItem(`${getConfig().config.cortexApi.scope}_$`);
          throw err;
        };
        const onSuccess = data => resolve(data.json());
        return checkResponse(res, onSuccess, onError);
      })
      .catch(error => reject(error));
  });
}

export function purchaseLookup(purchaseLookupCode) {
  return new Promise((resolve, reject) => {
    cortexFetch(
      `${localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_purchaseLookupForm`
      )}?zoom=${purchaseFormZoomArray.join()}&followlocation=true`,
      {
        method: "post",
        body: JSON.stringify({
          "purchase-number": purchaseLookupCode
        })
      }
    )
      .then(res => {
        const onError = err => {
          localStorage.removeItem(
            `${getConfig().config.cortexApi.scope}_purchaseLookupForm`
          );
          throw err;
        };
        return checkResponse(res, undefined, onError);
      })
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
}

export function searchLookup(searchKeyword) {
  return new Promise((resolve, reject) => {
    if (
      searchKeyword.includes("/") &&
      searchKeyword.includes(getConfig().config.cortexApi.scope.toLowerCase())
    ) {
      cortexFetch(`/${searchKeyword}?zoom=${searchFormZoomArray.join()}`)
        .then(res => {
          const onError = data => reject(data);
          return checkResponse(res, undefined, onError);
        })
        .then(res => resolve(res))
        .catch(error => reject(error));
    } else {
      cortexFetch("/?zoom=searches:keywordsearchform,searches:offersearchform")
        .then(res => checkResponse(res))
        .then(res => {
          let searchForm = "";
          if (res._searches[0]._offersearchform) {
            searchForm = res._searches[0]._offersearchform[0].links.find(
              link => link.rel === "offersearchaction"
            ).uri;
          } else {
            searchForm = res._searches[0]._keywordsearchform[0].links.find(
              link => link.rel === "itemkeywordsearchaction"
            ).uri;
          }
          cortexFetch(
            `${searchForm}?zoom=${searchFormZoomArray.join()}&followlocation=true`,
            {
              method: "post",
              body: JSON.stringify({
                keywords: searchKeyword
              })
            }
          )
            .then(resData => {
              const onError = data => reject(data);
              return checkResponse(resData, undefined, onError);
            })
            .then(resData => resolve(resData))
            .catch(error => reject(error));
        })
        .catch(error => reject(error));
    }
  });
}

export function batchLookup(batchLookupCodes) {
  return new Promise((resolve, reject) => {
    cortexFetch(
      `${localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_batchLookupForm`
      )}?zoom=${batchFormZoomArray.join()}&followlocation=true`,
      {
        method: "post",
        body: JSON.stringify({
          codes: batchLookupCodes
        })
      }
    )
      .then(res => {
        const onError = err => {
          localStorage.removeItem(
            `${getConfig().config.cortexApi.scope}_batchLookupForm`
          );
          throw err;
        };
        return checkResponse(res, undefined, onError);
      })
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
}
