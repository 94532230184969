import { cortexFetch, adminFetch, zoom } from "@zilker/store-components";
import Config from "../ep.config.json";
import { checkResponse } from "../utils/helpers";
import base32 from "../utils/base32/base32";
/**
 * @description EP cortex fetch for geting logged in user profile data.
 */
export const fetchProfile = () => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem(
        `${Config.cortexApi.scope}_oAuthToken`
      )
    }
  };
  if (Config.GDPR.enable) {
    options.headers[
      "X-Ep-Data-Policy-Segments"
    ] = `${Config.GDPR.dataPolicySegments}`;
  }

  return cortexFetch(`/?zoom=${zoom.zoomUserProfile.join()}`, options)
    .then(res => checkResponse(res))
    .catch(err => {
      throw err;
    });
};

/**
 * @description EP admin cortex fetch for geting logged in user account data.
 */
export const fetchAccount = () => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem(
        `${Config.cortexApi.scope}_oAuthToken`
      )
    }
  };
  if (Config.GDPR.enable) {
    options.headers[
      "X-Ep-Data-Policy-Segments"
    ] = `${Config.GDPR.dataPolicySegments}`;
  }

  return adminFetch(`/?zoom=${zoom.zoomAccountAssociates.join()}`)
    .then(res => checkResponse(res))
    .catch(err => {
      throw err;
    });
};

export const changeBranchOnCurrentOrder = (branchNumber, orderUri) => {
  const { scope } = Config.cortexApi;

  const orderId = orderUri.slice(orderUri.lastIndexOf(scope) + scope.length);

  let encodedBranchNumber = base32.encode(branchNumber);
  if (encodedBranchNumber.length < 26) {
    encodedBranchNumber += "=";
  }

  const fetchLink = `/orderdetails/${scope}${orderId}/warehouse/selector/warehouses/${scope}/${encodedBranchNumber}?followlocation&format=standardlinks,zoom.nodatalinks&`;

  return cortexFetch(fetchLink, {
    method: "post",
    body: JSON.stringify({})
  })
    .then(res => {
      if (res.status >= 400) {
        return res.json();
      }
      return res;
    })
    .then(res => {
      checkResponse(res);
    })
    .catch(err => {
      throw err;
    });
};

export const changeBranchAndVendorOnCurrentOrder = (branch, fetchLink) => {
  return cortexFetch(fetchLink, {
    method: "post",
    body: JSON.stringify({
      "update-mode": "BRANCH",
      "branch-number": branch.branchNumber,
      "branch-vendor": branch.distributor
    })
  })
    .then(res => {
      if (res.status >= 400) {
        return res.json();
      }
      return res;
    })
    .then(res => {
      const onSuccess = data => data;
      checkResponse(res, onSuccess);
    })
    .catch(err => {
      throw err;
    });
};

export const updateProsGroupQuantity = (items, quantity, cartId) => {
  const { scope } = Config.cortexApi;
  const fetchLink = `/updateitemsquantity/carts/${scope}/${cartId}/updateitemsquantity/form`;

  const guids = items.map(item =>
    item.self.uri.substring(item.self.uri.lastIndexOf("/"))
  );
  return cortexFetch(fetchLink, {
    method: "POST",
    body: JSON.stringify({
      items: guids.map(guid => ({
        guid: base32.decode(guid),
        quantity
      }))
    })
  });
};

/**
 * ## addToCart
 * @param addToCartLink string
 * @param payload {
                    "branch-number": number;
                    "branch-vendor": string;
                    "contract-number": number;
                    items: Array<{
                      code: string;
                      "contract-control-line-number": number;
                      quantity: number;
                    }>;
                    "job-name": string;
                    "job-number": number;
                    "po-number": string;
                    pricing: "string";
                  }
 *
 * @description Posts actual items to the cart
 */
export const addToCart = (
  addToCartLink: string,
  payload: {
    "branch-number"?: number;
    "branch-vendor"?: string;
    "contract-number"?: string;
    items: Array<{
      code: string;
      "contract-control-line-number"?: string;
      quantity: number;
    }>;
    "job-name"?: string;
    "job-number"?: string;
    "po-number"?: string;
    pricing?: string;
  }
): Promise<any> => {
  return cortexFetch(
    `${addToCartLink}?followlocation&format=standardlinks,zoom.nodatalinks&`,
    {
      method: "post",
      body: JSON.stringify(payload)
    }
  )
    .then(res => {
      const onSuccess = data => data;
      const onError = data => {
        if (!data.ok) {
          return data.json().then(json => {
            throw json;
          });
        }
        throw data;
      };
      return checkResponse(res, onSuccess, onError);
    })
    .catch(err => {
      throw err;
    });
};

/**
 * ## generateUpdatePayload
 * @param updates any - Object with keys equal to profile properties.
 * @param userProfile any
 *
 * @description Generates the payload for the PUT request that is sent to Cortex.
 * This request requires all the properties to be included, not only those that are
 * updated. If not included properties are reset to empty values.
 */
const generateUpdatePayload = (updates: any, userProfile: any): string => {
  const updatedProfile = {
    "business-number": userProfile.businessNumber,
    "cash-allowed": userProfile.cashAllowed,
    "charge-credit-code": userProfile.chargeCreditCode,
    "check-allowed": userProfile.checkAllowed,
    company: userProfile.company,
    "credit-card-allowed": userProfile.creditCardAllowed,
    "credit-line-allowed": userProfile.creditLineAllowed,
    cust_Attribute1: userProfile.custAttribute1,
    cust_Attribute2: userProfile.custAttribute2,
    "customer-number": userProfile.customerNumber,
    "date-of-birth": userProfile.dateOfBirth,
    "delivery-status-emails": userProfile.deliveryStatusEmails,
    "eligible-for-loyalty": userProfile.isEligibleForLoyalty,
    "family-name": userProfile.familyName,
    fax: userProfile.fax,
    gender: userProfile.gender,
    "given-name": userProfile.givenName,
    "home-branch": userProfile.initialHomeBranch,
    "home-branch-vendor": userProfile.initialHomeBranchVendor,
    "motili-home-branch": userProfile.initialMotiliHomeBranch,
    "motili-home-branch-vendor": userProfile.initialMotiliHomeBranchVendor,
    "html-email": userProfile.htmlEmail,
    "job-name-required": userProfile.jobNameRequired,
    "job-number-name-array": userProfile.jobsArray,
    "job-number-required": userProfile.jobNumberRequired,
    notification: userProfile.notification,
    "opt-in-sms-alert": userProfile.optInSmsAlert,
    "opt-in-order-status-email": userProfile.optInOrderStatusEmail,
    "opt-in-delivery-status-email": userProfile.optInDeliveryStatusEmail,
    "order-status-emails": userProfile.orderStatusEmails,
    phone: userProfile.phone,
    "po-number-required": userProfile.poNumberRequired,
    "preferred-currency": userProfile.preferedCurrency,
    "preferred-locale": userProfile.preferredLocale,
    "sms-phone-numbers": userProfile.smsPhoneNumbers,
    "sub-user-email": userProfile.subuserEmail,
    "sub-user-home-branch": userProfile.initialSubUserHomeBranch,
    "sub-user-home-branch-vendor": userProfile.initialSubUserHomeBranchVendor,
    "sub-user-motili-home-branch": userProfile.initialSubUserMotiliHomeBranch,
    "sub-user-motili-home-branch-vendor":
      userProfile.initialSubUserMotiliHomeBranchVendor,
    "sub-user-first-name": userProfile.subuserFirstName,
    "sub-user-last-name": userProfile.subuserLastName,
    "sub-user-phone": userProfile.subuserPhone,
    "tax-exemption-id": userProfile.taxЕxemptionId,
    "opt-out-marketing": userProfile.optOutMarketing,
    "sub-user-opt-out-marketing": userProfile.subUserOptOutMarketing,
    roles: userProfile.roles,
    "sub-user-roles": userProfile.subUserRoles
  };
  const result = {
    ...updatedProfile
  };

  Object.keys(updates).forEach((key: string) => {
    result[key] = updates[key];
  });

  return JSON.stringify(result);
};

export const updateDefaultProfile = (updates: any, userProfile: any) => {
  const { profileUri } = userProfile;
  const body = generateUpdatePayload(updates, userProfile);
  return cortexFetch(profileUri, {
    method: "put",
    body
  })
    .then(response => {
      const onSucces = data => data;
      const onError = data => {
        if (!data.ok) {
          return data.json().then(json => {
            throw json;
          });
        }
        throw data;
      };
      return checkResponse(response, onSucces, onError);
    })
    .catch(err => {
      throw err;
    });
};

export const resetPassword = (password: string): Promise<any> => {
  const url = `/passwordreset/${Config.cortexApi.scope}/form?followlocation&format=standardlinks,zoom.nodatalinks&`;
  return cortexFetch(url, {
    method: "post",
    body: JSON.stringify({ password })
  })
    .then(response => {
      const onSucces = data => data;
      const onError = data => {
        if (!data.ok) {
          return data.json().then(json => {
            throw json;
          });
        }
        throw data;
      };
      return checkResponse(response, onSucces, onError);
    })
    .catch(err => {
      throw err;
    });
};
