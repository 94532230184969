import React, { useState } from "react";
// eslint-disable-next-line import/prefer-default-export
export const ModalContext = () => {
  const [openLoginModal, handleOpenLoginModal] = useState(false);
  const [warningModal, setWarningModal] = useState({
    openModal: false,
    modalMessage: ""
  });
  const [showSsoModal, setShowSsoModal] = useState(false);
  const [passwordResetMessage, setPasswordResetMessage] = useState(false);

  return {
    openLoginModal,
    handleOpenLoginModal,
    warningModal,
    setWarningModal,
    showSsoModal,
    setShowSsoModal,
    passwordResetMessage,
    setPasswordResetMessage
  };
};
