/* eslint-disable camelcase */
import React from "react";
import intl from "react-intl-universal";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  SearchResultsMenu,
  BreadCrumbsComponent,
  productListViewed,
  productAdded,
  productListFiltered,
  productsSearched,
  page,
  logSearchTermResultPageThroughSegment,
  getConfig,
  Campaign,
  SearchResultsItems,
  SearchNoResults,
  SearchResultsAvailabilityCheckobox,
  SearchResultsScroppToTopButton
} from "@zilker/store-components";
import MessageContainer from "@zilker/store-components/src/MessageContainer/messagecontainer";
import {
  SingleProduct,
  formatFacets,
  filterFacetFields,
  generateSearchParams,
  clearItemFromSessionStorage
} from "../utils/searchUtils";
import { MainContext } from "../contexts/MainContext";
import { brSearch } from "../services/SearchService";
import {
  getProductPrice,
  ProductPriceRequest
} from "../utils/mappings/productDetails";
import {
  checkEntitlementSku,
  getAvailability,
  getAvailabilityDGA
} from "../services/connectServices";

import {
  checkTokensExpired,
  handleCustomException,
  pushToMaintenace,
  formatInventoryAvailability,
  generateSpecificErrorMessage,
  formatDGAInventory
} from "../utils/helpers";
import { addToCart } from "../services/EpServices";

import "./SearchResultsPage.less";

interface SearchResultsPageProps extends RouteComponentProps {
  match: any;
  history: any;
  searchType?: string;
}

interface SearchResultsPageState {
  rows: number;
  start: number;
  isLoading: boolean;
  docs: SingleProduct[];
  numFound: number;
  facetFields: { [name: string]: any };
  loadedAll: boolean;
  filtersChecked: { [name: string]: any };
  productPrices: any;
  productEntitlements: any;
  currentBranch: any;
  addCartButtonIds: any[];
  scrollToTop: boolean;
  productAvailability: any;
  scrolled: boolean;
  sortByAvailability: boolean;
  campaign: { htmlText: string };
  title: string;
  displayResults: boolean;
  avoidUpdate: boolean;
  currentJobNumber: any;
  inventoryError: string;
}

let Config;

class SearchResultsPage extends React.Component<
  SearchResultsPageProps,
  SearchResultsPageState
> {
  static contextType = MainContext;

  _isMounted = false;

  /* Initial number of products to fetch.
     This is used when component mounts and if the user navigates to different category/facet/search page.
  */
  initialRows = 105;

  constructor(props: Readonly<SearchResultsPageProps>) {
    super(props);
    Config = getConfig().config;
    this.state = {
      rows: 21,
      start: 0,
      isLoading: true,
      docs: [],
      numFound: 0,
      facetFields: {},
      loadedAll: false,
      filtersChecked: {},
      productPrices: null,
      productEntitlements: null,
      currentBranch: null,
      addCartButtonIds: [],
      scrollToTop: false,
      productAvailability: null,
      scrolled: false,
      sortByAvailability: false,
      campaign: null,
      title: "",
      displayResults: false,
      avoidUpdate: false,
      currentJobNumber: null,
      inventoryError: ""
    };

    this.onAddToCart = this.onAddToCart.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.onFacetChange = this.onFacetChange.bind(this);
    this.onFacetClicked = this.onFacetClicked.bind(this);
    this.fetchProductPrices = this.fetchProductPrices.bind(this);
    this.updateProductInfo = this.updateProductInfo.bind(this);
    this.validateEntitlement = this.validateEntitlement.bind(this);
    this.validateAvailability = this.validateAvailability.bind(this);
    this.renderProducts = this.renderProducts.bind(this);
    this.toggleScrollVisibility = this.toggleScrollVisibility.bind(this);
    this.onAvailabilityFilter = this.onAvailabilityFilter.bind(this);
    this.updateJobState = this.updateJobState.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let { loadedAll, docs } = this.state;
    const { rows } = this.state;

    const { location, searchType } = this.props;
    const { state, pathname } = location;
    const {
      match: {
        params: { keywords }
      },
      history
    } = this.props;
    const {
      auth: { isLoggedIn },
      branches: { branchesList, branchesInContext },
      navigation: { categoriesMap }
    } = this.context;
    const {
      cortexApi: { scope }
    } = Config;
    this.formatCategoryTitle();

    let searchMethod =
      state && state.searchType ? state.searchType : searchType;

    if (!searchMethod && pathname.includes("search")) {
      searchMethod = "keyword";
    }

    window.addEventListener("scroll", this.toggleScrollVisibility);

    window.addEventListener("beforeunload", clearItemFromSessionStorage);

    this.handleAnalytics(searchMethod);

    const {
      start,
      startParam,
      filtersChecked,
      sortByAvailabilityParam: sortByAvailability
    } = generateSearchParams(
      history,
      location,
      rows,
      this.initialRows,
      Config.showAvailableItemsFirst
    );
    if (branchesInContext) {
      this.setState({ avoidUpdate: true });
    }

    const filtersParam = filtersChecked && formatFacets(filtersChecked);
    // on component mount
    let prev = "";
    if (!(typeof state === "undefined")) {
      prev = state.prevUrl;
    }
    const prevUrl = prev;
    // PGL-364: Check whether the page title calculated in formatCategoryTitle is IAQ PRODUCTS
    let expectedPageTitle = "";
    if (this._isMounted && categoriesMap && categoriesMap.size && keywords) {
      expectedPageTitle =
        searchMethod === "keyword" && state && state.searchTerm
          ? state.searchTerm
          : keywords;
      categoriesMap.forEach((value, key) => {
        if (value.name === keywords) expectedPageTitle = key;
      });
    }
    const isIAQProducts = expectedPageTitle.toUpperCase() === "IAQ PRODUCTS";
    const includeBranchesList =
      isLoggedIn &&
      !(scope === "motili") &&
      searchMethod !== "keyword" &&
      !isIAQProducts;
    brSearch(
      searchMethod === "keyword" && state
        ? encodeURIComponent(state.searchTerm)
        : encodeURIComponent(keywords),
      searchMethod,
      startParam,
      this.initialRows,
      filtersParam,
      "",
      window.location.href,
      prevUrl,
      includeBranchesList ? branchesList : null
    )
      .then(async res => {
        const {
          data: {
            response,
            facet_counts: { facet_fields },
            campaign
          }
        } = res;
        const facetFields = filterFacetFields(facet_fields);
        docs = docs.concat(response.docs);

        loadedAll = response.docs.length >= response.numFound;

        if (Config.calculatePrice) this.fetchProductPrices(response.docs);

        if (response.docs.length && this._isMounted) {
          this.validateEntitlement(response.docs);
          if (scope !== "motili") {
            await this.validateAvailability(response.docs);
          }
        }

        if (this._isMounted) {
          this.setState({
            docs: response.docs,
            numFound: response.numFound,
            facetFields,
            start,
            loadedAll,
            filtersChecked: filtersChecked || {},
            sortByAvailability,
            campaign,
            displayResults: branchesInContext
          });
          if (!isLoggedIn || !docs.length) {
            this.setState({
              isLoading: false
            });
          }
          if (response.docs.length && scope === "motili") {
            await this.validateAvailability(response.docs);
          }
        }
      })
      .catch(e =>
        pushToMaintenace(history, {
          e,
          errIn: "brSearch => SearchResultsPage.tsx"
        })
      );
  }

  componentDidUpdate(prevProps) {
    const {
      currentBranch,
      scrolled,
      title,
      displayResults,
      isLoading,
      avoidUpdate,
      currentJobNumber
    } = this.state;
    let { docs, loadedAll } = this.state;
    const {
      cortexApi: { scope }
    } = Config;
    const {
      user: {
        userProfile: { customerNumber }
      },
      cart: {
        cartDetails: { defaultCart }
      },
      auth: { isLoggedIn },
      branches: { branchesList, branchesInContext }
    } = this.context;

    const {
      match: {
        path,
        params: { keywords }
      },
      location: { state, pathname },
      searchType,
      history
    } = this.props;
    const {
      match: {
        params: { keywords: prevKeywords }
      }
    } = prevProps;

    if (!title || (keywords && prevKeywords !== keywords)) {
      this.formatCategoryTitle();
    }

    let scrollSku;

    if (history.action === "POP") {
      scrollSku = sessionStorage.getItem("sku");
    } else if (state && state.sku) {
      scrollSku = state.sku;
    }

    const itemToScroll = document.getElementById(`${scrollSku}`);
    if (itemToScroll && !scrolled && this._isMounted) {
      itemToScroll.scrollIntoView();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        scrolled: true
      });
    }

    const findRegex = /\b(category|search)/g;
    const isProductPage = path.match(findRegex);

    if (isProductPage) {
      let searchMethod =
        state && state.searchType ? state.searchType : searchType;

      if (!searchMethod && pathname.includes("search")) {
        searchMethod = "keyword";
      }

      const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
      const selectedJobNumber = defaultCart ? defaultCart.jobNumber : null;

      if (
        JSON.stringify(currentBranch) !== JSON.stringify(selectedBranch) &&
        branchesList
      ) {
        this.updateProductInfo(docs, selectedBranch);
      }
      if (selectedJobNumber !== currentJobNumber) {
        this.updateJobState(docs, selectedJobNumber);
      }

      const start = 0;
      if (
        (searchMethod && prevKeywords !== keywords) ||
        (branchesInContext && !displayResults && !isLoading && !avoidUpdate)
      ) {
        this.handleAnalytics(searchMethod);
        clearItemFromSessionStorage();

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          isLoading: true,
          avoidUpdate: true
        });
        // on  component update
        let prev = "";
        if (!(typeof state === "undefined")) {
          prev = state.prevUrl;
        }
        const prevUrl = prev;
        // PGL-364
        const isIAQProducts = title.toUpperCase() === "IAQ PRODUCTS";
        const includeBranchesList =
          isLoggedIn &&
          !(scope === "motili") &&
          searchMethod !== "keyword" &&
          !isIAQProducts;
        brSearch(
          searchMethod === "keyword" && state
            ? encodeURIComponent(state.searchTerm)
            : encodeURIComponent(keywords),
          searchMethod,
          start,
          this.initialRows,
          "",
          "",
          window.location.href,
          prevUrl,
          includeBranchesList ? branchesList : null
        )
          .then(async res => {
            const {
              data: {
                response,
                facet_counts: { facet_fields },
                campaign
              }
            } = res;

            const facetFields = filterFacetFields(facet_fields);
            docs = docs.concat(response.docs);

            loadedAll = response.docs.length >= response.numFound;

            if (Config.calculatePrice) this.fetchProductPrices(response.docs);
            if (response.docs.length && this._isMounted) {
              this.validateEntitlement(response.docs);
              if (scope !== "motili") {
                await this.validateAvailability(response.docs);
              }
            }
            if (this._isMounted) {
              this.setState({
                isLoading: false,
                docs: response.docs,
                numFound: response.numFound,
                facetFields,
                start,
                loadedAll,
                filtersChecked: {},
                campaign,
                displayResults: branchesInContext
              });
            }
            if (response.docs.length && this._isMounted && scope === "motili") {
              await this.validateAvailability(response.docs);
            }
          })
          .catch(e =>
            pushToMaintenace(history, {
              e,
              errIn: "brSearch => SearchResultsPage.tsx"
            })
          );
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.toggleScrollVisibility);
    window.removeEventListener("beforeunload", clearItemFromSessionStorage);
  }

  handleAnalytics(searchMethod) {
    const {
      match: {
        params: { keywords }
      },
      location: { state }
    } = this.props;

    page();
    if (searchMethod === "category") {
      // sends informaion to Segment when user lands on PLP
      productListViewed(keywords);
    } else if (searchMethod === "keyword") {
      // sends informaion to Segment when user lands on Search Result Page
      productsSearched(state ? state.searchTerm : keywords);
    }
  }

  handleLogSearchTermResultPage(products) {
    const { productPrices: prices } = this.state;
    const {
      match: {
        params: { keywords }
      },
      location: { state, pathname },
      searchType
    } = this.props;

    let searchMethod =
      state && state.searchType ? state.searchType : searchType;

    if (!searchMethod && pathname.includes("search")) {
      searchMethod = "keyword";
    }

    const impressionProducts = products.map(item => {
      const priceDetails =
        prices && prices.find(product => product.sku === item.pid);
      return {
        id: item.pid,
        name: item.title,
        brand: item.brand,
        price: priceDetails ? priceDetails.total : 0
      };
    });

    if (searchMethod === "keyword") {
      logSearchTermResultPageThroughSegment(
        "search",
        state ? state.searchTerm : keywords,
        impressionProducts
      );
    } else if (searchMethod === "category") {
      logSearchTermResultPageThroughSegment(
        "categoryitems",
        keywords,
        impressionProducts
      );
    }
  }

  toggleScrollVisibility() {
    if (window.pageYOffset > 300 && this._isMounted) {
      this.setState({
        scrollToTop: true
      });
    } else if (this._isMounted) {
      this.setState({
        scrollToTop: false
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  async fetchProductPrices(products: SingleProduct[]) {
    if (products.length) {
      const {
        context: {
          auth: { isLoggedIn, logout },
          user,
          cart: {
            cartDetails: { defaultCart }
          },
          job: { persistedJobNumber }
        }
      } = this;
      const { history } = this.props;

      const { customerNumber } = user.userProfile;
      const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
      const jobNumber = defaultCart ? defaultCart.jobNumber : null;

      if (isLoggedIn && selectedBranch && customerNumber) {
        const skus = products.map((product: SingleProduct) => product.pid);

        const body: ProductPriceRequest = {
          customerNumber,
          branchNumber: selectedBranch.code,
          skus,
          jobNumber
        };

        if (defaultCart.jobNumber || persistedJobNumber) {
          body.jobNumber = defaultCart.jobNumber || persistedJobNumber;
        }

        await getProductPrice(
          body,
          res => {
            if (this._isMounted) {
              this.setState({
                productPrices: res.items
              });
            }
          },
          e => {
            const errorPath = "fetchProductPrices => SearchResultsPage.tsx";
            handleCustomException(e, logout, history, errorPath);

            if (this._isMounted) {
              this.setState({
                productPrices: []
              });
            }
          }
        );
        this.handleLogSearchTermResultPage(products);
      }
    }
  }

  async updateProductInfo(docs, selectedBranch) {
    if (this._isMounted) {
      this.setState({ currentBranch: selectedBranch });
    }
    if (Config.calculatePrice) this.fetchProductPrices(docs);
    if (docs.length && this._isMounted) {
      this.validateEntitlement(docs);
      await this.validateAvailability(docs);
    }
  }

  async validateEntitlement(docs: SingleProduct[]) {
    const {
      auth: { isLoggedIn, logout },
      user: {
        userProfile: { customerNumber }
      },
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;
    const { history } = this.props;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;

    if (isLoggedIn && Config.entitlementCheck && selectedBranch) {
      const skus = docs
        .map((product: SingleProduct) => encodeURIComponent(product.pid))
        .join("|");
      try {
        const { data } = await checkEntitlementSku(
          customerNumber,
          skus,
          selectedBranch.vendor
        );
        if (this._isMounted) {
          this.setState({
            productEntitlements: data
          });
        }
      } catch (error) {
        const errorPath =
          "validateEntitlementAndAvailability => checkEntitlementSku => SearchResultsPage.tsx";

        handleCustomException(error, logout, history, errorPath);
      }
    }
  }

  async validateAvailability(docs: SingleProduct[]) {
    const {
      auth: { isLoggedIn, logout },
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { findBranch, branchesList },
      user: {
        userProfile: { customerNumber }
      }
    } = this.context;

    const { history } = this.props;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    const clientId = defaultCart ? defaultCart.clientId : null;

    if (isLoggedIn && selectedBranch && branchesList) {
      const skus = docs.map((product: SingleProduct) => product.pid);
      const skusMotili = skus && skus.join("|");
      const {
        cortexApi: { scope }
      } = Config;

      try {
        if (scope === "motili") {
          const { latitude, longitude } = findBranch(selectedBranch.code);
          if (this._isMounted) {
            this.setState({
              isLoading: false
            });
          }
          const { data } = await getAvailability(
            customerNumber,
            selectedBranch.code,
            skusMotili,
            selectedBranch.vendor,
            latitude,
            longitude,
            true,
            clientId
          );
          if (this._isMounted) {
            this.setState({
              productAvailability: formatInventoryAvailability(data)
            });
          }
        } else {
          const branchNumber = selectedBranch.code;
          const { data } = await getAvailabilityDGA(
            customerNumber,
            skus,
            branchNumber
          );
          if (this._isMounted) {
            if (data.error || (data.result && !data.result.length)) {
              console.error("Inventory error");
              this.setState({
                isLoading: false,
                inventoryError: intl.get("inventory-error")
              });
            } else {
              this.setState({
                isLoading: false,
                productAvailability: formatDGAInventory(data),
                inventoryError: ""
              });
            }
          }
        }
      } catch (error) {
        const errorPath =
          "validateAvailability => getAvailability => SearchResultsPage.tsx";
        handleCustomException(error, logout, history, errorPath);
        if (this._isMounted) {
          console.error("Inventory error", error);
          this.setState({
            isLoading: false,
            productAvailability: [],
            inventoryError: intl.get("inventory-error")
          });
        }
      }
    }
  }

  updateJobState(docs, selectedJobNumber) {
    this.setState({ currentJobNumber: selectedJobNumber });
    if (Config.calculatePrice) this.fetchProductPrices(docs);
  }

  onFacetClicked(event) {
    const { checked, value, name } = event.target;
    const { filtersChecked } = this.state;
    const escValue = `"${value}"`;

    if (!filtersChecked[`${name}`]) {
      filtersChecked[`${name}`] = new Set();
    }

    if (checked) {
      filtersChecked[`${name}`].add(escValue);
    } else {
      filtersChecked[`${name}`].delete(escValue);
    }

    const filter = formatFacets(filtersChecked);
    this.onFacetChange(filter, filtersChecked);
  }

  onAddToCart(event: { target: { id: any } }, item?: any) {
    const { id: pid } = event.target;
    const {
      history,
      match: {
        params: { keywords }
      },
      location: { state, pathname },
      searchType
    } = this.props;
    const {
      cart: {
        cartDetails: {
          defaultCart: { addItemsToCart }
        },
        getCartDetails,
        setSuccesCartPopupMessage,
        setErrorCartPopupMessage
      },
      auth: { logout }
    } = this.context;
    const { addCartButtonIds } = this.state;

    let searchMethod =
      state && state.searchType ? state.searchType : searchType;

    if (!searchMethod && pathname.includes("search")) {
      searchMethod = "keyword";
    }

    const items = [
      {
        code: pid,
        quantity: 1
      }
    ];

    if (this._isMounted) {
      this.setState({
        addCartButtonIds: addCartButtonIds.concat(pid)
      });
    }
    addToCart(addItemsToCart.self.uri, { items })
      .then(res => {
        // Update Cart Count in Context.
        return getCartDetails();
      })
      .then(() => {
        if (item) {
          // sends information to Segment when user adds a product
          productAdded(
            item.title,
            item.pid,
            !item.productPrice || item.productPrice === intl.get("pending")
              ? 0
              : item.productPrice,
            item.brand,
            searchMethod === "keyword" && state ? state.searchTerm : keywords,
            1
          );
        }
      })
      .then(() => {
        setSuccesCartPopupMessage(1);
        if (this._isMounted) {
          this.setState(prevState => {
            const { addCartButtonIds: buttonIds } = prevState;
            return {
              isLoading: false,
              addCartButtonIds: buttonIds.filter(buttonId => {
                return buttonId !== pid;
              })
            };
          });
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => onAddToCart => SearchResultsPage.tsx"
            })
          );
        }
        if (this._isMounted) {
          this.setState(prevState => {
            const { addCartButtonIds: buttonIds } = prevState;
            return {
              isLoading: false,
              addCartButtonIds: buttonIds.filter(buttonId => {
                return buttonId !== pid;
              })
            };
          });
          setErrorCartPopupMessage(generateSpecificErrorMessage(e));
        }
      });
  }

  onLoadMore() {
    const { rows, filtersChecked, numFound } = this.state;
    const {
      match: {
        params: { keywords }
      },
      location: { state, pathname },
      searchType,
      history
    } = this.props;
    const {
      cortexApi: { scope }
    } = Config;

    const {
      auth: { isLoggedIn },
      branches: { branchesList }
    } = this.context;

    let searchMethod =
      state && state.searchType ? state.searchType : searchType;

    if (!searchMethod && pathname.includes("search")) {
      searchMethod = "keyword";
    }

    let { start, docs } = this.state;

    start += 1;

    const startIndex = start * rows;
    const filters = filtersChecked && formatFacets(filtersChecked);

    if (numFound > docs.length && startIndex >= docs.length) {
      // If fetched items length is less than number of items found,
      // and if the first next item to show (starting index) is ahead of items length, fetch new items.
      let prev = "";
      if (!(typeof state === "undefined")) {
        prev = state.prevUrl;
      }
      const includeBranchesList =
        isLoggedIn && !(scope === "motili") && searchMethod !== "keyword";

      const prevUrl = prev;
      brSearch(
        searchMethod === "keyword" && state
          ? encodeURIComponent(state.searchTerm)
          : encodeURIComponent(keywords),
        searchMethod,
        startIndex,
        rows,
        filters,
        "",
        window.location.href,
        prevUrl,
        includeBranchesList ? branchesList : null
      )
        .then(async res => {
          const {
            data: {
              response,
              facet_counts: { facet_fields },
              campaign
            }
          } = res;

          const facetFields = filterFacetFields(facet_fields);
          docs = docs.concat(response.docs);

          if (Config.calculatePrice) this.fetchProductPrices(docs);

          if (docs.length && this._isMounted) {
            this.validateEntitlement(docs);
            if (scope !== "motili") {
              await this.validateAvailability(docs);
            }
          }

          if (this._isMounted) {
            this.setState(prevState => {
              const updatedState = {
                ...prevState,
                isLoading: false,
                docs,
                numFound: response.numFound,
                facetFields,
                start,
                campaign
              };
              if (docs.length >= response.numFound) {
                updatedState.loadedAll = true;
              }

              return updatedState;
            });
            if (docs.length && scope !== "motili") {
              await this.validateAvailability(docs);
            }
          }
        })
        .catch(e =>
          pushToMaintenace(history, {
            e,
            errIn: "onLoadMore => SearchResultsPage.tsx"
          })
        );
    } else if (docs.length >= numFound && this._isMounted) {
      this.setState({ loadedAll: true, start });
    } else if (this._isMounted) {
      this.setState({ start });
    }
  }

  onAvailabilityFilter(event): void {
    const { checked } = event.target;
    if (this._isMounted) {
      this.setState({ sortByAvailability: checked });
    }
  }

  onFacetChange(filter: string, filtersChecked: { [name: string]: any }) {
    const {
      match: {
        params: { keywords }
      },
      location: { state, pathname },
      searchType,
      history
    } = this.props;

    const {
      cortexApi: { scope }
    } = Config;

    const {
      auth: { isLoggedIn },
      branches: { branchesList }
    } = this.context;

    let searchMethod =
      state && state.searchType ? state.searchType : searchType;

    if (!searchMethod && pathname.includes("search")) {
      searchMethod = "keyword";
    }

    const start = 0;

    const filters = [];

    Object.entries(filtersChecked).forEach(filterChecked => {
      const type = filterChecked[0];
      filterChecked[1].forEach(value => {
        const f = {
          type,
          value
        };
        filters.push(f);
      });
    });

    // sends applied filters to Segment
    productListFiltered(keywords, filters);
    let prev = "";
    if (!(typeof state === "undefined")) {
      prev = state.prevUrl;
    }
    const prevUrl = prev;
    const includeBranchesList =
      isLoggedIn && !(scope === "motili") && searchMethod !== "keyword";
    brSearch(
      searchMethod === "keyword" && state
        ? encodeURIComponent(state.searchTerm)
        : encodeURIComponent(keywords),
      searchMethod,
      start,
      this.initialRows,
      filter,
      "",
      window.location.href,
      prevUrl,
      includeBranchesList ? branchesList : null
    )
      .then(async res => {
        let { loadedAll } = this.state;
        const {
          data: {
            response,
            facet_counts: { facet_fields },
            campaign
          }
        } = res;

        const fields = filterFacetFields(facet_fields);

        loadedAll = response.docs.length >= response.numFound;

        if (Config.calculatePrice) this.fetchProductPrices(response.docs);

        if (response.docs.length && this._isMounted) {
          this.validateEntitlement(response.docs);
          if (scope !== "motili") {
            await this.validateAvailability(response.docs);
          }
        }

        if (this._isMounted) {
          this.setState({
            isLoading: false,
            docs: response.docs,
            numFound: response.numFound,
            facetFields: { ...fields },
            start,
            loadedAll,
            filtersChecked,
            campaign
          });
          if (response.docs.length && scope !== "motili") {
            await this.validateAvailability(response.docs);
          }
        }
      })
      .catch(e =>
        pushToMaintenace(history, {
          e,
          errIn: "onFacetChange => SearchResultsPage.tsx"
        })
      );
  }

  // eslint-disable-next-line class-methods-use-this
  useFullImage(item) {
    const updatedItem = { ...item };
    if (item.full_image) {
      updatedItem.thumb_image = item.full_image;
    }

    return updatedItem;
  }

  formatCategoryTitle() {
    const {
      match: {
        params: { keywords }
      },
      location,
      searchType
    } = this.props;
    const {
      navigation: { categoriesMap }
    } = this.context;
    const { state, pathname } = location;

    let searchMethod =
      state && state.searchType ? state.searchType : searchType;

    if (!searchMethod && pathname.includes("search")) {
      searchMethod = "keyword";
    }

    if (this._isMounted && categoriesMap && categoriesMap.size && keywords) {
      let title =
        searchMethod === "keyword" && state && state.searchTerm
          ? state.searchTerm
          : keywords;
      categoriesMap.forEach((value, key) => {
        if (value.name === keywords) title = key;
      });

      this.setState({
        title
      });
    }
  }

  renderProducts(searchMethod: string) {
    const {
      productEntitlements,
      productAvailability,
      docs,
      productPrices,
      addCartButtonIds,
      start,
      rows,
      filtersChecked,
      sortByAvailability,
      numFound,
      displayResults
    } = this.state;
    const {
      auth: { isLoggedIn }
    } = this.context;
    const {
      cortexApi: { scope }
    } = Config;

    const displaySearchResults =
      isLoggedIn && !(scope === "motili") && searchMethod !== "keyword"
        ? displayResults
        : true;

    return displaySearchResults ? (
      <SearchResultsItems
        productEntitlements={productEntitlements}
        productAvailability={productAvailability}
        products={docs}
        productPrices={productPrices}
        addCartButtonIds={addCartButtonIds}
        start={start}
        rows={rows}
        filtersChecked={filtersChecked}
        sortByAvailability={sortByAvailability}
        numFound={numFound}
        onAddToCart={this.onAddToCart}
        onLoadMore={this.onLoadMore}
      />
    ) : (
      <div className="loader-container">
        <div className="loader" />
      </div>
    );
  }

  render() {
    const {
      docs,
      numFound,
      isLoading,
      facetFields,
      filtersChecked,
      scrollToTop,
      sortByAvailability,
      start,
      rows,
      campaign,
      title,
      inventoryError
    } = this.state;
    const {
      auth: { isLoggedIn }
    } = this.context;

    const {
      match: {
        params: { keywords }
      },
      location: { state, pathname },
      searchType
    } = this.props;

    let searchMethod =
      state && state.searchType ? state.searchType : searchType;

    if (!searchMethod && pathname.includes("search")) {
      searchMethod = "keyword";
    }

    const hasResults = !!docs.length;

    const itemsLoaded =
      (start + 1) * rows >= numFound ? docs.length : (start + 1) * rows;

    const isCategoryPage = searchType && searchType === "category";
    const breadCrumbsMap = isCategoryPage
      ? [
          { param: "home", name: "Home" },
          {
            param: "nocrumb",
            name: title,
            start: state && state.start
          }
        ]
      : [
          { param: "home", name: "Home" },
          {
            param: "nocrumb",
            name: intl.get("search-results-crumbs", {
              keywords: state ? state.searchTerm : keywords
            })
          }
        ];

    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;

    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;

    return (
      <>
        <div className="search-results-page">
          <div className="search-results-wrapper container">
            {inventoryError && (
              <MessageContainer
                message={{
                  type: "basic",
                  debugMessages: inventoryError
                }}
                closeContainerHandler={null}
              />
            )}
            <BreadCrumbsComponent breadCrumbsMap={breadCrumbsMap} />

            <div className="top-right">
              {campaign && <Campaign content={campaign.htmlText} />}
              <div className="search-results-title">
                <h1 className="bullet">
                  {isCategoryPage
                    ? intl.get("category-page-title", {
                        keywords: title
                      })
                    : intl.get("search-results-title", {
                        keywords: title
                      })}
                </h1>
              </div>
              {Config.sortByAvailability && isLoggedIn ? (
                <SearchResultsAvailabilityCheckobox
                  checked={sortByAvailability}
                  handler={this.onAvailabilityFilter}
                />
              ) : null}
              {numFound ? (
                <div className="label total-results">
                  {intl.get("search-results-count", {
                    loaded: itemsLoaded,
                    found: numFound
                  })}
                </div>
              ) : null}
            </div>
            <div>
              {!isLoading &&
                (hasResults && (
                  <>
                    <SearchResultsMenu
                      facets={facetFields}
                      filtersChecked={filtersChecked}
                      onFacetClicked={this.onFacetClicked}
                      selectedBranch={selectedBranch}
                    />
                    {scrollToTop && <SearchResultsScroppToTopButton />}
                    {this.renderProducts(searchMethod)}
                  </>
                ))}
              {isLoading && (
                <div className="loader-container">
                  <div className="loader" />
                </div>
              )}
            </div>
          </div>
        </div>
        {!isLoading && !hasResults && <SearchNoResults />}
      </>
    );
  }
}

export default withRouter(SearchResultsPage);
