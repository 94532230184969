/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { Link, Redirect, NavLink } from "react-router-dom";
import intl from "react-intl-universal";
import _ from "lodash";

import { RenderCmsComponent } from "bloomreach-experience-react-sdk";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import HeaderMainMenu from "../Bloomreach/components/HeaderMainMenu/header-main-menu";

import { getConfig, IEpConfig } from "../utils/ConfigProvider";

import "./appheadernavigation.main.less";

let Config: IEpConfig | any = {};

interface AppHeaderNavigationMainProps {
  isOfflineCheck: (...args: any[]) => any;
  isOffline?: boolean;
  isMobileView: boolean;
  appHeaderNavigationLinks?: { [key: string]: any };
  cortexNavigations: any;
}

interface AppHeaderNavigationMainState {
  navigations: any;
  originalMinimizedNav: any;
  error: string;
  customerNumber: string;
  branchNumber: string;
  isLoggedIn: any;
  cartId: string;
  tools: any;
  warranty: any;
}

interface NavItem {
  name: string;
  path: string;
  internal: boolean;
}

class AppHeaderNavigationMain extends React.Component<
  AppHeaderNavigationMainProps,
  AppHeaderNavigationMainState
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    this.state = {
      navigations: {},
      error: "",
      /* eslint-disable react/no-unused-state */
      originalMinimizedNav: {},
      customerNumber: null,
      branchNumber: null,
      isLoggedIn: false,
      cartId: null,
      tools: [],
      warranty: []
    };
  }

  componentDidMount() {
    const { cortexNavigations } = this.props;
    const { isOffline, isOfflineCheck } = this.props;

    const {
      auth: { isLoggedIn },
      user: { userProfile },
      cart: {
        cartDetails: { defaultCart }
      },
      navigation: { setNavigation }
    } = this.context;

    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    const cartId = defaultCart ? defaultCart.cartId : null;
    const customerNumber = userProfile ? userProfile.customerNumber : null;
    const branchNumber = selectedBranch ? selectedBranch.code : selectedBranch;

    if (!navigator.onLine && !isOffline && isOffline !== undefined) {
      isOfflineCheck(true);
    } else if (navigator.onLine && isOffline) {
      isOfflineCheck(false);
    }

    const navigations = this.getDropDownNavigationState(cortexNavigations);
    setNavigation(navigations);
    this.setState({
      navigations,
      originalMinimizedNav: JSON.parse(JSON.stringify(navigations)),
      customerNumber,
      branchNumber,
      isLoggedIn,
      cartId
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOffline, isOfflineCheck } = this.props;

    const {
      auth: { isLoggedIn },
      user: { userProfile },
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;

    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    let selectedBranchNumber = selectedBranch;
    if (selectedBranch) {
      selectedBranchNumber = selectedBranch.code;
    }
    const cartId = defaultCart ? defaultCart.cartId : null;
    const customerNumber = userProfile ? userProfile.customerNumber : null;

    const {
      customerNumber: prevCustomerNumber,
      isLoggedIn: prevLoggedIn,
      branchNumber,
      cartId: prevCartId
    } = prevState;

    if (
      prevLoggedIn !== isLoggedIn ||
      (prevCustomerNumber !== customerNumber && customerNumber) ||
      (branchNumber !== selectedBranchNumber && selectedBranchNumber) ||
      (prevCartId !== cartId && cartId)
    ) {
      const tools = this.formatHVACTools(
        customerNumber,
        selectedBranchNumber,
        cartId
      );
      const warranty = this.formatWarrantyLinks();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        branchNumber: selectedBranch ? selectedBranch.code : selectedBranch,
        customerNumber,
        isLoggedIn,
        cartId,
        tools,
        warranty
      });
    }

    if (!navigator.onLine && !isOffline && isOffline !== undefined) {
      isOfflineCheck(true);
    } else if (navigator.onLine && isOffline) {
      isOfflineCheck(false);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  formatHVACTools(
    customerNumber: string,
    branchNumber: string,
    cartId: string
  ): Array<NavItem> {
    const {
      ahriURL,
      cortexApi,
      brXM,
      infoFinderPlusURL,
      partnerLinkURL,
      partsFinderPageDisplay,
      documentsLibraryPageDisplay
    } = Config;

    const token = localStorage.getItem("DK_oAuthToken") || "";
    let tokenValue = token;
    if (token) {
      tokenValue = token.replace("Bearer ", "");
    }

    const ahriLink = `${ahriURL}?brand=${cortexApi.scope}&customerNumber=${customerNumber}&branchNumber=${branchNumber}&JWT=${tokenValue}&cartId=${cartId}&callbackURL=${brXM.origin}mycart`;
    const infoFinderPlusLink = `${infoFinderPlusURL}?JWT=${tokenValue}&callbackURL=${brXM.origin}`;

    const hvacTools: Array<NavItem> = [];

    const partsFinder: NavItem = {
      name: intl.get("parts-finder"),
      path: "/partsFinder",
      internal: true
    };
    if (partsFinderPageDisplay) {
      hvacTools.push(partsFinder);
    }

    const documentsLibrary: NavItem = {
      name: intl.get("documents-library"),
      path: "/documentsLibrary",
      internal: true
    };
    if (documentsLibraryPageDisplay) {
      hvacTools.push(documentsLibrary);
    }

    const ahriTool: NavItem = {
      name: intl.get("ahri-tool"),
      path: ahriLink,
      internal: false
    };
    hvacTools.push(ahriTool);

    const infoFinderPlus: NavItem = {
      name: intl.get("info-finder-plus"),
      path: infoFinderPlusLink,
      internal: false
    };
    hvacTools.push(infoFinderPlus);

    const partnerLink: NavItem = {
      name: intl.get("partner-link"),
      path: partnerLinkURL,
      internal: false
    };
    hvacTools.push(partnerLink);

    return hvacTools;
  }

  formatWarrantyLinks = (): Array<NavItem> => {
    const { warrantyExpressURL } = Config;
    const warrantyLookup: NavItem = {
      name: intl.get("warranty-lookup"),
      path: "/warrantyLookup",
      internal: true
    };

    const warrantyExpress: NavItem = {
      name: intl.get("warranty-express"),
      path: warrantyExpressURL,
      internal: false
    };

    return [warrantyLookup, warrantyExpress];
  };

  getDropDownNavigationState(navigations) {
    const dropDownNavigation = {};

    navigations.forEach(category => {
      const displayName = category["display-name"];
      const { name } = category;
      const show = false;

      let children;

      if (category._child) {
        children = this.getDropDownNavigationState(category._child);
      }

      if (!category.hidden) {
        dropDownNavigation[displayName] = {
          show,
          name,
          ...children
        };
      }
    });

    return dropDownNavigation;
  }

  static getListOfPathsToAlterShow(path) {
    const loPathsToChange = [];
    let currentPathToAddToArray = path;

    do {
      const indexOfLastDot = currentPathToAddToArray.lastIndexOf(".");
      currentPathToAddToArray = currentPathToAddToArray.substring(
        0,
        indexOfLastDot
      );

      loPathsToChange.push(currentPathToAddToArray);
    } while (currentPathToAddToArray.indexOf(".") > -1);

    return loPathsToChange;
  }

  toggleShowForCategory(category, path) {
    const { isMobileView } = this.props;

    if (isMobileView) {
      this.setState(state => {
        const { navigations, originalMinimizedNav } = state;

        const returnNav = JSON.parse(JSON.stringify(originalMinimizedNav));

        const loPathsToChange = AppHeaderNavigationMain.getListOfPathsToAlterShow(
          path
        );

        loPathsToChange.forEach(pathToChange => {
          _.set(returnNav, `${pathToChange}.show`, true);
        });

        const lowestCategoryInPathVal = !_.get(navigations, `${path}.show`, "");
        _.set(returnNav, `${path}.show`, lowestCategoryInPathVal);

        return { navigations: returnNav };
      });
    }
  }

  renderSubCategoriesWithChildren(
    subcategoryChildKeyName,
    nestedChildObj,
    path,
    isLeftDropDownStyling,
    categoryLevel
  ) {
    const { navigations } = this.state;
    const currentCategoryLevel = categoryLevel + 1;
    return (
      <li
        className={isLeftDropDownStyling ? "left-drop-down" : "right-drop-down"}
        key={`${path}`}
      >
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        {/* eslint-disable jsx-a11y/click-events-have-key-events */}
        <Link
          className={`dropdown-item dropdown-toggle ${
            _.get(navigations, `${path}.show`, "") ? "rotateCaret" : ""
          }`}
          to={{
            pathname: `/category/${nestedChildObj.name}`,
            state: {
              navClick: true,
              name: subcategoryChildKeyName,
              prevUrl: window.location.href
            }
          }}
          id={`"navbarDropdownMenuLink_" + ${nestedChildObj.name}`}
          onClick={() =>
            this.toggleShowForCategory(subcategoryChildKeyName, `${path}`)
          }
          aria-haspopup="true"
          aria-expanded="false"
        >
          {subcategoryChildKeyName}
        </Link>
        <ul
          className={`dropdown-menu nav-dropdown-menu ${
            nestedChildObj.show ? "show" : ""
          } nestedCategory${currentCategoryLevel}`}
          aria-labelledby="navbarDropdownMenuLink"
        >
          {this.renderSubCategories(
            subcategoryChildKeyName,
            path,
            !isLeftDropDownStyling,
            currentCategoryLevel
          )}
        </ul>
      </li>
    );
  }

  renderSubCategoriesWithNoChildren(
    subcategoryChildKeyName,
    nestedChildObj,
    path
  ) {
    const { appHeaderNavigationLinks } = this.props;
    if (
      subcategoryChildKeyName !== "show" &&
      subcategoryChildKeyName !== "name"
    ) {
      return (
        <li key={`${path}`}>
          <Link
            className={`dropdown-item ${nestedChildObj.show ? "show" : ""}`}
            id={`header_navbar_sub_category_button_${nestedChildObj.name}`}
            title={subcategoryChildKeyName}
            to={{
              pathname:
                appHeaderNavigationLinks.categories + nestedChildObj.name,
              state: {
                navClick: true,
                name: subcategoryChildKeyName,
                prevUrl: window.location.href
              }
            }}
          >
            <div
              data-toggle="collapse"
              data-target=".collapsable-container"
              className=""
              aria-expanded="true"
            >
              {subcategoryChildKeyName}
            </div>
          </Link>
        </li>
      );
    }
    return null;
  }

  renderSubCategories(category, path, isLeftDropDownStyling, categoryLevel) {
    const { navigations } = this.state;
    const childObj = _.get(navigations, path, "");
    const subCategoryChildArray = Object.keys(childObj);

    return subCategoryChildArray.map(subcategoryChildKeyName => {
      const nestedChildObj = childObj[subcategoryChildKeyName];
      const currentPath = `${path}.${subcategoryChildKeyName}`;
      if (
        subcategoryChildKeyName !== "show" &&
        subcategoryChildKeyName !== "name"
      ) {
        if (Object.keys(nestedChildObj).length > 2) {
          return this.renderSubCategoriesWithChildren(
            subcategoryChildKeyName,
            nestedChildObj,
            currentPath,
            isLeftDropDownStyling,
            categoryLevel
          );
        }
        return this.renderSubCategoriesWithNoChildren(
          subcategoryChildKeyName,
          nestedChildObj,
          currentPath
        );
      }
      return null;
    });
  }

  renderCategoriesWithNoChildren(categoryKey, path) {
    const { navigations } = this.state;
    const { appHeaderNavigationLinks } = this.props;

    return (
      <li
        className="nav-item"
        key={`${path}`}
        data-name={categoryKey}
        data-el-container="category-nav-item-container"
      >
        <Link
          className="nav-link"
          to={{
            pathname:
              appHeaderNavigationLinks.categories +
              navigations[categoryKey].name,
            state: {
              navClick: true,
              prevUrl: window.location.href
            }
          }}
          id="navbarMenuLink"
          aria-haspopup="true"
          aria-expanded="false"
          data-target="#"
        >
          <div
            data-toggle="collapse"
            data-target=".collapsable-container"
            className=""
            aria-expanded="true"
          >
            {categoryKey}
          </div>
        </Link>
      </li>
    );
  }

  renderCategoriesWithChildren(
    category,
    path,
    isLeftDropDownStyling,
    categoryLevel
  ) {
    const { navigations } = this.state;
    return (
      <li
        className="nav-item"
        key={`${path}`}
        data-name={category}
        data-el-container="category-nav-item-container"
        data-toggle="collapse"
        data-target=".collapsable-container"
        aria-expanded="true"
      >
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        {/* eslint-disable jsx-a11y/click-events-have-key-events */}
        <Link
          className={`nav-link dropdown-toggle ${
            _.get(navigations, `${path}.show`, "") ? "rotateCaret" : ""
          }`}
          to={{
            pathname: `/category/${navigations[category].name}`,
            state: {
              navClick: true,
              prevUrl: window.location.href
            }
          }}
          onClick={() => this.toggleShowForCategory(category, path)}
          id={`"navbarDropdownMenuLink_" + ${navigations[category].name}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {category}
        </Link>
        <div className="nav-dropdown-container">
          <ul
            className={`dropdown-menu nav-dropdown-menu ${
              _.get(navigations, `${path}.show`, "") ? "show" : ""
            } nestedCategory${categoryLevel}`}
            aria-labelledby="navbarDropdownMenuLink"
          >
            {this.renderSubCategories(
              category,
              path,
              isLeftDropDownStyling,
              categoryLevel
            )}
          </ul>
        </div>
      </li>
    );
  }

  renderCategories() {
    const { navigations } = this.state;
    const firstLevelKeys = Object.keys(navigations);

    return firstLevelKeys
      .filter(categoryKey => !!categoryKey)
      .map(category => {
        const categoryObj = navigations[category];
        const path = category;
        if (Object.keys(categoryObj).length > 2) {
          const categoryLevel = 0;
          return this.renderCategoriesWithChildren(
            category,
            path,
            true,
            categoryLevel
          );
        }
        return this.renderCategoriesWithNoChildren(category, path);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  renderMenuItemWithSubItems(name: string, menu: Array<NavItem>) {
    return (
      <li
        className="nav-item"
        key={name}
        data-name={name}
        data-el-container="category-nav-item-container"
        data-toggle="collapse"
        data-target=".collapsable-container"
        aria-expanded="true"
      >
        <span
          className="nav-link dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {name}
        </span>
        <div className="nav-dropdown-container sub-items-menu">
          <ul
            className="dropdown-menu nav-dropdown-menu sub-items-menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            {menu.map(
              menuItem =>
                menuItem &&
                menuItem.path && (
                  <li key={menuItem.name}>
                    {menuItem.internal ? (
                      <Link
                        to={{
                          pathname: menuItem.path,
                          state: { prevUrl: window.location.href }
                        }}
                        className="dropdown-item"
                      >
                        {" "}
                        <div
                          data-toggle="collapse"
                          data-target=".collapsable-container"
                          className=""
                          aria-expanded="true"
                        >
                          {menuItem.name}
                        </div>
                      </Link>
                    ) : (
                      <a
                        href={menuItem.path}
                        className="dropdown-item"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          data-toggle="collapse"
                          data-target=".collapsable-container"
                          className=""
                          aria-expanded="true"
                        >
                          {menuItem.name}
                        </div>
                      </a>
                    )}
                  </li>
                )
            )}
          </ul>
        </div>
      </li>
    );
  }

  render() {
    const { error, isLoggedIn, tools, warranty } = this.state;
    const { isMobileView } = this.props;
    const {
      user: {
        userProfile: { isEligibleForLoyalty }
      }
    } = this.context;

    const {
      homeOwnersURL,
      productRegistrationURL,
      systemBuilderPageDisplay,
      partsFinderPageDisplay,
      documentsLibraryPageDisplay
    } = Config;

    if (error) {
      return (
        <Redirect
          to={{
            pathname: "/maintenance",
            state: {
              error: { e: { message: error }, errIn: "AppHeaderNavigationMain" }
            }
          }}
        />
      );
    }

    return (
      <div
        className={`app-header-navigation-component ${
          isMobileView ? "mobile-view" : ""
        }`}
      >
        <nav className="navbar navbar-expand hover-menu">
          <div className="container">
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                {this.renderCategories()}
                {systemBuilderPageDisplay && isLoggedIn && (
                  <li
                    className="nav-item"
                    data-name={intl.get("system-builder")}
                    data-el-container="category-nav-item-container"
                    data-toggle="collapse"
                    data-target=".collapsable-container"
                    aria-expanded="true"
                  >
                    <NavLink className="nav-link" to="/systemBuilder">
                      {intl.get("system-builder")}
                    </NavLink>
                  </li>
                )}

                {isLoggedIn && tools
                  ? this.renderMenuItemWithSubItems(
                      intl.get("hvac-tools"),
                      tools
                    )
                  : null}
                {!isLoggedIn && partsFinderPageDisplay ? (
                  <li
                    className="nav-item"
                    data-name={intl.get("parts-finder")}
                    data-el-container="category-nav-item-container"
                    data-toggle="collapse"
                    data-target=".collapsable-container"
                    aria-expanded="true"
                  >
                    <NavLink
                      className="nav-link"
                      to={{
                        pathname: "/partsFinder",
                        state: { prevUrl: window.location.href }
                      }}
                    >
                      {intl.get("parts-finder")}
                    </NavLink>
                  </li>
                ) : null}
                {!isLoggedIn && documentsLibraryPageDisplay ? (
                  <li
                    className="nav-item"
                    data-name={intl.get("documents-library")}
                    data-el-container="category-nav-item-container"
                    data-toggle="collapse"
                    data-target=".collapsable-container"
                    aria-expanded="true"
                  >
                    <NavLink className="nav-link" to="/documentsLibrary">
                      {intl.get("documents-library")}
                    </NavLink>
                  </li>
                ) : null}
                {isLoggedIn &&
                  warranty &&
                  this.renderMenuItemWithSubItems(
                    intl.get("warranty"),
                    warranty
                  )}

                {/* <RenderCmsComponent
                  path="menu"
                  renderComponent={HeaderMainMenu}
                /> */}
                {productRegistrationURL ? (
                  <li
                    className="nav-item"
                    data-name="Product Registration"
                    data-el-container="category-nav-item-container"
                    data-toggle="collapse"
                    data-target=".collapsable-container"
                    aria-expanded="true"
                  >
                    {isLoggedIn ? (
                      <a
                        className="nav-link"
                        href={productRegistrationURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.get("product-registration")}
                      </a>
                    ) : null}
                  </li>
                ) : null}
                {isLoggedIn ? (
                  <li
                    className="nav-item"
                    data-name="Branch Location"
                    ata-el-container="category-nav-item-container"
                    data-toggle="collapse"
                    data-target=".collapsable-container"
                    aria-expanded="true"
                  >
                    <NavLink
                      className="nav-link"
                      to="/myAccount/branchSettings"
                    >
                      {intl.get("branch-location")}
                    </NavLink>
                  </li>
                ) : null}
                {Config.showDealerFirstRewardLink &&
                isEligibleForLoyalty === "true" ? (
                  <li
                    className="nav-item"
                    data-name="Reward"
                    data-el-container="category-nav-item-container"
                    data-toggle="collapse"
                    data-target=".collapsable-container"
                    aria-expanded="true"
                  >
                    {isLoggedIn ? (
                      <a
                        className="nav-link"
                        href="http://www.dealerfirstrewards.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.get("rewards")}
                      </a>
                    ) : null}
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default AppHeaderNavigationMain;
