import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  CmsEditButton,
  getNestedObject
} from "bloomreach-experience-react-sdk";
import CreateLink from "../../Bloomreach/components/BRlink/brlink";
import { getConfig } from "../../utils/ConfigProvider";
import "../appfooter.main.less";

let intl = { get: str => str };

const AppFooterMenu = props => {
  const { configuration, preview } = props;
  const [columnVisible, setColumnVisibility] = useState(true);
  ({ intl } = getConfig());

  if (!configuration) {
    return null;
  }

  const menuConfiguration = getNestedObject(configuration, ["models", "menu"]);
  const editButton = preview ? (
    <CmsEditButton configuration={menuConfiguration} preview={preview} />
  ) : null;

  function createLinks() {
    const scrollTop = true;
    return configuration.models.menu.siteMenuItems.map(menuItem => (
      <li key={`${menuItem.id}_${Math.random()}`}>
        <div className="container">
          <CreateLink
            configuration={menuItem}
            scrollTop={scrollTop}
            key={menuItem.name}
          />
        </div>
      </li>
    ));
  }
  return (
    <>
      <div className="title-container d-none d-md-block">
        {intl.get("quick-links-title")}
      </div>
      <div className="menu-container d-none d-md-block">
        {editButton && editButton}
        <ul>{createLinks()}</ul>
      </div>
      <div className="title-container-mobile d-flex d-md-none align-items-center">
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-between">
              <span>{intl.get("quick-links-title")}</span>
              <button
                type="button"
                aria-label="show all links"
                className="expand-menu"
                onClick={() => setColumnVisibility(!columnVisible)}
              >
                {columnVisible ? (
                  <i className="icon-minus" />
                ) : (
                  <i className="icon-plus" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {columnVisible ? (
        <div className="menu-container-mobile d-block d-md-none">
          {editButton && editButton}
          <ul>{createLinks()}</ul>
        </div>
      ) : null}
    </>
  );
};

export default AppFooterMenu;
