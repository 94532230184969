import React, { FC, useState, useContext } from "react";
import intl from "react-intl-universal";
import PartsFinderTableItem from "../PartsFinderTableItem/PartsFinderTableItem";
import {
  PartDetails,
  PriceDetails,
  EntitlementDetails,
  PartsFinderSearchParams
} from "../../../app/src/containers/PartsFinderPage";
import {
  checkTokensExpired,
  pushToMaintenace,
  generateSpecificErrorMessage,
  InventoryAvailabilityInterface,
  formatGeneralDGAAvailabilityLabel
} from "../../../app/src/utils/helpers";
import { addToCart } from "../../../app/src/services/EpServices";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { productAdded } from "../utils/Segment";

import "./PartsFinderTable.less";
import AppHeaderLoginMain from "../AppHeaderLogin/appheaderlogin.main";

interface PartsFinderTableProps {
  parts: Array<PartDetails>;
  prices: Array<PriceDetails>;
  availability: Array<InventoryAvailabilityInterface>;
  entitlements: Array<EntitlementDetails>;
  isMobile: boolean;
  history: any;
  searchParams: PartsFinderSearchParams;
}

const appHeaderLoginLinks = {
  profile: "/myAccount/profile",
  wishlists: "/wishlists"
};

const appModalLoginLinks = {
  registration: "/registration"
};

const PartsFinderTable: FC<PartsFinderTableProps> = ({
  parts,
  prices,
  availability,
  entitlements,
  history,
  isMobile,
  searchParams
}) => {
  const [checkedParts, setCheckedParts] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const context = useContext<{ cart: any; auth: any }>(MainContext);
  const { cart, auth } = context;
  const { isLoggedIn, logout } = auth;

  const formatEntitlement = (part: PartDetails): boolean => {
    if (entitlements && entitlements.length) {
      const partEntitlement: EntitlementDetails = entitlements.find(
        item => item.sku === part.pid
      );
      if (partEntitlement) return partEntitlement.entitled;
    }
    return false;
  };

  const formatPrice = (part: PartDetails): string => {
    if (prices && prices.length) {
      const partPrice: PriceDetails = prices.find(
        item => item.sku === part.pid
      );
      if (partPrice) return `${partPrice.total}`;
    }
    return intl.get("pending");
  };

  const formatAvailability = (part: PartDetails, selectedQty): string => {
    const inventoryItem = (availability &&
      availability.find(item => item.sku === part.pid)) || {
      branchAvailability: 0,
      regionAvailability: 0
    };
    return formatGeneralDGAAvailabilityLabel(
      inventoryItem.branchAvailability,
      selectedQty
    );
  };

  const handlePartChecked = (
    part: PartDetails,
    quantity: string,
    price: string,
    add: boolean
  ) => {
    const checkedPart = {
      ...part,
      quantity,
      price
    };

    if (add) {
      const updatedCheckedParts = {
        ...checkedParts,
        [checkedPart.pid]: checkedPart
      };
      setCheckedParts(updatedCheckedParts);
    } else {
      const {
        [checkedPart.pid]: removedPart,
        ...updatedCheckedParts
      } = checkedParts;
      setCheckedParts(updatedCheckedParts);
    }
  };

  const updateSelectedQuantity = (part: PartDetails, quantity: string) => {
    const updatedPart = { ...checkedParts[part.pid], quantity };
    const updatedCheckedParts = { ...checkedParts, [part.pid]: updatedPart };
    setCheckedParts(updatedCheckedParts);
  };

  const handleAddSelectedToCart = () => {
    const {
      getCartDetails,
      setSuccesCartPopupMessage,
      setErrorCartPopupMessage,
      cartDetails: {
        defaultCart: { addItemsToCart }
      }
    } = cart;
    let totalQuantity = 0;

    setLoading(true);

    const items = Object.values(checkedParts).map((part: any) => {
      totalQuantity += Number(part.quantity);
      return {
        code: part.pid,
        quantity: part.quantity
      };
    });

    addToCart(addItemsToCart.self.uri, { items })
      .then(res => getCartDetails())
      .then(() => {
        Object.values(checkedParts).forEach((part: any) => {
          // sends information to Segment when user adds a product
          productAdded(
            part.title,
            part.pid,
            !part.price || part.price === intl.get("pending")
              ? 0
              : Number(part.price),
            part.brand,
            part.class,
            Number(part.quantity)
          );
        });
      })
      .then(() => {
        setLoading(false);
        setSuccesCartPopupMessage(totalQuantity);
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleAddSelectedToCart => PartsFinderTable.tsx"
            })
          );
        } else {
          console.error(e);
          setLoading(false);
          setErrorCartPopupMessage(generateSpecificErrorMessage(e));
        }
      });
  };

  const renderParts = () => {
    return (
      <div>
        {parts.map(part => {
          const entitled: boolean = part.exists
            ? formatEntitlement(part)
            : false;
          const partPrice: string = part.exists ? formatPrice(part) : "";

          return (
            <PartsFinderTableItem
              key={part.pid}
              part={part}
              price={partPrice}
              entitled={entitled}
              handlePartChecked={handlePartChecked}
              updateSelectedQuantity={updateSelectedQuantity}
              isMobile={isMobile}
              history={history}
              searchParams={searchParams}
              formatAvailability={formatAvailability}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="parts-table">
      <div className="parts-table-header">
        {// eslint-disable-next-line no-nested-ternary
        isLoggedIn ? (
          !loading ? (
            <button
              type="button"
              className="ep-btn primary"
              disabled={!Object.keys(checkedParts).length}
              onClick={handleAddSelectedToCart}
            >
              {intl.get("add-selected-to-cart")}
            </button>
          ) : (
            <div className="miniLoader" />
          )
        ) : (
          <div className="login-button ep-btn primary">
            <AppHeaderLoginMain
              history={history}
              permission
              appHeaderLoginLinks={appHeaderLoginLinks}
              appModalLoginLinks={appModalLoginLinks}
              isLoggedIn={false}
              descriptiveButtonText={intl.get("login-message")}
            />
          </div>
        )}
      </div>
      <div className="parts-table-body">{renderParts()}</div>
    </div>
  );
};

export default PartsFinderTable;
