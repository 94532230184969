import React, { useContext, useState, useEffect } from "react";
import { History } from "history";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";
import {
  BranchListComponent,
  Messagecontainer
} from "@zilker/store-components";
import {
  formatAlternateBranchesInventory,
  checkTokensExpired,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { getAvailabilityDGA } from "@elasticpath/ref-store/src/services/connectServices";

interface Item {
  sku: string;
  quantity: number;
}

interface AvailableBranchesInfo {
  availableBranches: any[];
  branchModalMessage: string;
}

interface AvailableBranchListProps {
  branchModalOpen: boolean;
  selectedBranch: any; // BranchDetails type, see BranchSettings.tsx
  clientId: string;
  items: Item[];
  history: History;
  handleBranchModalOpen: {
    (branchModalOpen: boolean): void;
  };
  changeBranch?: (...args: any[]) => any;
  useButton?: boolean;
  useWarningMessage?: boolean;
}

const AvailableBranchList: React.FC<AvailableBranchListProps> = ({
  branchModalOpen,
  selectedBranch,
  clientId,
  items,
  history,
  handleBranchModalOpen,
  changeBranch,
  useButton,
  useWarningMessage
}) => {
  const mainContext = useContext<{
    auth: any;
    user: any;
    cart: any;
    branches: any;
  }>(MainContext);

  const {
    user: {
      userProfile: { homeBranch, customerNumber }
    },
    auth: { logout },
    branches: { findBranch }
  } = mainContext;
  const userHomeBranch = findBranch(homeBranch);
  const [availableBranchesInfo, setAvailableBranchesInfo] = useState<
    AvailableBranchesInfo
  >({
    availableBranches: [],
    branchModalMessage: ""
  });
  const [prevItems, setPrevItems] = useState<Item[]>([]);

  const handleSpinner = () => {
    const { availableBranches } = availableBranchesInfo;
    const { branchModalMessage } = availableBranchesInfo;
    if (availableBranches.length > 0 || branchModalMessage !== "") {
      return false;
    }
    return true;
  };

  const handleOpenBranchModal = () => {
    handleBranchModalOpen(true);
  };

  const handleCloseBranchModal = () => {
    handleBranchModalOpen(false);
  };

  const processInventory = (resultData: any) => {
    const availableBranchesMap = new Map();
    const data = formatAlternateBranchesInventory(resultData);
    data.forEach(branch => {
      if (branch.items.length !== 0 && branch.items.length === items.length) {
        for (let index = 0; index < branch.items.length; index++) {
          const itemFromOrder = items.find(
            item => item.sku === branch.items[index].sku
          );
          if (branch.items[index].qtyAvailable < itemFromOrder.quantity) {
            // exit for loop since this branch can't fulfill the order
            break;
          } else if (index === branch.items.length - 1) {
            // branch can fulfill order based on getAvailability.
            availableBranchesMap.set(branch.branchNumber, branch);
          }
        }
      }
    });
    setAvailableBranchesInfo({
      availableBranches: Array.from(availableBranchesMap.values()),
      branchModalMessage: availableBranchesInfo.availableBranches.length
        ? ""
        : intl.get("no-fulfillment-branches")
    });
    setPrevItems(items);
  };

  const fetchAvailability = (skus: string[]) => {
    const needsDetails = true;
    getAvailabilityDGA(
      customerNumber,
      skus,
      selectedBranch.branchNumber,
      needsDetails
    )
      .then(res => {
        processInventory(res.data.result);
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => fetchBranchesWithCompleteInventory => CartPage.tsx"
            })
          );
        }
        setAvailableBranchesInfo({
          availableBranches: [],
          branchModalMessage: intl.get("custom-error-complete-inventory")
        });
      });
  };

  useEffect(() => {
    if (branchModalOpen) {
      let itemsChanged = items.length !== prevItems.length;
      // if the array length is the same, check if all items are the same
      if (!itemsChanged) {
        itemsChanged = !items.every(item => {
          return prevItems.find(prevItem => {
            return (
              prevItem.sku === item.sku && prevItem.quantity === item.quantity
            );
          });
        });
      }
      if (itemsChanged) {
        const skus = items && items.map(item => item.sku);
        setAvailableBranchesInfo({
          availableBranches: [],
          branchModalMessage: ""
        });
        fetchAvailability(skus);
      }
    }
  }, [branchModalOpen]);

  return (
    <>
      {useButton && (
        <button
          className="ep-btn primary small"
          id="display_ship_complete_button"
          aria-label={intl.get("ship-complete")}
          type="submit"
          onClick={() => {
            handleOpenBranchModal();
          }}
        >
          {intl.get("ship-complete")}
        </button>
      )}
      {useWarningMessage && (
        <Messagecontainer
          message={{
            type: "danger-message",
            debugMessages: intl.get("backordered-message")
          }}
          closeContainerHandler={null}
          stayOpen
          button={{
            text: intl.get("backordered-button-message"),
            action: handleOpenBranchModal,
            className: "branches-modal-btn btn"
          }}
        />
      )}
      <Modal open={branchModalOpen} onClose={handleCloseBranchModal}>
        <div className="modal-lg select-fulfillment-branch-modal">
          {!handleSpinner() ? (
            <>
              <div
                className={`branch-modal-header ${
                  availableBranchesInfo.availableBranches &&
                  availableBranchesInfo.availableBranches.length
                    ? ""
                    : "empty"
                }`}
              >
                <div className="fulfillment-message">
                  {availableBranchesInfo.availableBranches &&
                  availableBranchesInfo.availableBranches.length ? (
                    <p className="label">
                      {intl.get("fulfillment-branches")} <br />
                      {intl.get("choose-branch")}
                    </p>
                  ) : (
                    <p className="label">
                      {availableBranchesInfo.branchModalMessage}
                    </p>
                  )}
                </div>
                {selectedBranch && (
                  <div className="selected-branch-info">
                    <p className="label">{intl.get("selected-branch")}</p>
                    <p>{selectedBranch.branchName}</p>
                  </div>
                )}
              </div>
              <div className="branch-modal-body">
                <BranchListComponent
                  branches={availableBranchesInfo.availableBranches}
                  homeBranch={userHomeBranch}
                  changeBranch={changeBranch}
                />
              </div>
            </>
          ) : (
            <div className="branch-modal-loader">
              <div className="loader" />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AvailableBranchList;
