import React, { FC, useContext } from "react";
import intl from "react-intl-universal";

import {
  getConfig,
  IEpConfig
} from "@zilker/store-components/src/utils/ConfigProvider";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { CatalogPriceResponse } from "../productdisplayitem.interfaces";

interface ProductPriceProps {
  priceDetails: CatalogPriceResponse;
}

const ProductPrice: FC<ProductPriceProps> = ({ priceDetails }) => {
  const { config }: { config: IEpConfig } = getConfig();
  const context = useContext<{ auth: any }>(MainContext);
  const {
    auth: { isLoggedIn }
  } = context;
  const { calculatePrice } = config;
  const displayPrice = isLoggedIn && calculatePrice;
  return (
    displayPrice &&
    (priceDetails ? (
      <div className="product-details-page-price">
        {priceDetails.total && priceDetails.total !== intl.get("pending")
          ? `$${priceDetails.total}`
          : intl.get("pending")}
      </div>
    ) : (
      <div className="product-details-page-miniLoader">
        <div className="miniLoader" />
      </div>
    ))
  );
};
export default ProductPrice;
