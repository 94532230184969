/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useContext } from "react";
import intl from "react-intl-universal";

import "./generalorderinformation.main.less";
import { Input } from "@zilker/store-components";
import Notifications from "../Notifications/Notifications";
import { MainContext } from "../../../app/src/contexts/MainContext";

import { getConfig, IEpConfig } from "../utils/ConfigProvider";

interface GOIProps {
  orderInformationData: any;
  notificationsStatus: any;
  notificationsData: any;
  poNumberRequired: boolean;
  handleOrderInformationChange: (...args: any[]) => any;
  handleNotificationsStatusChange: (...args: any[]) => any;
  handleNotificationsDataChange: (...args: any[]) => any;
  orderInformationErrors: any;
  notificationsErrors: any;
}

const GeneralOrderInformation: React.FC<GOIProps> = props => {
  const {
    orderInformationData,
    notificationsStatus,
    notificationsData,
    poNumberRequired,
    handleOrderInformationChange,
    handleNotificationsStatusChange,
    handleNotificationsDataChange,
    orderInformationErrors,
    notificationsErrors
  } = props;

  const context = useContext<{ user: any }>(MainContext);
  const {
    user: { userProfile }
  } = context;
  const {
    subuserFirstName,
    subuserLastName,
    subuserEmail,
    subuserPhone
  } = userProfile;

  const { config }: { config: IEpConfig } = getConfig();
  const {
    showUserNotifications,
    displayPriceOnPickedTickets,
    showPhoneNumberPopUp
  } = config;

  const displayPhoneNumber =
    showPhoneNumberPopUp &&
    (subuserFirstName || subuserLastName || subuserEmail) &&
    (!subuserPhone ||
      subuserPhone === "9999999999" ||
      subuserPhone === "999-999-9999" ||
      orderInformationData.phone);

  const [formErrors, setFormErrors] = useState(orderInformationErrors);
  const maxDate = new Date();
  const minDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 10);

  useEffect(() => {
    setFormErrors(prevState => ({
      ...prevState,
      ...orderInformationErrors
    }));
  }, [orderInformationErrors]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    handleOrderInformationChange(name, value);
  };

  const handleCheckboxClick = e => {
    const name = e.target.id;
    const value = !orderInformationData[e.target.id];
    handleOrderInformationChange(name, value);
  };

  return (
    <div className="general-order-information-container">
      <div className="row">
        {/* <div className="col-12 col-md-4">
          <div className="d-flex flex-column">
            <Input
              label={intl.get("alternate-email")}
              title={intl.get("alternate-email-tooltip")}
              type="email"
              inputName="alternate-email-address"
              ariaLabel={intl.get("alternate-email")}
              inputHandler={handleInputChange}
              value={orderInformationData["alternate-email-address"]}
              errors={formErrors}
              required={false}
            />
          </div>
        </div> */}
        {/* // See DGE-3145. Alternate email input is removed.
        <div className="col-12 col-md-4"> */}
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <div className="input-component">
              <p className="label">
                <span className="star">*</span>
                {intl.get("date-needed")}
              </p>
              <input
                type="date"
                name="date"
                placeholder="yyyy-mm-dd"
                onChange={handleInputChange}
                value={orderInformationData.date}
                min={minDate.toJSON().split("T")[0]}
                max={maxDate.toJSON().split("T")[0]}
                className={
                  formErrors && formErrors.date
                    ? "validation-error-border"
                    : null
                }
                required
              />
              <span className="date-time-error">{formErrors.date}</span>
            </div>
          </div>
        </div>
        {/* // See DGE-3145. Alternate email input is removed.
        <div className="col-12 col-md-4"> */}
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <Input
              label={intl.get("p-o-number")}
              type="text"
              inputName="po-number"
              ariaLabel={intl.get("p-o-number")}
              inputHandler={handleInputChange}
              value={orderInformationData["po-number"]}
              errors={formErrors}
              required={poNumberRequired}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <div className="input-component">
              <p className="label">{intl.get("order-comments")}: </p>
              <textarea
                id="order-comments"
                name="order-comments"
                value={orderInformationData["order-comments"]}
                onChange={handleInputChange}
                maxLength={500}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <div className="input-component">
              <p className="label">{intl.get("delivery-instructions")}</p>
              <textarea
                id="shipping-instructions"
                name="shipping-instructions"
                value={orderInformationData["shipping-instructions"]}
                onChange={handleInputChange}
                maxLength={240}
              />
            </div>
          </div>
        </div>
        {displayPriceOnPickedTickets && (
          <div className="col-12">
            <label
              className="price-pick-tickets-label"
              htmlFor="price-pick-tickets"
              id="price-pick-tickets-label"
            >
              <input type="checkbox" name="price-pick-tickets" id="checkbox" />
              <span
                className={`price-pick-tickets-checkbox ${
                  orderInformationData["show-pick-tickets-price"]
                    ? "show-checkmark"
                    : "hide-checkmark"
                }`}
                onClick={handleCheckboxClick}
                onKeyDown={handleCheckboxClick}
                role="checkbox"
                aria-checked="false"
                tabIndex={0}
                aria-labelledby="price-pick-tickets-label"
                id="show-pick-tickets-price"
              />
              {intl.get("price-pick-tickets")}
            </label>
          </div>
        )}
        {showUserNotifications && (
          <div className="col-12">
            <Notifications
              notificationsData={notificationsData}
              notificationsStatus={notificationsStatus}
              notificationsError={notificationsErrors}
              updateNotificationsStatus={handleNotificationsStatusChange}
              updateNotificationsData={handleNotificationsDataChange}
              userProfile={userProfile}
            />
          </div>
        )}
        {displayPhoneNumber && (
          <div className="col-12 col-md-6">
            <div className="d-flex flex-column">
              <Input
                label={intl.get("phone-number")}
                type="text"
                inputName="phone"
                ariaLabel={intl.get("phone-number")}
                inputHandler={handleInputChange}
                value={orderInformationData.phone}
                errors={formErrors}
                required
              />
              <p className="error">
                {intl.get("empty-phone-number-message-2")}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralOrderInformation;
