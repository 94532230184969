interface UserRolesInterface {
  [key: string]: string;
}

const UserRoles = () => {
  const userRoles: UserRolesInterface = {
    viewCredit: "ECOMM_VIEW_CREDIT",
    viewStatements: "ECOMM_VIEW_STATEMENTS"
  };

  return userRoles;
};

export default UserRoles;
