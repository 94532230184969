import React from "react";
import intl from "react-intl-universal";

import "./OrderDetailsComponent.less";

interface OrderDetailsComponentProps {
  branch: { branchNumber: string };
  job?: { jobNumber: string; jobName: string };
  contractNumber?: string;
  poNumber?: string;
}

const OrderDetailsComponent: React.FC<OrderDetailsComponentProps> = ({
  branch,
  job,
  contractNumber,
  poNumber
}) => {
  const jobNumber = job ? job.jobNumber : null;
  const jobName = job ? job.jobName : null;
  const branchNumber = branch ? branch.branchNumber : null;

  return (
    <div>
      <ul className="job-branch-items">
        {jobNumber && jobName && (
          <li>{`${intl.get("job")}: ${jobNumber} ${jobName}`}</li>
        )}
        {branchNumber && <li>{`${intl.get("branch")}: #${branchNumber}`}</li>}
        {contractNumber && (
          <li>{`${intl.get("contract-number")}: ${contractNumber}`}</li>
        )}
        {poNumber && <li>{`${intl.get("p-o-number")}: ${poNumber}`}</li>}
      </ul>
    </div>
  );
};
export default OrderDetailsComponent;
