import { useState, useEffect, useContext } from "react";

import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { getConfig } from "../utils/ConfigProvider";

const useAhriToolsLink = () => {
  const [ahriToolsLink, setAhriToolsLink] = useState("");
  const context = useContext<{ user: any; cart: any; auth: any }>(MainContext);
  const {
    user: {
      userProfile: { customerNumber }
    },
    cart: {
      cartDetails: { defaultCart }
    },
    auth: { isLoggedIn }
  } = context;
  const selectedBranch = defaultCart ? defaultCart.selectedBranch : {};
  const selectedBranchNumber = selectedBranch.code;
  const cartId = defaultCart ? defaultCart.cartId : null;

  const token = localStorage.getItem("DK_oAuthToken") || "";
  let tokenValue = token;
  if (token) {
    tokenValue = token.replace("Bearer ", "");
  }

  const { config } = getConfig();
  const {
    ahriURL,
    cortexApi: { scope },
    brXM: { origin }
  } = config;

  useEffect(() => {
    if (
      tokenValue &&
      customerNumber &&
      selectedBranchNumber &&
      cartId &&
      isLoggedIn &&
      !ahriToolsLink
    ) {
      setAhriToolsLink(
        `${ahriURL}?brand=${scope}&customerNumber=${customerNumber}&branchNumber=${selectedBranchNumber}&JWT=${tokenValue}&cartId=${cartId}&callbackURL=${origin}mycart`
      );
    }
  }, [tokenValue, customerNumber, selectedBranchNumber, cartId, isLoggedIn]);

  return ahriToolsLink;
};

export default useAhriToolsLink;
