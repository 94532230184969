import React from "react";
import "./MatchUpAside.less";

interface MatchUpProps {
  matchUp: { [key: string]: any };
  scrollBundles: (...args: any[]) => any;
}

const MatchUpAside: React.FC<MatchUpProps> = ({ matchUp, scrollBundles }) => {
  const { name, groups } = matchUp;

  function getGroupList() {
    return groups.map(group => {
      return (
        <div
          key={group._id}
          className="asideList"
          onClick={() => scrollBundles(group._id)}
          onKeyDown={() => {}}
          role="presentation"
        >
          {group.name}
        </div>
      );
    });
  }
  return (
    <div className="asideSection">
      <div className="asideHeading">
        <h4 className="bullet">{name}</h4>
      </div>
      {getGroupList()}
    </div>
  );
};

export default MatchUpAside;
