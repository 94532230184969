import { useState, useEffect } from "react";
import { getConfig } from "@zilker/store-components";
import { discoverOIDCParameters } from "@zilker/store-components/src/utils/AuthService";
import * as LoginService from "../services/LoginService";
import { setTokenInHeaders, checkResponse } from "../utils/helpers";
// eslint-disable-next-line import/prefer-default-export
export const AuthContext = () => {
  const [isLoggedIn, setLogin] = useState(LoginService.isLoggedIn());
  const [guestLoggedIn, setGuestLoggedIn] = useState(false);
  const [tempSsoTokens, setTempSsoTokens] = useState({
    _oAuthRole: "",
    _oAuthScope: "",
    _oAuthTokenAuthService: "",
    _oAuthUserName: "",
    multipleCompanies: true
  });
  const [signoutUrlParams, setSignoutUrlParams] = useState(undefined);
  const [guestTokenError, setGuestTokenError] = useState(false);
  const [userClickedLogout, setUserClickedLogout] = useState(false);
  const [isTokenSet, setIsTokenSet] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  const { config } = getConfig();

  const guestLogin = () => {
    LoginService.doPublicLogin()
      .then(() => {
        setGuestLoggedIn(true);
      })
      .catch(() => {
        setGuestTokenError(true);
      });
  };

  const logout = (userClicked = false) => {
    setCurrentUrl(window.location.href);
    return LoginService.doLogout()
      .then(res => {
        const onSuccess = data => data;
        checkResponse(res, onSuccess);
      })
      .then(() => {
        return discoverOIDCParameters();
      })
      .then(oidcParameters => {
        setUserClickedLogout(userClicked);
        setTokenInHeaders();
        setSignoutUrlParams(oidcParameters);
        setLogin(false);
        sessionStorage.removeItem("notified-branch");
        sessionStorage.removeItem("jobChosen");
        sessionStorage.removeItem("clientChosen");
        localStorage.removeItem("phoneUpdateModalOpened");
      })
      .catch(err => {
        throw err;
      });
  };

  const checkURL = () => {
    // Check if user is currently on home page or category browsing page (PPL, Search Result Page)
    return (
      currentUrl === config.brXM.origin ||
      currentUrl.includes("search") ||
      currentUrl.includes("category") ||
      currentUrl.includes("itemdetail")
    );
  };

  useEffect(() => {
    const daikinToken = localStorage.getItem("DK_oAuthToken");
    if (isLoggedIn) {
      setLogin(true);
      const token = setTokenInHeaders(daikinToken);
      setIsTokenSet(token);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const location = window.location.href.includes("/loggedin");
    if (!isLoggedIn && !signoutUrlParams && !location) {
      guestLogin();
    }
  }, [isLoggedIn, signoutUrlParams]);

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!signoutUrlParams) {
      const returnUrl =
        userClickedLogout || checkURL()
          ? config.b2b.openId.callbackUrl
          : config.b2b.openId.sessionExpiredUrl;
      const logoutUrl = config.b2b.openId.partialLogoutUrl;
      const encodedReturnUrl = encodeURIComponent(returnUrl);
      const logoutDaikinDomain = signoutUrlParams.issuer;
      const logoutDaikinUrl = `${logoutDaikinDomain}${logoutUrl}${encodedReturnUrl}`;

      window.location.href = logoutDaikinUrl;
    }
  }, [signoutUrlParams]);

  return {
    isLoggedIn,
    guestLoggedIn,
    setGuestLoggedIn,
    logout,
    tempSsoTokens,
    setTempSsoTokens,
    setLogin,
    guestTokenError,
    setGuestTokenError,
    isTokenSet
  };
};
