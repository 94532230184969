/* eslint-disable class-methods-use-this */
/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { debounce } from "lodash";

import "./appheadersearch.main.less";
import { SearchDropdown } from "@zilker/store-components";
import { getConfig } from "../utils/ConfigProvider";
import { ReactComponent as SearchIcon } from "../../../app/src/images/header-icons/magnifying-glass.svg";
import { handleCustomException } from "../../../app/src/utils/helpers";

let intl = { get: str => str };
let dropdownLimit = "string";

interface AppHeaderSearchMainProps {
  isMobileView: boolean;
  isFocused?: boolean;
  brSuggest: (...args: any[]) => any;
  history: any;
  isLoggedIn: boolean;
}
interface AppHeaderSearchMainState {
  inputValue: string;
  suggestions: Array<string>;
  focused: boolean;
  suggestionIndex: number | undefined;
  arrowSelection: boolean;
  noSuggestions: boolean;
  recentSearches: Array<string>;
  errorMessage: string;
}

class AppHeaderSearchMain extends React.Component<
  AppHeaderSearchMainProps,
  AppHeaderSearchMainState
> {
  private searchInput: React.RefObject<HTMLInputElement>;

  recentSearchesNumberLimit = 3;

  suggestionsNumberLimit = 5;

  dropdownNumberLimit =
    this.recentSearchesNumberLimit + this.suggestionsNumberLimit;

  constructor(props) {
    super(props);
    ({
      intl,
      config: {
        bloomreachSearch: {
          config: {
            suggest: { suggestionDropdownLimit: dropdownLimit }
          }
        }
      }
    } = getConfig());
    this.state = {
      inputValue: "",
      suggestions: [],
      focused: false,
      suggestionIndex: undefined,
      arrowSelection: false,
      noSuggestions: false,
      recentSearches: [],
      errorMessage: ""
    };
    this.searchInput = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.getSuggestions = debounce(this.getSuggestions.bind(this), 200);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickOutsideSearch);

    const { isLoggedIn } = this.props;
    const {
      config: { showRecentSearches }
    } = getConfig();
    if (isLoggedIn && showRecentSearches) {
      this.setState({
        recentSearches:
          JSON.parse(localStorage.getItem("recent_searches")) || []
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickOutsideSearch);
  }

  clickOutsideSearch = event => {
    if (
      this.searchInput.current &&
      this.searchInput.current.className !== event.target.className &&
      typeof event.target.className === "string" &&
      !event.target.className.includes("suggestion-link")
    ) {
      this.handleBlur();
    }
  };

  saveRecentSearches = async searchTerm => {
    const { recentSearches } = this.state;
    const updatedRecentSearces = Array.from(
      new Set([searchTerm, ...recentSearches])
    ).slice(0, this.recentSearchesNumberLimit);
    await this.setState({
      recentSearches: updatedRecentSearces
    });
    localStorage.setItem(
      "recent_searches",
      JSON.stringify(updatedRecentSearces)
    );
  };

  getSuggestions = async inputValue => {
    const { brSuggest } = this.props;
    try {
      const suggestResults = await brSuggest(
        inputValue,
        window.location.href,
        document.referrer
      );
      const {
        data: {
          response: { suggestions }
        }
      } = suggestResults;

      if (!suggestions || !suggestions.length) {
        this.setState({
          suggestions: [],
          suggestionIndex: undefined,
          arrowSelection: false,
          noSuggestions: true
        });
        return;
      }

      this.setState({
        suggestions: suggestions
          .map(suggestion => suggestion.dq)
          .slice(0, this.suggestionsNumberLimit),
        suggestionIndex: undefined,
        arrowSelection: false
      });
    } catch (error) {
      this.setState({
        errorMessage: intl.get("could-not-get-search-suggestions")
      });
    }
  };

  handleChange = event => {
    this.setState(
      {
        inputValue: event.target.value,
        noSuggestions: false,
        focused: true,
        errorMessage: ""
      },
      () => {
        const { inputValue } = this.state;

        if (inputValue.length > 2) {
          this.getSuggestions(inputValue);
        }
        if (inputValue.length === 0) {
          this.setState({
            suggestionIndex: undefined,
            arrowSelection: false,
            suggestions: []
          });
        }
      }
    );
  };

  handleEnter = async event => {
    const { keyCode } = event;
    const {
      arrowSelection,
      suggestionIndex,
      suggestions,
      inputValue,
      recentSearches
    } = this.state;

    if (keyCode !== 13) return;
    if (arrowSelection && suggestionIndex) {
      const keyword = [...recentSearches, ...suggestions][suggestionIndex];
      this.goToSearchPage(keyword);
      return;
    }
    this.goToSearchPage(inputValue);
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = () => {
    this.setState({
      focused: false,
      arrowSelection: false,
      suggestionIndex: undefined,
      errorMessage: ""
    });
  };

  goToSearchPage = suggestionKeyword => {
    const { history, isLoggedIn } = this.props;
    const { suggestions, noSuggestions, recentSearches } = this.state;
    const {
      config: { showRecentSearches }
    } = getConfig();

    // We need to encode the query as well as deal with a special case for '%' existing in the query.
    // There are some issues here regarding display of the '%' character as well as breakages
    // with malformed URI components when trying to encode that character and have it play nice with
    // page reloads, breadcrumb links, titles, encodeURI and deocodeURI, etc.
    // This special treatment of the '%' character around search results and breadcrumbs was needed
    // to make the form submit work and display the same way refreshing the page with the search
    // param already loaded behaves.
    const encoded = suggestionKeyword.replace(/%/g, "%25");

    if (
      suggestionKeyword &&
      ((suggestions && suggestions.length) ||
        (recentSearches && recentSearches.length) ||
        noSuggestions)
    ) {
      this.setState(
        {
          arrowSelection: false,
          suggestionIndex: undefined,
          inputValue: "",
          suggestions: [],
          focused: false,
          errorMessage: ""
        },
        async () => {
          if (isLoggedIn && showRecentSearches)
            await this.saveRecentSearches(suggestionKeyword);
          history.push(encodeURI(`/search/${encoded}`), {
            searchType: "keyword",
            searchTerm: suggestionKeyword,
            prevUrl: window.location.href
          });
        }
      );
    }
  };

  setCarrotPosition = () => {
    const { inputValue } = this.state;
    window.setTimeout(() => {
      this.searchInput.current.setSelectionRange(
        inputValue.length,
        inputValue.length
      );
    }, 0);
  };

  selectionOutOfBounds = index => {
    return (
      index >= parseInt(dropdownLimit, this.dropdownNumberLimit) || index < 0
    );
  };

  handleUpDownKeys = e => {
    const { keyCode } = e;
    const {
      suggestionIndex,
      suggestions,
      arrowSelection,
      recentSearches
    } = this.state;

    if (
      keyCode === 40 &&
      !this.selectionOutOfBounds(suggestionIndex + 1) &&
      ((suggestions && suggestions.length) ||
        (recentSearches && recentSearches.length))
    ) {
      if (typeof suggestionIndex === "number") {
        this.setState(
          prevState => ({
            suggestionIndex: prevState.suggestionIndex + 1,
            arrowSelection: true,
            inputValue: [...prevState.recentSearches, ...prevState.suggestions][
              prevState.suggestionIndex + 1
            ]
          }),
          () => {
            this.setCarrotPosition();
          }
        );
        return;
      }
      this.setState(
        {
          suggestionIndex: 0,
          arrowSelection: true,
          inputValue: [...recentSearches, ...suggestions][0]
        },
        () => {
          this.setCarrotPosition();
        }
      );
    }

    if (
      keyCode === 38 &&
      !this.selectionOutOfBounds(suggestionIndex - 1) &&
      ((suggestions && suggestions.length) ||
        (recentSearches && recentSearches.length))
    ) {
      if (!arrowSelection) {
        this.setState(
          {
            suggestionIndex:
              parseInt(dropdownLimit, this.dropdownNumberLimit) - 1,
            arrowSelection: true,
            inputValue: [...recentSearches, ...suggestions][
              parseInt(dropdownLimit, this.dropdownNumberLimit) - 1
            ]
          },
          () => {
            this.setCarrotPosition();
          }
        );
        return;
      }
      this.setState(
        prevState => ({
          suggestionIndex: prevState.suggestionIndex - 1,
          arrowSelection: true,
          inputValue: [...prevState.recentSearches, ...prevState.suggestions][
            prevState.suggestionIndex - 1
          ]
        }),
        () => {
          this.setCarrotPosition();
        }
      );
    }
  };

  keyHandler = e => {
    if (e.key === "Enter") {
      this.handleEnter(e);
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      this.handleUpDownKeys(e);
    }
  };

  render() {
    const { isMobileView } = this.props;
    const {
      suggestions,
      focused,
      suggestionIndex,
      arrowSelection,
      inputValue,
      recentSearches,
      errorMessage
    } = this.state;

    return (
      <div
        className={`main-search-container ${isMobileView ? "mobile-view" : ""}`}
      >
        <div className="search-form" role="search">
          <input
            className="input-search"
            type="search"
            aria-label="enter a product, or product information here to search"
            tabIndex={0}
            onChange={this.handleChange}
            onKeyDown={this.keyHandler}
            placeholder={intl.get("search")}
            onFocus={this.handleFocus}
            ref={this.searchInput}
            value={inputValue}
          />
          <div
            onClick={() => {
              this.goToSearchPage(inputValue);
            }}
            className="main-search-button"
            onKeyDown={() => {}}
            role="button"
            aria-label="click this button to search"
            tabIndex={0}
          >
            <SearchIcon className="search-icon" />
          </div>
          {(suggestions.length || recentSearches.length) && focused ? (
            <SearchDropdown
              suggestions={suggestions}
              suggestionIndex={suggestionIndex}
              arrowSelection={arrowSelection}
              inputValue={inputValue}
              goToSearchPage={this.goToSearchPage}
              errorMessage={errorMessage}
              recentSearches={recentSearches}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default AppHeaderSearchMain;
