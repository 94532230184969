import React, { FC, useContext, useState, useEffect, useRef } from "react";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";

import "./saved.order.card.less";
import {
  checkResponse,
  checkTokensExpired,
  pushToMaintenace,
  generateSpecificErrorMessage
} from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { cortexFetch } from "../utils/Cortex";
import Input from "../common/InputComponent/Input";
// import { productAdded } from "../utils/Segment";

interface CartsInterface {
  _element: any[];
  links: any;
  messages: any;
  self: any;
}

interface SavedOrderCardProps {
  history: any;
  carts: CartsInterface;
}

interface OrderDetails {
  links: any;
  messages: any;
  self: any;
  "total-quantity": number;
  _descriptor: any;
}

const SavedOrderCard: FC<SavedOrderCardProps> = ({ history, carts }) => {
  const context = useContext<{ cart: any; auth: any }>(MainContext);
  const {
    cart: {
      cartListError,
      getCartList,
      setSuccesCartPopupMessage,
      setErrorCartPopupMessage,
      getCartDetails
    },
    auth: { logout }
  } = context;

  const [selectedOrder, setSelectedOrder] = useState<OrderDetails>(null);
  const [renameModal, setRenameModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [orderName, setOrderName] = useState<string>("");
  const [modalLoader, setModalLoader] = useState<boolean>(false);
  const [loader, setLoader] = useState<Array<boolean>>([]);
  const [renameError, setRenameError] = useState<string>("");

  const isCancelled = useRef(false);

  const { config }: { config: IEpConfig } = getConfig();
  const { defaultChannel } = config.brXM;
  const motiliStyle = defaultChannel === "motili";

  useEffect(() => {
    // eslint-disable-next-line no-return-assign
    return () => (isCancelled.current = true);
  }, []);

  if (cartListError) {
    pushToMaintenace(history, {
      e: { message: cartListError },
      errIn: "Cart context error => SavedOrderCard.tsx"
    });
  }

  const openRenameModal = (index: number): void => {
    setSelectedOrder(carts._element[index]);
    setOrderName(carts._element[index]._descriptor[0].name);
    setRenameModal(true);
  };

  const openDeleteModal = (index: number): void => {
    setSelectedOrder(carts._element[index]);
    setDeleteModal(true);
  };

  const handleInputChange = (e: any): void => {
    setOrderName(e.target.value);
    setRenameError("");
  };

  const validateOrderName = (): boolean => {
    const duplicate: OrderDetails = carts._element.find(
      order =>
        order._descriptor[0].name
          .toUpperCase()
          .trim()
          .split(" ")
          .join("") ===
        orderName
          .toUpperCase()
          .trim()
          .split(" ")
          .join("")
    );
    if (duplicate) {
      setRenameError(
        intl.get("create-order-error-1") +
          orderName +
          intl.get("create-order-error-2")
      );
      return false;
    }
    return true;
  };

  const handleRenameOrder = (): void => {
    if (validateOrderName()) {
      setModalLoader(true);
      if (selectedOrder && orderName.length) {
        cortexFetch(selectedOrder._descriptor[0].self.uri, {
          method: "put",
          body: JSON.stringify({ name: orderName })
        })
          .then(res => checkResponse(res, data => data))
          .then(() => getCartList())
          .then(() => {
            if (!isCancelled.current) {
              setOrderName("");
              setModalLoader(false);
              setRenameModal(false);
            }
          })
          .catch(e => {
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn: "Logout => handleRenameOrder => SavedOrderCard.tsx"
                })
              );
            } else if (!isCancelled.current) {
              pushToMaintenace(history, {
                e,
                errIn: "handleRenameOrder => SavedOrderCard.tsx"
              });
            }
          });
      }
    }
  };

  const handleDeleteOrder = (): void => {
    setModalLoader(true);
    if (selectedOrder) {
      cortexFetch(selectedOrder.self.uri, {
        method: "delete"
      })
        .then(res => checkResponse(res, data => data))
        .then(() => getCartList())
        .then(() => {
          if (!isCancelled.current) {
            setModalLoader(false);
            setDeleteModal(false);
          }
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => handleDeleteOrder => SavedOrderCard.tsx"
              })
            );
          } else if (!isCancelled.current) {
            pushToMaintenace(history, {
              e,
              errIn: "handleDeleteOrder => SavedOrderCard.tsx"
            });
          }
        });
    }
  };

  const handleAddToDefaultCart = (index: number): void => {
    let updatedLoader: Array<boolean> = [...loader];
    updatedLoader[index] = true;
    if (!isCancelled.current) {
      setLoader(updatedLoader);
    }
    let addToDefaultCartLink;
    let itemsCount;

    try {
      addToDefaultCartLink = carts._element[index].links.find(
        link => link.rel === "addcarttodefaultcartform"
      ).uri;

      itemsCount = carts._element[index]["total-quantity"];
    } catch (e) {
      pushToMaintenace(history, {
        e,
        errIn: "handleAddToDefaultCart => SavedOrderCard.tsx"
      });
    }

    cortexFetch(addToDefaultCartLink, {
      method: "post",
      body: JSON.stringify({})
    })
      .then(res => {
        // TODO DGE-3116
        // How to send items to Segment:
        // // sends information to Segment for every product user adds
        // orderItems.forEach(item => {
        //   productAdded(
        //     item.name,
        //     item.id,
        //     item.price,
        //     item.brand,
        //     item.category,
        //     item.quantity
        //   );
        // });

        const onSuccess = data => data;
        const onError = data => {
          if (!data.ok) {
            return data.json().then(json => {
              throw json;
            });
          }
          throw data;
        };
        return checkResponse(res, onSuccess, onError);
      })
      // Update only the default cart - after adding item(s).
      .then(() => getCartDetails())
      .then(() => {
        setSuccesCartPopupMessage(itemsCount);
        if (!isCancelled.current) {
          setLoader(prevLoader => {
            updatedLoader = [...prevLoader];
            updatedLoader[index] = false;
            return updatedLoader;
          });
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleAddToDefaultCart => SavedOrderCard.tsx"
            })
          );
        } else if (!isCancelled.current) {
          setLoader(prevLoader => {
            updatedLoader = [...prevLoader];
            updatedLoader[index] = false;
            return updatedLoader;
          });
          setErrorCartPopupMessage(generateSpecificErrorMessage(e));
        }
      });
  };

  const handleCloseRenameModal = (): void => {
    setRenameError("");
    setRenameModal(false);
  };

  const renderRenameModal = (): any => {
    return (
      <Modal open={renameModal} onClose={handleCloseRenameModal}>
        <div className={`modal-${motiliStyle ? "lg" : "md"} rename-modal`}>
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">{intl.get("rename-order")}</h2>
            </div>
            {selectedOrder ? (
              <div className="modal-body">
                <Input
                  type="text"
                  label={intl.get("name")}
                  ariaLabel={intl.get("name")}
                  inputHandler={handleInputChange}
                  inputName="orderName"
                  required={motiliStyle}
                  value={orderName}
                />
                {!modalLoader ? (
                  <div>
                    <div className="edit-order-buttons">
                      <button
                        type="button"
                        className="ep-btn"
                        aria-label={intl.get("cancel")}
                        onClick={handleCloseRenameModal}
                      >
                        {intl.get("cancel")}
                      </button>
                      <button
                        type="button"
                        aria-label={intl.get("save")}
                        className="ep-btn primary"
                        onClick={handleRenameOrder}
                        disabled={!orderName.length}
                      >
                        {intl.get("save")}
                      </button>
                    </div>
                    <p className="rename-error">{renameError}</p>
                  </div>
                ) : (
                  <div className="miniLoader" />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    );
  };

  const renderDeleteModal = (): any => {
    return (
      <Modal open={deleteModal} onClose={() => setDeleteModal(false)}>
        <div className={`modal-${motiliStyle ? "lg" : "md"} delete-modal`}>
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">{intl.get("delete-order")}</h2>
            </div>
            <div className="modal-body">
              {selectedOrder ? (
                <div>
                  <p>
                    {`${intl.get("you-are-about-delete")} ${
                      selectedOrder._descriptor[0].name
                    }`}
                  </p>
                  <p>{intl.get("delete-order-confirmation")}</p>
                  {!modalLoader ? (
                    <div className="edit-order-buttons">
                      <button
                        type="button"
                        aria-label={intl.get("cancel")}
                        className="ep-btn"
                        onClick={() => setDeleteModal(false)}
                      >
                        {intl.get("cancel")}
                      </button>
                      <button
                        type="button"
                        aria-label={intl.get("delete")}
                        className="ep-btn primary delete-order-btn"
                        onClick={handleDeleteOrder}
                      >
                        <i className="icon-delete" />
                        {intl.get("delete")}
                      </button>
                    </div>
                  ) : (
                    <div className="miniLoader" />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  if (carts) {
    const { _element } = carts;
    if (!_element) return null;
    return (
      <div>
        {renderRenameModal()}
        {renderDeleteModal()}
        {_element.map((element, index: number) => {
          const cartName = getNameFromCartDescriptor(element);
          const selfReferenceLink = `/myAccount/savedOrders/${cartName}${getSelfReferenceLink(
            element
          )}`;
          const key = cartName + index;

          return (
            <div className="cart-card vertically-aligned" key={key}>
              <p>{cartName}</p>
              <div className="cart-card-options spread-row">
                <div className="order-action-button vertically-aligned">
                  <i title={intl.get("document")} className="icon-document" />
                  <button
                    className="details-button"
                    type="button"
                    onClick={() => history.push(selfReferenceLink)}
                  >
                    {intl.get("details")}
                  </button>
                </div>
                {!element._descriptor[0].default ? (
                  <div className="order-action-button vertically-aligned">
                    <i className="icon-edit" />
                    <button
                      className="rename-button"
                      type="button"
                      onClick={() => openRenameModal(index)}
                    >
                      {intl.get("rename")}
                    </button>
                  </div>
                ) : null}
                {!element._descriptor[0].default ? (
                  <div className="order-action-button vertically-aligned">
                    <i className="icon-delete" />
                    <button
                      className="delete-button"
                      type="button"
                      onClick={() => openDeleteModal(index)}
                    >
                      {intl.get("delete")}
                    </button>
                  </div>
                ) : null}
                <div className="break" />
                {!element._descriptor[0].default ? (
                  <div className="order-action-button vertically-aligned">
                    {!loader[index] ? (
                      <button
                        className="ep-btn primary"
                        aria-label={intl.get("add-to-cart")}
                        type="button"
                        disabled={!element["total-quantity"]}
                        onClick={() => handleAddToDefaultCart(index)}
                      >
                        {intl.get("add-to-cart")}
                      </button>
                    ) : (
                      <div className="loader-container">
                        <div className="miniLoader" />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

function getNameFromCartDescriptor(element) {
  if (element._descriptor[0].default) return intl.get("default-cart");
  return element._descriptor[0].name;
}

function getSelfReferenceLink(element) {
  return `${element.self.uri}`;
}

export default SavedOrderCard;
