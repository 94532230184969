import React, { FC, useState, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
import intl from "react-intl-universal";

import {
  checkResponse,
  checkTokensExpired,
  generateSpecificErrorMessage,
  formatAvailabilityLabel,
  formatGeneralDGAAvailabilityLabel,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig } from "../utils/ConfigProvider";
import { cortexFetch } from "../utils/Cortex";
import { generateImageUrl } from "../../../app/src/utils/mappings/productDetails";
import missingImage from "../../../app/src/images/img_missing_horizontal@2x.png";
import { useMainContext } from "../../../app/src/contexts/MainContext.js";
import Popup from "../Popup/Popup";
import { ReactComponent as InfoIcon } from "../../../app/src/images/icons/info-icon.svg";

import "./saved.order.detail.component.less";

interface CartDetail {
  self: { type: string; uri: string; href: string };
  messages: [];
  _item: any[];
  _price: any[];
  _total: any[];
  configuration: {};
  quantity: number;
  branchAvailability: number;
  regionAvailability: number;
}

interface SavedOrderProps {
  cartDetail: CartDetail;
  qtyChangeDisabled: boolean;
  removeItemDisabled: boolean;
  setIsChangeDisabled: (value: boolean) => any;
  refreshCartDetails: (any?) => any;
  hasError: boolean;
  prosId?: string;
  first?: boolean;
  handleGroupItemsUpdate?: (...args: any[]) => any;
  activePros?: any;
  toggleActivePros?: (...args: any[]) => any;
  handleAddSingleItemTypeToCart: (
    productCode: string,
    setReorderSpinner: (value: React.SetStateAction<boolean>) => void
  ) => any;
  inventoryError: string;
}

enum CortexMethod {
  PUT = "put",
  DELETE = "delete"
}

const isDesktopScreen = (): boolean => window.innerWidth >= 1092;

const SavedOrderDetailComponent: FC<SavedOrderProps> = ({
  cartDetail,
  qtyChangeDisabled,
  removeItemDisabled,
  setIsChangeDisabled,
  refreshCartDetails,
  hasError,
  prosId,
  first,
  handleGroupItemsUpdate,
  activePros,
  toggleActivePros,
  handleAddSingleItemTypeToCart,
  inventoryError
}) => {
  const [isDesktop, setIsDesktop] = useState<boolean>(isDesktopScreen());
  const [openAvailabilityPopUp, setOpenAvailabilityPopUp] = useState<boolean>(
    false
  );

  const savedOrderPopupStyle = "lower-right";
  const savedOrderDetailPage = "saved-order-detail";
  const productName = cartDetail._item[0]._definition[0]["display-name"];
  const productCode = cartDetail._item[0]._code[0].code;
  let productPrice;
  let productTotal;
  try {
    productPrice = {
      display: cartDetail._price[0]["purchase-price"][0].display,
      amount: cartDetail._price[0]["purchase-price"][0].amount,
      currency: cartDetail._price[0]["purchase-price"][0].currency
    };
    productTotal = cartDetail._total[0].cost[0].display;
  } catch (e) {
    console.error(e);
    productPrice = {
      display: intl.get("pending"),
      amount: intl.get("pending"),
      currency: ""
    };
    productTotal = intl.get("pending");
  }
  if (!productTotal && cartDetail._price) {
    productTotal = cartDetail._price[0]["purchase-price"][0].display;
  }
  const { quantity, self } = cartDetail;
  let productImage;
  if (Object.keys(cartDetail).length) {
    productImage = generateImageUrl(cartDetail._item[0]._definition[0].details);
  }

  const [showReorderSpinner, setShowReorderSpinner] = useState<boolean>(false);
  const [selectedQuantity, setSelectedQuantity] = useState<number | string>(
    quantity
  );
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);

  const { config } = getConfig();
  const history = useHistory();
  const {
    auth: { logout },
    cart: { setErrorCartPopupMessage }
  } = useMainContext();

  const { scope } = config.cortexApi;
  const isSpecialAirPurifier =
    productCode === "MCB50YSAU" || productCode === "MCKB70YSAU";

  let itemAvailability;
  const selectedQty = Number(selectedQuantity);
  // PGL-364: remove iventory icon
  if (isSpecialAirPurifier) {
    itemAvailability = intl.get("special-air-purifier-inventory-msg");
  } else if (scope !== "motili") {
    itemAvailability = formatGeneralDGAAvailabilityLabel(
      cartDetail.branchAvailability,
      selectedQty,
      cartDetail.regionAvailability
    );
  } else {
    itemAvailability = formatAvailabilityLabel(
      cartDetail.branchAvailability,
      cartDetail.regionAvailability
    );
  }

  const editSavedOrder = (
    payload: {
      method: CortexMethod;
      body?: string;
    },
    callbackName: string
  ): void => {
    cortexFetch(self.uri, payload)
      .then(res => {
        const onSuccess = data => data;
        const onError = data => {
          if (!data.ok) {
            return data.json().then(json => {
              throw json;
            });
          }
          throw data;
        };
        return checkResponse(res, onSuccess, onError);
      })
      .then(() => refreshCartDetails())
      .then(() => {
        setUpdateLoader(false);
        setDeleteLoader(false);
        setIsChangeDisabled(false);
      })
      .catch(e => {
        setUpdateLoader(false);
        setDeleteLoader(false);
        setIsChangeDisabled(false);
        setErrorCartPopupMessage(generateSpecificErrorMessage(e));

        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: `Logout => ${callbackName} => saved.order.detail.component.tsx`
            })
          );
        }
      });
  };

  const handleQuantityChange = (): void => {
    setUpdateLoader(true);
    setIsChangeDisabled(true);

    if (!selectedQuantity || selectedQuantity === quantity) {
      setSelectedQuantity(quantity);
      setUpdateLoader(false);
      setIsChangeDisabled(false);
    } else {
      editSavedOrder(
        {
          method: CortexMethod.PUT,
          body: JSON.stringify({ quantity: selectedQuantity })
        },
        "handleQuantityChange"
      );
    }
  };

  const handleInputQuantityChange = (e): void => {
    if (!e.target.value) {
      setSelectedQuantity("");
    } else {
      setSelectedQuantity(parseInt(e.target.value, 10));
    }
  };

  const handleRemoveBtnClicked = (): void => {
    setDeleteLoader(true);
    setIsChangeDisabled(true);
    editSavedOrder({ method: CortexMethod.DELETE }, "handleRemoveBtnClicked");
  };

  const updateProsItemsQuantity = (): void => {
    setUpdateLoader(true);
    setIsChangeDisabled(true);
    handleGroupItemsUpdate(prosId, selectedQuantity, setUpdateLoader);
  };

  const removeProsItems = (): void => {
    setDeleteLoader(true);
    setIsChangeDisabled(true);
    handleGroupItemsUpdate(prosId, 0, setDeleteLoader);
  };

  const handleItemReorder = (): void => {
    handleAddSingleItemTypeToCart(productCode, setShowReorderSpinner);
  };

  const openAvailabilityPopUpModal = () => {
    setOpenAvailabilityPopUp(true);
  };

  const closeAvailabilityPopUpModal = () => {
    setOpenAvailabilityPopUp(false);
  };

  const renderProsHeader = () => {
    return (
      <div className="cart-detail-card">
        <div className="pros-header">
          <div className="description">
            <p>{intl.get("system-configuration")}</p>
            <button
              type="button"
              className="expand-pros-button"
              onClick={() => toggleActivePros(prosId)}
            >
              {activePros.includes(prosId) ? (
                <i className="icon-chevron-up" />
              ) : (
                <i className="icon-chevron-down" />
              )}
            </button>
          </div>
          <div className="quantity detail-column">
            {selectedQuantity !== undefined ? (
              <span className="input-group-btn">
                <div className="quantity-col form-content form-content-quantity">
                  <input
                    aria-label={`"quantity " + ${selectedQuantity}`}
                    className="saved-order-quantity-select form-control form-control-quantity"
                    type="number"
                    step="1"
                    min="1"
                    max="9999"
                    value={selectedQuantity}
                    onChange={handleInputQuantityChange}
                    onBlur={updateProsItemsQuantity}
                    disabled={qtyChangeDisabled || hasError}
                  />
                </div>
              </span>
            ) : (
              ""
            )}
            {updateLoader ? (
              <div className="quantity-loader-box">
                <div className="miniLoader" />
              </div>
            ) : null}
          </div>
          {!deleteLoader ? (
            <div
              className={`remove-btn-box ${
                !config.calculatePrice ? "wide-box" : ""
              }`}
            >
              <button
                className={`order-item-remove-btn d-block ${
                  !config.calculatePrice ? "wide-remove-btn" : ""
                }`}
                aria-label="remove item"
                type="button"
                onClick={removeProsItems}
                disabled={removeItemDisabled}
              >
                <span className="btn-text">
                  <i className="icon-close-x" />
                </span>
              </button>
            </div>
          ) : (
            <div
              className={`remove-btn-box ${
                !config.calculatePrice ? "wide-box" : ""
              }`}
            >
              <div className="miniLoader" />
            </div>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener("resize", () => setIsDesktop(isDesktopScreen()));

    return () =>
      window.removeEventListener("resize", () =>
        setIsDesktop(isDesktopScreen())
      );
  }, []);

  return (
    <>
      {prosId !== "undefined" && first ? renderProsHeader() : null}
      <div
        className={`cart-detail-card ${hasError ? "with-error" : ""} ${
          prosId !== "undefined"
            ? `pros-lineitem ${activePros.includes(prosId) ? "active" : ""}`
            : ""
        } ${!isDesktop ? "mobile-position" : ""}`}
        style={prosId !== "undefined" ? { backgroundColor: "#f0f0f0" } : {}}
      >
        <div className="description detail-column">
          <div className="image">
            <img
              src={productImage}
              alt={productName || intl.get("none-available")}
              onError={e => {
                const element: any = e.target;
                element.src = missingImage;
              }}
            />
          </div>
          <div className="product-definition">
            <p className="product-name">{productName}</p>
            <NavLink
              to={`/itemdetail/${productCode}`}
              target="_blank"
              tabIndex={-1}
            >
              <p className="label">{intl.get("product")}:</p>
              <span className="product-code">{productCode}</span>
            </NavLink>
          </div>
        </div>
        {isDesktop ? (
          <>
            {config.calculatePrice && (
              <div className="price detail-column">
                <p>{intl.get("price")}: </p>
                <p>{productPrice.display}</p>
              </div>
            )}

            <div className="quantity detail-column">
              {// eslint-disable-next-line no-nested-ternary
              prosId === "undefined"
                ? selectedQuantity !== undefined && (
                    <>
                      <span className="input-group-btn">
                        <div className="quantity-col form-content form-content-quantity">
                          <input
                            aria-label={`"quantity " + ${selectedQuantity}`}
                            className="saved-order-quantity-select form-control form-control-quantity"
                            type="number"
                            step="1"
                            min="1"
                            max="9999"
                            value={selectedQuantity}
                            onChange={handleInputQuantityChange}
                            onBlur={handleQuantityChange}
                            disabled={qtyChangeDisabled || hasError}
                          />
                        </div>
                      </span>
                      {updateLoader ? (
                        <div className="quantity-loader-box">
                          <div className="miniLoader" />
                        </div>
                      ) : null}
                      {scope !== "motili" && (
                        <div className="reorder-wrapper">
                          <span
                            data-id={`reorder ${productName} with ${productCode}`}
                            className="ico-button"
                            onClick={handleItemReorder}
                            role="button"
                            tabIndex={0}
                            aria-hidden="true"
                          >
                            {showReorderSpinner ? (
                              <span className="miniLoader" />
                            ) : (
                              <i className="icon-reorder" />
                            )}
                            <span> {intl.get("reorder")}</span>
                          </span>
                        </div>
                      )}
                    </>
                  )
                : null}
            </div>

            <div className="availability detail-column label">
              <div>
                {inventoryError ? (
                  intl.get("available-for-back-order-label")
                ) : (
                  <div className="availability-popup-container">
                    <div className="item-availability-container">
                      <span>{itemAvailability}</span>
                      {!isSpecialAirPurifier && (
                        <div className="info-popup-btn">
                          {!updateLoader && openAvailabilityPopUp ? (
                            <Popup
                              closePopUp={closeAvailabilityPopUpModal}
                              availabilityLabel={itemAvailability}
                              isCallButtonActive={false}
                              popupStyle={savedOrderPopupStyle}
                              page={savedOrderDetailPage}
                            />
                          ) : null}
                          <InfoIcon
                            className="info-icon"
                            onClick={openAvailabilityPopUpModal}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {updateLoader ? <div className="miniLoader" /> : null}
            </div>

            {config.calculatePrice && (
              <div className="total-price detail-column">
                <p>{intl.get("ext-price")}: </p>
                <p>{productTotal}</p>
              </div>
            )}
            {// eslint-disable-next-line no-nested-ternary
            prosId === "undefined" ? (
              !deleteLoader ? (
                <div
                  className={`remove-btn-box ${
                    !config.calculatePrice ? "wide-box" : ""
                  }`}
                >
                  <button
                    className={`order-item-remove-btn d-block ${
                      !config.calculatePrice ? "wide-remove-btn" : ""
                    }`}
                    aria-label="remove item"
                    type="button"
                    onClick={handleRemoveBtnClicked}
                    disabled={removeItemDisabled}
                  >
                    <span className="btn-text">
                      <i className="icon-close-x" />
                    </span>
                  </button>
                </div>
              ) : (
                <div
                  className={`remove-btn-box ${
                    !config.calculatePrice ? "wide-box" : ""
                  }`}
                >
                  <div className="miniLoader" />
                </div>
              )
            ) : (
              <div />
            )}
          </>
        ) : (
          // Mobile & tablet view
          <div className="flex-container">
            {prosId === "undefined" && (
              <div
                className={`quantity detail-column mobile-version${
                  scope === "motili" ? " motili-mobile" : ""
                }`}
              >
                {selectedQuantity !== undefined ? (
                  <span className="input-group-btn">
                    <div className="quantity-col form-content form-content-quantity">
                      <input
                        aria-label={`"quantity " + ${selectedQuantity}`}
                        className="saved-order-quantity-select form-control form-control-quantity"
                        type="number"
                        step="1"
                        min="1"
                        max="9999"
                        value={selectedQuantity}
                        onChange={handleInputQuantityChange}
                        onBlur={handleQuantityChange}
                        disabled={qtyChangeDisabled || hasError}
                      />
                    </div>
                  </span>
                ) : (
                  ""
                )}
                {updateLoader ? (
                  <div className="quantity-loader-box">
                    <div className="miniLoader" />
                  </div>
                ) : (
                  <></>
                )}
                {scope !== "motili" && (
                  <span
                    data-id={`reorder ${productName} with ${productCode}`}
                    className="ico-button"
                    onClick={handleItemReorder}
                    role="button"
                    tabIndex={0}
                    aria-hidden="true"
                  >
                    {showReorderSpinner ? (
                      <span className="miniLoader" />
                    ) : (
                      <i className="icon-reorder" />
                    )}
                    <span> {intl.get("reorder")}</span>
                  </span>
                )}
              </div>
            )}
            {config.calculatePrice && (
              <div className="price detail-column mobile-version d-flex justify-content-end">
                <p className="label mr-2 mx-2">{intl.get("price")}: </p>
                <p>{productPrice.display}</p>
              </div>
            )}
            {config.calculatePrice && (
              <div className="total-price detail-column mobile-version d-flex justify-content-end">
                <p className="label mr-2 mx-2">{intl.get("ext-price")}: </p>
                <p>{productTotal}</p>
              </div>
            )}
            {!deleteLoader ? (
              <div
                className={`remove-btn-box ${
                  !config.calculatePrice ? "wide-box" : ""
                }`}
              >
                {prosId === "undefined" && (
                  <button
                    className={`order-item-remove-btn order-idem-remove-btn-mobile d-block ${
                      !config.calculatePrice ? "wide-remove-btn" : ""
                    }`}
                    aria-label="remove item"
                    type="button"
                    onClick={handleRemoveBtnClicked}
                    disabled={removeItemDisabled}
                  >
                    <span className="btn-text">
                      <i className="icon-close-x" />
                    </span>
                  </button>
                )}
              </div>
            ) : (
              <div
                className={`remove-btn-box ${
                  !config.calculatePrice ? "wide-box" : ""
                }`}
              >
                <div className="miniLoader" />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SavedOrderDetailComponent;
