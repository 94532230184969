/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";

import {
  formatAvailabilityLabel,
  formatGeneralDGAAvailabilityLabel
} from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import imgPlaceholder from "../../../app/src/images/img_missing_horizontal@2x.png";
import { MainContext } from "../../../app/src/contexts/MainContext";
import Popup from "../Popup/Popup";
import { ReactComponent as InfoIcon } from "../../../app/src/images/icons/info-icon.svg";

import "./cart.lineitem.less";

let Config: IEpConfig | any = {};

interface CartLineItemProps extends RouteComponentProps {
  item: { [key: string]: any };
  itemDetailLink?: string;
  history: any;
  match: any;
  isChangeDisabled?: boolean;
  first?: boolean;
  prosId?: string;
  handleQuantityChange: (...args: any[]) => any;
  handleRemoveBtnClicked: (...args: any[]) => any;
  handleGroupItemsUpdate: (...args: any[]) => any;
  toggleActivePros?: (...args: any[]) => any;
  activePros?: any;
  setItemError?: (...args: any[]) => any;
  inventoryError?: string;
}

interface CartLineItemState {
  selectedQuantity: any;
  deleteLoader: boolean;
  updateLoader: boolean;
  hasError: boolean;
  availabilityLoader: boolean;
  openAvailabilityPopUp: boolean;
}

class CartLineItem extends React.Component<
  CartLineItemProps,
  CartLineItemState
> {
  static contextType = MainContext;

  _isMounted = false;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    const { item } = this.props;

    this.state = {
      selectedQuantity: item.quantity,
      deleteLoader: false,
      updateLoader: false,
      hasError: false,
      availabilityLoader: false,
      openAvailabilityPopUp: false
    };
    this.handleInputQuantityChange = this.handleInputQuantityChange.bind(this);
    this.toggleUpdateLoader = this.toggleUpdateLoader.bind(this);
    this.toggleDeleteLoader = this.toggleDeleteLoader.bind(this);
    this.updateQuantity = this.updateQuantity.bind(this);
    this.openAvailabilityPopUpModal = this.openAvailabilityPopUpModal.bind(
      this
    );
    this.closeAvailabilityPopUpModal = this.closeAvailabilityPopUpModal.bind(
      this
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    const { item } = this.props;
    if (prevProps.item.quantity !== item.quantity) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedQuantity: item.quantity
      });
    }

    this.validateQuantity();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validateQuantity() {
    const { selectedQuantity } = this.state;
    const { setItemError, item } = this.props;
    if (selectedQuantity > 1000 && !item.error) {
      setItemError({
        ...item,
        error: intl.get("large-quantity-error", { limit: 1000 })
      });
      this.setState({
        hasError: true
      });
    }
    if (selectedQuantity < 0 && !item.error) {
      setItemError({ ...item, error: intl.get("negative-quantity-error") });
      this.setState({
        hasError: true
      });
    }
    if (selectedQuantity <= 1000 && selectedQuantity > 0 && item.error) {
      setItemError({ ...item, error: "" });
      this.setState({
        hasError: false
      });
    }
  }

  handleInputQuantityChange(e) {
    const { value } = e.target;
    if (!value) {
      this.setState({
        selectedQuantity: ""
      });
    } else {
      this.setState({
        selectedQuantity: parseInt(value, 10),
        availabilityLoader: true
      });
    }
  }

  updateQuantity(groupItems: boolean) {
    const {
      item,
      prosId,
      handleQuantityChange,
      handleGroupItemsUpdate
    } = this.props;
    const { selectedQuantity, hasError } = this.state;

    if (!hasError) {
      if (!groupItems) {
        handleQuantityChange(item, selectedQuantity, this.toggleUpdateLoader);
        this.setState({
          availabilityLoader: false
        });
      } else {
        handleGroupItemsUpdate(
          item,
          selectedQuantity,
          prosId,
          this.toggleUpdateLoader,
          this.toggleDeleteLoader
        );
      }
    }
  }

  toggleUpdateLoader() {
    const { updateLoader } = this.state;
    if (this._isMounted) {
      this.setState({
        updateLoader: !updateLoader
      });
    }
  }

  toggleDeleteLoader() {
    const { deleteLoader } = this.state;
    if (this._isMounted) {
      this.setState({
        deleteLoader: !deleteLoader
      });
    }
  }

  renderContractNumber() {
    const { item } = this.props;
    if (item["contract-number"]) {
      return (
        <span className="contract-number">
          {`${intl.get("contract-number")}: ${item["contract-number"]}`}
        </span>
      );
    }
    return null;
  }

  renderUnitPrice() {
    const { item } = this.props;
    if (item._item && (item._price || item._item[0]._price)) {
      const itemPrice = item._price ? item._price : item._item[0]._price;
      const purchasePrice =
        item._price && itemPrice[0]["purchase-price"]
          ? itemPrice[0]["purchase-price"][0].display
          : intl.get("pending");
      return (
        <ul
          className={`price-container ${
            item["contract-number"] ? "contract-container" : ""
          }`}
        >
          <li className="cart-unit-purchase-price">{purchasePrice}</li>
          <li className="contract-number-container">
            {this.renderContractNumber()}
          </li>
        </ul>
      );
    }
    return (
      <ul className="price-container">
        <li className="cart-unit-purchase-price">{}</li>
        <li className="contract-number-container">
          {this.renderContractNumber()}
        </li>
      </ul>
    );
  }

  renderTotalPrice() {
    const { item } = this.props;
    let itemTotal =
      item._total && item._total[0].cost ? item._total[0].cost[0].display : "";
    if (!itemTotal && item._price) {
      itemTotal = item._price[0]["purchase-price"]
        ? item._price[0]["purchase-price"][0].display
        : "";
    }
    if (!itemTotal) {
      itemTotal = intl.get("pending");
    }
    return (
      <ul className="price-container">
        <li
          className="cart-total-list-price is-hidden"
          data-region="itemListPriceRegion"
        />
        <li className="cart-total-purchase-price">{itemTotal}</li>
      </ul>
    );
  }

  renderProsItemsHeader = () => {
    const { selectedQuantity, updateLoader, deleteLoader } = this.state;
    const {
      handleGroupItemsUpdate,
      item,
      prosId,
      isChangeDisabled,
      toggleActivePros,
      activePros
    } = this.props;
    return (
      <div className="row cart-lineitem-new pros-header">
        <div
          className={`col-12 ${
            Config.calculatePrice ? "col-lg-4" : "col-lg-7"
          }`}
        >
          <div className="col-header d-lg-block">
            <h4>{intl.get("system-configuration")}</h4>
            <button
              type="button"
              className="expand-pros-button"
              onClick={() => toggleActivePros(prosId)}
            >
              {activePros.includes(prosId) ? (
                <i className="icon-chevron-up" />
              ) : (
                <i className="icon-chevron-down" />
              )}
            </button>
          </div>
        </div>
        {Config.calculatePrice && (
          <div className="col-12 col-lg-2 d-none d-lg-block col-header" />
        )}
        <div className="col-6 col-lg-2">
          <div className="col-header d-lg-block quantity-update">
            <span className="input-group-btn" key="quantity-buttons">
              <div className="quantity-col form-content form-content-quantity">
                <input
                  className="product-display-item-quantity-select form-control form-control-quantity"
                  type="number"
                  step="1"
                  min="1"
                  max="9999"
                  value={selectedQuantity}
                  onChange={this.handleInputQuantityChange}
                  onBlur={() => this.updateQuantity(true)}
                  disabled={isChangeDisabled}
                />
              </div>
            </span>
            {updateLoader && <div className="miniLoader" />}
          </div>
        </div>
        <div className="col-6 col-lg-2 vertically-aligned-mobile col-header d-none" />
        {Config.calculatePrice && (
          <div className="col-12 col-lg-1 col-header d-none" />
        )}
        <div className="col-6 col-lg-1">
          <div className="col-header d-lg-block remove-button">
            {!deleteLoader ? (
              <button
                className="item-remove-btn d-lg-block"
                type="button"
                aria-label="remove item"
                onClick={() =>
                  handleGroupItemsUpdate(
                    item,
                    0,
                    prosId,
                    this.toggleUpdateLoader,
                    this.toggleDeleteLoader
                  )
                }
                disabled={isChangeDisabled}
              >
                <span className="btn-text">
                  <i className="icon-close-x" />
                </span>
              </button>
            ) : (
              <div className="miniLoader d-lg-block" />
            )}
          </div>
        </div>
      </div>
    );
  };

  renderCloseButton() {
    const {
      item,
      isChangeDisabled,
      prosId,
      handleRemoveBtnClicked
    } = this.props;
    const { deleteLoader } = this.state;
    const isProsUndefined = prosId === "undefined";

    return (
      isProsUndefined &&
      (!deleteLoader ? (
        <button
          className="item-remove-btn d-none d-lg-block"
          type="button"
          aria-label="remove item"
          onClick={() => handleRemoveBtnClicked(item, this.toggleDeleteLoader)}
          disabled={isChangeDisabled}
        >
          <span className="btn-text">
            <i className="icon-close-x" />
          </span>
        </button>
      ) : (
        <div className="miniLoader d-none d-lg-block" />
      ))
    );
  }

  openAvailabilityPopUpModal() {
    this.setState({
      openAvailabilityPopUp: true
    });
  }

  closeAvailabilityPopUpModal() {
    this.setState({
      openAvailabilityPopUp: false
    });
  }

  render() {
    const {
      item,
      itemDetailLink,
      isChangeDisabled,
      prosId,
      first,
      handleRemoveBtnClicked,
      activePros,
      inventoryError
    } = this.props;
    const {
      selectedQuantity,
      deleteLoader,
      updateLoader,
      availabilityLoader,
      openAvailabilityPopUp
    } = this.state;

    let itemCodeString = "";
    let itemDisplayName = "";
    let itemThumbnailImage = null;
    let itemAvailability = "";
    if (item._item) {
      itemCodeString = item._item[0]._code[0].code;
      itemDisplayName = item._item[0]._definition[0]["display-name"];
      itemThumbnailImage = item._item[0]._definition[0].details.find(
        detail => detail.name === "thumb_image"
      );
    }
    if (item._code) {
      itemCodeString = item._code[0].code;
    }
    if (item._definition) {
      itemDisplayName = item._definition[0]["display-name"];
    }
    const { scope } = Config.cortexApi;
    // PGL-364: Updates to MCB50YSAU and MCKB70YSAU
    const isSpecialAirPurifier =
      itemCodeString === "MCB50YSAU" || itemCodeString === "MCKB70YSAU";

    if (isSpecialAirPurifier) {
      itemAvailability = intl.get("special-air-purifier-inventory-msg");
    } else if (scope !== "motili") {
      itemAvailability = formatGeneralDGAAvailabilityLabel(
        item.branchAvailability,
        selectedQuantity,
        item.regionAvailability
      );
    } else {
      itemAvailability = formatAvailabilityLabel(
        item.branchAvailability,
        item.regionAvailability
      );
    }

    const isLoadingAvailability =
      itemAvailability === intl.get("checking-availability");

    const cartPopupStyle = "lower-right";
    const cartPage = "cart";

    return (
      <>
        {first && prosId !== "undefined" ? this.renderProsItemsHeader() : null}

        <div
          id={`cart_lineitem_${itemCodeString}`}
          className={`row cart-lineitem-new ${
            prosId !== "undefined"
              ? `pros-lineitem ${activePros.includes(prosId) ? "active" : ""}`
              : ""
          }`}
          style={prosId !== "undefined" ? { backgroundColor: "#f0f0f0" } : {}}
        >
          <div
            className={`col-12 ${
              Config.calculatePrice ? "col-lg-4" : "col-lg-7"
            }`}
          >
            <div className="d-flex product-details-image">
              <Link
                to={{
                  pathname: `${itemDetailLink}/${itemCodeString}`,
                  state: {
                    name: itemDisplayName,
                    prevUrl: window.location.href
                  }
                }}
              >
                <img
                  src={itemThumbnailImage ? itemThumbnailImage.value : ""}
                  onError={e => {
                    const element: any = e.target;
                    element.src = imgPlaceholder;
                  }}
                  alt={itemDisplayName || intl.get("none-available")}
                  className="cart-lineitem-thumbnail"
                />
              </Link>
              <div className="product-details">
                <Link
                  to={{
                    pathname: `${itemDetailLink}/${itemCodeString}`,
                    state: {
                      name: itemDisplayName,
                      prevUrl: window.location.href
                    }
                  }}
                >
                  {itemDisplayName}
                </Link>
                <ul className="options-container">
                  <li className="part-number-container">
                    <span>{`${intl.get("product")}: `}</span>
                    <span className="part-number">{itemCodeString}</span>
                  </li>
                </ul>
                {Config.calculatePrice && (
                  <div className="d-flex d-block d-lg-none">
                    {this.renderUnitPrice()}
                  </div>
                )}
                {!deleteLoader ? (
                  prosId === "undefined" && (
                    <button
                      className="item-remove-btn d-block d-lg-none"
                      aria-label="remove item"
                      type="button"
                      onClick={() =>
                        handleRemoveBtnClicked(item, this.toggleDeleteLoader)
                      }
                      disabled={isChangeDisabled}
                    >
                      <span className="btn-text">
                        <i className="icon-close-x" />
                      </span>
                    </button>
                  )
                ) : (
                  <div className="miniLoader d-block d-lg-none" />
                )}
              </div>
            </div>
          </div>
          {Config.calculatePrice && (
            <div className="col-12 col-lg-2 d-none d-lg-block">
              <div className="d-flex justify-content-center">
                <div className="d-inline-block d-md-none mr-2">
                  {`${intl.get("price")}: `}
                </div>
                {this.renderUnitPrice()}
              </div>
            </div>
          )}
          <div className="col-6 col-lg-1">
            <div className="d-flex quantity-container justify-content-center flex-column">
              {prosId === "undefined" && (
                <div className="quantity-col form-content justify-content-lg-center">
                  {selectedQuantity !== undefined
                    ? [
                        <span
                          className="input-group-btn"
                          key="quantity-buttons"
                        >
                          <div className="quantity-col form-content form-content-quantity">
                            <input
                              aria-label={`"quantity " + ${selectedQuantity}`}
                              className="product-display-item-quantity-select form-control form-control-quantity"
                              type="number"
                              step="1"
                              min="1"
                              max="9999"
                              value={selectedQuantity}
                              onChange={this.handleInputQuantityChange}
                              onBlur={() => this.updateQuantity(false)}
                              disabled={isChangeDisabled}
                            />
                          </div>
                        </span>
                      ]
                    : ""}
                  {updateLoader && <div className="miniLoader" />}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-2 vertically-aligned-mobile">
            {/* eslint-disable-next-line no-nested-ternary */}
            {!inventoryError &&
            (isLoadingAvailability || updateLoader || availabilityLoader) ? (
              <>
                <div className="d-flex justify-content-end justify-content-lg-center">
                  <span className="label">
                    {intl.get("checking-availability")}
                  </span>
                </div>
                <div className="miniLoader" />
              </>
            ) : inventoryError ? (
              <div className="d-flex justify-content-end justify-content-lg-center">
                <span className="label">
                  {intl.get("available-for-back-order-label")}
                </span>
              </div>
            ) : (
              <div className="d-flex justify-content-end justify-content-lg-center">
                <span className="label">{itemAvailability}</span>
                {/* PGL-364: remove iventory icon */}
                {!isSpecialAirPurifier && (
                  <div className="info-popup-btn">
                    {openAvailabilityPopUp && (
                      <Popup
                        closePopUp={this.closeAvailabilityPopUpModal}
                        availabilityLabel={itemAvailability}
                        isCallButtonActive={false}
                        popupStyle={cartPopupStyle}
                        page={cartPage}
                      />
                    )}
                    <InfoIcon
                      className="info-icon"
                      onClick={this.openAvailabilityPopUpModal}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {Config.calculatePrice ? (
            <div className="col-12 col-lg">
              <div className="d-flex justify-content-end justify-content-lg-between">
                <div className="d-inline-block d-md-none mr-2">
                  {`${intl.get("ext-price")}: `}
                </div>
                {this.renderTotalPrice()}
                {this.renderCloseButton()}
              </div>
            </div>
          ) : (
            <div className="col-12 col-lg-1">{this.renderCloseButton()}</div>
          )}
          <p className="warning-text">{item.warrningMessage}</p>
          <p className="warning-text">{item.error}</p>
        </div>
      </>
    );
  }
}

export default withRouter(CartLineItem);
