/**
 * Refactored component from productdisplayitem.main.tsx. This component handles displaying the product
 * long description.
 */
import React, { FC } from "react";
import intl from "react-intl-universal";

// Types
import { ItemDescription } from "../productdisplayitem.interfaces";
import { ReactComponent as WarningIcon } from "../../../../app/src/images/icons/warning.svg";

interface ProductLongDescriptionProps {
  productData: any;
}

const ProductLongDescription: FC<ProductLongDescriptionProps> = ({
  productData
}) => {
  if (productData._definition && productData._definition[0].details) {
    const prop65requiredAttr = productData._definition[0].details.find(
      detail => detail.name.toLowerCase() === "prop_65_label_required"
    );
    const desc = productData._definition[0].details.find(
      (item: ItemDescription) => item.name === "long_description"
    );
    const prop65required: boolean =
      prop65requiredAttr && prop65requiredAttr.value === "TRUE";

    return (
      <ul className="itemdetail-availability-container">
        <li>
          <h4 className="bullet override-h2-as-h4">
            {`${intl.get("product-description")}`}
          </h4>
          <span className="itemdetail-attribute-value-col">
            {desc && desc["display-value"]}
          </span>
          {prop65required && (
            <div className="prop-65-warning">
              <WarningIcon className="prop-65-warning-icon" />
              <span>{`${intl.get("prop-65-warning")}. `}</span>
              <a
                href="https://partnerlinkmarketing.goodmanmfg.com/goodman/service-technical-tools"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.get("prop-65-learn-more")}
              </a>
            </div>
          )}
        </li>
      </ul>
    );
  }
  return null;
};

export default ProductLongDescription;
