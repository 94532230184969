/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import queryString from "query-string";
import {
  loginRegisteredAuthService,
  getConfig
} from "@zilker/store-components";
import { discoverOIDCParameters } from "@zilker/store-components/src/utils/AuthService";
import { useMainContext } from "../contexts/MainContext";
import { pushToMaintenace } from "../utils/helpers";

interface OidcParameters {
  clientId: string;
  scopes: string;
  authorizationEndpoint: string;
  endSessionEndpoint: string;
}

export const LoginRedirectPage: FC<RouteComponentProps> = props => {
  const mainContext = useMainContext();

  const { config } = getConfig();

  const {
    auth: { setTempSsoTokens },
    modal: { setShowSsoModal }
  } = mainContext;

  useEffect(() => {
    const locationData = window.location.search;
    const url = locationData;
    const params = queryString.parse(url);
    if (
      config.b2b.openId &&
      config.b2b.openId.enable &&
      params.code &&
      params.session_state
    ) {
      localStorage.setItem(
        `${config.cortexApi.scope}_openIdcCode`,
        params.code
      );
      localStorage.setItem(
        `${config.cortexApi.scope}_openIdcSessionState`,
        params.session_state
      );
      localStorage.removeItem("OidcSecret");
    }

    // generate unique, random values of 13 digits to enable BR click-tracking
    localStorage.setItem(
      "request_id",
      `${Math.floor(Math.random() * 10000000000000)}`
    );

    discoverOIDCParameters().then((oidcParameters: OidcParameters) => {
      const { history } = props;
      const redirectUri = encodeURIComponent(
        config.b2b.openId && config.b2b.openId.enable
          ? `${config.b2b.openId.callbackUrl}/loggedin`
          : config.b2b.keycloak.callbackUrl
      );
      const clientId = encodeURIComponent(
        config.b2b.openId && config.b2b.openId.enable
          ? oidcParameters.clientId
          : config.b2b.keycloak.client_id
      );
      loginRegisteredAuthService(params.code, redirectUri, clientId)
        .then(res => res.json())
        .then(response => {
          const tempTokens = {
            _oAuthRole: "REGISTERED",
            _oAuthScope: config.cortexApi.scope,
            _oAuthTokenAuthService: `Bearer ${response.access_token}`,
            _oAuthUserName: clientId
          };
          setTempSsoTokens(prevState => {
            return { ...prevState, ...tempTokens };
          });
          history.push("/");
          setShowSsoModal(true);
        })
        .catch(e => {
          pushToMaintenace(history, {
            e,
            errIn: "loginRegisteredAuthService => LoginRedirectPage.tsx"
          });
        });
    });
  }, []);
  return <div className="loader" />;
};

export default withRouter(LoginRedirectPage);
