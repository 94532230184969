import React, { FC, useState, useContext, useEffect } from "react";
import intl from "react-intl-universal";

import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { getConfig } from "@zilker/store-components";
import {
  extractPhoneAndFax,
  InventoryAvailabilityInterface,
  handleCustomException,
  formatAlternateBranchesInventory,
  formatInventoryAvailability,
  formatNumberValue,
  formatDGAInventoryList
} from "@elasticpath/ref-store/src/utils/helpers";
import {
  getAvailabilityDGA,
  getAvailability
} from "../../../app/src/services/connectServices";

import "./AlternateBranchList.less";

interface BranchListProps {
  orderInfo?: {
    orderName: string;
    orderItemsLength: number;
  };
  product?: { sku: string; qtyEntered: number; qtyAvailable: number };
  products?: Array<{ sku: string; qtyEntered: number; qtyAvailable: number }>;
  qtyColumnHeader: string;
  history: any;
  branches: any;
  showCustomErrorMessage?: boolean;
  descriptiveAvailability?: boolean;
  currentBranchesInventory?: Array<{
    sku: string;
    branchNumber: number;
    quantityAvailable: number;
  }>;
  itemQty?: number;
}

const AlternateBranchList: FC<BranchListProps> = props => {
  const {
    product,
    orderInfo,
    qtyColumnHeader,
    branches,
    descriptiveAvailability,
    itemQty
  } = props;

  const { config } = getConfig();
  const { defaultChannel } = config.brXM;
  const { alternateBranchesModalPageSize } = config;
  const isMotili = defaultChannel === "motili";

  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(alternateBranchesModalPageSize);
  const [filterByAvailability, setFilterByAvailability] = useState<boolean>(
    false
  );
  const [memberBranchInventory, setMemberBranchInventory] = useState<
    Array<{
      sku: string;
      branchNumber: number;
      quantityAvailable: number;
    }>
  >([]);
  const [noAvailableBranches, setNoAvailableBranches] = useState<boolean>(
    false
  );
  const [branchAvailability, setBranchAvailability] = useState(null);
  const [inventoryError, setInventoryError] = useState<string>("");
  const [availabilityCheckDone, setAvailabilityCheckDone] = useState<boolean>(
    false
  );
  const [availabilityLoading, setAvailabilityLoading] = useState<boolean>(
    false
  );
  const [alternateBranchList, setAlternateBranchList] = useState([]);

  const context = useContext<{
    auth: any;
    user: any;
    branches: any;
    cart: any;
  }>(MainContext);

  const insufficientStock: boolean = branchAvailability
    ? branchAvailability === 0 || itemQty > branchAvailability
    : false;

  const shouldRenderCheckbox = branches && defaultChannel !== "motili";

  const onAvailabilityFilter = (event): void => {
    const { checked } = event.target;
    setFilterByAvailability(checked);
  };

  const handleAvailabilityError = error => {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      auth: { logout }
    } = context;
    const { history } = props;

    const {
      cortexApi: { scope }
    } = config;
    const { selectedBranch } = defaultCart;

    if (selectedBranch.vendor !== "GOODMAN" && scope === "motili") {
      /* See DGE-3133. This is related only to Motili store, if the distributor branch
      is other than "GOODMAN".
    */
      setNoAvailableBranches(true);
    }
    setAvailabilityCheckDone(true);
    const errorPath =
      "validateAvailability => getAvailability => AlternateBranchList.tsx";
    handleCustomException(error, logout, history, errorPath);
  };

  const handleDGAInventory = (sku: string, alternateBranches) => {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      user: {
        userProfile: { customerNumber }
      }
    } = context;
    const { selectedBranch } = defaultCart;

    const branchNumber = selectedBranch.code;
    const needsDetails = true;
    setAvailabilityLoading(true);

    getAvailabilityDGA(
      customerNumber,
      [sku],
      branchNumber,
      needsDetails,
      limit,
      skip
    )
      .then(inventoryResponse => {
        if (
          inventoryResponse.data.error ||
          !inventoryResponse.data.result.allBranches
        ) {
          console.error("Inventory error");
          setInventoryError(intl.get("inventory-error"));
          setAvailabilityCheckDone(true);
          setAvailabilityLoading(false);
        } else {
          const inventoryAvailability = formatDGAInventoryList(
            inventoryResponse.data.result
          );
          const branchInventory = inventoryResponse.data.result.allBranches.map(
            branch => {
              return {
                sku: branch.items.length ? branch.items[0].sku : sku,
                branchNumber: branch.branchNumber,
                quantityAvailable: branch.items.length
                  ? branch.items[0].qtyAvailable
                  : 0
              };
            }
          );

          const inventoryItem = inventoryAvailability.length
            ? inventoryAvailability[0]
            : {
                branchAvailability: 0,
                regionAvailability: 0,
                unitOfMeasure: 1
              };
          setMemberBranchInventory(prevState => {
            const updatedState = [...prevState];
            return updatedState.concat(branchInventory);
          });
          setNoAvailableBranches(!alternateBranches);
          setBranchAvailability(inventoryItem.branchAvailability);
          setAvailabilityCheckDone(true);
          setAvailabilityLoading(false);
          setInventoryError("");
        }
      })
      .catch(error => {
        console.error("Inventory error", error);
        setInventoryError(intl.get("inventory-error"));
        handleAvailabilityError(error);
      });
  };

  const handleDGABranches = (sku: string, alternateBranches) => {
    handleDGAInventory(sku, alternateBranches);
  };

  const formatAvailability = branch => {
    const { products } = props;

    let availability;
    let availabilityMotili = "0";

    if (descriptiveAvailability) {
      const availableLabel =
        defaultChannel === "motili"
          ? intl.get("in-stock")
          : intl.get("available-label");
      const notAvailableLabel =
        defaultChannel === "motili"
          ? intl.get("not-in-stock")
          : intl.get("available-for-back-order-label");
      availability =
        branch &&
        branch.items.length &&
        (orderInfo && branch.items.length === orderInfo.orderItemsLength) &&
        branch.items.every(item => {
          const vendorSku =
            defaultChannel === "motili" ? item.vendorSku : item.sku;
          const branchItem = products.find(({ sku }) => sku === vendorSku);
          return item.qtyAvailable >= branchItem.qtyEntered;
        })
          ? availableLabel
          : notAvailableLabel;
      return availability;
    }
    if (defaultChannel === "motili") {
      availabilityMotili =
        branch.items && branch.items.length
          ? branch.items.reduce(
              (total, item) => total + formatNumberValue(item.qtyAvailable),
              0
            )
          : "0";
      return availabilityMotili;
    }
    if (memberBranchInventory || memberBranchInventory.length) {
      const foundBranch = memberBranchInventory.find(currentBranch => {
        return currentBranch.branchNumber === branch.branchNumber;
      });
      if (foundBranch) {
        if (foundBranch.quantityAvailable - itemQty >= 0) {
          if (foundBranch.quantityAvailable - itemQty >= 5) {
            availability = intl.get("available-label");
          } else {
            availability = intl.get("limited-availability");
          }
        } else {
          availability = intl.get("available-for-back-order-label");
        }
      } else {
        return null;
      }
    }
    return availability;
  };

  /**
   * ## renderHomeBranchLabel
   *
   * @remarks Renders small label-like
   * span next to the branch name and number, if the
   * given branch is a home branch of the user.
   */

  const renderHomeBranchLabel = () => {
    return (
      <span className="home-branch-label">
        <i className="icon-home" />
        <span>{`${intl.get("home")} ${intl.get("branch")}`}</span>
      </span>
    );
  };

  /**
   * ## renderBranchItem
   *
   * @param branch BranchDetails
   *
   * @remarks Renders the single list item that displays the quantity for the
   * product in warehouses(branhces) and distance.
   *
   * @returns JSX.Element
   */

  const renderBranchItem = branch => {
    const {
      user: { userProfile }
    } = context;

    const { homeBranch, subuserHomeBranch, isCanadianUser } = userProfile;

    const inventoryAvailable = formatAvailability(branch);
    const shouldRenderItem =
      inventoryAvailable !== null &&
      (!filterByAvailability ||
        (filterByAvailability &&
          inventoryAvailable !== "Available for Back Order"));

    const { distance } = branch;

    let distanceRounded;
    // Convert distance to kilometers if user is from Canada
    // 1mile = 1.609344km
    if (!isCanadianUser) {
      distanceRounded =
        typeof distance === "number"
          ? `${distance.toFixed(2)} mi`
          : `${distance} mi`;
    } else {
      distanceRounded =
        typeof distance === "number"
          ? `${(distance * 1.609344).toFixed(2)} km`
          : `${distance} km`;
    }

    return shouldRenderItem ? (
      <li
        className="alternate-branches-modal-list-item"
        key={`alternate-branch-${branch.branchName}`}
      >
        <div className="branch-item-product-quantity">
          <span className="branch-item-product-quantity-mobile">
            {`${qtyColumnHeader}: `}
          </span>
          <span className="branch-item-product-availability">
            {inventoryAvailable}
          </span>
        </div>
        <div className="branch-item-details">
          <p>
            {branch.branchName}
            {(subuserHomeBranch
            ? branch.branchNumber === subuserHomeBranch
            : branch.branchNumber === homeBranch)
              ? renderHomeBranchLabel()
              : ""}
          </p>
          <p>{branch.formattedAddress}</p>

          <a
            className="show-on-mobile"
            href={`tel:${extractPhoneAndFax(branch).phone}`}
          >
            {`P: ${extractPhoneAndFax(branch).phone}`}
          </a>
          <span className="hide-on-mobile">
            {extractPhoneAndFax(branch).phone}
          </span>
          {branch.fax && <span>{` F: ${extractPhoneAndFax(branch).fax}`}</span>}
        </div>
        <div className="branch-item-distance">
          <span className="branch-item-distance-mobile">
            {`${intl.get("distance")}: `}
          </span>
          {distanceRounded}
        </div>
      </li>
    ) : null;
  };

  const onLoadMore = () => {
    setSkip(skip + alternateBranchesModalPageSize);
    setLimit(limit + alternateBranchesModalPageSize);
  };

  const branchesRendered = () => {
    const branchNumbersList = memberBranchInventory.map(
      branch => branch.branchNumber
    );
    const bracnhesInList = [];
    for (let i = 0; i < branchNumbersList.length; i++) {
      const branchItem = branches.find(
        branch => branch.branchNumber === branchNumbersList[i]
      );
      bracnhesInList.push(branchItem);
    }
    return bracnhesInList;
  };

  const renderBranchList = () => {
    const branchesListDGA = branchesRendered();

    // eslint-disable-next-line no-nested-ternary
    const renderBranches = descriptiveAvailability
      ? branches
      : isMotili
      ? alternateBranchList
      : branchesListDGA;

    const shouldRenderButton =
      renderBranches &&
      availabilityCheckDone &&
      branches.length !== renderBranches.length &&
      !isMotili;

    return (
      <div className="alternate-branches-modal-body">
        <ul>
          {renderBranches && availabilityCheckDone ? (
            renderBranches.map(branch => renderBranchItem(branch))
          ) : (
            <>
              {!noAvailableBranches || !memberBranchInventory.length ? (
                <div className="loader-container">
                  <h3>
                    <span>{intl.get("checking-availability")}</span>
                  </h3>
                  <div className="loader" />
                </div>
              ) : (
                // noAvailableBranches will only be true if error happens in Motili site, for non-goodman branch.
                <div className="custom-error-availability">
                  {intl.get("third-party-branch-availability-error")}
                </div>
              )}
            </>
          )}
          {shouldRenderButton ? (
            <div className="button-container">
              <div className="center-container">
                <button
                  type="button"
                  className="ep-btn secondary"
                  onClick={onLoadMore}
                  aria-label={intl.get("load-more")}
                >
                  {intl.get("load-more")}
                </button>
                {availabilityLoading && <div className="miniLoader" />}
              </div>
            </div>
          ) : null}
        </ul>
      </div>
    );
  };

  /**
   * ## validateAvailability
   * @param sku string
   *
   * @description Validates if item is available on current branch and in the region.
   */
  const validateAvailability = async (sku: string) => {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { branchesList, findBranch },
      user: {
        userProfile: { customerNumber }
      }
    } = context;
    const {
      cortexApi: { scope }
    } = config;

    if (defaultCart && branchesList && product) {
      const { selectedBranch, clientId } = defaultCart;
      const { latitude, longitude } = findBranch(selectedBranch.code);

      let inventoryAvailability: Array<InventoryAvailabilityInterface>;
      if (scope === "motili") {
        getAvailability(
          customerNumber,
          selectedBranch.code,
          sku,
          selectedBranch.vendor,
          latitude,
          longitude,
          true,
          clientId
        )
          .then(({ data }) => {
            const alternateBranches = formatAlternateBranchesInventory(data);
            inventoryAvailability = formatInventoryAvailability(data);
            const inventoryItem = inventoryAvailability.length
              ? inventoryAvailability[0]
              : {
                  branchAvailability: 0,
                  regionAvailability: 0,
                  unitOfMeasure: 1
                };
            setNoAvailableBranches(!alternateBranches);
            setBranchAvailability(inventoryItem.branchAvailability);
            setAvailabilityCheckDone(true);
            setAlternateBranchList(alternateBranches);
          })
          .catch(error => {
            handleAvailabilityError(error);
          });
      } else {
        handleDGABranches(product.sku, branchesList);
      }
    }
  };

  useEffect(() => {
    const {
      branches: { branchesList },
      cart: {
        cartDetails: { defaultCart }
      }
    } = context;
    if (defaultCart && branchesList && product) {
      validateAvailability(product.sku);
    } else {
      setAvailabilityCheckDone(true);
    }
  }, [skip]);

  const shouldRenderWarning = !memberBranchInventory.find(
    branch => branch.quantityAvailable !== 0
  );

  return (
    <div className="alternate-branches-modal-content">
      <div className="alternate-branches-modal-header">
        <div className="alternate-branches-modal-title">
          <h4>{intl.get("alternate-branches")}</h4>
          {insufficientStock && branches ? (
            <p>
              <i className="icon-info" />
              <span>{intl.get("insufficient-stock")}</span>
            </p>
          ) : (
            ""
          )}
          {filterByAvailability &&
            !inventoryError &&
            !isMotili &&
            shouldRenderWarning && (
              <p>
                <i className="icon-info" />
                <span>{intl.get("filtered-branches-warning")}</span>
              </p>
            )}
          {inventoryError && (
            <p>
              <i className="icon-info" />
              <span>{inventoryError}</span>
            </p>
          )}
        </div>
        <div className="alternate-branches-modal-details">
          {product && (
            <p>
              <span>{`${intl.get("product")}: `}</span>
              <span>{product.sku}</span>
            </p>
          )}
          {orderInfo && (
            <p>
              <span>{`${intl.get("order-number")}: `}</span>
              <span>{orderInfo.orderName}</span>
            </p>
          )}

          {shouldRenderCheckbox && (
            <div className="availability-label">
              <label id="availability-label" htmlFor="filterByAvailability">
                <input
                  name="filterByAvailability"
                  id="filterByAvailability"
                  type="checkbox"
                  checked={filterByAvailability}
                  onChange={onAvailabilityFilter}
                />
                <span>{intl.get("show-branches-with-stock")}</span>
              </label>
            </div>
          )}
          {product && (
            <p>
              <span>{`${intl.get("quantity-entered")}: `}</span>
              <span>{itemQty}</span>
            </p>
          )}
        </div>
      </div>
      <div className="alternate-branches-modal-branches-head">
        <span>{qtyColumnHeader}</span>
        <span>
          {`${intl.get("branch")} ${intl.get("name")}/ ${intl.get("address")}`}
        </span>
        <span>{intl.get("distance")}</span>
      </div>
      {renderBranchList()}
    </div>
  );
};

export default AlternateBranchList;
