import { useState, useEffect } from "react";
import { fetchAccount } from "../services/EpServices";
import * as LoginService from "../services/LoginService";

const initialUserAccount = {
  uri: "",
  isBuyerAdmin: "",
  legalName: "",
  name: "",
  registrationId: "",
  associates: "",
  primaryEmail: "",
  hasError: ""
};
// eslint-disable-next-line import/prefer-default-export
export const AccountContext = () => {
  const [accountDetails, setAccountDetails] = useState(initialUserAccount);
  const checkLogin = LoginService.isLoggedIn();

  useEffect(() => {
    if (checkLogin) {
      getUserAccount();
    }
  }, [checkLogin]);

  function getUserAccount() {
    fetchUserAccount(setAccountDetails);
  }

  function removeUserAccount() {
    setAccountDetails(initialUserAccount);
  }

  return {
    accountDetails,
    getUserAccount,
    removeUserAccount
  };
};

function fetchUserAccount(setAccountDetails) {
  fetchAccount()
    .then(res => {
      if (
        res &&
        res._accounts &&
        res._accounts.length &&
        res._myprofile &&
        res._myprofile.length
      ) {
        const accountPayload = res._accounts[0];
        const myProfile = res._myprofile[0];
        const userAccount = {
          uri: accountPayload._element[0].self.uri,
          isBuyerAdmin: !!accountPayload._element[0]._associateroleassignments,
          addAssociateUri:
            accountPayload._element[0]._associateroleassignments[0]
              ._associateform[0]._addassociateaction[0].self.uri,
          legalName: accountPayload._element[0]["legal-name"],
          name: accountPayload._element[0].name,
          registrationId: accountPayload._element[0]["registration-id"],
          associates:
            accountPayload._element[0]._associateroleassignments[0]._element,
          primaryEmail: myProfile._primaryemail[0].email
        };

        setAccountDetails(userAccount);
      }
    })
    .catch(error => {
      const accountDetails = initialUserAccount;
      accountDetails.hasError = error;

      setAccountDetails(accountDetails);
    });
}
