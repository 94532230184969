import React, { FC } from "react";
import {
  CmsEditButton,
  getNestedObject
} from "bloomreach-experience-react-sdk";

interface MenuEditButtonProps {
  configuration: any;
  preview: any;
}

const MenuEditButton: FC<MenuEditButtonProps> = ({
  configuration,
  preview
}) => {
  if (!getNestedObject(configuration, ["models", "menu", "siteMenuItems", 0])) {
    return null;
  }

  const menuConfiguration = getNestedObject(configuration, ["models", "menu"]);
  const editButton = preview ? (
    <CmsEditButton configuration={menuConfiguration} preview={preview} />
  ) : null;

  return <>{editButton && editButton}</>;
};

export default MenuEditButton;
