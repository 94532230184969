/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { FC } from "react";
import "./cartpopup.less";
import { Link } from "react-router-dom";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

let Config: IEpConfig | any = {};
let intl = { get: (str, prop?) => str };

interface CartPopUpProps {
  appHeaderLinks: any;
  cartData: any;
}

const CartPopUp: FC<CartPopUpProps> = props => {
  const epConfig = getConfig();
  Config = epConfig.config;
  ({ intl } = epConfig);

  const { appHeaderLinks, cartData } = props;
  const { currentCount, setCurrentCount } = cartData;

  const closePopUp = () => {
    setCurrentCount({ show: false });
  };

  const message = (
    <div className="multi-cart-message">
      <p>{currentCount.message}</p>
    </div>
  );
  return (
    // eslint-disable-next-line react/jsx-curly-brace-presence
    <div className="cart-nav-container">
      {currentCount.type === "error" && (
        <button className="close-popup-btn" type="button" onClick={closePopUp}>
          <i className="icon-close-x" />
        </button>
      )}
      <div className="multi-cart-menu">{message}</div>
      {currentCount.type === "success" && (
        <div className="checkout-btn-container">
          <Link
            className="ep-btn primary checkout-btn link-to-cart"
            to={{
              pathname: appHeaderLinks.myCart,
              state: { prevUrl: window.location.href }
            }}
            aria-label={intl.get("view-your-carts")}
            onClick={closePopUp}
          >
            {intl.get("view-your-carts")}
          </Link>
        </div>
      )}
    </div>
  );
};

export default CartPopUp;
