import React, { useContext, useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import intl from "react-intl-universal";
import {
  checkResponse,
  checkTokensExpired,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import base32 from "../../../app/src/utils/base32/base32";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { cortexFetch } from "../utils/Cortex";
import {
  changeBranchOnCurrentOrder,
  changeBranchAndVendorOnCurrentOrder
} from "../../../app/src/services/EpServices";

import "./BranchDropdownComponent.less";

interface BranchDropdownProps extends RouteComponentProps {
  history: any;
  location: any;
  match: any;
  selectedBranch: string;
  isMobile?: boolean;
  displayName?: boolean;
}

const BranchDropdownComponent: React.FC<BranchDropdownProps> = ({
  history,
  location,
  match,
  selectedBranch,
  isMobile = false,
  displayName = false
}) => {
  const [selectedBranchNumber, setSelectedBranchNumber] = useState(
    selectedBranch
  );
  const [updateLoader, setUpdateLoader] = useState(false);

  const mainContext = useContext<{
    auth: any;
    user: any;
    cart: any;
    branches: any;
  }>(MainContext);

  const {
    user: {
      userProfile: { customerRoles }
    },
    cart: {
      cartDetails: { defaultCart },
      getCartDetails
    },
    auth: { logout },
    branches: { branchesList, findBranch }
  } = mainContext;
  const { cartOrderDetailsForm, items } = defaultCart;

  const isCartAssociatedToContract =
    items &&
    items.length &&
    cartOrderDetailsForm["contract-number"] &&
    cartOrderDetailsForm.pricing.toUpperCase() === "N";

  const BRANCHES_VIRTUAL = intl.get("virtual-branches");
  const isVirtualBranchUser =
    customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

  const { url } = match;
  const { pathname } = location;
  const { config }: { config: IEpConfig } = getConfig();

  useEffect(() => {
    if (
      config.branchDropDownLocation === "header" &&
      branchesList &&
      branchesList.length &&
      selectedBranchNumber !== defaultCart.selectedBranch.code
    ) {
      changeBranch();
    }
  }, [selectedBranchNumber]);

  useEffect(() => {
    setSelectedBranchNumber(defaultCart.selectedBranch.code);
  }, [defaultCart.selectedBranch.code]);

  const changeBranch = () => {
    const branch = findBranch(selectedBranchNumber);
    const fetchLink = cartOrderDetailsForm.links[0].uri;

    changeBranchAndVendorOnCurrentOrder(branch, fetchLink)
      .then(() => {
        sessionStorage.setItem("notified-branch", "true");
        return getCartDetails();
      })
      .then(() => {
        setUpdateLoader(false);
      })
      .catch((e: any) => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => changeBranch => BranchDropdownComponent.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "changeBranch => BranchDropdownComponent.tsx"
          });
        }
      });
  };

  const onBranchSelection = e => {
    const { value: branchNumber } = e.target;
    setSelectedBranchNumber(branchNumber);
  };

  if (branchesList && branchesList.length && !isVirtualBranchUser) {
    return (
      <div className="branch-dropdown">
        {isMobile ? <span>{intl.get("branch")}: </span> : null}
        <select
          name="branch-dropdown"
          id="branch-dropdown"
          onChange={onBranchSelection}
          value={selectedBranchNumber}
          disabled={
            url.includes("purchaseReceipt") ||
            pathname === "/order" ||
            isCartAssociatedToContract
          }
          aria-label={intl.get("select-branch")}
        >
          {branchesList.map(branch => (
            <option
              className="branch-dropdown-option"
              key={branch.branchNumber}
              value={branch.branchNumber}
            >
              {branch.branchName}
            </option>
          ))}
        </select>
        {config.branchDropDownLocation === "plpLeft" &&
          !isMobile &&
          (!updateLoader ? (
            <button
              type="button"
              className="update-btn ep-btn primary"
              onClick={changeBranch}
            >
              {intl.get("update")}
            </button>
          ) : (
            <div className="miniLoader" />
          ))}
      </div>
    );
  }

  return null;
};
export default withRouter(BranchDropdownComponent);
