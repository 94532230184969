/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import * as React from "react";
import Modal from "react-responsive-modal";
import {
  checkTokensExpired,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { adminFetch } from "../utils/Cortex";
import { login } from "../utils/AuthService";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

import "../B2bEditAccount/b2b.editaccount.less";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface B2bAddSubAccountProps {
  isOpen: boolean;
  handleClose: () => void;
  handleUpdate: () => void;
  addSubAccountUri: string;
  addSubAccountSellerAdmin: boolean;
  history: any;
  auth: any;
}

interface B2bAddSubAccountState {
  name: string;
  legalName: string;
  externalId: string;
  registrationNumber: string;
  isLoading: boolean;
}

export default class B2bAddSubAccount extends React.Component<
  B2bAddSubAccountProps,
  B2bAddSubAccountState
> {
  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = epConfig);
    this.state = {
      name: "",
      legalName: "",
      externalId: "",
      registrationNumber: "",
      isLoading: false
    };

    this.addSubAccount = this.addSubAccount.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  addSubAccount(event) {
    const {
      addSubAccountUri,
      handleClose,
      handleUpdate,
      history,
      auth: { logout }
    } = this.props;
    const { name, legalName, externalId, registrationNumber } = this.state;

    event.preventDefault();
    this.setState({ isLoading: true });
    login().then(() => {
      adminFetch(
        `${addSubAccountUri}?followlocation&format=standardlinks,zoom.nodatalinks`,
        {
          method: "post",
          body: JSON.stringify({
            name,
            "external-id": externalId,
            "legal-name": legalName,
            "registration-id": registrationNumber
          })
        }
      )
        .then(() => {
          handleClose();
          handleUpdate();
          this.setState({ isLoading: false });
        })
        .catch(e => {
          this.setState({ isLoading: false });
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => addSubAccount => B2bAddSubAccount.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "addSubAccount => B2bAddSubAccount.tsx"
            });
          }
        });
    });
  }

  changeHandler(event) {
    const { name } = event.target;
    const { value } = event.target;

    // @ts-ignore
    this.setState({ [name]: value });
  }

  render() {
    const { isOpen, handleClose, addSubAccountSellerAdmin } = this.props;
    const {
      name,
      legalName,
      externalId,
      registrationNumber,
      isLoading
    } = this.state;

    return (
      <Modal
        open={isOpen}
        onClose={handleClose}
        classNames={{
          modal: "b2b-edit-account-dialog",
          closeButton: "b2b-dialog-close-btn"
        }}
      >
        <div className="dialog-header">{intl.get("add-sub-account")}</div>
        <div className="dialog-content">
          <form onSubmit={this.addSubAccount} id="subAccountsForm">
            <div className="b2b-form-row">
              <label htmlFor="name" className="b2b-form-col">
                <p className="b2b-dark-text">{intl.get("name")}</p>
                <input
                  id="name"
                  className="b2b-input"
                  value={name || ""}
                  onChange={this.changeHandler}
                  name="name"
                  type="text"
                />
              </label>
              <label htmlFor="legal-name" className="b2b-form-col">
                <p className="b2b-dark-text">{intl.get("legal-name")}</p>
                <input
                  id="legal-name"
                  className="b2b-input"
                  value={legalName || ""}
                  onChange={this.changeHandler}
                  name="legalName"
                  type="text"
                />
              </label>
            </div>
            <div className="b2b-form-row">
              <label htmlFor="registration-number" className="b2b-form-col">
                <p className="b2b-dark-text">
                  {intl.get("registration-number")}
                </p>
                <input
                  id="registration-number"
                  className="b2b-input"
                  value={registrationNumber || ""}
                  onChange={this.changeHandler}
                  name="registrationNumber"
                  type="text"
                />
              </label>
              {addSubAccountSellerAdmin && (
                <label htmlFor="external-id" className="b2b-form-col">
                  <p className="b2b-dark-text">{intl.get("external-id")}</p>
                  <input
                    id="external-id"
                    className="b2b-input"
                    value={externalId || ""}
                    onChange={this.changeHandler}
                    name="externalId"
                    type="text"
                  />
                </label>
              )}
            </div>
          </form>
        </div>
        <div className="dialog-footer">
          <button className="cancel" type="button" onClick={handleClose}>
            {intl.get("cancel")}
          </button>
          <button
            className="save"
            type="submit"
            disabled={isLoading}
            form="subAccountsForm"
          >
            {intl.get("save")}
          </button>
        </div>
      </Modal>
    );
  }
}
