/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import uuidv4 from "uuid/v4";
import { Link, Redirect } from "react-router-dom";
import queryString from "query-string";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { zoomResetPassword } from "@zilker/store-components/src/static/zoom";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
  // DGE-2944 - methods for reading/ writing to session storage.
  // setLastPageVisited,
  // hasNavigationHappened,
  // getLastPageVisited
} from "@elasticpath/ref-store/src/utils/helpers";
import { changeBranchAndVendorOnCurrentOrder } from "@elasticpath/ref-store/src/services/EpServices";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import AppModalCartSelectMain from "../AppModalCartSelect/appmodalcartselect.main";
import AppHeaderJobSelect from "../AppHeaderJobSelect/appheaderjobselect.main";
import AppModalClientSelect from "../AppModalClientSelect/appmodalclientselect";
import AppModalWarning from "../AppModalWarning/appmodalwarning.main";
import AppModalPhoneUpdate from "../AppModalPhoneUpdate/appmodalphoneupdate";
// DGE-2944 - PopUp that displays the link with last visited page.
// import ErrorPopUp from "../ErrorPopUp/errorpopup";
import { getAccessToken, discoverOIDCParameters } from "../utils/AuthService";
import { cortexFetch, adminFetch } from "../utils/Cortex";

import "./appheaderlogin.main.less";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface AppHeaderLoginMainProps {
  history: any;
  isMobileView?: boolean;
  permission: boolean;
  onLogout?: (...args: any[]) => any;
  onLogin?: (...args: any[]) => any;
  onContinueCart?: (...args: any[]) => any;
  fetchCartData?: (...args: any[]) => any;
  locationSearchData?: string;
  appHeaderLoginLinks: {
    [key: string]: any;
  };
  appModalLoginLinks: {
    [key: string]: any;
  };
  isLoggedIn: boolean;
  disableLogin?: boolean;
  descriptiveButtonText?: string;
}
interface AppHeaderLoginMainState {
  openCartModal: boolean;
  openJobModal: boolean;
  showForgotPasswordLink: boolean;
  accountData: any;
  loginUrlAddress: string;
  oidcParameters: any;
  ssoLoader: boolean;
  error: string;
  // DGE-2944
  // hasLogedIn: boolean;
  openClientModal: boolean;
}
interface OidcParameters {
  clientId: string;
  scopes: string;
  authorizationEndpoint: string;
  endSessionEndpoint: string;
}

const AccountIcon = props => {
  const { className, title } = props;
  return (
    <svg
      className={className}
      width="160px"
      height="160px"
      viewBox="0 0 160 160"
      version="1.1"
    >
      <title>{title}</title>
      <g id="Page-1" stroke="none" strokeWidth="1">
        <path
          // eslint-disable-next-line max-len
          d="M80,160 C35.81722,160 0,124.18278 0,80 C0,35.81722 35.81722,0 80,0 C124.18278,0 160,35.81722 160,80 C160,124.18278 124.18278,160 80,160 Z M80,72 C93.254834,72 104,61.254834 104,48 C104,34.745166 93.254834,24 80,24 C66.745166,24 56,34.745166 56,48 C56,61.254834 66.745166,72 80,72 Z M79.9905519,138 C79.9937012,138 79.9968505,138 80,138 C80.0031495,138 80.0062988,138 80.0094481,137.999999 C99.9612145,137.996819 117.559091,127.919449 127.993289,112.577683 C127.560402,98.965365 106.238855,88 80,88 C53.7611445,88 32.4395976,98.965365 32.0067107,112.577679 C42.4409087,127.919449 60.0387855,137.996819 79.9905519,137.999999 Z"
          id="Combined-Shape"
        />
      </g>
    </svg>
  );
};

class AppHeaderLoginMain extends React.Component<
  AppHeaderLoginMainProps,
  AppHeaderLoginMainState,
  OidcParameters
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = epConfig);

    this.state = {
      openCartModal: false,
      openJobModal: false,
      showForgotPasswordLink: false,
      accountData: undefined,
      oidcParameters: {},
      loginUrlAddress: "",
      ssoLoader: false,
      // DGE-2944 - Flag to signalize initial login event.
      // hasLogedIn: false
      error: "",
      openClientModal: false
    };

    this.handleCartModalClose = this.handleCartModalClose.bind(this);
    this.getAccountData = this.getAccountData.bind(this);
    this.logoutRegisteredUser = this.logoutRegisteredUser.bind(this);
    this.handleCartModalOpen = this.handleCartModalOpen.bind(this);
  }

  componentDidMount() {
    const { locationSearchData } = this.props;
    const url = locationSearchData;
    const params = queryString.parse(url);

    if (params.userId && params.role && params.token) {
      this.impersonate(params);
    } else if (params.role && params.token) {
      this.logoutRegisteredUser();
    }
    if (Config.b2b.openId && Config.b2b.openId.enable) {
      this.login();
    }
  }

  componentDidUpdate() // DGE-2944 - parameters used for checking if user has just logged in.
  // prevProps, prevState
  {
    const { history } = this.props;
    // DGE-2944
    // const { isLoggedIn: prevPropsIsLoggedIn } = prevProps;
    // const { hasLogedIn: prevHasLoggedIn } = prevState;
    try {
      const { loginUrlAddress, ssoLoader, openCartModal } = this.state;
      const {
        context: {
          modal: { showSsoModal },
          auth: {
            tempSsoTokens: { _oAuthTokenAuthService },
            isLoggedIn
          }
        }
      } = this;

      this.shouldOpenJobModal();
      this.shouldOpenClientModal();
      this.shouldOpenWarningModal();

      /* DGE-2944 - Checks prevent showing popup on each page re-render. The pop up
      should be visible, only when the user logs in initially.

      if (prevPropsIsLoggedIn === false && isLoggedIn) {
        this.onUserLogIn();
      }

      if (prevHasLoggedIn && window.location.pathname !== "/") {
        this.setHasLoggedIn(false);
      }
      */
      if (showSsoModal && !ssoLoader) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ ssoLoader: true });
      }

      if (
        Config.b2b.enable &&
        _oAuthTokenAuthService !== null &&
        localStorage.getItem(`${Config.cortexApi.scope}_b2bCart`) === null &&
        showSsoModal &&
        loginUrlAddress &&
        ssoLoader &&
        !openCartModal &&
        !isLoggedIn
      ) {
        const {
          context: {
            modal: { setShowSsoModal }
          }
        } = this;
        setShowSsoModal(false);
        this.handleCartModalOpen();
      }
    } catch (e) {
      pushToMaintenace(history, {
        e,
        errIn: "componentDidUpdate => AppHeaderLoginMain.tsx"
      });
    }
  }

  /* DGE-2944 
  setHasLoggedIn(hasLogedIn: boolean): void {
    this.setState({ hasLogedIn });
  }

  onUserLogIn(): void {
    if (hasNavigationHappened() && getLastPageVisited()) {
      this.setHasLoggedIn(true);
    }
  }
  */

  shouldOpenJobModal() {
    const { openJobModal } = this.state;
    const {
      context: {
        user: { userProfile },
        cart: {
          cartDetails: { defaultCart }
        }
      }
    } = this;
    const { jobsArray } = userProfile;

    if (
      jobsArray &&
      jobsArray.length > 1 &&
      !sessionStorage.getItem("jobChosen") &&
      !openJobModal &&
      defaultCart
    ) {
      this.setState({ openJobModal: true });
    }
  }

  shouldOpenClientModal() {
    const {
      user: { userProfile },
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;
    const { clientList } = userProfile;

    const { openClientModal } = this.state;

    if (
      clientList &&
      clientList.length > 1 &&
      !sessionStorage.getItem("clientChosen") &&
      !openClientModal &&
      defaultCart
    ) {
      this.setState({ openClientModal: true });
    }
  }

  shouldOpenWarningModal() {
    let mismatch;
    let isJobAssociated = false;
    const { openCartModal, error } = this.state;
    const { user, cart, modal, branches, auth } = this.context;
    const { userProfile } = user;
    const {
      cartDetails: { defaultCart },
      getCartDetails
    } = cart;
    const { branchesList, findBranch } = branches;
    const { homeBranch, subuserHomeBranch } = userProfile;
    const { logout } = auth;
    const { history } = this.props;

    const { warningModal, setWarningModal } = modal;
    if (defaultCart && (subuserHomeBranch || homeBranch) && branchesList) {
      const { selectedBranch, jobNumber, cartOrderDetailsForm } = defaultCart;
      const selectedBranchDetails = findBranch(selectedBranch.code);

      if (!selectedBranchDetails) {
        // Current branch does no longer exist in the list of entitled branches
        // We need to set current branch to the home branch
        const homeBranchDetails = findBranch(subuserHomeBranch || homeBranch);

        // Home branch does no longer exist in the list of entitled branches
        if (!homeBranchDetails) {
          if (!error) {
            this.setState({
              error: intl.get("unentitled-home-branch")
            });
          }
          return;
        }

        changeBranchAndVendorOnCurrentOrder(
          homeBranchDetails,
          cartOrderDetailsForm.links[0].uri
        )
          .then(() => getCartDetails())
          .catch((e: any) => {
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn:
                    "Logout => shouldOpenWarningModal => AppHeaderLoginMain.tsx"
                })
              );
            } else {
              pushToMaintenace(history, {
                e,
                errIn: "shouldOpenWarningModal => AppHeaderLoginMain.tsx"
              });
            }
          });
      } else {
        isJobAssociated = !!jobNumber;
        mismatch = subuserHomeBranch
          ? subuserHomeBranch !== selectedBranch.code
          : homeBranch !== selectedBranch.code;

        if (
          Config.showHomeBranchWarning &&
          mismatch &&
          !warningModal.openModal &&
          !sessionStorage.getItem("notified-branch") &&
          !openCartModal
        ) {
          setWarningModal({
            openModal: true,
            modalMessage: intl.get("branch-mismatch-notice")
          });
        }
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async login() {
    const { history } = this.props;
    try {
      const oidcSecret = uuidv4();
      localStorage.setItem("OidcSecret", oidcSecret);
      const oidcParameters: OidcParameters = await discoverOIDCParameters();
      const oidcStateObject = {
        secret: oidcSecret,
        pathname: window.location.pathname
      };

      const oidcStateEncoded = btoa(JSON.stringify(oidcStateObject));
      const redirectUrl = `${Config.b2b.openId.callbackUrl}/loggedin`;

      const query = [
        `scope=${encodeURIComponent(oidcParameters.scopes)}`,
        "response_type=code",
        `client_id=${encodeURIComponent(oidcParameters.clientId)}`,
        `redirect_uri=${encodeURIComponent(redirectUrl)}`,
        `state=${oidcStateEncoded}`
      ].join("&");
      const loginUrl = `${oidcParameters.authorizationEndpoint}?${query}`;

      this.setState({
        loginUrlAddress: loginUrl,
        oidcParameters
      });
    } catch (e) {
      pushToMaintenace(history, {
        e,
        errIn: "login => AppHeaderLoginMain.tsx"
      });
    }
  }

  logoutRegisteredUser() {
    const { history } = this.props;
    const {
      context: {
        auth: { logout },
        user
      }
    } = this;
    user.removeUserProfile();
    logout().catch(err =>
      pushToMaintenace(history, {
        e: err,
        errIn: "Logout => logoutRegisteredUser => AppHeaderLoginMain.tsx"
      })
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleCartModalOpen() {
    this.setState({
      openCartModal: true
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleCartModalClose() {
    this.setState({
      openCartModal: false
    });
  }

  ssoCloseButton = () => {
    const {
      context: {
        auth: { logout }
      }
    } = this;
    const { history } = this.props;
    logout(true).catch(err =>
      pushToMaintenace(history, {
        e: err,
        errIn: "Logout => ssoCloseButton => AppHeaderLoginMain.tsx"
      })
    );
  };

  closeSsoLoader = () => {
    this.setState({
      ssoLoader: false
    });
  };

  // eslint-disable-next-line class-methods-use-this
  getAccountData() {
    const {
      context: {
        auth: {
          tempSsoTokens: { _oAuthTokenAuthService },
          logout
        }
      },
      props: { history }
    } = this;

    const headers = {
      "Content-Type": "application/json",
      Authorization: _oAuthTokenAuthService
    };

    return adminFetch("/?zoom=accounts", { headers })
      .then(res => checkResponse(res))
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => getAccountData => AppHeaderLoginMain.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "getAccountData => AppHeaderLoginMain.tsx"
          });
        }
      });
  }

  impersonate(params) {
    const {
      context: {
        auth: { isLoggedIn }
      }
    } = this;
    const { onLogin } = this.props;
    this.logoutRegisteredUser();
    if (isLoggedIn) {
      getAccessToken(params.token).then(res => {
        localStorage.setItem(
          `${Config.cortexApi.scope}_oAuthToken`,
          `Bearer ${res["access-token"]}`
        );
        localStorage.setItem(
          `${Config.cortexApi.scope}_oAuthRole`,
          params.role
        );
        localStorage.setItem(
          `${Config.cortexApi.scope}_oAuthUserId`,
          params.userId
        );
        localStorage.setItem(
          `${Config.cortexApi.scope}_oAuthImpersonationToken`,
          params.token
        );
        onLogin();
      });
    }
  }

  fetchPasswordResetData() {
    const {
      context: {
        auth: { logout }
      },
      props: { history }
    } = this;

    cortexFetch(`/?zoom=${zoomResetPassword.join()}`)
      .then(res => checkResponse(res))
      .then(res => {
        if (res && res._passwordresetform) {
          this.setState({ showForgotPasswordLink: true });
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => fetchPasswordResetData => AppHeaderLoginMain.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "fetchPasswordResetData => AppHeaderLoginMain.tsx"
          });
        }
      });
  }

  closeJobModal = () => {
    this.setState({ openJobModal: false });
  };

  closeClientModal = () => {
    this.setState({ openClientModal: false });
  };

  render() {
    const {
      context: { auth, modal, user, cart, branches, contract }
    } = this;

    const { descriptiveButtonText = "" } = this.props;

    const {
      isLoggedIn,
      logout,
      tempSsoTokens: { multipleCompanies }
    } = auth;
    const { openLoginModal, handleOpenLoginModal } = modal;
    const { userProfile } = user;
    const { company, onlineBillPayLink } = userProfile;
    const {
      cartDetails: { defaultCart }
    } = cart;
    const { findBranch } = branches;
    const selectedBranch = defaultCart
      ? findBranch(defaultCart.selectedBranch.code)
      : null;
    const { contractsList } = contract;

    const {
      isMobileView,
      onContinueCart,
      appHeaderLoginLinks,
      fetchCartData,
      appModalLoginLinks,
      disableLogin,
      history
    } = this.props;
    const {
      openCartModal,
      openJobModal,
      showForgotPasswordLink,
      accountData,
      loginUrlAddress,
      oidcParameters,
      ssoLoader,
      // DGE-2944
      // hasLogedIn
      error,
      openClientModal
    } = this.state;

    let keycloakLoginRedirectUrl = "";
    if (Config.b2b.enable && Config.b2b.openId && !Config.b2b.openId.enable) {
      keycloakLoginRedirectUrl = `${
        Config.b2b.keycloak.loginRedirectUrl
      }?client_id=${
        Config.b2b.keycloak.client_id
      }&response_type=code&scope=openid&redirect_uri=${encodeURIComponent(
        Config.b2b.keycloak.callbackUrl
      )}`;
    }

    const makeAuthContainerDropdown = () => {
      return (
        <div className="auth-container dropdown">
          <button
            className="dropdown-toggle btn-auth-menu"
            type="button"
            id={`${isMobileView ? "mobile_" : ""}header_navbar_loggedIn_button`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {isMobileView ? (
              intl.get("account-logged-in")
            ) : (
              <i
                className="icon-profile"
                title={`${userProfile.subuserFirstName ||
                  userProfile.givenName} ${userProfile.subuserLastName ||
                  userProfile.familyName}`}
              />
            )}
          </button>
          <div
            data-region="authMainRegion"
            className="auth-nav-container  dropdown-menu-right dropdown-menu"
            aria-label="header_navbar_login_button "
          >
            <ul
              data-el-container="global.profileMenu"
              className="auth-profile-menu-list"
            >
              <li className="dropdown-item">
                <div>
                  <i className="icon-home" />
                  {selectedBranch && (
                    <span id="header_navbar_login_menu_profile_link">
                      {intl.get("branch")}: {selectedBranch.branchName}
                    </span>
                  )}
                </div>
              </li>
              <li className="dropdown-item">
                <Link to={appHeaderLoginLinks.profile} className="profile-link">
                  <div>
                    <i className="icon-user-cog" />
                    <span id="header_navbar_login_menu_profile_link_2">
                      {intl.get("my-account")}
                    </span>
                  </div>
                </Link>
              </li>
              {Config.b2b.enable ? (
                <li className="dropdown-item">
                  <div>
                    <i className="icon-users" />
                    {selectedBranch && (
                      <span id="header_navbar_login_menu_profile_link">
                        {intl.get("shopping-as")}: {company || null}
                      </span>
                    )}
                  </div>
                </li>
              ) : (
                ""
              )}
              <li className="dropdown-item">
                <Link
                  to={appHeaderLoginLinks.myOrders}
                  className="profile-link"
                >
                  <div>
                    <i className="icon-my-orders" />
                    <span id="header_navbar_login_menu_my_orders_link">
                      {intl.get("my-orders")}
                    </span>
                  </div>
                </Link>
              </li>
              {Config.contractOrderPageDisplay &&
              contractsList &&
              contractsList.length ? (
                <li className="dropdown-item">
                  <Link
                    to={appHeaderLoginLinks.myContracts}
                    className="profile-link"
                  >
                    <div>
                      <i className="icon-my-orders" />
                      <span id="header_navbar_login_menu_my_contracts_link">
                        {intl.get("my-contracts")}
                      </span>
                    </div>
                  </Link>
                </li>
              ) : null}
              {Config.showOnlineBillPay && (
                <li className="dropdown-item">
                  <a
                    href={onlineBillPayLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <i className="icon-online-payments" />
                      <span id="header_navbar_login_menu_online-bill-pay-link">
                        {intl.get("online-billing-website")}
                      </span>
                    </div>
                  </a>
                </li>
              )}
              {/* {Config.b2b.enable && accountData && accountData._accounts ? (
                <li className="dropdown-item">
                  <Link className="dashboard-link" to="/b2b">
                    <div>
                      <span className="dashboard-nav">
                        {intl.get("dashboard")}
                      </span>
                    </div>
                  </Link>
                </li>
              ) : (
                ""
              )} */}
              {/* removing SHOP FOR  */}
              {/* {Config.b2b.enable && multipleCompanies ? (
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={this.handleCartModalOpen}
                  >
                    <i className="icon-cart" />
                    <span className="Cart-select-btn">
                      {intl.get("change-carts")}
                    </span>
                  </button>
                </li>
              ) : (
                ""
              )} */}
              <li className="dropdown-item">
                {Config.b2b.enable ? (
                  <button
                    className="logout-link"
                    type="button"
                    data-el-label="auth.logout"
                    onClick={() => {
                      logout(true).catch(err => {
                        pushToMaintenace(history, {
                          e: err,
                          errIn: "Logout  => AppHeaderLoginMain.tsx"
                        });
                      });
                    }}
                  >
                    <i className="icon-sign-out" />
                    {intl.get("sign-out")}
                  </button>
                ) : (
                  <button
                    className="logout-link"
                    type="button"
                    data-el-label="auth.logout"
                    onClick={() => this.logoutRegisteredUser()}
                  >
                    <i className="icon-sign-out" />
                    {intl.get("sign-out")}
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      );
    };

    function makeLoginButton() {
      return (
        <>
          {Config.b2b.enable ? (
            <a
              href={`${
                Config.b2b.openId && Config.b2b.openId.enable
                  ? loginUrlAddress
                  : keycloakLoginRedirectUrl
              }`}
              className="login-auth-service-btn"
              // DGE-2944 - On Login click - saves the last visited page to session storage.
              // onClick={() => {
              //   if (
              //     window.location.pathname !== "/sessionExpired" &&
              //     window.location.pathname !== "/maintenance"
              //   ) {
              //     setLastPageVisited(window.location.pathname);
              //   }
              // }}
            >
              <button
                className="login-btn"
                id={`${
                  isMobileView ? "mobile_" : ""
                }header_navbar_loggedIn_button`}
                type="button"
              >
                {isMobileView ? (
                  intl.get("account-login")
                ) : (
                  <div className="login-container">
                    <i className="icon-sign-in" />
                    <p className="login">
                      {descriptiveButtonText ||
                        `${Config.loginDisplay} ${intl.get("login")} ${
                          Config.showRegistration
                            ? `/ ${intl.get("register")}`
                            : ""
                        }`}
                    </p>
                  </div>
                )}
              </button>
            </a>
          ) : (
            <button
              className="login-btn"
              id={`${
                isMobileView ? "mobile_" : ""
              }header_navbar_loggedIn_button`}
              type="button"
              data-toggle="modal"
              onClick={() => handleOpenLoginModal(true)}
              data-target="#login-modal"
            >
              {isMobileView ? (
                intl.get("account-login")
              ) : (
                <div className="login-container">
                  <i className="icon-sign-in" />
                  <p className="login">
                    {descriptiveButtonText ||
                      `${Config.loginDisplay} ${intl.get("login")}`}
                  </p>
                  &nbsp;
                  {Config.showRegistration && (
                    <p className="register">{`/ ${intl.get("register")}`}</p>
                  )}
                </div>
              )}
            </button>
          )}
          <div data-region="authMainRegion" className="auth-nav-container" />
        </>
      );
    }

    const handleErrors = () => {
      return (
        error && (
          <Redirect
            to={{
              pathname: "/maintenance",
              state: {
                error: {
                  e: { message: error },
                  errIn: "AppHeaderLoginMain",
                  errorCode: intl.get(
                    "home-branch-not-in-branches-list-error-code"
                  )
                }
              }
            }}
          />
        )
      );
    };

    const renderJobModal = () => {
      const { items, cartOrderDetailsForm } = defaultCart;
      const isCartAssociatedToContract =
        items &&
        items.length &&
        cartOrderDetailsForm["contract-number"] &&
        cartOrderDetailsForm.pricing;

      return (
        <AppHeaderJobSelect
          closeJobModal={this.closeJobModal}
          initialJob={
            isCartAssociatedToContract
              ? defaultCart.cartOrderDetailsForm["job-number"]
              : ""
          }
          disableJobSelection={isCartAssociatedToContract}
        />
      );
    };

    return (
      <div
        className={`app-login-component ${isMobileView ? "mobile-view" : ""}`}
      >
        {ssoLoader && (
          <div className="fill-screen">
            <div className="loader" />
          </div>
        )}
        {handleErrors()}
        {isLoggedIn ? makeAuthContainerDropdown() : makeLoginButton()}
        {openCartModal && (
          <AppModalCartSelectMain
            key="app-modal-cart-selection-main"
            handleModalClose={this.handleCartModalClose}
            handleCartModalCloseButton={this.ssoCloseButton}
            openModal={openCartModal}
            onContinueCart={onContinueCart}
            fetchCartData={fetchCartData}
            closeSsoLoader={this.closeSsoLoader}
            history={history}
            auth={auth}
          />
        )}
        {openJobModal && renderJobModal()}
        {Config.showClientInformation && openClientModal && (
          <AppModalClientSelect closeClientModal={this.closeClientModal} />
        )}
        <AppModalWarning />
        <AppModalPhoneUpdate history={history} />
        {/* DGE-2944 -  The pop up is visible only after the login, and leads the user to the last visited page.
      
         {hasLogedIn && (
          <ErrorPopUp
            errorMessage=""
            closePopUp={() => this.setHasLoggedIn(false)}
          >
            <Link
              to={sessionStorage.getItem("lastVisitedPage")}
              aria-label={sessionStorage.getItem("lastVisitedPage")}
            >
              {intl.get("go-to-last-visited-page")}
            </Link>
          </ErrorPopUp>
        )} */}
      </div>
    );
  }
}

export default AppHeaderLoginMain;
