/* eslint-disable camelcase */
import React, { FC, useState, useEffect, useContext } from "react";
import jsonpointer from "jsonpointer";
import { getNestedObject } from "bloomreach-experience-react-sdk";
import { withRouter, RouteComponentProps } from "react-router-dom";
import intl from "react-intl-universal";
import { History, Location } from "history";

import { brSearch } from "@elasticpath/ref-store/src/services/SearchService";
import {
  SingleProduct,
  formatFacets,
  filterFacetFields,
  generateSearchParams,
  clearItemFromSessionStorage
} from "@elasticpath/ref-store/src/utils/searchUtils";
import {
  SearchResultsAvailabilityCheckobox,
  SearchResultsMenu
} from "@zilker/store-components";
import { ProductPriceRequest } from "@elasticpath/ref-store/src/utils/mappings/productDetails";
import {
  checkEntitlementSku,
  getAvailabilityMotili,
  getAvailabilityDGA,
  priceCatalog
} from "@elasticpath/ref-store/src/services/connectServices";
import {
  checkTokensExpired,
  formatInventoryAvailability,
  formatDGAInventory,
  generateSpecificErrorMessage,
  handleCustomException,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import {
  EntitlementDetails,
  PriceDetails
} from "@elasticpath/ref-store/src/containers/PartsFinderPage";
import { addToCart } from "@elasticpath/ref-store/src/services/EpServices";
import { MainContext } from "../../../../../app/src/contexts/MainContext";
import { getConfig } from "../../../utils/ConfigProvider";
import SearchResultsItems from "../../../SearchResultsPage/search.results.items";
import { productAdded, productListFiltered } from "../../../utils/Segment";
import SearchResultsScroppToTopButton from "../../../SearchResultsPage/search.results.scroll.to.top.button";
import BloomreachConfig, {
  Configuration,
  PageModel
} from "../../types/BloomreachConfigInterfaces";

import "./search-component.less";

const initialRows: number = 105;

const searchType: string = "keyword";

interface SearchComponentProps extends BloomreachConfig, RouteComponentProps {
  configuration: Configuration;
  pageModel: PageModel;
  preview: boolean;
  history: History;
  location: Location;
}

const SearchComponent: FC<SearchComponentProps> = ({
  configuration,
  pageModel,
  history,
  location
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [rows, setRows] = useState<number>(6);
  const [start, setStart] = useState<number>(0);
  const [numFound, setNumFound] = useState<number>(0);
  const [products, setProducts] = useState<SingleProduct[]>([]);
  const [filtersChecked, setFiltersChecked] = useState<{ [name: string]: any }>(
    {}
  );
  const [facetFields, setFacetFields] = useState<{ [name: string]: any }>({});
  const [productPrices, setProductPrices] = useState<PriceDetails[]>(null);
  const [productEntitlements, setProductEntitlements] = useState<
    EntitlementDetails[]
  >(null);
  const [productAvailability, setProductAvailability] = useState(null);
  const [addCartButtonIds, setAddCartButtonIds] = useState([]);
  const [sortByAvailability, setSortByAvailability] = useState<boolean>(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [itemToScroll, setItemToScroll] = useState<string>("");

  const context = useContext<{
    auth: any;
    user: any;
    cart: any;
    job: any;
    branches: any;
  }>(MainContext);
  const {
    cart: {
      cartDetails: { defaultCart },
      getCartDetails,
      setSuccesCartPopupMessage,
      setErrorCartPopupMessage
    },
    user: {
      userProfile: { customerNumber }
    },
    job: { persistedJobNumber },
    auth: { isLoggedIn, logout },
    branches: { findBranch, branchesList }
  } = context;
  const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
  const jobNumber = defaultCart ? defaultCart.jobNumber : null;
  const clientId = defaultCart ? defaultCart.clientId : null;
  const { config } = getConfig();
  const { calculatePrice, showAvailableItemsFirst } = config;

  useEffect(() => {
    const ref = getNestedObject(configuration, ["models", "document", "$ref"]);
    const content = ref && jsonpointer.get(pageModel, ref);
    if (content) {
      setSearchTerm(content.searchTerm);
      setRows(content.numberOfItems || rows);
      setSortByAvailability(showAvailableItemsFirst);
      window.addEventListener("scroll", () =>
        setScrollToTop(window.pageYOffset > 300)
      );
      window.addEventListener("beforeunload", clearItemFromSessionStorage);
    }

    return () => {
      window.removeEventListener("scroll", () =>
        setScrollToTop(window.pageYOffset > 300)
      );
      window.removeEventListener("beforeunload", clearItemFromSessionStorage);
    };
  }, []);

  // Fetch BR search results
  useEffect(() => {
    if (searchTerm) {
      fetchSearchResults();
    }
  }, [searchTerm]);

  // Fetch product prices
  useEffect(() => {
    if (
      calculatePrice &&
      isLoggedIn &&
      products.length &&
      selectedBranch &&
      customerNumber
    ) {
      fetchProductProductPrices();
    }
  }, [products, selectedBranch, customerNumber]);

  // Fetch product entitlements
  useEffect(() => {
    if (isLoggedIn && products.length && customerNumber && selectedBranch) {
      fetchProductEntitlements();
    }
  }, [isLoggedIn, products, customerNumber, selectedBranch]);

  // Fetch product availability
  useEffect(() => {
    if (
      isLoggedIn &&
      products.length &&
      selectedBranch &&
      customerNumber &&
      branchesList
    ) {
      fetchProductAvailability();
    }
  }, [
    isLoggedIn,
    products,
    selectedBranch,
    clientId,
    customerNumber,
    branchesList
  ]);

  // Scroll to last viewed item
  useEffect(() => {
    if (products.length && productAvailability && !itemToScroll) {
      scrollToLastViewedItem();
    }
  }, [itemToScroll, products, productAvailability]);

  const fetchSearchResults = async () => {
    const {
      start: startValue,
      startParam,
      filtersChecked: filtersCheckedValue,
      sortByAvailabilityParam
    } = generateSearchParams(
      history,
      location,
      rows,
      initialRows,
      showAvailableItemsFirst
    );

    const filtersParam = formatFacets(filtersCheckedValue);

    const { state } = location;
    const prevUrl = state && state.prevUrl ? state.prevUrl : "";
    try {
      const res: any = await brSearch(
        searchTerm,
        searchType,
        startParam,
        initialRows,
        filtersParam,
        "",
        window.location.href,
        prevUrl,
        branchesList
      );
      const {
        data: {
          response,
          facet_counts: { facet_fields }
        }
      } = res;

      setProducts(products.concat(response.docs));
      setFacetFields(filterFacetFields(facet_fields));
      setFiltersChecked(filtersCheckedValue || {});
      setNumFound(response.numFound);
      setStart(startValue);
      setSortByAvailability(sortByAvailabilityParam);
    } catch (e) {
      if (checkTokensExpired()) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "logout => fetchSearchResults => search-component.tsx"
          })
        );
      } else {
        pushToMaintenace(history, {
          e,
          errIn: "fetchSearchResults => search-component.tsx"
        });
      }
    }
  };

  const fetchProductProductPrices = async () => {
    const skus = products.map((product: SingleProduct) => product.pid);

    const body: ProductPriceRequest = {
      customerNumber,
      branchNumber: selectedBranch.code,
      skus,
      jobNumber
    };

    if (defaultCart.jobNumber || persistedJobNumber) {
      body.jobNumber = defaultCart.jobNumber || persistedJobNumber;
    }

    try {
      const { data } = await priceCatalog(body);
      setProductPrices(data.items);
    } catch (e) {
      const errorPath = "fetchProductPrices => search-component.tsx";
      handleCustomException(e, logout, history, errorPath);
      setProductPrices([]);
    }
  };

  const fetchProductEntitlements = async () => {
    const skus = products
      .map((product: SingleProduct) => product.pid)
      .join("|");
    try {
      const { data } = await checkEntitlementSku(
        customerNumber,
        skus,
        selectedBranch.vendor
      );
      setProductEntitlements(data);
    } catch (e) {
      const errorPath = "fetchProductEntitlements => search-component.tsx";
      handleCustomException(e, logout, history, errorPath);
      setProductEntitlements([]);
    }
  };

  const fetchProductAvailability = async () => {
    const {
      cortexApi: { scope }
    } = config;
    const skus = products.map((product: SingleProduct) => product.pid);
    const skusMotili = skus && skus.join("|");

    try {
      if (scope === "motili") {
        const { latitude, longitude } = findBranch(selectedBranch.code);

        const { data } = await getAvailabilityMotili(
          skusMotili,
          latitude,
          longitude,
          clientId
        );
        setProductAvailability(formatInventoryAvailability(data));
      } else {
        const branchNumber = selectedBranch.code;

        const { data } = await getAvailabilityDGA(
          customerNumber,
          skus,
          branchNumber
        );
        setProductAvailability(formatDGAInventory(data));
      }
    } catch (e) {
      const errorPath = "fetchProductAvailability => search-component.txs";
      handleCustomException(e, logout, history, errorPath);
      setProductAvailability([]);
    }
  };

  const onAddToCart = async (event, item?: SingleProduct) => {
    const { id } = event.target;
    const items = [
      {
        code: id,
        quantity: 1
      }
    ];
    const {
      addItemsToCart: {
        self: { uri }
      }
    } = defaultCart;

    setAddCartButtonIds(addCartButtonIds.concat(id));

    try {
      await addToCart(uri, { items });
      if (item) {
        const { title, pid, productPrice, brand } = item;
        // sends information to Segment when user adds a product
        productAdded(
          title,
          pid,
          !productPrice || productPrice === intl.get("pending")
            ? 0
            : Number(productPrice),
          brand,
          searchTerm,
          1
        );
      }
      await getCartDetails();
      setSuccesCartPopupMessage(1);
      setAddCartButtonIds(prevAddCartButtonIds =>
        prevAddCartButtonIds.filter(buttonId => buttonId !== id)
      );
    } catch (e) {
      if (checkTokensExpired(e)) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "Logout => onAddToCart => search-component.tsx"
          })
        );
      }
      setErrorCartPopupMessage(generateSpecificErrorMessage(e));
      setAddCartButtonIds(prevAddCartButtonIds =>
        prevAddCartButtonIds.filter(buttonId => buttonId !== id)
      );
    }
  };

  const onFacetChange = async (
    filter: string,
    checkedFilters: {
      [name: string]: any;
    }
  ) => {
    const filters = [];
    Object.entries(checkedFilters).forEach(checkedFilter => {
      const type = checkedFilter[0];
      checkedFilter[1].forEach(value => {
        const f = {
          type,
          value
        };
        filters.push(f);
      });
    });

    // sends applied filters to Segment
    productListFiltered(searchTerm, filters);

    try {
      // fetch BR search results for selected facets
      const { state } = location;
      const prevUrl = state && state.prevUrl ? state.prevUrl : "";
      const res: any = await brSearch(
        searchTerm,
        searchType,
        start,
        initialRows,
        filter,
        "",
        window.location.href,
        prevUrl,
        branchesList
      );
      const {
        data: {
          response,
          facet_counts: { facet_fields }
        }
      } = res;

      const fields = filterFacetFields(facet_fields);

      setProducts(response.docs);
      setFacetFields({ ...fields });
      setNumFound(response.numFound);
    } catch (e) {
      if (checkTokensExpired()) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "logout => onFacetChange => search-component.tsx"
          })
        );
      } else {
        pushToMaintenace(history, {
          e,
          errIn: "onFacetChange => search-component.tsx"
        });
      }
    }
  };

  const onFacetClicked = event => {
    const { checked, value, name } = event.target;
    const escValue = `"${value}"`;
    const checkedFilters = {};

    if (!checkedFilters[`${name}`]) {
      checkedFilters[`${name}`] = new Set();
    }

    if (checked) {
      checkedFilters[`${name}`].add(escValue);
    } else {
      checkedFilters[`${name}`].delete(escValue);
    }

    const filter = formatFacets(checkedFilters);
    setFiltersChecked(checkedFilters);
    onFacetChange(filter, checkedFilters);
  };

  const onAvailabilityCheckboxClick = event => {
    const { checked } = event.target;
    setSortByAvailability(checked);
  };

  const onLoadMore = async () => {
    const startIndex = (start + 1) * rows;
    const filters = formatFacets(filtersChecked);

    try {
      if (numFound > products.length && startIndex >= products.length) {
        const { state } = location;
        const prevUrl = state && state.prevUrl ? state.prevUrl : "";
        const res: any = await brSearch(
          searchTerm,
          searchType,
          startIndex,
          rows,
          filters,
          "",
          window.location.href,
          prevUrl,
          branchesList
        );
        const {
          data: {
            response,
            facet_counts: { facet_fields }
          }
        } = res;

        setProducts(products.concat(response.docs));
        setFacetFields(filterFacetFields(facet_fields));
        setNumFound(response.numFound);
        setStart(start + 1);
      } else {
        setStart(start + 1);
      }
    } catch (e) {
      if (checkTokensExpired()) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "logout => onLoadMore => search-component.tsx"
          })
        );
      } else {
        pushToMaintenace(history, {
          e,
          errIn: "onLoadMore => search-component.tsx"
        });
      }
    }
  };

  const scrollToLastViewedItem = () => {
    let scrollSku;
    const { state } = location;

    if (history.action === "POP") {
      scrollSku = sessionStorage.getItem("sku");
    } else if (state && state.sku) {
      scrollSku = state.sku;
    }

    const element = document.getElementById(`${scrollSku}`);

    if (element) {
      element.scrollIntoView();
      setItemToScroll(scrollSku);
    }
  };

  if (!searchTerm) return null;

  if (!products || !products.length || (isLoggedIn && !productAvailability)) {
    return <div className="loader" />;
  }

  return (
    <div className="search-component">
      <div className="search-component-wrapper container">
        <div className="search-component-header">
          <div className="label total-results">
            {intl.get("search-results-count", {
              loaded: (start + 1) * rows,
              found: numFound
            })}
          </div>
          {config.sortByAvailability && isLoggedIn ? (
            <SearchResultsAvailabilityCheckobox
              checked={sortByAvailability}
              handler={onAvailabilityCheckboxClick}
            />
          ) : null}
        </div>

        <div>
          <SearchResultsMenu
            facets={facetFields}
            filtersChecked={filtersChecked}
            onFacetClicked={onFacetClicked}
          />
          <SearchResultsItems
            productEntitlements={productEntitlements}
            productAvailability={productAvailability}
            products={products}
            productPrices={productPrices}
            addCartButtonIds={addCartButtonIds}
            start={start}
            rows={rows}
            filtersChecked={filtersChecked}
            sortByAvailability={sortByAvailability}
            numFound={numFound}
            onAddToCart={onAddToCart}
            onLoadMore={onLoadMore}
          />
        </div>
        {scrollToTop && <SearchResultsScroppToTopButton />}
      </div>
    </div>
  );
};

export default withRouter(SearchComponent);
