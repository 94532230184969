/* eslint-disable no-param-reassign */
import axios from "axios";
import { cortexFetch } from "@zilker/store-components";
import { zoomFetchCart } from "@zilker/store-components/src/static/zoom";
import intl from "react-intl-universal";
import moment from "moment";
import "moment/locale/es-us";
import "moment/locale/fr-ca";
import jwt from "jsonwebtoken";

import Config from "../ep.config.json";

const { scope } = Config.cortexApi;

/**
 * @desc this function does nothing and is used for empty event handlers required by linter
 * @see ./goodman/react/app/src/containers/OrderHistoryPage.tsx
 */
function noOp() {}

/**
 * @desc convert date format received from DaikinCloud API to match the one used by intl package
 * @see ./goodman/react/app/src/containers/OrderHistoryPage.tsx
 */
function dkToIntlFormatDate(dkDate) {
  if (dkDate) {
    const dateRegex = /^([0-9]{2})([0-9]{2})([0-9]{2})/;
    const yearPrefix = new Date()
      .getFullYear()
      .toString()
      .slice(0, 2);

    return new Date(dkDate.replace(dateRegex, `${yearPrefix}$1-$2-$3`));
  }

  return "";
}

/**
 * @desc convert date format received from DaikinCloud API to match the one used by intl package
 * @see ./goodman/react/app/src/containers/OrderHistoryPage.tsx
 */
function dkToUTCIntlFormatDate(dkDate) {
  if (dkDate) {
    const dt = dkDate.split(/[: T-]/).map(parseFloat);
    return new Date(
      dt[0],
      dt[1] - 1,
      dt[2],
      dt[3] || 0,
      dt[4] || 0,
      dt[5] || 0,
      0
    );
  }

  return "";
}

/**
 * @desc convert date to format sent to the DaikinCloud API for order(s) history filtering
 * @see ./goodman/react/app/src/containers/OrderHistoryPage.tsx
 */
function dkFormatDate(value) {
  const [year, month, day] = value.split("-");
  return `${month}${day}${year}`;
}

/**
 * ## checkResponse
 *
 * @param response any
 *
 * @description Function that processes the http response to check if it was successful.
 */
const checkResponse = (
  response,
  onSuccess = undefined,
  onError = undefined
) => {
  if (!response) {
    const error = {
      status: 400,
      message: "Bad response!"
    };
    throw error;
  }

  if (!onSuccess) {
    onSuccess = data => {
      return data.json();
    };
  }

  if (!onError) {
    onError = data => {
      throw data;
    };
  }

  return response &&
    (response.ok ||
      response.statusText === "OK" ||
      (response.status >= 200 && response.status < 300))
    ? onSuccess(response)
    : onError(response);
};

/**
 * ## setTokenInHeaders
 *
 * @param token? string
 *
 * @description Function sets the token in the axios common headers.
 */
function setTokenInHeaders(token?: string): boolean {
  if (token) {
    axios.defaults.headers.common.Authorization = `${token}`;
    return true;
  }
  axios.defaults.headers.common.Authorization = "";

  return false;
}
/**
 * ## processHttpResponse
 * @param param0  { data: any; status: number }
 * @param onSuccess (...args) => any
 * @param onError (...args) => any
 *
 * @description Generic function that handles Http response.
 */
function processHttpResponse(
  { data, status }: { data: any; status: number },
  onSuccess: (...args) => any,
  onError: (...args) => any
) {
  if (status >= 200 && status < 300) {
    onSuccess(data);
  } else if (status >= 400) {
    onError(data);
  }
}

/**
 * ## createDangerousMarkup
 * @param content any
 *
 * @description Function that creates a new object containing only the key __html
 *  and sanitized data as the value.
 */

function createDangerousMarkup(content: any) {
  return { __html: content };
}

/**
 * ## checkTokensExpired
 *
 * @description Function that checks if tokens have expired. Both Daikin and Elastic Path.
 * Returns true in case the tokens have expired, false otherwise.
 */

function checkTokensExpired(e = undefined) {
  const regex = new RegExp(/\b(401|403)\b/);
  let isExpired = false;
  if (e && (e.status === 401 || (e.message && regex.test(e.message)))) {
    const token = localStorage.getItem(`${scope}_oAuthToken`);
    const decodedToken = token && jwt.decode(token.substring(7));
    if (decodedToken && decodedToken.exp * 1000 < new Date().getTime()) {
      isExpired = true;
    }
  }
  /* DGE-2944 - When the token expires, the last visited page gets persisted to session storage in browser.
  if (isExpired) {
    setLastPageVisited(window.location.pathname);
  }
  */

  return isExpired;
}

/**
 * ## isInvalidEmail
 *
 * @description Function that performs the validation of the email string
 * using RegEx and returns empty string is email is valid.
 */
function isInvalidEmail(email: string): string {
  // eslint-disable-next-line no-useless-escape
  const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!email.trim()) {
    return intl.get("field-cannot-be-empty");
  }
  if (!email.match(emailFormat)) {
    return intl.get("invalid-email-address");
  }

  return "";
}

/**
 *
 * @param date as string "yymmdd"
 * @description Formats date string "yymmdd" to UTC date object.
 */
function formatStringToUTCDate(date: string): Date | null {
  if (date && date.length === 6) {
    const year = date.slice(0, 2);
    const month = date.slice(2, 4);
    const day = date.slice(4);

    const yearPrefix = new Date()
      .getFullYear()
      .toString()
      .slice(0, 2);

    const fulldate = new Date(
      Date.UTC(parseInt(`${yearPrefix}${year}`, 10), +month - 1, +day)
    );

    return fulldate;
  }
  return null;
}

function generateAddressString(addressInfo: {
  locality: string;
  "extended-address": string;
  "postal-code": string;
  region: string;
  "street-address": string;
  "country-name": string;
}): string {
  const extAddress = addressInfo["extended-address"]
    ? addressInfo["extended-address"]
    : "";

  const addressString = `${addressInfo["street-address"]} ${extAddress} ${addressInfo.locality} ${addressInfo.region} ${addressInfo["postal-code"]} ${addressInfo["country-name"]}`;

  return addressString;
}

function generateLocaleDate(utcDate: any): string {
  const selectedLocale = localStorage.getItem(`${scope}_locale`);

  let localeDate = "";
  if (
    selectedLocale &&
    (selectedLocale === "es-US" || selectedLocale === "fr-CA")
  ) {
    localeDate = utcDate.locale(selectedLocale.toLowerCase()).format("LL");
  } else if (!selectedLocale || selectedLocale === "en-US") {
    localeDate = utcDate.locale("en-us").format("LL");
  }

  return localeDate;
}

function isoToLocaleDate(date: string): string {
  const utcDate = moment.utc(date);

  const localeDate = generateLocaleDate(utcDate);

  return localeDate;
}

function simpleToLocaleDate(date: string): string {
  let localeDate = "";

  if (date && date !== "0") {
    let dateFormat = "YYMMDD";
    if (date.length === 8) {
      dateFormat = "YYYYMMDD";
    }
    const utcDate = moment.utc(date, dateFormat);

    localeDate = generateLocaleDate(utcDate);
  }

  return localeDate;
}

/**
 *
 * @param date string
 * @description Transforms ISO format date string to YYMMDD as "2020-12-01T09:42:57.451Z" => 201201.
 */
function isoToSimpleDate(date: string): string {
  let simpleDate = "";
  if (date) {
    const dateObject = moment(date);
    simpleDate = dateObject.utc().format("YYMMDD");
  }
  return simpleDate;
}

function pushToMaintenace(history, error) {
  if (error.e instanceof Response) {
    history.push({
      pathname: "/maintenance",
      state: {
        error: {
          ...error,
          e: { status: error.e.status, message: intl.get("service-error") }
        }
      }
    });
  } else {
    history.push({
      pathname: "/maintenance",
      state: { error }
    });
  }
}

function postErrorService(body) {
  const url = `/logmessage/${scope}/form?followlocation=&format=standardlinks,zoom.nodatalinks&=`;
  cortexFetch(url, {
    method: "post",
    body: JSON.stringify({
      message: body
    })
  });
}

function handleCustomException(
  e: any,
  logout: (...args: any) => any,
  history: any,
  errorPath: string
): void {
  if (checkTokensExpired(e)) {
    logout().catch(err =>
      pushToMaintenace(history, {
        e: err,
        errIn: `Logout => ${errorPath}`
      })
    );
  } else {
    postErrorService({
      msg: e,
      errIn: errorPath
    });
    const time = new Date();
    console.error(
      `${errorPath} - ${time.toDateString()}, ${time.toTimeString()}`,
      e
    );
  }
}

function extractPhoneAndFax(
  branchDetails: any = {}
): { phone: string; fax: string } {
  const contactData = {
    phone: "",
    fax: ""
  };
  if (branchDetails.phone) {
    contactData.phone = branchDetails.phone;
  }

  if (branchDetails.fax) {
    contactData.fax = branchDetails.fax;
  }

  if (!contactData.phone && !contactData.fax && branchDetails.otherContact) {
    const { otherContact } = branchDetails;
    const phoneNumber = otherContact.slice(
      otherContact.indexOf("P") + 2,
      otherContact.indexOf("F") - 1
    );
    const faxNumber = otherContact.slice(otherContact.indexOf("F") + 2);

    contactData.phone = phoneNumber;
    contactData.fax = faxNumber;
  }

  return contactData;
}

function openInvoiceReport(invoice: any, orderNumber: string): void {
  const invoiceUrl = window.URL.createObjectURL(new Blob([invoice.data]));
  const invoiceLink = document.createElement("a");
  invoiceLink.href = invoiceUrl;
  invoiceLink.setAttribute(
    "download",
    `InvoiceForOrderNumber-${orderNumber}.pdf`
  );
  document.body.appendChild(invoiceLink);
  invoiceLink.click();
  invoiceLink.parentNode.removeChild(invoiceLink);
}

/**
 * ## getBRCookie
 * @description Fetches "_br_uid_2" cookie parameter value. The value is already encoded.
 */
function getBRCookie(): string {
  let result = "_br_uid_2=";
  if (document.cookie.length) {
    const cookieList = document.cookie.split("; ");
    const brCookie = cookieList.find((cookieItem: string) =>
      cookieItem.startsWith("_br_uid_2")
    );
    if (brCookie) {
      result = brCookie;
    }
  }

  return result;
}

/**
 *
 * @description The function checks if the value is stringified null value, and
 * if it is, returns an empty string. Sometimes, DCS response has value "null",
 * and this function simplifies the string validation in code.
 */
function formatNullString(value: string): string {
  if (value && value === "null") {
    return "";
  }

  return value;
}

/**
 * @description The function takes value and formats it to number.
 * Handles negative vaules - that should be processed as zero quantity.
 */
function formatNumberValue(value: any): number {
  const valueType: string = typeof value;

  // If the value is null:
  if (!value) {
    return 0;
  }
  // If the value is a number:
  if (valueType === "number") {
    return value <= 0 ? 0 : value;
  }

  // If the value is a stringified number:
  return parseInt(value, 10) <= 0 ? 0 : parseInt(value, 10);
}

/**
 * @description The function takes number and formats it to the proper price format based on
 * a provided locale and currency
 */
function formatPrice(value: number, locale: string, currency: string): string {
  const options = {
    style: "currency",
    currency
  };
  const formattedPrice = new Intl.NumberFormat(locale, options).format(value);
  return formattedPrice;
}

/**
 *
 * @param arr any[]
 * @description Function that filters out duplicates from array, and returns copy of original array,
 * with unique values.
 */
function getUniqueArray(arr: any[]): any[] {
  return arr.filter(
    (arrItem, index, self) =>
      self.findIndex(
        item => JSON.stringify(item) === JSON.stringify(arrItem)
      ) === index
  );
}

function formatAvailabilityLabel(
  branchAvailability: number,
  regionAvailability: number
): string {
  let availabilityLabel: string = "";
  switch (true) {
    case !!branchAvailability:
      availabilityLabel = intl.get("available", {
        quantity: branchAvailability
      });
      break;
    case !branchAvailability && !!regionAvailability:
      availabilityLabel = intl.get("available-locally");
      break;
    case !branchAvailability && !regionAvailability:
      availabilityLabel = intl.get("call-for-availability");
      break;
    default:
      availabilityLabel = intl.get("call-for-availability");
  }
  return availabilityLabel;
}

/**
 *
 * @param quantity amount of product available on the current branch
 * @param selectedQty amount of product selected for purchase
 * @description Returns the correct availability string based on the given quantity and whether a component uses the limited-availabilty label
 */
function formatGeneralDGAAvailabilityLabel(
  quantity: number,
  selectedQty: any,
  regionAvailability?: number
) {
  if (selectedQty !== "") {
    if (quantity - selectedQty >= 0) {
      if (quantity - selectedQty < 5) {
        return intl.get("limited-availability");
      }
      return intl.get("available-label");
    }
    if (quantity - selectedQty < 0) {
      if (regionAvailability && regionAvailability - selectedQty >= 0) {
        if (regionAvailability - selectedQty >= 5) {
          return intl.get("available-in-your-region");
        }
        return intl.get("limited-in-your-region");
      }
      return intl.get("available-for-back-order-label");
    }
  }
  return intl.get("checking-availability");
}

export interface InventoryAvailabilityInterface {
  sku: string;
  branchAvailability: number;
  regionAvailability: number;
  unitOfMeasure: number;
}

// Format inventory response for DGA
function formatGoodmanInventory(
  inventory: any
): Array<InventoryAvailabilityInterface> {
  const { itemAvailability } = inventory;
  const result: Array<InventoryAvailabilityInterface> = itemAvailability.map(
    item => {
      const { sku, availableAtBranch, availableInResult, unitOfMeasure } = item;
      return {
        sku,
        branchAvailability: formatNumberValue(availableAtBranch),
        regionAvailability: formatNumberValue(availableInResult),
        unitOfMeasure: Number(unitOfMeasure) || 1
      };
    }
  );
  return result;
}

function formatDGAInventory(
  inventory: any,
  neededData?: boolean
): Array<InventoryAvailabilityInterface> {
  const { result } = inventory;
  if (neededData) {
    const resulted: Array<InventoryAvailabilityInterface> =
      result &&
      result.itemAvailability &&
      result.itemAvailability.map(item => {
        const {
          sku,
          availableAtBranch,
          availableInResult,
          unitOfMeasure
        } = item;
        return {
          sku,
          branchAvailability: formatNumberValue(availableAtBranch),
          regionAvailability: formatNumberValue(availableInResult),
          unitOfMeasure: Number(unitOfMeasure) || 1
        };
      });
    return resulted;
  }
  const resulted: Array<InventoryAvailabilityInterface> =
    result &&
    result.map(item => {
      const { sku, availableAtBranch, availableInResult, unitOfMeasure } = item;
      return {
        sku,
        branchAvailability: formatNumberValue(availableAtBranch),
        regionAvailability: formatNumberValue(availableInResult),
        unitOfMeasure: Number(unitOfMeasure) || 1
      };
    });
  return resulted;
}

function formatDGAInventoryList(inventory: any) {
  const { allBranches, itemAvailability } = inventory;
  const resulted: Array<InventoryAvailabilityInterface> =
    allBranches &&
    allBranches.map(item => {
      const itemDetails = item.items.length && item.items[0];
      if (itemDetails) {
        const { sku, qtyAvailable, qtyOnHand, unitOfMeasure } = itemDetails;
        return {
          sku,
          branchAvailability: formatNumberValue(qtyAvailable),
          regionAvailability: formatNumberValue(qtyOnHand),
          unitOfMeasure: Number(unitOfMeasure) || 1
        };
      }
      return {
        sku: itemAvailability[0].sku,
        branchAvailability: 0,
        regionAvailability: 0,
        unitOfMeasure: 1
      };
    });
  return resulted;
}

function formatRealTimeDGAInventory(
  inventory: any,
  memberBranches?: Array<string>
): Array<InventoryAvailabilityInterface> {
  const { result } = inventory;
  const resultMap = {};
  let resulted: Array<InventoryAvailabilityInterface> = [];
  if (result) {
    result.forEach(branch => {
      const { items } = branch;
      if (memberBranches) {
        const foundBranch = memberBranches.find(memberBranch => {
          return memberBranch === branch.branchNumber;
        });
        if (foundBranch) {
          items.forEach(item => {
            const { quantityAvailable, sku } = item;
            if (resultMap[sku]) {
              resultMap[sku] += quantityAvailable;
            } else {
              resultMap[sku] = quantityAvailable;
            }
          });
        }
      } else {
        items.forEach(item => {
          const { quantityAvailable, sku } = item;
          if (resultMap[sku]) {
            resultMap[sku] += quantityAvailable;
          } else {
            resultMap[sku] = quantityAvailable;
          }
        });
      }
    });
    resulted = Object.keys(resultMap).map(sku => {
      return {
        sku,
        branchAvailability: formatNumberValue(resultMap[sku]),
        regionAvailability: formatNumberValue(resultMap[sku]),
        unitOfMeasure: 1
      };
    });
  }
  return resulted;
}

function formatMotiliInventory(
  branches
): Array<InventoryAvailabilityInterface> {
  if (branches && branches.error) {
    return [];
  }

  if (!branches || !branches.length) {
    return [];
  }

  const [currentBranch, ...regionBranches] = branches;
  // First branch holds the current branch inventory -> branchAvailability
  const result: Array<
    InventoryAvailabilityInterface
  > = currentBranch.inventory.map(item => ({
    sku: item.vendorSku,
    branchAvailability: formatNumberValue(item.quantityAvailable),
    regionAvailability: 0,
    unitOfMeasure: 1
  }));

  // Rest of the branches hold region availability -> regonAvailability
  regionBranches.forEach(branch => {
    branch.inventory.forEach(item => {
      const existingItemIndex = result.findIndex(
        ia => ia.sku === item.vendorSku
      );
      // If item is already added to the array, increment its regionAvailability
      if (existingItemIndex >= 0) {
        result[existingItemIndex].regionAvailability += formatNumberValue(
          item.quantityAvailable
        );
      } else {
        // Else add item to the array
        result.push({
          sku: item.vendorSku,
          branchAvailability: 0,
          regionAvailability: formatNumberValue(item.quantityAvailable),
          unitOfMeasure: 1
        });
      }
    });
  });

  return result;
}

function formatInventoryAvailability(data) {
  if (scope === "motili") {
    return formatMotiliInventory(data);
  }
  return formatGoodmanInventory(data);
}

function formatMotiliAlternateBranchesInventory(branches) {
  const result = branches.map(branch => ({
    ...branch,
    items: branch.inventory.map(item => ({
      ...item,
      qtyAvailable: item.quantityAvailable
    }))
  }));
  return result;
}

function formatAlternateBranchesInventory(data) {
  if (scope === "motili") {
    return formatMotiliAlternateBranchesInventory(data);
  }
  return data.allBranches;
}

function convertUnitOfMeasure(unitOfMeasure, itemQty) {
  const convertedUnitOfMeasure = itemQty % unitOfMeasure;

  if (Number.isNaN(convertedUnitOfMeasure)) {
    return 1;
  }
  return convertedUnitOfMeasure;
}

function generateSpecificErrorMessage(e: any): string {
  enum ErrorId {
    SHOPPINGCART_MAX_LINEITEMS = "shoppingcart.max.lineitems",
    SHOPPINGCART_ADDITEMS_CONTRACTNUMBER = "shoppingcart.additems.contractnumber",
    FIELD_INVALID_MINIMUM_VALUE = "field.invalid.minimum.value",
    SHOPPINGCART_MAX_ITEMSIZE = "shoppingcart.max.itemsize",
    ITEM_NOT_VISIBLE = "item.not.visible"
  }
  let errorMessage = intl.get("custom-error-add-to-cart");
  if (e.messages && e.messages[0]) {
    switch (e.messages[0].id) {
      case ErrorId.SHOPPINGCART_MAX_LINEITEMS: {
        const { data } = e.messages[0];
        errorMessage = intl.get("cart-items-limit-message", {
          limit: data["max-cart-size"]
        });
        break;
      }
      case ErrorId.SHOPPINGCART_ADDITEMS_CONTRACTNUMBER: {
        errorMessage = intl.get("mixed-contract-items");
        break;
      }
      case ErrorId.FIELD_INVALID_MINIMUM_VALUE: {
        errorMessage = intl.get("negative-quantity-error");
        break;
      }
      case ErrorId.SHOPPINGCART_MAX_ITEMSIZE: {
        const { data } = e.messages[0];
        errorMessage = intl.get("large-quantity-error", {
          limit: data["max-cart-size"]
        });
        break;
      }
      case ErrorId.ITEM_NOT_VISIBLE: {
        const { data } = e.messages[0];
        errorMessage = intl.get("item-not-visible-error", {
          sku: data["item-code"]
        });
        break;
      }
      default:
        break;
    }
  }
  return errorMessage;
}

function groupProsItems(items) {
  if (!items) {
    return {};
  }

  const groupedItems = items.reduce(
    (result, item) => ({
      ...result,
      [item["pros-config-id"]]: [
        ...(result[item["pros-config-id"]] || []),
        item
      ]
    }),
    {}
  );
  return groupedItems;
}

function createNotificationsPhoneList(phoneNumber, smsPhoneNumbers) {
  if (smsPhoneNumbers) {
    if (phoneNumber === "" || !phoneNumber) {
      return smsPhoneNumbers;
    }

    const phoneList = smsPhoneNumbers.split("|");
    const phoneIndex = phoneList.indexOf(phoneNumber);
    if (phoneIndex !== -1) {
      phoneList.splice(phoneIndex, 1);
      phoneList.unshift(phoneNumber);
    } else {
      phoneList.unshift(phoneNumber);
    }
    return phoneList.join("|");
  }
  return phoneNumber;
}

function isLinkExternal(url: string): [boolean, string] {
  const {
    pageMetadata: { canonicalURL }
  } = Config;

  if (url.startsWith("www") || url.startsWith("http")) {
    return [true, url];
  }
  if (url.includes(canonicalURL)) {
    const internalLink = url.substring(canonicalURL.length);
    return [false, internalLink];
  }

  return [false, url];
}

export {
  noOp,
  dkToIntlFormatDate,
  dkToUTCIntlFormatDate,
  dkFormatDate,
  setTokenInHeaders,
  checkResponse,
  processHttpResponse,
  createDangerousMarkup,
  checkTokensExpired,
  isInvalidEmail,
  formatStringToUTCDate,
  generateAddressString,
  isoToLocaleDate,
  simpleToLocaleDate,
  isoToSimpleDate,
  pushToMaintenace,
  postErrorService,
  handleCustomException,
  extractPhoneAndFax,
  openInvoiceReport,
  getBRCookie,
  formatNullString,
  formatNumberValue,
  formatPrice,
  getUniqueArray,
  convertUnitOfMeasure,
  generateSpecificErrorMessage,
  formatInventoryAvailability,
  formatGeneralDGAAvailabilityLabel,
  formatDGAInventory,
  formatRealTimeDGAInventory,
  formatAvailabilityLabel,
  formatAlternateBranchesInventory,
  groupProsItems,
  isLinkExternal,
  createNotificationsPhoneList,
  formatDGAInventoryList
};
