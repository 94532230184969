import React from "react";
import { createLink } from "bloomreach-experience-react-sdk";
import { Link } from "react-router-dom";
import { getConfig, IEpConfig } from "../../../utils/ConfigProvider";

const BRlink = props => {
  const { configuration, scrollTop, classes } = props;
  if (!configuration) {
    return null;
  }

  const activeElm = configuration.selected ? (
    <span className="sr-only">(current)</span>
  ) : null;

  const linkText = () => (
    <>
      {configuration.name}
      {activeElm}
    </>
  );

  const onLinkClick = e => {
    window.scrollTo(0, 0);
  };

  /** This here is a quick (and dirty) fix for removing the /site part
   * from internal CMS URLs. It also removes the scope part since
   * for other sites (daikin and amana) that part is also appended
   * to the internal URLs */
  const formatSiteLink = (href: string) => {
    const { config }: { config: IEpConfig } = getConfig();
    const {
      cortexApi: { scope },
      brXM: { channels }
    } = config;
    /** When language/locale is changed from default en-US to either
     * es-US or fr-CA the scope part in the URL is also changed to match
     * that value from brXM.channels array in config, so
     * SCOPE_es_us or SCOPE_fr_ca is appended to the URL as well
     * Current locale is set in localStorage once user logges in,
     * if no value is found in localStorage use english (en-US) as default
     */
    const currentUserLocale =
      localStorage.getItem(`${scope}_locale`) || "en-US";
    const currentChannel = channels.filter(
      channel => channel.language === currentUserLocale
    );
    const regex = new RegExp(`/site/${currentChannel[0].value}/|/site/`);

    return href.replace(regex, "/");
  };

  configuration._links.site.href = formatSiteLink(
    configuration._links.site.href
  );

  if (configuration._links.site.type === "internal") {
    return (
      <div
        onClick={onLinkClick}
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
      >
        {createLink("self", configuration, linkText, classes)}
      </div>
    );
  }
  if (configuration._links.site.type === "external") {
    if (
      configuration._links.site.href.startsWith("www") ||
      configuration._links.site.href.startsWith("http")
    ) {
      return (
        <a
          href={configuration._links.site.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {configuration.name}
        </a>
      );
    }
    return (
      <Link to={configuration._links.site.href} target="_blank">
        {configuration.name}
      </Link>
    );
  }

  return <>{createLink("self", configuration, linkText, classes)}</>;
};

export default BRlink;
