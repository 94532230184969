import React, { FC, useState, useContext } from "react";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { cortexFetch } from "@zilker/store-components";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import {
  checkResponse,
  checkTokensExpired,
  pushToMaintenace
} from "../../../app/src/utils/helpers";

interface AppModalClientSelectProps extends RouteComponentProps {
  history: any;
  closeClientModal: () => void;
}

interface ClientEntity {
  name: string;
  id: string;
}

const AppModalClientSelect: FC<AppModalClientSelectProps> = ({
  history,
  closeClientModal
}) => {
  const context = useContext<{ auth: any; user: any; cart: any }>(MainContext);
  const {
    auth: { logout },
    user: { userProfile },
    cart: {
      cartDetails: { defaultCart },
      getCartDetails
    }
  } = context;

  const initialClient = defaultCart
    ? { id: defaultCart.clientId, name: defaultCart.clientName }
    : {
        name: "",
        id: ""
      };
  const [selectedClient, setSelectedClient] = useState<ClientEntity>(
    initialClient
  );
  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
    closeClientModal();
  };

  const selectClient = e => {
    setSelectedClient(JSON.parse(e.target.value));
  };

  const onContinue = () => {
    const link = defaultCart.cartOrderDetailsForm.links[0].uri;

    const body = {
      ...defaultCart.cartOrderDetailsForm,
      "update-mode": "CLIENT",
      "client-id": selectedClient.id,
      "client-name": selectedClient.name
    };

    cortexFetch(link, {
      method: "post",
      body: JSON.stringify(body)
    })
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(() => {
        getCartDetails();
        sessionStorage.setItem("clientChosen", "true");
        closeModal();
      })
      .catch(e => {
        closeModal();
        if (checkTokensExpired(e)) {
          logout.catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => onContinue => AppModalClientSelect.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "onContinue => AppModalClientSelect.tsx"
          });
        }
      });
  };

  const renderClientList = () => {
    const { clientList } = userProfile;
    return clientList && clientList.length
      ? clientList.map((client, index) => (
          <div className="radio" key={client.id}>
            <label
              htmlFor={`cart-selection-option${index}`}
              className="custom-radio-button"
            >
              <input
                id={`cart-selection-option${index}`}
                type="radio"
                value={JSON.stringify(client)}
                checked={selectedClient.id === client.id}
                onChange={selectClient}
              />
              <span className="helping-el" />
              <span className="label-text">
                {`${client.name} ${client.id}`}
              </span>
            </label>
          </div>
        ))
      : null;
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={logout}
        classNames={{ modal: "cart-selection-modal-content" }}
      >
        <div className="modal-lg">
          <div className="modal-content" id="simplemodal-container">
            <div className="modal-header">
              <h2 className="modal-title">{intl.get("select-client")}</h2>
            </div>
            <div className="modal-body">
              <div id="cart_selection_modal_form">
                <div className="carts-selection-region">
                  {renderClientList()}
                </div>
              </div>
              <div className="action-row">
                <div className="form-input btn-container">
                  <button
                    onClick={onContinue}
                    className="ep-btn primary wide"
                    id="continue_with_cart_button"
                    data-cmd="continue"
                    // data-toggle="collapse"
                    // data-target=".navbar-collapse"
                    type="submit"
                    disabled={!selectedClient.id}
                  >
                    {intl.get("continue")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(AppModalClientSelect);
