/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SearchResultsPage from "./SearchResultsPage";

interface CategoryPageProps extends RouteComponentProps {
  history: any;
  match: any;
}

const CategoryPage: FC<CategoryPageProps> = ({ history, match }) => {
  function handleProductFacetSelection(offerSearch, title) {
    if (history.location.pathname.includes("category")) {
      history.push(
        `/category/${title}${offerSearch._offersearchresult[0].self.uri}`,
        { state: { prevUrl: window.location.href } }
      );
    } else {
      history.push(
        `/search/${title}${offerSearch._offersearchresult[0].self.uri}`,
        { state: { prevUrl: window.location.href } }
      );
    }
  }

  // const productLinks = {
  //   itemDetail: "/itemdetail",
  //   productsCompare: "/productscompare",
  //   productSearch: "/search",
  //   productCategory: "/category"
  // };

  return (
    <div>
      <SearchResultsPage searchType="category" />
    </div>
  );
};

export default withRouter(CategoryPage);
