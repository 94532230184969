import React, { FC, useState } from "react";

import SearchResultsMenuItem from "./search.results.menu.item";
import "./search.results.menu.section.less";

interface SearchResultsMenuSectionProps {
  name: string;
  data: any;
  filtersChecked: any;
  onFacetClicked: (any) => any;
}

const SearchResultsMenuSection: FC<SearchResultsMenuSectionProps> = ({
  name,
  data,
  filtersChecked,
  onFacetClicked
}) => {
  const [isOpened, setIsOpen] = useState<boolean>(false);
  const [classNameSuffix, setClassNameSuffix] = useState<string>("opened");

  const toggleSection = (): void => {
    setIsOpen(!isOpened);
    setClassNameSuffix(isOpened ? "opened" : "");
  };

  const sections = Array.from(data);
  const sectionName = name.replace(/_/g, " ");

  return (
    <div className="search-results-menu-item">
      <div
        className={`search-results-menu-item-title ${classNameSuffix}`}
        onClick={toggleSection}
        onKeyDown={toggleSection}
        role="button"
        aria-hidden="true"
      >
        <h4 className="bullet">{`${sectionName}`}</h4>
        <i
          title={`Open ${name} Menu`}
          aria-label={`Open ${name} Menu`}
          className={!isOpened ? "icon-minus" : "icon-plus"}
        />
      </div>
      <div className={`search-results-menu-item-fields ${classNameSuffix}`}>
        {sections.map(
          (item: { name?: string; type?: string; count?: number }) => {
            const key = `${item.name}-${item.type}`;
            const sectionItems = filtersChecked[item.type]
              ? Array.from(filtersChecked[item.type])
              : [];
            const itemChecked = sectionItems.includes(`"${item.name}"`);

            return (
              <SearchResultsMenuItem
                key={key}
                data={item}
                itemChecked={itemChecked}
                onFacetClicked={onFacetClicked}
              />
            );
          }
        )}
        {/* {data.map((item, i) => {
          return (
            <SearchResultsMenuItem
              key={item.name}
              data={item}
              onFacetClicked={onFacetClicked}
            />
          );
        })} */}
      </div>
    </div>
  );
};

export default SearchResultsMenuSection;
