/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */
import React from "react";
import { RenderCmsComponent } from "bloomreach-experience-react-sdk";
import HeaderUtilityMenu from "../Bloomreach/components/HeaderUtilityMenu/header-utility-menu";
import AppHeaderLocaleMain from "../AppHeaderLocale/appheaderlocale.main";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import JobDropdownComponent from "../JobDropdownComponent/JobDropdownComponent";
import BranchDropdownComponent from "../BranchDropdownComponent/BranchDropdownComponent";
import ClientDropdownComponent from "../ClientDropdownComponent/ClientDropdownComponent";

import "./appheadertop.main.less";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface AppHeaderTopProps {
  isMobileView?: boolean;
  onCurrencyChange?: (...args: any[]) => any;
  onLocaleChange?: (...args: any[]) => any;
  appHeaderTopLinks: {
    [key: string]: any;
  };
  isLoggedIn: boolean;
  userProfile: any;
  cart: any;
  homeBranchDetails: any;
}

class AppHeaderTop extends React.Component<AppHeaderTopProps> {
  constructor(props) {
    super(props);
    ({ intl } = getConfig());
    const epConfig = getConfig();
    Config = epConfig.config;

    this.renderCompanyInfo = this.renderCompanyInfo.bind(this);
  }

  renderCompanyInfo(): any {
    const {
      userProfile: { company, customerNumber }
    } = this.props;

    if (company) {
      if (Config.showCustomerNumber && customerNumber) {
        return (
          <p className="company-information">{`${company} - ${customerNumber}`}</p>
        );
      }

      if (!Config.showCustomerNumber) {
        return <p className="company-information">{company}</p>;
      }
    }

    return null;
  }

  render() {
    const {
      isMobileView,
      onCurrencyChange,
      onLocaleChange,
      appHeaderTopLinks,
      isLoggedIn,
      userProfile,
      cart,
      homeBranchDetails
    } = this.props;

    const {
      givenName,
      homeBranch,
      subuserHomeBranch,
      subuserFirstName,
      jobNumberRequired,
      jobsArray,
      clientList
    } = userProfile;
    const {
      cartDetails: { defaultCart }
    } = cart;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;

    const impersonating = localStorage.getItem(
      `${Config.cortexApi.scope}_oAuthImpersonationToken`
    );
    const userName =
      localStorage.getItem(`${Config.cortexApi.scope}_oAuthUserName`) ||
      localStorage.getItem(`${Config.cortexApi.scope}_oAuthUserId`);

    const jobsArrayExists = jobsArray && jobsArray.length;
    const jobNumberExists = defaultCart && defaultCart.jobNumber;

    const shouldDisplayJobDropdown =
      jobsArrayExists || (jobNumberRequired || jobNumberExists);

    return [
      <div
        key="AppHeaderTop"
        className={`top-header container-fluid ${
          isMobileView ? "mobile-view" : ""
        }`}
      >
        <div
          className={
            impersonating
              ? "top-container-impersonation container"
              : "top-container container"
          }
        >
          {userProfile && (
            <div className="locale-container">
              <AppHeaderLocaleMain
                onCurrencyChange={onCurrencyChange}
                onLocaleChange={onLocaleChange}
                userProfile={userProfile}
                isLoggedIn={isLoggedIn}
              />
            </div>
          )}

          {isLoggedIn ? (
            <div className="user-information">
              <p className="welcome-message">
                {intl.get("welcome")}, {subuserFirstName || givenName}
              </p>
              {this.renderCompanyInfo()}
              {(subuserHomeBranch || homeBranch) && selectedBranch ? (
                <div className="dropdown-container">
                  <div className="branch-selection-container">
                    <span>
                      {// eslint-disable-next-line no-template-curly-in-string
                      intl.get("home-branch")}{" "}
                      #{subuserHomeBranch || homeBranch} -
                      {Config.showBranchName && homeBranchDetails
                        ? homeBranchDetails.branchName // If subuserHomeBranch exists, it will populate the value of user's home branch.
                        : intl.get("current-branch")}
                    </span>
                    {Config.branchDropDownLocation === "header" && (
                      <BranchDropdownComponent
                        selectedBranch={selectedBranch.code}
                      />
                    )}
                  </div>
                  {shouldDisplayJobDropdown ? <JobDropdownComponent /> : null}
                  {Config.showClientInformation &&
                  clientList &&
                  clientList.length &&
                  defaultCart ? (
                    <ClientDropdownComponent />
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}

          {impersonating ? (
            <div className="impersonation-notification">
              {intl.get("shopper-impersonation-message")}
              {userName}
            </div>
          ) : (
            ""
          )}

          <div className="top-container-menu">
            <RenderCmsComponent
              path="header-utility-menu"
              renderComponent={HeaderUtilityMenu}
            />
          </div>
        </div>
      </div>
    ];
  }
}

export default AppHeaderTop;
